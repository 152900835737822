import tinycolor from '@ctrl/tinycolor';

export function getMatColors(hex: string) {
  return [
    {
      hex: tinycolor(hex).lighten(37.7).saturate(10.4).spin(-13).toHexString(),
      name: '50',
    },
    {
      hex: tinycolor(hex).lighten(31.8).saturate(10.4).spin(-9.5).toHexString(),
      name: '100',
    },
    {
      hex: tinycolor(hex).lighten(18.7).desaturate(17).spin(-3.9).toHexString(),
      name: '200',
    },
    {
      hex: tinycolor(hex).lighten(9.1).desaturate(20.9).spin(-4).toHexString(),
      name: '300',
    },
    {
      hex: tinycolor(hex).lighten(4.1).desaturate(6.6).spin(-3).toHexString(),
      name: '400',
    },
    { hex: tinycolor(hex).toHexString(), name: '500' },
    {
      hex: tinycolor(hex).darken(3.1).desaturate(12.4).spin(-2.7).toHexString(),
      name: '600',
    },
    {
      hex: tinycolor(hex).darken(7.8).desaturate(24.5).spin(-4).toHexString(),
      name: '700',
    },
    {
      hex: tinycolor(hex).darken(11.7).desaturate(23.2).spin(-4).toHexString(),
      name: '800',
    },
    {
      hex: tinycolor(hex).darken(17).desaturate(16.1).spin(-4).toHexString(),
      name: '900',
    },
    {
      hex: tinycolor(hex).lighten(16.7).saturate(10.4).spin(0.6).toHexString(),
      name: 'A100',
    },
    {
      hex: tinycolor(hex).lighten(7.7).saturate(10.4).spin(-4).toHexString(),
      name: 'A200',
    },
    {
      hex: tinycolor(hex).darken(3.9).saturate(10.4).spin(-15.5).toHexString(),
      name: 'A400',
    },
    {
      hex: tinycolor(hex).darken(16.6).saturate(10.4).spin(-4).toHexString(),
      name: 'A700',
    },
  ];
}

export function getNeutralColors() {
  const DARK_CONTRAST = '#FFFFFF';
  const LIGHT_CONTRAST = '#101213';
  return [
    {
      hex: '#f1f3f5',
      name: '50',
      light: true,
    },
    {
      hex: '#ced4da',
      name: '100',
      light: true,
    },
    {
      hex: '#adb5bd',
      name: '200',
      light: false,
    },
    {
      hex: '#62656c',
      name: '300',
      light: false,
    },
    {
      hex: '#2c2934',
      name: '400',
      light: false,
    },
    {
      hex: '#101213',
      name: '500',
      light: false,
    },
    {
      hex: '#101213',
      name: '600',
      light: false,
    },
    {
      hex: '#101213',
      name: '700',
      light: false,
    },
    {
      hex: '#101213',
      name: '800',
      light: false,
    },
    {
      hex: '#101213',
      name: '900',
      light: false,
    },
    {
      hex: '#f8f9fa',
      name: 'A50',
      light: true,
    },
    {
      hex: '#e9ecef',
      name: 'A100',
      light: true,
    },
    {
      hex: '#dee2e6',
      name: 'A200',
      light: true,
    },
    {
      hex: '#1a1821',
      name: 'A400',
      light: false,
    },
    {
      hex: '#1a1821',
      name: 'A700',
      light: false,
    },
  ].map((item) => ({
    ...item,
    contrast: item.light ? LIGHT_CONTRAST : DARK_CONTRAST,
  }));
}
