<div
  class="message-item-wrap"
  [class]="customWrapClass"
  [style]="{
    '--message-item-color-primary': colorPrimary,
    '--message-item-color-primary-light': colorPrimaryLight,
    '--message-item-color-primary-light-bg': colorPrimaryLightBG
  }"
>
  <!-- up -->
  <div
    *ngIf="info?.status === 'failed'"
    class="message-item-up"
    [style]="{
      'justify-content': info?.position === 'left' ? 'flex-start' : 'flex-end'
    }"
  >
    <div class="message-item-up-resend" (click)="handleResendClick()">
      <svg class="icon message-item-up-resend-svg" viewBox="0 0 1216 1024">
        <path
          d="M1179.2 405.632a37.76 37.76 0 0 1 0 55.168l-167.04 167.04a37.76 37.76 0 0 1-55.04 0l-167.04-167.04a39.04 39.04 0 0 1 55.104-55.168l139.456 138.624 139.392-138.624a37.76 37.76 0 0 1 55.168 0z"
        ></path>
        <path
          d="M512 1024a512 512 0 1 1 512-512 39.36 39.36 0 1 1-78.72 0A433.28 433.28 0 1 0 512 945.28a425.344 425.344 0 0 0 216.64-58.368 40.192 40.192 0 0 1 58.688 29.44 38.528 38.528 0 0 1-19.328 39.104A510.4 510.4 0 0 1 512 1024z"
        ></path>
      </svg>
      <span
        [style]="{
          'margin-left': '0.375rem'
        }"
      >
        Re-send
      </span>
    </div>
    <div class="message-item-up-delete" (click)="handleDeleteClick()">
      <svg class="icon message-item-up-delete-svg" viewBox="0 0 1029 1024">
        <path
          d="M796.444444 859.022222c0 34.133333-28.444444 62.577778-62.577777 62.577778H284.444444c-34.133333 0-62.577778-28.444444-62.577777-62.577778V199.111111H796.444444v659.911111zM347.022222 85.333333c0-5.688889 5.688889-11.377778 11.377778-11.377777h301.511111c5.688889 0 11.377778 5.688889 11.377778 11.377777v39.822223H347.022222v-39.822223z m637.155556 39.822223h-238.933334v-39.822223c0-45.511111-39.822222-85.333333-85.333333-85.333333H364.088889c-51.2 0-91.022222 39.822222-91.022222 85.333333v39.822223H39.822222c-22.755556 0-39.822222 17.066667-39.822222 39.822222s17.066667 39.822222 39.822222 39.822222h113.777778v659.911111c0 73.955556 62.577778 136.533333 136.533333 136.533333H739.555556c73.955556 0 136.533333-62.577778 136.533333-136.533333V199.111111h113.777778c22.755556 0 39.822222-17.066667 39.822222-39.822222s-22.755556-34.133333-45.511111-34.133333zM512 813.511111c22.755556 0 39.822222-17.066667 39.822222-39.822222v-398.222222c0-22.755556-17.066667-39.822222-39.822222-39.822223-22.755556 0-39.822222 17.066667-39.822222 39.822223v398.222222c0 22.755556 17.066667 39.822222 39.822222 39.822222m-176.355556 0c22.755556 0 39.822222-17.066667 39.822223-39.822222v-398.222222c0-22.755556-17.066667-39.822222-39.822223-39.822223-22.755556 0-39.822222 17.066667-39.822222 39.822223v398.222222c5.688889 22.755556 22.755556 39.822222 39.822222 39.822222m352.711112 0c22.755556 0 39.822222-17.066667 39.822222-39.822222v-398.222222c0-22.755556-17.066667-39.822222-39.822222-39.822223-22.755556 0-39.822222 17.066667-39.822223 39.822223v398.222222c0 22.755556 17.066667 39.822222 39.822223 39.822222"
        ></path>
      </svg>
    </div>
  </div>
  <!-- mid -->
  <div
    class="message-item-mid"
    [style]="{
      'flex-direction': info?.position === 'left' ? 'row-reverse' : 'row'
    }"
  >
    <!-- mid-block -->
    <div
      [style]="{
        flex: '1 1 auto'
      }"
    ></div>
    <!-- mid-content -->
    <div
      class="message-item-mid-content"
      [ngClass]="{
        'message-item-mid-content-f1f3f5': info?.position === 'left',
        'message-item-mid-content-primary': info?.position !== 'left',
        'message-item-mid-content-sending': info?.status === 'sending'
      }"
      [style]="{
        'border-radius': '0.5rem',
        'border-bottom-right-radius':
          sourceReal === 'LP' && info?.position === 'right' ? '0' : '0.5rem',
        color: info?.position === 'left' ? '#62656C' : '#ffffff',
        opacity: ['failed', 'sending'].includes(info?.status || '')
          ? '0.4'
          : '1'
      }"
      (click)="handleContentClick()"
    >
      <ng-container [ngSwitch]="messageType">
        <div *ngSwitchCase="'TEXT'" class="message-item-mid-content-text">
          <div class="break-words break-all text-justify">
            {{ info?.content }}
          </div>
        </div>
        <div *ngSwitchCase="'FILE'" class="message-item-mid-content-file">
          <stream-message-file
            *ngFor="
              let itemAttachment of info?.attachment;
              let indexAttachment = index
            "
            [info]="itemAttachment"
            class="message-item-file"
            [style]="{
              diaplay: 'flex'
            }"
            customStyle="background-color: rgba(0, 0, 0, 0); padding: 0"
            (previewClick)="handleFilePreviewClick($event)"
          ></stream-message-file>
        </div>
        <div
          *ngSwitchCase="'TEXT_FILE'"
          class="message-item-mid-content-text-file"
          [style]="{
            'align-items': info?.position === 'left' ? 'flex-start' : 'flex-end'
          }"
        >
          <div class="message-item-mid-content-text-file-text">
            {{ info?.content }}
          </div>
          <stream-message-file
            *ngFor="
              let itemAttachment of info?.attachment;
              let indexAttachment = index
            "
            [info]="itemAttachment"
            class="message-item-file"
            [style]="{
              diaplay: 'flex',
              'max-width': '100%',
              'justify-content':
                info?.position === 'left' ? 'flex-start' : 'flex-end'
            }"
            [customStyle]="{
              'background-color':
                info?.position === 'left'
                  ? '#ffffff'
                  : 'var(--message-item-color-primary-light-bg)',
              padding: '0.625rem'
            }"
            (previewClick)="handleFilePreviewClick($event)"
          ></stream-message-file>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- down -->
  <div
    class="message-item-down"
    [style]="{
      'justify-content': info?.position === 'left' ? 'flex-start' : 'flex-end',
      opacity: ['failed', 'sending'].includes(info?.status || '') ? '0' : '1'
    }"
  >
    <!-- down-tag -->
    <div
      *ngIf="tagDown"
      class="message-item-down-item message-item-down-tag"
      [style]="{
        'background-color': tagDownBG
      }"
    >
      {{ tagDown }}
    </div>
    <!-- down-black -->
    <div
      *ngIf="info?.textBlack"
      class="message-item-down-item message-item-down-black"
    >
      {{ info?.textBlack }}
    </div>
    <!-- down-gray -->
    <div
      *ngIf="info?.textGray"
      class="message-item-down-item message-item-down-gray"
    >
      {{ info?.textGray }}
    </div>
  </div>
</div>
