import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { StreamFormlyType } from '@stream/models';

import { AutocompleteComponent } from './autocomplete.component';

const matModules = [MatAutocompleteModule, MatInputModule];

@NgModule({
  declarations: [AutocompleteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...matModules,
    FormlyModule.forChild({
      types: [
        {
          name: StreamFormlyType.AUTOCOMPLETE,
          component: AutocompleteComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
})
export class AutocompleteModule {}
