declare let window: Window;

export const environment = {
  production: true,
  baseUrl: '/api',
  headers: {
    Target: 'web',
    Version: ''
  },
  biteDebugPackageInfoKey: 'BITE_DEBUG_PACKAGE_INFO',
  reCaptchaKeyV2: '6Lf_dN4fAAAAAH8lypIU8aWUHdnWRgX4ENBQQC6e',
  reCaptchaKeyV3: '6LdIZwIeAAAAAA6cXKp54VTqVbRi8OR0v_Biage_',
  googleAnalyticsTrackingID_PRE: 'G-6C6VBJ41X4',
  googleAnalyticsTrackingID_PRE_EU: 'G-7CVG7GG3CH',
  googleAnalyticsTrackingID_PROD_EU: 'G-4BZYDB3JGZ',
  featureFlags: [
    {
      // STREAM_FEATURE_FLAG_MT_ENABLED: ['DEV1', 'ENGINEER', 'SIT1', 'UNKNOWN']
    }
  ]
};

if (window.console) {
  window.console.log = function () {};
}
