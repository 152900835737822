import { Inject, Injectable } from '@angular/core';
import { GPSystemService } from '@stream/libs/console';
import { LPSystemService } from '@stream/libs/portal';
import { BehaviorSubject, Observable, ReplaySubject, Subject, of, timer } from 'rxjs';
import { buffer, catchError, finalize, map, switchMap, take } from 'rxjs/operators';

import {
  Action,
  GpRoleTypeEnum,
  ROLE_TYPE_CODE_MAP,
  RoleCodeEnum,
  SecurityPermission
} from '../../../console/model/permission.model';
import { STREAM_CONFIG, StreamConfig } from '../../common.type';
import { FeatureItem, FeatureStatus } from '../../models/src';

export type PermissionFeature = {
  [featureKey: string]: Pick<FeatureItem, 'status' | 'display'>;
};

export interface PermissionTenantFeature {
  tenantId: string;
  features: PermissionFeature;
}

export type PermissionAction = {
  [actionKey: string]: Pick<Action, 'effect' | 'actionMenu'>;
};

export interface Permission {
  status: 'success' | 'error';
  actions: PermissionAction;
  features: PermissionFeature;
  roles: PermissionRole;
}

enum PermissionFeatureSourceEnum {
  REQUEST = 'REQUEST',
  ENV = 'ENV',
  CACHE = 'CACHE'
}

enum PermissionActionSourceEnum {
  REQUEST = 'REQUEST',
  CACHE = 'CACHE'
}

export type PermissionFeatureSourceType = `${PermissionFeatureSourceEnum}`;

export type PermissionActionSourceType = `${PermissionActionSourceEnum}`;

export class PermissionRole {
  roleList: GpRoleTypeEnum[];
  constructor(roleList?: GpRoleTypeEnum[]) {
    this.roleList = roleList || [];
  }

  get empty() {
    return !this.roleList.length;
  }

  get roleCodes() {
    return this.transform2RoleCodes(this.roleList);
  }

  transform2RoleCodes(roleTypes: GpRoleTypeEnum[]) {
    const roleCodes: RoleCodeEnum[] = [];
    roleTypes.forEach(roleType => {
      Object.entries(ROLE_TYPE_CODE_MAP).forEach(([roleCode, types]) => {
        if (types.includes(roleType) && !roleCodes.includes(roleCode as RoleCodeEnum)) {
          roleCodes.push(roleCode as RoleCodeEnum);
        }
      });
    });
    return roleCodes;
  }

  hasRoles(roleCodes: RoleCodeEnum[] = []) {
    return roleCodes.some(roleCode => this.roleCodes.includes(roleCode));
  }

  isRole(roleCode: RoleCodeEnum) {
    return this.roleCodes.includes(roleCode);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  originalTenantId$ = new ReplaySubject<string>(1);

  private permissonCache$ = new BehaviorSubject<Permission | null>(null); // should not include features.

  private featureCache$ = new ReplaySubject<PermissionTenantFeature>(200); // should include features from  permissions.

  private permissionResultSubject = new Subject<Permission>();

  private pendingPermissions = false;

  private featureResultSubject = new Subject<PermissionFeature>();

  private pendingFeatures = false;

  private _localFeatures?: PermissionFeature;

  private get localFeatures() {
    if (!this._localFeatures) {
      const config = this.streamConfig.environment.features || [];
      const currentEnv = this.streamConfig.environment.currentEnv;
      const mergedConfig = config.reduce(
        (prev, item) => {
          return { ...prev, ...item };
        },
        {} as { [featrueKey: string]: string[] }
      );
      this._localFeatures = Object.entries(mergedConfig).reduce((prev, [featureKey, envs]) => {
        prev[featureKey] = envs.includes(currentEnv)
          ? { status: FeatureStatus.ON, display: true }
          : { status: FeatureStatus.OFF, display: false };
        return prev;
      }, {} as PermissionFeature);
    }
    return this._localFeatures;
  }

  private queryPermissions: () => Observable<SecurityPermission>;

  private queryfeatures: (params?: {
    tenantId?: string;
    includeSystemDefault?: boolean;
  }) => Observable<{ features?: FeatureItem[]; status?: string }>;

  private tenantFeatures$ = this.featureCache$.asObservable().pipe(
    buffer(timer(0)),
    take(1),
    map(values => {
      const tenantIdSet = new Set(values.map(value => value.tenantId));
      return values.reduceRight((prev, value) => {
        if (tenantIdSet.has(value.tenantId)) {
          tenantIdSet.delete(value.tenantId);
          prev.unshift(value);
        }
        return prev;
      }, [] as PermissionTenantFeature[]);
    })
  );

  constructor(
    private gpSystemService: GPSystemService,
    private lpSystemService: LPSystemService,
    @Inject(STREAM_CONFIG) private streamConfig: StreamConfig
  ) {
    this.streamConfig.tenantInfo$.pipe(take(1)).subscribe(({ tenantId }) => {
      this.originalTenantId$.next(tenantId);
    });
    switch (this.streamConfig.source) {
      case 'GP':
        this.queryfeatures = this.gpSystemService.getSystemFeatureFlags.bind(this.gpSystemService);
        this.queryPermissions = this.gpSystemService.getSecurityPermissions.bind(
          this.gpSystemService
        );
        break;
      case 'LP':
        this.queryfeatures = this.lpSystemService.getSystemFeatureFlags.bind(this.lpSystemService);
        this.queryPermissions = () => of({} as any);
        break;
      default:
        this.queryfeatures = () => of({} as any);
        this.queryPermissions = () => of({} as any);
    }
  }

  private updatePermissionCache(permissions: Permission) {
    this.permissonCache$.next(permissions);
  }

  private updateFeatureCache(features: PermissionFeature, tenantId?: string) {
    if (tenantId) {
      this.featureCache$.next({
        tenantId: tenantId,
        features
      });
      return;
    }

    this.originalTenantId$.pipe(take(1)).subscribe(originalTenantId => {
      this.featureCache$.next({
        tenantId: originalTenantId,
        features
      });
    });
  }

  private requstPermissions() {
    return new Observable<Permission>(subscriber => {
      if (this.pendingPermissions) {
        this.permissionResultSubject.pipe(take(1)).subscribe(subscriber);
      } else {
        this.pendingPermissions = true;
        this.queryPermissions()
          .pipe(
            finalize(() => {
              this.pendingPermissions = false;
            }),
            catchError(() => of({} as SecurityPermission)),
            map(data => {
              if (data.status === 'success') {
                const permissions = {
                  status: 'success' as const,
                  actions: this.parseActions(data.actionList),
                  features: this.parseFeatures(data.features),
                  roles: new PermissionRole(data.rawRoleList)
                };
                this.updateFeatureCache(permissions.features);
                this.updatePermissionCache(permissions);
                return permissions;
              }
              return {
                status: 'error' as const,
                actions: {},
                features: {},
                roles: new PermissionRole()
              };
            })
          )
          .subscribe({
            next: data => {
              subscriber.next(data);
              this.permissionResultSubject.next(data);
            },
            error: err => {
              subscriber.error(err);
            },
            complete: () => {
              subscriber.complete();
            }
          });
      }
    });
  }

  private requestFeatures(params?: { tenantId?: string }) {
    return new Observable<PermissionFeature>(subscriber => {
      if (this.pendingFeatures) {
        this.featureResultSubject.pipe(take(1)).subscribe(subscriber);
      } else {
        this.pendingFeatures = true;
        this.queryfeatures({
          ...params,
          includeSystemDefault: true
        })
          .pipe(
            finalize(() => {
              this.pendingFeatures = false;
            }),
            catchError(() => of({} as { features?: FeatureItem[]; status?: string })),
            map(({ features, status }) => {
              if (status === 'SUCCESS') {
                const parsedFeatures = this.parseFeatures(features);
                this.updateFeatureCache(parsedFeatures, params?.tenantId);
                return parsedFeatures;
              }
              return {};
            })
          )
          .subscribe({
            next: features => {
              subscriber.next(features);
              this.featureResultSubject.next(features);
            },
            error: err => {
              subscriber.error(err);
            },
            complete: () => {
              subscriber.complete();
            }
          });
      }
    });
  }

  private parseFeatures(features?: FeatureItem[]): PermissionFeature {
    if (!Array.isArray(features)) {
      return {};
    }
    const extendFeature: PermissionFeature = {
      LITE_GP: {
        display: true,
        status: FeatureStatus.OFF
      }
    };
    const resFeature = (features || []).reduce((prev, feature) => {
      prev[feature.featureKey] = { status: feature.status, display: feature.display };
      return prev;
    }, {} as PermissionFeature);

    return {
      ...extendFeature,
      ...resFeature
    };
  }

  private parseActions(actionList?: Action[]): PermissionAction {
    if (!Array.isArray(actionList)) {
      return {};
    }
    return (actionList || []).reduce((prev, action) => {
      prev[action.actionKey] = { effect: action.effect, actionMenu: action.actionMenu };
      return prev;
    }, {} as PermissionAction);
  }

  private getCachedPermissions(): Observable<Permission> {
    const permissionCacheValue = this.permissonCache$.getValue();
    if (permissionCacheValue) {
      // should merge features from featureCache.
      return this.getCacheFeaturesByTenantId().pipe(
        map(tenantFeature => {
          return {
            ...permissionCacheValue,
            features: tenantFeature?.features || {}
          };
        })
      );
    }
    return this.requstPermissions();
  }

  private getCachedFeatures(params?: { tenantId?: string }): Observable<PermissionFeature> {
    return this.getCacheFeaturesByTenantId(params?.tenantId).pipe(
      switchMap(tenantFeature => {
        if (tenantFeature) {
          return of(tenantFeature.features);
        }
        return this.requestFeatures(params);
      })
    );
  }

  private getCacheFeaturesByTenantId(tenantId?: string) {
    if (!tenantId) {
      return this.originalTenantId$.pipe(
        switchMap(originalTenantId => {
          return this.tenantFeatures$.pipe(
            map(tenantFeatures => {
              return tenantFeatures.find(
                tenantFeature => tenantFeature.tenantId === originalTenantId
              );
            })
          );
        }),
        take(1)
      );
    }
    return this.tenantFeatures$.pipe(
      map(tenantFeatures => {
        return tenantFeatures.find(tenantFeature => tenantFeature.tenantId === tenantId);
      })
    );
  }

  getFeatures(params?: {
    tenantId?: string;
    dataSource?: PermissionFeatureSourceType;
  }): Observable<PermissionFeature> {
    const { dataSource = PermissionFeatureSourceEnum.CACHE, ...rest } = params || {};
    if (dataSource === PermissionFeatureSourceEnum.CACHE) {
      return this.getCachedFeatures(rest);
    }
    if (dataSource === PermissionFeatureSourceEnum.REQUEST) {
      return this.requestFeatures(rest);
    }
    return of(this.localFeatures);
  }

  getFeatureEnabled(params: {
    featureKey: string;
    tenantId?: string;
    dataSource?: PermissionFeatureSourceType;
  }): Observable<boolean> {
    const { featureKey, ...rest } = params;
    return this.getFeatures(rest).pipe(
      map(features => {
        const feature = features[featureKey];
        // for environment feature flags, if not config featureKey, should return true.
        if (params.dataSource === PermissionFeatureSourceEnum.ENV && !feature) {
          return true;
        }
        return feature ? feature.status === FeatureStatus.ON : false;
      })
    );
  }

  getFeatureVisible(params: {
    featureKey: string;
    tenantId?: string;
    dataSource?: PermissionFeatureSourceType;
  }): Observable<boolean> {
    const { featureKey, ...rest } = params;
    return this.getFeatures(rest).pipe(
      map(features => {
        const feature = features[featureKey];
        if (!feature) {
          // for environment feature flags, if not config featureKey, should return true.
          if (params.dataSource === PermissionFeatureSourceEnum.ENV) return true;
          return false;
        }
        return feature.display;
      })
    );
  }

  getPermissions(params?: { dataSource?: PermissionActionSourceType }): Observable<Permission> {
    if (params?.dataSource === PermissionActionSourceEnum.REQUEST) {
      return this.requstPermissions();
    }
    return this.getCachedPermissions();
  }

  getActionAllow(params: {
    actionKey: string;
    dataSource: PermissionActionSourceType;
  }): Observable<boolean> {
    const { actionKey, ...rest } = params;
    return this.getPermissions(rest).pipe(
      map(({ actions }) => {
        const action = actions[actionKey];
        return action ? action.effect === 'ALLOW' : false;
      })
    );
  }

  hasRoles(roleCodes: RoleCodeEnum[]) {
    return this.getCachedPermissions().pipe(map(({ roles }) => roles.hasRoles(roleCodes)));
  }
}
