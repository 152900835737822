import { Component, HostBinding } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FieldWrapper } from '@ngx-formly/core';
import { isQuillHasContent } from '@stream/utils';

@Component({
  selector: 'stream-checkbox-group-wrapper',
  templateUrl: './checkbox-group-wrapper.component.html',
  styleUrls: ['./checkbox-group-wrapper.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    }
  ]
})
export class CheckboxGroupWrapperComponent extends FieldWrapper {
  @HostBinding('class.vertical')
  get isVertical() {
    return (this.to as any).layout === 'inline';
  }

  get showTooltips() {
    return isQuillHasContent(this.to?.['tooltip']);
  }
}
