import { Component, Input } from '@angular/core';

@Component({
  selector: 'stream-tooltips',
  template: `
    <mat-icon
      class="!text-lg text-inherit"
      #icon
      nz-popover
      nzPopoverOverlayClassName="max-w-xl"
      nzPopoverPlacement="bottom"
      [nzPopoverContent]="info"
      >info_outlined</mat-icon
    >

    <ng-template #info>
      <stream-quill-view
        [style]="{
          maxWidth: '480px',
          display: 'block',
          maxHeight: '600px',
          overflow: 'auto'
        }"
        [content]="content"
        format="json"
      ></stream-quill-view>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
        align-items: center;
      }
    `
  ]
})
export class TooltipsComponent {
  @Input()
  content!: string;
}
