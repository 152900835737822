import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GpPlatformApi, LpPlatformApi, Restful } from '@stream/models';
import { Subject } from 'rxjs';
import { map, repeatWhen, shareReplay } from 'rxjs/operators';
import { STREAM_CONFIG, StreamConfig } from '../../common.type';

@Injectable({
  providedIn: 'root'
})
export class ProfileSettingService {
  constructor(
    private http: HttpClient,
    @Inject(STREAM_CONFIG) public config: StreamConfig
  ) {}

  updateTenantProfileSettings$ = new Subject<void>();

  tenantProfileSettings$ = this.getTenantSettings({
    settingKeys: [
      'DOCUMENT_INDIVIDUAL_PROFILE',
      'DOCUMENT_ENTITY_PROFILE',
      'DOCUMENT_ENTITY_MEMBER'
    ]
  }).pipe(
    repeatWhen(() => this.updateTenantProfileSettings$),
    map(({ data }) => {
      let res: { [key: string]: any } = {};

      data.forEach(item => {
        try {
          res[item.settingKey] = { ...item, ...{ settingValue: JSON.parse(item.settingValue) } };
        } catch (e) {
          res[item.settingKey] = { ...item, ...{ settingValue: null } };
          console.error('Analysis Tenant setting error:', e);
        }
      });

      return res;
    }),
    shareReplay(1)
  );

  getTenantSettings(params: { settingKeys: string[] }) {
    return this.http.get<Restful<{ settingKey: string; settingValue: string }[]>>(
      this.config.source === 'GP' ? GpPlatformApi.tenantSetting : LpPlatformApi.TenantSettings,
      {
        params
      }
    );
  }
}
