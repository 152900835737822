import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedService } from '@stream/shared';
import { isQuillHasContent } from '@stream/utils';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { MatSelectChange } from '@angular/material/select';
import { MandatoryDocFile, Tag, TagCountry } from '@stream/models';
import {
  MandatoryDocItem,
  MandatoryDocOptions,
  MandatoryDocTagOption
} from '../mandatory-doc.interface';
import { MandatoryDocService } from '../mandatory-doc.service';

@Component({
  selector: 'stream-mandatory-doc-item',
  templateUrl: './mandatory-doc-item.component.html',
  styleUrls: ['./mandatory-doc-item.component.scss']
})
export class MandatoryDocItemComponent implements OnInit, OnDestroy {
  constructor(
    public mandatoryDocService: MandatoryDocService,
    private sharedService: SharedService
  ) {}

  @Input()
  md!: MandatoryDocItem;

  @Input()
  config!: MandatoryDocOptions;

  @Input()
  form!: FormGroup;

  tagList$ = new BehaviorSubject<MandatoryDocTagOption[]>([]);

  tagValueChange = new BehaviorSubject<string | null>(null);

  fileValueChangeSub!: Subscription | undefined;

  countryList$ = this.tagValueChange.pipe(
    switchMap(() => this.tagList$),
    switchMap(list => {
      // #STREAM-8758 cause tag's id may not exist in the list, so we need to use name to match it.
      const tag = list.find(t => t.name === this.form.get('tag')?.value);
      if (!tag) {
        return of([]);
      }
      return this.sharedService.countryList.pipe(
        map(countries =>
          countries
            .filter(({ countryAlpha2Code }) =>
              (tag.countryAlpha2Codes || []).includes(countryAlpha2Code)
            )
            .map(item => ({
              ...item,
              label: item.countryName,
              value: item.countryAlpha2Code
            }))
        )
      );
    })
  );

  get isTagEmpty() {
    return !this.form.get('tag')?.value;
  }

  get isErrorMsgShow() {
    const fileCtr = this.form.get('file');
    return fileCtr && fileCtr.dirty && !(fileCtr.value && fileCtr.value.length);
  }

  get hasFile() {
    const fileCtr = this.form.get('file');
    return fileCtr && fileCtr.value && fileCtr.value.length > 0;
  }

  needShowTooltip = false;

  isReusedPanelOpen = false;

  showTooltip = (description: string) => isQuillHasContent(description);

  ngOnInit(): void {
    this.needShowTooltip = this.showTooltip(this.md.documentDescription);
    this.getTagList();
  }

  ngOnDestroy(): void {
    if (this.fileValueChangeSub) {
      this.fileValueChangeSub.unsubscribe();
    }
  }

  getTagList() {
    const { fundMandatoryDocId, tags } = this.md || {};
    if (tags.length > 0) {
      this.setTags(tags);
      this.loadInitValue();
      this.registerFileChangeCallback();
      return;
    }
    const params: { fundMandatoryDocId: string; groupName?: string } = {
      fundMandatoryDocId: fundMandatoryDocId ?? ''
    };
    if (this.config.groupName) {
      params.groupName = this.config.groupName;
    }
    this.mandatoryDocService
      .getTenantTags(params)
      .pipe(
        map(res =>
          (res.data || []).map(item => ({
            ...item,
            label: item.name,
            value: item.id
          }))
        )
      )
      .subscribe(list => {
        this.setTags(list);
        this.loadInitValue();
        this.registerFileChangeCallback();
      });
  }

  loadInitValue() {
    const { loadInitValue } = this.config;
    const { files = [] } = this.md;
    // just run this once, so set the form dirty.
    if (loadInitValue && files[0] && !this.form.dirty) {
      const { tag, country, ...rest } = files[0];
      const _tag = tag && (this.tagList$.value ?? []).some((t) => t.id === tag.id) ? tag : null
      this.form.patchValue({
        tag: _tag?.name,
        country: _tag ? country?.id : null,
        file: [{ ...rest }]
      });
      if (_tag) {
        this.form.get('tag')?.disable();
      }
      if (_tag && country) {
        this.form.get('country')?.disable();
      }
      this.form.markAsDirty();
    }
  }

  setTags(list: MandatoryDocTagOption[]) {
    this.tagList$.next(list);
    // cache the tags
    this.md.tags = list;
  }

  registerFileChangeCallback() {
    this.fileValueChangeSub = this.form.get('file')?.valueChanges.subscribe(value => {
      const tagCtr = this.form.get('tag');
      const countryCtr = this.form.get('country');
      if (tagCtr) {
        if (tagCtr.disabled) {
          tagCtr.enable();
        }
        tagCtr.reset();
      }
      if (countryCtr) {
        if (countryCtr.disabled) {
          countryCtr.enable();
        }
        countryCtr.reset();
      }
    });
  }

  onReusedPanelClosed($event: any) {
    this.isReusedPanelOpen = false;
  }

  onSelectChange(v: { file: MandatoryDocFile; country?: TagCountry; tag: Tag; index: number }) {
    const { tag, country, file } = v;
    const isTagMatched = tag && this.md.tags.some(t => t.id === tag?.id);
    const isCountryMatched = isTagMatched
      ? country &&
        this.md.tags.some(t => t.id === tag.id && t.countryAlpha2Codes.includes(country.id))
      : false;
    this.form.patchValue({
      file: file ? [file] : [],
      tag: isTagMatched && tag ? tag.name : null,
      country: isCountryMatched && country ? country.id : null
    });
    if (isTagMatched) {
      this.form.get('tag')?.disable();
    }
    if (isCountryMatched && country) {
      this.form.get('country')?.disable();
    }
    this.form.markAsDirty();
  }

  onTagChange($event: MatSelectChange) {
    const countryCtr = this.form.get('country');
    if (countryCtr) {
      countryCtr.reset();
    }
    this.tagValueChange.next($event.value);
  }
}
