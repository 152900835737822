import { LocalStorageService } from 'ngx-webstorage';
import { Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { map, scan, shareReplay, switchMap, tap } from 'rxjs/operators';

import { EmailModule } from './email.module';

/* eslint-disable @typescript-eslint/ban-types */
const DEFAULT_DELAY_SECOND = 60;

const delayMap = new Map<string, number>();

const subjectMap = new Map<string, Subject<number>>();

const shareSubjectMap = new Map<Subject<number>, Observable<number>>();

function getTimerSubject(key: string) {
  if (subjectMap.has(key)) {
    return subjectMap.get(key) as ReplaySubject<number>;
  }
  const subject = new ReplaySubject<number>(1);
  subjectMap.set(key, subject);
  const _key = (key ?? '').toLowerCase();
  const value = Number(localStorage.getItem(`lp|${_key}`)) || 0;
  subject?.next(value);
  return subject;
}

/**
 * 发送邮件
 * @param storeKey 本地存储最后发送时间的键
 * @param delay 发送间隔 单位为秒
 */
export function SendEmail(storeKey: string, delay = DEFAULT_DELAY_SECOND) {
  function storeCurrentTime() {
    const now = Date.now();
    const key = (storeKey ?? '').toLowerCase();
    localStorage.setItem(`lp|${key}`, now.toString());
    getTimerSubject(storeKey)?.next(now);
  }
  return function (
    _target: Object,
    _propertyName: string,
    descriptor: TypedPropertyDescriptor<(...arg: any[]) => Observable<any>>
  ) {
    delayMap.set(storeKey, delay * 1000);
    const method = descriptor.value;
    descriptor.value = function (...arg) {
      const result = method?.apply(this, arg) ?? of(null);
      if (result instanceof Observable) {
        return result.pipe(
          tap(() => {
            storeCurrentTime();
          })
        );
      } else {
        storeCurrentTime();
        return result;
      }
    };
  };
}

/**
 * 邮件倒计时
 * @param storeKey 存储最后发送时间的键
 */
export function EmailDelay(storeKey: string) {
  return function (target: any, propertyName: string) {
    const delay = delayMap.get(storeKey) ?? DEFAULT_DELAY_SECOND * 1000;
    const subject = getTimerSubject(storeKey);

    const shareSubject =
      shareSubjectMap.get(subject) ??
      subject.pipe(
        map((lastSendTime) =>
          Math.ceil((delay - (Date.now() - lastSendTime)) / 1000)
        ),
        switchMap((remainingTime) =>
          timer(0, 1000).pipe(
            scan((seconds) => seconds - 1, remainingTime),
            map((seconds) => (seconds > 0 ? seconds : 0))
          )
        ),
        shareReplay(1)
      );
    shareSubjectMap.set(subject, shareSubject);
    target[propertyName] = shareSubject;
  };
}
