import { Component } from '@angular/core';

import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'stream-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  get viewMode() {
    return this.chatService.viewMode;
  }

  get opened() {
    return this.chatService.opened;
  }

  constructor(private chatService: ChatService) {}

  close(): void {
    this.chatService.checkPermission.next();
    this.chatService.opened = false;
  }
}
