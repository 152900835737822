import { Injectable } from '@angular/core';
import { AuthStoreKeyEnum } from '@stream/models';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  constructor(private localStorage: LocalStorageService) {}

  getBlob(url: string, cb: any) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    // Retrieve the AccessToken from local storage
    const accessToken = this.localStorage.retrieve(
      AuthStoreKeyEnum.AccessToken
    );
    const cleanedAccessToken = accessToken.replace(/^"|"$/g, '');

    const principalToken = this.localStorage.retrieve(
      AuthStoreKeyEnum.PrincipalToken
    );
    // Set the AccessToken header if it exists
    if (accessToken) {
      xhr.setRequestHeader('AccessToken', cleanedAccessToken);
      xhr.setRequestHeader('PrincipalToken', principalToken);
      xhr.setRequestHeader('target', 'web');
    }
    xhr.onload = function () {
      if (xhr.status === 200) {
        1;
        cb(xhr.response);
      }
    };
    xhr.send();
  }

  saveAs(blob: any, filename: string): void {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    body?.appendChild(link);
    link.click();
    body?.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }
}
