import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'stream-scenario-dialog',
  templateUrl: './scenario-dialog.component.html',
})
export class ScenarioDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type?: 'default' | 'fbo' | 'system';
      title?: string;
    } = { type: 'default' },
  ) {}
}
