import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslateComponent } from './translate.component';
import { TranslateConfig } from './translate.interface';
import { TranslateConfigProvider } from './translate.provider';
import { TranslateService } from './translate.service';

@NgModule({
  declarations: [TranslateComponent],
  imports: [CommonModule],
  providers: [TranslateService],
  exports: [TranslateComponent],
})
export class TranslateModule {
  static forRoot(
    config: TranslateConfig
  ): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [TranslateConfigProvider(config)],
    };
  }
}
