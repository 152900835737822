<mat-progress-bar
  [mode]="(panelService.loading | async) || loading ? 'indeterminate' : 'determinate'"
  class="mb-8"
></mat-progress-bar>
<ng-container *ngTemplateOutlet="info; context: custodian | async"> </ng-container>
<stream-documents-list
  [documentsList]="(custodian | async)?.documents ?? []"
  (checkListChange)="handleCheckListChange($event)"
/>

<ng-template #info let-info="info">
  <div class="ira-base-info">
    <div>
      Name:<span>{{ info?.name }}</span>
    </div>
    <div>
      Account type: <span>{{ formatAccountType(info?.accountType) }}</span>
    </div>
    <div>
      Account No: <span>*****{{ info?.accountNo }}</span>
    </div>
  </div>
</ng-template>

<ng-template #footer>
  <button
    mat-stroked-button
    color="primary"
    class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none"
    (click)="discard()"
  >
    {{ 'Discard' | buttonName | async }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none font-semibold"
    [disabled]="!allChecked"
    (click)="submit()"
  >
    {{ 'Continue' | buttonName | async }}
  </button>
</ng-template>
