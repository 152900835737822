export const savePdf = (
  data: ArrayBuffer,
  filename: string,
  view: boolean = false
): void => {
  const blob = new Blob([data], {
    type: 'application/pdf',
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  if (view) {
    link.setAttribute('target', '_self');
  } else {
    link.setAttribute('download', filename);
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadFileOrOpen = (params: {
  url: string;
  filename: string;
  open?: boolean;
  target: string;
  accessToken: string;
  principalToken: string;
}) => {
  const { url, open, filename, accessToken, principalToken, target } = params;
  // if (open) {
  //   window.open(url);
  // }
  const urlStr = url.replace(/\\/g, '/');
  const xhr = new XMLHttpRequest();
  xhr.open('GET', urlStr, true);
  xhr.responseType = 'blob';
  // Set the AccessToken header if it exists
  if (accessToken) {
    xhr.setRequestHeader('AccessToken', accessToken);
    xhr.setRequestHeader('PrincipalToken', principalToken);
    xhr.setRequestHeader('target', target);
  }
  xhr.onload = () => {
    if (xhr.status === 200) {
      savePdf(xhr.response, filename || 'download-file');
    }
  };
  xhr.send();
};
