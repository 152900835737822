import { Component, Inject, LOCALE_ID } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { Logic } from '../condition-builder.model';
import { ConditionBuilderService } from '../condition-builder.service';

@Component({
  selector: 'stream-logic-panel',
  templateUrl: './logic-panel.component.html',
  styleUrls: ['./logic-panel.component.less'],
})
export class LogicPanelComponent {
  constructor(
    private service: ConditionBuilderService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  expressionControl = this.service.expressionControl;

  logicSymbol$ = this.service.logicControl.valueChanges.pipe(
    startWith(this.service.logicControl.value),
    map((value: Logic) =>
      // @ts-ignore
      Object.keys(Logic).find(logicKey => value === Logic[logicKey]),
    ),
    shareReplay(1),
  );

  logics$ = this.expressionControl.valueChanges.pipe(
    startWith(this.expressionControl.value),
    switchMap((value: any[]) =>
      combineLatest(
        value
          .filter((_, i) => this.expressionControl.at(i).valid)
          .map(([key, operator, value]) =>
            this.service.getConfig(key).pipe(
              take(1),
              map(
                config =>
                  this.service
                    .getTypeConfig(config.type)
                    .formatter?.(config, operator, value, this.locale) ?? '',
              ),
            ),
          ),
      ),
    ),
  );
}
