import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'stream-mobile-normal-header',
  template: `
    <div class="header-layout flex h-14 w-full bg-white px-5">
      <div class="w-8 self-center">
        <button class="w-8 align-middle" (click)="backHandle()" mat-icon-button>
          <mat-icon fontSet="outlined"> arrow_back </mat-icon>
        </button>
      </div>

      <h3 class="flex-1 self-center text-center text-lg font-semibold">
        {{ pageTitle ?? ' ' }}
      </h3>
      <div class="w-8 self-center">
        <ng-content select=".handle-btn"></ng-content>
      </div>
    </div>
  `,
})
export class MobileNormalHeaderComponent {
  @Input() pageTitle?: string;
  @Input() backToWelcome?: boolean;
  @Output()
  toWelcome = new EventEmitter<boolean>();

  constructor(public location: Location) {}

  backHandle() {
    if (this.backToWelcome) {
      this.toWelcome.emit();
    } else {
      this.location.back();
    }
  }
}
