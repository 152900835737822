import { Component, OnDestroy } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NzSegmentedOption } from 'ng-zorro-antd/segmented';
import { Logic } from '../condition-builder.model';
import { ConditionBuilderService } from '../condition-builder.service';

@Component({
  selector: 'stream-condition-builder-nav',
  templateUrl: './condition-builder-nav.component.html',
  styleUrls: ['./condition-builder-nav.component.less'],
})
export class ConditionBuilderNavComponent implements OnDestroy {
  constructor(private service: ConditionBuilderService) {}

  segmentOptions: NzSegmentedOption[] = Object.keys(Logic).map(logicKey => ({
    // @ts-ignore
    value: Logic[logicKey],
    label: logicKey,
  }));

  config$ = this.service.config$;

  logicControl = this.service.logicControl;

  expressionControl = this.service.expressionControl;

  private expressionValueChangeHandler = this.expressionControl.valueChanges.subscribe(
    expressions => {
      if (expressions.length <= 1 && this.logicControl.value !== Logic.And) {
        this.logicControl.setValue(Logic.And);
      }
    },
  );

  get selectedIndex() {
    return this.segmentOptions.findIndex(
      ({ value }) => value === this.logicControl.value,
    );
  }

  disabledItem = (key: string) =>
    this.service.expressionControl.controls.some(
      control => (control as FormArray).at(0).value === key,
    );

  addExpression(key: string) {
    this.service.addExpression(key);
  }

  ngOnDestroy(): void {
    this.expressionValueChangeHandler.unsubscribe();
  }
}
