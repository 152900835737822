import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Bank,
  DataRoomDocument,
  Entity,
  ProfileDocumentConfigEnum,
  ProfileType,
  Store
} from '@stream/models';
import { objectFilterNull } from '@stream/utils';
import { of, zip } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';

import { MandatoryDocItemComponent, MandatoryDocService } from '../../../mandatory-doc';
import { ProfileService, ProfileSettingService } from '../../services';
import { profileFormOption } from '../../utils';
import { UploadRes } from '../upload-private-file/upload-private-file.component';

@Component({
  selector: 'stream-bank-dialog',
  templateUrl: './bank-dialog.component.html',
  styleUrls: ['./bank-dialog.component.scss']
})
export class BankDialogComponent implements OnInit {
  @ViewChild('mandatoryDocItem')
  mandatoryDocItemRef?: MandatoryDocItemComponent;

  document?: UploadRes;
  store = new Store({ type: 'session', namespace: 'scenario' });
  loading = false;
  contentView = '';

  form = this.fb.group({});

  mdConfig: any = {
    mandatoryDocs: [
      {
        documentName: 'Proof of bank account',
        documentDescription: '',
        isMandatory: true,
        mandatoryDocumentKey: '',
        multiple: false,
        files: this.data.bank?.referenceProofBankFileEntity?.documents
          ? [
              {
                tag: this.data.bank?.tag,
                country: this.data.bank?.country,
                ...(this.data.bank?.referenceProofBankFileEntity?.documents ?? {})
              }
            ]
          : [],
        tags: []
      }
    ],
    profileId: this.data.profileId,
    loadInitValue: true,
    checkAllFiles: true,
    errorMessage: '',
    allowReuse: true,
    allowDelete: true
  };
  mdForm = this.fb.group({
    tag: [null, []],
    country: [],
    file: []
  });

  proofOfBankAccountConfig: ProfileDocumentConfigEnum = ProfileDocumentConfigEnum.hidden;

  constructor(
    private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private dialogRef: MatDialogRef<BankDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      investorProfileType?: ProfileType;
      referenceInvestorProfileMetaEntityId?: Entity['id'];
      tenantId: string;
      bank?: Bank & {
        referenceProofBankFileEntity?: { documents: DataRoomDocument };
      };
      reModify?: boolean;
      profileId: string;
      isMember?: false;
    },
    private mandatoryDocService: MandatoryDocService,
    private profileSettingService: ProfileSettingService
  ) {
    this.initBankFrom();
    this.form.patchValue(data.bank ?? {});
  }

  get hideBank() {
    return this.proofOfBankAccountConfig === ProfileDocumentConfigEnum.hidden;
  }

  ngOnInit(): void {
    this.init();
  }

  // init bank form config
  initBankFrom() {
    const formGroup: { [key: string]: any } = {};
    const bankOptions = profileFormOption.bank;
    if (bankOptions) {
      for (const option of bankOptions) {
        formGroup[option.key] = [
          option.default ?? null,
          option.require ? Validators.required : null
        ];
      }
    }
    this.form = this.fb.group(formGroup);
  }

  init() {
    zip(
      this.getTooltipInfo(),
      this.profileSettingService.tenantProfileSettings$.pipe(take(1))
    ).subscribe(([tooltips, configs]) => {
      const bankCodeTip = tooltips.find((t: any) => t.policyType === 'INVESTOR_BANK_SUPPORT');
      const proofOfBankTip = tooltips.find((t: any) => t.policyType === 'PROOF_OF_BANK_ACCOUNT');
      this.mdConfig.mandatoryDocs.forEach((item: any) => {
        item.documentDescription = proofOfBankTip?.policyContext ?? '';
      });
      this.contentView = bankCodeTip?.policyContext ?? '';
      this.mdForm
        .get('tag')
        ?.addValidators(this.mandatoryDocService.tagRequireValidator(this.form));
      const { investorProfileType } = this.data;
      const settingKey =
        investorProfileType === ProfileType.Entity
          ? 'DOCUMENT_ENTITY_PROFILE'
          : 'DOCUMENT_INDIVIDUAL_PROFILE';
      const configValue = configs?.[settingKey]?.settingValue ?? {};

      this.mdConfig.checkAllFiles =
        configValue.proofOfBankAccount === ProfileDocumentConfigEnum.mandatory;
      if (this.data.reModify) {
        this.mdConfig.allowDelete = false;
      }
      this.proofOfBankAccountConfig = configValue.proofOfBankAccount;
      if (this.proofOfBankAccountConfig === ProfileDocumentConfigEnum.mandatory) {
        this.mdForm.get('file')?.addValidators(this.mandatoryDocService.fileRequiredValidator());
        this.mdConfig.errorMessage = 'This field is required.';
      }
    });
  }

  convertQueryData(data: Record<string, string[]>) {
    return [
      ...Object.keys(data).map(key => (data[key] ?? []).map(v => `${key}="${v}"`).join(' OR '))
    ]
      .filter(Boolean)
      .map(v => `(${v})`)
      .join(' AND ');
  }

  getTooltipInfo() {
    const query = {
      policyTypes: ['INVESTOR_BANK_SUPPORT', 'PROOF_OF_BANK_ACCOUNT']
    };
    return this.profileService
      .getBankCodePolicy({
        tenantId: this.data.tenantId,
        query: encodeURIComponent(this.convertQueryData(query))
      })
      .pipe(map(({ data }) => data?.gpPolicies ?? []));
  }

  submit() {
    if (this.hideBank) {
      this._saveBankWithoutMandatoryDoc();
    } else {
      this._saveBank();
    }
  }

  _saveBankWithoutMandatoryDoc() {
    if (this.form.invalid) return;
    const { bank, ...data } = this.data;
    const profile = this.store.get('profile');
    this.loading = true;
    const tagAndCountry = {
      tag: this.data.bank?.tag,
      country: this.data.bank?.country
    };
    of(tagAndCountry)
      .pipe(
        switchMap(tagAndCountry => {
          if (profile && data.reModify) {
            const { investorProfileMetaInfoBank } = profile;
            const fileCtr = this.mdForm.get('file');
            if (fileCtr?.value && fileCtr?.value.length > 0) {
              this.document = {
                ...fileCtr?.value[0],
                url: fileCtr?.value[0]?.downloadUrl
              };
            }
            if (this.document) {
              const { referenceProofBankFileEntity } = investorProfileMetaInfoBank;
              referenceProofBankFileEntity.documents = {
                ...referenceProofBankFileEntity.documents,
                ...this.document
              };
            }

            if (!tagAndCountry.country) {
              delete investorProfileMetaInfoBank.country;
            }

            if (!tagAndCountry.tag) {
              delete investorProfileMetaInfoBank.tag;
            }

            profile.investorProfileMetaInfoBank = {
              ...investorProfileMetaInfoBank,
              ...(objectFilterNull({
                ...this.form.value,
                ...tagAndCountry,
                referenceProofBankFileToken:
                  this.data.bank?.referenceProofBankFileEntity?.documents?.documentToken
              }) as any)
            };

            this.store.set('profile', profile);
            this.loading = false;
            return of({});
          }

          return this.profileService
            .patchBank(
              objectFilterNull({
                ...this.form.value,
                ...tagAndCountry,
                referenceProofBankFileToken:
                  this.data.bank?.referenceProofBankFileEntity?.documents?.documentToken
              }) as any,
              { id: bank?.id, ...data }
            )
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            );
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  _saveBank() {
    if (!this.hideBank) {
      Object.values(this.mdForm.controls).forEach(control => {
        control.updateValueAndValidity({ emitEvent: false });
        setTimeout(() => {
          control.markAsDirty();
          control.markAsTouched();
        });
      });
      if (!this.mdForm.valid || this.form.invalid) {
        return;
      }
    } else if (this.form.invalid) {
      return;
    }
    const { bank, ...data } = this.data;
    const profile = this.store.get('profile');
    this.loading = true;
    this.mandatoryDocItemRef?.tagList$
      .pipe(
        take(1),
        switchMap(tagList => {
          if (!this.mandatoryDocItemRef) {
            return of({ tagList, countryList: [] });
          }
          return this.mandatoryDocItemRef.countryList$.pipe(
            map(countryList => ({ tagList, countryList }))
          );
        }),
        switchMap(({ tagList, countryList }) => {
          const tag = tagList.find((t: any) => t.label === this.mdForm.get('tag')?.value);
          const country = countryList.find(
            (c: any) => c.value === this.mdForm.get('country')?.value
          );
          const documentToken = this.mdForm.get('file')?.value?.[0]?.documentToken;
          const tagAndCountry = {
            tag: tag
              ? {
                  id: tag.value,
                  name: tag.label
                }
              : null,
            country: country
              ? {
                  id: country.value,
                  name: country.label
                }
              : null
          };
          if (profile && data.reModify) {
            const { investorProfileMetaInfoBank } = profile;
            const fileCtr = this.mdForm.get('file');
            if (fileCtr?.value && fileCtr?.value.length > 0) {
              this.document = {
                ...fileCtr?.value[0],
                url: fileCtr?.value[0]?.downloadUrl
              };
            }
            if (this.document) {
              const { referenceProofBankFileEntity } = investorProfileMetaInfoBank;
              referenceProofBankFileEntity.documents = {
                ...referenceProofBankFileEntity.documents,
                ...this.document
              };
            }

            if (!tagAndCountry.country) {
              delete investorProfileMetaInfoBank.country;
            }

            if (!tagAndCountry.tag) {
              delete investorProfileMetaInfoBank.tag;
            }

            profile.investorProfileMetaInfoBank = {
              ...investorProfileMetaInfoBank,
              ...(objectFilterNull({
                ...this.form.value,
                ...tagAndCountry,
                referenceProofBankFileToken: documentToken
              }) as any)
            };

            this.store.set('profile', profile);
            this.loading = false;
            return of({});
          }

          return this.profileService
            .patchBank(
              objectFilterNull({
                ...this.form.value,
                ...tagAndCountry,
                referenceProofBankFileToken: documentToken
              }) as any,
              { id: bank?.id, ...data }
            )
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            );
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  get contentH(): string {
    return `calc(${window?.innerHeight - 161}px - 20vh)`;
  }

  docUploaded(doc: UploadRes) {
    this.document = doc;
  }
}
