/**
 * The `streamPermission` directive is a powerful tool for controlling the visibility and behavior of DOM elements based on user permissions. It checks the user's permissions against a set of required permissions and decides whether to display the element, disable it, or show an alternative template (using the `else` clause).
 *
 * The directive provides a context for the template it is applied to. The context is an instance of the `PermissionContext` interface, which includes:
 *
 * - `$implicit`: An object containing optional `features`, `actions`, and `roles` properties. These properties represent the current user's permissions and can be used in the template to further customize the display of the element.
 * - `features`: An optional object representing the current user's features.
 * - `actions`: An optional object representing the current user's actions in permissions.
 * - `roles`: An optional object representing the current user's roles in permissions.
 * - `disabled`: A boolean indicating whether the element should be disabled.
 * - `tenantType`: An optional string representing the type of tenant.
 *
 * Example usage:
 * - Using for simple case:
 *   <button *streamPermission="'REPORTING'">Button</button>
 *   In this example, the button will only be displayed if the tenant's 'REPORTING' feature's  display is true.
 *
 * - Using for complex case:
 * <div *streamPermission="'R2.5, R9.1'">
 *   <button
 *     *streamPermission="'REPORTING, Action:ADD_OFFLINE_SUBSCRIPTION';
 *       baseOn:'FULL_GP';
 *       accessBy: false;
 *       let tenantType = tenantType;
 *       let features = features;
 *       let actions = actions;
 *       let disabeld = disabled"
 *     "
 *     [disabled]="disabled"
 *   >Button A</button>
 *   <button
 *      *streamPermission="'R9.1';
 *        accessBy: false";
 *        let roles = roles";
 *      [disabled]="roles?.accessible"
 *   >Button B</button>
 * </div>
 * In this example, If user has neither 'R2.5' role nor 'R9.1' role, the div will be hidden.
 * if user has 'R2.5' role or 'R9.1' role:
 *  - Button A will be enabled if the tenant's 'REPORTING' feature's status is ON and the 'ADD_OFFLINE_SUBSCRIPTION' action's effect is ALLOW;
 *    otherwise, it will be disabled.
 *  - Button B will be enabled if the user has the 'R9.1' role; otherwise, it will be disabled.
 */
import {
  Directive,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { cloneDeep } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  forkJoin,
  map,
  shareReplay,
  skipUntil,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { ROLE_TYPE_CODE_MAP, RoleCodeEnum } from '../../console/model/permission.model';
import { STREAM_CONFIG, StreamConfig } from '../common.type';
import {
  PermissionAction,
  PermissionActionSourceType,
  PermissionFeature,
  PermissionFeatureSourceType,
  PermissionRole,
  PermissionService
} from '../utils/service';

class PermissionContextRole extends PermissionRole {
  accessible = false;
}

interface PermissionContext {
  $implicit: {
    features?: Partial<PermissionFeature>;
    actions?: Partial<PermissionAction>;
    roles?: PermissionContextRole;
  };

  features?: Partial<PermissionFeature>;

  actions?: Partial<PermissionAction>;

  roles?: PermissionContextRole;

  // for Feature Keys: if one of the features' status is not ON, the disabled is true.
  // for Action Keys: if one of the actions' effect is not ALLOW, the disabled is true.
  disabled: boolean;

  tenantType?: string;
}

@Directive({
  selector: '[streamPermission]',
  exportAs: 'streamPermission'
})
export class PermissionDirective implements OnInit, OnDestroy {
  private context: PermissionContext = { $implicit: {}, disabled: false };

  private _actionKeyPrifix = 'Action:';

  private _featureKeyPrifix = 'Feature:';

  // [featureKeys, actionKeys, roleCodes]
  private groupKeys: [string[], string[], RoleCodeEnum[]] = [[], [], []];

  destroyed$ = new Subject<void>();

  tenantId$ = new BehaviorSubject<string>('');

  delayForTenantId$ = new BehaviorSubject<boolean>(false);

  features$ = this.tenantId$.pipe(
    skipUntil(this.delayForTenantId$.pipe(filter(delay => !delay))),
    switchMap(tenantId => {
      const params: {
        dataSource: PermissionFeatureSourceType;
        tenantId?: string;
      } = {
        dataSource: this.streamPermissionSource
      };
      if (tenantId) {
        params.tenantId = tenantId;
      }
      return this.permissionService.getFeatures(params);
    }),
    shareReplay(1)
  );

  /**
   * `streamPermission` Input property determines the visibility of the host element based on the user's permissions.
   *
   * This property can accept one or more values, separated by commas, which represent different types of permissions:
   *
   * 1. Role Codes: These are codes like 'R2.5' which represent a specific role.
   * 2. Feature Keys: These are strings like 'INVESTMENT' or 'Feature:INVESTMENT'(start with a 'Feature:' prefix), which represent a specific feature.
   * 3. Action Keys: These are strings like 'Action:SUBSCRIPTION_DETAIL'(must start with a 'Action:' prefix) which represent a specific action.
   *
   * The directive will make a call to features/permissions API, check these values based on the response, it will show or hide the host element.
   *
   * Examples:
   *
   * - Using only Feature Key:
   *   <button *streamPermission="'INVESTMENT'">Button</button>
   *   In this example, the button will be visible only if the 'display' property of Feature Key 'INVESTMENT' is true.
   *
   * - Using only Action Key:
   *   <button *streamPermission="'Action:ADD_OFFLINE_SUBSCRIPTION'>Button</button>
   *   In this example, the button will be visible only if the 'effect' property of Action Key 'ADD_OFFLINE_SUBSCRIPTION' is ALLOW.
   *
   * - Using only  Role Codes:
   *   <button *streamPermission="'R2.5'">Button</button>
   *   In this example, the button will be visible only if the user has the role 'R2.5'(admin&owner).
   *
   * - Using multiple values:
   *   <button *streamPermission="'INVESTMENT, Action:ADD_OFFLINE_SUBSCRIPTION, R2.5'">Button</button>
   *   In this example, the button will be visible only if the 'display' property of Feature Key 'INVESTMENT' is true, and the 'effect' property of Action Key 'ADD_OFFLINE_SUBSCRIPTION' is ALLOW, and the user has the role 'R2.5'.
   */
  @Input() streamPermission?: string;

  /**
   * `streamPermissionOf` is an additional Input property for the directive, providing a more structured and explicit way to specify permissions.
   *
   * This property accepts an object with the following optional properties:
   *
   * 1. `actionKeys`: An array of strings, each representing a specific action. Example: ['ADD_OFFLINE_SUBSCRIPTION']
   * 2. `featureKeys`: An array of strings, each representing a specific feature. Example: ['INVESTMENT']
   * 3. `roleCodes`: An array of `RoleCodeEnum` values, each representing a specific role. Example: ['R2.5']
   *
   * the directive will make a call to features/permissions API, check these values based on the response, it will show or hide the host element.
   *
   * Examples:
   *
   * - Using only `actionKeys`:
   *   <button *streamPermission="let permissions of { actionKeys: ['ADD_OFFLINE_SUBSCRIPTION'] }">Button</button>
   *   In this example, the button will be visible only if the 'effect' property of Action Key 'ADD_OFFLINE_SUBSCRIPTION' is ALLOW.
   *
   * - Using `actionKeys` and `featureKeys`:
   *   <button *streamPermission="let permissions of { actionKeys: ['ADD_OFFLINE_SUBSCRIPTION'], featureKeys: ['INVESTMENT'] }">Button</button>
   *   In this example, the button will be visible if the the 'effect' property of Action Key 'ADD_OFFLINE_SUBSCRIPTION' is ALLOW and the 'display' property of Feature Key 'INVESTMENT' is true.
   *
   * - Using all three properties:
   *   <button *streamPermission="let permissions of { actionKeys: ['ADD_OFFLINE_SUBSCRIPTION'], featureKeys: ['INVESTMENT'], roleCodes: ['R2.5'] }">Button</button>
   *   In this example, the button will be visible if the user has all of the specified permissions.
   */
  @Input()
  streamPermissionOf?: {
    actionKeys?: string[];
    featureKeys?: string[];
    roleCodes?: RoleCodeEnum[];
  };

  /**
   * `streamPermissionBaseOn` Input property represents the tenant type(s) that the permissions should be checked against.
   *
   * This property can accept one or more `tenantType` values, separated by commas, such as 'FULL_GP', 'LITE_GP', 'UNREGULATED_MARKETPLACE', or 'FULL_GP, UNREGULATED_MARKETPLACE'.
   *
   * If `baseOn` is provided,
   *  If the `baseOn` value includes the tenant's `tenantType`, the directive will proceed to check the permissions and show or hide the host element accordingly.
   *  If the `baseOn` value does not include the tenant's `tenantType`, the host element will be hidden directly without checking the permissions.
   *
   * If `baseOn` is not provided, the directive will directly check the permissions to determine the visibility of the host element, regardless of the tenant's `tenantType`.
   *
   * When `baseOn` is used, the `tenantType` can be accessed from the directive's context, as shown in the example below.
   *
   * Examples:
   *
   * - Using `baseOn` with a single tenant type:
   *   <button *streamPermission="'INVESTMENT'; baseOn:'FULL_GP'; let tenantType = tenantType">Button</button>
   *   In this example, the button will only be visible if the tenantType is 'FULL' and the user has the 'INVESTMENT' permission.
   *
   * - Using `baseOn` with multiple tenant types:
   *   <button *streamPermission="''; baseOn:'FULL_GP, UNREGULATED_MARKETPLACE'; let tenantType = tenantType">Button</button>
   *   In this example, the button will be visible if the tenantType is either 'FULL_GP' or 'UNREGULATED_MARKETPLACE'.
   */
  @Input()
  streamPermissionBaseOn?: string;

  /**
   * `streamPermissionAccessBy` can be either `false`, a string in the format of '[key:value]', or not provided at all.
   *
   * If `accessBy` is not provided, the directive will behave as follows:
   * - For feature keys, it will fetch the corresponding feature's 'display' field from the API response data. If the 'display' field is true, the host element will be shown; otherwise, it will be hidden.
   * - For action keys, it will fetch the corresponding action's 'effect' field from the API response data. If the 'effect' field is 'ALLOW', the host element will be shown; otherwise, it will be hidden.
   * - For Role Codes, the host element will be shown if the user has at least one of the roles; otherwise, it will be hidden.
   * - For tenant types provided in `baseOn`, the host element will be shown if `baseOn` includes the current tenant's tenantType; otherwise, it will be hidden.
   *
   * If `accessBy` is `false`, the host element will always be shown. You can use the directive's context to access features, actions, roles, and tenantType for specific business logic.
   *
   * If `accessBy` is a string in the format of '[key:value]', it can only be used with Feature Key and Action Key (and only supports single-type multiple keys when multiple keys are passed in). It is used to control the visibility of the host element based on the specified field and its value in the API response data.
   *
   * Examples:
   *
   * - Using `accessBy` with a string:
   *   <button *streamPermission="'LITE_GP'; accessBy: 'status:OFF'">Button</button>
   *   In this example, the host element will be shown if the 'status' field of the 'LITE_GP' feature in the API response data is 'OFF'.
   *
   * - Using `accessBy` with `false`:
   *   <button *streamPermission="'Action:ADD_OFFLINE_SUBSCRIPTION'; accessBy: false" let actions = actions let disabled = disabled>Button</button>
   *   In this example, the host element will always be shown, regardless of the 'ADD_OFFLINE_SUBSCRIPTION' action.
   */
  @Input()
  streamPermissionAccessBy?: string | false;

  /**
   * `streamPermissionSource`  specifies the source of data that the directive should use to determine feature keys, action keys, and role codes.
   * It can be either 'CACHE', 'REQUEST', 'ENV', or not provided at all. If not provided, it defaults to 'CACHE'.
   *
   * Here's how the directive behaves based on the value of `source`:
   * - 'CACHE': The directive will use the data from the last API response.
   * - 'REQUEST': The directive will initiate a new API request to fetch the latest data.
   * - 'ENV': The directive will use the feature flags from the frontend environment file configuration.
   *          Note that this only applies when a Feature Key is provided.
   *
   * Examples:
   *
   * - Using `source` with 'CACHE':
   *   <button *streamPermission="'LITE_GP'; source: 'CACHE'">Button</button>
   *   In this example, the directive will use the cached data to determine the 'LITE_GP' feature key.
   *
   * - Using `source` with 'REQUEST':
   *   <button *streamPermission="'INVESTMENT'; source: 'REQUEST'">Button</button>
   *   In this example, the directive will initiate a new API request to determine the 'INVESTMENT' feature key.
   *
   *   <button *streamPermission="'Action:ADD_OFFLINE_SUBSCRIPTION'; source: 'REQUEST'">Button</button>
   *    In this example, the directive will initiate a new API request to determine the 'ADD_OFFLINE_SUBSCRIPTION' action key.
   *
   * - Using `source` with 'ENV' and a Feature Key:
   *   <button *streamPermission="'USER_MANAGEMENT'; source: 'ENV'">Button</button>
   *   In this example, the directive will use the feature flags from the frontend environment file to determine the 'USER_MANAGEMENT' feature key.
   */
  @Input()
  streamPermissionSource: PermissionFeatureSourceType | PermissionActionSourceType = 'CACHE';

  /**
   * `streamPermissionTenantId` is only applicable when a Feature Key is provided. It specifies the tenant whose feature data will be used for decision making.
   *
   * If `tenantId` is not provided, the directive will use the features data of the current tenant for decision making.
   *
   * If `tenantId` is provided as a string, the directive will use the features data of the provided tenant for decision making.
   *
   *
   * Examples:
   *
   * - Using `tenantId` with a static value:
   *   <button *permission="'REPORTING'; tenantId: '123'">Button</button>
   *   In this example, the directive will use the features data of '123' to determine the visibility of the button.
   *
   * - Using `tenantId` with an asynchronous value:
   *   (example: fetching product details for DOM rendering and using the `productTenantId` in the details data to determine the visibility of a DOM element,
   *   an falsy (like '' or null or undefined) can be passed in before `productTenantId` is obtained.
   *   Once the data is fetched, `productTenantId` can be passed in.)
   *
   *   ```html
   *   <button *permission="'REPORTING'; tenantId: (data$ | async)?.productTenantId">Button</button>
   *   ```
   *   ```ts
   *   data$ = this.getProductDetail().pipe(map(res => res.data));
   *   ```
   */
  @Input()
  set streamPermissionTenantId(tenantId: string | null | undefined) {
    if (!tenantId) {
      this.delayForTenantId$.next(true);
      return;
    }
    this.delayForTenantId$.next(false);
    this.tenantId$.next(tenantId);
  }

  /**
   * `streamPermissionElse` mirroring the functionality of the 'else' clause in Angular's `ngIf` directive. It can be used to display a different template when the primary condition fails.
   *
   * The `else` property accepts a template reference (usually defined with `ng-template`), and this template will be displayed if the primary permission check fails.
   *
   * Examples:
   *
   * - Using `else` to display an alternate button:
   *   <button *permission="'REPORTING'; else noPermission">Button</button>
   *   <ng-template #noPermission><button>Alternate Button</button></ng-template>
   *   In this example, if the 'REPORTING' feature display false, the 'Alternate Button' will be displayed instead of the primary button.
   *
   */
  @Input()
  streamPermissionElse?: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<PermissionContext>,
    private vc: ViewContainerRef,
    private permissionService: PermissionService,
    @Inject(STREAM_CONFIG) private streamConfig: StreamConfig
  ) {}

  ngOnInit() {
    this.groupKeys = this.getGroupKeys();

    this.streamConfig.tenantInfo$.pipe(take(1)).subscribe(({ tenantType }) => {
      this.context.tenantType = tenantType;
      if (
        !this.streamPermissionBaseOn ||
        this.streamPermissionAccessBy === false ||
        this.streamPermissionBaseOn.includes(tenantType)
      ) {
        this.checkByPermissions();
        return;
      }
      if (this.streamPermissionElse) {
        this.vc.createEmbeddedView(this.streamPermissionElse, this.context);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  checkByPermissions() {
    const [featureKeys, actionKeys, roleCodes] = this.groupKeys;
    const checkObservables: Observable<any>[] = [];
    if (featureKeys.length) {
      checkObservables.push(this.checkFeatures());
    }
    if (actionKeys.length || roleCodes.length) {
      checkObservables.push(this.getActions());
    }
    if (!checkObservables.length) {
      this.vc.createEmbeddedView(this.templateRef, this.context);
      return;
    }
    forkJoin(checkObservables).subscribe(result => {
      const accessible = result.every(Boolean);
      const ref = accessible ? this.templateRef : this.streamPermissionElse;
      if (!ref) return;
      const view = this.vc.createEmbeddedView(ref);
      this.context.$implicit = {
        features: this.context.features,
        actions: this.context.actions,
        roles: this.context.roles
      };
      this.context.disabled = this.context.disabled || false;
      view.context = this.context;
    });
  }

  isActionKey(key?: string) {
    return key?.startsWith(this._actionKeyPrifix);
  }

  isRoleCodes(value?: string) {
    return value && Object.keys(ROLE_TYPE_CODE_MAP).includes(value);
  }

  getGroupKeys(): [string[], string[], RoleCodeEnum[]] {
    if (this.streamPermissionOf) {
      const { featureKeys, actionKeys, roleCodes } = this.streamPermissionOf;
      return [featureKeys ?? [], actionKeys ?? [], roleCodes ?? []];
    }
    if (!this.streamPermission) return [[], [], []];
    const keys = this.streamPermission!.split(',').map(key => key.trim());
    const actionKeys = keys
      .filter(key => this.isActionKey(key))
      .map(key => key.split(':')[1].trim());
    const roleCodes = (keys as RoleCodeEnum[]).filter(this.isRoleCodes);
    const featureKeys: string[] = keys
      .filter(key => {
        return !this.isActionKey(key) && !this.isRoleCodes(key);
      })
      .map(key => {
        if (key.startsWith(this._featureKeyPrifix)) {
          return key.split(':')[1].trim();
        }
        return key;
      });
    return [featureKeys, actionKeys, roleCodes];
  }

  parseCustomAccessBy() {
    if (!this.streamPermissionAccessBy) return;
    const result = this.streamPermissionAccessBy.match(/([^:]*):(.*)/);
    if (result) {
      const [key, value] = result.slice(1);
      if (key && value) {
        return { key: key.trim(), value: value.trim() };
      }
    }
    console.error('streamPermission directive: accessBy format error, should be "key:value"');
    return;
  }

  getAccessibleByFeatures(featureKeys: string[], features: PermissionFeature) {
    if (this.streamPermissionAccessBy === false) return true;
    const { key, value } = this.parseCustomAccessBy() || { key: 'display', value: 'true' };
    return featureKeys.every(featureKey => {
      return (features as any)[featureKey]?.[key]?.toString() === value;
    });
  }

  checkFeatures() {
    const featureKeys = this.groupKeys[0];
    return this.features$.pipe(
      take(1),
      map(features => {
        return featureKeys.reduce((prev, featureKey) => {
          prev[featureKey] = features[featureKey];
          return prev;
        }, {} as PermissionFeature);
      }),
      tap(features => {
        this.context.features = features;
        if (featureKeys.some(featureKey => features[featureKey]?.status !== 'ON')) {
          this.context.disabled = true;
        }
      }),
      map(features => {
        return this.getAccessibleByFeatures(featureKeys, features);
      }),
      takeUntil(this.destroyed$)
    );
  }

  getAccessibleByActions(actionKeys: string[], actions: PermissionAction) {
    if (this.streamPermissionAccessBy === false) return true;
    const { key, value } = this.parseCustomAccessBy() || { key: 'effect', value: 'ALLOW' };
    return actionKeys.every(actionKey => {
      return (actions as any)[actionKey]?.[key]?.toString() === value;
    });
  }

  getActions() {
    const actionKeys = this.groupKeys[1];
    const roleCodes = this.groupKeys[2];
    return this.permissionService
      .getPermissions({
        dataSource: this.streamPermissionSource as PermissionActionSourceType
      })
      .pipe(
        map(({ actions, roles }) => {
          return {
            actions: actionKeys.reduce((prev, actionKey) => {
              prev[actionKey] = actions[actionKey];
              return prev;
            }, {} as PermissionAction),
            roles
          };
        }),
        tap(({ actions, roles }) => {
          if (actionKeys.length) {
            this.context.actions = actions;
            if (actionKeys.some(actionKey => actions[actionKey]?.effect !== 'ALLOW')) {
              this.context.disabled = true;
            }
          }
          if (roleCodes.length) {
            this.context.roles = cloneDeep(roles) as PermissionContextRole;
            this.context.roles.accessible = roles.hasRoles(roleCodes);
          }
        }),
        map(({ actions, roles }) => {
          if (actionKeys.length && !this.getAccessibleByActions(actionKeys, actions)) {
            return false;
          }
          if (!roleCodes.length || this.streamPermissionAccessBy === false) {
            return true;
          }
          return roles.hasRoles(roleCodes);
        }),
        takeUntil(this.destroyed$)
      );
  }

  static ngTemplateContextGuard(
    directive: PermissionDirective,
    ctx: unknown
  ): ctx is PermissionContext {
    return true;
  }
}
