import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import {
  FieldWrapper,
  FormlyConfig,
  FormlyFieldConfig,
  ɵdefineHiddenProp as defineHiddenProp
} from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { FormlyWrapperFormField } from '@ngx-formly/material/form-field/form-field.wrapper';
import { isQuillHasContent } from '@stream/utils';
import { Subject } from 'rxjs';

interface MatFormlyFieldConfig extends FormlyFieldConfig {
  __formField__?: FormlyWrapperFormField;
}

/**
 * source code
 * https://github.com/ngx-formly/ngx-formly/blob/v5/src/material/form-field/src/form-field.wrapper.ts
 */
@Component({
  selector: 'stream-formly-field-wrapper',
  template: `
    <mat-form-field
      [hideRequiredMarker]="true"
      [floatLabel]="to['floatLabel']"
      [appearance]="to['appearance']"
      [color]="to['color']"
      nz-popover
      [nzPopoverContent]="showTooltips ? info : undefined"
      nzPopoverPlacement="topLeft"
    >
      <ng-container #fieldComponent></ng-container>
      <ng-template #info>
        <stream-quill-view
          [style]="{
            maxWidth: '480px',
            display: 'block'
          }"
          [content]="to['tooltip']"
          format="json"
        ></stream-quill-view>
      </ng-template>
      <mat-label class="inline-flex items-center" *ngIf="to['label'] && to?.['hideLabel'] !== true">
        {{ to['label'] }}
        <mat-icon *ngIf="showTooltips" class="!text-lg text-inherit" #icon>info_outlined</mat-icon>
        <span
          *ngIf="to?.['required'] && to?.['hideRequiredMarker'] !== true"
          aria-hidden="true"
          class="mat-form-field-required-marker"
          >*</span
        >
      </mat-label>

      <ng-container matPrefix *ngIf="to?.['prefix'] || to?.['_matPrefix']">
        <ng-container
          *ngTemplateOutlet="to?.['prefix'] ? to?.['prefix'] : to?.['_matPrefix']"
        ></ng-container>
      </ng-container>

      <ng-container matSuffix *ngIf="to?.['suffix'] || to?.['_matSuffix']">
        <ng-container
          *ngTemplateOutlet="to?.['suffix'] ? to?.['suffix'] : to?.['_matSuffix']"
        ></ng-container>
      </ng-container>

      <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
      <mat-hint *ngIf="to?.['description']" [id]="$any(null)">{{ to['description'] }}</mat-hint>
    </mat-form-field>
  `,
  styles: [
    `
      stream-formly-field-wrapper {
        .mat-mdc-form-field {
          width: 100%;
        }
      }
    `
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FormlyFieldWrapperComponent
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class FormlyFieldWrapperComponent
  extends FieldWrapper<MatFormlyFieldConfig>
  implements OnInit, OnDestroy, MatFormFieldControl<any>, AfterViewInit, AfterContentChecked
{
  /**
   * @deprecated should check after angular version upgrade or ngFormly version upgrade
   */
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true })
  // @ts-ignore
  override fieldComponent: ViewContainerRef;

  @ViewChild(MatFormField, { static: true }) formField!: MatFormField;

  stateChanges = new Subject<void>();
  _errorState = false;
  private initialGapCalculated = false;

  get showTooltips() {
    return isQuillHasContent(this.to?.['tooltip']);
  }

  constructor(
    private config: FormlyConfig,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private focusMonitor: FocusMonitor
  ) {
    super();
  }

  ngOnInit() {
    this.formField._control = this;
    defineHiddenProp(this.field, '__formField__', this.formField);

    const ref = this.config.resolveFieldTypeRef(this.formlyField);
    if (ref && !(ref.instance instanceof FieldType)) {
      console.warn(
        `Component '${ref.componentType.name}' must extend 'FieldType' from '@ngx-formly/material/form-field'.`
      );
    }

    // fix for https://github.com/angular/material2/issues/11437
    if (this.formlyField.hide && this.formlyField.templateOptions?.['appearance'] === 'outline') {
      this.initialGapCalculated = true;
    }

    this.focusMonitor.monitor(this.elementRef, true).subscribe(origin => {
      if (!origin && this.field.focus) {
        this.field.focus = false;
      }

      this.stateChanges.next();
    });
  }

  // NOTICE: formly field hide won't happen
  ngAfterContentChecked() {
    if (!this.initialGapCalculated || this.formlyField.hide) {
      return;
    }

    // this.formField.updateOutlineGap();
    this.initialGapCalculated = true;
  }

  ngAfterViewInit() {
    // temporary fix for https://github.com/angular/material2/issues/7891
    if (this.formField.appearance !== 'outline' && this.to?.['hideFieldUnderline'] === true) {
      const underlineElement = this.formField._elementRef.nativeElement.querySelector(
        '.mat-form-field-underline'
      );
      underlineElement && this.renderer.removeChild(underlineElement.parentNode, underlineElement);
    }
  }

  ngOnDestroy() {
    delete this.formlyField.__formField__;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {
    this.formlyField.focus = true;
    this.stateChanges.next();
  }

  get errorState() {
    const showError = this.options!.showError!(this);
    if (showError !== this._errorState) {
      this._errorState = showError;
      this.stateChanges.next();
    }

    return showError;
  }
  get controlType() {
    return this.to.type;
  }
  get focused() {
    return !!this.formlyField.focus && !this.disabled;
  }
  get disabled() {
    return !!this.to.disabled;
  }
  get required() {
    return !!this.to.required;
  }
  get placeholder() {
    return this.to.placeholder || '';
  }
  get shouldPlaceholderFloat() {
    return this.shouldLabelFloat;
  }
  get value() {
    return this.formControl.value;
  }
  get ngControl() {
    return this.formControl as any;
  }
  get empty() {
    return !this.formControl.value;
  }
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get formlyField() {
    return this.field as MatFormlyFieldConfig;
  }
}
