import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { STREAM_CONFIG, StreamLPConfig } from '@stream/libs/common/common.type';
import { NotificationService } from '@stream/ngx-utils';
import { finalize, map } from 'rxjs/operators';

import {
  IDocumentType,
  IResIRADocumentType,
  ProfileIRAService
} from '../../services/profile-ira.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'stream-certify-documents-to-link-ira-dialog',
  templateUrl: './certify-documents-to-link-ira-dialog.component.html',
  styleUrls: ['./certify-documents-to-link-ira-dialog.component.scss']
})
export class CertifyDocumentsToLinkIRADialogComponent {
  loading = false;

  documentsList: IDocumentType[] = [];

  disabledSubmit: boolean = true;

  @ViewChild('linkingFailedDialog')
  linkingFailedDialogTpl!: TemplateRef<unknown>;

  constructor(
    private dialog: MatDialog,
    private profileService: ProfileService,
    private profileIRAService: ProfileIRAService,
    private dialogRef: MatDialogRef<CertifyDocumentsToLinkIRADialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      profileId: string;
      resDocumentList: {
        [key: string]: IResIRADocumentType;
      };
      resIRAInformation: {
        taxType: string;
        taxCode: string;
        type: string; // unused
        mtAccountNumberLast4: string;
      };
    },
    private notification: NotificationService,
    @Inject(STREAM_CONFIG)
    public streamConfig: StreamLPConfig
  ) {
    this.documentsList = this.profileIRAService.dealIRADocumentListData(this.data.resDocumentList);
  }

  salesSupportEmail = this.streamConfig.tenantInfo$.pipe(
    map(({ salesSupportEmail }) => salesSupportEmail ?? '')
  );

  handleCheckListChange(value: boolean) {
    this.disabledSubmit = !value;
  }

  handleBtnCloseClick() {
    if (this.loading) {
      return;
    }

    this.dialogRef.close(false);
  }

  handleBtnSubmitClick() {
    if (this.loading) {
      return;
    }
    this.loading = true;

    const { profileId, resIRAInformation, resDocumentList } = this.data || {};
    const { taxType, taxCode, mtAccountNumberLast4 } = resIRAInformation || {};
    const { accountDocuments, contactDocuments } = resDocumentList || {};

    this.profileService
      .patchIRA({
        profileId,
        taxCode: taxCode.trim(),
        taxType,
        mtAccountNumberLast4: mtAccountNumberLast4.trim(),
        accountDocumentId: accountDocuments?.['document-id'] || '',
        contactDocumentId: contactDocuments?.['document-id'] || ''
      })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        res => {
          this.notification.success('Account retrieved successfully');
          this.dialogRef.close(res);
        },
        () => {
          this.dialogRef.close(false);
          this.dialog.open(this.linkingFailedDialogTpl, {
            width: '680px',
            autoFocus: false,
            maxWidth: '95vw'
          });
        }
      );
  }
}
