import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';

import { Config, TYPE_CONFIG_MAP, TypeConfig } from './condition-builder.model';

@Injectable()
export class ConditionBuilderService {
  constructor(
    private fb: UntypedFormBuilder,
    @Inject(TYPE_CONFIG_MAP)
    private typeConfigMap: Map<TypeConfig['type'], TypeConfig>,
  ) {}

  config$ = new BehaviorSubject<Config[]>([]);

  validate$ = new Subject();

  formArray!: FormArray;

  get expressionControl() {
    return this.formArray?.at(1) as FormArray;
  }

  get logicControl() {
    return this.formArray?.at(0) as FormControl;
  }

  configMap$ = this.config$.pipe(
    map(
      source =>
        new Map(
          source.flatMap(item =>
            (item.children ?? [])
              .map(
                child =>
                  [
                    child.key,
                    {
                      ...child,
                      parent: item,
                    },
                  ] as [
                    string,
                    Config & {
                      parent?: Config;
                    },
                  ],
              )
              .concat([[item.key, item]]),
          ),
        ),
    ),
    shareReplay(1),
  );

  getConfig(key: string) {
    return this.configMap$.pipe(
      take(1),
      map(configMap => configMap.get(key)!),
      tap(config => {
        if (!config) throw new Error(`Unknown key: ${key}`);
      }),
    );
  }

  getTypeConfig(type?: string): TypeConfig {
    if (!type || !this.typeConfigMap.has(type))
      throw new Error(`Unregistered type: '${type}'.`);

    return this.typeConfigMap.get(type)!;
  }

  addExpression(key: string, operator?: string, value?: any) {
    this.getConfig(key).subscribe(config => {
      if (!config?.type)
        throw new Error(`Source input of '${key}' must have 'type' field.`);

      const typeConfig = this.getTypeConfig(config.type);

      const expressionControl = this.fb.array([
        key,
        this.fb.control(operator ?? typeConfig?.operators[0]?.value ?? null, [
          Validators.required,
        ]),
        this.fb.control(
          value ?? null,
          (typeConfig.validators ?? []).concat(config.validators ?? []),
        ),
      ]);

      this.expressionControl.push(expressionControl);
    });
  }

  removeExpression(i: number) {
    this.expressionControl.removeAt(i);
  }
}
