import { NgStyle } from '@angular/common';

import { Profile, Project, StreamFormlyPanelConfig } from '.';
import { KycStatusEnum } from './principal.interface';

export enum GatewaySubmitResponseEnum {
  Success = 'SUCCESS',
  RepeatedSubmit = 'REPEATED_SUBMIT',
}

export interface GatewaySubmitResponse {
  submitResponseEnum: GatewaySubmitResponseEnum;
  actionResult: boolean;
  submitResult: boolean;
  resultMessage: string;
}

export enum GatewayPermission {
  Normal = 'normal',
}

export enum GatewayType {
  UserDefined = 'USER_DEFINED',
}

export enum NodeType {
  Form = 'NODE_ACTION_FORM',
  Start = 'NODE_START',
  Statement = 'NODE_ACTION_STATEMENT',
  Questionnaire = 'NODE_ACTION_QUESTIONNAIRE',
  Sign = 'NODE_ACTION_SIGN',
  Custodian = 'NODE_ACTION_VIEW_CUSTODIAN_DOCUMENTS',
  End = 'NODE_END', // 结束
  Router = 'NODE_ROUTER', // 路由
  ChangeDBValue = 'NODE_ACTION_CHANGEDBVALUE', // 修改数据库值
  Email = 'NODE_ACTION_EMAIL', // 邮件
  Inbox = 'NODE_ACTION_INBOX', // 站内信
  NodeForm = 'NODE_FORM', // 老数据 Form
}

export enum GatewayStatus {
  Inuse = 'inuse',
}
export interface Gateway {
  gatewayInstanceDataList: {
    biteFlowNodeActionFormGroupList: {
      panels: StreamFormlyPanelConfig[];
      submit: {
        label: string;
        styles: NgStyle['ngStyle'];
      };
      id: string;
    }[];
  }[];
  gatewayOrderIndex: number;
  gatewayPermission: GatewayPermission;
  gatewayName: string;
  gatewayType: GatewayType;
  nodes: GatewayNode[];
  gatewayDescription: string;
  tenantId: string;
  gatewayVersion: string;
  gatewayId: string;
  gatewayStatus: GatewayStatus;
}

export enum CustomButtonType {
  Save_Exit = 'SAVE_EXIT',
}
export interface GatewayNode {
  temporaryNodeResult?: {
    actionResult: Record<string, any>;
  };
  data: {
    customButton: { name: string; type: CustomButtonType }[];
    panels?: StreamFormlyPanelConfig[];
    id: string;
    projectId: Project['id'];
    gatewayId: string;
    content?: string;
    checkbox?: string;
    surveyQuestionData: {
      currentQuestionId: string;
      progress: number;
      recordId: string;
      status: 'ready' | 'in_progress' | 'finished';
    };
    profileId?: string;
    subscriptionId?: string;
    isDeliveryOrder?: string;
    selectedProfile: Profile;
  };
  nodeInnerType?: string;
  flow: {
    targetNodeId?: GatewayNode['id'];
    sourceNodeId?: GatewayNode['id'];
  };
  type: NodeType;
  isNodeDone: boolean;
  nodeDescription: string;
  nodeButtonName?: Record<string, string>;
  id: string;
  nodeProgress: number;
  nodeName: string;
  signDocumentsKeyValue: Record<string, string>;
  customerJsonKeyValue: Record<string, any>;
  rules?: GatewayRule[];
  model: Record<string, any>;
  busData?: string;
  canPrevious?: boolean;
  profile?: Profile;
}

export interface GatewayRule {
  condition: any;
  flow: {
    sourceNodeId?: GatewayNode['id'];
    targetNodeId?: GatewayNode['id'];
  };
}
export enum InteractionStatus {
  InterActions = 'INTERACTIONS',
  NoInteractions = 'NO_INTERACTIONS',
}
export interface GatewayProgress {
  gatewayName: string;
  gatewayDescription: string;
  gatewayInnerType: string;
  id: string;
  nodes: GatewayNode[];
  gatewayProgress: number;
  orderIndex: number;
  isGatewayDone: boolean;
  interactionStatus: InteractionStatus;
  hitResultShow?: {
    icon: string;
    label: string;
    title: string;
    value: KycStatusEnum;
    nodeButtonName?: Record<string, string>;
    salesSupportEmail?: string;
    mailto?: string;
    profileType: 'INDIVIDUAL' | 'ENTITY';
  };
  scenarioId?: string;
}
