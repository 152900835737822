<ng-container [formGroup]="$any(formControl)">
  <mat-form-field class="share-class" *ngIf="shareClassList.length > 1">
    <mat-label>Share class</mat-label>
    <mat-select formControlName="shareClass">
      <mat-option *ngFor="let shareClass of shareClassList" [value]="shareClass.id">
        {{ shareClass.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="amount">
    <h4 class="title">Amount to invest</h4>
    <p class="sub-title">Specify how much you would like to invest in this fund</p>
    <div class="row">
      <mat-form-field class="currency-field">
        <mat-label>Currency</mat-label>
        <mat-select formControlName="currency">
          <mat-option [value]="currentShareClass?.currency">
            {{ currentShareClass?.currency }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- todo: floatLabel -->
      <mat-form-field class="number-field" #amountInputField>
        <button mat-icon-button (click)="stepDown()" class="control-button" matPrefix>-</button>
        <input
          matInput
          [value]="transformAmount(reModify ? snapshotInitValue : minimumInvestment)"
          (blur)="onAmountBlur()"
          #amountInput
          class="amount-input"
        />
        <button mat-icon-button (click)="stepUp()" class="control-button" matSuffix>+</button>
      </mat-form-field>
    </div>
    <p class="tip" *ngIf="formControl.get('shareClass')?.value">
      Minimum commitment is
      {{ minimumInvestment | customCurrency : formControl.get('currency')?.value }}
    </p>
  </div>

  <ul class="result">
    <li class="item" *ngIf="currentShareClass?.subscriptionFeeOpen">
      <span class="item-name">Subscription fee</span>
      <span>
        {{ subscriptionFee | customCurrency : formControl.get('currency')?.value }}
      </span>
    </li>
    <li class="item">
      <span class="item-name">Total commitment</span>
      <span class="text-primary-500">{{
        total | customCurrency : formControl.get('currency')?.value
      }}</span>
    </li>
  </ul>
</ng-container>
