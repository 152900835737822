import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

import { ClientService } from "../services/client.service";

const regex = /:tenantId/;

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private clientService: ClientService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (regex.test(request.url)) {
      return this.clientService.configuration.pipe(
        switchMap(({ tenantId }) =>
          next.handle(
            request.clone({
              url: request.url.replace(regex, tenantId),
            })
          )
        )
      );
    }
    return next.handle(request);
  }
}
