<!-- Document -->
<div class="mt-1 w-full">
  <div class="mt-6 w-full" *ngFor="let doc of _documentsList; let indexDoc = index">
    <!-- title -->
    <div *ngIf="doc.title" class="w-full font-semibold text-lg color-[#101213]">{{
      doc.title
    }}</div>
    <!-- files -->
    <div
      class="box-border mt-3 bg-[#F8F9FA] w-full rounded"
      *ngFor="let file of doc.files; let indexFile = index"
    >
      <div
        class="box-border flex flex-row content-between items-center px-4 w-full h-14 cursor-pointer"
        (click)="handleFileClick(file, indexDoc, indexFile)"
      >
        <div class="flex-[1_1_auto] w-0">
          <div class="flex flex-row flex-nowrap items-center w-full">
            <svg
              *ngIf="file.type === 'PDF'"
              viewBox="0 0 24 28"
              fill="none"
              class="icon flex-[0_0_auto] text-2xl"
            >
              <path
                d="M2.62602 0.461426H16.2592L23.0757 7.23066V25.846C23.0757 26.7807 22.3128 27.5383 21.3716 27.5383H2.62602C1.68482 27.5383 0.921875 26.7807 0.921875 25.846V2.15373C0.921875 1.21907 1.68482 0.461426 2.62602 0.461426Z"
                fill="#adb5bd"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.4264 18.6971C15.5063 18.6292 14.6205 18.2899 13.905 17.6791C12.5079 17.9844 11.1788 18.4254 9.84995 18.9685C8.79372 20.8346 7.80549 21.7847 6.95359 21.7847C6.78318 21.7847 6.57885 21.7508 6.44252 21.6489C6.06761 21.4794 5.86328 21.1061 5.86328 20.7329C5.86328 20.4274 5.93145 19.5793 9.16846 18.188C9.91829 16.8308 10.4974 15.4397 10.9745 13.9808C10.5655 13.1664 9.67971 11.1644 10.2929 10.1467C10.4974 9.77334 10.9063 9.56976 11.3494 9.60378C11.6901 9.60378 12.0307 9.77334 12.2352 10.0448C12.6783 10.6555 12.6442 11.9449 12.0648 13.845C12.6102 14.8615 13.3244 15.7792 14.1774 16.5595C14.8932 16.4236 15.6086 16.3219 16.3242 16.3219C17.9257 16.3557 18.1643 17.1022 18.1302 17.5434C18.1302 18.6971 17.0056 18.6971 16.4264 18.6971ZM6.8856 20.8006L6.88575 20.8005L6.8856 20.8008V20.8006ZM6.88575 20.8005C7.19231 20.3595 7.60108 20.0202 8.11224 19.8167C7.83958 20.2579 7.46483 20.5972 6.98784 20.7667L6.88575 20.8005ZM11.3152 10.6217H11.4174C11.5878 11.1984 11.5878 11.8092 11.3833 12.3859C11.1448 11.843 11.0425 11.2323 11.1788 10.6555C11.2129 10.6217 11.2811 10.6217 11.3152 10.6217ZM11.656 15.5416L11.6219 15.6093L11.5878 15.5754C11.2811 16.3559 10.9403 17.1362 10.5655 17.8827L10.6337 17.8489V17.9166C11.3914 17.6437 12.1652 17.4171 12.9508 17.2379L12.9167 17.2041H13.019C12.5077 16.6951 12.0307 16.1183 11.656 15.5416ZM15.4041 17.4075C15.7108 17.3398 15.9833 17.3398 16.2901 17.3398C16.9716 17.3398 17.1079 17.5094 17.1079 17.6113C16.9036 17.6791 16.665 17.713 16.4264 17.6791C16.0857 17.6451 15.7449 17.5773 15.4041 17.4075Z"
                fill="#ffffff"
              />
              <path
                d="M16.2578 0.461426L23.0744 7.23066H17.962C17.0208 7.23066 16.2578 6.47301 16.2578 5.53835V0.461426Z"
                fill="#ffffff"
              />
            </svg>
            <svg
              *ngIf="file.type === 'TXT'"
              viewBox="0 0 23 28"
              fill="none"
              class="icon flex-[0_0_auto] text-2xl"
            >
              <path
                d="M1.70414 0H15.3373L22.1538 6.76923V25.3846C22.1538 26.3193 21.3909 27.0769 20.4497 27.0769H1.70414C0.762944 27.0769 0 26.3193 0 25.3846V1.69231C0 0.757646 0.762944 0 1.70414 0Z"
                fill="#adb5bd"
              />
              <path
                d="M15.3373 0L22.1538 6.76923H17.0414C16.1002 6.76923 15.3373 6.01159 15.3373 5.07692L15.3373 0Z"
                fill="#ffffff"
              />
              <path
                d="M5.57692 16.8035H6.62092V19.1345H7.65592V16.8035H8.70892V16.0385H5.57692V16.8035ZM12.3809 16.0385H11.2559L10.6799 16.9925L10.0859 16.0385H8.95192L9.99592 17.5145L8.84392 19.1255H10.0049L10.6619 18.1355L11.3189 19.1255H12.4799L11.3459 17.5325L12.3809 16.0385ZM12.5609 16.0385V16.8035H13.6139V19.1345H14.6489V16.8035H15.7019V16.0385H12.5609Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.1549 13.5385H5.55292C4.74292 13.5385 4.07692 14.2045 4.07692 15.0145V20.3335C4.07692 21.1434 4.74292 21.8095 5.55292 21.8095H16.1639C16.5599 21.8095 16.9289 21.6565 17.2079 21.3685C17.4869 21.0895 17.6399 20.7204 17.6309 20.3335V15.0145C17.6309 14.2045 16.9649 13.5385 16.1549 13.5385ZM16.4429 20.6125C16.5149 20.5405 16.5509 20.4415 16.5509 20.3335V15.0145C16.5509 14.7985 16.3709 14.6185 16.1549 14.6185H5.55292C5.33692 14.6185 5.15692 14.7985 5.15692 15.0145V20.3335C5.15692 20.5494 5.33692 20.7295 5.55292 20.7295H16.1639C16.2719 20.7295 16.3709 20.6845 16.4429 20.6125Z"
                fill="white"
              />
            </svg>
            <div
              class="ml-1.5 flex-[0_1_auto] text-[#2C2934] text-base truncate"
              [ngClass]="{
                'font-normal': file.isFolded,
                'font-semibold': !file.isFolded
              }"
            >
              {{ file.name }}
            </div>
            <svg
              *ngIf="file.isMustView && !file.checkMustView"
              class="ml-2 icon flex-[0_0_auto] text-lg"
            >
              <use style="fill: #ff9900" xlink:href="#icon-must-view"></use>
            </svg>
            <svg
              *ngIf="file.isMustView && file.checkMustView"
              class="ml-2 icon flex-[0_0_auto] text-2xl"
            >
              <use style="fill: #44bb5c" xlink:href="#icon-wancheng"></use>
            </svg>
          </div>
        </div>
        <div class="flex-[0_0_auto] flex flex-row">
          <svg
            class="ml-2 icon flex-[0_0_auto] text-lg transition-transform"
            [ngClass]="{
              'rotate-[90deg]': file.isFolded,
              'rotate-[270deg]': !file.isFolded
            }"
          >
            <use style="fill: #adb5bd" xlink:href="#icon-a-Lineicons2"></use>
          </svg>
        </div>
      </div>
      <div *ngIf="!file.isFolded" class="flex flex-col w-full">
        <div class="relative px-4 pb-6 min-h-60">
          <div
            *ngIf="file.type === 'PDF'"
            class="box-border pt-4 border-t border-solid border-[#CED4DA]"
          >
            <ngx-extended-pdf-viewer
              class="pdf-viewer"
              [src]="file.blobUrl || ''"
              [showUnverifiedSignatures]="true"
              [textLayer]="true"
              [useBrowserLocale]="true"
            ></ngx-extended-pdf-viewer>
          </div>
          <div
            *ngIf="file.type === 'TXT'"
            class="relative box-border pt-4 border-t border-solid border-[#CED4DA]"
          >
            <pre class="whitespace-pre-wrap text-base text-[#62656C]">{{
              file.blobUrl || ''
            }}</pre>
          </div>
          <mat-spinner *ngIf="file.isFileLoading" class="file-spinner"></mat-spinner>
        </div>
        <div
          *ngIf="file.isMustView"
          class="flex flex-row items-center w-full h-10"
          style="box-shadow: 0px -4px 16px 0px #0000001a"
        >
          <mat-checkbox
            [(ngModel)]="file.checkMustView"
            [disabled]="!file.blobUrl"
            (change)="handleCheckMustViewChange()"
          >
            Check this box to certify to the above</mat-checkbox
          >
        </div>
      </div>
    </div>
  </div>
</div>
