<div class="flex h-full flex-col">
  <h1 class="mb-4 flex-none text-xl font-semibold">
    {{ member ? 'Edit' : 'Add' }} Owners/Directors/Trustees/Beneficiaries
  </h1>
  <form
    class="flex flex-1 flex-col space-y-4 overflow-auto"
    [formGroup]="form"
    id="ownership-form"
    (submit)="saveMember()"
  >
    <div>
      <h3
        class="label flex items-center"
        nz-popover
        *ngIf="tooltipRoles"
        nzPopoverOverlayClassName="max-w-xl"
        nzPopoverPlacement="bottom"
        [nzPopoverOrigin]="icon._elementRef"
        [nzPopoverContent]="info"
      >
        <span class="text-red-500">*&nbsp;</span>
        <span>Role in the entity</span>
        <mat-icon color="primary" class="ml-2 !h-auto !text-xl" #icon
          >info_outlined</mat-icon
        >
      </h3>

      <h3 class="label flex items-center" *ngIf="!tooltipRoles">
        <span class="text-red-500">*&nbsp;</span>
        <span>Role in the entity</span>
      </h3>

      <ng-template #info>
        <stream-quill-view
          [content]="tooltipRoles"
          [pureViewer]="true"
        ></stream-quill-view>
      </ng-template>
      <mat-radio-group
        class="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 md:gap-y-0"
        formControlName="memberRole"
      >
        <mat-radio-button [value]="EntityMemberRole.AuthorisedSignatories"
          >Authorised signatories</mat-radio-button
        >
        <mat-radio-button [value]="EntityMemberRole.ControllingPerson"
          >Controlling person</mat-radio-button
        >
      </mat-radio-group>

      <mat-checkbox
        class="mt-4"
        *ngIf="isControllingPerson"
        [checked]="directorChecked.getValue()"
        (change)="directorChecked.next($event.checked)"
        >Director</mat-checkbox
      >
    </div>

    <div *ngIf="isControllingPerson">
      <h3 class="label">
        <span class="text-red-500">*&nbsp;</span>
        <span>Please specify if the Controlling Person is a 'U.S. Person'</span>
      </h3>
      <mat-radio-group
        class="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2 md:gap-y-0"
        formControlName="isUsPerson"
      >
        <mat-radio-button [value]="true"
          >The Controlling Person is a 'U.S. Person'</mat-radio-button
        >
        <mat-radio-button [value]="false"
          >The Controlling Person is not a 'U.S. Person'</mat-radio-button
        >
      </mat-radio-group>
      <mat-error
        *ngIf="
          form.get('isUsPerson')?.hasError('required') && form.get('isUsPerson')?.touched
        "
      >
        Please specify if the Controlling Person is a 'U.S. Person'.
      </mat-error>
    </div>

    <div>
      <h3 class="label">Signing authority</h3>
      <mat-checkbox formControlName="isSigningAuthority"
        >Confirm the delegation of signing authority for subscription
        documents.</mat-checkbox
      >
    </div>

    <div *ngIf="isControllingPerson">
      <h3 class="label">
        <span class="text-red-500">*&nbsp;</span>
        <span>Percentage Ownership</span>
      </h3>

      <mat-form-field class="w-full">
        <input
          type="text"
          matInput
          formControlName="percentageOwnership"
          placeholder="Please input"
        />
        <span class="pr-1" matSuffix>%</span>
      </mat-form-field>

      <mat-error
        *ngIf="
          form.get('percentageOwnership')?.hasError('required') &&
          form.get('percentageOwnership')?.touched
        "
      >
        Please enter the percentage ownership for the controlling person.
      </mat-error>

      <mat-error
        *ngIf="
          !form.get('percentageOwnership')?.hasError('required') &&
          (form.get('percentageOwnership')?.hasError('percentageOutOfRange') ||
            form.get('percentageOwnership')?.hasError('invalidFormat')) &&
          form.get('percentageOwnership')?.touched
        "
      >
        Please enter a percentage value between 0 and 100.
      </mat-error>
    </div>

    <div>
      <h3 class="label">
        <span class="text-red-500">*</span>
        Please select one of the persons to sync the following information
      </h3>
      <div
        class="rounded bg-gray-100 p-4"
        [class]="personError ? 'border border-red-500' : null"
      >
        <stream-drawer-select
          icon="#icon-person"
          name="person"
          [optionTemplate]="personTemplate"
          (add)="openPerson()"
          (edit)="openPerson($event)"
          formControlName="person"
          [optionsObservable]="personList"
          [showEdit]="false"
          class="person-select"
          [disableCreate]="reModify"
          #personSelect
        >
          <ng-template
            #personTemplate
            let-person
            let-selected="selected"
            let-checked="checked"
          >
            <div class="group divide-y">
              <div
                class="px-5 py-3 pr-12 md:pr-5"
                [ngClass]="{
                  'gradient-background': selected,
                  'ml-9': !selected,
                  'pr-12': selected
                }"
              >
                <h2
                  class="flex flex-wrap items-center gap-y-1 text-sm font-semibold md:flex-nowrap md:gap-y-0 md:text-lg"
                >
                  {{ person.firstName }} {{ person.lastName }} (<span
                    class="block w-[100px] flex-shrink truncate md:inline md:w-auto"
                  >
                    {{ person.email }} </span
                  >)
                  <span
                    class="ml-0 mr-2 rounded-l-full rounded-r-full bg-gray-50 px-2 text-sm font-normal text-primary-500 md:ml-2 md:mr-0"
                    *ngIf="person.jobPositionTitle"
                  >
                    {{ person.jobPositionTitle }}
                  </span>
                  <mat-icon
                    class="absolute right-4 top-4 !hidden -translate-y-1 cursor-pointer text-gray-500 underline transition-all hover:text-primary-500 group-hover:!inline-block"
                    *ngIf="!selected"
                    (click)="$event.preventDefault(); openPerson(person)"
                    >edit</mat-icon
                  >
                </h2>
                <label class="text-gray-500">
                  Nationality :
                  {{ person.nationalityCode | countryName | async }}
                </label>
              </div>
              <ng-container
                *ngIf="
                  checked ||
                  (selected &&
                    form.get('memberRole')?.value === MemberRole.ControllingPerson)
                "
              >
                <ul class="grid grid-cols-3 gap-3 px-5 py-2">
                  <li class="text-left">
                    <label class="text-gray-500">Town of birth</label>
                    <div class="truncate font-semibold">
                      {{ person.townOfBirth }}
                    </div>
                  </li>
                  <li class="text-center">
                    <label class="text-gray-500">Date of birth (mm/dd/yyyy)</label>
                    <div class="truncate font-semibold">
                      {{ person.birthday | date }}
                    </div>
                  </li>
                  <li class="text-right">
                    <label class="text-gray-500">Country of birth</label>
                    <div class="truncate font-semibold">
                      {{ person.countryCodeOfBirth | countryName | async }}
                    </div>
                  </li>
                </ul>
                <div class="flex items-center justify-between px-5 py-2">
                  <label class="text-gray-500">Country of residence</label>
                  <div class="font-semibold">
                    {{ person.countryCodeOfResidence }}
                  </div>
                </div>
                <div class="grid grid-cols-2 px-5 py-2">
                  <label class="text-gray-500">Jurisdiction of tax residency</label>
                  <label class="justify-self-end text-gray-500"
                    >Tax reference number (e.g TIN)</label
                  >
                  <ng-container
                    *ngFor="
                      let taxJurisdictionItem of parseValue(person.taxJurisdiction);
                      trackBy: trackTaxJurisdiction
                    "
                  >
                    <div class="font-semibold">
                      {{ taxJurisdictionItem[0] }}
                    </div>
                    <div class="justify-self-end font-semibold">
                      {{ taxJurisdictionItem[2] }}
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </stream-drawer-select>
      </div>
      <input matInput formControlName="person" #personValue="matInput" hidden />

      <ng-container>
        <mat-error
          [ngx-mat-errors]="personValue"
          *ngIf="form.get('person')?.invalid"
        ></mat-error>
        <mat-error *ngIf="personError"
          >To proceed, please provide complete information. Some fields are currently
          incomplete.</mat-error
        >
      </ng-container>
    </div>
    <div
      class="grid grid-cols-2 gap-x-2"
      formArrayName="legalInfo"
      *ngIf="form.get('legalInfo')"
    >
      <ng-container
        *ngFor="let legalInfo of legalInfoList; let i = index"
        [formGroupName]="i"
      >
        <mat-form-field>
          <mat-label>Legal name of Entity{{ i + 1 }}</mat-label>
          <input matInput formControlName="legalName" />
          <mat-error ngx-mat-errors></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label> Type of controlling person </mat-label>
          <mat-select
            panelClass="legal-select-panel"
            formControlName="controllingPersonType"
          >
            <mat-option
              *ngFor="let type of ControllingPersonType"
              [value]="type.key"
              [matTooltip]="type.en"
              [matTooltipShowDelay]="500"
            >
              {{ type.en }}
            </mat-option>
          </mat-select>
          <mat-error ngx-mat-errors></mat-error>
        </mat-form-field>
      </ng-container>
    </div>
    <div>
      <h3 class="label">
        <span class="text-red-500">*</span>
        Address information
      </h3>
      <stream-drawer-select
        name="address"
        icon="#icon-address"
        formControlName="address"
        (add)="openAddress()"
        (edit)="openAddress($event)"
        (drawerOpened)="getMemberAddress()"
        [optionTemplate]="addressTemplate"
        [optionSource]="memberAddressList"
        [disableCreate]="reModify"
      >
        <ng-template #addressTemplate let-address>
          <div class="space-y-1 pr-8 md:pr-0">
            <div class="text-base font-semibold text-gray-500">
              <p class="text-black"
                >{{ address.addressLineOne }} {{ address.addressLineTwo }},</p
              >
              <p>
                {{ address.addressTown }} {{ address.addressState }}
                {{ translateCountryName(address.addressCountryCode) }},
                {{ address.zipCode }}
              </p>
            </div>
            <div class="!-mb-1 flex flex-wrap items-center gap-x-2">
              <stream-data-room-document
                [document]="address.referenceProofAddressFileEntity?.documents"
                [proofType]="'address'"
                class="mb-1"
              ></stream-data-room-document>
              <div
                *ngIf="address.tag"
                class="mb-1 whitespace-normal rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
              >
                {{ getDocTagLabel(address) }}
              </div>
            </div>
          </div>
        </ng-template>
      </stream-drawer-select>

      <mat-error *ngIf="form.get('address')?.hasError('required')">
        This field is required.
      </mat-error>
    </div>
    <div>
      <h2 class="label">
        <span class="text-red-500">*</span>
        Identity information
      </h2>
      <stream-drawer-select
        name="identity"
        icon="#icon-identity1"
        (drawerOpened)="getMemberIdentity()"
        (add)="openIdentity()"
        (edit)="openIdentity($event)"
        formControlName="identity"
        [optionTemplate]="identityTemplate"
        [optionSource]="memberIdentityList"
        [disableCreate]="reModify"
      >
        <ng-template #identityTemplate let-identity>
          <div class="space-y-1 pr-8 md:pr-0">
            <div class="space-x-1 text-base font-semibold capitalize text-gray-500">
              {{ getIdentityInformation(identity.documentType) }}:
              <strong class="text-black">{{ identity.identityNumber }}</strong>
            </div>
            <div class="!-mb-1 flex flex-wrap items-center gap-x-2">
              <stream-data-room-document
                [document]="identity.referenceProofCertificateFileEntity?.documents"
                [proofType]="'identity'"
                class="mb-1"
              ></stream-data-room-document>
              <div
                *ngIf="identity.tag"
                class="mb-1 whitespace-normal rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
              >
                {{ getDocTagLabel(identity) }}
              </div>
            </div>
          </div>
        </ng-template>
      </stream-drawer-select>

      <mat-error *ngIf="form.get('identity')?.hasError('required')">
        This field is required.
      </mat-error>
    </div>
  </form>
  <footer class="flex flex-none justify-end space-x-2 bg-white">
    <button
      mat-stroked-button
      color="primary"
      class="!px-16"
      type="button"
      (click)="drawer.close()"
      *ngIf="!reModify"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      class="!px-16"
      [disabled]="loading"
      form="ownership-form"
    >
      Submit
    </button>
  </footer>
</div>
