import { Component, OnInit } from '@angular/core';
import { BOTTOM_MENU, NavMenu } from '@stream/models';

import { AuthService } from '@stream/service/auth.service';
import { AccountService } from '../../../services/account.service';
import { InboxService } from '../../../services/inbox.service';

@Component({
  selector: 'stream-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent implements OnInit {
  constructor(
    private inboxService: InboxService,
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  userId: string = '';

  wmCandidate$ = this.accountService.wmCandidate;

  get showNotification() {
    return this.authService.retrieveNotificationRecord(this.userId);
  }

  menuList: Array<NavMenu> = [];

  inboxMessageCount$ = this.inboxService.unreadCount$;

  ngOnInit(): void {
    this.accountService.wmCandidate.subscribe(wmCandidate => {
      if (wmCandidate) {
        this.menuList = BOTTOM_MENU.filter(item => ['Opportunities', 'My'].includes(item.label));
        return;
      }
      this.menuList = BOTTOM_MENU;
      this.inboxService.getInboxList().subscribe();
    });
    this.accountService.userInfo.subscribe(res => {
      this.userId = res.investAccount.id;
    });
  }
}
