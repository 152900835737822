import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormlyModule } from '@ngx-formly/core';
import { MandatoryDocModule } from '@stream/mandatory-doc';
import { StreamFormlyType } from '@stream/models';
import { FilePreviewerModule, QuillViewModule } from '@stream/ngx-utils';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { MandatoryDocsComponent } from './mandatory-docs/mandatory-docs.component';

@NgModule({
  declarations: [MandatoryDocsComponent, DocUploadComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    NzPopoverModule,
    MatDialogModule,
    FilePreviewerModule,
    QuillViewModule,
    MandatoryDocModule,
    MatButtonModule,
    MatTooltipModule,
    FormlyModule.forChild({
      types: [
        {
          name: StreamFormlyType.MANDATORYDOCS,
          component: MandatoryDocsComponent
        }
      ]
    })
  ],
  // providers: [MandatoryDocService]
  exports: [DocUploadComponent]
})
export class MandatoryDocsModule {}
