import { I18n } from '../common';
import { TableCellType } from './formly.interface';

export enum QuestionType {
  Label = 'label',
  Text = 'text',
  TextArea = 'textarea',
  Select = 'select',
  RadioGroup = 'radio-group',
  Checkbox = 'checkbox',
  CheckboxGroup = 'checkbox-group',
  Date = 'date',
  Table = 'table',
}

export interface Question {
  extras?: {
    options?: {
      value: string;
      text: I18n;
    }[];
    cellStyle?: TableCellType;
    columns?: (I18n & { value: string })[];
    rows?: I18n[];
  };
  id: string;
  name: string;
  previousQuestionId: Question['id'];
  required: boolean;
  title: I18n;
  progress: number;
  type: QuestionType;
  value: any;
}

export interface AnswerResult {
  finished: boolean;
  nextId: Question['id'];
  progress: number;
  result: string;
}
