const COMMON_PHONE_REGEX = /^\d{0,12}$/;
const ENGLAND_PHONE_REGEX = /^\d{8,11}$/;
const CHINA_PHONE_REGEX = /^1\d{10}/;
const PHONE_REGEX_MAP = new Map<string, RegExp>([
  ['UK', ENGLAND_PHONE_REGEX],
  ['CN', CHINA_PHONE_REGEX],
]);

export const phoneValidatorFn = (code: string, phone: string) =>
  (PHONE_REGEX_MAP.get(code) || COMMON_PHONE_REGEX).test(phone);
