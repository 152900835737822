import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'ngx-select-all',
  template: `
    <mat-checkbox
      class="mat-option"
      matRipple
      [checked]="checked"
      [indeterminate]="indeterminate"
      (change)="handleChange($event)"
      >All</mat-checkbox
    >
  `,
  styleUrls: [`./ngx-select-all.component.scss`]
})
export class NgxSelectAllComponent {
  constructor(public select: MatSelect) {
    if (!select) {
      throw new Error('ngx-select-all component must contain in a MatSelect');
    }
    if (!select.multiple) {
      throw new Error('ngx-select-all component must be used with multiple selection');
    }
  }

  get selectedOptions() {
    return this.select.selected as MatOption[];
  }

  get checked() {
    return this.select.options.length === this.selectedOptions.length;
  }

  get indeterminate() {
    return !this.checked && this.selectedOptions.length > 0;
  }

  handleChange(e: MatCheckboxChange) {
    this.select.options.forEach(option => {
      e.checked ? option.select() : option.deselect();
    });
  }
}
