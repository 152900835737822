import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavMenu } from '@stream/models';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const MENU_HEIGHT = 64;

@Component({
  selector: 'stream-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  constructor(private router: Router) {}

  @Input()
  menuList: Array<NavMenu> = [];

  @Input()
  menuHeight?: number;

  routeChangeSub = new Subscription();

  ngOnInit(): void {
    this.autoCollapseMenu();
    this.routeChangeSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.autoCollapseMenu();
      });
  }

  ngOnDestroy(): void {
    this.routeChangeSub.unsubscribe();
  }

  autoCollapseMenu() {
    const activeMenu = this.menuList.find((item) => this.getRouteActive(item));
    if (activeMenu && activeMenu.children && !activeMenu.isOpen) {
      activeMenu.isOpen = true;
      this.collapseMenu(activeMenu);
    }
  }

  getMenuHeight(menu: NavMenu) {
    return menu.isOpen
      ? `${(menu.children ?? []).length * (this.menuHeight || MENU_HEIGHT)}px`
      : 0;
  }

  collapseMenu(menu: NavMenu) {
    this.menuList
      .filter(
        (item: any) => item.index !== menu.index && item.index !== menu.parent
      )
      .forEach((item: any) => (item.isOpen = false));
  }

  toggleMenu(menu: NavMenu) {
    menu.isOpen = !menu.isOpen;
  }

  getRouteActive(menu: NavMenu) {
    return (menu.children ?? []).some((item) =>
      this.router.isActive(item.path as string, {
        matrixParams: 'ignored',
        queryParams: 'ignored',
        fragment: 'ignored',
        paths: 'subset',
      })
    );
  }
}
