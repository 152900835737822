import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatConversationRecipient } from '@stream/models';
import { finalize } from 'rxjs/operators';

import { ChatService } from '../../../../services/chat.service';
import { ChatMembersEditComponent } from '../chat-members-edit/chat-members-edit.component';

@Component({
  selector: 'stream-chat-members-management',
  templateUrl: './chat-members-management.component.html',
  styleUrls: ['./chat-members-management.component.scss'],
})
export class ChatMembersManagementComponent implements OnInit {
  members: ChatConversationRecipient[] = [];

  loading = true;

  hasMore = false;

  @Input()
  conversationId!: string;

  @ViewChild('memberNames')
  memberNamesRef!: ElementRef<HTMLDivElement>;

  disabled = this.chatService.conversationDisabled;

  constructor(private chatService: ChatService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.getRecipients();
  }

  getRecipients() {
    this.chatService
      .getChatConversationRecipientsExists(this.conversationId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res) => {
        this.members = res;
        this.checkHasMore();
      });
  }

  checkHasMore() {
    setTimeout(() => {
      const ele = this.memberNamesRef.nativeElement;
      this.hasMore = ele.offsetWidth < ele.scrollWidth;
    });
  }

  handleMoreClick() {}

  edit() {
    this.dialog
      .open(ChatMembersEditComponent, {
        data: {
          members: this.members,
          conversationId: this.conversationId,
        },
        width: '680px',
        maxWidth: '95vw',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.getRecipients();
        }
      });
  }
}
