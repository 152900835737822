<div
  (click)="loading ? null : input.click()"
  class="relative my-4 flex w-full cursor-pointer flex-col items-center justify-around space-y-1 text-center"
  *ngIf="!_document || !_document?.name || !showResult; else uploaded"
>
  <div class="text-base underline">{{ title }}</div>

  <p class="text-sm text-gray-500">{{ placeholder }}</p>

  <input
    type="file"
    hidden
    #input
    (input)="onInput($event)"
    [accept]="accept"
    [multiple]="multiple"
  />

  <div
    class="loading-modal absolute top-0 left-0 flex h-full w-full items-center justify-center"
    *ngIf="loading"
  >
    <div class="loader"></div>
  </div>
</div>
<ng-template #uploaded>
  <div
    class="m-2.5 flex w-full items-center justify-between rounded bg-gray-200 px-5 py-3"
  >
    <stream-data-room-document [document]="_document"></stream-data-room-document>
    <button mat-icon-button type="button" [disabled]="loading" [class.!cursor-not-allowed]="loading">
      <input
        *ngIf="!loading"
        type="file"
        #input
        (input)="onInput($event)"
        [accept]="accept"
        [multiple]="multiple"
        class="absolute h-full w-full opacity-0 z-10"
      />
      <mat-icon class="text-gray-500">replay</mat-icon>
    </button>
  </div>
</ng-template>
