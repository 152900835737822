import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecaptchaModule } from 'ng-recaptcha';

import {
  ReCaptchaV2ChildComponent,
  ReCaptchaV2Component,
} from './re-captcha-v2.component';

@NgModule({
  declarations: [ReCaptchaV2Component, ReCaptchaV2ChildComponent],
  imports: [CommonModule, RecaptchaModule],
  exports: [ReCaptchaV2Component],
})
export class ReCaptchaV2Module {}
