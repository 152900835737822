import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TrackByFunction, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatConversationsItem } from '@stream/models';
import { BehaviorSubject, EMPTY, interval, of, Subscription } from 'rxjs';
import { catchError, delayWhen, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { ChatService } from '../../../../services/chat.service';
import { ChatConversationAddComponent } from '../conversation-add/conversation-add.component';

@Component({
  selector: 'stream-chat-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ChatConversationComponent implements OnInit, OnDestroy {
  constructor(
    private chatService: ChatService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}
  pageSize = 30;

  pageNum = 1;

  hasMore = false;

  loading = true;

  searchText = '';

  searchValue = '';

  polling = true;

  disabled$ = this.chatService.conversationDisabled;

  visible$ = this.disabled$.pipe(
    filter((disabled) => !disabled),
    switchMap(() => {
      return this.chatService.getCategories();
    }),
    map((data) => Array.isArray(data) && !!data.length)
  );

  pool$ = new BehaviorSubject<ChatConversationsItem[]>([]);

  PollingSub?: Subscription;

  get listData() {
    return this.pool$.getValue();
  }

  @Output()
  closeEvent = new EventEmitter();

  @ViewChild('conversationsWrapper')
  conversationsWrapper?: ElementRef<HTMLDivElement>;

  ngOnInit(): void {
    this.chatService.checkPermission.next();
    this.init();
    this.startPollingConversations();
  }

  getChatConversations() {
    this.polling = false;
    return this.chatService
      .getChatConversations({
        current: this.pageNum,
        size: this.pageSize,
        search: this.searchText,
      })
      .pipe(
        catchError(() => of(null)),
        tap((data) => {
          if (!data) {
            this.hasMore = false;
            this.pageNum = 1;
            return;
          }
          if (data.totalPage > data.pageNo) {
            this.hasMore = true;
            this.pageNum = data.pageNo + 1;
          } else {
            this.hasMore = false;
          }
        }),
        map((data) => {
          return (data && data.rows) || [];
        }),
        finalize(() => {
          this.polling = true;
        })
      );
  }

  init() {
    this.loading = true;
    this.getChatConversations()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((data) => {
        this.pool$.next(data);
      });
  }

  startPollingConversations() {
    this.PollingSub = interval(5000)
      .pipe(
        delayWhen(() => this.disabled$.pipe(filter((disabled) => !disabled))),
        filter(() => this.polling),
        switchMap(() => {
          return this.chatService
            .getChatConversations({
              current: 1,
              size: Math.max(this.listData.length, this.pageSize),
              search: this.searchText,
            })
            .pipe(catchError(() => EMPTY));
        })
      )
      .subscribe((data) => {
        if (data && Array.isArray(data.rows)) {
          const ids = data.rows.map((item) => item.id);
          this.pool$.next([
            ...data.rows,
            ...this.listData.filter((item) => !ids.includes(item.id)),
          ]);
          this.hasMore = data.totalPage > data.pageNo;
        }
      });
  }

  handleSearch() {
    this.searchText = this.searchValue;
    this.getChatConversations().subscribe((data) => {
      this.pool$.next(data);
    });
  }

  handleScrollDown() {
    if (!this.hasMore) return;
    this.getChatConversations().subscribe((data) => {
      const ids = data.map((item) => item.id);
      this.pool$.next([
        ...this.listData.filter((item) => !ids.includes(item.id)),
        ...data,
      ]);
    });
  }

  openConversationModal() {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    const dialogRef = this.dialog.open(ChatConversationAddComponent, {
      width: '680px',
      maxWidth: isMedium ? '680px' : '95vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((conversationId: string) => {
      if (conversationId) {
        this.chatService.openMessagesView(conversationId);
      }
    });
  }

  trackByFn: TrackByFunction<ChatConversationsItem> = (_index, item) => item.id;

  handleConversationClick(id: string) {
    this.chatService.openMessagesView(id);
  }

  close() {
    this.closeEvent.emit();
  }

  ngOnDestroy(): void {
    this.PollingSub?.unsubscribe();
  }
}
