import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { StreamFormlyConfig, StreamFormlyType } from '@stream/models';

import { TooltipsModule } from '../tooltips/tooltips.module';
import { RadioWrapperComponent } from './radio-wrapper/radio-wrapper.component';
import { RadioComponent } from './radio/radio.component';

@NgModule({
  declarations: [RadioWrapperComponent, RadioComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    FormlySelectModule,
    TooltipsModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'radio-wrapper',
          component: RadioWrapperComponent
        }
      ],
      types: [
        {
          name: StreamFormlyType.BITE_RADIO_GROUP,
          component: RadioComponent,
          wrappers: ['radio-wrapper', 'form-field']
        }
      ]
    })
  ]
})
export class RadioModule {
  static transformRadioGroupField({
    id,
    key,
    name,
    defaultValue,
    asyncValidators,
    validators = '',
    type,
    placeholder,
    label,
    ...templateOptions
  }: StreamFormlyConfig) {
    return {
      id,
      key,
      name,
      defaultValue,
      asyncValidators,
      validators,
      type: StreamFormlyType[type],
      templateOptions: {
        ...templateOptions,
        label: '',
        customLabel: label
      }
    };
  }
}
