export enum PrincipalGroupNewType {
  IndividualInvestor = 'Individual Investor',
  InstitutionalInvestor = 'Institutional Investor',
  IndividualCandidate = 'Individual Candidate',
  InstitutionalCandidate = 'Institutional Candidate'
}

export enum InvestorType {
  institutional = 'institutional',
  individual = 'individual'
}

type InvestorCheckValue = keyof typeof InvestorType | 'WM_INDIVIDUAL' | 'WM_INSTITUTIONAL';

type InvestorCheckLabel = `${PrincipalGroupNewType}`;

type InvestorCheck = {
  value: InvestorCheckValue;
  checked: boolean;
  label: InvestorCheckLabel;
  supportRules?: string[];
};

export const INVESTOR_CHECK_LIST: InvestorCheck[] = [
  {
    value: 'individual',
    checked: false,
    label: 'Individual Investor'
  },
  {
    value: 'institutional',
    checked: false,
    label: 'Institutional Investor'
  },
  {
    value: 'WM_INDIVIDUAL',
    checked: false,
    label: 'Individual Candidate'
  },
  {
    value: 'WM_INSTITUTIONAL',
    checked: false,
    label: 'Institutional Candidate'
  }
];

export const INVESTOR_INVITE_LIST = [
  {
    label: 'Add single investor manually',
    desc: 'Enable editing more information about the investor, but need to add one by one',
    value: 'single',
    icon: 'icon-a-Frame730'
  },
  {
    label: 'Add investors by uploading a file',
    desc: 'Only basic information of investors can be imported through bulk upload',
    value: 'multi',
    icon: 'icon-Exceldaoru'
  },
  {
    label: 'Add a WM Candidate',
    value: 'wm',
    icon: 'icon-a-icon-WMCandidate'
  }
];
