import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { InvestAccount, Principal } from './models/src';
import { ScenarioConfig } from './scenario';

export type Source = 'LP' | 'GP';

export type ConsoleConfig = {
  scenario?: ScenarioConfig;
};

export type ProviderKey = keyof ConsoleConfig;

export type ProviderConfig = Record<ProviderKey, any>;

export interface StreamCommonConfig {
  environment: {
    features: { [featrueKey: string]: string[] }[];
    currentEnv: string;
  };
  tenantInfo$: Observable<{
    tenantId: string;
    tenantType: string;
    salesSupportEmail?: string;
  }>;
}

export interface StreamGPConfig extends StreamCommonConfig {
  source: 'GP';
}

export interface StreamLPConfig extends StreamCommonConfig {
  source: 'LP';
  account$: Observable<InvestAccount>;
  principal$: Observable<Principal>;
}

export type StreamConfig = StreamGPConfig | StreamLPConfig;

export const STREAM_CONFIG = new InjectionToken<StreamConfig>('STREAM_CONFIG');
