import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStoreKeyEnum } from '@stream/models';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';

import { AccountService } from '../services/account.service';

@Injectable()
export class PrincipalInterceptor implements HttpInterceptor {
  constructor(
    private accountService: AccountService,
    private localStorageService: LocalStorageService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const principalToken = this.localStorageService.retrieve(
      AuthStoreKeyEnum.PrincipalToken
    );

    return next.handle(
      request.clone({
        setHeaders: {
          PrincipalToken: principalToken ?? '',
        },
      })
    );
  }
}
