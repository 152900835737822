<div class="flex w-full flex-col gap-y-1">
  <div class="!flex items-center text-[#07133A]" mat-dialog-title>
    <h2 class="w-full truncate pr-10 text-xl font-semibold leading-10">
      {{ data.name }}
    </h2>
    <button class="!absolute right-0" mat-icon-button color="basic" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="relative flex h-full w-full flex-col items-center !overflow-hidden bg-[#F5F6FA]">
      <ng-container *ngIf="data.downloadUrl; else NO_FILE">
        <div class="file-content bg-gray-100" *ngIf="data.type === 'PDF'">
          <ngx-extended-pdf-viewer
            class="pdf-viewer block w-full"
            [src]="pdfFileUrl || ''"
            [showUnverifiedSignatures]="true"
            [textLayer]="true"
            [useBrowserLocale]="true"
            [height]="isMedium ? '720px' : '65vh'"
          ></ngx-extended-pdf-viewer>
        </div>
        <div
          class="file-content flex justify-center bg-gray-100 px-2"
          *ngIf="data.type === 'IMAGE'"
        >
          <img [src]="imageSafeFileUrl || ''" alt="" />
        </div>
      </ng-container>
      <ng-template #NO_FILE>
        <div class="flex h-full min-h-[500px] w-full items-center justify-center text-lg font-bold">
          <p>File doesn't exist.</p>
        </div>
      </ng-template>
    </div>
  </mat-dialog-content>
</div>

<footer mat-dialog-actions class="flex w-full justify-center sm:justify-end">
  <button
    class="w-[168px] flex-1 sm:flex-none"
    [style]="data.btnCloseStyle || ''"
    mat-stroked-button
    mat-dialog-close
  >
    {{ data.btnCloseText || 'Close' }}
  </button>
  <button
    class="btn-download w-[168px] flex-1 sm:flex-none"
    [style]="data.btnDownloadStyle || ''"
    mat-raised-button
    color="primary"
    id="download"
    (click)="onDownload(data)"
  >
    {{ data.btnDownloadText || 'Download' }}
  </button>
</footer>
