import { Component, HostBinding } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FieldWrapper } from '@ngx-formly/core';
import { isQuillHasContent } from '@stream/utils';

@Component({
  selector: 'stream-radio-wrapper',
  templateUrl: './radio-wrapper.component.html',
  styleUrls: ['./radio-wrapper.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
  ],
})
export class RadioWrapperComponent extends FieldWrapper {
  @HostBinding('class.block')
  get block() {
    return this.to?.['block'];
  }

  get showTooltips() {
    return isQuillHasContent(this.to?.['tooltip']);
  }
}
