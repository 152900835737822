import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { PermissionService } from '@stream/libs/common/utils/service';
import { PrincipalTypeEnum } from '@stream/models';
import { AccountService } from '@stream/service/account.service';
import { ClientService } from '@stream/service/client.service';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap, take } from 'rxjs/operators';

export const PermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const permissionService = inject(PermissionService);
  const accountService = inject(AccountService);
  const clientService = inject(ClientService);
  const router = inject(Router);
  const { url } = state;

  const urlStartWithTeamMembers = url.startsWith('/account/team-members');
  const urlStartWithPortfolio = url.startsWith('/portfolio');
  const urlStartWithPrincipalProfile = url.startsWith('/account/principal-profile');
  const urlStartWithInvestorProfile = url.startsWith('/account/investor-profile');

  if (urlStartWithTeamMembers || urlStartWithPrincipalProfile) {
    return permissionService.getFeatures().pipe(
      switchMap((features: any) => {
        if (urlStartWithInvestorProfile && !features['INVESTMENT']?.display) {
          router.createUrlTree(['/']);
          return of(false);
        }

        return accountService.getAccountRequest().pipe(
          take(1),
          map(({ data: { investPrincipals } }) => {
            const principalType = investPrincipals[0].type;

            if (
              principalType === PrincipalTypeEnum.Individual &&
              !features['TEAM_MEMBER']?.display
            ) {
              return router.createUrlTree(['/']);
            }

            return true;
          })
        );
      })
    );
  }

  if (urlStartWithPortfolio || urlStartWithInvestorProfile || urlStartWithPrincipalProfile) {
    return clientService.configuration.pipe(
      map(config => {
        const { type } = config;
        if (type === 'LITE_GP') {
          router.createUrlTree(['/']);
          return false;
        }

        return true;
      })
    );
  }

  return true;
};
