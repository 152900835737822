import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpStatusEnum,
  InboxApi,
  InboxDetailMessage,
  InboxMessage,
  Restful,
  SearchInboxMessageType,
  TaskApi,
  TaskStatus,
  TaskUploadFileErrorEnum
} from '@stream/models';
import { BehaviorSubject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) {}

  unreadCount$ = new BehaviorSubject(0);

  selectedStatus = new BehaviorSubject(TaskStatus.All);

  getInboxList(queryParams?: {
    inBoxMessageType?: string[];
    status?: string;
    searchInboxMessageType?: SearchInboxMessageType;
    taskStatusEnum?: TaskStatus;
  }) {
    return this.accountService.account.pipe(
      take(1),
      switchMap(({ id }) =>
        this.http.post<
          Restful<{
            inBoxMessageVOS?: InboxMessage[];
            commonErrorEnum: HttpStatusEnum;
            unReadNotificationNum: number;
            unReadTaskNum: number;
          }>
        >(InboxApi.GetInboxMessage, { ...(queryParams ?? {}), accountId: id })
      ),
      tap(({ data: { unReadNotificationNum, unReadTaskNum } }) => {
        this.unreadCount$.next(unReadNotificationNum + unReadTaskNum);
      })
    );
  }

  getInboxUnreadCount() {
    return this.http
      .get<
        Restful<{
          unReadNotificationNum: number;
          unReadTaskNum: number;
        }>
      >(InboxApi.GetInboxMessageUnreadCount)
      .pipe(
        tap(res => {
          const { data } = res || {};
          const { unReadNotificationNum = 0, unReadTaskNum = 0 } = data || {};

          this.unreadCount$.next(unReadNotificationNum + unReadTaskNum);
        })
      );
  }

  getBannerInboxList(queryParams: { inBoxMessageType?: string[]; status?: string }) {
    return this.accountService.account.pipe(
      take(1),
      switchMap(({ id }) =>
        this.http.post<
          Restful<{
            inBoxMessageVOS: InboxMessage[];
            commonErrorEnum: HttpStatusEnum;
          }>
        >(InboxApi.GetInboxBanner, { ...queryParams, accountId: id })
      )
    );
  }

  readMessage(ids: string[]) {
    this.unreadCount$.next(this.unreadCount$.value - 1);

    return this.http.post<Restful<{ status: HttpStatusEnum }>>(InboxApi.ReadInboxMessage, {
      ids
    });
  }

  updateTaskSubscriptionFile(params: { referenceFileToken: string; taskId: string }) {
    return this.http.put<Restful<{ status: TaskUploadFileErrorEnum }>>(
      TaskApi.UpdateTaskSubscriptionFile,
      params
    );
  }

  updateTasks(
    taskId: string,
    params: {
      data: any;
    }
  ) {
    return this.http.put<Restful<{ status: TaskUploadFileErrorEnum }>>(
      TaskApi.UpdateTasks.replace(':id', taskId),
      params
    );
  }

  getInboxDetail(inboxId: string) {
    return this.http.get<
      Restful<{
        status: HttpStatusEnum;
        inBoxMessageVO: InboxDetailMessage;
      }>
    >(InboxApi.GetInboxMessageDetail.replace(':id', inboxId));
  }

  transferAmountIRA(params: {
    investorCallId: string;
    subscriptionId: string;
    transferAmount: string;
    messageId: string;
  }) {
    return this.http.post<Restful<{ status: HttpStatusEnum }>>(InboxApi.TransferAmountIRA, params);
  }
}
