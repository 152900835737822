<ngx-extended-pdf-viewer
  [src]="fileUrl ?? ''"
  class="flex-1 pdf-viewer"
  [showUnverifiedSignatures]="true"
  [(formData)]="formData"
  (pageRendered)="onPagesLoaded()"
  [httpHeaders]="httpHeaders"
  [textLayer]="true"
></ngx-extended-pdf-viewer>
<ng-template #footer>
  <button
    mat-stroked-button
    (click)="back.emit()"
    color="primary"
    [disabled]="loading"
    class="mat-button-lg !w-auto flex-1 md:!w-40 md:flex-none"
  >
    {{ 'Back' | buttonName | async }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="loading"
    class="mat-button-lg !w-auto flex-1 md:!w-40 md:flex-none"
    (click)="submit()"
    *ngIf="file"
  >
    {{ 'Submit' | buttonName | async }}
  </button>
</ng-template>
