<div
  class="flex rounded items-center justify-between w-full pl-4 pr-4 pt-2 pb-2 mb-4 bg-[#f8f9fa]"
>
  <stream-data-room-document
    [document]="documentList(document)"
  ></stream-data-room-document>
  <button mat-icon-button type="button">
    <input
      (input)="onInput($event)"
      [accept]="accept"
      class="absolute w-full h-full opacity-0"
      type="file"
    />
    <mat-icon class="text-gray-500">replay</mat-icon>
  </button>
</div>

<div
  *ngIf="loading"
  class="loading-modal absolute top-0 left-0 flex h-full w-full items-center justify-center"
>
  <div class="loader"></div>
</div>
