import { Component, Inject, OnDestroy, ViewContainerRef } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import {
  ContactFile,
  EmailDelayKeyEnum,
  EntityMember,
  EntityMemberRole,
  GatewayNode,
  GatewayProgress,
  IPayMethodType,
  Profile,
  ProfileSignatoryType,
  ProfileType,
  Project,
  SignerType,
  Store
} from '@stream/models';
import { EmailDelay, NotificationService } from '@stream/ngx-utils';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { LocalStorageService } from 'ngx-webstorage';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  of,
  timer
} from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  repeat,
  shareReplay,
  skip,
  skipUntil,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { ErrorMessages, SignatoryOptions } from '../../constant';
import { SCENARIO_CONFIG, ScenarioConfig } from '../../scenario.type';
import {
  ScenarioLateSignService,
  ScenarioPanelService,
  ScenarioService,
  SignService
} from '../../services';
import { ScenarioBaseComponent, ScenarioComponent } from '../scenario/scenario.component';
import { SealSelectDialogComponent } from '../seal-select-dialog/seal-select-dialog.component';
import { SignVerifyDialogComponent } from '../sign-verify-dialog/sign-verify-dialog.component';
import { WitnessFormComponent } from '../witness-form/witness-form.component';

@Component({
  selector: 'stream-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent extends ScenarioBaseComponent implements OnDestroy {
  constructor(
    @Inject(SCENARIO_CONFIG) public config: ScenarioConfig,
    public panelService: ScenarioPanelService,
    private signService: SignService,
    private scenarioComponent: ScenarioComponent,
    private matDialog: MatDialog,
    vcr: ViewContainerRef,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private lateSignService: ScenarioLateSignService,
    private scenarioService: ScenarioService,
    private fb: UntypedFormBuilder
  ) {
    super(scenarioComponent, vcr);
    this.panelService.scenarioProgress.pipe(take(1)).subscribe(({ gateways }) => {
      const currentGateway = gateways.find(({ isGatewayDone }: any) => !isGatewayDone);
      if (currentGateway?.hitResultShow) {
        this.preloadResultGateway = currentGateway;
      }
    });

    // init formGroup
    this.formGroup = this.fb.group({
      contracts: new FormArray([]),
      id: [null, Validators.required]
    });
  }

  @EmailDelay(EmailDelayKeyEnum.SignCaptcha)
  sendEmailDelay!: Observable<number>;

  preloadResultGateway: GatewayProgress | null = null;

  Projects!: Project;

  projectId?: string;

  profile?: Profile;

  // formGroup
  formGroup: FormGroup;

  contracts: ContactFile[] = [];

  contractOptions?: EntityMember[] = [];

  signatoryOptions = SignatoryOptions;

  contractSubscription?: Subscription;

  // global loading state
  loading = true;

  /**
   * !!! This variable is used to submit this order
   */
  subscriptionId?: string;

  ticket = '';

  sealId = '';

  captchaId = '';

  currentFile: ContactFile | null = null;

  valid = false;

  refreshProject = new Subject<void>();

  isSigned = false;

  isFirstContract = true;

  hasCompletedWitness = false;

  needCompleteWitness = true;

  /**
   * late sign submit loading state
   */
  lateSignLoading = false;

  pdfLoading = false;

  // use for sign button
  disabled = false;

  isCustodian = false;

  store = new Store({ type: 'session', namespace: 'scenario' });

  currentStatus = new ReplaySubject<{
    fileSignStatusList: {
      fileName: string;
      id: string;
      progress: number;
      status: string;
      signedUrl: string;
    }[];
    taskStatus: string;
    projectStatus: string;
  }>(1);

  startSign$ = new BehaviorSubject(false);

  signing = this.currentStatus.pipe(
    map(
      ({ fileSignStatusList }) =>
        this.startSign$.value ||
        fileSignStatusList.some(({ progress }) => progress > 0 && progress < 100)
    )
  );

  hasFailed = this.currentStatus.pipe(
    map(({ fileSignStatusList }) => fileSignStatusList.some(({ status }) => status === 'failed'))
  );

  isSign = combineLatest([this.signing, this.hasFailed, this.currentStatus]).pipe(
    map(
      ([signing, hasFailed, currentStatus]) =>
        signing || hasFailed || currentStatus?.taskStatus === 'signed'
    )
  );

  /**
   * 签署人选择阶段无法获取到 project
   */
  project = this.panelService.nodeData.pipe(
    take(1),
    repeat({ delay: () => this.refreshProject }),
    switchMap(node => {
      const {
        nodeInnerType,
        data: { projectId, subscriptionId, selectedProfile }
      } = node;
      // The projectId can only be obtained after submitting the modification process.
      if (this.panelService.isModifyInvest) {
        const snapshot = this.store.get('snapshot');

        this.projectId = this.panelService.projectId.getValue() ?? '';
        this.profile = snapshot?.profile;
        this.subscriptionId = this.panelService.subscriptionId;
      } else {
        this.projectId = projectId ?? this.projectId;
        this.subscriptionId = subscriptionId;
        this.profile = selectedProfile;
      }

      this.isCustodian = this.profile?.payMethod === IPayMethodType.IRA;

      // only sign node execute this logic
      if (nodeInnerType == 'INVESTING_SIGN') {
        this.panelService.isCompleteInvestment = true;

        if (!this.profile) {
          this.loading = false;
          return of(null);
        }

        if (!this.contracts.length && !this.projectId) {
          this.initContracts(this.profile).subscribe(contracts => (this.contracts = contracts));
        }

        if (!this.projectId) {
          return of(null);
        }
      }

      // Obtain specific contract documents and their signature information.
      return this.signService.getProjectDetail(this.projectId).pipe(
        map(x => {
          const responseData = x?.data?.responseData;
          const { fileList, groupList } = responseData;
          const investId =
            nodeInnerType === 'INVESTING_SIGN'
              ? _find(groupList, { name: 'Investment documents' })?.id
              : groupList[0]?.id;

          responseData.signFileList = _filter(fileList, { groupId: investId });

          return responseData;
        }),
        tap(({ ticket }) => {
          if (ticket) {
            this.ticket = ticket;
          }
        }),
        tap(data => {
          const { signFileList } = data;
          this.autoUpdateFormData(signFileList ?? []);

          if (!data.currentSigner?.witnessList?.length) {
            this.needCompleteWitness = false;
          }
        }),

        catchError(() => of(null))
      );
    }),
    shareReplay(1)
  );

  // STREAM-8324 judge whether the current user need sign
  // the reason that don't use the 'currentUserNeedSign' is
  // the 'currentUserNeedSign' would be 'false' before the project is started
  isFirstSigner = this.project.pipe(
    take(1),
    switchMap(project => {
      if (!project) {
        return of(null);
      }

      const firstSigner = (project.signerList || [])[0];
      const result = this.scenarioComponent.userPayload.email$.pipe(
        map(email => firstSigner?.email === email)
      );

      this.panelService.isFirstSigner = result;
      return result;
    })
  );

  getProgressBarMode(): 'indeterminate' | 'determinate' {
    return this.loading ||
      this.panelService.loading.getValue() ||
      this.lateSignLoading ||
      this.pdfLoading
      ? 'indeterminate'
      : 'determinate';
  }

  get showFilesControl() {
    return this.formGroup.get('signatory')?.value === ProfileSignatoryType.onBehalf;
  }

  get showFilesCtrError() {
    const fileCtr = this.formGroup.get('files');
    return fileCtr && fileCtr.touched && fileCtr.invalid;
  }

  /**
   * Get the corresponding contract data through profileId.
   * @param profileId
   * @returns
   */
  getContracts = (profileId: Profile['id']) => {
    return this.panelService.currentNodePath.pipe(
      switchMap(({ gatewayId }) => {
        const { marketingId, productId, taskId } = this.route.snapshot.params;
        const currentProductId = productId || this.localStorage.retrieve('ASSIST_PRODUCT_ID');
        const currentTaskId = taskId || this.localStorage.retrieve('ASSIST_TASK_ID');
        const snapshot = this.store.get('snapshot');
        const params = {
          gatewayIds: [gatewayId],
          productId: currentProductId,
          profileId,
          subscriptionId: this.subscriptionId,
          taskId: currentTaskId,
          marketingId
        };

        if (snapshot && this.panelService.isModifyInvest) {
          return this.lateSignService
            .getGatewayContractsv2({
              ...params,
              subscriptionSnapshotDataJson: snapshot.profile
            })
            .pipe(map(x => x?.data?.contracts));
        }

        return this.scenarioService.getGatewayContracts(params).pipe(map(x => x?.data?.contracts));
      })
    );
  };

  /**
   * init contracts
   */
  initContracts = (profile: Profile) =>
    this.getContracts(profile.id).pipe(
      take(1),
      switchMap(contracts =>
        this.signService.getPdfFiles(contracts.map(contract => contract.contractId)).pipe(
          map(files => {
            // console.info(files, contracts)
            return _map(contracts, contract => {
              const file = _find(files, file => file.id === contract.contractId)!;

              return {
                ...file,
                fileName: contract.contractOriginName ?? file?.fileName
              };
            });
          }),
          finalize(() => {
            const { source } = this.config;
            const { profileType, investorProfileMetaInfoMemberList } = profile;

            // entity side: get contractOptions from profile investorProfileMetaInfoMemberList.
            if (
              profileType === ProfileType.Entity ||
              (profileType === ProfileType.Individual && !this.panelService.isModifyInvest)
            ) {
              this.contractOptions = investorProfileMetaInfoMemberList?.filter(
                ({ memberRole, isSigningAuthority }) =>
                  isSigningAuthority || memberRole === EntityMemberRole.AuthorisedSignatories
              );
              this.loading = false;
            }

            // trigger continue action
            if (
              (source === 'LP' && profileType === ProfileType.Individual) ||
              (source === 'GP' &&
                profileType === ProfileType.Individual &&
                (this.panelService.isModifyInvest || this.isCustodian))
            ) {
              this.continueSign();
            }
          }),
          tap(files => {
            this.initFormControl(files, profile);
          }),
          tap(() => {
            const snapshot = this.store.get('snapshot');

            if (profile.profileType === ProfileType.Individual) {
              Object.values((this.formGroup.get('contracts') as FormArray).controls).forEach(
                control => {
                  Object.values((control.get('signPlaceholders') as FormArray).controls).forEach(
                    signerControl => {
                      signerControl
                        .get('individualId')
                        ?.setValue(profile.investorProfileMetaIndividual?.id);
                    }
                  );
                }
              );
              this.formGroup.updateValueAndValidity();

              return;
            }

            // modify investment process: display contract selected data.
            if (snapshot && this.panelService.isModifyInvest) {
              // TODO: Legalize older versions，delete this logic when after sprint 125
              // !!! Compatible with old logic，historical subscription contract data is obtained from profile node.
              const { investing_profile, investing_sign } = snapshot;
              const profile_contracts = investing_profile?.component_investment_profile?.contracts;
              const sign_contracts = investing_sign?.component_investment_sign?.contracts;
              const contracts = sign_contracts ?? profile_contracts;

              Object.values((this.formGroup.get('contracts') as FormArray).controls).forEach(
                control => {
                  const fileName = control.get('contractName')?.value;
                  const _contract = contracts.find((item: any) => item.contractName === fileName);
                  const signPlaceholders = Object.values(
                    (control.get('signPlaceholders') as FormArray).controls
                  );

                  if (_contract && signPlaceholders.length === _contract.signPlaceholders.length) {
                    Object.values((control.get('signPlaceholders') as FormArray).controls).forEach(
                      (signerControl, index) => {
                        signerControl
                          .get('individualId')
                          ?.setValue(_contract.signPlaceholders[index]?.individualId);
                      }
                    );
                  }
                }
              );

              this.formGroup.updateValueAndValidity();
            }
          })
        )
      ),
      catchError(() => {
        this.loading = false;
        return of([]);
      }),
      shareReplay(1)
    );

  /**
   * init contract form data
   * @param files
   * @param profile
   */
  initFormControl(files: ContactFile[], profile: Profile | undefined) {
    this.formGroup.get('id')?.setValue(profile!.id);

    // GP side: behalf & authorization file logic
    if (
      this.config.source === 'GP' &&
      profile?.profileType === ProfileType.Individual &&
      !this.panelService.isModifyInvest
    ) {
      const signatory = new FormControl(ProfileSignatoryType.personally, [Validators.required]);

      this.formGroup.addControl('signatory', signatory);
    }

    files.forEach(file => {
      (this.formGroup.get('contracts') as FormArray).push(
        new FormGroup({
          contract: new FormControl(file.id),
          contractName: new FormControl(file.fileName),
          signPlaceholders: new FormArray(
            file.signPlaceholderList.map(
              ({ id, required }) =>
                new FormGroup({
                  signPlaceholder: new FormControl(id),
                  individualId: new FormControl(null, [
                    required ? Validators.required : () => null,
                    profile?.profileType === ProfileType.Entity
                      ? control =>
                          (control.parent?.parent as FormArray)?.controls
                            .filter(v => v.get('individualId') !== control)
                            .some(
                              otherControl =>
                                control.value &&
                                otherControl.get('individualId')?.value === control.value
                            )
                            ? { duplicate: true }
                            : null
                      : () => null
                  ])
                })
            ),
            {
              validators:
                profile?.profileType === ProfileType.Entity
                  ? (this.duplicateSignatoryValidator as ValidatorFn)
                  : null
            }
          )
        })
      );
    });
  }

  /**
   * Custom validator
   * @param param0
   * @returns
   */
  duplicateSignatoryValidator = ({ controls }: FormArray) => {
    let hasDuplicate = false;

    controls
      .filter(k => k.get('individualId')?.errors?.['duplicate'] === true)
      .forEach(k => {
        k.get('individualId')?.setErrors(null, { emitEvent: false });
        k.get('individualId')?.markAsPristine({ onlySelf: true });
      });

    for (let i = 0, ii = controls.length; i < ii; i++) {
      let hasDup = false;

      const ctr = controls[i];

      for (let j = controls.length - 1; j > i; j--) {
        if (
          ctr.value['individualId'] &&
          controls[j].value['individualId'] === ctr.value['individualId']
        ) {
          controls[j].get('individualId')?.setErrors({ duplicate: true }, { emitEvent: false });
          hasDuplicate = true;
          hasDup = true;
        }
      }
      if (hasDup) {
        ctr.get('individualId')?.setErrors({ duplicate: true }, { emitEvent: false });
      }
    }
    return hasDuplicate ? { duplicate: true } : null;
  };

  onSignatoryChange($event: MatRadioChange) {
    const { value } = $event;

    if (value === ProfileSignatoryType.personally) {
      this.formGroup.get('files')?.patchValue([], { eventEmit: false });
      this.formGroup.removeControl('files');
    } else {
      const filesControl = new FormControl([], [Validators.required]);
      this.formGroup.addControl('files', filesControl);
      this.formGroup.get('files')?.markAsUntouched();
    }
  }

  /**
   * continue logic
   * @returns
   */
  continueSign() {
    const formValue = this.formGroup.value;
    const snapshot = this.store.get('snapshot');

    // TODO: Legalize older versions，modify this logic when after sprint 125
    if (snapshot && this.panelService.isModifyInvest && this.config.source === 'GP') {
      const sign = snapshot?.investing_sign?.component_investment_sign;
      const profile = snapshot.investing_profile?.component_investment_profile;

      Object.assign(formValue, {
        signatory: sign?.signatory ?? profile?.signatory,
        files: sign?.files ?? profile?.files
      });
    }

    const params = {
      result: { component_investment_sign: formValue },
      // next: false,
      ...this.config
    };

    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) return;

    this.loading = true;
    this.panelService.submitNodeResult(params).subscribe(
      ({ businessData }) => {
        this.panelService.projectId.next(businessData?.projectId);
      },
      () => {
        this.loading = false;
      }
    );
  }

  reviewPdf(file: ContactFile) {
    if (this.projectId) {
      this.currentFile = file;
    }
  }

  private polling$ = timer(0, 3000)
    .pipe(filter(() => !!this.ticket && !this.isSigned))
    .subscribe(() =>
      this.signService
        .getProjectStatus(this.ticket)
        .pipe(map(x => x?.data?.responseData))
        .subscribe(status => {
          // STREAM-8306 do not update the status after the project is signed
          // cause the status will be reset to 'signing' during the website deploying
          if (status.taskStatus === 'signed') {
            this.isSigned = true;
          }
          this.currentStatus.next(status);
        })
    );

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.polling$.unsubscribe();
  }

  trackByFn = (
    _index: number,
    {
      id
    }: {
      fileName: string;
      id: string;
      progress: number;
      status: string;
      signedUrl: string;
    }
  ) => id;

  /**
   * Auto update file's form data
   * @param fileList
   * @returns
   */
  private autoUpdateFormData(fileList: ContactFile[]) {
    // Avoid unnecessary requests for dynamic contract data
    if (!this.isFirstContract) {
      return;
    }

    (!this.panelService.isModifyInvest
      ? this.panelService.nodeData
      : this.panelService.getModifyContractTags
    )
      .pipe(take(1))
      .subscribe(({ signDocumentsKeyValue, customerJsonKeyValue = {} }) => {
        const isModifyInvest = this.panelService.isModifyInvest;
        const _fileList = isModifyInvest
          ? fileList
          : fileList.filter(({ formData }) => !Object.keys(formData).length);
        if (_fileList.length) {
          (isModifyInvest
            ? this.panelService.submitNodeResult({
                businessData: {
                  contractDataToBeUpdated: fileList.map(file => {
                    return {
                      fileId: file.id,
                      formData: {
                        ...file.formData,
                        ...signDocumentsKeyValue,
                        ...customerJsonKeyValue[
                          Object.keys(customerJsonKeyValue).find(name =>
                            file.fileName.startsWith(name)
                          ) ?? ''
                        ],
                        ...customerJsonKeyValue['contractArray']?.find(
                          (contractInfo: any) => contractInfo.contractName === file.fileName
                        )?.signPlaceholders
                      }
                    };
                  })
                },
                next: false
              })
            : this.signService.updateProjectFiles(
                fileList.map(file => {
                  return {
                    fileId: file.id,
                    formData: {
                      ...signDocumentsKeyValue,
                      ...customerJsonKeyValue[
                        Object.keys(customerJsonKeyValue).find(name =>
                          file.fileName.startsWith(name)
                        ) ?? ''
                      ],
                      ...customerJsonKeyValue['contractArray']?.find(
                        (contractInfo: any) => contractInfo.contractName === file.fileName
                      )?.signPlaceholders,
                      ...file.formData
                    }
                  };
                })
              )
          ).subscribe(
            () => {
              this.loading = false;
              this.panelService.loading.next(false);
              this.isFirstContract = false;
              this.refreshProject.next();
            },
            () => {
              this.loading = false;
            }
          );
        } else {
          this.loading = false;
        }
      });
  }

  updateFileList() {
    this.refreshProject.next();
    this.currentFile = null;
  }

  syncPdfLoading(loading: boolean) {
    this.pdfLoading = loading;
  }

  startUp() {
    const sendEmail = this.config.source === 'GP' && this.isCustodian;

    // when start up
    this.disabled = true;
    this.panelService.loading.next(true);
    this.project.pipe(take(1)).subscribe(res => {
      if (!res) {
        return;
      }

      // only project status is draft can call startUpProject
      if (res.status !== 'draft') {
        this.leave(true);
        return;
      }

      this.signService
        .startUpProject(res.id, sendEmail)
        .pipe(
          finalize(() => {
            this.submitSubscription();
            this.panelService.loading.next(false);
          })
        )
        .subscribe(
          ({
            data: {
              responseData: { ticket, currentUserNeedSign }
            }
          }) => {
            // different source has different logic
            if (this.config.source === 'GP') {
              // STREAM-7486 WM signer
              if (currentUserNeedSign && !this.isCustodian) {
                this.refreshProject.next();
                this.ticket = ticket as string;
                this.openWitnessForm().subscribe(result => {
                  if (this.hasCompletedWitness || !this.needCompleteWitness || result) {
                    this.sign();
                  }
                });
              } else {
                this.disabled = false;
                this.leave();
              }
            }
            if (this.config.source === 'LP') {
              this.isFirstSigner.pipe(take(1)).subscribe(value => {
                if (value) {
                  this.refreshProject.next();
                  this.ticket = ticket as string;
                  this.openWitnessForm().subscribe(result => {
                    if (this.hasCompletedWitness || !this.needCompleteWitness || result) {
                      this.sign();
                    }
                  });
                } else {
                  this.disabled = false;
                  this.leave();
                }
              });
            }
          },
          err => {
            if (err.error.status === 'projectRevoked') {
              this.panelService.leave.next('projectRevoked');
            }
          }
        );
    });
  }

  openWitnessForm() {
    const subject = new Subject<boolean>();
    this.project
      .pipe(
        take(1),
        switchMap(project => {
          if (!project) {
            return of(null);
          }

          if (project.currentSigner) {
            return of(project);
          }
          return this.signService
            .getProjectDetail(project.id)
            .pipe(map(x => x?.data?.responseData));
        })
      )
      .subscribe(
        res => {
          if (!res) {
            return;
          }

          const { currentSigner, signerList } = res;
          const { type, witnessList } = currentSigner;

          if (!currentSigner) {
            subject.next(false);
            return;
          }

          if (!witnessList?.length) {
            this.needCompleteWitness = false;
            subject.next(false);
            return;
          }

          const witness =
            type === SignerType.lp || type === SignerType.gp
              ? currentSigner.witnessList?.[0]
              : currentSigner;
          const dialog = this.matDialog.open(WitnessFormComponent, {
            data: {
              witness,
              signerType: type,
              signerList: signerList
            }
          });
          dialog.afterClosed().subscribe(result => {
            if (result) {
              this.hasCompletedWitness = true;
            }
            subject.next(!!result);
          });
        },
        () => {
          this.disabled = false;
        }
      );
    return subject;
  }

  sendCode() {
    // before send code
    this.panelService.loading.next(true);
    this.signService
      .signCaptcha(this.ticket)
      .pipe(
        finalize(() => {
          this.panelService.loading.next(false);
        })
      )
      .subscribe(
        ({ data: { responseData } }) => {
          this.captchaId = responseData;
          this.verify();
        },
        err => {
          if (err.error.status === 'projectRevoked') {
            this.panelService.leave.next('projectRevoked');
          }
        }
      );
  }

  verify() {
    // 'currentSigner' only has value after the project started.
    // and refreshProject is async, so there has a condition to handle it.
    this.project
      .pipe(
        take(1),
        switchMap(project => {
          if (!project) {
            return of(null);
          }

          if (project.currentSigner || !this.projectId) {
            return of(project);
          }

          return this.signService
            .getProjectDetail(this.projectId)
            .pipe(map(x => x?.data?.responseData));
        })
      )
      .subscribe(project => {
        const dialogRef = this.matDialog.open(SignVerifyDialogComponent, {
          data: {
            captchaId: this.captchaId,
            ticket: this.ticket,
            email: project?.currentSigner?.email,
            panelService: this.panelService
          }
        });
        dialogRef.afterClosed().subscribe(
          (
            { captchaId, result } = {
              captchaId: this.captchaId,
              result: false
            }
          ) => {
            this.disabled = false;
            this.captchaId = captchaId;
            this.valid = result;
            if (result) {
              this.sign();
            }
          }
        );
      });
  }

  /**
   * !!! NDA / Screening universal node logic
   */
  submitUniversal() {
    // The signing logic of the general class relies on the completion status of the submission node after the front-end signing is completed,
    // and synchronously participates in the judgment logic of the entire gateway node
    // (there is currently no asynchronous implementation mechanism and business scenario)
    this.panelService.nodeData.pipe(take(1)).subscribe(nodeData => {
      // Universal signing node and signed
      if (nodeData.nodeInnerType === 'UNIVERSAL_SIGN') {
        // Submit Universal signing result
        this.panelService.submitNodeResult(this.config).subscribe();
      } else {
        this.panelService.getProgress();
      }
    });
  }

  nextStep() {
    this.panelService.loading.next(true);
    this.submitUniversal();
    this.panelService.scenarioProgress.pipe(skip(1), take(1)).subscribe(() => {
      this.panelService.loading.next(false);
    });
  }

  addSuffix(fileName: string) {
    if (fileName.endsWith('.pdf')) {
      return fileName;
    }

    return fileName + '.pdf';
  }

  beforeSign() {
    this.disabled = true;

    if (this.hasCompletedWitness || !this.needCompleteWitness) {
      this.sign();
      return;
    }

    this.openWitnessForm().subscribe(result => {
      if (result) {
        this.sign();
      }
    });
  }

  sign() {
    if (!this.valid) {
      if (!this.captchaId) {
        this.sendCode();
      } else {
        this.verify();
      }
    } else {
      if (this.sealId) {
        this.doSign(this.sealId);
      } else {
        this.project.pipe(take(1)).subscribe(project => {
          if (!project) {
            return;
          }

          const dialogRef = this.matDialog.open(SealSelectDialogComponent, {
            data: {
              fullName: project.signerList[0].fullName,
              ticket: this.ticket
            }
          });

          dialogRef.afterClosed().subscribe((sealId: string) => {
            if (sealId) {
              this.startSign$.next(true);
              this.sealId = sealId;
              this.doSign(sealId);
            }
          });
        });
      }
    }
  }

  private doSign(sealId: string) {
    this.startSign$.next(true);
    this.signService
      .signProject(this.ticket, sealId)
      .pipe(
        finalize(() => {
          this.currentStatus
            .pipe(
              skipUntil(
                this.currentStatus.pipe(
                  map(({ fileSignStatusList }) =>
                    fileSignStatusList.some(({ progress }) => progress > 0)
                  )
                )
              ),
              take(1)
            )
            .subscribe(() => {
              this.startSign$.next(false);
            });
        })
      )
      .subscribe(
        () => {},
        err => {
          if (err.error.status === 'projectRevoked') {
            this.panelService.leave.next('projectRevoked');
          }
        }
      );
  }

  discard() {
    this.panelService.discard.next(null);
  }

  leave(onlyLeave: boolean = false) {
    if (!onlyLeave) {
      this.submitUniversal();
    }

    this.panelService.nodeData.pipe(take(1)).subscribe(({ nodeInnerType, data }) => {
      if (nodeInnerType === 'INVESTING_SIGN') {
        const discardFn$ = onlyLeave ? of(true) : this.panelService.discardInvest();

        if (!discardFn$) return;
        discardFn$.subscribe(() => {
          if (this.config.source === 'GP') {
            const subscriptionInfo: Partial<GatewayNode['data']> = {
              subscriptionId: data?.subscriptionId,
              isDeliveryOrder: data?.isDeliveryOrder
            };
            this.scenarioComponent.commit.next(subscriptionInfo);
          }
          if (this.config.source === 'LP') {
            this.scenarioComponent.commit.next();
          }
          if (this.preloadResultGateway && this.config.source !== 'GP') {
            this.panelService.resultGateway.next(this.preloadResultGateway);
          } else {
            this.panelService.leave.next('');
          }
        });
      }
    });
  }

  lateSign() {
    const subscriptionInfo = {
      isLateSign: true,
      isModifyInvest: this.panelService.isModifyInvest,
      subscriptionId: this.subscriptionId,
      behalf: this.isFirstSigner
    };

    // Cancel scenario progress
    if (!this.panelService.isModifyInvest) {
      this.panelService.discardInvest(false)?.subscribe();
    }

    // Submit subscription
    this.submitSubscription();

    // Re-modify process, confirm submit subscription info
    if (this.panelService.isModifyInvest) {
      this.lateSignLoading = true;
      this.panelService.subscriptionConfirm
        .pipe(
          catchError(() => {
            this.notification.error(ErrorMessages.general);
            this.lateSignLoading = false;
            return of(false);
          })
        )
        .subscribe(data => {
          if (data) {
            this.updateScenarioComponent(this.config.source, subscriptionInfo);
          }
        });
    } else {
      this.updateScenarioComponent(this.config.source, subscriptionInfo);
    }

    this.store.clear('scenario');
  }

  submitSubscription() {
    if (this.subscriptionId && !this.panelService.isModifyInvest) {
      this.lateSignService.submitSubscription(this.subscriptionId).subscribe();
    }
  }

  updateScenarioComponent(source?: 'LP' | 'GP', subscriptionInfo?: any) {
    if (source === 'LP') {
      this.scenarioComponent.commit.next();
      if (this.config.source === 'LP') {
        this.panelService.resultGateway.next(this.preloadResultGateway);
      }
    }

    if (source === 'GP') {
      this.scenarioComponent.commit.next(subscriptionInfo);
    }
  }

  downloadFile(fileId: string, fileName: string) {
    this.signService.downloadFile(fileId).subscribe(
      blob => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      },
      () => {
        this.notification.error('There seems to be a little problem.');
      }
    );
  }

  hasSignPermission() {
    const isTeamMember$ = this.scenarioComponent.userPayload.isTeamMember$;

    if (this.isCustodian && this.config.source === 'GP') {
      return of(false);
    }

    if (!isTeamMember$) {
      return of(true);
    }

    return isTeamMember$.pipe(
      switchMap(v => {
        if (!v) return of(true);
        return this.isFirstSigner;
      })
    );
  }
}
