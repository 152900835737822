<div *ngIf="(hidden$ | async) === false" class="pb-4">
  <h2
    *ngIf="tooltipAdditionalDocuments"
    nz-popover
    nzPopoverOverlayClassName="max-w-xl"
    nzPopoverPlacement="bottom"
    [nzPopoverOrigin]="icon._elementRef"
    [nzPopoverContent]="info"
    class="mb-2 text-lg font-semibold"
  >
    {{ title }}
    <mat-icon color="primary" class="ml-2 !h-auto align-sub !text-xl" #icon>info_outlined</mat-icon>
  </h2>

  <h2 *ngIf="!tooltipAdditionalDocuments" class="mb-2 text-lg font-semibold">{{ title }}</h2>
  <ng-template #info>
    <stream-quill-view
      [content]="tooltipAdditionalDocuments"
      [pureViewer]="true"
    ></stream-quill-view>
  </ng-template>

  <div class="relative flex w-full flex-col gap-y-4">
    <div
      *ngFor="let doc of documents || []; let i = index"
      class="relative flex w-full items-center gap-x-2 rounded bg-[#f8f9fa] px-3 py-3 hover:bg-primary-50 md:px-5"
      (click)="preview(doc)"
    >
      <svg class="icon flex-none text-lg">
        <use [attr.xlink:href]="getIconByType(doc?.fileType)"></use>
      </svg>
      <div class="flex max-w-fit items-center gap-x-1 text-sm text-gray-700">
        <span class="line-clamp-1">{{ doc.fileName || '--' }}</span>
      </div>
      <span
        *ngIf="doc.tag"
        class="min-w-min rounded bg-[#fff9eb] px-2 py-0.5 text-black md:min-w-fit"
      >
        {{ getDocumentTagLabel(doc) }}
      </span>
      <span class="flex-1"></span>
      <button
        class="flex-none"
        mat-icon-button
        type="button"
        (click)="$event.stopPropagation(); deleteFile(doc)"
      >
        <mat-icon class="text-gray-500">delete</mat-icon>
      </button>
    </div>
    <div
      #UPLOAD_BUTTON
      [ngClass]="{
        'cursor-pointer': !submitting,
        'cursor-not-allowed': submitting
      }"
      class="upload-button relative flex w-full flex-col items-start justify-center rounded border border-solid border-[rgb(229,231,235)] px-5 py-4"
      (click)="submitting ? null : openMdSelector()"
    >
      <span class="upload-title text-base text-primary-700">Add document</span>
      <span class="upload-placeholder text-[#62656C]"
        >You can upload a new file or select one from your investor profile</span
      >
      <span class="material-icons absolute right-5"> navigate_next </span>
      <div class="loading-modal" *ngIf="submitting">
        <div class="loader"></div>
      </div>
    </div>
    <div class="loading-modal" *ngIf="loading">
      <div class="loader"></div>
    </div>
  </div>
</div>
