import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormlyFieldRadio } from '@ngx-formly/material/radio';
import { Subscription } from 'rxjs';

@Component({
  selector: 'stream-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent extends FormlyFieldRadio implements AfterViewInit, OnDestroy {
  @HostBinding('class.vertical')
  get isVertical() {
    return (this.to as any).layout === 'inline';
  }

  otherControl = new UntypedFormControl('');

  get otherOption() {
    return (this.to.options as any)?.find((option: any) => option.value === 'Other');
  }

  valueChangeSubscription?: Subscription;

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (
      this.formControl.value &&
      (this.to.options as any[])?.every(({ value }) => value !== this.formControl.value)
    ) {
      this.otherControl.setValue(this.formControl.value);
    }
    this.valueChangeSubscription = this.formControl.valueChanges.subscribe(value => {
      if (!value) {
        if (this.to.required) {
          this.formControl.setErrors({ required: true });
        }
      } else {
        const { required, ...otherError } = this.formControl.errors || {};
        this.formControl.setErrors(Object.keys(otherError).length ? otherError : null);
      }
    });
    if (this.otherOption?.disabled) {
      this.otherControl.disable();
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.valueChangeSubscription?.unsubscribe();
  }
}
