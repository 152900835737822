import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AnswerResult,
  Question,
  QuestionnaireStatusEnum,
  Restful,
  SurveyApi,
} from '@stream/models';

@Injectable()
export class QuestionnaireService {
  constructor(private http: HttpClient) {}

  /**
   * 获取问题
   * @param path 问题索引路径
   * @returns
   */
  getQuestion(path: { recordId: string; questionId: string }) {
    return this.http.get<
      Restful<{
        description: string;
        responseData: Question;
        status: QuestionnaireStatusEnum;
      }>
    >(SurveyApi.GetQuestion, {
      params: path,
    });
  }

  /**
   * 提交回答
   * @param path 问题索引路径
   * @param value 答案
   * @returns
   */
  submitAnswer(path: { recordId: string; questionId: string }, value: any) {
    return this.http.post<
      Restful<{
        description: string;
        responseData: AnswerResult;
        status: QuestionnaireStatusEnum;
      }>
    >(SurveyApi.SubmitAnswer, { ...path, value });
  }

  /**
   * 清空回答
   * @param path 问题索引路径
   * @returns
   */
  clearAnswer(path: { recordId: string; questionId: string }) {
    return this.http.post<
      Restful<{
        description: string;
        responseData: { previousQuestionId: string; progress: number };
        status: QuestionnaireStatusEnum;
      }>
    >(SurveyApi.ClearAnswer, path);
  }
}
