import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import {
  FORM_ERROR_MESSAGE,
  FormErrorCodeEnum,
  StreamFormlyConfig,
  StreamFormlyType
} from '@stream/models';
import { phoneValidatorFn } from '@stream/utils';

import { CountriesModule, CountriesService } from '@stream/libs/common/countries';
import { PhoneWrapperComponent } from './phone-wrapper/phone-wrapper.component';

@NgModule({
  declarations: [PhoneWrapperComponent],
  imports: [
    CommonModule,
    CountriesModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'phone-wrapper',
          component: PhoneWrapperComponent
        }
      ],
      validators: [
        {
          name: FormErrorCodeEnum.Phone,
          validation: (control: AbstractControl, fieldConfig: FormlyFieldConfig) =>
            phoneValidatorFn(fieldConfig.parent?.formControl?.value.areaCode, control.value)
              ? null
              : { phone: true }
        }
      ],
      validationMessages: [
        {
          name: FormErrorCodeEnum.Phone,
          message: FORM_ERROR_MESSAGE.get(FormErrorCodeEnum.Phone) ?? ''
        }
      ]
    })
  ]
})
export class PhoneModule {
  static countriesService = new CountriesService();

  /**
   * 转换phone类型
   * @param param
   * @returns
   */
  static transformPhoneField({
    id,
    key,
    name,
    defaultValue,
    asyncValidators,
    validators = '',
    type,
    placeholder,
    required,
    ...templateOptions
  }: StreamFormlyConfig): FormlyFieldConfig {
    const { telephoneAreaCode, telephone } = JSON.parse(defaultValue || '{}');

    return {
      id,
      key,
      name,
      wrappers: ['phone-wrapper'],
      templateOptions,
      fieldGroup: [
        {
          key: 'areaCode',
          type: StreamFormlyType.AREA_CODE,
          className: 'stream-formly-area-code',
          defaultValue: telephoneAreaCode,
          templateOptions: {
            label: 'Country code',
            placeholder: (placeholder as Record<string, string>)?.['areaCode'],
            required,
            options: PhoneModule.countriesService.getCountriesArray().map(item => ({
              label: `${item.name} +${item.dialCode}`,
              value: item.countryCode
            }))
          }
        },
        {
          key: 'phoneNumber',
          type: StreamFormlyType.INPUT,
          className: 'stream-formly-phone',
          defaultValue: telephone,
          validators: {
            validation: ['phone']
          },
          templateOptions: {
            type: 'tel',
            key: 'phone-number',
            attributes: {
              autocomplete: 'tel-national'
            },
            placeholder: (placeholder as Record<string, string>)?.['phoneNumber'],
            label: 'Phone number',
            required
          }
        }
      ]
    };
  }
}
