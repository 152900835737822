import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import {
  ProfileCardComponent,
  ProfileSettingService,
  ScenarioPanelService,
  ScenarioService,
  ScenarioSnapshot
} from '@stream/libs/common';
import { Profile, ProfileType, Store } from '@stream/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, shareReplay, switchMap } from 'rxjs/operators';

import { PermissionService } from '@stream/libs/common/utils/service';
import { ClientService } from '@stream/service/client.service';
import { SharedService } from '@stream/shared';
import { FormlyService } from '../../formly.service';
import { StreamFormlyComponent, StreamFormlyEventTypeEnum } from '../../stream-formly.component';

@Component({
  selector: 'stream-profile-radio',
  templateUrl: './profile-radio.component.html',
  styleUrls: ['./profile-radio.component.scss']
})
export class ProfileRadioComponent extends FieldType<any> implements OnInit {
  constructor(
    private clientService: ClientService,
    private scenarioService: ScenarioService,
    private scenarioPanelService: ScenarioPanelService,
    private formlyService: FormlyService,
    private formlyComponent: StreamFormlyComponent,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private permissionService: PermissionService,
    private profileSettingService: ProfileSettingService
  ) {
    super();
  }

  store = new Store({ type: 'session', namespace: 'scenario' });
  StreamFormlyEventTypeEnum = StreamFormlyEventTypeEnum;
  ProfileType = ProfileType;
  currentProfile = new BehaviorSubject<Profile | undefined>(undefined);
  reModify = false;

  @ViewChildren('profile') profiles?: QueryList<ProfileCardComponent>;

  profileList = this.initProfileList();

  isDisableIRA: boolean = false;
  isUnsupportCustodian: boolean = false;

  initProfileList() {
    this.profileSettingService.updateTenantProfileSettings$.next();
    const snapshot = this.store.get('snapshot') as ScenarioSnapshot;
    if (snapshot) {
      this.reModify = true;
      return of([snapshot.profile]);
    }
    return this.formlyService.profileList.pipe(
      filter(v => v != null),
      switchMap(v => v as Observable<Profile[]>),
      shareReplay(1)
    );
  }

  duplicateSignatoryValidator = ({ controls }: FormArray) => {
    controls
      .filter(k => k.get('individualId')?.errors?.['duplicate'] === true)
      .forEach(k => {
        k.get('individualId')?.setErrors(null, { emitEvent: false });
        k.get('individualId')?.markAsPristine({ onlySelf: true });
      });
    let hasDuplicate = false;
    for (let i = 0, ii = controls.length; i < ii; i++) {
      const ctr = controls[i];
      let hasDup = false;
      for (let j = controls.length - 1; j > i; j--) {
        if (
          ctr.value['individualId'] &&
          controls[j].value['individualId'] === ctr.value['individualId']
        ) {
          controls[j].get('individualId')?.setErrors({ duplicate: true }, { emitEvent: false });
          hasDuplicate = true;
          hasDup = true;
        }
      }
      if (hasDup) {
        ctr.get('individualId')?.setErrors({ duplicate: true }, { emitEvent: false });
      }
    }
    return hasDuplicate ? { duplicate: true } : null;
  };

  prePopulate(field: FormlyFieldConfig) {
    (field as any).formControl = new UntypedFormGroup({
      id: new UntypedFormControl(null)
    });
  }

  async checkEnableIRA(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.permissionService
        .getFeatureEnabled({
          featureKey: 'INSPIRA_IRA'
        })
        .subscribe(
          flag => {
            this.isDisableIRA = !flag;
            resolve(this.isDisableIRA);
          },
          error => {
            this.isDisableIRA = true;
            resolve(this.isDisableIRA);
          }
        );
    });
  }

  async ngOnInit(): Promise<void> {
    // todo 不继承 夫级的 ngOnInit
    this.formControl.get('id')?.addValidators((control: any) => {
      const currentProfileComponent = this.profiles?.find(
        item => item.profile.id === control.value
      );
      return currentProfileComponent?.progress === 100 ? null : { profileNotCompleted: true };
    });

    const reModify = !!this.store.get('snapshot');
    if (!reModify) {
      await this.checkEnableIRA();

      if (!this.isDisableIRA) {
        this.scenarioService
          .queryProductCustodianStatus({
            productId: this.scenarioPanelService.productId
          })
          .subscribe(res => {
            const { assetMt } = res?.data || {};
            const { iraInvestmentEnabled, mtAssetStatus } = assetMt || {};
            this.isUnsupportCustodian = !(
              iraInvestmentEnabled && mtAssetStatus.toLocaleUpperCase() === 'ACCEPTED'
            );
          });
      }
    }
  }

  profileEventEmit(type: StreamFormlyEventTypeEnum, params?: any) {
    this.formlyComponent.formEvent.emit({ type, params });
  }

  handleDisabledRadioChange(params: { profile: Profile; valueDisabledRadio: boolean }) {
    const { profile, valueDisabledRadio } = params;
    profile.valueDisabledRadio = valueDisabledRadio;
    this.cdr.detectChanges();
  }

  onProfileChange({ value }: { value: Profile['id'] }) {
    const currentProfileComponent = this.profiles?.find(item => item.profile.id === value);
    this.formlyComponent.radioChange.emit(currentProfileComponent);
  }

  validator(): void {
    // console.log(this.formControl);
  }

  ngAfterViewInit(): void {
    if (this.reModify) {
      this.el.nativeElement.querySelector('.profile-card').click();
      this.cdr.detectChanges();
    }
  }
}
