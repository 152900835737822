import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isQuillHasContent } from '@stream/utils';

import { FormlyService } from '../formly.service';

@Component({
  template: `
    <h1 class="stream-formly-title" [ngStyle]="to['styles']">
      {{ to.label }}
      <stream-tooltips *ngIf="showTooltips" [content]="to['tooltip']"></stream-tooltips>
    </h1>
    <ngx-form-progress
      [formGroup]="form"
      [loading]="loading | async"
      class="progress-bar"
    ></ngx-form-progress>
  `,
  styles: [
    `
      .stream-formly-title {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 30px;
        text-align: center;
      }
      .progress-bar {
        margin-bottom: 1.25rem;
      }
    `
  ]
})
export class HeaderComponent extends FieldType {
  constructor(private formlyService: FormlyService) {
    super();
  }

  loading = this.formlyService.loading;

  get showTooltips() {
    return isQuillHasContent(this.to?.['tooltip']);
  }
}
