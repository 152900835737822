import { IMessageFileValueType } from '../message-file/message-file.model';

export interface IEMessageItemInfoType {
  id?: string | number;
  position?: EMessageItemInfoPosition;
  type?: 'MESSAGE' | 'LINE' | any; // 可用于区分消息还是分割线
  status?: EMessageItemInfoStatus;
  content?: string; // 文本使用
  attachment?: IMessageFileValueType[]; // 文件使用
  rolesType?: string[];
  textBlack?: string;
  textGray?: string;
}

export enum EMessageItemInfoPosition {
  left = 'left',
  right = 'right',
}

export enum EMessageItemInfoStatus {
  success = 'success',
  sending = 'sending',
  failed = 'failed',
}

export enum EMessageItemInfoRoles {
  BIZ_OPS = 'BIZ_OPS',
  owner = 'owner',
  wmCandidate = 'wmCandidate',
  LP = 'LP',
  INVESTMENT_DIRECTOR = 'INVESTMENT_DIRECTOR',
  HEAD_OF_INVESTOR_RELATIONS = 'HEAD_OF_INVESTOR_RELATIONS',
  admin = 'admin',
  LEGAL_COUNSEL = 'LEGAL_COUNSEL',
  RELATIONSHIP_MANAGER = 'RELATIONSHIP_MANAGER',
  WEALTH_MANAGER_IFA_RIA = 'WEALTH_MANAGER_IFA_RIA',
  OPERATIONAL_SUPPORT = 'OPERATIONAL_SUPPORT',
  FUND_ADMINISTRATOR = 'FUND_ADMINISTRATOR',
  FUND_SIGNATORY = 'FUND_SIGNATORY',
  GP_MEMBER = 'GP_MEMBER',
}

export const rolesLabel = {
  [EMessageItemInfoRoles.wmCandidate]: 'WM Candidate',
  [EMessageItemInfoRoles.LP]: 'LP',
  [EMessageItemInfoRoles.INVESTMENT_DIRECTOR]: 'Fund Director',
  [EMessageItemInfoRoles.HEAD_OF_INVESTOR_RELATIONS]: 'Head of IR',
  [EMessageItemInfoRoles.admin]: 'Fund Manager',
  [EMessageItemInfoRoles.LEGAL_COUNSEL]: 'Legal Counsel',
  [EMessageItemInfoRoles.RELATIONSHIP_MANAGER]: 'Sales',
  [EMessageItemInfoRoles.WEALTH_MANAGER_IFA_RIA]: 'Sales',
  [EMessageItemInfoRoles.OPERATIONAL_SUPPORT]: 'Operational Support',
  [EMessageItemInfoRoles.FUND_ADMINISTRATOR]: 'Fund Admin',
  [EMessageItemInfoRoles.FUND_SIGNATORY]: 'Fund Signatory',
  [EMessageItemInfoRoles.GP_MEMBER]: 'GP Member',
};

// 身份标签权重
export const rolesOrderList = [
  EMessageItemInfoRoles.wmCandidate,
  EMessageItemInfoRoles.LP,
  EMessageItemInfoRoles.INVESTMENT_DIRECTOR,
  EMessageItemInfoRoles.HEAD_OF_INVESTOR_RELATIONS,
  EMessageItemInfoRoles.admin,
  EMessageItemInfoRoles.LEGAL_COUNSEL,
  EMessageItemInfoRoles.RELATIONSHIP_MANAGER,
  EMessageItemInfoRoles.WEALTH_MANAGER_IFA_RIA,
  EMessageItemInfoRoles.OPERATIONAL_SUPPORT,
  EMessageItemInfoRoles.FUND_ADMINISTRATOR,
  EMessageItemInfoRoles.FUND_SIGNATORY,
];
