import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isQuillHasContent } from '@stream/utils';

@Component({
  template: `
    <p class="stream-formly-text" [ngStyle]="to['styles']">
      {{ to.label }}
      <stream-tooltips *ngIf="showTooltips" [content]="to['tooltip']"></stream-tooltips>
    </p>
  `,
  styles: [
    `
      .stream-formly-text {
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
        color: #62656c;
        line-height: 1.5;
        display: flex;
      }
    `
  ]
})
export class ParagraphComponent extends FieldType {
  get showTooltips() {
    return isQuillHasContent(this.to?.['tooltip']);
  }
}
