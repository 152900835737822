import { Component, Inject, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  Store,
  StreamFormlyConfig,
  StreamFormlyPanelConfig,
  StreamFormlyType,
  StreamPanelType
} from '@stream/models';
import { BehaviorSubject } from 'rxjs';

import { CheckboxModule } from '../checkbox/checkbox.module';
import { STREAM_FORMLY_CONFIG } from '../formly.config';
import { PhoneModule } from '../phone/phone.module';
import { RadioModule } from '../radio/radio.module';
import { TEXT_FORMLY_CONFIG } from '../text/text.module';

const STATIC_TYPES = [
  ...(TEXT_FORMLY_CONFIG.types?.map(({ name }) => name) ?? []),
  StreamFormlyType.BUTTON_SUBMIT
];
@Component({
  selector: 'stream-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  store = new Store({ type: 'session', namespace: 'scenario' });

  @Input()
  set model(model: Record<string, unknown>) {
    const snapshot = this.store.get('snapshot');
    if (snapshot && snapshot.model) {
      this._model = snapshot.model;
    } else {
      this._model = model;
    }
  }
  get model() {
    return this._model;
  }

  @Input()
  set fields(value: StreamFormlyPanelConfig['forms'] | StreamFormlyPanelConfig['forms'][number]) {
    if (Array.isArray(value)) {
      this.formlyFields$.next(value.map(field => this.transformFields(field)));
    } else {
      this.formlyFields$.next([this.transformFields(value)]);
    }
  }

  @Input()
  form!: UntypedFormGroup;

  @Input()
  panelConfig?: StreamFormlyPanelConfig;

  constructor(@Inject(STREAM_FORMLY_CONFIG) private config: { editMode: boolean }) {}

  formlyFields$ = new BehaviorSubject<FormlyFieldConfig[]>([]);

  _model: Record<string, unknown> = {};

  get formlyFields() {
    return this.formlyFields$.getValue();
  }

  transformMap = new Map<StreamFormlyType, (params: StreamFormlyConfig) => FormlyFieldConfig>([
    [StreamFormlyType['PHONE'], PhoneModule.transformPhoneField],
    [StreamFormlyType['CHECKBOX_GROUP'], CheckboxModule.transformCheckboxGroupField],
    [StreamFormlyType['BITE_RADIO_GROUP'], RadioModule.transformRadioGroupField]
  ] as any);

  private transformFields(config: StreamFormlyConfig): FormlyFieldConfig {
    if (this.transformMap.has(StreamFormlyType[config.type])) {
      return {
        // hideExpression: this.getHideExpression(),
        ...(this.transformMap.get(StreamFormlyType[config.type])?.(config) as FormlyFieldConfig)
      };
    }
    const { id, key, name, defaultValue, type, placeholder, ...templateOptions } = config;

    const asyncValidators = config.asyncValidators as any;
    const validators = (config.validators || '') as any;

    return {
      id,
      key,
      name,
      defaultValue,
      asyncValidators,
      validators,
      type: StreamFormlyType[type],

      templateOptions: {
        ...templateOptions,

        placeholder: placeholder?.[key],
        disabled: STATIC_TYPES.includes(StreamFormlyType[type]) || templateOptions.disabled
      }
      // hideExpression: this.getHideExpression()
    };
  }

  getHideExpression() {
    return () => {
      if (this.config.editMode) return false;
      if (this.panelConfig?.panelType === StreamPanelType.Static) return false;

      const getConditionResult = (
        displayCondition: StreamFormlyPanelConfig['panelDisplayCondition'] = {}
      ) => {
        if (displayCondition['and']) {
          return displayCondition.and.reduce(
            (acc, { formKey: formKey, formValue: formValue, type: type, value: value }) =>
              acc &&
              (type === 'BACKEND_FILTER_RESULT'
                ? value
                : this.form.getRawValue()[formKey] === formValue),
            true
          );
        } else if (displayCondition['or']) {
          return displayCondition.or.reduce(
            (acc, { formKey: formKey, formValue: formValue, type: type, value: value }) =>
              (type === 'BACKEND_FILTER_RESULT'
                ? value
                : this.form.getRawValue()[formKey] === formValue) || acc,
            false
          );
        }
        return true;
      };
      const condition = this.panelConfig?.panelDisplayCondition;
      const result = getConditionResult(condition);
      return this.panelConfig?.panelDisplayConditionIsShow ? !result : result;
    };
  }
}
