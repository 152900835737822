<mat-drawer-container
  class="chat-drawer-container"
  [hasBackdrop]="true"
  (backdropClick)="close()"
>
  <mat-drawer
    class="chat-drawer w-screen md:w-[560px] 2xl:w-[770px]"
    #drawer
    mode="side"
    position="end"
    [opened]="opened"
  >
    <stream-chat-conversation
      *ngIf="drawer.opened && viewMode === 'LIST'"
      (closeEvent)="close()"
    ></stream-chat-conversation>
    <stream-chat-conversation-detail
      *ngIf="drawer.opened && viewMode === 'MESSAGE'"
      (closeEvent)="close()"
    ></stream-chat-conversation-detail>
  </mat-drawer>
</mat-drawer-container>
