import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StreamFormlyEventTypeEnum } from '@stream/formly';
import { CustomButtonType, Profile, Store, StreamFormlyType } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import _ from 'lodash';
import { catchError, of } from 'rxjs';
import { finalize, map, pluck, switchMap, take, tap } from 'rxjs/operators';

import { ProfileService } from '../../../profiles';
import { ErrorMessages } from '../../constant';
import { SCENARIO_CONFIG, ScenarioConfig, ScenarioSubmitResult } from '../../scenario.type';
import { ScenarioPanelService } from '../../services/scenario-panel.service';
import { ScenarioService } from '../../services/scenario.service';
import { ScenarioBaseComponent, ScenarioComponent } from '../scenario/scenario.component';
import { SaveAndExitConfirmDialogComponent } from './save-and-exit-confirm-dialog.component';

@Component({
  selector: 'stream-scenario-form',
  templateUrl: './scenario-form.component.html',
  styleUrls: ['./scenario-form.component.scss']
})
export class ScenarioFormComponent extends ScenarioBaseComponent implements OnInit {
  constructor(
    public panelService: ScenarioPanelService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private scenarioService: ScenarioService,
    public scenarioComponent: ScenarioComponent,
    private notification: NotificationService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    vcr: ViewContainerRef,
    @Inject(SCENARIO_CONFIG) private config: ScenarioConfig
  ) {
    super(scenarioComponent, vcr);
  }

  profileList: Profile[] = [];
  profileInnerType = '';
  progress = 0;
  profileId = '';
  form = this.fb.group({});
  store = new Store({ type: 'session', namespace: 'scenario' });
  opportunity$ = this.scenarioComponent.opportunity$ ?? null;
  shareClassObservable = this.scenarioComponent.shareClassObservable;
  validFormKeys?: string[];

  mandatoryDocsObservable = this.panelService.isModifyInvest
    ? this.panelService.requestMandatoryDocs.getValue()
      ? this.panelService.modifyMandatoryDocs
      : of(this.store.get('snapshot')?.model?.component_investment_mandatorydocs || [])
    : this.scenarioComponent.mandatoryDocsObservable;

  uploadMandatoryDoc = this.scenarioComponent.uploadMandatoryDoc;

  profileObservable = this.profileService
    .getProfileList()
    .pipe(pluck('data', 'investorProfileEntityList'));

  isMandatoryDocNode = this.panelService.nodeData.pipe(
    map(({ nodeInnerType }) => nodeInnerType === StreamFormlyType.MANDATORYDOCS),
    catchError(() => of(false))
  );

  isMandatoryFormValid = () => {
    return this.panelService.nodeData.pipe(
      map(nodeData =>
        (nodeData.data?.panels || []).every(panel =>
          panel.forms.every(f => {
            const formArray = this.form.get(f.key) as UntypedFormArray;
            const valid = formArray?.controls
              .filter(ctr => ctr.value['isMandatory'])
              .every(ctr => (ctr.value['files'] || []).length > 0);
            return valid;
          })
        )
      ),
      catchError(() => of(false))
    );
  };

  ngOnInit(): void {
    const snapshot = this.store.get('snapshot');

    // before sign node, reset projectId
    this.panelService.projectId.next(null);

    if (snapshot) {
      this.profileList = [snapshot.profile];
    } else {
      this.profileObservable.subscribe(data => {
        this.profileList = data;
      });
    }

    this.panelService.nodeData.pipe(take(1)).subscribe(nodeData => {
      this.profileInnerType = nodeData.nodeInnerType ?? '';
      this.form.patchValue(nodeData.temporaryNodeResult?.actionResult ?? {});
    });
  }

  onChange(event: any) {
    if (!event) return;
    this.progress = event.progress;
    this.profileId = event.profile.id;
  }

  customButtonClickHandler(type: CustomButtonType) {
    switch (type) {
      case CustomButtonType.Save_Exit:
        this.dialog
          .open(SaveAndExitConfirmDialogComponent, {
            maxWidth: this.breakpointObserver.isMatched('(min-width: 768px)') ? '680px' : '95vw'
          })
          .afterClosed()
          .subscribe(result => {
            if (result) {
              this.panelService.saveNodeResult(this.form.value).subscribe(() => {
                this.scenarioComponent.discard.next();
              });
            }
          });

        break;
    }
  }

  profileValidate() {
    return of({ profileList: this.profileList }).pipe(
      switchMap(({ profileList }) =>
        profileList.length === 0
          ? this.profileObservable.pipe(
              take(1),
              map(data => {
                this.profileList = data;
                return data;
              })
            )
          : of(profileList)
      ),
      tap(data => {
        if (data.length === 0 || !this.profileId) {
          this.notification.error(ErrorMessages.profileNotSelect);
          return;
        }
      })
    );
  }

  defaultValidate(params: ScenarioSubmitResult) {
    if (this.form.invalid) return;
    this.panelService.submitNodeResult(params).subscribe();
  }

  onValidKeyChange(event: any) {
    this.validFormKeys = event;
  }

  // TODO: optimize this logic, don't distinguish source
  submit() {
    Object.keys(this.form.controls).forEach(key => {
      if (!this.validFormKeys?.includes(key)) {
        this.form.removeControl(key);
      }
    });

    const { source } = this.config;
    const formValue = this.form.value;
    const params = {
      result: formValue,
      ...this.config
    };
    const {
      component_investment_amount: amount,
      component_investment_profile: profile,
      component_investment_mandatorydocs: mandatorydocs
    } = this.form.value;

    this.panelService.nodeData.subscribe(node => {
      if (amount || profile || mandatorydocs) {
        return;
      }

      const {
        data: { panels }
      } = node;
      const forms = _.flatten(_.map(panels, 'forms'));

      // reset smart form
      this.form.reset(formValue);

      _.forEach(_.keys(formValue), key => {
        const value = formValue[key];

        let isNull = false;

        if (value && !_.isString(value)) {
          isNull = !_.some(_.values(value), val => !!val);
        }

        if (!formValue[key]) {
          isNull = true;
        }

        if (isNull && _.find(forms, form => form.key === key)?.required) {
          const control = this.form.get(key);
          control?.setErrors({ required: true });
        }
      });
    });

    this.form.markAllAsTouched();

    // TODO:  optimize this logic, bind key to form element
    setTimeout(() => {
      const validationMessages = document.getElementsByTagName('formly-validation-message');

      for (let i = 0; i < validationMessages.length; i++) {
        const message = validationMessages[i].textContent;

        if (message) {
          validationMessages[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
          break;
        }
      }
    });

    // modify condition, validate md is needed
    if (
      this.panelService.isModifyInvest &&
      mandatorydocs &&
      mandatorydocs.filter((doc: any) => doc.isMandatory && doc.files.length).length !==
        mandatorydocs.filter((doc: any) => doc.isMandatory).length
    ) {
      this.notification.error(ErrorMessages.mandatoryNotUpload);
      return;
    }

    const isInvestingProfile = this.profileInnerType === 'INVESTING_PROFILE';

    // LP use this logic
    if (source === 'LP') {
      if (
        (isInvestingProfile && this.profileList.length === 0) ||
        (isInvestingProfile && !this.profileId)
      ) {
        this.notification.error(ErrorMessages.profileNotSelect);
      } else if (isInvestingProfile && this.progress !== 100) {
        this.notification.error(ErrorMessages.profileNotDone);
      }

      if (this.form.invalid) return;

      this.panelService.submitNodeResult(params).subscribe();
      return;
    }

    if (source === 'GP' && isInvestingProfile && this.progress !== 100) {
      this.notification.error(ErrorMessages.profileNotDone);
      return;
    }

    // default use this logic
    if (isInvestingProfile) {
      this.profileValidate().subscribe(() => {
        this.defaultValidate(params);
      });
      return;
    }
    this.defaultValidate(params);
  }

  onEvent(event: { type: StreamFormlyEventTypeEnum; params?: any }) {
    if (event.type === StreamFormlyEventTypeEnum.ProfileAdd) {
      this.scenarioComponent.profileCreate.emit();
    }
    if (event.type === StreamFormlyEventTypeEnum.ProfileEdit) {
      this.scenarioComponent.profileEdit.emit(event.params);
    }
    if (event.type === StreamFormlyEventTypeEnum.ProfileDelete) {
      this.profileService
        .deleteProfile(event.params.id)
        .pipe(
          finalize(
            () =>
              (this.profileObservable = this.profileService
                .getProfileList()
                .pipe(pluck('data', 'investorProfileEntityList')))
          )
        )
        .subscribe();
    }
  }

  discard() {
    this.panelService.discard.next(null);
  }

  // TODO: merge logic
  previous() {
    // No form validation required
    const nodeData = this.form.value;

    this.panelService.loading.next(true);

    if (this.panelService.isModifyInvest) {
      const params = {
        result: this.form.value,
        ...this.config
      };

      // Modification previous
      this.panelService.modificationPrevious(nodeData).subscribe(() => {
        this.panelService.updateSnapshot(params);
        this.panelService.updateModifyStep(-1);

        // In the modified state, reinitialize md
        this.panelService.requestMandatoryDocs.next(false);
      });

      return;
    }

    this.panelService.previous(nodeData).subscribe();
  }
}
