<mat-label
  class="flex gap-x-1 font-bold text-[#0000008a]"
  *ngIf="to?.['customLabel'] && to?.['hideLabel'] !== true"
>
  {{ to['customLabel'] }}
  <stream-tooltips *ngIf="showTooltips" [content]="to['tooltip']"></stream-tooltips>
  <span
    *ngIf="to?.required && to?.['hideRequiredMarker'] !== true"
    aria-hidden="true"
    class="mat-form-field-required-marker"
    >*</span
  >
</mat-label>
<ng-container #fieldComponent></ng-container>
