import { Injectable } from '@angular/core';

export interface IDocumentType {
  title?: string;
  files: IFileType[];
}

export interface IFileType {
  fileId: string;
  name: string;
  type: 'PDF' | 'TXT' | unknown;
  isMustView: boolean;
  checkMustView: boolean;
  isFolded?: boolean;
  isFileLoading?: boolean;
  blobUrl?: string;
}

export interface IResIRADocumentType {
  category?: string;
  'document-id'?: string;
  files: IResIRAFileType[];
}

export interface IResIRAFileType {
  'file-id': string;
  'file-name': string;
  'must-view': boolean;
  title: string;
}

@Injectable()
export class ProfileIRAService {
  constructor() {}

  public dealIRADocumentListData(resDocument: {
    [key: string]: IResIRADocumentType;
  }): IDocumentType[] {
    const resultDocumentList: IDocumentType[] = [];

    const getDocumentTitle = (key: string) => {
      let result = key.charAt(0).toUpperCase(); // Convert the first character to uppercase
      for (let i = 1; i < key.length; i++) {
        if (key.charAt(i).toUpperCase() === key.charAt(i)) {
          result += ' '; // If encountering uppercase characters, add a space
        }
        result += key.charAt(i);
      }
      return result;
    };

    const getFileType = (name: string) => {
      const fileExtension = name.split('.').pop()?.toLowerCase() || '';
      if (fileExtension === 'pdf') {
        return 'PDF';
      } else if (fileExtension === 'txt') {
        return 'TXT';
      } else {
        return 'unknown';
      }
    };

    const dealDocument = (key: string, doc: IResIRADocumentType): IDocumentType => {
      return {
        title: getDocumentTitle(key || ''),
        files: doc.files.map((file: IResIRAFileType) => {
          return {
            name: file['file-name'],
            isMustView: file['must-view'],
            fileId: file['file-id'],
            type: getFileType(file['file-name']),
            checkMustView: false,
            isFolded: true,
            isFileLoading: true
          };
        })
      };
    };

    Object.keys(resDocument)
      .sort()
      .map(key => {
        return { key: key, value: resDocument[key] };
      })
      .forEach(obj => {
        const { key, value } = obj;
        if (value.files?.length > 0) {
          resultDocumentList.push(dealDocument(key, value));
        }
      });

    return resultDocumentList;
  }
}
