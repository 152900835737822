<div
  class="inner bg-[rgba(16,18,19,0.8)]"
  [class]="mobileMode ? 'mobile-mode p-[3.33vw] rounded-md' : 'p-5 rounded-lg'"
>
  <h3
    class="font-semibold text-white"
    [class]="mobileMode ? 'text-sm pb-2' : 'text-[22px] leading-[26px] pb-3'"
  >
    Invite a team member?
  </h3>
  <p class="text-[rgba(255,255,255,0.8)]" [class]="mobileMode ? 'text-xs pb-3' : 'text-base pb-5'">
    Invite a team member to join you in co-managing investments.
  </p>
  <div class="flex justify-start" [class]="mobileMode ? 'gap-[6.25px]' : 'gap-[10px]'">
    <button
      class="!border-white font-bold !text-white"
      [class]="mobileMode ? 'text-xs' : 'text-base'"
      mat-stroked-button
      (click)="dismiss()"
    >
      Dismiss({{ number }})
    </button>
    <button
      class="text-nowrap !bg-white font-bold !text-[#101213]"
      [class]="mobileMode ? 'text-xs' : 'text-base'"
      mat-flat-button
      color="white"
      (click)="toInviteMember()"
    >
      Invite member
    </button>
  </div>
</div>
