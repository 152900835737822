<div class="grow overflow-hidden">
  <div
    #messagesWrapper
    *ngIf="(messageStore$ | async)?.length"
    class="h-full overflow-auto px-[30px] pb-5"
    infiniteScroll
    [infiniteScrollUpDistance]="0"
    [scrollWindow]="false"
    (scrolledUp)="handleScrollUp()"
  >
    <div
      class="mt-5"
      #messageItem
      *ngFor="let itemMessage of messageStore$ | async; trackBy: trackByFn"
    >
      <div
        *ngIf="earliestUnReadMsgId === itemMessage.id"
        class="messages-divider"
      >
        New message
      </div>
      <stream-message-item
        [info]="transform(itemMessage) | async"
        (resendClick)="resendMsg($event)"
        (deleteClick)="deleteMsg($event)"
      ></stream-message-item>
    </div>
  </div>
</div>
<div class="m-[30px] mt-0 text-sm">
  <stream-message-input
    type="chat"
    [uuid]="conversationId"
    placeholder="Please input"
    [apiUpload]="(fileUploadApi$ | async)!"
    [isShowBtnSend]="true"
    [isDisableBtnSend]="(disabled$ | async)! || sending"
    (sendClick)="sendMsg($event)"
    [isHiddenBorder]="false"
  >
  </stream-message-input>
</div>
