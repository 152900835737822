export type StoreType = 'local' | 'session';

export type StoreOptions = {
  type: StoreType;
  namespace?: string;
};

const NAMESPACE_KEY = 'namespace_key';

class Store {
  private storage: Storage;
  private namespace?: string;

  constructor(options: StoreOptions) {
    const { type } = options;
    this.namespace = `stream-${options.namespace}` ?? 'stream-default';

    switch (type) {
      case 'local':
        this.storage = window.localStorage;
        break;
      case 'session':
        this.storage = window.sessionStorage;
        break;
      default:
        this.storage = window.localStorage;
    }
  }

  public set(key: string, value: any) {
    if (key === NAMESPACE_KEY) {
      console.error('use of this key is prohibited');
      return;
    }

    const namespaceKey = `${this.namespace}-${NAMESPACE_KEY}`;

    key = `${this.namespace}-${key}`;

    if (typeof value === 'string') {
      this.storage.setItem(key, value);
    }

    try {
      const keys = JSON.parse(this.storage.getItem(namespaceKey) || '[]');
      this.storage.setItem(key, JSON.stringify(value));
      this.storage.setItem(namespaceKey, JSON.stringify([...new Set([...keys, key])]));
    } catch (error) {
      console.error('set store data possible failed');
    }
  }

  public get(key: string) {
    key = `${this.namespace}-${key}`;
    const value = this.storage.getItem(key);

    if (!value) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (_) {
      return value;
    }
  }

  public remove(key: string) {
    key = `${this.namespace}-${key}`;
    this.storage.removeItem(key);
  }

  // namespace: clear all namespace key value
  public clear(namespace?: string) {
    if (namespace) {
      const namespaceKey = `${this.namespace}-${NAMESPACE_KEY}`;
      const value = this.storage.getItem(namespaceKey);

      if (!value) {
        this.storage.clear();
        return;
      }

      try {
        const keys = JSON.parse(value) as string[];
        keys.forEach(key => {
          this.storage.removeItem(key);
        });
      } catch {
        this.storage.clear();
      }
      this.storage.removeItem(namespaceKey);
      return;
    }
    this.storage.clear();
  }
}

export { Store };
