import { LoginStatusEnum, SendRegisterEmailStatusEnum } from '../status.enum';
import { Gateway } from './gateway.interface';
import { Principal } from './principal.interface';

export enum PrincipalType {
  Individual = 'individual',
  Institutional = 'institutional',
  NotVerified = 'notVerified'
}

export enum InvestAccountRoleEnum {
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN'
}

export interface InvestAccount {
  /** 头像 */
  avatar: string;
  /** 📮 */
  email: string;
  /** 🔒 */
  password: string;
  /** 名 */
  firstName: string;
  /** 姓 */
  lastName: string;
  /** 📱 */
  phone: string;
  /** 投资人类型 */
  principalType: PrincipalType;
  tenantId: string;
  /** 时区  */
  timeZone: number;
  timeZoneText: string;
  /** 语言偏好  */
  language: string;
  id: string;

  currencyCode: string;

  passwordModifiedTime: number;
  /** 取消订阅的邮件类型 */
  unsubscribeGroups: string[];

  role: InvestAccountRoleEnum;
}

/** 本地注册信息 */
export type RegisterInfo = Pick<
  InvestAccount,
  'email' | 'firstName' | 'lastName' | 'principalType' | 'password'
> & { companyName?: string; receiveMarketingEmails?: boolean };

export interface LoginInfo {
  loginStatus: LoginStatusEnum;
  status: LoginStatusEnum | SendRegisterEmailStatusEnum;
  tokenData: {
    accessToken: string;
    refreshToken: string;
    principalToken: string;
    tokenId: string;
  };
  scenarioData?: Gateway;
  tempToken?: string;
  investPrincipalVOS: Principal[];
  isFirstLogin?: boolean;
  memberCount?: number;
}
