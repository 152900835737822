export const OldOccupations = [
  {
    en: 'Healthcare Practitioners and Technical Occupations:',
    zh: '医疗保健从业人员和技术职业：',
    children: [
      {
        en: 'Chiropractor',
        key: 'Chiropractor',
        zh: '脊医',
      },
      {
        en: 'Dentist',
        key: 'Dentist',
        zh: '牙医',
      },
      {
        en: 'Dietitian or Nutritionist',
        key: 'Dietitian or Nutritionist',
        zh: '营养师或营养师',
      },
      {
        en: 'Optometrist',
        key: 'Optometrist',
        zh: '验光师',
      },
      {
        en: 'Pharmacist',
        key: 'Pharmacist',
        zh: '药剂师',
      },
      {
        en: 'Physician',
        key: 'Physician',
        zh: '医师',
      },
      {
        en: 'Physician Assistant',
        key: 'Physician Assistant',
        zh: '医师助理',
      },
      {
        en: 'Podiatrist',
        key: 'Podiatrist',
        zh: '足病医生',
      },
      {
        en: 'Registered Nurse',
        key: 'Registered Nurse',
        zh: '注册护士',
      },
      {
        en: 'Therapist',
        key: 'Therapist',
        zh: '治疗师',
      },
      {
        en: 'Veterinarian',
        key: 'Veterinarian',
        zh: '兽医',
      },
      {
        en: 'Health Technologist or Technician',
        key: 'Health Technologist or Technician',
        zh: '卫生技术员或技术员',
      },
      {
        en: 'Chiropractor',
        key: 'Other Healthcare Practitioners and Technical Occupation',
        zh: '脊医',
      },
      {
        en: 'Other Healthcare Practitioners and Technical Occupation',
        key: 'Chiropractor',
        zh: '其他医疗从业人员和技术职业',
      },
    ],
  },

  {
    en: 'Healthcare Support Occupations:',
    zh: '医疗保健支持职业：',
    children: [
      {
        en: 'Nursing, Psychiatric, or Home Health Aide',
        key: 'Nursing, Psychiatric, or Home Health Aide',
        zh: '护理，精神病或家庭健康助手',
      },
      {
        en: 'Occupational and Physical Therapist Assistant or Aide',
        key: 'Occupational and Physical Therapist Assistant or Aide',
        zh: '职业和物理治疗师助理或助手',
      },
      {
        en: 'Other Healthcare Support Occupation',
        key: 'Other Healthcare Support Occupation',
        zh: '其他医疗支持职业',
      },
    ],
  },
  {
    en: 'Business, Executive, Management, and Financial Occupations:',
    zh: '商业，行政，管理和财务职业：',
    children: [
      {
        en: ' Chief Executive',
        key: 'Chief Executive',
        zh: ' 首席执行官',
      },
      {
        en: 'General and Operations Manager',
        key: 'General and Operations Manager',
        zh: '总经理兼运营经理',
      },
      {
        en: 'Advertising, Marketing, Promotions, Public Relations, and Sales Manager',
        key: 'Advertising, Marketing, Promotions, Public Relations, and Sales Manager',
        zh: '广告，市场营销，促销，公共关系和销售经理',
      },
      {
        en: 'Operations Specialties Manager (e.g., IT or HR Manager)',
        key: 'Operations Specialties Manager (e.g., IT or HR Manager)',
        zh: '运营专业经理（例如IT或HR经理）',
      },
      {
        en: 'Construction Manager',
        key: 'Construction Manager',
        zh: '施工经理',
      },
      {
        en: 'Engineering Manager',
        key: 'Engineering Manager',
        zh: '工程经理',
      },
      {
        en: 'Accountant, Auditor',
        key: 'Accountant, Auditor',
        zh: '会计师，审计师',
      },
      {
        en: 'Business Operations or Financial Specialist',
        key: 'Business Operations or Financial Specialist',
        zh: '业务运营或财务专家',
      },
      {
        en: 'Business Owner',
        key: 'Business Owner',
        zh: '企业主',
      },
      {
        en: 'Other Business, Executive, Management, Financial Occupation',
        key: 'Other Business, Executive, Management, Financial Occupation',
        zh: '其他业务，执行，管理，财务职业',
      },
    ],
  },

  {
    en: 'Architecture and Engineering Occupations:',
    zh: '建筑与工程职业：',
    children: [
      {
        en: 'Architect, Surveyor, or Cartographer',
        key: 'Architect, Surveyor, or Cartographer',
        zh: '建筑师，测量师或制图师',
      },
      {
        en: 'Engineer',
        key: 'Engineer',
        zh: '工程师',
      },
      {
        en: 'Other Architecture and Engineering Occupation',
        key: 'Other Architecture and Engineering Occupation',
        zh: '其他建筑和工程职业',
      },
    ],
  },
  {
    en: 'Education, Training, and Library Occupations:',
    zh: '教育，培训和图书馆职业：',
    children: [
      {
        en: 'Postsecondary Teacher (e.g., College Professor)',
        key: 'Postsecondary Teacher (e.g., College Professor)',
        zh: '专任教师（例如大学教授）',
      },
      {
        en: 'Primary, Secondary, or Special Education School Teacher',
        key: 'Primary, Secondary, or Special Education School Teacher',
        zh: '小学，中学或特殊教育学校老师',
      },
      {
        en: 'Other Teacher or Instructor',
        key: 'Other Teacher or Instructor',
        zh: '其他老师或讲师',
      },
      {
        en: 'Other Education, Training, and Library Occupation',
        key: 'Other Education, Training, and Library Occupation',
        zh: '其他教育，培训和图书馆职业',
      },
    ],
  },

  {
    en: 'Other Professional Occupations:',
    zh: '其他职业：',
    children: [
      {
        en: 'Arts, Design, Entertainment, Sports, and Media Occupations',
        key: 'Arts, Design, Entertainment, Sports, and Media Occupations',
        zh: '艺术，设计，娱乐，体育和媒体职业',
      },
      {
        en: 'Computer Specialist, Mathematical Science',
        key: 'Computer Specialist, Mathematical Science',
        zh: '数学科学计算机专家',
      },
      {
        en: 'Counselor, Social Worker, or Other Community and Social Service Specialist',
        key: 'Counselor, Social Worker, or Other Community and Social Service Specialist',
        zh: '顾问，社会工作者或其他社区和社会服务专家',
      },
      {
        en: 'Lawyer, Judge',
        key: 'Lawyer, Judge',
        zh: '律师，法官',
      },
      {
        en: 'Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)',
        key: 'Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)',
        zh: '生命科学家（例如动物，食物，土壤或生物科学家，动物学家）',
      },
      {
        en: 'Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)',
        key: 'Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)',
        zh: '物理科学家（例如，天文学家，物理学家，化学家，水文学家）',
      },
      {
        en: 'Religious Worker (e.g., Clergy, Director of Religious Activities or Education)',
        key: 'Religious Worker (e.g., Clergy, Director of Religious Activities or Education)',
        zh: '宗教工作者（例如，神职人员，宗教活动或教育主管）',
      },
      {
        en: 'Social Scientist and Related Worker',
        key: 'Social Scientist and Related Worker',
        zh: '社会科学家及相关工作者',
      },
      {
        en: 'Other Professional Occupation',
        key: 'Other Professional Occupation',
        zh: '其他职业',
      },
    ],
  },

  {
    en: 'Office and Administrative Support Occupations:',
    zh: '办公室和行政支持职业：',
    children: [
      {
        en: 'Supervisor of Administrative Support Workers',
        key: 'Supervisor of Administrative Support Workers',
        zh: '行政支援人员主管',
      },
      {
        en: 'Financial Clerk',
        key: 'Financial Clerk',
        zh: '财务文员',
      },
      {
        en: 'Secretary or Administrative Assistant',
        key: 'Secretary or Administrative Assistant',
        zh: '秘书或行政助理',
      },
      {
        en: 'Material Recording, Scheduling, and Dispatching Worker',
        key: 'Material Recording, Scheduling, and Dispatching Worker',
        zh: '物料记录，计划和调度工作人员',
      },
      {
        en: 'Other Office and Administrative Support Occupation',
        key: 'Other Office and Administrative Support Occupation',
        zh: '其他办公室和行政支持职业',
      },
    ],
  },

  {
    en: 'Services Occupations: ',
    zh: '服务职业：',
    children: [
      {
        en: 'Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)',
        key: 'Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)',
        zh: '防护服务（例如消防，警务人员，惩教人员）',
      },
      {
        en: 'Chef or Head Cook',
        key: 'Chef or Head Cook',
        zh: '厨师或厨师长',
      },
      {
        en: 'Cook or Food Preparation Worker',
        key: 'Cook or Food Preparation Worker',
        zh: '厨师或食物制备工人',
      },
      {
        en: 'Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)',
        key: 'Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)',
        zh: '餐饮服务人员（例如，酒保，侍应生，服务生）',
      },
      {
        en: 'Building and Grounds Cleaning and Maintenance',
        key: 'Building and Grounds Cleaning and Maintenance',
        zh: '建筑物和地面的清洁和维护',
      },
      {
        en: 'Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)',
        key: 'Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)',
        zh: '个人护理和服务（例如，美发师，空姐，礼宾服务）',
      },
      {
        en: 'Sales Supervisor, Retail Sales',
        key: 'Sales Supervisor, Retail Sales',
        zh: '零售销售主管',
      },
      {
        en: 'Retail Sales Worker',
        key: 'Retail Sales Worker',
        zh: '零售业员',
      },
      {
        en: 'Insurance Sales Agent',
        key: 'Insurance Sales Agent',
        zh: '保险销售代理',
      },
      {
        en: 'Sales Representative',
        key: 'Sales Representative',
        zh: '销售代表',
      },
      {
        en: 'Real Estate Sales Agent',
        key: 'Real Estate Sales Agent',
        zh: '房地产销售代理',
      },
      {
        en: 'Other Services Occupation',
        key: 'Other Services Occupation',
        zh: '其他服务职业',
      },
    ],
  },

  {
    en: 'Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:',
    zh: '农业，维修，修理和熟练手工艺职业：',
    children: [
      {
        en: 'Construction and Extraction (e.g., Construction Laborer, Electrician)',
        key: 'Construction and Extraction (e.g., Construction Laborer, Electrician)',
        zh: '施工和提取（例如，建筑工人，电工）',
      },
      {
        en: 'Farming, Fishing, and Forestry',
        key: 'Farming, Fishing, and Forestry',
        zh: '农业，渔业和林业',
      },
      {
        en: 'Installation, Maintenance, and Repair',
        key: 'Installation, Maintenance, and Repair',
        zh: '安装，维护和维修',
      },
      {
        en: 'Production Occupations',
        key: 'Production Occupations',
        zh: '生产职业',
      },
      {
        en: 'Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation',
        key: 'Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation',
        zh: '其他农业，维修，修理和熟练手工艺职业',
      },
    ],
  },

  {
    en: 'Transportation Occupations:',
    zh: '运输职业：',
    children: [
      {
        en: 'Aircraft Pilot or Flight Engineer',
        key: 'Aircraft Pilot or Flight Engineer',
        zh: '飞机驾驶员或飞行工程师',
      },
      {
        en: 'Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)',
        key: 'Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)',
        zh: '机动车驾驶员（例如，救护车，公共汽车，出租车或卡车驾驶员）',
      },
      {
        en: 'Other Transportation Occupation',
        key: 'Other Transportation Occupation',
        zh: '其他运输职业',
      },
    ],
  },

  {
    en: 'Other Occupations:',
    zh: '其他职业：',
    children: [
      {
        en: 'Military',
        key: 'Military',
        zh: '军事',
      },
      {
        en: 'Homemaker',
        key: 'Homemaker',
        zh: '家庭主妇',
      },
      {
        en: 'Other Occupation',
        key: 'Other Occupation',
        zh: '其他职业',
      },
    ],
  },
];

export const Occupations = [
  {
    en: 'Accountant',
    key: 'accountant',
    zh: '会计师',
  },
  {
    en: 'Actuary',
    key: 'actuary',
    zh: '精算师',
  },
  {
    en: 'Auditor',
    key: 'auditor',
    zh: '审计员',
  },
  {
    en: 'Bank manager',
    key: 'bank_manager',
    zh: '银行经理',
  },
  {
    en: 'Business adviser',
    key: 'business_adviser',
    zh: '业务顾问',
  },
  {
    en: 'Business development manager',
    key: 'bdm',
    zh: '业务拓展经理',
  },
  {
    en: 'Business project manager',
    key: 'bpm',
    zh: '项目经理',
  },
  {
    en: 'Chief executive officer',
    key: 'ceo',
    zh: '首席执行官',
  },
  {
    en: 'Chief financial officer',
    key: 'cfo',
    zh: '首席财务官',
  },
  {
    en: 'Chief operating officer',
    key: 'coo',
    zh: '首席运营官',
  },
  {
    en: 'Company director',
    key: 'company_director',
    zh: '公司董事',
  },
  {
    en: 'Company secretary',
    key: 'company_secretary',
    zh: '公司秘书',
  },
  {
    en: 'Compliance manager',
    key: 'compliance_manager',
    zh: '合规经理',
  },
  {
    en: 'Credit controller',
    key: 'credit_controller',
    zh: '信贷员',
  },
  {
    en: 'Credit manager',
    key: 'credit_manager',
    zh: '信贷经理',
  },
  {
    en: 'Digital marketer',
    key: 'digital_marketer',
    zh: '数字营销人员',
  },
  {
    en: 'Economist',
    key: 'economist',
    zh: '经济学家',
  },
  {
    en: 'Entrepreneur',
    key: 'entrepreneur',
    zh: '企业家',
  },
  {
    en: 'Finance officer',
    key: 'finance_officer',
    zh: '财务总监',
  },
  {
    en: 'Financial adviser',
    key: 'fa',
    zh: '理财师',
  },
  {
    en: 'Financial analyst',
    key: 'financial_analyst',
    zh: '财务分析人员',
  },
  {
    en: 'Financial services customer adviser',
    key: 'fscd',
    zh: '金融服务客户顾问',
  },
  {
    en: 'Insurance account manager',
    key: 'iam',
    zh: '保险客户经理',
  },
  {
    en: 'Insurance broker',
    key: 'insurance_broker',
    zh: '保险经纪人',
  },
  {
    en: 'Investment analyst',
    key: 'investment_analyst',
    zh: '投资分析员',
  },
  {
    en: 'Investment manager',
    key: 'investment_manager',
    zh: '投资经理',
  },
  {
    en: 'Lawyer',
    key: 'lawyer',
    zh: '律师',
  },
  {
    en: 'Marketing manager',
    key: 'marketing_manager',
    zh: '市场部经理',
  },
  {
    en: 'Mortgage adviser',
    key: 'mortgage_adviser',
    zh: '抵押贷款顾问',
  },
  {
    en: 'Operations director',
    key: 'operations_director',
    zh: '运营总监',
  },
  {
    en: 'Pensions adviser',
    key: 'pensions_adviser',
    zh: '养老金顾问',
  },
  {
    en: 'Sales director',
    key: 'sales_director',
    zh: '销售总监',
  },
  {
    en: 'Stockbroker',
    key: 'stockbroker',
    zh: '证券经纪人',
  },
  {
    en: 'Tax adviser',
    key: 'tax_adviser',
    zh: '税务顾问',
  },
  {
    en: 'Treasury Analyst',
    key: 'treasury_analyst',
    zh: '财务分析师',
  },
  {
    en: 'Other',
    key: 'other',
    zh: '其他',
  },
];
