import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NoDataModule, QuillViewModule } from '@stream/ngx-utils';
import { SharedModule } from '@stream/shared';
import { MandatoryDocItemComponent } from './mandatory-doc-item/mandatory-doc-item.component';
import { MandatoryDocReuseComponent } from './mandatory-doc-reuse/mandatory-doc-reuse.component';
import { MandatoryDocUploadComponent } from './mandatory-doc-upload/mandatory-doc-upload.component';
import { MandatoryDocService } from './mandatory-doc.service';
import { MandatoryDocComponent } from './mandatory-doc/mandatory-doc.component';

@NgModule({
  declarations: [
    MandatoryDocComponent,
    MandatoryDocItemComponent,
    MandatoryDocUploadComponent,
    MandatoryDocReuseComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    SharedModule,
    MatInputModule,
    NzPopoverModule,
    QuillViewModule,
    NoDataModule,
    MatMenuModule,
    MatTooltipModule,
    MatAutocompleteModule
  ],
  exports: [MandatoryDocItemComponent, MandatoryDocUploadComponent],
  providers: [MandatoryDocService]
})
export class MandatoryDocModule {}
