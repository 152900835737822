import { InjectionToken } from '@angular/core';

import { Source } from '../common.type';

export const SCENARIO_CONFIG = new InjectionToken('SCENARIO_CONFIG');

export type ScenarioConfig = {
  source?: Source;
};

export type ScenarioSubmitResult = ScenarioConfig & {
  result?: any;
  taskId?: string;
  businessData?: any;
  // 是否更新到下一节点
  next?: boolean;
};
