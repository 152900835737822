import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, skip, take } from 'rxjs/operators';
import { ScenarioPanelService } from '../../services';

@Component({
  templateUrl: './re-invest-process.component.html',
  styleUrls: ['./re-invest-process.component.less'],
})
export class ReInvestProcessComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      panelService: ScenarioPanelService;
    },
    private dialogRef: MatDialogRef<ReInvestProcessComponent>
  ) {}

  loading = false;

  reInvest() {
    this.loading = true;
    this.data.panelService.discardInvest(true)?.subscribe(() => {
      this.data.panelService.scenarioProgress
        .pipe(
          skip(1),
          take(1),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(() => {
          this.dialogRef.close('RE_INVEST');
        });
    });
  }
}
