<div class="message-file-wrap" [style]="customStyle">
  <!-- icon -->
  <div
    [ngSwitch]="info?.documentType || info?.type"
    class="message-file-icon"
    (click)="handleFilePreviewClick()"
  >
    <svg
      *ngSwitchCase="'IMAGE'"
      class="icon message-file-icon-svg"
      viewBox="0 0 1024 1024"
    >
      <path
        d="M143.36 0C130.792727 0 117.387636 5.12 108.171636 15.36 98.955636 25.6 93.090909 38.4 93.090909 51.2v921.6c0 12.8 5.026909 26.437818 15.080727 35.84 10.053818 10.24 22.621091 15.36 35.188364 15.36h737.28c12.567273 0 25.972364-5.12 35.188364-15.36 10.053818-10.24 15.080727-23.04 15.080727-35.84V279.272727l-279.272727-279.272727H143.36z"
        fill="#4DD27A"
      ></path>
      <path
        d="M930.909091 279.272727h-229.981091c-12.334545 0-25.460364-4.933818-34.490182-14.801454a47.290182 47.290182 0 0 1-14.801454-34.443637V0l279.272727 279.272727z"
        fill="#B5F3CA"
      ></path>
      <path
        d="M762.181818 729.041455l-106.914909-165.934546a16.756364 16.756364 0 0 0-14.522182-7.726545 19.083636 19.083636 0 0 0-14.568727 7.726545l-57.297455 88.948364-128.279272-207.825455a16.802909 16.802909 0 0 0-14.568728-7.726545 19.083636 19.083636 0 0 0-14.522181 7.726545l-176.221091 284.811637a15.546182 15.546182 0 0 0 0 17.082181 15.872 15.872 0 0 0 14.568727 8.564364h497.803636a19.083636 19.083636 0 0 0 15.36-8.517818c2.606545-6.050909 2.606545-12.846545-0.837818-17.128727zM616.773818 415.138909a42.728727 42.728727 0 1 0 85.504 0 42.728727 42.728727 0 0 0-85.504 0z"
        fill="#FFFFFF"
      ></path>
    </svg>
    <svg
      *ngSwitchCase="'PDF'"
      class="icon message-file-icon-svg"
      viewBox="0 0 1024 1024"
    >
      <path
        d="M143.36 0C130.792727 0 117.387636 5.12 108.171636 15.36 98.955636 25.6 93.090909 38.4 93.090909 51.2v921.6c0 12.8 5.026909 26.437818 15.080727 35.84 10.053818 10.24 22.621091 15.36 35.188364 15.36h737.28c12.567273 0 25.972364-5.12 35.188364-15.36 10.053818-10.24 15.080727-23.04 15.080727-35.84V279.272727l-279.272727-279.272727H143.36z"
        fill="#F44343"
      ></path>
      <path
        d="M930.909091 279.272727h-229.981091c-12.334545 0-25.460364-4.933818-34.490182-14.801454a47.290182 47.290182 0 0 1-14.801454-34.443637V0l279.272727 279.272727z"
        fill="#FFBBC0"
      ></path>
      <path
        d="M695.994182 837.818182c-50.967273 0-96.302545-88.622545-120.552727-146.106182a870.4 870.4 0 0 0-127.860364-43.473455c-38.027636 25.413818-101.934545 63.162182-151.272727 63.162182-30.766545 0-52.596364-15.592727-60.741819-42.635636-6.469818-22.155636-0.791273-37.794909 5.678546-45.986909 12.939636-18.059636 39.656727-27.089455 80.104727-27.089455 32.395636 0 73.634909 5.771636 119.761455 17.221818a724.433455 724.433455 0 0 0 86.574545-72.192c-12.101818-57.483636-25.088-150.202182 8.098909-192.884363 16.197818-20.48 41.285818-27.089455 71.214546-18.059637 33.186909 9.867636 45.288727 30.394182 49.338182 45.98691 13.777455 55.808-49.338182 131.258182-92.206546 175.616 9.681455 38.586182 21.829818 78.801455 37.236364 115.712 61.44 27.927273 134.283636 68.933818 142.382545 114.082909 3.258182 15.592727-1.629091 30.394182-13.730909 42.682181-10.519273 9.029818-21.876364 13.963636-33.978182 13.963637z m-75.264-124.741818c30.766545 63.162182 59.904 92.718545 75.264 92.718545 2.466909 0 5.678545-0.791273 10.519273-4.933818 5.678545-5.725091 5.678545-9.821091 4.887272-13.125818-3.258182-16.384-29.137455-43.473455-90.670545-74.658909z m-299.380364-84.526546c-39.656727 0-50.967273 9.821091-54.225454 13.963637-0.791273 1.629091-4.049455 5.725091-0.791273 17.221818 2.420364 9.821091 8.890182 19.688727 29.928727 19.688727 25.925818 0 63.115636-14.801455 106.821818-41.053091-31.557818-6.516364-59.066182-9.821091-81.733818-9.821091z m160.209455-4.933818c25.925818 7.400727 52.596364 16.384 77.730909 26.251636a824.366545 824.366545 0 0 1-22.667637-72.238545c-17.826909 15.592727-36.445091 31.185455-55.063272 45.986909z m100.352-265.122909c-8.890182 0-15.36 3.258182-21.038546 9.867636-16.989091 21.317818-18.618182 75.496727-5.678545 144.430546 49.338182-53.341091 76.055273-102.586182 69.585454-128.837818-0.791273-4.096-4.049455-15.592727-26.670545-22.155637a43.473455 43.473455 0 0 0-16.197818-3.258182z"
        fill="#FFFFFF"
      ></path>
    </svg>
  </div>
  <!-- name -->
  <div class="message-file-name" (click)="handleFilePreviewClick()">
    {{ info?.name }}
  </div>
  <!-- delete -->
  <div
    *ngIf="isShowBtnDelete"
    class="message-file-delete"
    (click)="handleFileDeleteClick()"
  >
    <svg class="icon message-file-delete-svg" viewBox="0 0 1029 1024">
      <path
        d="M796.444444 859.022222c0 34.133333-28.444444 62.577778-62.577777 62.577778H284.444444c-34.133333 0-62.577778-28.444444-62.577777-62.577778V199.111111H796.444444v659.911111zM347.022222 85.333333c0-5.688889 5.688889-11.377778 11.377778-11.377777h301.511111c5.688889 0 11.377778 5.688889 11.377778 11.377777v39.822223H347.022222v-39.822223z m637.155556 39.822223h-238.933334v-39.822223c0-45.511111-39.822222-85.333333-85.333333-85.333333H364.088889c-51.2 0-91.022222 39.822222-91.022222 85.333333v39.822223H39.822222c-22.755556 0-39.822222 17.066667-39.822222 39.822222s17.066667 39.822222 39.822222 39.822222h113.777778v659.911111c0 73.955556 62.577778 136.533333 136.533333 136.533333H739.555556c73.955556 0 136.533333-62.577778 136.533333-136.533333V199.111111h113.777778c22.755556 0 39.822222-17.066667 39.822222-39.822222s-22.755556-34.133333-45.511111-34.133333zM512 813.511111c22.755556 0 39.822222-17.066667 39.822222-39.822222v-398.222222c0-22.755556-17.066667-39.822222-39.822222-39.822223-22.755556 0-39.822222 17.066667-39.822222 39.822223v398.222222c0 22.755556 17.066667 39.822222 39.822222 39.822222m-176.355556 0c22.755556 0 39.822222-17.066667 39.822223-39.822222v-398.222222c0-22.755556-17.066667-39.822222-39.822223-39.822223-22.755556 0-39.822222 17.066667-39.822222 39.822223v398.222222c5.688889 22.755556 22.755556 39.822222 39.822222 39.822222m352.711112 0c22.755556 0 39.822222-17.066667 39.822222-39.822222v-398.222222c0-22.755556-17.066667-39.822222-39.822222-39.822223-22.755556 0-39.822222 17.066667-39.822223 39.822223v398.222222c0 22.755556 17.066667 39.822222 39.822223 39.822222"
      ></path>
    </svg>
  </div>
</div>
