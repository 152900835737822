import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { ScenarioPanelService } from '../services';

@Pipe({
  name: 'buttonName',
})
export class NodeButtonNamePipe implements PipeTransform {
  constructor(private panelService: ScenarioPanelService) {}

  transform(value: string) {
    return this.panelService.nodeData.pipe(
      map(({ nodeButtonName }) => nodeButtonName?.[value.toLowerCase()] ?? value),
    );
  }
}
