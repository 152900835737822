import { InboxAction, InboxMessageType } from '../interfaces';

export const INBOX_TEMPLATE_MAP = new Map([
  [
    InboxMessageType.AppointmentCanStartOrders,
    {
      title: 'Reserved allocation',
      subtitle: 'The fund that you have booked is open for subscription now.',
      name: 'Dear [[firstName]],',
      content: `The fund that you have booked is open for subscription now.

If you have any questions, please contact our team or your dedicated sales representative.

Kind regards.
Your [[gpName]] team
[[email]]`,
      action: [InboxAction.View, InboxAction.Invest],
    },
  ],
  [
    InboxMessageType.SignedOrders,
    {
      title: 'Signing',
      subtitle: 'The investment documents have been submitted for signature.',
      name: `Dear [[firstName]],`,
      content: `Your investment documents in [[fundName]] are waiting to be signed. You can check out the progress in investment detail page.

If you have any questions, please contact our team or your dedicated sales representative.

Kind regards.
Your [[gpName]] team
[[email]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.UnconfirmedCapitalCall,
    {
      title: 'Capital call',
      subtitle: `Please transfer the subscription monies.`,
      name: `Dear [[firstName]],`,
      content: '',
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.NAVUpdate,
    {
      title: 'NAV updated',
      subtitle: `[[fundName]] Net Asset Value updated.`,
      name: `Dear [[firstName]],`,
      content: '',
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.TargetFundConfirmation,
    {
      title: 'Subscription confirmation',
      subtitle: 'The fund that you have booked is open for subscription now.',
      name: `Dear [[firstName]],`,
      content: `We are delighted to confirm your subscription to [[fundName]].
Please click "View investment" to view the detail of subscription.

If we can be of any assistance, please do not hesitate to get in touch.

Kind regards.
Your [[gpName]] team
[[email]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.ReceiptOfFunds,
    {
      title: 'Receipt of funds',
      subtitle: `Your wire transfer has been confirmed.`,
      name: `Dear [[firstName]],`,
      content: `Thank you for your wire transfer regarding your investment in [[fundName]].
We confirm receipt of [[currencySymbol]][[money]] and will inform you when your subscription confirmation is issued.

If you have any questions, please let us know.

Kind regards.
Your [[gpName]] team
[[email]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.InvestedSuccessfully,
    {
      title: 'Invested successfully',
      subtitle: `Successful investment order to [[fundName]]`,
      name: `Dear [[firstName]],`,
      content: `Thank you for your investment in [[fundName]].
Please monitor your platform notifications and emails to proceed with your investment process.

Should you need any further information, please do not hesitate to contact us.

Kind regards.
Your [[gpName]] team
[[email]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.AssistInvestEntity,
    {
      title: 'Review',
      subtitle: `[[gpName]] created an investment for your entity.`,
      name: `Dear [[investorName]],`,
      content: `[[gpName]] created an investment for your entity, please review the investment documents.

Fund: [[fundName]]
Entity name: [[entityName]]

Kind regards.
Your [[gp]] team
[[gpEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.AssistInvestIndividual,
    {
      title: 'Review',
      subtitle: '[[gpName]] created an investment for you.',
      name: `Dear [[investorName]],`,
      content: `[[gpName]] created an investment for you, please review and sign the investment documents.

Fund: [[fundName]]

Kind regards.
Your [[gp]] team
[[gpEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.KYCSuccessNotify,
    {
      title: 'KYC confirmed',
      subtitle: 'Your KYC status has been confirmed.',
      name: `Dear [[investorName]],`,
      content: `Thanks you for applying to [[gpName]] Investment portal. Your KYC status has been confirmed and you can see the Investment opportunities.

Kind regards,
Your [[gp]] team
[[gpEmail]]`,
      action: [InboxAction.ViewOpportunities],
    },
  ],
  [
    InboxMessageType.SubscriptionApproved,
    {
      title: 'Subscription confirmation',
      subtitle: '[[investor]] subscription to [[fundName]] has been confirmed.',
      name: `Dear [[investorFirstName]],`,
      content: `We are delighted to confirm [[investor]] subscription to [[fundName]].

If we can be of any assistance, please do not hesitate to get in touch.

Kind regards,
Your [[gp]] team
[[gpEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.SubscriptionApprovedNotice,
    {
      title: 'Subscription approved',
      subtitle: '[[investor]] subscription to [[fundName]] has been approved.',
      name: `Dear [[investorFirstName]],`,
      content: `We are delighted to inform you that [[investor]] subscription to [[fundName]] has been approved.

If we can be of any assistance, please do not hesitate to get in touch.

If you have any questions, please let us know.

Kind regards,
Your [[gp]] team
[[gpEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.SubscriptionRejected,
    {
      title: 'Subscription confirmation',
      subtitle: 'Your subscription to [[fundName]] has been declined.',
      name: `Dear [[investorFirstName]],`,
      content: `We are sorry to inform you that [[investor]] subscription for [[fundName]] has been declined.

Please contact our team or your dedicated sales representative if you have any questions.

Kind regards.
Your [[gp]] team
[[gpEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.ReserveSuccessfully,
    {
      title: 'Reserved successfully',
      subtitle: 'Successful reservation to [[fund_name]].',
      name: `Dear [[first_name]],`,
      content: `Thanks you for your interest in our fund opportunity, we will be in touch shortly.

Kind regards,
Your [[company_name]] team`,
      action: [],
    },
  ],
  [
    InboxMessageType.AddressMaterialTask,
    {
      title: 'Additional documents required',
      subtitle: 'Please upload: Proof of address - [[personName]]',
      name: 'Dear [[firstName]]',
      content: `Thank you for investment in [[fundName]]. Please upload your proof of address file to proceed with your investment:

Proof of address - [[personName]]

Address: [[address]]

In the meantime, if you have any questions please do get in touch with support at [[salesSupportEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.IdentityMaterialTask,
    {
      title: 'Additional documents required',
      subtitle: 'Please upload: Proof of identity - [[personName]]',
      name: 'Dear [[firstName]]',
      content: `Thank you for investment in [[fundName]]. Please upload your proof of identity file to proceed with your investment:

Proof of identity - [[personName]]

Document type: [[documentType]]
Identification number: [[number]]

In the meantime, if you have any questions please do get in touch with support at [[salesSupportEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.BankMaterialTask,
    {
      title: 'Additional documents required',
      subtitle: 'Please upload: Proof of bank - [[personName]]',
      name: 'Dear [[firstName]]',
      content: `Thank you for investment in [[fundName]]. Please upload your proof of bank file to proceed with your investment:

Proof of bank - [[fundName]]

Bank name: [[bankName]]
Account name: [[accountName]]
Account number: [[accountNumber]]

In the meantime, if you have any questions please do get in touch with support at [[salesSupportEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.SubscriptionRedeem,
    {
      title: 'Withdrawal application',
      subtitle: 'Your request for withdrawal has been received.',
      name: `Dear [[firstName]],`,
      content: `We have received your request to withdraw investment from [[fundName]]. A member of our team will be in touch to facilitate the process.

If you have any questions, please let us know.

Kind regards.
Your [[gpName]] team`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.ExpressInterest,
    {
      title: 'Interest expressed',
      subtitle: 'Your interest in [[fundName]] has been received.',
      name: 'Dear [[investorName]],',
      content: `Thank you very much for your interest in [[fundName]]. A member of the [[gpName]] Investor Services team will be in touch with further details on the next steps.

Kind regards,
Your [[gpName]] team
[[salesSupportEmail]]`,
    },
  ],
  [
    InboxMessageType.LPInvestorCallReceived,
    {
      title: 'Funds received',
      subtitle: 'Your wire transfer has been confirmed.',
      name: 'Dear [[investorName]],',
      content: `Thank you for your wire transfer regarding your investment in [[fundName]].

We confirm receipt of [[receivedAmount]].

If you have any questions, please let us know.

Kind regards,
Your [[gpName]] team
[[salesSupportEmail]]`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.CapitalCall,
    {
      title: 'Capital call',
      subtitle: `Please transfer the subscription monies.`,
      name: `Dear [[firstName]],`,
      content: '',
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.OpenCapitalCall,
    {
      title: 'Capital call',
      subtitle: `Please transfer the subscription monies.`,
      name: `Dear [[firstName]],`,
      content: '',
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.MandatoryDocumentsTask,
    {
      title: 'Upload Mandatory documents',
      subtitle: '',
      name: 'Dear [[RECIPIENT_FIRST_NAME]],',
      content: `Thank you for investment in [[FUND_NAME]]. Please upload mandatory documents to proceed with your investment:`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.AdditionalDocumentsTask,
    {
      title: 'Upload additional documents',
      subtitle: '',
      name: 'Dear [[RECIPIENT_FIRST_NAME]],',
      content: `Thank you for investment in [[FUND_NAME]].

Your subscription is being reviewed and we require some additional documents. Please upload the following documents required to proceed with your investment.`,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.ModifySubscriptionTask,
    {
      title: 'Request for changes to subscription',
      subtitle: 'Please make changes to investment to proceed subscription.',
      name: 'Dear [[recipientFirstName]],',
      content: `Thank you for investment in [[fundName]].

Your subscription is currently undergoing the [[reviewType]] and some changes are required. Using the feedback below please make the necessary changes to proceed with your investment.

[[feedbackContent]]

In the meantime, if you have any questions please do get in touch with support at [[salesSupportEmail]].`,
      action: [InboxAction.AmendYourInvestment, InboxAction.View],
    },
  ],
  [
    InboxMessageType.OpenCapitalCallWithIRA,
    {
      title: 'Fund capital call',
      subtitle: 'Transfer request',
      name: 'Dear [[recipientFullName]],',
      content: `A capital call has been issued for your investment in [[fundName]] for [[requestedAmount]].
As you have invested using a Inspira Financial IRA, you'll need to instruct the transfer. To begin, click “Instruct”below.

On receipt of your funds we will notify you via email. Please note it usually takes 5 days to clear transaction. In the meantime, if you have any questions please do not hesitate to get in touch.

Best regards,
Your [[gpName]] team
[[salesSupportEmail]]`,
      action: [InboxAction.Instruct, InboxAction.View],
    },
  ],
  [
    InboxMessageType.CloseCapitalCallWithIRA,
    {
      title: 'Fund capital call',
      subtitle: '[[fundName]] capital call',
      name: 'Dear [[recipientFullName]],',
      content: `[[customParagraph]]

Congratulations on your investment in [[fundName]].

A capital call has been issued for your investment in [[fundName]] for [[requestedAmount]].

As you have invested using a Inspira Financial IRA, you'll need to instruct the transfer before [[dueDate]].

On receipt of your funds we will notify you via email. Please note it usually takes 5 days to clear transaction. In the meantime, if you have any questions please do not hesitate to get in touch.

Best regards,
Your [[gpName]] team
[[salesSupportEmail]]`,
      action: [InboxAction.Instruct, InboxAction.View],
    },
  ],
  [
    InboxMessageType.FECOpenCapitalCallWithIRAResult,
    {
      title: 'Fund capital call',
      subtitle: 'Transfer instructed',
      name: 'Dear [[recipientFullName]],',
      content: `Please check back in due course for confirmation of receipt. Amount instructed [[transferAmount]].

[[customTable]]

Best regards,
Your [[gpName]] team
[[salesSupportEmail]]`,
      customTable: {
        col: [{ style: { width: '35%' } }, { style: { width: '65%' } }],
        date: [
          [
            {
              type: 'primary',
              value: 'Asset name',
            },
            {
              type: 'default',
              value: '[[fundName]]',
            },
          ],
          [
            {
              type: 'primary',
              value: 'Total committed amount',
            },
            {
              type: 'default',
              value: '[[totalCommittedAmount]]',
            },
          ],
          [
            {
              type: 'primary',
              value: 'Requested amount',
            },
            {
              type: 'default',
              value: '[[requestedAmount]]',
            },
          ],
          [
            {
              type: 'primary',
              value: 'Amount instructed to transfer',
            },
            {
              type: 'default',
              value: '[[transferAmount]]',
            },
          ],
        ],
      },
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.FECCloseCapitalCallWithIRAResult,
    {
      title: 'Fund capital call',
      subtitle: 'Transfer instructed',
      name: 'Dear [[recipientFullName]],',
      content: `Please check back in due course for confirmation of receipt. Amount instructed [[transferAmount]].

[[customTable]]

Best regards,
Your [[gpName]] team
[[salesSupportEmail]]`,
      customTable: {
        col: [{ style: { width: '35%' } }, { style: { width: '65%' } }],
        date: [
          [
            {
              type: 'primary',
              value: 'Asset name',
            },
            {
              type: 'default',
              value: '[[fundName]]',
            },
          ],
          [
            {
              type: 'primary',
              value: 'Total committed amount',
            },
            {
              type: 'default',
              value: '[[totalCommittedAmount]]',
            },
          ],
          [
            {
              type: 'primary',
              value: 'Requested amount',
            },
            {
              type: 'default',
              value: '[[requestedAmount]]',
            },
          ],
          [
            {
              type: 'primary',
              value: 'Due date',
            },
            {
              type: 'default',
              value: '[[dueDate]]',
            },
          ],
          [
            {
              type: 'primary',
              value: 'Amount instructed to transfer',
            },
            {
              type: 'default',
              value: '[[transferAmount]]',
            },
          ],
        ],
      },
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.LPProductSubscriptionRedemption,
    {
      title: 'Redemption Update',
      subtitle: 'Your redemption request has been confirmed.',
      name: `Dear [[investor]],`,
      content: `We have confirmed your redemption request for [[fundName]].
Here are the summary for this redemption:

No. of shares redeemed: [[noOfShares]]
NAV per share: [[navPerShare]]
Total redemption amount: [[redeemedAmount]]

If you have any questions, please contact our team or your dedicated sales representative.

Kind regards,
Your [[gp]] team
[[gpEmail]]
      `,
      action: [InboxAction.View],
    },
  ],
  [
    InboxMessageType.RedemptionRequest,
    {
      title: 'Redemption Request',
      subtitle: 'Your redemption request has been received.',
      name: `Dear [[investor]],`,
      content: `We have received your request to redeem [[redemptionContent]] in [[fundName]] and we will proceed according to the terms of your investment agreement.

If you have any questions, please contact our team or your dedicated sales representative.

Kind regards,
Your [[gp]] team
[[gpEmail]]
      `,
      action: [InboxAction.View],
    },
  ],
]);
