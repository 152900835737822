import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Question, QuestionType, StreamFormlyType, TableCellType } from '@stream/models';

const FORM_FIELD_TYPE_MAP = new Map([
  [QuestionType.RadioGroup, StreamFormlyType.BITE_RADIO_GROUP],
  [QuestionType.Text, StreamFormlyType.INPUT],
]);

@Pipe({
  name: 'questionFormly',
})
export class QuestionFormlyPipe implements PipeTransform {
  transform(question: Question | null): FormlyFieldConfig[] {
    if (!question) return [];

    if (question.type === QuestionType.CheckboxGroup) {
      return [this.transformCheckboxGroupField(question)];
    }
    return [
      {
        type: FORM_FIELD_TYPE_MAP.get(question.type) || question.type,
        key: question.name,
        templateOptions: {
          options: question.extras?.options?.map(({ value, text: { en } }) => ({
            value,
            label: en,
          })),
          required: question.required,
          block: question.type === QuestionType.RadioGroup,
          tableOptions: {
            rows: question.extras?.rows,
            columns: question.extras?.columns,
            cellType: question.extras?.cellStyle,
          },
        },
        ...this.getTableConfig(question),
      },
      ...this.hasOtherRadio(question),
    ];
  }

  transformCheckboxGroupField(question: Question): FormlyFieldConfig {
    return {
      key: question.name,
      fieldGroup: question.extras?.options?.map(option => ({
        type: 'checkbox',
        key: option.value,
        templateOptions: {
          label: option.text.en,
        },
      })),
      templateOptions: {
        required: question.required,
      },
      validators: {
        required: {
          expression: (control: UntypedFormControl) =>
            Object.values(control.value).some(Boolean),
          message: 'This field is required',
        },
      },
      wrappers: ['checkbox-group-wrapper'],
    };
  }

  hasOtherRadio(question: Question): FormlyFieldConfig[] {
    if (
      question.type === QuestionType.RadioGroup &&
      question.extras?.options?.some(({ value }) => value === 'Other')
    ) {
      return [
        {
          type: StreamFormlyType.INPUT,
          key: `${question.name}_other`,
          hideExpression: function ($viewValue, _$modelValue, $scope) {
            const hide = $viewValue[question.name] !== 'Other';
            if (hide) {
              $scope?.formControl?.reset();
            }
            return hide;
          },
          templateOptions: {
            label: 'Other',
            required: question.required,
          },
        },
      ];
    }
    return [];
  }

  getTableConfig(question: Question): any {
    if (question.type === QuestionType.Table) {
      if (question.extras?.cellStyle === TableCellType.Radio) {
        return {
          fieldArray: {
            type: StreamFormlyType.BITE_RADIO_GROUP,
          },
          modelOptions: {
            updateOn: 'submit',
          },
          validators: {
            required: {
              expression: (array: UntypedFormArray) =>
                array.controls.every(c => c.value != null),
              message: 'This field is required',
            },
          },
        };
      }
    }
    return {};
  }
}
