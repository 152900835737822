import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ChatAsset,
  ChatAssetCategory,
  ChatAssetWithCategory,
  ChatCategory,
  ChatConversationRecipient,
  ChatResponseEnum,
  ChatSubscription
} from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';

import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '@stream/service/client.service';
import { ProductService } from '@stream/service/product.service';
import { ChatService } from '../../../../services/chat.service';
import { ChatOngoingConversationComponent } from '../chat-ongoing-conversation/chat-ongoing-conversation.component';

@Component({
  selector: 'stream-chat-conversation-add',
  templateUrl: './conversation-add.component.html',
  styleUrls: ['./conversation-add.component.scss']
})
export class ChatConversationAddComponent implements OnInit {
  categoryList: ChatCategory[] = [];
  assetWithCategory: ChatAssetWithCategory[] = [
    {
      assetId: 'NOT_RELATED_TO_ANY_ASSETS',
      assetName: 'Not related to any assets'
    }
  ];

  subscriptionList: ChatSubscription[] = [];
  recipientList: ChatConversationRecipient[] = [];
  institutional = this.chatService.isInstitutional;
  fileUploadApi = this.chatService.fileUploadApi;

  form = this.fb.group({
    categoryId: [null, Validators.required],
    subject: [null, [Validators.required, Validators.maxLength(256)]],
    subscriptionId: [null],
    assetId: [null, Validators.required],
    recipientIds: [null],
    message: [
      null,
      (control: any) => {
        const value = control?.value;
        if (!value) return { required: true };
        const { contentValue, fileValue } = value;
        if (contentValue || (fileValue && fileValue.length > 0)) {
          return null;
        } else {
          return { required: true };
        }
      }
    ]
  });

  get subscriptionRequired() {
    return !!this.form.get('subscriptionId')?.hasValidator(Validators.required);
  }

  get selectedSubscription() {
    const sid = this.form.get('subscriptionId')?.value;
    if (!sid) return null;
    const subscription = this.subscriptionList.find(item => item.id === sid);
    return subscription;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private chatService: ChatService,
    private clientService: ClientService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ChatConversationAddComponent>,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getRecipientList();
    this.clientService.configuration.subscribe(config => {
      const { type: tenantType } = config || {};
      if (['FULL_GP', 'UNREGULATED_MARKETPLACE'].includes(tenantType)) {
        this.getSubscriptionList();
      }
    });
    // init asset list
    this.loadAssetList();
    this.getCategoryList(this.getCurrentAssetId() ?? 'NOT_RELATED_TO_ANY_ASSETS');

    // Add listener for assetId changes
    this.form.get('assetId')?.valueChanges.subscribe(assetId => {
      if (assetId) {
        this.getCategoryList(assetId);
      }
    });

    // Add listener for categoryId changes
    this.form.get('categoryId')?.valueChanges.subscribe(categoryId => {
      if (categoryId) {
        this.updateSubscriptionValidation(categoryId);
      }
    });
  }

  getCategoryList(productId: string) {
    const location = this.chatService.getLocation();
    this.productService.getProductCategories(location, productId).subscribe(response => {
      // process data
      this.categoryList = response.data.map((item: ChatAssetCategory) => {
        return {
          id: item.id,
          title: item.title,
          requiredSubscription: item.requiredSubscription ? 1 : 0
        };
      });

      // Reset categoryId when categories change
      this.form.patchValue({ categoryId: null });
    });
  }

  getSubscriptionList() {
    this.chatService.getSubscriptions().subscribe(data => {
      this.subscriptionList = data;
    });
  }

  getRecipientList() {
    this.chatService.getChatConversationRecipientsNotExists().subscribe(data => {
      this.recipientList = data;
    });
  }

  updateSubscriptionValidation(categoryId: string) {
    const category = this.categoryList.find(cat => cat.id === categoryId);
    const subscriptionControl = this.form.get('subscriptionId');

    if (category?.requiredSubscription) {
      subscriptionControl?.setValidators(Validators.required);
    } else {
      subscriptionControl?.clearValidators();
    }

    subscriptionControl?.updateValueAndValidity();
  }

  getCurrentAssetId(): string | null {
    // Remove the hash fragment
    const urlWithoutFragment = this.router.url.split('#')[0];
    const urlParts = urlWithoutFragment.split('/');

    if (
      urlParts.length >= 4 &&
      urlParts[1].toLocaleLowerCase() === 'product' &&
      urlParts[2].toLocaleLowerCase() === 'detail'
    ) {
      return urlParts[3];
    }

    // other url: http://john.localhost:4300/scenario/INVESTING/1777535783033888770
    if (
      urlParts.length >= 3 &&
      urlParts[1].toLocaleLowerCase() === 'scenario' &&
      urlParts[2].toLocaleLowerCase() === 'investing'
    ) {
      return urlParts[3];
    }

    // another: http://john.localhost:4300/portfolio/investments/detail/1767435381758308354
    if (
      urlParts.length >= 5 &&
      urlParts[1].toLocaleLowerCase() === 'portfolio' &&
      urlParts[2].toLocaleLowerCase() === 'investments' &&
      urlParts[3].toLocaleLowerCase() === 'detail'
    ) {
      return urlParts[4];
    }

    // compliance: https://john.terry.engr.bite.dev/scenario/FUND_DATA/1777535783033888770/1777535784510283777
    if (urlParts.length >= 4 && urlParts[1].toLocaleLowerCase() === 'scenario') {
      return urlParts[3];
    }

    return null;
  }

  loadAssetList() {
    // merge asset list
    const location = this.chatService.getLocation();
    this.productService.getProductsHasCategory(location).subscribe(response => {
      // append
      this.assetWithCategory = this.assetWithCategory.concat(
        response.data.map((item: ChatAsset) => {
          return {
            assetId: item.assetId,
            assetName: item.asset
          };
        })
      );

      // smart select assetId
      const currentAssetId = this.getCurrentAssetId();
      if (
        currentAssetId &&
        this.assetWithCategory.some(asset => asset.assetId === currentAssetId)
      ) {
        this.form.patchValue({ assetId: currentAssetId });
      } else {
        this.form.patchValue({ assetId: 'NOT_RELATED_TO_ANY_ASSETS' });
      }
    });
  }

  viewConversation(id: string) {
    this.dialogRef.close(id);
  }

  create() {
    const values = this.form.value;
    const { contentValue, fileValue } = values.message;
    this.chatService
      .createConversation({
        categoryId: values.categoryId,
        subject: values.subject,
        bizObjectId: values.subscriptionId || undefined,
        recipientIds:
          values.recipientIds && values.recipientIds.length ? values.recipientIds : undefined,
        message: {
          content: contentValue,
          attachments: fileValue || []
        },
        assetId: values.assetId
      })
      .subscribe(
        data => {
          if (data.status === ChatResponseEnum.Success && data.conversationDto?.id) {
            this.viewConversation(data.conversationDto.id);
          } else if (data.status === ChatResponseEnum.InviteUpperLimit) {
            this.dialog.open(ChatOngoingConversationComponent, {
              data: {
                showLimitReached: true
              },
              width: '580px',
              maxWidth: '85vw'
            });
          } else {
            this.notification.error(
              'Your request could not be processed. Please verify the information provided and try again.'
            );
          }
        },
        err => {
          if (err.status !== 400) {
            this.notification.error(
              'Your request could not be processed. Please verify the information provided and try again.'
            );
          }
        }
      );
  }

  submit() {
    if (this.form.invalid) return;
    const subscriptionId = this.form.get('subscriptionId')?.value;
    if (!subscriptionId) {
      this.create();
      return;
    }
    this.chatService
      .checkRelatedChatConversation(subscriptionId, this.form.get('categoryId')?.value)
      .subscribe(({ conversationId, accountId, isRecipient }) => {
        if (!conversationId) {
          this.create();
          return;
        }
        this.dialog
          .open(ChatOngoingConversationComponent, {
            data: {
              conversationId,
              accountId,
              isRecipient
            },
            width: '680px',
            maxWidth: '95vw'
          })
          .afterClosed()
          .subscribe((result: boolean) => {
            if (result) {
              this.viewConversation(conversationId);
            }
          });
      });
  }

  getMembersTriggerText() {
    const recipientIds = this.form.get('recipientIds')?.value;
    if (!Array.isArray(recipientIds) || !recipientIds.length) return '';
    const names = recipientIds
      .map(id => {
        const member = this.recipientList.find(item => item.recipientId === id);
        return member?.userName;
      })
      .filter(name => !!name);
    return names.join(',');
  }
}
