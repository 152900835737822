import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatResponseEnum } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';

import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'stream-chat-ongoing-conversation',
  templateUrl: './chat-ongoing-conversation.component.html',
  styleUrls: ['./chat-ongoing-conversation.component.scss'],
})
export class ChatOngoingConversationComponent {
  constructor(
    private chatService: ChatService,
    private notification: NotificationService,
    private dialogRef: MatDialogRef<ChatOngoingConversationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      showLimitReached: boolean;
      conversationId?: string;
      accountId?: string;
      isRecipient?: boolean;
    }
  ) {
    this.showLimitReached = this.data.showLimitReached || false;
  }

  showLimitReached = false;

  get title() {
    return !this.showLimitReached
      ? 'Ongoing-conversation'
      : 'Recipients limit reached';
  }

  get content() {
    return !this.showLimitReached
      ? 'You already have an ongoing conversation related to this subscription. Would you like to navigate to the conversation?'
      : 'The conversation has reached the maximum number of recipients (50). If you wish to invite additional recipients, please remove existing recipients.';
  }

  submit() {
    if (this.showLimitReached) {
      this.dialogRef.close();
      return;
    }
    if (this.data.isRecipient) {
      this.dialogRef.close(true);
      return;
    }
    this.chatService
      .addChatConversationRecipients(
        this.data.conversationId!,
        this.data.accountId!
      )
      .subscribe(
        ({ status }) => {
          if (status === ChatResponseEnum.Success) {
            this.dialogRef.close(true);
          } else if (status === ChatResponseEnum.InviteUpperLimit) {
            this.showLimitReached = true;
          } else {
            this.notification.error(
              'Your request could not be processed. Please verify the information provided and try again.'
            );
          }
        },
        () => {
          this.notification.error(
            'Your request could not be processed. Please verify the information provided and try again.'
          );
        }
      );
  }
}
