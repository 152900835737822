import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedService } from './services/shared.service';

@NgModule({
  imports: [CommonModule],
  providers: [SharedService],
})
export class SharedModule {}
