<form [formGroup]="form" (ngSubmit)="submit()" class="flex h-full flex-col">
  <div class="!flex items-center" mat-dialog-title>
    <h1 class="text-xl font-semibold flex-1">
      {{ data.identity ? 'Edit' : 'Add' }} identity
    </h1>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    mat-dialog-content
    class="identity-form-content flex-1"
    [style]="{ 'max-height': contentH }"
  >
    <mat-form-field>
      <mat-label>Document type</mat-label>
      <mat-select formControlName="documentType">
        <mat-option
          *ngFor="let option of data.tenantAttributeList"
          [value]="option.attributeName"
        >
          {{ option.attributeValue }}
        </mat-option>
      </mat-select>
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> Identification number </mat-label>
      <input matInput formControlName="identityNumber" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Expiry date (mm/dd/yyyy)</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [min]="todayDate"
        formControlName="expirationDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <stream-mandatory-doc-item
      *ngIf="!hideIdentity"
      #mandatoryDocItem
      [form]="mdForm"
      [md]="mdConfig.mandatoryDocs[0]"
      [config]="mdConfig"
    >
      <p class="doc-description text-xs mb-2">
        Please upload a proof of identity that will be used for this investor profile.
      </p>
    </stream-mandatory-doc-item>
  </div>

  <div mat-dialog-actions class="grid grid-cols-2 justify-around md:flex md:justify-end">
    <button
      mat-stroked-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      cdkFocusInitial
      type="submit"
      [disabled]="loading"
    >
      Confirm
    </button>
  </div>
</form>
