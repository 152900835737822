import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';

@Component({
  selector: 'stream-invite-member-notification',
  templateUrl: './invite-member-notification.component.html',
  styleUrls: ['./invite-member-notification.component.scss']
})
export class InviteMemberNotificationComponent implements OnInit {
  @Input() mobileMode: boolean = false;

  number = 10;

  timer: any;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.countDown();
  }

  countDown() {
    this.timer = setInterval(() => {
      this.number--;
      if (this.number === 0) {
        this.dismiss();
      }
    }, 1000);
  }

  dismiss() {
    clearInterval(this.timer);
    this.authService.storeNotificationRecord(false);
  }

  toInviteMember() {
    this.dismiss();
    this.router.navigate(['/account/team-members']);
  }
}
