import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { FORM_ERROR_MESSAGE } from '@stream/models';
import { NGX_MAT_ERROR_DEFAULT_OPTIONS } from 'ngx-mat-errors';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        disableOptionCentering: true
      }
    },
    {
      provide: NGX_MAT_ERROR_DEFAULT_OPTIONS,
      useFactory: () => ({
        ...[...FORM_ERROR_MESSAGE.entries()].reduce(
          (acc, [key, value]) => ({ ...acc, [key]: value }),
          {}
        )
      })
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
