import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { AccountService } from '../../../services/account.service';

@Pipe({
  name: 'accountDate',
})
export class AccountDatePipe implements PipeTransform {
  constructor(
    private accountSv: AccountService,
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  transform(
    value: Date | string | number | null | undefined,
    format: string = 'MMM dd, y',
    timezone?: string,
    _locale: string = 'UTC'
  ): Observable<any> {
    const tz = moment.tz.guess();
    return this.accountSv.account.pipe(
      pluck('timeZoneText'),
      map((tzt) => {
        return new DatePipe(this.localeId).transform(
          value,
          format,
          timezone || tzt ? `UTC${moment(value).tz(tzt).format('Z')}` : moment(value).tz(tz).format('Z')
        );
      })
    );
  }
}
