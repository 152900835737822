<div class="mb-2 flex items-center gap-x-1 text-base font-normal " *ngIf="md.documentName">
  <label class="line-clamp-1">{{ mandatoryDocService.transLabelName(md.owner, md.documentName) }}</label>
  <mat-icon
    class="!text-lg text-[#62656C] flex-none"
    #icon
    *ngIf="needShowTooltip"
    nz-popover
    nzPopoverOverlayClassName="max-w-xl"
    nzPopoverPlacement="bottom"
    [nzPopoverOrigin]="icon._elementRef"
    [nzPopoverContent]="info"
    >info_outlined</mat-icon
  >
  <ng-template #info>
    <stream-quill-view [pureViewer]="true" [content]="md.documentDescription"></stream-quill-view>
  </ng-template>
  <span *ngIf="config.checkAllFiles" class="text-red-500">*</span>
</div>

<ng-content selector=".doc-description"></ng-content>

<div class="rounded bg-[#F8F9FA] px-3 py-4">
  <form class="flex w-full flex-col gap-y-4" [formGroup]="form">
    <div>
      <stream-mandatory-doc-upload
        formControlName="file"
        accept="application/pdf,image/jpeg,image/png"
        [fileName]="
          md.multiple
            ? null
            : md.fileName ?? mandatoryDocService.transLabelName(md.owner, md.documentName)
        "
        [trigger]="config.allowReuse ? docReuseTrigger : null"
        [fileListRef]="uploadFileList"
        [errorMsgRef]="errorMsgRef"
      ></stream-mandatory-doc-upload>
      <ng-container *ngIf="config.allowReuse">
        <a
          #menuTrigger="matMenuTrigger"
          class="inline-flex cursor-pointer items-center gap-x-1 text-sm text-primary-400 hover:text-primary-300"
          [matMenuTriggerFor]="menu"
          (click)="isReusedPanelOpen = true"
        >
          Or select from existing documents
          <mat-icon>{{ isReusedPanelOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
        </a>
        <mat-menu
          #menu="matMenu"
          class="w-[468px] !max-w-[468px]"
          (closed)="onReusedPanelClosed($event)"
        >
          <ng-template matMenuContent>
            <div class="p-3" (click)="$event.stopPropagation()">
              <stream-mandatory-doc-reuse
                [mandatory]="md"
                [tags]="md.tags || []"
                [profileId]="config.profileId || ''"
                (selectChange)="onSelectChange($event); menuTrigger.closeMenu()"
              ></stream-mandatory-doc-reuse>
            </div>
          </ng-template>
        </mat-menu>
      </ng-container>
    </div>
    <div class="flex w-full flex-col items-center gap-y-2 sm:flex-row sm:gap-x-2">
      <mat-form-field class="md-form-field" subscriptSizing="dynamic">
        <mat-label>Document type</mat-label>
        <mat-select
          formControlName="tag"
          [required]="hasFile"
          (selectionChange)="onTagChange($event)"
        >
          <!-- cause tag's id may not exist in the list, so we need to use name to match it. #STREAM-8758 -->
          <mat-option></mat-option>
          <mat-option *ngFor="let tag of tagList$ | async" [value]="tag.label">{{
            tag.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="md-form-field" subscriptSizing="dynamic" *ngIf="!isTagEmpty">
        <mat-label>Country of origin</mat-label>
        <mat-select formControlName="country">
          <mat-option></mat-option>
          <mat-option *ngFor="let country of countryList$ | async" [value]="country.value">{{
            country.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-template #docReuseTrigger>
      <div>
        <span class="cursor-pointer text-base underline">Upload</span>
      </div>
    </ng-template>
    <ng-template #uploadFileList let-uploadRef="uploadRef">
      <div
        *ngFor="let doc of form.get('file')?.value || []; let i = index"
        class="upload-file-item"
      >
        <div class="flex w-full items-center gap-x-2 rounded bg-[#F8F9FA] px-4 py-2">
          <svg class="icon text-lg">
            <use [attr.xlink:href]="uploadRef.getIconByType(doc?.type)"></use>
          </svg>
          <span class="flex-1 cursor-pointer text-sm text-gray-700 line-clamp-1">
            {{ doc.name }}
          </span>
          <div class="flex items-center gap-x-1">
            <button
              mat-icon-button
              type="button"
              *ngIf="config.allowReuse"
              (click)="uploadRef.loading || uploadRef.disabled ? null : reUpload.click()"
            >
              <input
                type="file"
                hidden
                #reUpload
                (input)="uploadRef.onInput($event)"
                [accept]="uploadRef.accept"
                [multiple]="false"
              />
              <mat-icon class="text-gray-500">replay</mat-icon>
            </button>
            <button *ngIf="config.allowDelete" mat-icon-button type="button" (click)="uploadRef.deleteFile(i)">
              <mat-icon class="text-gray-500">delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #errorMsgRef let-uploadRef="uploadRef">
      <div *ngIf="uploadRef.showUploadButton && isErrorMsgShow" class="text-sm text-[#DC202B]">
        {{ md.errorMessage ?? config.errorMessage ?? 'This field is required.' }}
      </div>
    </ng-template>
  </form>
</div>
