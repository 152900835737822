import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatConversationRecipient, ChatResponseEnum } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { forkJoin } from 'rxjs';

import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'stream-chat-members-edit',
  templateUrl: './chat-members-edit.component.html',
  styleUrls: ['./chat-members-edit.component.scss']
})
export class ChatMembersEditComponent implements OnInit {
  showLimitReached = false;

  memberList: ChatConversationRecipient[] = [];

  membersCtr = new FormControl<string[] | null>(null);

  constructor(
    private chatService: ChatService,
    private notification: NotificationService,
    private dialogRef: MatDialogRef<ChatMembersEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      members: ChatConversationRecipient[];
      conversationId: string;
    }
  ) {
    if (this.data.members?.length) {
      this.membersCtr.patchValue(this.data.members.map(item => item.recipientId));
    }
  }

  ngOnInit(): void {
    this.getMemberList();
  }

  getMemberList() {
    this.chatService.getChatConversationRecipientsNotExists().subscribe(data => {
      this.memberList = data;
    });
  }

  getMembersTriggerText() {
    const value = this.membersCtr?.value;
    if (!Array.isArray(value) || !value.length) return '';
    const names = value
      .map(id => {
        const member = this.memberList.find(item => item.recipientId === id);
        return member?.userName;
      })
      .filter(name => !!name);
    return names.join(',');
  }

  submit() {
    if (this.showLimitReached) {
      this.showLimitReached = false;
      return;
    }
    const newMemberIds = this.membersCtr?.value;
    if (!newMemberIds || !Array.isArray(newMemberIds)) return;
    const originIds: string[] = this.data.members.map(item => item.recipientId);
    const addIds: string[] = newMemberIds.filter(id => !originIds.includes(id));
    const delIds: string[] = originIds.filter(id => !newMemberIds.includes(id));

    forkJoin([
      ...delIds.map(id =>
        this.chatService.deleteChatConversationRecipients(this.data.conversationId, id)
      ),
      ...addIds.map(id =>
        this.chatService.addChatConversationRecipients(this.data.conversationId, id)
      )
    ]).subscribe(
      result => {
        if (result.some(item => item.status === ChatResponseEnum.Success)) {
          this.dialogRef.close(true);
        } else if (result[result.length - 1].status === ChatResponseEnum.InviteUpperLimit) {
          this.showLimitReached = true;
        } else {
          this.notification.error(
            'Your request could not be processed. Please verify the information provided and try again.'
          );
        }
      },
      () => {
        this.notification.error(
          'Your request could not be processed. Please verify the information provided and try again.'
        );
      }
    );
  }

  close() {
    if (this.showLimitReached) {
      this.showLimitReached = false;
      return;
    }
    this.dialogRef.close();
  }
}
