import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrmApi } from '../interface/http.api';
import {
  Investor,
  MemberStatus,
  SignLog,
  accountStatus,
  currentSales,
} from '../model/investor.model';

import { Rest } from '../interface/http';

@Injectable()
export class InvestorService {
  constructor(private http: HttpClient) {}

  getPrincipalDetail(params: { principalId: string }) {
    return this.http.post<
      Rest<{
        hasInviteButton: boolean;
        principal: Investor;
        signLogsList: Array<SignLog>;
        status: string;
        principalGroupList: Array<{ name: string; id: number }>;
        accountStatus: MemberStatus;
        sale: currentSales;
        principalAccountStatus: accountStatus;
        sourceName?: string;
        sourceLogo?: string;
        wmCandidate?: boolean;
        ownerInvestAccountId?: string;
      }>
    >(CrmApi.principalDetail, { ...params });
  }
}
