<div class="flex h-full flex-col">
  <div class="p-[30px] pb-0">
    <div class="flex items-center">
      <div class="flex-grow text-[22px] font-semibold leading-[26px] text-[#101213]">
        Conversation
      </div>
      <button mat-icon-button class="!h-6 !w-6 !p-0" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="conversation-search">
      <!-- validate floatLabel="never" delete -->
      <mat-form-field>
        <input
          [(ngModel)]="searchValue"
          matInput
          placeholder="Search by subject"
          autocomplete="off"
          (keyup.enter)="handleSearch()"
        />
        <mat-icon class="text-primary-200" matSuffix (click)="handleSearch()">search</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="grow overflow-hidden">
    <div
      #conversationsWrapper
      *ngIf="listData.length"
      class="conversation-result"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollUpDistance]="0"
      (scrolled)="handleScrollDown()"
    >
      <div
        class="conversation-card"
        *ngFor="let item of listData; trackBy: trackByFn"
        (click)="handleConversationClick(item.id)"
      >
        <div class="flex justify-between gap-2">
          <div class="relative truncate pr-8 text-base font-bold text-[#101213]">
            {{ item.categoryTitle }}:{{ item.subject }}
            <span class="badge" *ngIf="item.unReadCount">
              {{ item.unReadCount > 99 ? '99+' : item.unReadCount }}
            </span>
          </div>
          <div class="text-xs">
            {{ item.message.messageTime | accountDate: 'dd/MM' | async }}
          </div>
        </div>
        <div class="mt-2" *ngIf="item.subscription?.fundName">
          <stream-chat-fund-info [subscription]="item.subscription"></stream-chat-fund-info>
        </div>
        <div class="mt-2 truncate">
          {{ item.message.userName }}:
          {{ item.message.attachment?.[0]?.name || item.message.content || '' }}
        </div>
      </div>
    </div>
    <stream-no-data class="h-full" type="wave" *ngIf="!loading && !listData.length">
      <span class="description">No conversation</span>
    </stream-no-data>
  </div>
  <div class="flex w-full flex-row-reverse justify-around gap-x-5 p-[30px] pt-5 md:justify-start">
    <button
      mat-flat-button
      type="button"
      color="primary"
      class="mat-button-lg basis-[168px] font-semibold tracking-normal"
      (click)="openConversationModal()"
    >
      New Conversation
    </button>
    <button
      mat-stroked-button
      color="primary"
      (click)="close()"
      type="button"
      class="mat-button-lg max-w-[168px] shrink-0 grow basis-[80px] font-semibold tracking-normal"
    >
      Close
    </button>
  </div>
</div>
