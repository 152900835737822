<div class="flex w-full flex-col">
  <form [formGroup]="form" class="grid w-full grid-cols-2 items-center gap-x-2.5 mb-2">
    <mat-form-field class="md-form-field flex-1" subscriptSizing="dynamic">
      <mat-label>Document type</mat-label>
      <mat-select formControlName="tag" [panelWidth]="300">
        <mat-option [value]="UNTAGGED_OPTION_VALUE">Untagged ({{ getTagCount('Untagged') }})</mat-option>
        <mat-option *ngFor="let tag of tags" [value]="tag.value">
          {{ tag.label }} ({{ getTagCount(tag.label) }})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="md-form-field flex-1" subscriptSizing="dynamic">
      <mat-label>Search</mat-label>
      <input matInput formControlName="keyword" type="text" (keydown.enter)="onSearch()" />
      <span class="material-icons cursor-pointer" matSuffix (click)="onSearch()"> search </span>
    </mat-form-field>
  </form>
  <div class="relative h-[260px] w-full overflow-hidden overflow-y-auto">
    <stream-no-data class="h-full" type="wave" *ngIf="documents.length === 0">
      <span class="description !text-sm">No documents found</span>
    </stream-no-data>
    <div class="flex w-full flex-col gap-y-2">
      <div
        class="doc-item"
        [class.disabled]="doc.disabled"
        *ngFor="let doc of documents"
        (click)="onSelect(doc)"
      >
        <div class="flex w-full flex-1 items-center gap-x-2">
          <svg class="icon flex-none text-lg">
            <use [attr.xlink:href]="getIconByType(doc?.fileType)"></use>
          </svg>
          <div class="max-w-fit flex items-center gap-x-1 text-sm text-gray-700">
            <span class="line-clamp-1" [matTooltip]="doc.fileName" [matTooltipShowDelay]="1000">
              {{ doc.fileName || '--' }}
            </span>
          </div>
          <span
            *ngIf="doc.tag"
            class="rounded bg-[#fff9eb] py-0.5 px-2 text-black min-w-fit text-sm"
            [matTooltip]="getDocumentTagLabel(doc)"
            [matTooltipShowDelay]="1000"
          >
            {{ getDocumentTagLabel(doc) }}
          </span>
          <span class="flex-1"></span>
          <mat-icon
            *ngIf="doc.disabled"
            class="flex-none text-neutral-400"
            matTooltip="Unable to select this file as the associated country does not meet the requirements."
            [matTooltipShowDelay]="200"
            matTooltipPosition="above"
          >info_outlined</mat-icon>
        </div>
      </div>
    </div>
    <div class="loading-modal" *ngIf="loading">
      <div class="loader"></div>
    </div>
  </div>
</div>
