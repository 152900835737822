<div class="profile-container max-w-[100vw]" cdkScrollable>
  <header
    class="fixed left-0 top-0 z-[3] flex h-14 w-[100vw] items-center gap-2 border-b border-solid border-primary-100 bg-white px-5 md:h-[78px]"
  >
    <svg class="icon text-xl text-primary-500 md:text-primary-200" (click)="location.back()">
      <use xlink:href="#icon-a-icon-return1"></use>
    </svg>

    <h1 class="flex-1 pl-3 text-center text-lg font-semibold md:text-left">
      <span *ngIf="isEdit; else createTitle"> Edit investor profile </span>
      <ng-template #createTitle> Create a new Investor profile </ng-template>
    </h1>

    <div class="md:hidden" (click)="openProgressPanel()">
      <svg class="icon text-2xl" aria-hidden="true">
        <use xlink:href="#icon-a-icon-shouqi"></use>
      </svg>
    </div>
    <stream-chat-widget></stream-chat-widget>
  </header>

  <div class="flex w-full flex-1 pb-2" streamAnchorManagement #anchorManagement="anchorManagement">
    <!-- form nav -->
    <ng-container *ngIf="isMedium | async" [ngTemplateOutlet]="NAV_PROGRESS"></ng-container>

    <!-- form content -->
    <div class="form-content flex-1 bg-white p-5 pb-32 shadow-xl md:p-10 md:pb-10">
      <ng-container *ngIf="showProfileType">
        <h2 class="mb-2 text-lg font-semibold">
          <span class="text-red-500">*</span>
          Please select your investor type from the options below
        </h2>
        <mat-radio-group
          required
          [disabled]="isEdit || principalType === PrincipalType.Institutional"
          class="mb-6 grid grid-cols-2 gap-x-4"
          [(ngModel)]="profileType"
        >
          {{ profileId | async }}
          <mat-radio-button [value]="ProfileType.Individual">
            <svg class="icon svg-icon inline text-2xl" aria-hidden="true">
              <use xlink:href="#icon-geren"></use>
            </svg>
            Individual</mat-radio-button
          >
          <mat-radio-button [value]="ProfileType.Entity">
            <svg class="icon svg-icon inline text-2xl" aria-hidden="true">
              <use xlink:href="#icon-Frame"></use>
            </svg>
            Entity</mat-radio-button
          >
        </mat-radio-group></ng-container
      >

      <!-- individual form -->
      <stream-individual-form
        [tenantId]="tenantId"
        *ngIf="profileType === ProfileType.Individual"
      ></stream-individual-form>
      <stream-entity-form [tenantId]="tenantId" *ngIf="profileType === ProfileType.Entity">
      </stream-entity-form>
    </div>

    <ng-template #NAV_PROGRESS>
      <div class="nav flex flex-col">
        <h1 class="mb-3 text-lg font-semibold">Check list</h1>
        <p class="mb-6 text-gray-600">
          Click on the following items to direct to the corresponding area
        </p>
        <ul class="flex-1 space-y-[32px] overflow-auto rounded-lg bg-white px-[30px] py-9">
          <li
            *ngFor="let anchor of anchorManagement.anchorSet; let i = index"
            (click)="
              matDialog.closeAll();
              anchor.elementRef.nativeElement.scrollIntoView({
                behavior: 'smooth'
              })
            "
            class="nav-item"
            [class.checked]="anchor.checked"
          >
            <svg *ngIf="anchor.checked" class="icon self-center text-3xl">
              <use xlink:href="#icon-Steps"></use>
            </svg>

            <div
              *ngIf="!anchor.checked"
              class="inline-block h-[30px] w-[30px] rounded-[50%] border border-solid border-primary-300 text-center text-base leading-[30px]"
              [class.border-primary]="anchor.checked"
            >
              {{ i + 1 }}
            </div>
            <p class="name pl-4">
              {{ anchor.name }}
            </p>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #MIN_PROGRESS>
  <div class="fixed left-0 top-0 z-20 h-screen w-screen bg-[#f1f3f5] px-5">
    <div
      class="flex h-14 flex-row-reverse items-center text-primary-400"
      (click)="matDialog.closeAll()"
    >
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-cuo"></use>
      </svg>
    </div>
    <ng-container *ngTemplateOutlet="NAV_PROGRESS"></ng-container>
  </div>
</ng-template>

<div class="loader-wrap flex items-center" *ngIf="autoSaving | async">
  <span class="loader mr-5"></span>
  <span class="text-primary-500">Saving</span>
</div>
