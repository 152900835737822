<nz-form-control>
  <nz-select
    [nzOptions]="(options$ | async) ?? []"
    nzMode="tags"
    [formControl]="formControl"
    nzPlaceHolder="Select / Input value and press Enter to add"
    [nzTokenSeparators]="[',']"
    [nzMaxTagCount]="5"
  >
  </nz-select>
</nz-form-control>
