import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigOption, FormlyModule } from '@ngx-formly/core';
import { StreamFormlyType } from '@stream/models';
import { NgxFormProgressModule } from '@stream/ngx-utils';
import { QuillModule } from 'ngx-quill';

import { TooltipsModule } from '../tooltips/tooltips.module';
import { HeaderComponent } from './header.component';
import { ParagraphComponent } from './paragraph.component';
import { RichTextComponent } from './rich-text.component';

export const TEXT_FORMLY_CONFIG: ConfigOption = {
  types: [
    {
      name: StreamFormlyType.H,
      component: HeaderComponent,
    },
    {
      name: StreamFormlyType.P,
      component: ParagraphComponent,
    },
    {
      name: StreamFormlyType.RICH_TEXT,
      component: RichTextComponent,
    },
  ],
};
@NgModule({
  declarations: [HeaderComponent, ParagraphComponent, RichTextComponent],
  imports: [
    CommonModule,
    NgxFormProgressModule,
    QuillModule,
    FormlyModule.forChild(TEXT_FORMLY_CONFIG),
    TooltipsModule,
  ],
})
export class TextModule {}
