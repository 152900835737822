<button
  nz-button
  *ngIf="!config.children; else nested"
  [disabled]="disabledItem(config.key)"
  (click)="itemClick.emit(config.key)"
>
  {{ config.name }}
</button>

<ng-template #nested>
  <button
    nz-button
    nz-dropdown
    nzTrigger="click"
    [disabled]="groupDisabled"
    [nzDropdownMenu]="menu"
    nzOverlayClassName="nested-config-overlay"
    (nzVisibleChange)="$event || dropdownHideHandler()"
  >
    {{ config.name }}
    <span nz-icon nzType="down"></span>
  </button>
</ng-template>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <nz-input-group [nzSuffix]="suffixIconSearch" *ngIf="searchable">
    <input nz-input placeholder="Search" [formControl]="searchControl" />
    <ng-template #suffixIconSearch>
      <span nz-icon nzType="search"></span>
    </ng-template>
  </nz-input-group>

  <ul nz-menu class="menu">
    <li
      *ngFor="let group of groups$ | async | keyvalue"
      nz-menu-group
      [nzTitle]="group.key | titlecase"
    >
      <ul>
        <li
          nz-menu-item
          *ngFor="let config of group.value"
          [nzDisabled]="disabledItem(config.key)"
          (click)="itemClick.emit(config.key)"
        >
          {{ config.name }}
        </li>
      </ul>
    </li>
  </ul>
</nz-dropdown-menu>
