import { Injectable } from '@angular/core';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';
import { from, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BrowserFingerService {
  cacheResult$: Observable<GetResult> | null = null;

  get fpResult() {
    if (!this.cacheResult$) {
      // 缓存指纹计算结果
      this.cacheResult$ = this.getFpResult().pipe(shareReplay(1));
    }
    return this.cacheResult$;
  }

  deviceHeaderInfo = this.fpResult.pipe(
    map(
      ({
        components: {
          // 默认值解决firefox无法获取浏览器类型报错
          vendorFlavors: { value: [DeviceBrowserType = ''] = [] },
          platform: { value: DeviceOSType = '' },
        },
        visitorId: DeviceIdentity,
      }) => ({
        DeviceBrowserType,
        DeviceIdentity,
        DeviceOSType,
        'CF-IPCountry': 'CN',
      })
    )
  );

  getFpResult() {
    return from(FingerprintJS.load({})).pipe(switchMap((fp) => from(fp.get())));
  }
}
