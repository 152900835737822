<ngx-form-progress
  [formGroup]="form"
  [loading]="(panelService.loading | async) || (formly.formlyService.loading | async)"
  class="mb-8"
></ngx-form-progress>

<form
  (ngSubmit)="submit()"
  class="flex flex-1 flex-col"
  [formGroup]="form"
  #ngForm="ngForm"
>
  <stream-formly
    [panels]="(panelService.nodeData | async)?.data?.panels"
    [attr.data-at-tag]="
      (panelService.nodeData | async)?.nodeInnerType === 'CUSTOM_FIELD'
        ? 'at-smartforms'
        : ''
    "
    [shareClassObservable]="shareClassObservable"
    [profileObservable]="profileObservable"
    [mandatoryDocsObservable]="mandatoryDocsObservable"
    [uploadMandatoryDoc]="uploadMandatoryDoc"
    [opportunity$]="opportunity$"
    [form]="form"
    [profileId]="(panelService.nodeData | async)?.data?.profileId"
    (formEvent)="onEvent($event)"
    (radioChange)="onChange($event)"
    (validKeyChange)="onValidKeyChange($event)"
    class="flex-1 overflow-auto"
    #formly
  ></stream-formly>

  <ng-template #footer>
    <button
      mat-stroked-button
      color="primary"
      class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none"
      (click)="discard()"
      type="button"
      *ngIf="(panelService.canDiscard | async) || scenarioComponent.isAssist"
    >
      {{ 'Discard' | buttonName | async }}
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none"
      (click)="previous()"
      type="button"
      *ngIf="
        (panelService.getStep() > 0 && panelService.isModifyInvest) ||
        (panelService.canPrevious | async)
      "
      [disabled]="
        (panelService.loading | async) || (formly.formlyService.loading | async)
      "
    >
      {{ 'Previous' | buttonName | async }}
    </button>
    <ng-container *ngIf="!panelService.isModifyInvest">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none"
        *ngFor="let button of (panelService.nodeData | async)?.data?.customButton"
        (click)="customButtonClickHandler(button.type)"
        [disabled]="
          (panelService.loading | async) || (formly.formlyService.loading | async)
        "
      >
        {{ button.name }}
      </button>
    </ng-container>
    <button
      mat-flat-button
      (click)="ngForm.ngSubmit.emit()"
      type="submit"
      color="primary"
      class="!px-auto mat-button-lg flex-1 !py-1 text-base font-semibold md:flex-none md:!px-16"
      [disabled]="
        (panelService.loading | async) || (formly.formlyService.loading | async)
      "
    >
      {{
        (isMandatoryDocNode | async)
          ? (isMandatoryFormValid() | async) || panelService.isModifyInvest
            ? 'Proceed'
            : 'Proceed and Upload Later'
          : (((panelService.isLastNode | async) ? 'Submit' : 'Next') | buttonName | async)
      }}
    </button>
  </ng-template>
  <button type="submit" hidden></button>
</form>
