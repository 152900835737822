import { isMoment, Moment } from "moment-timezone";

/**
 * 清空本地创建的时间的时分秒
 */
function clearLocalTimes(sourceDate: Date | number) {
  const date = new Date(
    typeof sourceDate === "number" ? sourceDate : sourceDate.getTime()
  );
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
}

export function transformTimezone(
  sourceDate: Date | number,
  targetTimeZoneOffset: number = new Date().getTimezoneOffset(),
  sourceTimeZoneOffset: number = new Date().getTimezoneOffset()
) {
  const date = new Date(
    typeof sourceDate === "number" ? sourceDate : sourceDate.getTime()
  );
  date.setMinutes(
    date.getMinutes() + (sourceTimeZoneOffset - targetTimeZoneOffset)
  );
  return date;
}

export function transformToUTC(date: Date | number | Moment) {
  return transformTimezone(
    clearLocalTimes(isMoment(date) ? date.toDate() : date),
    0
  );
}

export function transformFromUTC(date: Date | number | Moment) {
  return transformTimezone(isMoment(date) ? date.toDate() : date, undefined, 0);
}
