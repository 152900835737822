<form [formGroup]="form" (ngSubmit)="submit()" class="flex h-full flex-col">
  <div class="mb-4 !flex items-center md:mb-5" mat-dialog-title>
    <h1 class="flex-1 text-lg font-semibold text-primary-500 md:text-xl">
      {{ data.bank ? 'Edit' : 'Add' }} bank information
    </h1>
    <button mat-icon-button mat-dialog-close class="!hidden md:!inline-block">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    mat-dialog-content
    class="bank-form-content flex-1"
    [style]="{ 'max-height': contentH }"
  >
    <p class="text-sm text-gray-400">
      Account name must be the same as investor’s name.
    </p>
    <mat-form-field>
      <mat-label>Account name</mat-label>
      <input matInput autocomplete="cc-name" formControlName="bankAccountName" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Name of bank</mat-label>
      <input matInput formControlName="bankName" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <div class="flex flex-col items-center space-x-0 sm:flex-row sm:space-x-4">
      <mat-form-field class="w-full flex-1 sm:w-auto">
        <mat-label>Account no.</mat-label>
        <input matInput autocomplete="cc-number" formControlName="bankAccountNumber" />
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="contentView; else notINfo"
        class="w-full flex-none sm-custom"
        nz-popover
        nzPopoverOverlayClassName="max-w-xl"
        nzPopoverPlacement="top"
        [nzPopoverOrigin]="icon._elementRef"
        [nzPopoverContent]="info"
      >
        <mat-label class="flex items-center">
          <span>Bank code</span>
          <mat-icon
            #icon
            color="primary"
            class="ml-2 !text-xl"
            >info_outlined</mat-icon
          >
        </mat-label>
        <ng-template #info>
          <div class="max-h-96 overflow-y-scroll hide-scrollbar">
            <stream-quill-view [content]="contentView"></stream-quill-view>
          </div>
        </ng-template>
        <input matInput autocomplete="cc-csc" formControlName="bankSwiftCode" />
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <ng-template #notINfo>
        <mat-form-field class="w-full flex-none sm-custom">
          <mat-label>Bank code</mat-label>
          <input matInput autocomplete="cc-csc" formControlName="bankSwiftCode" />
          <mat-error ngx-mat-errors></mat-error>
        </mat-form-field>
      </ng-template>
    </div>
    <mat-form-field>
      <mat-label>Bank address</mat-label>
      <input matInput formControlName="bankAddress" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> Correspondent bank name (Optional) </mat-label>
      <input matInput formControlName="correspondentBankName" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> Correspondent bank branch account no. (Optional) </mat-label>
      <input matInput formControlName="correspondentBankBranchAccountNumber" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> Correspondent bank code (Optional) </mat-label>
      <input matInput formControlName="correspondentBankSwiftCode" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <stream-mandatory-doc-item
      *ngIf="!hideBank"
      #mandatoryDocItem
      [form]="mdForm"
      [md]="mdConfig.mandatoryDocs[0]"
      [config]="mdConfig"
    >
      <p class="doc-description text-xs mb-2">
        Please upload a proof of bank account to be used for this investor profile.
      </p>
    </stream-mandatory-doc-item>
  </div>

  <div mat-dialog-actions class="grid grid-cols-2 justify-around md:flex md:justify-end">
    <button
      mat-stroked-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      cdkFocusInitial
      type="submit"
      [disabled]="loading"
    >
      Confirm
    </button>
  </div>
</form>
