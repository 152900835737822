import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ENTITY_TYPE, Profile, Store } from '@stream/models';
import { SharedService } from '@stream/shared';
import { phoneValidatorFn, transformDateToMoment } from '@stream/utils';
import { filter, map, take } from 'rxjs/operators';

import { CountriesService } from '@stream/libs/common/countries';
import { ProfilePanelService } from '../../services/profile-panel.service';

@Component({
  selector: 'stream-entity-info-form',
  templateUrl: './entity-info-form.component.html',
  styleUrls: ['./entity-info-form.component.scss'],
})
export class EntityInfoFormComponent implements OnInit {
  constructor(
    private formGroupDirective: FormGroupDirective,
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    private panelService: ProfilePanelService,
    private countriesService: CountriesService
  ) {}

  areaCodeList = this.countriesService.getCountriesArray();
  store = new Store({ type: 'session', namespace: 'scenario' });

  countryList$ = this.sharedService.countryList.pipe(
    map(res =>
      (res || []).map(item => ({
        label: item.countryName,
        value: item.countryAlpha2Code,
        ...item,
      })),
    ),
  );

  isOther = false;
  entityTypeValue: string | null = '';
  get entityTypeList() {
    return ENTITY_TYPE['OTHER'];
  }

  todayDate = new Date();

  form = this.fb.group({
    legalName: [null, Validators.required],
    contactEmail: [null, [Validators.required, Validators.email]],
    phoneCode: [null, Validators.required],
    phoneNumber: [
      null,
      [
        Validators.required,
        (control: AbstractControl) =>
          phoneValidatorFn(control.parent?.get('phoneCode')?.value, control.value)
            ? null
            : { phone: true },
      ],
    ],
    countryCodeIncorporation: [null, Validators.required],
    incorporationDate: [null, Validators.required],
    natureOfBusiness: [null, Validators.required],
    entityType: [null, Validators.required],
    nationalRegistrationNumber: [null, Validators.required],
  });

  changeValue(e: any): void {
    if (e.value === 'other') {
      this.isOther = true;
      this.form.setControl('entityTypeComment', new FormControl('', Validators.required));
    } else {
      this.isOther = false;
      this.form.removeControl('entityTypeComment');
    }
  }

  ngOnInit(): void {
    const profile = this.store.get('profile');
    this.formGroupDirective.form.addControl('info', this.form);

    if (profile) {
      this.initEntityInfo(profile);
    } else {
      this.panelService.profile
        .pipe(
          filter(v => !!v),
          take(1),
        )
        .subscribe(profile => {
          if (profile) {
            this.initEntityInfo(profile);
          }
        });
    }
  }

  initEntityInfo(profile: Profile) {
    if (
      !profile?.investorProfileMetaEntity?.entityType &&
      !profile?.investorProfileMetaEntity?.entityTypeComment
    ) {
      this.entityTypeValue = null;
    } else if (profile?.investorProfileMetaEntity?.entityTypeComment) {
      this.entityTypeValue = 'other';
      this.isOther = true;
      this.form.setControl(
        'entityTypeComment',
        new FormControl(
          profile?.investorProfileMetaEntity?.entityTypeComment,
          Validators.required,
        ),
      );
    }
    if (profile?.investorProfileMetaEntity?.entityType === 'other') {
      this.entityTypeValue = 'other';
      this.isOther = true;
      this.form.setControl('entityTypeComment', new FormControl('', Validators.required));
    }
    this.form.patchValue(
      {
        ...(profile?.investorProfileMetaEntity ?? {}),
        entityType:
          profile?.investorProfileMetaEntity?.entityType || this.entityTypeValue,
        incorporationDate: transformDateToMoment(
          profile?.investorProfileMetaEntity?.incorporationDate,
        ),
      },
      {
        emitEvent: false,
      },
    );
    if (profile?.investorProfileMetaEntity?.dateOfIncorporation) {
      // 'dateOfIncorporation' is deprecated since version 101 for page, but still need to send value returned by server
      this.form.addControl(
        'dateOfIncorporation',
        new FormControl(profile.investorProfileMetaEntity.dateOfIncorporation),
      );
    }
  }

  getCodeByLabel(str: string = '') {
    const arr = str?.split('+') ?? [];
    return arr[arr.length - 1] ? '+' + arr[arr.length - 1] : '';
  }
}
