import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import {
  FORM_ERROR_MESSAGE,
  FormErrorCodeEnum,
  StreamFormlyConfig,
  StreamFormlyType
} from '@stream/models';
import { QuillViewModule } from '@stream/ngx-utils';
import { TooltipsModule } from '../tooltips/tooltips.module';
import { CheckboxGroupWrapperComponent } from './checkbox-group-wrapper/checkbox-group-wrapper.component';
import { CheckboxComponent } from './checkbox/checkbox.component';

const matModules = [MatFormFieldModule, MatCheckboxModule, MatInputModule];

@NgModule({
  declarations: [CheckboxGroupWrapperComponent, CheckboxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    QuillViewModule,
    ...matModules,
    TooltipsModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'checkbox-group-wrapper',
          component: CheckboxGroupWrapperComponent
        }
      ],
      types: [
        {
          name: StreamFormlyType.CHECKBOX,
          component: CheckboxComponent
        }
      ]
    })
  ]
})
export class CheckboxModule {
  static transformCheckboxGroupField({
    id,
    key,
    name,
    defaultValue,
    asyncValidators,
    validators = '',
    type,
    placeholder,
    required,
    options,
    label,
    ...templateOptions
  }: StreamFormlyConfig): FormlyFieldConfig {
    let parsedDefaultValue = {} as Record<string, any>;
    try {
      parsedDefaultValue = JSON.parse(defaultValue || '{}');
    } catch (err) {
      //
    }
    const config = {
      id,
      key,
      name,
      props: {
        ...templateOptions,
        label: '',
        customLabel: label,
        required,
        customErrorTemplate: true,
        errorMessage: FORM_ERROR_MESSAGE.get(FormErrorCodeEnum.AtLeastOneChecked)
      },
      wrappers: ['checkbox-group-wrapper'],
      validators: {
        ...(required
          ? {
              [FormErrorCodeEnum.AtLeastOneChecked]: {
                expression: (form: UntypedFormGroup) => {
                  return Object.values(form.parent?.value).some(v => v);
                },
                message: FORM_ERROR_MESSAGE.get(FormErrorCodeEnum.AtLeastOneChecked)
              }
            }
          : {})
      },
      fieldGroup: options?.map(({ label, value }) => ({
        type: StreamFormlyType.CHECKBOX,
        key: value + '',
        defaultValue: parsedDefaultValue[value],
        props: {
          label: (label as any)?.en || label
        }
      }))
    };
    return config;
  }
}
