import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EntityMember,
  Individual,
  IndividualType,
  Store,
  policyType
} from '@stream/models';
import { SharedService } from '@stream/shared';
import {
  phoneValidatorFn,
  transformDateToMoment,
  transformDateToStr,
} from '@stream/utils';
import { finalize, map } from 'rxjs/operators';

import { CountriesService } from '@stream/libs/common/countries';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'stream-person-dialog',
  templateUrl: './person-dialog.component.html',
  styleUrls: ['./person-dialog.component.scss'],
})
export class PersonDialogComponent implements OnInit {
  todayDate = new Date();
  tooltipTaxJurisdiction: string = '';

  areaCodeList = this.countriesService.getCountriesArray();
  countryList$ = this.sharedService.countryList.pipe(
    map(res =>
      (res || []).map(item => ({
        ...item,
        label: item.countryName,
        value: item.countryAlpha2Code,
      })),
    ),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<PersonDialogComponent>,
    private profileService: ProfileService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      person?: Individual;
      personSelectList?: Individual[];
      reModify?: boolean;
    },
    private countriesService: CountriesService,
  ) {
    if (data.person) {
      const taxJurisdiction =
        data.person.taxJurisdiction &&
        (JSON.parse(data.person.taxJurisdiction) as string[]);
      if (Array.isArray(taxJurisdiction)) {
        taxJurisdiction.slice(1).forEach(() => {
          this.taxes.push(this.createTaxFormArray());
        });
      }
      this.form.patchValue(
        {
          ...data.person,
          dateOfBirth: data.person?.dateOfBirth || null,
          birthday: transformDateToMoment(data.person?.birthday),
          taxJurisdiction,
        },
        { emitEvent: false },
      );
    }
  }
  ngOnInit(): void {
    this.getTooltipTax();
  }

  getTooltipTax() {
    this.profileService
      .GetPolicyByType(policyType.TAX_JURISDICTION)
      .subscribe(({ data }) => {
        if (data?.gpPolicy) {
          this.tooltipTaxJurisdiction = data?.gpPolicy.policyContext;
        }
      });
  }

  loading = false;
  store = new Store({ type: 'session', namespace: 'scenario' });

  form = this.fb.group({
    firstName: [null, [Validators.maxLength(50), Validators.required]],
    lastName: [null, [Validators.maxLength(50), Validators.required]],
    email: [
      null,
      [
        Validators.email,
        Validators.required,
        (control: FormControl) => {
          const value = control.value as string;
          const emailTaken = this.data.personSelectList
            ?.filter(person => {
              return person?.id !== this.data.person?.id;
            })
            .some(person => {
              return person?.email === value;
            });
          return emailTaken ? { emailTaken: true } : null;
        },
      ],
    ],
    phoneNationalityCode: [null],
    phoneNumber: [
      null,
      (control: AbstractControl) => {
        const code = control.parent?.get('phoneNationalityCode')?.value;
        if (!code && !control.value) return null;
        return phoneValidatorFn(code, control.value || '') ? null : { phone: true };
      },
    ],
    nationalityCode: [null, Validators.required],
    countryCodeOfResidence: [null, Validators.required],
    birthday: [null, Validators.required],
    /**
     * @deprecated 'dateOfBirth' is deprecated since version 101 for page, but still need to send to server when it's not null
     */
    dateOfBirth: [null],
    countryCodeOfBirth: [null, Validators.required],
    jobPositionTitle: [null, Validators.required],
    townOfBirth: [null, Validators.required],
    taxJurisdiction: this.fb.array([this.createTaxFormArray()]),
  });

  get timezone() {
    return this.profileService.investAccount$.getValue().timeZone || 0;
  }

  get taxes() {
    return this.form.get('taxJurisdiction') as FormArray;
  }

  createTaxFormArray() {
    return this.fb.array([
      [null, Validators.required],
      null,
      [null, Validators.required],
    ]);
  }
  getCodeByLabel(str: string = '') {
    const arr = str?.split('+') ?? [];
    return arr[arr.length - 1] ? '+' + arr[arr.length - 1] : '';
  }

  savePerson() {
    if (this.form.invalid) return;

    const profile = this.store.get('profile');

    this.loading = true;
    const value = { ...this.form.value };
    if (!value.dateOfBirth) {
      Reflect.deleteProperty(value, 'dateOfBirth');
    }

    const params = {
      ...value,
      birthday: transformDateToStr(value['birthday']),
      taxJurisdiction: JSON.stringify(value.taxJurisdiction),
    };
    const id = this.data.person?.id;

    if (this.data.reModify && profile) {
      const { investorProfileMetaInfoMemberList: memberList = [] } = profile;
      profile.investorProfileMetaInfoMemberList = memberList.map(
        (member: EntityMember) => {
          const { referenceInvestorMetaIndividualEntity: person } = member;

          member.referenceInvestorMetaIndividualEntity =
            person.id === id ? ({ ...person, ...params } as Individual) : person;

          return member;
        },
      );

      this.store.set('profile', profile);
      this.dialogRef.close(true);
    } else {
      this.profileService
        .patchIndividual(params, {
          id,
          individualType: IndividualType.Member,
        })
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    }
  }
  get contentH(): string {
    return `calc(${window?.innerHeight - 161}px - 20vh)`;
  }
}
