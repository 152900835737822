<div class="title">
  <div class="tag-wrapper flex flex-row items-center">
    <span class="tag">{{ profile.profileType | lowercase }}</span>
    <span class="tag entity-type" *ngIf="profile.structureType">{{
      getStructureTypeString(profile.structureType)
    }}</span>
    <span class="tag custodian-type" *ngIf="isCustodian"> Custodian </span>
    <svg
      *ngIf="isCustodian && isDisableIRA; else CustodianNotIRA"
      class="icon ml-2 text-base text-[#272636]"
      [matTooltip]="
        'Investments using a Custodian Profile is not supported. Please contact your support team: support@biteinvestments.com'
      "
    >
      <use xlink:href="#icon-information"></use>
    </svg>
    <ng-template #CustodianNotIRA>
      <svg
        *ngIf="isCustodian && isUnsupportCustodian"
        class="icon ml-2 text-base text-[#272636]"
        [matTooltip]="
          'This fund does not support investments via a custodian. Please use another investor profile.'
        "
      >
        <use xlink:href="#icon-information"></use>
      </svg>
    </ng-template>
  </div>
  <div class="flex flex-row">
    <div
      [ngClass]="{
        'hover:cursor-not-allowed': isCustodian && isDisableIRA
      }"
    >
      <button
        [ngClass]="{
          'pointer-events-none': isCustodian && isDisableIRA
        }"
        mat-icon-button
        (click)="edit.emit(profile)"
      >
        <svg
          class="mx-auto h-6 w-6 cursor-pointer fill-current text-[#62656C]"
          [ngStyle]="{
            color: isCustodian && isDisableIRA ? '#CED4DA' : '#62656C'
          }"
        >
          <use xlink:href="#icon-Edit"></use>
        </svg>
      </button>
    </div>
    <div
      *ngIf="!reModify"
      [ngClass]="{
        'hover:cursor-not-allowed': isLinkCustodian
      }"
      [matTooltip]="
        isLinkCustodian
          ? 'We\'re sorry, but you currently can\'t delete your Custodian Profile.'
          : ''
      "
    >
      <button
        [ngClass]="{
          'pointer-events-none': isLinkCustodian
        }"
        mat-icon-button
        (click)="openDeleteDialog()"
      >
        <svg
          class="mx-auto h-6 w-6 cursor-pointer fill-current"
          [ngStyle]="{
            color: isLinkCustodian ? '#CED4DA' : '#62656C'
          }"
        >
          <use xlink:href="#icon-a-shanchu1"></use>
        </svg>
      </button>
    </div>
  </div>
</div>
<div class="content">
  <ng-container *ngIf="isIndividual; else entityContent">
    <div class="content-item">
      <div
        class="text"
        [matTooltip]="
          (profile.investorProfileMetaIndividual?.firstName ?? '') +
          ' ' +
          (profile.investorProfileMetaIndividual?.lastName ?? '')
        "
        [matTooltipShowDelay]="3000"
      >
        {{ profile.investorProfileMetaIndividual?.firstName }}
        {{ profile.investorProfileMetaIndividual?.lastName }}
      </div>
      <label class="label">Full name</label>
    </div>
    <div class="content-item">
      <div
        class="text"
        [matTooltip]="profile.investorProfileMetaIndividual?.email ?? ''"
        [matTooltipShowDelay]="3000"
      >
        {{ profile.investorProfileMetaIndividual?.email }}
      </div>
      <label class="label">Email</label>
    </div>
    <div class="content-item">
      <div
        class="text"
        [matTooltip]="
          profile.investorProfileMetaIndividual?.phoneNationalityCode ? 
          '+' + (profile.investorProfileMetaIndividual?.phoneNationalityCode | countries : 'dialCode') : '' +
          ' ' +
          (profile.investorProfileMetaIndividual?.phoneNumber ?? '')
        "
        [matTooltipShowDelay]="3000"
      >
        {{ profile.investorProfileMetaIndividual?.phoneNationalityCode ? '+' + (profile.investorProfileMetaIndividual?.phoneNationalityCode | countries: 'dialCode') : '' }}
        {{ profile.investorProfileMetaIndividual?.phoneNumber }}
      </div>
      <label class="label">Phone number</label>
    </div>
  </ng-container>

  <ng-template #entityContent>
    <div class="content-item">
      <div
        class="text"
        [matTooltip]="profile.investorProfileMetaEntity?.legalName ?? ''"
        [matTooltipShowDelay]="3000"
      >
        {{ profile.investorProfileMetaEntity?.legalName }}
      </div>
      <label class="label">Entity name</label>
    </div>
    <div class="content-item">
      <div
        class="text"
        [matTooltip]="profile.investorProfileMetaEntity?.contactEmail ?? ''"
        [matTooltipShowDelay]="3000"
      >
        {{ profile.investorProfileMetaEntity?.contactEmail }}
      </div>
      <label class="label">Contact email</label>
    </div>
    <div class="content-item">
      <div
        class="text"
        [matTooltip]="
          (profile.investorProfileMetaEntity?.phoneCode
            ? '+' + (profile.investorProfileMetaEntity?.phoneCode | countries: 'dialCode')
            : '') +
          ' ' +
          (profile.investorProfileMetaEntity?.phoneNumber ?? '')
        "
        [matTooltipShowDelay]="3000"
      >
        {{
          profile.investorProfileMetaEntity?.phoneCode
            ? '+' + (profile.investorProfileMetaEntity?.phoneCode | countries: 'dialCode')
            : ''
        }}
        {{ profile.investorProfileMetaEntity?.phoneNumber }}
      </div>
      <label class="label">Phone number</label>
    </div>
  </ng-template>
</div>

<div
  *ngIf="isCustodian; else Everyman"
  class="footer"
  style="grid-template-columns: 1fr 1fr; column-gap: 1rem"
>
  <div class="start-container">
    <label class="label footer-text" [matTooltip]="'Custodian'" [matTooltipShowDelay]="3000">
      Custodian
    </label>
    <div
      *ngIf="profile.investorProfileMtCustodianData"
      class="bank-number footer-text"
      [matTooltip]="'Inspira Financial'"
      [matTooltipShowDelay]="3000"
    >
      Inspira Financial
    </div>
  </div>
  <div class="end-container flex flex-row items-center">
    <svg
      *ngIf="isAccountStatusError"
      class="icon mb-[2px] ml-2 mr-[-0.5rem] text-base text-[#272636]"
      [matTooltip]="
        'Unfortunately you are unable to invest using your Millennium Trust IRA due to account status. Please get in touch with Millennium Trust by sending an email to itpartnersupport@mtrustcompany.com.'
      "
    >
      <use xlink:href="#icon-information"></use>
    </svg>
    <label class="label footer-text" [matTooltip]="'Account status'" [matTooltipShowDelay]="3000">
      Account status
    </label>
    <div
      *ngIf="profile.investorProfileMtCustodianData?.accountStatus"
      class="bank-number footer-text"
      [matTooltip]="profile.investorProfileMtCustodianData?.accountStatus || ''"
      [matTooltipShowDelay]="3000"
    >
      {{ profile.investorProfileMtCustodianData?.accountStatus || '' }}
    </div>
  </div>
  <div class="start-container">
    <label class="label footer-text" [matTooltip]="'IRA type'" [matTooltipShowDelay]="3000">
      IRA type
    </label>
    <div
      *ngIf="labelAccountType"
      class="bank-number footer-text"
      [matTooltip]="labelAccountType"
      [matTooltipShowDelay]="3000"
    >
      <span class="container-tag tag-ira-type">{{ labelAccountType }}</span>
    </div>
  </div>
</div>

<ng-template #Everyman>
  <div class="footer">
    <div class="between-container">
      <label
        class="label footer-text"
        [matTooltip]="profile.investorProfileMetaInfoBank?.bankName ?? 'Bank'"
        [matTooltipShowDelay]="3000"
      >
        {{ profile.investorProfileMetaInfoBank?.bankName ?? 'Bank' }}
      </label>
      <div class="bank-number footer-text" [matTooltip]="bankNumber" [matTooltipShowDelay]="3000">
        {{ bankNumber }}
      </div>
    </div>
    <div class="between-container">
      <label class="label">Registered address</label>
      <div
        *ngIf="address"
        class="address footer-text"
        [matTooltip]="
          (address.addressLineOne || '') +
          ' ' +
          (address.addressLineTwo || '') +
          ', ' +
          (address.addressTown || '') +
          ' ' +
          (address.addressState || '') +
          ' ' +
          (translateCountryName(address.addressCountryCode) || '') +
          ', ' +
          (address.zipCode || '')
        "
        [matTooltipShowDelay]="3000"
      >
        <strong> {{ address.addressLineOne }} {{ address.addressLineTwo }}, </strong>
        {{ address.addressTown }} {{ address.addressState }}
        {{ translateCountryName(address.addressCountryCode) }},
        {{ address.zipCode }}
      </div>
    </div>
  </div>
</ng-template>

<div class="progress-bar-wrapper">
  <mat-progress-bar
    [value]="(progress$ | async) || 0"
    class="progress-bar"
    [class]="(progress$ | async) === 100 ? 'finished' : ''"
  >
  </mat-progress-bar>
  <span class="progress-bar-text">{{ (progress$ | async)?.toFixed(0) || 0 }}%</span>
  <span *ngIf="(progress$ | async) === 100" class="complete absolute right-0 top-0">Complete</span>
</div>

<ng-template #deleteProfileDialog>
  <header mat-dialog-title class="!flex items-center">
    <h1 class="flex-1 text-neutral-900 md:text-[22px]">Delete</h1>
    <button mat-icon-button mat-dialog-close type="button">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <p mat-dialog-content class="text-base text-neutral-500">Are you sure to delete this profile?</p>
  <div class="flex items-center justify-end gap-3" mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close class="flex-1 md:w-40 md:flex-none">
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      class="flex-1 md:w-40 md:flex-none"
    >
      Confirm
    </button>
  </div>
</ng-template>
