import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { StreamFormlyModule } from '@stream/formly';
import {
    NgxCodeInputModule,
    NgxFormProgressModule,
    QuillViewModule,
} from '@stream/ngx-utils';
import { CodeInputModule } from 'angular-code-input';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMatErrorsModule } from 'ngx-mat-errors';

import { MandatoryDocModule } from '../mandatory-doc';
import { ProfileSharedModule } from '../profiles/profile-shared.module';
import {
    CustodianComponent,
    GatewayChangedDialogComponent,
    GatewayResultComponent,
    QuestionnaireComponent,
    ScenarioComponent,
    ScenarioDialogComponent,
    ScenarioFormComponent,
    SealSelectDialogComponent,
    SignComponent,
    SignPdfComponent,
    SignVerifyDialogComponent,
    StartComponent,
    StatementComponent,
    WitnessFormComponent,
} from './components';
import { ReInvestProcessComponent } from './components/re-invest-process/re-invest-process.component';
import { ReInvestRequiredComponent } from './components/re-invest-required/re-invest-required.component';
import { RefreshRequiredComponent } from './components/refresh-required/refresh-required.component';
import { SaveAndExitConfirmDialogComponent } from './components/scenario-form/save-and-exit-confirm-dialog.component';
import { NodeButtonNamePipe, QuestionFormlyPipe } from './pipes';
import { SCENARIO_CONFIG, ScenarioConfig } from './scenario.type';

const matModules = [
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatRippleModule,
  MatProgressBarModule,
  MatInputModule,
  MatDialogModule,
  MatRadioModule,
  PortalModule,
  MatSelectModule,
  MatSnackBarModule,
  NgxMatErrorsModule,
];
@NgModule({
  declarations: [
    ScenarioComponent,
    QuestionFormlyPipe,
    StartComponent,
    ScenarioFormComponent,
    StatementComponent,
    QuestionnaireComponent,
    GatewayResultComponent,
    SignComponent,
    SignPdfComponent,
    SignVerifyDialogComponent,
    SealSelectDialogComponent,
    NodeButtonNamePipe,
    SaveAndExitConfirmDialogComponent,
    GatewayChangedDialogComponent,
    WitnessFormComponent,
    ReInvestRequiredComponent,
    RefreshRequiredComponent,
    ReInvestProcessComponent,
    ScenarioDialogComponent,
    CustodianComponent,
  ],
  exports: [ScenarioComponent, RefreshRequiredComponent, ReInvestProcessComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule,
    ...matModules,
    StreamFormlyModule.forRoot(),
    QuillViewModule,
    NgxFormProgressModule,
    CodeInputModule,
    NgxCodeInputModule,
    RouterModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    MandatoryDocModule,
    ProfileSharedModule,
  ],
})
export class ScenarioModule {
  static injector: Injector;
  static forRoot(configs: ScenarioConfig): ModuleWithProviders<ScenarioModule> {
    return {
      ngModule: ScenarioModule,
      providers: [
        {
          provide: SCENARIO_CONFIG,
          useValue: configs,
        },
      ],
    };
  }

  constructor(injector: Injector) {
    ScenarioModule.injector = injector;
  }
}
