export enum StreamPanelType {
  Static = 'STATIC',
  Dynamic = 'DYNAMIC',
}

// 这里的key是真实json数据里的类型 value用于前端配置读取
export enum StreamFormlyType {
  // 标题
  H = 'h',
  // 文本
  P = 'p',
  // 输入框
  INPUT = 'input',
  // 选择框
  SELECT = 'select',
  // onboarding radio(样式不同)
  BITE_RADIO_GROUP = 'radio',
  // 复选框
  CHECKBOX = 'checkbox',
  // 手机号 包含区号和手机号输入框
  PHONE = 'phone',
  // onboarding提交按钮
  BUTTON_SUBMIT = 'submit-button',
  // 复选框组 用于问卷多选
  CHECKBOX_GROUP = 'checkbox-group',
  // 富文本展示
  RICH_TEXT = 'rich-text',
  // 表格 用于问卷表格(单选/复选)
  TABLE = 'table',
  // 金额输入 包含shareClass/货币/金额输入 以及总金额计算
  AMOUNT = 'amount',
  // profile卡片单选
  PROFILE = 'profile',
  // 自动完成 目前用于国家区号搜索选中
  AUTOCOMPLETE = 'autocomplete',
  // 区号选择
  AREA_CODE = 'area-code',
  LABEL = 'label',
  MANDATORYDOCS = 'MANDATORYDOCS',
}

export enum TableCellType {
  Radio = 'radio',
  Checkbox = 'checkbox',
}

export interface StreamFormlyConfig {
  name?: string;
  hidden?: boolean;
  readonly?: boolean;
  step?: number;
  tabindex?: number;
  tooltip?: string;

  // 主要属性
  /** 🆔 */
  id: string;
  type: keyof typeof StreamFormlyType;
  /** 键名 */
  key: string;

  // 布局相关
  cols?: number;
  rows?: number;

  streamCustomfield: string;
  streamCustomfieldBinding?: string;
  triggerInConditions?: string;
  triggerInConditionsShow?: string;
  formDisplayConditionForFrontShow?: string;
  /** 显示隐藏表达式 */
  formDisplayCondition?: Record<string, string>;
  /** 显隐表达式结果表示显示|隐藏 */
  formDisplayConditionIsShow?: boolean;

  // templateOption
  label?: string;
  /** 描述 */
  description?: string;
  /** 默认填充值 */
  defaultValue?: string;
  /** select/radio 选项 */
  options?: { value: string; label: string; disable?: boolean }[];
  /** layout 选项 */
  layout?: string;
  styles: Record<string, string | number>;

  placeholder?: Record<string, string>;

  disabled?: boolean;

  // 校验相关
  asyncValidators?: string;
  validators?: string;

  required?: boolean;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
}

type FormCondition = {
  formValue: string;
  formKey: string;
  type: string;
  value: boolean;
};

export interface StreamFormlyPanelConfig {
  forms: StreamFormlyConfig[];
  orderIndex?: number;
  panelDisplayConditionForFrontShow?: string;
  panelDisplayCondition: { or?: FormCondition[]; and?: FormCondition[] };
  panelDisplayConditionIsShow?: boolean;
  triggerInConditions?: string;
  triggerInConditionsShow?: string;
  panelKey: string;
  panelTitle?: string;
  panelType: StreamPanelType;
}
