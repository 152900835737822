import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UploadService } from '@stream/libs/common/models/src/services/upload.service';
import { ToastService } from '@stream/libs/common/ngx-utils/services/toast';
import { DataRoomDocument, DataRoomDocumentType } from '@stream/models';
import { Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

export interface UploadRes {
  documentToken: string;
  type: DataRoomDocumentType;
  name: string;
  url: string;
}

@Component({
  selector: 'stream-upload-private-file',
  templateUrl: './upload-private-file.component.html',
  styleUrls: ['./upload-private-file.component.scss']
})
export class UploadPrivateFileComponent implements OnDestroy {
  constructor(
    private toast: ToastService,
    private uploadService: UploadService
  ) {}
  _document: { url: string; name: string; type: DataRoomDocumentType } | null = null;

  loading = false;

  uuid?: string;

  @Input()
  title = 'Upload';

  @Input()
  fileName?: string;

  @Input()
  placeholder = 'Allowed file formats pdf, jpeg, png / max 30mb';

  @Input()
  showResult = true;

  @Input()
  multiple = false;

  @Input()
  control: AbstractControl | null = null;

  @Input()
  maxSize = 30 * 1024 * 1024;

  @Input()
  accept?: string;

  @Input()
  set document(document: DataRoomDocument | undefined) {
    if (document) {
      const { name, url, type, documentToken } = document;
      this._document = {
        name,
        url,
        type
      };
      if (this.control) {
        this.control.setValue(documentToken);
      }
    }
  }

  @Output()
  uploaded = new EventEmitter<UploadRes>();

  sub?: Subscription;

  @HostListener('dragover', ['$event']) public onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer?.files;
    if (files) {
      if (this.multiple) {
        Array.from(files).forEach(file => {
          this.beforeUpload(file);
        });
      } else {
        this.beforeUpload(files[0]);
      }
    }
  }

  onInput(e: Event) {
    const target = e.target as HTMLInputElement;
    const fileList = target.files;
    if (fileList) {
      Array.from(fileList).forEach(file => {
        this.beforeUpload(file);
      });
    }

    target.value = '';
  }

  private beforeUpload(file: File) {
    if (this.accept) {
      if (!this.accept.split(',').includes(file.type)) {
        this.toast.common.warning({
          msg: 'Upload Blocked: File format is not valid.'
        });
        return;
      }
    }
    if (file.size > this.maxSize) {
      this.toast.common.warning({
        msg: `Upload Blocked: Max size for file is ${this.maxSize / 1024 / 1024} MB. `
      });
      return;
    }
    if (this.fileName) {
      const blob = file.slice(0, file.size, file.type);
      file = new File([blob], this.fileName + '.' + file.name.split('.').pop(), {
        type: file.type
      });
    }
    this.upload(file);
  }

  private upload(file: File) {
    this.loading = true;
    this.uuid = this.uploadService.startScan();
    const formData = new FormData();
    formData.append('file', file);
    this.sub = this.uploadService
      .uploadFile(formData, this.uuid)
      .pipe(
        switchMap(({ blobName }) => {
          return this.uploadService.checkUploadResult({
            blobName,
            uuid: this.uuid!
          });
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe((data: any) => {
        if (data?.scanResult === 'NO_THREATS_FOUND') {
          const { documentToken = '', downloadUrl = '', name = '', type } = data;
          this.uploaded.emit({
            url: downloadUrl,
            name,
            type: type as DataRoomDocumentType,
            documentToken
          });
          this.control?.setValue(documentToken);
          this._document = {
            url: downloadUrl,
            name,
            type: type as DataRoomDocumentType
          };
        }
      });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    if (this.uuid) {
      this.toast.common.clear();
    }
  }
}
