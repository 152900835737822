<div class="mb-4 !flex items-center md:mb-5" mat-dialog-title>
  <h1 class="flex-1 text-lg font-semibold text-primary-500 md:text-xl">
    Verify your email with security code
  </h1>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="!flex max-w-xl flex-col gap-y-3 md:gap-y-6">
  <pre class="whitespace-normal font-sans text-sm text-primary-400 md:text-base">
For security purpose, a 6-digit code has been sent to {{
      data.email
    }} , please enter the code to complete the email verification step.
Please also note that the e-signing session will expire in 2 hours, remember to complete necessary actions before session expires.
</pre
  >
  <p class="text-sm text-primary-300 md:text-base">
    Can't find your code? Check your spam folder, or you can
    <span *ngIf="delay | async; else resendButton">
      (<span class="text-primary-500">{{ delay | async }}s</span>)
    </span>
    <ng-template #resendButton>
      <a (click)="resend()" class="cursor-pointer text-primary-500 pl-1 pr-1"> click here</a>
    </ng-template>
    to resend
  </p>

  <div>
    <code-input
      *ngIf="isMedium | async"
      [codeLength]="6"
      (codeChanged)="code.setValue($event)"
      class="code-input"
      inputType="number"
      [ngClass]="{
        error: code.hasError('wrong')
      }"
      (codeCompleted)="onCompleted($event)"
      (keydown.enter)="onEnter()"
    ></code-input>
    <ngx-code-input
      *ngIf="(isMedium | async) === false"
      [codeLength]="6"
      (codeChanged)="code.setValue($event)"
      class="ngx-code-input"
      inputType="number"
      [ngClass]="{
        error: code.hasError('wrong') || code.hasError('required')
      }"
      (codeCompleted)="onCompleted($event)"
      (keydown.enter)="onEnter()"
    ></ngx-code-input>
    <mat-error *ngIf="code.hasError('wrong')"> Invalid code </mat-error>
    <mat-error *ngIf="code.hasError('required')"> The code is required </mat-error>
  </div>
</div>
<footer mat-dialog-actions class="flex justify-end">
  <button
    class="confirm_button"
    mat-flat-button
    color="primary"
    (click)="verify()"
    [disabled]="loading"
  >
    Verify
  </button>
</footer>
