import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

import { NgxSelectCountryComponent } from './ngx-select-country.component';

@NgModule({
  declarations: [NgxSelectCountryComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    ScrollingModule,
  ],
  exports: [NgxSelectCountryComponent],
})
export class NgxSelectCountryModule {}
