import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MandatoryDocFile, ProfileMandatoryDocument, Tag, TagCountry } from '@stream/models';
import { getIconByType } from '@stream/utils';
import { Subscription } from 'rxjs';
import { debounceTime, finalize, map } from 'rxjs/operators';

import { MandatoryDocItem, MandatoryDocTagOption } from '../mandatory-doc.interface';
import { MandatoryDocService } from '../mandatory-doc.service';

@Component({
  selector: 'stream-mandatory-doc-reuse',
  templateUrl: './mandatory-doc-reuse.component.html',
  styleUrls: ['./mandatory-doc-reuse.component.scss']
})
export class MandatoryDocReuseComponent implements OnInit, OnDestroy {
  constructor(private fb: UntypedFormBuilder, public mdDocService: MandatoryDocService) {}

  readonly UNTAGGED_OPTION_VALUE = -1;

  @Input()
  mandatory!: MandatoryDocItem;

  @Input()
  profileId!: string;

  @Input()
  tags: MandatoryDocTagOption[] = [];

  @Output()
  selectChange = new EventEmitter<{
    file: MandatoryDocFile;
    tag: Tag;
    country?: TagCountry;
    index: number;
  }>();

  form = this.fb.group({
    tag: [''],
    keyword: []
  });

  documents: Array<ProfileMandatoryDocument & { disabled?: boolean }> = [];

  valueChangeSub = new Subscription();

  loading = false;

  getIconByType = getIconByType;

  tagCountMap = new Map<string, number>();

  getDocumentTagLabel = (v: ProfileMandatoryDocument) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  ngOnInit(): void {
    this.valueChangeSub = this.form.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe(() => {
        this.onSearch();
      });
    // by default, select the untagged option.
    this.form.patchValue({
      tag: this.UNTAGGED_OPTION_VALUE
    });
    this.getTagsStatistics(this.tags.map(item => item.name));
  }

  ngOnDestroy(): void {
    this.valueChangeSub.unsubscribe();
  }

  onSearch() {
    this.loading = true;
    const { tag, keyword } = this.form.value;
    if (!tag || !this.profileId) {
      this.documents = [];
      this.loading = false;
      return;
    }
    let tagOption: MandatoryDocTagOption | undefined;
    const params: {
      profileId: string;
      tagName?: string;
      keyword?: string;
      investorId?: string;
      untagged?: true;
    } = {
      profileId: this.profileId,
      keyword: keyword ?? ''
    };
    if (this.mandatory.owner?.id) {
      params.investorId = this.mandatory.owner.id;
    }
    if (tag === this.UNTAGGED_OPTION_VALUE) {
      params.untagged = true;
    } else {
      tagOption = this.tags.find(item => item.id === tag);
      params.tagName = tagOption?.name ?? '';
    }
    this.mdDocService
      .getDocByTagOrProfile(params)
      .pipe(
        finalize(() => (this.loading = false)),
        map(res => res.data || [])
      )
      .subscribe(data => {
        this.documents = params.untagged ? data : data.map((item) => {
          const countryNotMatched = !item.country?.id ? false : !(tagOption?.countryAlpha2Codes || []).includes(item.country?.id)
          return {
            ...item,
            disabled: countryNotMatched
          }
        })
      });
  }

  onSelect(doc: ProfileMandatoryDocument & { disabled?: boolean }) {
    if (doc.disabled) {
      return;
    }
    const { index } = this.mandatory;
    const {
      id,
      tag,
      country,
      downloadUrl,
      fileType: type,
      fileName: name,
      fileSize: size,
      fileUrl: url,
      documentToken
    } = doc;
    this.selectChange.emit({
      file: {
        type,
        name,
        size,
        url,
        downloadUrl,
        id,
        documentToken
      },
      tag,
      country,
      index
    });
  }

  getTagsStatistics(tags: string[]) {
    if (!this.profileId) {
      return;
    }
    this.mdDocService.getTagsStatistics({
      profileId: this.profileId,
      tagName: ['Untagged', ...tags]
    }).subscribe((res) => {
      res.data.forEach(item => {
        this.tagCountMap.set(item.name, item.count);
      });
    })
  }

  getTagCount(name: string) {
    return this.tagCountMap.get(name) ?? 0;
  }
}
