import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NotificationService } from '@stream/ngx-utils';
import { cloneDeep } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  NzIconRenderTemplate,
  NzShowUploadList,
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadListType,
  NzUploadTransformFileType,
  NzUploadXHRArgs,
  UploadFilter,
} from 'ng-zorro-antd/upload';
import { Observable, Subject, Subscription } from 'rxjs';
import { UTILS_CONFIG, UtilsConfig } from '../../utils.type';

@Component({
  selector: 'stream-verify-upload',
  templateUrl: './verify-upload.component.html',
  styleUrls: ['./verify-upload.component.less'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: VerifyUploadComponent,
    },
  ],
})
export class VerifyUploadComponent
  implements OnDestroy, DoCheck, MatFormFieldControl<any>, ControlValueAccessor
{
  constructor(
    private http: HttpClient,
    @Inject(UTILS_CONFIG)
    public config: UtilsConfig,
    @Optional()
    @Self()
    public ngControl: NgControl,
    @Optional()
    private _parentForm: NgForm,
    @Optional()
    private _parentFormGroup: FormGroupDirective,
    public notification: NotificationService,
    public nzMessageService: NzMessageService,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  // 业务功能使用
  @Input()
  source: 'GP' | 'LP' | '' = '';

  @Input()
  uploadType?: 'btnPrimary' | 'btnDefault';

  @Input()
  uploadTextNormal?: string = 'Upload file';

  @Input()
  uploadTextUploading?: string = 'Uploading...';

  @Input()
  uploadTextScanning?: string = 'Scanning...';

  @Input()
  nzActionScanning?: string = '';

  @Input()
  nzActionUploading?: string = '';

  @Input()
  paramsUpload?: { [key: string]: string } = {};

  get sourceReal() {
    return this.source || this.config.source;
  }

  get colorPrimary() {
    return this.sourceReal === 'GP' ? '#2E4B9C' : '#101213';
  }

  _uploadState: 'normal' | 'uploading' | 'scanning' = 'normal';

  set uploadState(value: 'normal' | 'uploading' | 'scanning') {
    this._uploadState = value;
    this.uploadStateChange.emit(value);
  }

  get uploadState() {
    return this._uploadState;
  }

  paramsUploadSnapshot?: { [key: string]: string } = {}; // 存储点击上传那一刻的参数状态

  @Output()
  uploadStateChange: EventEmitter<string> = new EventEmitter();

  // 透传nz-upload属性 https://ng.ant.design/version/13.4.x/components/upload/zh#api

  @Input()
  nzAccept?: string | string[] = '';
  @Input()
  nzAction?: string | ((file: NzUploadFile) => string | Observable<string>);
  @Input()
  nzDirectory: boolean = false;
  @Input()
  nzBeforeUpload?: (
    file: NzUploadFile,
    fileList: NzUploadFile[],
  ) => boolean | Observable<boolean>;
  @Input()
  nzCustomRequest?: (item: NzUploadXHRArgs) => Subscription;
  @Input()
  nzData?: {} | ((file: NzUploadFile) => {} | Observable<{}>);
  @Input()
  nzDisabled: boolean = false;
  @Input()
  nzFileList: NzUploadFile[] = [];
  @Input()
  nzLimit: number = 0;
  @Input()
  nzSize: number = 0;
  @Input()
  nzFileType?: string;
  @Input()
  nzFilter: UploadFilter[] = [];
  @Input()
  nzHeaders?: {} | ((file: NzUploadFile) => {} | Observable<{}>);
  @Input()
  nzListType: NzUploadListType = 'text';
  @Input()
  nzMultiple: boolean = false;
  @Input()
  nzName: string = 'file';
  @Input()
  nzShowUploadList: boolean | NzShowUploadList = true;
  @Input()
  nzShowButton: boolean = true;
  @Input()
  nzWithCredentials: boolean = false;
  @Input()
  nzOpenFileDialogOnClick: boolean = true;
  @Input()
  nzPreview?: (file: NzUploadFile) => void;
  @Input()
  nzPreviewFile?: (file: NzUploadFile) => Observable<string>;
  @Input()
  nzRemove?: (file: NzUploadFile) => boolean | Observable<boolean>;
  @Output()
  nzChange: EventEmitter<NzUploadChangeParam> = new EventEmitter();
  @Input()
  nzDownload?: (file: NzUploadFile) => void;
  @Input()
  nzTransformFile?: (file: NzUploadFile) => NzUploadTransformFileType;
  @Input()
  nzIconRender: NzIconRenderTemplate | null = null;
  @Input()
  nzFileListRender: TemplateRef<{
    $implicit: NzUploadFile[];
  }> | null = null;

  // 表单属性使用

  static nextId = 0;
  private _required = false;
  private _disabled = false;
  private _touched = false;
  focused: boolean = false;
  public cacheGetMap = new Map();
  public isContentFocus: boolean = false;
  public errorState: boolean = false;
  public _onChange: any = () => null;
  public _onTouch: any = () => null;

  @HostBinding()
  id = `stream-message-input-${VerifyUploadComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  stateChanges = new Subject<void>();
  controlType?: string | undefined;
  autofilled?: boolean | undefined;
  userAriaDescribedBy?: string | undefined;

  setDescribedByIds(ids: string[]): void {
    // implement this
  }

  onContainerClick(event: MouseEvent): void {
    // implement this
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  placeholder: string = 'Please input';

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  get value() {
    // TODO: 这里需要根据实际情况判断
    // return {
    //   contentValue: this.valueContent,
    //   fileValue: this.valueFile,
    // };
    return {};
  }

  get empty() {
    // TODO: 这里需要根据实际情况判断
    // return this.valueContent === '' && this.valueFile.length === 0;
    return false;
  }

  public updateErrorState() {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState =
      (this.ngControl?.invalid || this.ngControl.invalid) &&
      (this._touched || parent.submitted);

    if (oldState !== newState) {
      this.errorState = newState as boolean;
      this.stateChanges.next();
    }
  }

  onFocusIn(_event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(_event: FocusEvent) {
    this._touched = true;
    this.focused = false;
    this._onTouch();
    this.stateChanges.next();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.ngControl.control?.updateValueAndValidity();
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  writeValue(obj: any): void {
    // console.log('writeValue', obj);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // implement this
  }

  notificationError(message: string): void {
    if (this.sourceReal === 'GP') {
      this.nzMessageService.error(message);
    } else {
      this.notification.error(message);
    }
  }

  scanUpload(event: NzUploadChangeParam): void {
    if (event.file.status === 'uploading') {
      this.paramsUploadSnapshot = cloneDeep(this.paramsUpload);
      this.uploadState = 'scanning';
    } else if (event.file.status === 'done') {
      const { data } = event.file.response || {};
      const {
        blobName,
        scanResult, // 'NO_THREATS_FOUND' | 'MALICIOUS' | 'SCANNING'
      } = data || {};

      if (scanResult === 'NO_THREATS_FOUND') {
        this.uploadState = 'uploading';
        let nzActionUploadingReal = this.nzActionUploading || '';
        for (let key in this.paramsUploadSnapshot) {
          nzActionUploadingReal = nzActionUploadingReal?.replace(
            `:${key}`,
            this.paramsUploadSnapshot[key] || '',
          );
        }
        nzActionUploadingReal = nzActionUploadingReal?.replace(`:blobName`, blobName);
        this.http.post(nzActionUploadingReal, {}).subscribe(
          (res: any) => {
            if (res.data?.status !== 'success') {
              event.file.status = 'error';
            }
            this.uploadState = 'normal';
            this.nzChange.emit(event);
          },
          () => {
            event.file.status = 'error';
            this.uploadState = 'normal';
            this.nzChange.emit(event);
          },
        );
      } else if (scanResult === 'MALICIOUS') {
        this.uploadState = 'normal';
        // @ts-ignore
        event.file.status = 'MALICIOUS';
        this.nzChange.emit(event);
        this.notificationError(
          'The file you are trying to upload has been flagged as potentially unsafe. Please remove any suspicious content and try again.',
        );
      } else {
        this.uploadState = 'normal';
        event.file.status = 'error';
        this.nzChange.emit(event);
      }
    } else if (event.file.status === 'error') {
      this.uploadState = 'normal';
      this.nzChange.emit(event);
    } else {
      this.uploadState = 'normal';
      event.file.status = 'error';
      this.nzChange.emit(event);
    }
  }

  normalUpload(event: NzUploadChangeParam): void {
    if (event.file.status === 'uploading') {
      this.uploadState = 'uploading';
    } else if (event.file.status === 'done') {
      this.uploadState = 'normal';
      this.nzChange.emit(event);
    } else if (event.file.status === 'error') {
      this.uploadState = 'normal';
      this.nzChange.emit(event);
    } else {
      this.uploadState = 'normal';
      event.file.status = 'error';
      this.nzChange.emit(event);
    }
  }

  handleNzUploadChange(event: NzUploadChangeParam): void {
    if (this.nzActionScanning) {
      this.scanUpload(event);
    } else if (this.nzAction) {
      this.normalUpload(event);
    }
  }
}
