import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileDownloadService } from '@stream/service/file-download.service';

@Component({
  selector: 'stream-file-previewer',
  templateUrl: './file-previewer.component.html',
  styleUrls: ['./file-previewer.component.scss']
})
export class FilePreviewerComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string;
      url: string;
      downloadUrl: string;
      type: 'PDF' | 'IMAGE';
      miniCloseType?: string;
      btnCloseText?: string;
      btnCloseStyle?: string;
      btnDownloadText?: string;
      btnDownloadStyle?: string;
    },
    private breakpointObserver: BreakpointObserver,
    private fileDownloadService: FileDownloadService,
    private sanitizer: DomSanitizer
  ) {
    pdfDefaultOptions.cMapUrl = () => `/assets/cmaps/`;
  }

  downloadUrl!: string;

  pdfFileUrl!: string;
  imageSafeFileUrl!: SafeUrl;

  get isMedium() {
    return this.breakpointObserver.isMatched('(min-width: 768px)');
  }

  ngOnInit(): void {
    const { downloadUrl, type } = this.data;
    this.setFileUrl(downloadUrl, type === 'IMAGE');
  }

  setFileUrl(url: string, isImage = false) {
    if (url && !isImage) {
      this.fileDownloadService.getBlob(url, (blob: any) => {
        this.pdfFileUrl = URL.createObjectURL(blob);
      });
    } else if (url && isImage) {
      this.fileDownloadService.getBlob(url, (blob: any) => {
        const blobUrl = URL.createObjectURL(blob);
        this.imageSafeFileUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
      });
    }
  }

  onDownload(data: any) {
    if (data.downloadUrl) {
      this.fileDownloadService.getBlob(data.downloadUrl, (blob: any) => {
        this.saveAs(blob, data.name || '');
      });
    }
  }

  saveAs(blob: any, filename: string): void {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    body?.appendChild(link);
    link.click();
    body?.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }
}
