import { InjectionToken, Type } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';

export const TYPE_CONFIG_MAP = new InjectionToken('typeConfig');

export enum Logic {
  And = 'AND',
  Or = 'OR',
}

export type Value = [Logic, [string, string, any][]];

export interface Option {
  label: string;
  value: string;
}

export type Config<T = any> = {
  name: string;
  type?: string;
  key: string;
  params?: Partial<T>;
  children?: (Config & { group?: string })[];
  validators?: ValidatorFn[];
};

export class ValueSelectorComponent {
  formControl!: FormControl;
  config!: Config;
  typeConfig!: TypeConfig;
}

export interface TypeConfig<T extends ValueSelectorComponent = ValueSelectorComponent> {
  type: string;
  operators: Option[];
  component: Type<T>;
  defaultParams?: Record<string, any>;
  formatter?: (config: Config, operator: string, value: any, locale: string) => string;
  validators?: ValidatorFn[];
}
