import { TRANSLATE_CONFIG } from './translate.constants';
import { TranslateConfig } from './translate.interface';

export const TranslateConfigProvider = (config: TranslateConfig) => {
  const { origin, ignore } = config;
  return {
    provide: TRANSLATE_CONFIG,
    useValue: {
      ignore: {
        tags: (ignore?.tags ?? []).map((t) => t.toUpperCase()),
        classes: ignore?.classes ?? [],
        ids: ignore?.ids ?? [],
      },
      origin,
    },
  };
};
