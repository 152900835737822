import { DataRoomDocumentType } from './data-room.interface';

export interface DocumentOwner {
  id: string;
  name: string;
  type: string;
}

export interface MandatoryDocument {
  documentDescription: string;
  documentName: string;
  files: Array<MandatoryDocFile>;
  fundMandatoryDocId?: string;
  isMandatory: boolean;
  mandatoryDocumentKey: string;
  multiple: boolean;
  owner?: DocumentOwner;
  satisfy?: boolean;
  frontEndId?: string;
}

export interface MandatoryDocFile {
  downloadUrl: string;
  name: string;
  url: string;
  type: DataRoomDocumentType;
  size?: number;
  tag?: Tag;
  country?: TagCountry;
  id?: string;
  documentToken?: string;
}

export interface Tag {
  name: string;
  id: string;
}

export interface TagCountry {
  name: string;
  id: string;
}

export interface ProfileMandatoryDocument {
  country: TagCountry;
  tag: Tag;
  fileUrl: string;
  fileType: DataRoomDocumentType;
  fileName: string;
  downloadUrl: string;
  fileSize: number;
  id: string;
  documentToken: string;
}
