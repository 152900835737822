import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioGroup } from '@angular/material/radio';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { NotificationService } from '../../services';

@Component({
  selector: 'stream-drawer-select',
  templateUrl: './drawer-select.component.html',
  styleUrls: ['./drawer-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DrawerSelectComponent
    }
  ]
})
export class DrawerSelectComponent<T = any> implements ControlValueAccessor {
  constructor(private notification: NotificationService) {}
  @ViewChild('drawer')
  drawer?: MatDrawer;

  @ViewChild('optionGroup')
  optionGroup?: MatRadioGroup;

  @Input()
  set disabled(v: boolean) {
    this._disabled = v;
  }

  @Input()
  set value(v: T) {
    this._value = v;
    this._transmitValue = v;
    this.onChange?.(v);
  }

  @Input()
  showEdit = true;

  @Input() disableCreate = false;

  _value: T | null = null;

  _transmitValue: T | null = null;
  _disabled = false;

  @Input()
  name = '';

  @Input()
  nameSelector = '';

  @Input()
  nameDrawerTitle = '';

  @Input()
  nameDrawerAdd = '';

  @Input()
  totalLimitForAdd = 999999;

  @Input()
  disabledSelect = false;

  @Input()
  valueKey: keyof T = 'id' as keyof T;

  @Input()
  icon?: string;

  @Input()
  optionTemplate: TemplateRef<any> | null = null;

  @Input()
  set optionSource(v: T[]) {
    this.options.next(v);
    if (!this._value) {
      this.setValue((this._value?.[this.valueKey] || v?.[0]?.[this.valueKey]) ?? null);
    }
  }

  @Input()
  optionsObservable: Observable<T[]> = of([]);

  @Output()
  add = new EventEmitter<void>();

  @Output()
  edit = new EventEmitter<T>();

  options = new BehaviorSubject<T[] | null>(null);

  @Output()
  drawerOpened = new EventEmitter();

  getOptions(options: any) {
    const _options = (options || []).filter((option: any) =>
      this.disableCreate ? option.id === this._value?.[this.valueKey] : true
    );
    if (this.disableCreate && _options.length) {
      this._value = _options?.[0];
    }
    return _options;
  }

  refreshOptions(isOpenedRefresh?: boolean, options?: any) {
    (options ? of(options) : this.optionsObservable).subscribe(options => {
      this.options.next(options);
      if (!isOpenedRefresh && !this.disableCreate) {
        this.setValue(options?.[0]?.[this.valueKey] ?? null, false);
      } else {
        this.setValue(
          (this._value?.[this.valueKey] || options?.[0]?.[this.valueKey]) ?? null,
          !isOpenedRefresh
        );
      }
    });
  }

  onDrawerOpen() {
    this.drawerOpened.emit();
    if (this.optionGroup) {
      this.optionGroup.value = this._value?.[this.valueKey] ?? null;
    }
    if (!this.options.value) {
      this.refreshOptions(true);
    }
  }

  onChange?: (v: T) => void;
  onTouched?: () => void;
  writeValue(value: T | null, needPass?: boolean): void {
    if (needPass !== false) {
      this._transmitValue = value;
    }
    this._value = value;
  }
  transmitValue(valuekey: T | null): void {
    const tempValuekey: any = valuekey;
    const value = this.options.value?.find(o => o[this.valueKey] === tempValuekey) ?? null;
    this._transmitValue = value;
    if (value) {
      this.onChange?.(value);
    }
  }

  registerOnChange(fn: (v: T) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  submit(keyValue: any) {
    if (keyValue) {
      this.setValue(keyValue);
      this.transmitValue(keyValue);
      this.drawer?.close();
    } else {
      this.notification.error('Please select an item from the list before submitting');
    }
  }

  setValue(keyValue: any, force = false) {
    const value = this.options.value?.find(o => o[this.valueKey] === keyValue) ?? null;
    this.writeValue(value, force);
  }
}
