<ng-container [formGroup]="form">
  <mat-form-field>
    <mat-label>Legal name of the entity</mat-label>
    <input matInput autocomplete="organization" formControlName="legalName" />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Incorporation ID</mat-label>
    <input matInput formControlName="nationalRegistrationNumber" />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label> Contact email </mat-label>
    <input matInput type="email" autocomplete="email" formControlName="contactEmail" />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <div class="flex">
    <mat-form-field class="custom-small-form-width">
      <mat-label>Code</mat-label>
      <mat-select formControlName="phoneCode" #phoneCodeSelect [panelWidth]="300">
        <mat-select-trigger>
          {{ getCodeByLabel($any(phoneCodeSelect.selected)?.viewValue) }}
        </mat-select-trigger>
        <mat-option *ngFor="let areaCode of areaCodeList" [value]="areaCode.countryCode">
          {{ areaCode.name + ' +' + areaCode.dialCode }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Phone</mat-label>
      <input
        matInput
        autocomplete="tel-national"
        type="tel"
        formControlName="phoneNumber"
      />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Place of incorporation</mat-label>
    <ngx-select-country
      formControlName="countryCodeIncorporation"
      [required]="true"
      [options]="countryList$"
    ></ngx-select-country>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field class="custom-field-length">
    <mat-label>Date of incorporation or formation (mm/dd/yyyy)</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      formControlName="incorporationDate"
      [max]="todayDate"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Nature of business</mat-label>
    <input matInput formControlName="natureOfBusiness" />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Entity type</mat-label>
    <mat-select formControlName="entityType" (selectionChange)="changeValue($event)">
      <mat-option *ngFor="let type of entityTypeList" [value]="type.key">
        {{ type.en }}
      </mat-option>
    </mat-select>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="isOther">
    <mat-label></mat-label>
    <input
      formControlName="entityTypeComment"
      panelClass="max-w-none"
      type="text"
      placeholder="Placeholder"
      matInput
      autocomplete="entityTypeComment"
    />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
</ng-container>
