export interface NavMenu {
  icon?: string;
  path?: string;
  children?: NavMenu[];
  label: string;
  isOpen?: boolean;
  index?: string;
  parent?: string;
}

export const MAIN_MENU = [
  {
    path: 'product',
    icon: '#icon-Opportunities',
    label: 'Opportunities',
  },
  {
    icon: '#icon-portfolio',
    label: 'Portfolio',
    isOpen: false,
    children: [
      {
        path: 'portfolio/dashboard',
        icon: '#icon-dashboard',
        label: 'Dashboard',
      },
      {
        path: 'portfolio/investments',
        icon: '#icon-investments',
        label: 'Investments',
      },
    ],
  },
];

export const USER_CENTRE_MENU = [
  {
    icon: '#icon-account',
    label: 'Account',
    isOpen: false,
    children: [
      {
        path: 'account/basic',
        label: 'Basic',
      },
      {
        path: 'account/security',
        label: 'Security',
      },
    ],
  },
];

export const TEAM_MEMBERS_MENU = [
  {
    icon: '#icon-a-icon-teamMembers',
    label: 'Team Members',
    isOpen: false,
    path: 'account/team-members',
  },
];

export const BOTTOM_MENU = [
  {
    path: '/product',
    icon: '#icon-Opportunities',
    label: 'Opportunities',
  },
  {
    path: '/portfolio',
    icon: '#icon-portfolio',
    label: 'Portfolio',
  },
  {
    path: '/inbox',
    icon: '#icon-icon-inbox',
    label: 'Inbox',
  },
  {
    path: '/account',
    icon: '#icon-icon-my',
    label: 'My',
  },
];
