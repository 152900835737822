import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastService } from '@stream/libs/common/ngx-utils';
import { Principal, SetLanguageStoreKeyEnum } from '@stream/models';
import { deployingEnvironment, loadGoogleAnalytics } from '@stream/utils';
import { LocalStorageService } from 'ngx-webstorage';
import { Subscription, of, zip } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateComponent } from 'src/libs/translate/translate.component';

import { environment } from '../environments/environment';
import { AccountService } from './services/account.service';
import { ClientService } from './services/client.service';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'stream-lp-root',
  template: `
    <router-outlet></router-outlet>
    <stream-translate #translate></stream-translate>
    <stream-chat></stream-chat>
    <stream-inactivity-reminder
      *ngIf="boolLPAccountExist"
      (onTimeout)="signOut()"
      countdownPlatform="lp"
    ></stream-inactivity-reminder>
  `
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('translate')
  translateRef!: TranslateComponent;

  translateRegistered = false;
  routerSub!: Subscription;

  public boolLPAccountExist: boolean = false;
  private principalSubscription!: Subscription;

  constructor(
    private languageService: LanguageService,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private clientService: ClientService,
    private toast: ToastService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.toast.setViewContainerRef(this.viewContainerRef);
  }

  ngOnInit(): void {
    switch (deployingEnvironment()) {
      case 'PRE':
        loadGoogleAnalytics(environment.googleAnalyticsTrackingID_PRE);
        break;

      case 'PRE-EU':
      case 'PRE-EU-BLUE':
      case 'PRE-EU-GREEN':
      case 'PRE-US':
      case 'PRE-US-BLUE':
      case 'PRE-US-GREEN':
      case 'PRE-APAC':
      case 'PRE-APAC-BLUE':
      case 'PRE-APAC-GREEN':
        loadGoogleAnalytics(environment.googleAnalyticsTrackingID_PRE_EU);
        break;

      case 'PROD-EU':
      case 'PROD-EU-BLUE':
      case 'PROD-EU-GREEN':
      case 'PROD-US':
      case 'PROD-US-BLUE':
      case 'PROD-US-GREEN':
      case 'PROD-APAC':
      case 'PROD-APAC-BLUE':
      case 'PROD-APAC-GREEN':
        loadGoogleAnalytics(environment.googleAnalyticsTrackingID_PROD_EU);
        break;

      default:
        break;
    }
    this.routerSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.registerTranslate();
      });

    this.principalSubscription = this.accountService.principal$.subscribe(
      (principals: Principal[]) => {
        if (principals.length > 0) {
          this.boolLPAccountExist = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.translateRegistered = false;
    this.routerSub.unsubscribe();
    if (this.principalSubscription) {
      this.principalSubscription.unsubscribe();
    }
  }

  registerTranslate() {
    const firstChildConfig = this.route.snapshot.firstChild?.routeConfig;
    const needToTranslate = firstChildConfig?.data?.translate;
    if (!needToTranslate || this.translateRegistered) {
      return;
    }
    this.translateRegistered = true;
    this.languageService.translateSubject$
      .pipe(
        mergeMap(target => {
          return zip(this.accountService.account, this.clientService.configuration, of(target));
        }),
        mergeMap(([account, client, target]) => {
          if (!client.isMultiLingual) {
            this.translateRef.disable();
            return of(null);
          }
          const lang = target ?? account.language;
          this.translateRef.enable();
          this.translateRef.setLanguage(lang);
          if (target && target !== account.language) {
            return this.accountService
              .updateAccount({ language: lang, id: account.id })
              .pipe(map(() => lang));
          }
          return of(null);
        })
      )
      .subscribe(data => {
        if (data) {
          this.localStorage.store(SetLanguageStoreKeyEnum.DeviceLanguage, data);
          this.translateRef.translate({ lang: data });
        }
      });
  }

  public signOut(): void {
    this.accountService.logout().subscribe();
  }
}
