<div class="flex h-full w-full flex-col justify-start">
  <header
    class="z-[3] flex h-14 w-full flex-[0_0_auto] items-center gap-2 border-b border-solid border-primary-100 bg-white px-5 md:h-[78px]"
  >
    <svg class="icon text-xl text-primary-500 md:text-primary-200" (click)="handleBtnCloseClick()">
      <use xlink:href="#icon-a-icon-return1"></use>
    </svg>

    <h1 class="flex-1 pl-3 text-center text-lg font-semibold md:text-left">
      <span> Certify documents to link IRA </span>
    </h1>
  </header>

  <div class="box-border flex h-0 flex-[1_1_auto] flex-col overflow-y-auto px-8 py-5">
    <!-- desc -->
    <div
      class="relatived box-border flex w-full flex-[0_0_auto] flex-col border-b border-solid border-[#D0D4D9] pb-6"
    >
      <div class="color-[#101213] text-[22px] font-semibold">Retrieve account</div>
      <div class="color-[#2C2934] mt-6 text-base font-normal">
        Please view and certify each document before continuing.
      </div>
    </div>

    <!-- Document -->
    <stream-documents-list
      [documentsList]="documentsList"
      (checkListChange)="handleCheckListChange($event)"
    />
  </div>

  <footer
    class="grid w-full flex-[0_0_auto] grid-cols-2 justify-around gap-x-2 bg-white p-6 md:flex md:justify-end md:space-x-2"
    style="z-index: 1"
  >
    <button
      mat-stroked-button
      color="primary"
      (click)="handleBtnCloseClick()"
      type="button"
      class="mat-button-lg w-full !px-14 md:w-auto"
      [disabled]="loading"
    >
      <span class="font-semibold"> Back </span>
    </button>
    <button
      mat-flat-button
      type="button"
      color="primary"
      class="mat-button-lg !px-14"
      [disabled]="disabledSubmit || loading"
      (click)="handleBtnSubmitClick()"
    >
      <div class="flex flex-row items-center justify-center">
        <mat-spinner *ngIf="loading" diameter="22" strokeWidth="2" class="mr-2"></mat-spinner>
        <div>Submit</div>
      </div>
    </button>
  </footer>
</div>

<ng-template #linkingFailedDialog>
  <header mat-dialog-title class="!flex items-center">
    <h1 class="flex-1 font-semibold md:text-[22px]">IRA linking failed</h1>
    <button mat-icon-button mat-dialog-close type="button">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <p mat-dialog-content class="text-base text-[#101213]">
    Unfortunately we were unable to retrieve your existing Inspira Financial account, Please confirm
    the details you’ve entered are correct. If the problem persists, get in touch with sales
    support. {{ salesSupportEmail | async }}
  </p>

  <div class="flex items-center justify-end gap-3" mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      class="flex-1 md:w-40 md:flex-none"
    >
      OK
    </button>
  </div>
</ng-template>
