<nz-upload
  class="verify-upload-wrap"
  [style]="{
    '--verify-upload-color-default': '#d9d9d9',
    '--verify-upload-color-primary': colorPrimary
  }"
  [nzAccept]="nzAccept"
  [nzAction]="nzActionScanning || nzAction"
  [nzDirectory]="nzDirectory"
  [nzBeforeUpload]="nzBeforeUpload"
  [nzCustomRequest]="nzCustomRequest"
  [nzData]="nzData"
  [nzDisabled]="nzDisabled || uploadState !== 'normal'"
  [nzFileList]="nzFileList"
  [nzLimit]="nzLimit"
  [nzSize]="nzSize"
  [nzFileType]="nzFileType"
  [nzFilter]="nzFilter"
  [nzHeaders]="nzHeaders"
  [nzListType]="nzListType"
  [nzMultiple]="nzMultiple"
  [nzName]="nzName"
  [nzShowUploadList]="nzShowUploadList"
  [nzShowButton]="nzShowButton"
  [nzWithCredentials]="nzWithCredentials"
  [nzOpenFileDialogOnClick]="nzOpenFileDialogOnClick"
  [nzPreview]="nzPreview"
  [nzPreviewFile]="nzPreviewFile"
  [nzRemove]="nzRemove"
  [nzDownload]="nzDownload"
  [nzTransformFile]="nzTransformFile"
  [nzIconRender]="nzIconRender"
  [nzFileListRender]="nzFileListRender"
  (nzChange)="handleNzUploadChange($event)"
>
  <div [ngSwitch]="uploadType" [style]="{ width: '100%', height: '100%' }">
    <ng-container *ngSwitchCase="'btnPrimary'">
      <button
        class="verify-upload-btn"
        [style]="{ color: '#fff', backgroundColor: 'var(--verify-upload-color-primary)' }"
        nz-button
        nzSize="large"
        nzType="primary"
      >
        <ng-container [ngSwitch]="uploadState">
          <ng-container *ngSwitchCase="'normal'">
            <span class="verify-upload-icon-text">{{ uploadTextNormal }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'uploading'">
            <svg class="icon verify-upload-icon" viewBox="0 0 1024 1024">
              <path
                d="M688 207.168A352 352 0 1 0 864 512 48 48 0 0 1 960 512a448 448 0 1 1-224-387.968 48 48 0 0 1-48 83.136z"
              ></path>
            </svg>
            <span class="verify-upload-ml verify-upload-icon-text">{{
              uploadTextUploading
            }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'scanning'">
            <svg class="icon verify-upload-icon" viewBox="0 0 1024 1024">
              <path
                d="M37.312 512A474.688 474.688 0 0 1 512 37.312a48 48 0 0 1 48 48v341.312a48 48 0 0 1-96 0V136.32A378.752 378.752 0 1 0 890.688 512a48 48 0 0 1 96 0A474.688 474.688 0 1 1 37.312 512z"
              ></path>
              <path
                d="M512 597.312a85.312 85.312 0 1 0 0-170.688 85.312 85.312 0 0 0 0 170.688z"
              ></path>
            </svg>
            <span class="verify-upload-ml verify-upload-icon-text">{{
              uploadTextScanning
            }}</span>
          </ng-container>
        </ng-container>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'btnDefault'">
      <button
        class="verify-upload-btn"
        [style]="{ color: 'var(--verify-upload-color-primary)', backgroundColor: '#fff' }"
        nz-button
        nzSize="large"
        nzType="default"
      >
        <ng-container [ngSwitch]="uploadState">
          <ng-container *ngSwitchCase="'normal'">
            <span class="verify-upload-icon-text">{{ uploadTextNormal }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'uploading'">
            <svg class="icon verify-upload-icon" viewBox="0 0 1024 1024">
              <path
                d="M688 207.168A352 352 0 1 0 864 512 48 48 0 0 1 960 512a448 448 0 1 1-224-387.968 48 48 0 0 1-48 83.136z"
              ></path>
            </svg>
            <span class="verify-upload-ml verify-upload-icon-text">{{
              uploadTextUploading
            }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'scanning'">
            <svg class="icon verify-upload-icon" viewBox="0 0 1024 1024">
              <path
                d="M37.312 512A474.688 474.688 0 0 1 512 37.312a48 48 0 0 1 48 48v341.312a48 48 0 0 1-96 0V136.32A378.752 378.752 0 1 0 890.688 512a48 48 0 0 1 96 0A474.688 474.688 0 1 1 37.312 512z"
              ></path>
              <path
                d="M512 597.312a85.312 85.312 0 1 0 0-170.688 85.312 85.312 0 0 0 0 170.688z"
              ></path>
            </svg>
            <span class="verify-upload-ml verify-upload-icon-text">{{
              uploadTextScanning
            }}</span>
          </ng-container>
        </ng-container>
      </button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-content></ng-content>
    </ng-container>
  </div>
</nz-upload>
