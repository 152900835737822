import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ChatConversation, ChatResponseEnum } from '@stream/models';

import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'stream-chat-conversation-detail',
  templateUrl: './conversation-detail.component.html',
  styleUrls: ['./conversation-detail.component.scss'],
})
export class ChatConversationDetailComponent implements OnInit {
  titleToolTip = false;

  lastReadTime: string = '';

  @Output()
  closeEvent = new EventEmitter();

  currentConversation: ChatConversation | null = null;

  get conversationInfo() {
    return this.currentConversation?.conversation;
  }

  get subscription() {
    return this.currentConversation?.productSubscription;
  }

  institutional = this.chatService.isInstitutional;

  @ViewChild('titleWrapper')
  titleWrapperRef?: ElementRef<HTMLDivElement>;

  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.checkConversationValid();
  }

  checkConversationValid() {
    this.chatService.getLastReadTime().subscribe(
      ({ status, lastReadTime }) => {
        if (status === ChatResponseEnum.Success) {
          this.lastReadTime = lastReadTime;
          this.chatService.getCurrentConversation().subscribe((data) => {
            this.currentConversation = data;
            this.checkTitleToolTip();
          });
        } else {
          this.back();
        }
      },
      () => {
        this.back();
      }
    );
  }

  checkTitleToolTip() {
    setTimeout(() => {
      const ele = this.titleWrapperRef?.nativeElement;
      if (!ele) return;
      this.titleToolTip = ele.scrollHeight - ele.clientHeight > 10;
    });
  }

  back() {
    this.chatService.closeMessagesView();
  }

  close() {
    this.closeEvent.emit();
  }
}
