import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MandatoryDocument } from '@stream/models';
import { SharedService } from '@stream/shared';
import { map } from 'rxjs/operators';

import { MandatoryDocItem, MandatoryDocOptions } from '../mandatory-doc.interface';
import { MandatoryDocService } from '../mandatory-doc.service';

@Component({
  selector: 'stream-mandatory-doc',
  templateUrl: './mandatory-doc.component.html',
  styleUrls: ['./mandatory-doc.component.scss']
})
export class MandatoryDocComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: MandatoryDocOptions,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<MandatoryDocComponent>,
    private mandatoryDocService: MandatoryDocService
  ) {}

  mds: Array<MandatoryDocItem> = [];

  get confirmEnabled() {
    if (this.data.checkAllFiles) return true;
    return this.isOneOfFormGroupsValid();
  }

  ngOnInit(): void {
    this.mds = (this.data.mandatoryDocs || [])
      .map((item, index) => ({
        ...item,
        form: this.fb.group({
          tag: [null, []],
          country: [],
          file: []
        }),
        tags: [],
        index
      }))
      .map(item => {
        item.form
          .get('tag')
          ?.addValidators(this.mandatoryDocService.tagRequireValidator(item.form));
        return {
          ...item
        };
      });
  }

  onConfirm() {
    let isValid = true;
    if (!this.data.checkAllFiles) {
      // 只要有一个满足条件即可
      const formValid = this.isOneOfFormGroupsValid();
      isValid =
        formValid &&
        this.mds.some(item => {
          const fileValid = (item.form.get('file')?.value || []).length > 0;
          const tagCtr = item.form.get('tag');
          if (fileValid && tagCtr) {
            item.form.get('file')?.markAsDirty();
            tagCtr.updateValueAndValidity();
            tagCtr.markAsDirty();
            tagCtr.markAsTouched();
            return item.form.valid;
          }
          return false;
        });
    } else {
      // 所有都需要满足条件
      this.mds.forEach(item => {
        const tagCtr = item.form.get('tag');
        tagCtr?.updateValueAndValidity();
        const valid = (item.form.get('file')?.value || []).length > 0 && tagCtr?.valid;
        if (!valid) {
          item.form.get('file')?.markAsDirty();
          tagCtr?.markAsDirty();
          isValid = false;
        }
      });
    }
    if (!isValid) {
      return;
    }
    this.packageResult(this.mds).subscribe(v => {
      this.dialogRef.close(v);
    });
  }

  getFormatCountryFromList(value: string, list: Array<{ label: string; value: string }>) {
    return value ? { id: value, name: list.find(item => item.value === value)?.label } : undefined;
  }

  getFormatTagFromList(value: string, list: Array<{ label: string; value: string }>) {
    return value ? { name: value, id: list.find(item => item.label === value)?.value } : undefined;
  }

  packageResult(mdList: Array<MandatoryDocItem>) {
    return this.sharedService.countryList
      .pipe(
        map(list =>
          list.map(item => ({
            ...item,
            label: item.countryName,
            value: item.countryAlpha2Code
          }))
        )
      )
      .pipe(
        map(countries =>
          mdList.map(({ form, tags, files, index, ...rest }) => ({
            ...rest,
            files: (form.get('file')?.value || []).map((_f: MandatoryDocument) => ({
              ..._f,
              tag: this.getFormatTagFromList(form.get('tag')?.value, tags),
              country: this.getFormatCountryFromList(form.get('country')?.value, countries)
            }))
          }))
        )
      );
  }

  isOneOfFormGroupsValid() {
    // 应用场景：inbox
    if (this.data.checkAllFiles) return true;
    // 存在一个有文件且tag校验通过的
    let hasValidItem = false;
    let hasIncompleteItem = false;
    hasValidItem = this.mds.some(item => {
      if (!item.form) {
        return false;
      }
      const fileCtr = item.form.get('file');
      const tagCtr = item.form.get('tag');
      if (!fileCtr || !tagCtr) {
        return false;
      }
      const fileValid = (fileCtr.value || []).length > 0;
      return fileValid ? !!tagCtr.value : false;
    });
    // 存在一个有文件但tag校验不通过的
    hasIncompleteItem = this.mds.some(item => {
      if (!item.form) {
        return false;
      }
      const fileCtr = item.form.get('file');
      const tagCtr = item.form.get('tag');
      if (!fileCtr || !tagCtr) {
        return false;
      }
      const fileValid = (fileCtr.value || []).length > 0;
      return fileValid ? !tagCtr.value : false;
    });
    return hasValidItem && !hasIncompleteItem;
  }
}
