import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DashboardApi,
  DataRoomApi,
  DataRoomDocument,
  FundamentalTypeEnum,
  FundTypeEnum,
  HttpStatusEnum,
  InvestmentDetail,
  InvestmentFacade,
  InvestorCalls,
  NavLineGraphPolyline,
  Page,
  PageRequest,
  Product,
  ProductApi,
  ProductReport,
  ProductReportFolder,
  ProductSubscription,
  Redemptions,
  Restful,
  ShareClassAnalysis,
  SubscriptionProfileApi,
  Transaction
} from '@stream/models';
import { CreateRedemptionPayload, ProductSubscriptionApi } from '@stream/src/common';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, pluck, shareReplay, switchMap, take } from 'rxjs/operators';

import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) {}

  getDataRoomFileFn = new BehaviorSubject<(fileId: string) => Observable<ArrayBuffer>>(() =>
    of(new ArrayBuffer(0))
  );

  getInvestmentSubscriptionList(params: { productSubscriptionStepStatus: string | null }) {
    return this.http.post<
      Restful<{
        investmentFacadeList: InvestmentFacade[];
        status: HttpStatusEnum;
        productList: Product[];
        rate: Record<string, number>;
      }>
    >(ProductSubscriptionApi.GetSubscriptionInvestment, params);
  }

  getUMPInvestmentSubscriptionList(params: { productSubscriptionStepStatus: string | null }) {
    return this.http.post<
      Restful<{
        investmentFacadeList: InvestmentFacade[];
        status: HttpStatusEnum;
        productList: Product[];
        rate: Record<string, number>;
      }>
    >(ProductSubscriptionApi.GetUMPSubscriptionInvestment, params);
  }

  getProductSubscriptionList(params: { productId: string }) {
    return this.http.get<Restful<ProductSubscription[]>>(
      ProductSubscriptionApi.GetSubscriptionProduct,
      { params }
    );
  }

  getUMPProductSubscriptionList(params: { productId: string }) {
    return this.http.get<Restful<ProductSubscription[]>>(
      ProductSubscriptionApi.GetUMPSubscriptionProduct,
      { params }
    );
  }

  getNavLineGraph(params: { fundTypeEnum: FundTypeEnum }) {
    return this.http.post<
      Restful<{
        navLineGraphVOS: NavLineGraphPolyline[];
        status: string;
      }>
    >(DashboardApi.GetNavLineGraph, params);
  }

  getSubscriptionStatistics() {
    return this.http.post<
      Restful<{
        status: string;
        committed: number;
        distribution: number;
        invested: number;
        nav: number;
        unDrawn: number;
      }>
    >(DashboardApi.GetSubscriptionStatistics, {});
  }

  getInvestmentDetail(params: { productId: string }) {
    return this.http.post<Restful<{ status: string; investmentDetail: InvestmentDetail }>>(
      ProductSubscriptionApi.GetSubscriptionInvestmentDetail,
      params
    );
  }

  getTransactionPage(params: { page: PageRequest; productId: string }) {
    return this.http.post<
      Restful<{
        status: string;
        transactionPage: Page<Transaction>;
      }>
    >(ProductSubscriptionApi.GetSubscriptionTransactionPage, params);
  }
  getTransactionList(params: {
    current: number;
    size: number;
    productId: string;
    statuses: string;
    sort: string;
  }) {
    return this.http.get<
      Restful<{
        status: string;
        total: number;
        transactions: Transaction[];
        hasTokenyStatus: boolean;
      }>
    >(SubscriptionProfileApi.GetTransactions, { params: { ...params } });
  }

  getInvestorCalls(params: { current: number; size: number; productId: string; sort: string }) {
    return this.http.get<
      Restful<{ status: string; total: number; investorCalls: InvestorCalls[] }>
    >(SubscriptionProfileApi.GetInvestorCalls, { params: { ...params } });
  }

  getInvestmentAnalysis(params: { productId: string }) {
    return this.http
      .post<
        Restful<{
          status: string;
          productType: FundamentalTypeEnum;
          shareClassAnalysisList: ShareClassAnalysis[];
        }>
      >(ProductSubscriptionApi.GetSubscriptionInvestmentAnalysis, params)
      .pipe(
        catchError(err => {
          return of(err.error);
        })
      );
  }

  getShareClassNAVReports(params: { page: PageRequest; productId: string }) {
    return this.http.post<
      Restful<{
        status: string;
        page: Page<DataRoomDocument>;
      }>
    >(ProductSubscriptionApi.GetProductNavReportsByPOST, params);
  }

  getComplianceDocuments(params: { productId: string }) {
    return this.http.post<
      Restful<{
        status: string;
        currentDataRoomDocument: DataRoomDocument;
        dataRoomDocumentsList: Array<DataRoomDocument>;
      }>
    >(DataRoomApi.QueryComplianceDocuments, params);
  }

  getProduct(productId: Product['id'], withoutLogActivity?: boolean) {
    withoutLogActivity = withoutLogActivity == undefined ? false : withoutLogActivity;
    return this.http.post<Restful<{ product: Product; gpHasReporting: string }>>(
      ProductApi.GetProduct,
      {
        productId,
        withoutLogActivity
      }
    );
  }

  getSubscriptionDocuments(params: { subscriptionId: string }) {
    return this.http.post<Restful<{ dataRoomDocumentsList: Array<DataRoomDocument> }>>(
      DataRoomApi.QuerySubscriptionDocuments,
      params
    );
  }

  /**
   * 获取有水印文件
   * @param fileId 文件ID
   * @returns 文件Blob
   */
  queryWaterMarkFile(fileId: string) {
    return this.accountService.account.pipe(
      pluck('timeZoneText'),
      take(1),
      switchMap(tz => {
        return this.http.post<Blob>(
          DataRoomApi.QueryFile,
          {
            fileId,
            readDate: moment
              .tz(new Date(), tz ?? moment.tz.guess())
              .format('MMM DD, YYYY, hh:mmA')
              .toString()
          },
          {
            //@ts-ignore
            responseType: 'blob'
          }
        );
      })
    );
  }

  queryFile(fileId: string) {
    return this.http.post<Restful<{ documents: DataRoomDocument; status: string }>>(
      DataRoomApi.QueryFileWithoutWatermark,
      { fileId }
    );
  }

  getProductSubscriptionNAV(params: { subscriptionId: string }) {
    return this.http.post<
      Restful<{
        status: string;
        subscriptionNavVOList: {
          date: Date;
          dividend: number;
          nav: number;
        }[];
      }>
    >(ProductSubscriptionApi.getSubscriptionNav, params);
  }

  getSubscriptionRedemptions(subscriptionId: string) {
    return this.http.get<Restful<Redemptions[]>>(
      ProductSubscriptionApi.GetRedemptions.replace(':subscriptionId', subscriptionId)
    );
  }

  getSubscriptionInProgress() {
    return this.http.get<
      Restful<{
        subscriptionInProgressItemList: {
          productId: Product['id'];

          progressRatio: number;
          subscriptionInProgressId: string;
          snapshotProductFundamentalName: Product['fundamentalName'];
        }[];
      }>
    >(DashboardApi.GetSubscriptionInProgress);
  }

  rate = this.getCurrencyRate().pipe(pluck('data', 'rate'), shareReplay(1));
  // 获取汇率
  getCurrencyRate() {
    return this.http.get<Restful<{ rate: Record<string, number>; status: string }>>(
      ProductSubscriptionApi.getCurrencyRate
    );
  }

  redemptionRequest(subscriptionId: string, params: CreateRedemptionPayload) {
    return this.http.post<
      Restful<{
        status: HttpStatusEnum;
      }>
    >(ProductSubscriptionApi.redemptionRequest.replace(':subscriptionId', subscriptionId), params);
  }

  getProductReports(productId: string, folderId: string, params: PageRequest) {
    return this.http.get<Restful<Page<ProductReport>>>(
      ProductSubscriptionApi.GetProductsReports.replace(':productId', productId),
      { params: { ...params, 'folder-id': folderId } }
    );
  }

  getProductReportFolders(productId: string) {
    return this.http
      .get<
        Restful<{ data: ProductReportFolder[] }>
      >(ProductSubscriptionApi.GetProductsReportFolders.replace(':productId', productId))
      .pipe(pluck('data'));
  }

  getReport(productId: string, reportId: string) {
    return this.http
      .get<
        Restful<{ data: ProductReport }>
      >(ProductSubscriptionApi.GetReport.replace(':productId', productId).replace(':reportId', reportId))
      .pipe(pluck('data'));
  }
}
