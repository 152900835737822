import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataRoomDocumentType, ProfileInvestorDocument } from '@stream/models';
import { FilePreviewerComponent } from '@stream/ngx-utils';

@Component({
  selector: 'stream-additional-document',
  templateUrl: './additional-document.component.html',
  styleUrls: ['./additional-document.component.less']
})
export class AdditionalDocumentComponent {
  accept: string = 'application/pdf,image/jpg,image/png,image/jpeg';
  maxSize = 30 * 1024 * 1024;
  loading = false;

  @Input() additionalDoc: ProfileInvestorDocument[] = [];
  @Input() profileId!: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {}

  getDocTagLabel = (v: any) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  getIconByType(type: DataRoomDocumentType) {
    return {
      [DataRoomDocumentType.Pdf]: '#icon-PDF',
      [DataRoomDocumentType.Image]: '#icon-a-zhaopian11',
      [DataRoomDocumentType.Excel]: '#icon-a-Property1Variant19',
      [DataRoomDocumentType.Folder]: '#icon-wenjianguanbi'
    }[type];
  }

  previewFile(doc: ProfileInvestorDocument) {
    if (!doc) return;
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.dialog.open(FilePreviewerComponent, {
      data: {
        type: doc.fileType,
        url: doc.fileUrl,
        downloadUrl: doc.downloadUrl,
        name: doc.fileName
      },
      maxWidth: isMedium ? '960px' : '90vw',
      maxHeight: '100vh',
      width: '100%'
    });
  }
}
