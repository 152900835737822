<ul class="button-group">
  <li *ngFor="let config of config$ | async">
    <stream-add-expression-operator
      [config]="config"
      (itemClick)="addExpression($event)"
      [disabledItem]="disabledItem"
    ></stream-add-expression-operator>
  </li>
</ul>
<nz-segmented
  style="float: right"
  *ngIf="expressionControl.length > 1"
  [nzOptions]="segmentOptions"
  [ngModel]="selectedIndex"
  (ngModelChange)="logicControl.setValue(segmentOptions[$event].value)"
></nz-segmented>
