import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import {
  CustodianAccountStatus,
  CustodianAccountType,
  Entity,
  EntityMember,
  EntityMemberRole,
  EntityStructureType,
  IPayMethodType,
  Individual,
  LabelCustodianAccountType,
  Profile,
  ProfileType,
  Store
} from '@stream/models';

import { SharedService } from '@stream/shared';

import { Observable, filter, map, tap } from 'rxjs';

import { ProfileSettingService } from '../../services';
import { profileValidatorFn } from '../../utils';

@Component({
  selector: 'stream-profile-card',

  templateUrl: './profile-card.component.html',

  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements AfterViewInit {
  constructor(
    private sharedService: SharedService,

    private dialog: MatDialog,

    private profileSettingService: ProfileSettingService
  ) {}

  IPayMethodType = IPayMethodType;

  @ViewChild('deleteProfileDialog')
  deleteProfileDialogTpl!: TemplateRef<any>;

  @Input()
  reModify: boolean = false;

  @Input()
  profile!: Profile;

  @Input()
  isUnsupportCustodian: boolean = false;

  @Input()
  isDisableIRA: boolean = false;

  @Output()
  edit = new EventEmitter<Profile>();

  @Output()
  delete = new EventEmitter<Profile>();

  @Output()
  disabledRadioChange = new EventEmitter<{
    profile: Profile;

    valueDisabledRadio: boolean;
  }>();

  @HostBinding('class.individual')
  get isIndividual() {
    return this.profile.profileType === ProfileType.Individual;
  }

  @HostBinding('class.entity')
  get isEntity() {
    return this.profile.profileType === ProfileType.Entity;
  }

  progress$: Observable<number> = new Observable<number>();

  tenantSettings: any;

  get isCustodian() {
    return this.profile.payMethod === IPayMethodType.IRA;
  }

  get isLinkCustodian() {
    return (
      this.isCustodian && this.profile.investorProfileMtCustodianData?.accountType // this.profile.investorProfileMtCustodianData?.accountStatus
    );
  }

  // 是custodian 且 接口返回了IRA type 且 accountStatus 不是open或pending 会认为状态报错 (因为接口有概率accountStatus不返回, 同时还有是custodian但是未绑定状态)

  get isAccountStatusError() {
    return (
      this.isCustodian &&
      this.profile.investorProfileMtCustodianData?.accountType &&
      ![CustodianAccountStatus.Open, CustodianAccountStatus.Pending].includes(
        this.profile.investorProfileMtCustodianData?.accountStatus as CustodianAccountStatus
      )
    );
  }

  get isDisableRadioChange() {
    return (
      this.isCustodian &&
      (this.isDisableIRA || this.isUnsupportCustodian || this.isAccountStatusError)
    );
  }

  get labelAccountType() {
    return (
      LabelCustodianAccountType[
        this.profile.investorProfileMtCustodianData?.accountType as CustodianAccountType
      ] || this.profile.investorProfileMtCustodianData?.accountType
    );
  }

  store = new Store({ type: 'session', namespace: 'scenario' });

  countryList = this.sharedService.countryList;

  countryListSync: Array<{ label: string; value: string }> = [];

  get progress() {
    return this.isIndividual ? this.getIndividualProgress() : this.getEntityProgress();
  }

  get bankNumber() {
    return this.transformBankNumber(
      this.profile.investorProfileMetaInfoBank?.bankAccountNumber ?? '',

      true
    );
  }

  get address() {
    return this.profile.investorProfileMetaInfoAddress;
  }

  ngOnInit(): void {
    this.countryList.subscribe(countries => {
      this.countryListSync = (countries || []).map(item => ({
        ...item,

        label: item.countryName,

        value: item.countryAlpha2Code
      }));
    });

    this.progress$ = this.profileSettingService.tenantProfileSettings$.pipe(
      filter(configs => !!configs),

      tap(configs => {
        this.tenantSettings = configs;
      }),

      map(_ => (this.isIndividual ? this.getIndividualProgress() : this.getEntityProgress()))
    );
  }

  ngAfterViewInit() {
    this.disabledRadioChange.emit({
      profile: this.profile,

      valueDisabledRadio: !!this.isDisableRadioChange
    });
  }

  getEntityProgress() {
    const {
      investorProfileMetaEntity,

      investorProfileMetaInfoMemberList,

      investorProfileMetaInfoBank
    } = this.profile; // changed for: https://bite.atlassian.net/browse/STREAM-9107
    // retain this variable for future use
    // check if investorProfileMetaInfoMemberList length is greater than 0

    const entityCompleted = investorProfileMetaEntity
      ? (
          [
            'contactEmail',
            'legalName',
            'phoneCode',
            'phoneNumber',
            'countryCodeIncorporation',
            'incorporationDate',
            'natureOfBusiness',
            'nationalRegistrationNumber'
          ] as (keyof Entity)[]
        ).every(key => investorProfileMetaEntity[key]) &&
        !!(
          (investorProfileMetaEntity.entityType &&
            investorProfileMetaEntity.entityType !== 'other') ||
          investorProfileMetaEntity.entityTypeComment
        )
      : false;

    const validKeys: (keyof Profile)[] = ['investorProfileMetaInfoAddress', 'actualSituationType'];

    let totalChecks = validKeys.length + 2;

    const arr = validKeys.filter(key => this.profile[key]);

    let completedChecks = arr.length + Number(entityCompleted);

    let docConfigs = this.tenantSettings?.['DOCUMENT_ENTITY_PROFILE']?.settingValue ?? {};

    const validDocKeys = Object.keys(docConfigs).filter(key => docConfigs[key] === 'MANDATORY');

    // start check for proof of address
    totalChecks++;

    const hasProofOfBankAccount = validDocKeys.includes('proofOfBankAccount');
    const hasBankDocuments = investorProfileMetaInfoBank?.referenceProofBankFileEntity?.documents;

    if (hasProofOfBankAccount && hasBankDocuments) {
      completedChecks++;
    } else if (!hasProofOfBankAccount && investorProfileMetaInfoBank) {
      completedChecks++;
    }
    // end check for proof of address

    if (investorProfileMetaInfoMemberList) {
      let docConfigs = this.tenantSettings?.['DOCUMENT_ENTITY_MEMBER']?.settingValue ?? {};

      const validDocKeys = Object.keys(docConfigs).filter(key => docConfigs[key] === 'MANDATORY');

      if (this.validateMembers(investorProfileMetaInfoMemberList, validDocKeys)) {
        completedChecks++;
      }
    }

    const progress = Math.floor((completedChecks / totalChecks) * 100);

    return progress;
  }

  validateMembers(members: EntityMember[], mandatoryKeys: string[]): boolean {
    // Helper function to validate a member's field presence
    const isFieldValid = (field: any): boolean => field !== null && field !== undefined;

    // Helper function to check for mandatory documents
    const hasMandatoryDocuments = (entity: any, documentPath: string): boolean => {
      return entity && isFieldValid(entity[documentPath]?.documents);
    };

    for (const member of members) {
      const {
        referenceInfoAddressEntity: address,
        referenceInfoCertificateEntity: certificate,
        referenceInvestorMetaIndividualEntity: { townOfBirth, jobPositionTitle, taxJurisdiction }
      } = member;

      // Validate basic member fields
      if (![townOfBirth, jobPositionTitle, taxJurisdiction].every(isFieldValid)) {
        return false;
      }

      // Additional validation for Controlling Person role
      if (
        member.memberRole === EntityMemberRole.ControllingPerson ||
        member.memberRole === EntityMemberRole.Director
      ) {
        if (![member.isUsPerson, member.percentageOwnership].every(isFieldValid)) {
          return false;
        }
      }

      // Validate mandatory keys for proof of address and identity
      if (
        (mandatoryKeys.includes('proofOfAddress') &&
          !hasMandatoryDocuments(address, 'referenceProofAddressFileEntity')) ||
        (mandatoryKeys.includes('proofOfIdentity') &&
          !hasMandatoryDocuments(certificate, 'referenceProofCertificateFileEntity'))
      ) {
        return false;
      }
    }

    return true;
  }

  getIndividualProgress() {
    const {
      investorProfileMetaIndividual,
      payMethod,
      investorProfileMetaInfoAddress,
      investorProfileMetaInfoBank,
      investorProfileMetaInfoCert,
      investorProfileMtCustodianData
    } = this.profile;
    const individualCompleted = investorProfileMetaIndividual
      ? (
          [
            'countryCodeOfBirth',
            'countryCodeOfResidence',
            'birthday',
            'email',
            'firstName',
            'lastName',
            'nationalityCode',
            'phoneNationalityCode',
            'phoneNumber'
          ] as (keyof Individual)[]
        ).every(key => investorProfileMetaIndividual[key]) &&
        !!(
          (investorProfileMetaIndividual.occupation &&
            investorProfileMetaIndividual.occupation !== 'other') ||
          investorProfileMetaIndividual.occupationComment
        )
      : false;

    const validKeys: (keyof Profile)[] = ['actualSituationType'];
    const docConfigs = this.tenantSettings?.['DOCUMENT_INDIVIDUAL_PROFILE']?.settingValue ?? {};
    const validDocKeys = Object.keys(docConfigs).filter(key => docConfigs[key] === 'MANDATORY');

    /**
     * investorProfileMetaIndividual
     * actualSituationType
     * investorProfileMetaInfoAddress
     * investorProfileMetaInfoCert
     * ira / bank
     */
    const totalChecks = 5;
    let completedChecks =
      validKeys.filter(key => this.profile[key]).length + Number(individualCompleted);

    if (payMethod === IPayMethodType.IRA) {
      if (investorProfileMtCustodianData) {
        completedChecks++;
      }
    }

    // bank
    if (payMethod === IPayMethodType.BANK) {
      completedChecks += Number(
        profileValidatorFn(investorProfileMetaInfoBank, 'bank', {
          documentRequired: validDocKeys.includes('proofOfBankAccount'),
          document: investorProfileMetaInfoBank?.referenceProofBankFileEntity?.documents
        })
      );
    }

    // address
    completedChecks += Number(
      profileValidatorFn(investorProfileMetaInfoAddress, 'address', {
        documentRequired: validDocKeys.includes('proofOfAddress'),
        document: investorProfileMetaInfoAddress?.referenceProofAddressFileEntity?.documents
      })
    );

    // identity
    completedChecks += Number(
      profileValidatorFn(investorProfileMetaInfoCert, 'identity', {
        documentRequired: validDocKeys.includes('proofOfIdentity'),
        document: investorProfileMetaInfoCert?.referenceProofCertificateFileEntity?.documents
      })
    );

    const progress = (completedChecks / totalChecks) * 100;

    return progress;
  }

  transformBankNumber(value: string, privacy?: boolean) {
    const groups = value.split('').reduce((acc: string[], cur) => {
      const lastStr = acc[acc.length - 1];

      if (lastStr?.length < 4) {
        acc[acc.length - 1] = lastStr + cur;
      } else {
        acc.push(cur);
      }

      return acc;
    }, []);

    if (privacy) {
      return [
        ...groups.slice(0, groups.length - 1).map(() => '****'),
        groups[groups.length - 1]
      ].join(' ');
    }

    return groups.join(' ');
  }

  getStructureTypeString(type?: EntityStructureType) {
    return (
      type
        ?.split('_')
        .map(v => v.charAt(0) + v.slice(1).toLowerCase())
        .join(' ') ?? ''
    );
  }

  translateCountryName(code?: string) {
    if (!code) {
      return '--';
    }

    return this.countryListSync.find(item => item.value === code)?.label;
  }

  openDeleteDialog() {
    this.dialog

      .open(this.deleteProfileDialogTpl, {
        width: '680px',
        autoFocus: false,
        maxWidth: '95vw'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.delete.emit(this.profile);
        }
      });
  }
}
