import { Component } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  template: ` <ng-container #fieldComponent></ng-container> `,
  styles: [
    `
      :host ::ng-deep .mat-form-field-underline {
        display: none;
      }
      @media screen and (max-width: 768px) {
        :host {
          ::ng-deep {
            .mat-form-field-infix {
              display: flex;
              flex-direction: column-reverse;
            }
            .mat-form-field-label-wrapper {
              position: static;
              overflow: visible;
              .mat-form-field-label {
                position: static;
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    `,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
  ],
})
export class TableWrapperComponent extends FieldWrapper {}
