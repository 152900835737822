import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
  pure: false,
})
export class CustomCurrencyPipe extends CurrencyPipe implements PipeTransform {
  override transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null;
  override transform(
    value: null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): null;
  override transform(
    value: number | string | null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (currencyCode === 'SGD') {
      const result = super.transform(
        value,
        currencyCode,
        (display = 'S$'),
        digitsInfo,
        locale
      );
      return result;
    } else if (currencyCode === 'AED') {
      const result = super.transform(
        value,
        currencyCode,
        (display = 'د.إ'),
        digitsInfo,
        locale
      );
      return result;
    } else {
      const result = super.transform(
        value,
        currencyCode,
        display,
        digitsInfo,
        locale
      );
      return result;
    }
  }
}
