import { CommonModule, formatDate } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Option, TypeConfig } from '../condition-builder.model';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { DateComponent } from './date/date.component';
import { SelectComponent } from './select/select.component';

@NgModule({
  declarations: [SelectComponent, AutocompleteComponent, DateComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    NzSelectModule,
    NzFormModule,
  ],
})
export class BuiltInTypeModule {}

const ArrayNotEmptyValidator: ValidatorFn = control =>
  (control.value ?? []).length > 0 ? null : { required: true };

export const BUILT_IN_TYPES: TypeConfig[] = [
  {
    component: SelectComponent,
    type: 'select',
    operators: [
      {
        label: 'matches any of',
        value: 'IN',
      },
      {
        label: 'does not match any of',
        value: 'NOT_IN',
      },
    ],
    formatter(config, operator, value) {
      const valueLabelMap = new Map(
        config.params?.['options']?.map(({ label, value }: Option) => [value, label]),
      );
      return `${config.name} ${this.operators.find(({ value }) => value === operator)
        ?.label} ${
        value
          ?.map((v: string) => `"${valueLabelMap.get(v)}"`)
          ?.join(operator === 'IN' ? ' or ' : ' and ') ?? ''
      }`;
    },
    validators: [ArrayNotEmptyValidator],
  },
  {
    component: AutocompleteComponent,
    type: 'autocomplete',
    operators: [
      {
        label: 'matches any of',
        value: 'IN',
      },
      {
        label: 'does not match any of',
        value: 'NOT_IN',
      },
    ],
    formatter(config, operator, value) {
      return `${config.name} ${this.operators.find(({ value }) => value === operator)
        ?.label} ${
        value?.map((v: string) => `"${v}"`)?.join(operator === 'IN' ? ' or ' : ' and ') ??
        ''
      }`;
    },
    validators: [ArrayNotEmptyValidator],
  },
  {
    component: DateComponent,
    type: 'date',
    operators: [
      {
        label: 'is between',
        value: 'BETWEEN',
      },
      {
        label: 'is not between',
        value: 'NOT_BETWEEN',
      },
    ],
    formatter(config, operator, value, locale) {
      return `${config.name} ${this.operators.find(({ value }) => value === operator)
        ?.label} ${(str => (str ? `"${str}"` : ''))(
        value?.map((v: Date) => formatDate(v, 'mediumDate', locale))?.join(' and '),
      )}`;
    },
    validators: [ArrayNotEmptyValidator],
  },
];
