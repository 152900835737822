import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@stream/ngx-utils';
import { pluck, shareReplay } from 'rxjs/operators';

import { of } from 'rxjs';
import { SignService } from '../../services';

@Component({
  selector: 'stream-seal-select-dialog',
  templateUrl: './seal-select-dialog.component.html',
  styleUrls: ['./seal-select-dialog.component.scss'],
})
export class SealSelectDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fullName: string;
      ticket: string;
      sealList: Array<any>
    },
    private signService: SignService,
    private dialogRef: MatDialogRef<SealSelectDialogComponent>,
    private notification: NotificationService,
  ) {}

  selectedSealId = '';

  sealList = this.data.sealList?.length ? of(this.data.sealList) : this.signService
    .getSealList(this.data.ticket)
    .pipe(pluck('data', 'responseData'), shareReplay(1));

  adopt() {
    if (!this.selectedSealId) {
      this.notification.error('Please select a seal');
    } else {
      this.dialogRef.close(this.selectedSealId);
    }
  }
}
