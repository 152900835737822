import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMessageItemInfoStatus } from '@stream/libs/common/chat/components/message-item/message-item.model';
import {
  ChatApi,
  ChatCategory,
  ChatCategoryLocation,
  ChatConversation,
  ChatConversationMessages,
  ChatConversationRecipient,
  ChatConversations,
  ChatFeature,
  ChatMessage,
  ChatMessageAttachment,
  ChatResponseEnum,
  ChatStoreKeyEnum,
  ChatSubscription,
  PrincipalTypeEnum,
  Restful
} from '@stream/models';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, EMPTY, Subject, of, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  repeatWhen,
  scan,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';

import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  opened = false;

  checkPermission = new Subject<void>();

  viewMode: 'LIST' | 'MESSAGE' = 'LIST';

  activeConversationId = '';

  user$ = this.accountService.userInfo;

  fileUploadApi = this.user$.pipe(
    pluck('investAccount'),
    map(account => `/tenant/${account.tenantId}/files`)
  );

  isInstitutional = this.user$.pipe(
    pluck('investPrincipals'),
    map(([principal]) => {
      const principalType = principal.principalType || principal.type;
      return principalType === PrincipalTypeEnum.Institutional;
    })
  );

  CacheAction$ = new BehaviorSubject<{
    type: 'INSERT_SENDING_MSG' | 'UPDATE_MSG' | 'REMOVE_MSG' | 'SYNC';
    conversationId?: string;
    data?: Partial<ChatMessage>;
  }>({ type: 'SYNC' });

  localUpdate$ = new Subject();

  CacheStore = this.CacheAction$.pipe(
    scan(
      (localData, action: any) => {
        const { type, data, conversationId } = action;
        if (type === 'SYNC') {
          const syncData = this.localStorage.retrieve(ChatStoreKeyEnum.Messages);
          try {
            const syncDataObj = JSON.parse(syncData) || {};
            return { ...syncDataObj, dirty: false };
          } catch (e) {
            return {};
          }
        }
        let dirty = false;
        let newLocalData: ChatMessage[] = localData[conversationId] || [];
        if (type === 'INSERT_SENDING_MSG') {
          newLocalData.push(data);
        } else {
          // check dirty to use local store
          const matchedMsg = newLocalData.find(msg => msg.id === data.id);
          if (
            (matchedMsg?.status === EMessageItemInfoStatus.sending && type === 'UPDATE_MSG') ||
            (matchedMsg?.status === EMessageItemInfoStatus.failed && type === 'REMOVE_MSG')
          ) {
            dirty = true;
          }
          if (type === 'UPDATE_MSG') {
            newLocalData = newLocalData.map(msg => {
              if (msg.id === data.id) {
                return { ...msg, ...data };
              }
              return msg;
            });
          } else if (type === 'REMOVE_MSG') {
            newLocalData = newLocalData.filter(msg => msg.id !== data?.id);
          }
        }
        return {
          ...localData,
          [conversationId]: newLocalData,
          dirty
        };
      },
      {} as { [conversationId: string]: ChatMessage[] } & { dirty?: boolean }
    ),
    shareReplay(1)
  );

  conversationDisabled = this.checkDisabledByTenantFeatures().pipe(
    switchMap(disabled => {
      if (disabled) return of(disabled);
      return this.checkDisabledByChatFeatures();
    }),
    repeatWhen(() => this.checkPermission),
    distinctUntilChanged(),
    shareReplay(1)
  );

  unReadCount = timer(0, 5000).pipe(
    takeUntil(this.conversationDisabled.pipe(filter(disabled => disabled))),
    filter(() => !this.opened),
    switchMap(() => {
      return this.getUnReadCount().pipe(catchError(() => EMPTY));
    }),
    repeatWhen(() => this.conversationDisabled.pipe(filter(disabled => !disabled))),
    shareReplay(1)
  );

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    this.CacheStore.pipe(
      filter(cache => !!cache.dirty),
      withLatestFrom(this.CacheAction$)
    ).subscribe(([{ dirty, ...data }]) => {
      this.localStorage.store(ChatStoreKeyEnum.Messages, JSON.stringify(data));
    });
  }

  closeMessagesView() {
    this.viewMode = 'LIST';
  }

  openMessagesView(id: string) {
    this.activeConversationId = id;
    this.viewMode = 'MESSAGE';
  }

  getUnReadCount() {
    return this.http.get<Restful<number>>(ChatApi.getChatUnreadCount, {}).pipe(pluck('data'));
  }

  getChatConversations(params: { current: number; size: number; search?: string }) {
    return this.http
      .get<Restful<ChatConversations>>(ChatApi.getChatConversations, {
        params
      })
      .pipe(pluck('data'));
  }

  getChatLocation(url: string) {
    if (url.startsWith('/product')) {
      return ChatCategoryLocation.DMP;
    }
    if (url.startsWith('/portfolio')) {
      return ChatCategoryLocation.Portfolio;
    }
    if (url.startsWith('/scenario')) {
      if (url.includes('FUND_DATA')) {
        return ChatCategoryLocation.DMP;
      }
      if (
        url.includes('INVESTING') ||
        url.includes('RESERVE') ||
        url.toLocaleLowerCase().includes('express_interest')
      ) {
        return ChatCategoryLocation.InvestmentJourney;
      }
    }
    return ChatCategoryLocation.EveryWhere;
  }

  getLocation() {
    return this.getChatLocation(this.router.routerState.snapshot.url);
  }

  getCategories() {
    const locations = this.getChatLocation(this.router.routerState.snapshot.url);
    return this.http
      .get<Restful<Array<ChatCategory>>>(ChatApi.getChatCategories, {
        params: {
          locations,
          preferredAsset: 'NOT_RELATED_TO_ANY_ASSETS'
        }
      })
      .pipe(pluck('data'));
  }

  getSubscriptions() {
    return this.http
      .get<Restful<Array<ChatSubscription>>>(ChatApi.getSubscriptions, {})
      .pipe(pluck('data'));
  }

  getChatConversationRecipientsNotExists(conversationId?: string) {
    return this.http
      .get<
        Restful<{
          data: Array<ChatConversationRecipient>;
          status: ChatResponseEnum;
        }>
      >(
        ChatApi.getChatConversationRecipientsNotExists,
        conversationId
          ? {
              params: {
                conversationId
              }
            }
          : {}
      )
      .pipe(pluck('data', 'data'));
  }

  checkRelatedChatConversation(subscriptionId: string, categoryId: string) {
    return this.user$.pipe(
      pluck('investAccount', 'id'),
      switchMap((accountId: string) => {
        return this.http
          .get<Restful<ChatConversation>>(ChatApi.chatConversation, {
            params: {
              subscriptionId,
              categoryId
            }
          })
          .pipe(
            pluck('data'),
            map(data => {
              if (data && data.conversation && data.conversation.id) {
                return {
                  conversationId: data.conversation.id,
                  accountId,
                  isRecipient:
                    !!data.conversation.id &&
                    Array.isArray(data.conversation.recipients) &&
                    data.conversation.recipients.some(r => r.recipientId === accountId)
                };
              } else {
                return {};
              }
            })
          );
      })
    );
  }

  createConversation(data: {
    categoryId: string;
    subject: string;
    bizObjectId?: string;
    recipientIds?: string[];
    message: {
      content?: string;
      attachments?: ChatMessageAttachment[];
    };
    assetId?: string;
  }) {
    return this.http
      .post<
        Restful<{
          status: ChatResponseEnum;
          conversationDto: ChatConversation['conversation'];
        }>
      >(ChatApi.chatConversation, {
        ...data,
        location: this.getChatLocation(this.router.routerState.snapshot.url)
      })
      .pipe(pluck('data'));
  }

  getCurrentConversation() {
    return this.http
      .get<Restful<ChatConversation>>(ChatApi.chatConversation, {
        params: {
          conversationId: this.activeConversationId
        }
      })
      .pipe(pluck('data'));
  }

  addChatConversationRecipients(conversationId: string, recipientId: string) {
    return this.http
      .put<
        Restful<{ status: ChatResponseEnum }>
      >(`${ChatApi.editChatConversationRecipients.replace(':conversationId', conversationId).replace(':recipientId', recipientId)}`, {})
      .pipe(pluck('data'));
  }

  getChatConversationRecipientsExists(conversationId: string) {
    return this.http
      .get<
        Restful<{
          data: Array<ChatConversationRecipient>;
          status: ChatResponseEnum;
        }>
      >(`${ChatApi.getChatConversationRecipientsExists.replace(':conversationId', conversationId)}`)
      .pipe(pluck('data', 'data'));
  }

  deleteChatConversationRecipients(conversationId: string, recipientId: string) {
    return this.http
      .delete<
        Restful<{ status: ChatResponseEnum }>
      >(`${ChatApi.editChatConversationRecipients.replace(':conversationId', conversationId).replace(':recipientId', recipientId)}`, {})
      .pipe(pluck('data'));
  }

  getCurrentConversationMessages(
    conversationId: string,
    params: { current: number; size: number }
  ) {
    return this.http
      .get<Restful<ChatConversationMessages>>(
        `${ChatApi.chatConversationMessages.replace(':conversationId', conversationId)}`,
        {
          params
        }
      )
      .pipe(pluck('data'));
  }

  sendChatConversationMessages(
    conversationId: string,
    data: {
      content?: string;
      attachments?: ChatMessageAttachment[];
    }
  ) {
    return this.http
      .post<
        Restful<{ status: ChatResponseEnum }>
      >(`${ChatApi.chatConversationMessages.replace(':conversationId', conversationId)}`, data)
      .pipe(pluck('data'));
  }

  getLastReadTime() {
    return this.http
      .get<
        Restful<{ status: ChatResponseEnum; lastReadTime: string }>
      >(ChatApi.chatConversationRecipientTime.replace(':conversationId', this.activeConversationId), {})
      .pipe(pluck('data'));
  }

  checkDisabledByChatFeatures() {
    return this.http.get<Restful<ChatFeature[]>>(ChatApi.getChatFeatures, {}).pipe(
      pluck('data'),
      map(data => {
        const chatFeature = data.find(feature => feature.featureKey === 'CHAT');
        if (!chatFeature) return true;
        return chatFeature.status === 'OFF';
      })
    );
  }

  checkDisabledByTenantFeatures() {
    return this.http
      .get<
        Restful<{ features: ChatFeature[]; status: ChatResponseEnum }>
      >(ChatApi.getTenantFeatures)
      .pipe(
        pluck('data'),
        map(data => {
          if (data.status === ChatResponseEnum.Success && data.features) {
            const conversationFeature = data.features.find(
              feature => feature.featureKey === 'CONVERSATION'
            );
            if (!conversationFeature) return true;
            return conversationFeature.status === 'OFF';
          } else {
            return true;
          }
        })
      );
  }
}
