<ng-container [formGroup]="form">
  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput autocomplete="given-name" formControlName="firstName" />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput autocomplete="family-name" formControlName="lastName" />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Previous Name(Optional)</mat-label>
    <input matInput autocomplete="additional-name" formControlName="previousName" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput autocomplete="email" type="email" formControlName="email" />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <div class="flex">
    <mat-form-field class="custom-small-form-width">
      <mat-label>Code</mat-label>
      <mat-select
        formControlName="phoneNationalityCode"
        #phoneCodeSelect
        [panelWidth]="300"
      >
        <mat-select-trigger>
          {{ getCodeByLabel($any(phoneCodeSelect.selected)?.viewValue) }}
        </mat-select-trigger>
        <mat-option *ngFor="let areaCode of areaCodeList" [value]="areaCode.countryCode">
          {{ areaCode.name + ' +' + areaCode.dialCode }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Phone</mat-label>
      <input
        matInput
        autocomplete="tel-national"
        type="tel"
        formControlName="phoneNumber"
      />
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Nationality</mat-label>
    <ngx-select-country
      formControlName="nationalityCode"
      [required]="true"
      [options]="countryList$"
    ></ngx-select-country>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label> Any other Nationality held（Optional）</mat-label>
    <ngx-select-country
      formControlName="anotherNationalityCode"
      [options]="countryListOptional$"
    ></ngx-select-country>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Country of birth</mat-label>
    <ngx-select-country
      formControlName="countryCodeOfBirth"
      [required]="true"
      [options]="countryList$"
    ></ngx-select-country>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Country of residence</mat-label>
    <ngx-select-country
      formControlName="countryCodeOfResidence"
      [required]="true"
      [options]="countryList$"
    ></ngx-select-country>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Date of birth (mm/dd/yyyy)</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      formControlName="birthday"
      autocomplete="bday"
      [max]="todayDate"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label> Occupation </mat-label>
    <mat-select
      formControlName="occupation"
      panelClass="max-w-none"
      (selectionChange)="changeValue($event)"
    >
      <mat-option *ngFor="let occupation of occupationList" [value]="occupation.key">
        {{ occupation.en }}
      </mat-option>
    </mat-select>
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="isOther">
    <mat-label></mat-label>
    <input
      formControlName="occupationComment"
      panelClass="max-w-none"
      type="text"
      placeholder="Placeholder"
      matInput
      autocomplete="occupationComment"
    />
    <mat-error ngx-mat-errors></mat-error>
  </mat-form-field>
</ng-container>
