import { enableProdMode } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing, init, instrumentAngularRouting } from '@sentry/angular-ivy';
import { deployingEnvironment } from '@stream/utils';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const envType = deployingEnvironment();

if (environment.production && !['DEV1', 'SIT1', 'DEV-USA', 'ENGINEER'].includes(envType)) {
  init({
    dsn: 'https://386d14c29f504abcabfd275aa386dadb@o1100041.ingest.sentry.io/4504377788858368',
    environment: envType,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
        routingInstrumentation: instrumentAngularRouting
      })
    ],
    ignoreErrors: [
      'Non-Error',
      'ChunkLoadError',
      /401/g,
      /0[ ]unknown[ ]error/gi,
      /illegal[ ]invocation/gi,
      'The token has expired or become invalid'
    ],
    tracesSampleRate: 0.3
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    window.console.error(err);
    handleTenantNotExist(err);
  });

function handleTenantNotExist(err: any) {
  if (err.status === 404 && err.statusText === 'No Tenant') {
    const content = document.body.appendChild(document.createElement('div'));
    content.className = 'flex flex-col md:flex-row w-full h-full items-center justify-center';
    content.innerHTML = `
      <div class="mr-0 md:mr-24 text-center md:text-left">
      <h1 style='font-size:160px;line-height:180px;'>404</h1>
      <h2 class="text-3xl font-medium mb-12">
        The page you visited does not exist
      </h2>
    </div>
    <img src="./assets/images/404.png" class="w-full max-w-md" />
        `;
  }
}

const original = DefaultValueAccessor.prototype.registerOnChange;

DefaultValueAccessor.prototype.registerOnChange = function (fn) {
  return original.call(this, value => fn(typeof value === 'string' ? value.trim() : value));
};

// eslint-disable-next-line
// @ts-ignore
window[environment.biteDebugPackageInfoKey] = require('package.json');
