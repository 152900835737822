<div class="relative h-14 w-full text-[#07133A]" mat-dialog-title>
  <span class="inline-block w-full truncate pr-10 text-xl font-semibold leading-10">
    {{ data.title }}
  </span>
  <button class="!absolute right-4" mat-icon-button color="basic" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="mb-4 w-full" *ngFor="let item of mds || []; let i = index">
    <stream-mandatory-doc-item
      [form]="item.form"
      [md]="item"
      [config]="data"
    ></stream-mandatory-doc-item>
  </div>
</div>

<div mat-dialog-actions class="flex w-full items-center justify-between sm:justify-end">
  <button class="w-[148px]" mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    class="w-[148px]"
    mat-flat-button
    color="primary"
    [disabled]="!confirmEnabled"
    (click)="onConfirm()"
  >
    Confirm
  </button>
</div>
