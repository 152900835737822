import { Component, Input } from '@angular/core';
import { ChatSubscription, SUBSCRIPTION_STEP_STATUS_LABEL, SubscriptionStepStatus } from '@stream/models';

@Component({
  selector: 'stream-chat-fund-info',
  templateUrl: './chat-fund-info.component.html',
  styleUrls: ['./chat-fund-info.component.scss'],
})
export class ChatFundInfoComponent {
  constructor() {}

  @Input()
  subscription!: ChatSubscription;

  getFundStatusText(key: ChatSubscription['status']): string {
    return SUBSCRIPTION_STEP_STATUS_LABEL[key as SubscriptionStepStatus] || key;
  }
}
