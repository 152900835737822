import { NgModule } from "@angular/core";
import { CountriesPipe } from "./countries.pipe";
import { CountriesService } from "./countries.service";

@NgModule({
  declarations: [CountriesPipe],
  imports: [],
  providers: [CountriesService],
  exports: [CountriesPipe]
})
export class CountriesModule {}