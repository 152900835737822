import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { BUILT_IN_TYPES, BuiltInTypeModule } from './built-in-type/built-in-type.module';
import { AddExpressionOperatorComponent } from './condition-builder-nav/add-expression-operator/add-expression-operator.component';
import { ConditionBuilderNavComponent } from './condition-builder-nav/condition-builder-nav.component';
import { ConditionBuilderComponent } from './condition-builder.component';
import { TYPE_CONFIG_MAP, TypeConfig } from './condition-builder.model';
import { ExpressionEditorComponent } from './expression-list/expression-editor/expression-editor.component';
import { ValueSelectorDirective } from './expression-list/expression-editor/value-selector.directive';
import { ExpressionListComponent } from './expression-list/expression-list.component';
import { LogicPanelComponent } from './logic-panel/logic-panel.component';

const nzModules = [
  NzButtonModule,
  NzDropDownModule,
  NzSegmentedModule,
  NzIconModule,
  NzMenuModule,
  NzInputModule,
  NzSelectModule,
  NzToolTipModule,
  NzFormModule,
];

@NgModule({
  declarations: [
    ConditionBuilderComponent,
    ConditionBuilderNavComponent,
    AddExpressionOperatorComponent,
    ExpressionListComponent,
    ExpressionEditorComponent,
    ValueSelectorDirective,
    LogicPanelComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...nzModules,
    BuiltInTypeModule,
  ],
  exports: [ConditionBuilderComponent],
})
export class ConditionBuilderModule {
  static forRoot(configs: TypeConfig[]): ModuleWithProviders<ConditionBuilderModule> {
    return {
      ngModule: ConditionBuilderModule,
      providers: [
        {
          provide: TYPE_CONFIG_MAP,
          useValue: new Map(
            BUILT_IN_TYPES.concat(configs).map(config => [config.type, config]),
          ),
        },
      ],
    };
  }
}
