import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { ChatApi } from '@stream/libs/common/models/src';
import { environment } from 'src/environments/environment';
import { deployingEnvironment } from './system';

const ignoreApi = [ChatApi.getChatUnreadCount];

const GrafanaConfig: Record<string, { url: string; name: string }> = {
  SIT1: {
    url: 'https://faro-collector-prod-eu-west-0.grafana.net/collect/9f76ad5027ea7c57991aa9c6972b5a81',
    name: 'Stream Portal - sit1'
  },
  ENGINEER: {
    url: 'https://faro-collector-prod-eu-west-0.grafana.net/collect/2124df0d9135bd549b72825cc57ebaa3',
    name: 'Stream Portal - dev - engr'
  }
};

export const faroInitializer: Function = () => {
  const env = deployingEnvironment();
  const config = GrafanaConfig[env];

  if (!config) {
    return () => {};
  }

  return async () => {
    if (environment.production) {
      initializeFaro({
        url: config.url,
        app: {
          name: config.name,
          version: '1.0.0',
          environment: env
        },

        sessionTracking: {
          samplingRate: 0.6
        },

        instrumentations: [
          // Mandatory, omits default instrumentations otherwise.
          ...getWebInstrumentations(),

          // Tracing package to get end-to-end visibility for HTTP requests.
          new TracingInstrumentation()
        ],

        beforeSend(event: any) {
          if (event.type === 'event' && event.payload) {
            const url_name: string | undefined =
              event.payload.attributes.name || event.payload.attributes['http.url'];

            for (let i = 0; i < ignoreApi.length; i++) {
              if (url_name?.endsWith(ignoreApi[i])) {
                return null;
              }
            }
          }

          return event;
        }
      });
    }
  };
};
