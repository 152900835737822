import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';

import { DrawerSelectComponent } from './drawer-select.component';

const matModules = [
  MatSidenavModule,
  MatRadioModule,
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  declarations: [DrawerSelectComponent],
  imports: [CommonModule, ...matModules],
  exports: [DrawerSelectComponent],
})
export class DrawerSelectModule {}
