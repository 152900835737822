import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'stream-quill-view',
  template: `
    <quill-view
      [content]="content"
      theme="snow"
      format="json"
      [ngClass]="{ 'pure-viewer': pureViewer }"
    ></quill-view>
  `,
  styles: [
    `
      @import '~quill/dist/quill.core.css';
      @import '~quill/dist/quill.bubble.css';
      @import '~quill/dist/quill.snow.css';
      :host {
        .ql-container {
          border: none !important;
          font-size: 1rem !important;
          &::-webkit-scrollbar {
            display: none;
          }
        }

        .ql-editor {
          padding: 0;
          line-height: 1.5;
        }
        .ql-video {
          width: 100%;
          height: 500px;
        }

        .pure-viewer > .ql-container {
          overflow: auto;
          @media (max-width: 768px) {
            max-height: 400px;
          }
          @media (min-width: 768px) {
            max-height: 600px;
          }
        }
      }
    `
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})

//! custome Stream checkbox using this component to display the content of the quill editor
export class QuillViewComponent {
  @Input()
  content?: string;

  // If true, the viewer will be displayed in a pure view mode, responsive height
  @Input()
  pureViewer = false;
}
