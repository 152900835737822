import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ADD_MANDATORY_DOC_RES_MSG,
  AddMandatoryDocumentResponse,
  AdditionalDocument,
  MandatoryDocument,
  Store,
  policyType
} from '@stream/models';
import { FilePreviewerComponent, NotificationService } from '@stream/ngx-utils';
import { SharedService } from '@stream/shared';
import { getIconByType } from '@stream/utils';
import { finalize, map } from 'rxjs/operators';
import { MandatoryDocService } from '../../../mandatory-doc';

import { ProfileSettingService } from '../../services';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'stream-additional-document-form',
  templateUrl: './additional-document-form.component.html',
  styleUrls: ['./additional-document-form.component.scss']
})
export class AdditionalDocumentFormComponent implements OnInit {
  @Input() profileId: string = '';

  @Input() type: 'ADDTIONAL_DOCUMENTS' | 'SUPPORTING_DOCUMENTS' = 'ADDTIONAL_DOCUMENTS';

  documents: AdditionalDocument[] = [];
  submitting = false;
  loading = false;
  store = new Store({ type: 'session', namespace: 'scenario' });

  constructor(
    private profileService: ProfileService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    // private mdDocService: MdDocService,
    private notification: NotificationService,
    private mandatoryDocService: MandatoryDocService,
    private sharedService: SharedService,
    private profileSettingService: ProfileSettingService
  ) {}

  get title() {
    return this.type === 'ADDTIONAL_DOCUMENTS' ? 'Additional documents' : 'Supporting documents';
  }

  getIconByType = getIconByType;

  tooltipAdditionalDocuments: string = '';

  hidden$ = this.profileSettingService.tenantProfileSettings$.pipe(
    map(configs => {
      const settingKey =
        this.type === 'ADDTIONAL_DOCUMENTS'
          ? 'DOCUMENT_INDIVIDUAL_PROFILE'
          : 'DOCUMENT_ENTITY_PROFILE';
      const config = configs[settingKey];
      const sectionKey =
        this.type === 'ADDTIONAL_DOCUMENTS' ? 'additionalDocuments' : 'supportingDocuments';

      return config?.settingValue[sectionKey] === 'HIDDEN';
    })
  );

  getDocumentTagLabel = (v: AdditionalDocument) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  ngOnInit(): void {
    this.getAdditionalDocuments();
    this.getTooltipRoleinTheEntity();
  }

  getTooltipRoleinTheEntity() {
    this.profileService
      .GetPolicyByType(
        this.type === 'ADDTIONAL_DOCUMENTS'
          ? policyType.ADDITIONAL_DOCUMENTS
          : policyType.ENTITY_CERTIFIED_SUPPORT
      )
      .subscribe(({ data }) => {
        if (data?.gpPolicy) {
          this.tooltipAdditionalDocuments = data?.gpPolicy.policyContext;
        }
      });
  }

  // if has snapshot, get document from profile snapshot
  getAdditionalDocuments() {
    const profile = this.store.get('profile');

    if (profile) {
      const { investorProfileMetaAdditionalDocument = [] } = profile;
      this.documents = investorProfileMetaAdditionalDocument;
    } else {
      this.loading = true;
      this.profileService
        .getAdditionalDocument({ profileId: this.profileId, custom: true })
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(res => {
          const { data } = res;
          this.documents = data || [];
        });
    }
  }

  /**
   * @description delete additional document
   * @param {AdditionalDocument} doc
   */
  deleteFile(doc: AdditionalDocument) {
    this.loading = true;
    this.profileService
      .deleteAdditionalDocument({
        profileId: this.profileId,
        documentId: doc.id
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(({ data }) => {
        if (data.status === 'SUCCESS') {
          this.documents = this.documents.filter(d => d.id !== doc.id);
        } else {
          this.notification.error('Failed to delete document');
        }
      });
  }

  preview(doc: AdditionalDocument) {
    if (!doc) {
      return;
    }
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.dialog.open(FilePreviewerComponent, {
      data: {
        type: doc.fileType,
        url: doc.fileUrl,
        downloadUrl: doc.downloadUrl,
        name: doc.fileName
      },
      maxWidth: isMedium ? '960px' : '90vw',
      maxHeight: '100vh',
      width: '100%'
    });
  }

  // when mandatoryDoc dialog closed, if has snapshot, save to snapshot
  openMdSelector() {
    const dialog = this.mandatoryDocService.open({
      mandatoryDocs: [
        {
          documentName: this.title,
          files: [],
          isMandatory: true,
          multiple: true
        }
      ] as any,
      allowReuse: false,
      profileId: this.profileId
    });
    dialog.afterClosed().subscribe(result => {
      if (!(result && result[0])) {
        return;
      }

      const profile = this.store.get('profile');
      const v = result[0];

      if (v?.files?.[0]) {
        if (profile) {
          const { investorProfileMetaAdditionalDocument = [] } = profile;
          const file = v.files?.[0];
          file.fileName = file.name;
          file.fileType = file.type;
          file.fileUrl = file.url;
          profile.investorProfileMetaAdditionalDocument = [
            ...investorProfileMetaAdditionalDocument,
            file
          ];
          this.store.set('profile', profile);
          this.getAdditionalDocuments();
        } else {
          this.addNewMandatoryDocuments(v.files[0]);
        }
      }
    });
  }

  addNewMandatoryDocuments(md: MandatoryDocument) {
    this.submitting = true;
    this.profileService
      .addNewMandatoryDocument(this.profileId, {
        files: [md]
      })
      .pipe(
        finalize(() => (this.submitting = false)),
        map(res => res.data)
      )
      .subscribe(data => {
        if (data.status === AddMandatoryDocumentResponse.success) {
          this.getAdditionalDocuments();
        } else {
          this.notification.error(ADD_MANDATORY_DOC_RES_MSG[data.status] || '');
        }
      });
  }
}
