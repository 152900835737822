<div
  class="fixed right-[9vw] bottom-[63px] w-7/12"
  *ngIf="showNotification && (wmCandidate$ | async) !== true"
>
  <stream-invite-member-notification [mobileMode]="true"></stream-invite-member-notification>
</div>
<mat-toolbar class="bottom-toolbar">
  <a
    *ngFor="let menu of menuList"
    [routerLink]="menu.path"
    routerLinkActive="text-primary-500 active-route"
    class="menu-item"
  >
    <div
      [matBadge]="((inboxMessageCount$ | async) ?? 0) > 9 ? '9+' : (inboxMessageCount$ | async)"
      matBadgeColor="warn"
      [matBadgeHidden]="menu.label !== 'Inbox' || (inboxMessageCount$ | async) === 0"
      matBadgeSize="small"
    >
      <svg class="icon menu-icon" aria-hidden="true">
        <use [attr.xlink:href]="menu.icon"></use>
      </svg>
    </div>

    {{ menu.label }}
  </a>
</mat-toolbar>
