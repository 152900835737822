import { DataRoomDocument } from '.';

export interface MarketingFAQ {
  answer: string;
  id: string;
  marketingFaqId: string;
  orderIndex: number;
  question: string;
}

export interface MarketingText {
  content: string;
  id: string;
  marketingId: string;
  orderIndex: number;
}

export interface MarketingDataRoom {
  dataRoomDocuments: DataRoomDocument[];
  marketingId: Marketing['id'];
  orderIndex: number;
  tenantId: string;
  title: string;
  id: string;
}

export interface Marketing {
  id: string;
  marketingFAQVOS: {
    faqs: MarketingFAQ[];
    id: string;
    marketingId: string;
    orderIndex: number;
  }[];
  marketingTexts: MarketingText[];
  marketingDataRoomVOS: MarketingDataRoom[];
  orderIndex: number;
  productId: string;
  tenantId: string;
  title: string;
}
