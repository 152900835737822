import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActualSituationType,
  Address,
  Bank,
  EntityStructureType,
  Profile,
  ProfileType,
  Store,
  policyType
} from '@stream/models';
import { DrawerSelectComponent } from '@stream/ngx-utils';
import { SharedService } from '@stream/shared';
import { getFormValidValues, objectFilterNull, transformDateToStr } from '@stream/utils';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  pluck,
  skip,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { PROFILE_CONFIG, ProfileConfig } from '../../profile.type';
import { ProfileSettingService } from '../../services';
import { ProfilePanelService } from '../../services/profile-panel.service';
import { ProfileService } from '../../services/profile.service';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { BankDialogComponent } from '../bank-dialog/bank-dialog.component';
import { EntityInfoFormComponent } from '../entity-info-form/entity-info-form.component';

@Component({
  selector: 'stream-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss']
})
export class EntityFormComponent implements OnInit, OnDestroy {
  constructor(
    private fb: UntypedFormBuilder,
    public panelService: ProfilePanelService,
    private dialog: MatDialog,
    private profileService: ProfileService,
    public location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private profileSettingService: ProfileSettingService,
    private breakpointObserver: BreakpointObserver,
    @Inject(PROFILE_CONFIG) public config: ProfileConfig
  ) {}
  @Input() tenantId = '';
  @ViewChild('addressSelect')
  addressSelect?: DrawerSelectComponent<Address>;

  @ViewChild('bankSelect')
  bankSelect?: DrawerSelectComponent<Bank>;

  @ViewChild('infoForm')
  infoForm?: EntityInfoFormComponent;

  dialogSub = new Subscription();

  EntityStructureType = EntityStructureType;

  ActualSituationType = ActualSituationType;

  isEdit = this.panelService.isEdit.value;

  profileId = new BehaviorSubject<string | null>(null);

  optionalEntityId = new BehaviorSubject<string | undefined>(undefined);

  countryList = this.sharedService.countryList;
  countryListSync: Array<{ label: string; value: string }> = [];

  store = new Store({ type: 'session', namespace: 'scenario' });

  reModify = false;

  additionalDocument: any[] = [];

  tooltipInfoOwnerDirectorTrustedBeneficiary: string | undefined;

  entityId: Observable<string> = this.optionalEntityId.pipe(
    switchMap(id =>
      id
        ? of(id)
        : this.saveEntityProfile().pipe(
            map(
              ({ productSubscriptionProfile: { referenceProfileMetaId } }) => referenceProfileMetaId
            ),
            tap(entityId => {
              this.optionalEntityId.next(entityId);
            })
          )
    )
  );

  form = this.fb.group({
    structureType: [EntityStructureType.SingleInvestors, Validators.required],
    address: [null, Validators.required],
    bank: [null, Validators.required],
    actualSituationType: [null, Validators.required]
  });

  addressList = this.optionalEntityId.pipe(
    filter(id => !!id),
    switchMap(entityId =>
      this.profileService
        .getAddressList({ entityId })
        .pipe(pluck('data', 'investorProfileMetaInfoAddressList'))
    )
  );

  bankList = this.optionalEntityId.pipe(
    filter(id => !!id),
    switchMap(entityId =>
      this.profileService
        .getBankList(entityId)
        .pipe(pluck('data', 'investorProfileMetaInfoBankList'))
    )
  );

  autoSave = this.form.valueChanges.pipe(skip(2), debounceTime(2000)).subscribe(() => {
    this.saveEntityProfile().subscribe();
  });

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.checkFileFromValid();
    });
    const snapshot = this.store.get('snapshot');

    // 设置 profile 会话
    if (snapshot) {
      this.store.set('profile', snapshot.profile);
    }

    if (snapshot && snapshot.profile) {
      this.initProfileItem();
      this.optionalEntityId.next(snapshot.profile.investorProfileMetaEntity?.id);
    } else {
      this.panelService.profile
        .pipe(
          filter(v => !!v),
          take(1)
        )
        .subscribe(profile => {
          if (profile) {
            this.patchForm(profile);
            this.profileId.next((profile as Profile).id);
            this.optionalEntityId.next(profile.investorProfileMetaEntity?.id);
          }
        });
    }

    this.countryList.subscribe(countries => {
      this.countryListSync = (countries || []).map(item => ({
        ...item,
        label: item.countryName,
        value: item.countryAlpha2Code
      }));
    });

    this.getTooltipInfoOwnerDirectorTrustedBeneficiary();
  }

  getTooltipInfoOwnerDirectorTrustedBeneficiary() {
    this.profileService
      .GetPolicyByType(policyType.OWNER_DIRECTOR_TRUSTEE_BENEFICIARY)
      .subscribe(({ data }) => {
        if (data?.gpPolicy) {
          this.tooltipInfoOwnerDirectorTrustedBeneficiary = data?.gpPolicy.policyContext;
        }
      });
  }

  patchForm(profile: Profile) {
    this.form.patchValue(
      {
        structureType: profile?.structureType,
        address: profile?.investorProfileMetaInfoAddress,
        bank: profile?.investorProfileMetaInfoBank,
        actualSituationType: profile?.actualSituationType
      },
      { emitEvent: false }
    );
    this.checkFileFromValid();
  }

  checkFileFromValid() {
    let investorProfileMetaInfoBank = this.form.get('bank')?.value;

    // check profile has all mandatory documents
    this.profileSettingService.tenantProfileSettings$
      .pipe(take(1))
      .pipe(
        filter(configs => !!configs),
        tap(configs => {
          const config = configs['DOCUMENT_ENTITY_PROFILE']?.settingValue;
          const validDocKeys = Object.keys(config).filter(
            key => (config as any)[key] === 'MANDATORY'
          );

          if (validDocKeys.includes('proofOfBankAccount') && investorProfileMetaInfoBank) {
            if (
              !(
                investorProfileMetaInfoBank &&
                investorProfileMetaInfoBank.referenceProofBankFileEntity?.documents
              )
            ) {
              // mark this form value as invalid
              setTimeout(() => {
                this.form.get('bank')?.setErrors({ required: true });
              });
            }
          }
        })
      )
      .subscribe();
  }

  initProfileItem() {
    const profile = this.store.get('profile');

    if (profile) {
      this.reModify = true;
      this.patchForm(profile);
      this.addressList = of([profile.investorProfileMetaInfoAddress]);
      this.bankList = of([profile.investorProfileMetaInfoBank]);
      this.profileId.next((profile as Profile).id);
    }
    this.checkFileFromValid();
  }

  ngOnDestroy(): void {
    this.autoSave.unsubscribe();
  }

  openAddress(address?: Address) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.entityId.subscribe(entityId => {
      const dialogRef = this.dialog.open(AddressDialogComponent, {
        data: {
          investorProfileType: ProfileType.Entity,
          address,
          referenceInvestorProfileMetaEntityId: entityId,
          reModify: this.reModify,
          profileId: this.profileId.value
        },
        panelClass: 'full-screen-dialog',

        width: !isMedium ? '100vw' : 'auto',
        maxWidth: !isMedium ? '100vw' : 'auto',
        height: !isMedium ? '100vh' : 'auto',
        maxHeight: !isMedium ? '100vh' : 'auto'
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          const profile = this.store.get('profile');

          if (profile) {
            const { investorProfileMetaInfoAddress: options } = profile;
            this.addressSelect?.refreshOptions(false, [options]);
            this.initProfileItem();
          } else {
            this.addressSelect?.refreshOptions();
          }
        }
      });
    });
  }

  translateCountryName(code: string) {
    if (!code) {
      return '--';
    }
    return this.countryListSync.find(item => item.value === code)?.label;
  }

  openBank(bank?: Bank) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.entityId.subscribe(entityId => {
      const dialogRef = this.dialog.open(BankDialogComponent, {
        data: {
          investorProfileType: ProfileType.Entity,
          bank,
          referenceInvestorProfileMetaEntityId: entityId,
          tenantId: this.tenantId,
          reModify: this.reModify,
          profileId: this.profileId.value
        },
        panelClass: 'full-screen-dialog',
        ...(!isMedium
          ? {
              width: '100vw',
              maxWidth: '100vw',
              height: '100vh',
              maxHeight: '100vh'
            }
          : {
              width: 'auto',
              maxWidth: 'auto',
              height: 'auto',
              maxHeight: 'auto'
            })
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          const profile = this.store.get('profile');

          if (profile) {
            const { investorProfileMetaInfoBank: options } = profile;
            this.bankSelect?.refreshOptions(false, [options]);
            this.initProfileItem();
          } else {
            this.bankSelect?.refreshOptions();
            this.checkFileFromValid();
          }
        }
      });
    });
  }

  submit() {
    const snapshot = this.store.get('snapshot');
    this.saveEntityProfile().subscribe(() => {
      if (this.reModify && snapshot) {
        const profile = this.store.get('profile');
        snapshot.profile = profile;
        this.profileService.profileUpdate.next();
        this.store.set('snapshot', snapshot);
        this.store.remove('profile');
        this.goback();
      } else {
        this.goback();
      }
    });
  }

  goback() {
    if (this.config.source === 'GP') {
      this.panelService.back.next(null);
      this.panelService.profileId.next(null);
      this.panelService.profile.next(null);
    } else {
      this.location.back();
    }
  }

  saveEntityProfile() {
    const { value } = this.form;
    const info = getFormValidValues(this.infoForm?.form as FormGroup);
    const profile = this.store.get('profile');

    // !!! can't understand this code, temporary don't delete
    if (
      Object.keys(info).indexOf('entityType') > -1 &&
      Object.keys(info).indexOf('entityTypeComment') > -1
    ) {
      Reflect.deleteProperty(info, 'entityType');
    }

    const params: any = {
      profileType: ProfileType.Entity,
      profileId: this.profileId.value,
      ...objectFilterNull({
        ...info,
        incorporationDate: transformDateToStr(info['incorporationDate']),
        referenceProfileInfoAddressId: value.address?.id,
        referenceProfileInfoBankId: value.bank?.id,
        actualSituationType: value.actualSituationType,
        structureType: value.structureType
      })
    };

    // request change: update profile snapshot
    if (profile) {
      if (params.entityTypeComment) {
        params.entityType = 'other';
      }

      const investorProfileMetaEntity = (profile.investorProfileMetaEntity = {
        ...profile.investorProfileMetaEntity,
        ...params
      });

      const { entityType, entityTypeComment } = investorProfileMetaEntity;

      if (
        (entityType === 'other' && !params.entityTypeComment) ||
        (entityType && entityType !== 'other' && entityTypeComment)
      ) {
        delete investorProfileMetaEntity.entityTypeComment;
      }

      if (entityType === 'other' && entityTypeComment) {
        delete investorProfileMetaEntity.entityType;
      }

      profile.actualSituationType = value.actualSituationType;

      this.store.set('profile', profile);
      return of(profile);
    }

    this.panelService.saving.next(true);
    return this.profileService.patchProfile(params).pipe(
      pluck('data'),
      finalize(() => this.panelService.saving.next(false)),
      tap(({ productSubscriptionProfile }) => {
        this.form?.markAsPristine();
        this.panelService.isEdit.next(true);
        this.profileId.next(productSubscriptionProfile.id);
        this.location.replaceState(
          this.router
            .createUrlTree(
              [
                'edit',
                ProfileType.Entity.toLowerCase(),
                productSubscriptionProfile.id,
                this.tenantId
              ],
              {
                relativeTo: this.route.parent
              }
            )
            .toString()
        );
      })
    );
  }

  getDocTagLabel = (v: any) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  getAdditionalDocument() {
    this.profileService
      .getAdditionalDocument({
        profileId: this.profileId.value || '',
        custom: true
      })
      .subscribe(({ data }) => {
        const { profileInvestorDocumentMappingVOList, otherProfileInvestorDocumentMappingVOList } =
          data as any;
        this.additionalDocument = [
          ...profileInvestorDocumentMappingVOList,
          ...otherProfileInvestorDocumentMappingVOList
        ];
      });
  }
}
