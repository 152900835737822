import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormlyModule } from '@ngx-formly/core';
import { StreamFormlyType } from '@stream/models';
import { CustomCurrencyPipe } from '../pipes/currency.pipe';

import { AmountInputComponent } from './amount-input/amount-input.component';

const matModules = [
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
  MatIconModule,
];

@NgModule({
  declarations: [AmountInputComponent,CustomCurrencyPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...matModules,
    FormlyModule.forChild({
      types: [
        {
          name: StreamFormlyType.AMOUNT,
          component: AmountInputComponent,
        },
      ],
    }),
  ],
  exports:[CustomCurrencyPipe]
})
export class AmountModule {}
