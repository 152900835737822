import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

import { Config } from '../../condition-builder.model';

@Component({
  selector: 'stream-add-expression-operator',
  templateUrl: './add-expression-operator.component.html',
  styleUrls: ['./add-expression-operator.component.less'],
})
export class AddExpressionOperatorComponent {
  @Input()
  config!: Config;

  @Input()
  disabledItem = (key: string) => false;

  @Output()
  itemClick = new EventEmitter<string>();

  @Input()
  searchable = true;

  searchControl = new FormControl();

  groups$ = this.searchControl.valueChanges.pipe(
    startWith(''),
    map(keyword => {
      const reg = new RegExp(keyword, 'i');
      return this.config.children
        ?.filter(({ name }) => reg.test(name))
        .reduce(
          (acc, { group = '', ...value }) => (
            (acc[group] = (acc[group] ?? []).concat(value)), acc
          ),
          {} as Record<string, Config[]>,
        );
    }),
  );

  get groupDisabled() {
    return this.config.children?.every(({ key }) => this.disabledItem(key));
  }

  dropdownHideHandler() {
    setTimeout(() => {
      this.searchControl.setValue('');
    }, 200);
  }
}
