<ngx-form-progress
  [formGroup]="form"
  [loading]="panelService.loading | async"
  class="mb-8"
></ngx-form-progress>

<stream-quill-view
  class="flex-1 pb-4"
  [content]="(panelService.nodeData | async)?.data?.content"
></stream-quill-view>

<form class="form-statement" (ngSubmit)="submit()" [formGroup]="form" #ngForm="ngForm">
  <mat-checkbox
    [indeterminate]="false"
    color="accent"
    #checkbox
    formControlName="checkbox"
    ><span class="text-primary-500 text-sm">
      {{ (panelService.nodeData | async)?.data?.checkbox }}
    </span></mat-checkbox
  >

  <ng-template #footer>
    <button
      mat-flat-button
      color="primary"
      (click)="ngForm.ngSubmit.emit()"
      class="mat-button-lg w-full text-base font-semibold md:w-40"
      [disabled]="panelService.loading | async"
    >
      {{ ((isLastNode | async) ? 'Finish' : 'Next') | buttonName | async }}
    </button>
  </ng-template>
  <button type="submit" hidden></button>
</form>
