import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bankNumber',
})
export class BankNumberPipe implements PipeTransform {
  transform(value: string, privacy?: boolean) {
    const groups = value.split('').reduce((acc: string[], cur) => {
      const lastStr = acc[acc.length - 1];
      if (lastStr?.length < 4) {
        acc[acc.length - 1] = lastStr + cur;
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    if (privacy) {
      return [
        ...groups.slice(0, groups.length - 1).map(() => '****'),
        groups[groups.length - 1],
      ].join(' ');
    }
    return groups.join(' ');
  }
}
