import { Portal, TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  GatewayNode,
  IScenarioUserPayload,
  MandatoryDocFile,
  MandatoryDocument,
  NodeType,
  Opportunity,
  Profile,
  ShareClass,
  Store,
} from '@stream/models';
import { Observable } from 'rxjs';

import { ScenarioPanelService } from '../../services';

@Component({
  selector: 'stream-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss'],
})
export class ScenarioComponent implements OnDestroy, AfterContentChecked {
  constructor(
    public panelService: ScenarioPanelService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input()
  userPayload!: IScenarioUserPayload;

  @Input()
  httpHeaders?: any;

  @Input()
  shareClassObservable!: Observable<ShareClass[]>;

  @Input()
  opportunity$?: Observable<Opportunity | null>;

  @Input()
  mandatoryDocsObservable!: Observable<MandatoryDocument[]>;

  @Input()
  uploadMandatoryDoc!: (file: File) => Observable<MandatoryDocFile>;

  @Input()
  isAssist = false;

  @ViewChild('title')
  title?: TemplateRef<any>;

  @ViewChild('content')
  content?: TemplateRef<any>;

  @ViewChild('nodeContentContainer', { read: ViewContainerRef })
  nodeContent?: ViewContainerRef;

  @ViewChild('nodeRender')
  nodeRender?: TemplateRef<any>;

  @ViewChild('discardDialog')
  discardDialogRef!: TemplateRef<any>;

  @Output()
  profileEdit = new EventEmitter<Profile>();

  @Output()
  profileCreate = new EventEmitter<void>();

  @Output()
  commit = new EventEmitter<void | Partial<GatewayNode['data']>>();

  @Output()
  discard = new EventEmitter<void>();

  footerPortal?: Portal<any>;

  store = new Store({ type: 'session', namespace: 'scenario' });

  private nodeDataChange = this.panelService.nodeData.subscribe(() => {
    this.nodeContent?.clear();
    if (this.nodeRender) {
      this.nodeContent?.createEmbeddedView(this.nodeRender);
    }
  });

  private nodeStartChange = this.panelService.start.subscribe(() => {
    setTimeout(() => {
      this.nodeContent?.clear();
      if (this.nodeRender) {
        this.nodeContent?.createEmbeddedView(this.nodeRender);
      }
    }, 0);
  });

  private discard$ = this.panelService.discard.subscribe(res => {
    if (res?.skipDeleteSubscription) {
      this.discard.next();
      return;
    }
    const { subscriptionId } = this.panelService;

    this.dialog
      .open(this.discardDialogRef, {
        autoFocus: '[mat-flat-button]',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          // 修改流程
          if (subscriptionId) {
            this.panelService.modificationDiscard().subscribe(data => {
              if (data) {
                this.store.clear('scenario');
                this.discard.next();
              }
            });
            return;
          }

          // 正常投资
          this.panelService.discardInvest(true)?.subscribe(() => {
            this.discard.next();
          });
        }
      });
  });

  nodeType = NodeType;
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    this.nodeDataChange.unsubscribe();
    this.nodeStartChange.unsubscribe();
    this.discard$.unsubscribe();
  }
}

@Component({
  template: ``,
})
export class ScenarioBaseComponent implements AfterViewInit, OnDestroy {
  constructor(
    private _scenarioComponent: ScenarioComponent,
    private _vcr: ViewContainerRef,
  ) {}

  @ViewChild('footer')
  footerTemplate?: TemplateRef<any>;

  ngAfterViewInit(): void {
    if (this.footerTemplate) {
      this._scenarioComponent.footerPortal = new TemplatePortal(
        this.footerTemplate,
        this._vcr,
      );
    }
  }

  ngOnDestroy(): void {
    this._scenarioComponent.footerPortal = undefined;
  }
}
