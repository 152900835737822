import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Gateway,
  GatewayContract,
  GatewayNode,
  GatewayProgress,
  GatewaySubmitResponse,
  Marketing,
  Product,
  Profile,
  Restful,
  Scenario,
  ScenarioApi,
  ScenarioCustomFieldApi,
  ScenarioType,
  WithPrincipal,
} from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { objectFilterNull } from '@stream/utils';
import { BehaviorSubject } from 'rxjs';

import { ScenarioLateSignService } from './scenario-late-sign.service';
import { ScenarioPanelService } from './scenario-panel.service';

@Injectable({
  providedIn: 'root',
})
export class ScenarioService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    public notification: NotificationService,
    private scenarioLateSign: ScenarioLateSignService,
    private router: Router,
  ) {}

  panelServiceMap = new Map<string, ScenarioPanelService>();

  isScenarioDone = new BehaviorSubject(false);

  getPanelService(params: {
    type: ScenarioType;
    productId?: string;
    productTabId?: string;
    subscriptionId?: string;
    taskId?: string;
    // gp 端参数
    principalId?: string;
    source?: 'LP' | 'GP';
  }) {
    const { type, ...rest } = params;
    const { productId, productTabId, subscriptionId, taskId, source, principalId } = rest;
    const keyList = [type, productId, productTabId, subscriptionId, taskId];
    const key = (source === 'GP' ? keyList.concat([principalId]) : keyList)
      .filter(Boolean)
      .join('-');
    const panelService = new ScenarioPanelService(
      this,
      this.scenarioLateSign,
      this.router,
      type,
      this.dialog,
      rest,
    );

    // gp investing scenario don't cache
    if (source === 'GP') {
      return panelService;
    }

    if (!this.panelServiceMap.has(key)) {
      this.panelServiceMap.set(key, panelService);
    }

    return this.panelServiceMap.get(key) as ScenarioPanelService;
  }

  /**
   * 提交动态表单
   * @param actionFormGroupId 表单🆔
   * @param model 表单值
   * @param httpOptions http附加参数
   */
  submitActionForm<T>(
    actionFormGroupId: string,
    model: Record<string, unknown>,
    options: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      context?: HttpContext;
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | readonly (string | number | boolean)[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ) {
    return this.http.post<Restful<T & GatewaySubmitResponse>>(
      ScenarioApi.SubmitForm,
      {
        actionFormGroupId,
        formJsonArray: Object.entries(model)
          .filter(([, value]) => value !== null)
          .map(([key, value]) => ({
            key,
            value,
          })),
      },
      options,
    );
  }

  /**
   * 获取场景完成度
   * @param scenarioType 场景类型
   */
  getScenarioProgress(scenarioType: ScenarioType, extraParams: Record<string, any> = {}) {
    return this.http.post<
      Restful<{
        biteFlowGatewayList: GatewayProgress[];
        scenarioName: string;
        scenarioProgress: number;
        isScenarioDone: boolean;
        scenarioId: string;
        scenarioProgressResult: 'GATEWAY_NOT_EXIST' | 'BREAK';
        lateSigning: boolean;
        step?: number;
        canContinue?: boolean;
      }>
    >(ScenarioApi.GetScenarioProgress, {
      scenarioType,
      ...extraParams,
    });
  }

  /**
   * 获取场景配置
   * @param scenarioType 场景类型
   */
  getScenario(scenarioType: ScenarioType, extraParams: Record<string, any> = {}) {
    return this.http.post<
      Restful<{
        scenarioData: Scenario;
      }>
    >(ScenarioApi.GetScenario, {
      scenarioType,
      ...extraParams,
    });
  }

  /**
   * 获取节点配置
   * @param nodePath 节点路径
   */
  getNodeData(
    nodePath: {
      scenarioId: string;
      gatewayId: string;
      nodeId: string;
    },
    extraParams: Record<string, any> = {},
  ) {
    return this.http.get<Restful<{ data: GatewayNode }>>(ScenarioApi.GetNodeData, {
      params: { ...nodePath, ...objectFilterNull(extraParams) },
    });
  }

  saveNodeResult(
    nodePath: { scenarioId: string; gatewayId: string; nodeId: string },
    result: any,
    extraParams?: Record<string, any>,
  ) {
    return this.http.post(ScenarioApi.SaveNodeResult, {
      ...nodePath,
      ...extraParams,
      result,
    });
  }

  /**
   * 提交节点数据
   * @param nodePath 节点路径
   * @param result 节点数据
   */
  submitNodeResult(
    nodePath: { scenarioId: string; gatewayId: string; nodeId: string },
    result: any,
    extraParams?: Record<string, any>,
  ) {
    return this.http.post<Restful<{ result: string }>>(ScenarioApi.SubmitNodeResult, {
      ...nodePath,
      ...extraParams,
      result,
    });
  }

  discardInvest(productId: Product['id'], deleteSubscription = false) {
    return this.http.delete(`${ScenarioApi.DiscardInvest}/${productId}`, {
      params: {
        deleteSubscription,
      },
    });
  }

  deleteNodeResult(productId: Product['id'], scenarioType: ScenarioType) {
    return this.http.delete(`${ScenarioApi.DeleteNodeResult}/${productId}`, {
      params: {
        scenarioType,
      },
    });
  }

  getGatewayContracts(params: {
    gatewayIds: Gateway['gatewayId'][];
    profileId?: Profile['id'];
    productId?: Product['id'];
    marketingId?: Marketing['id'];
  }) {
    return this.http.post<
      Restful<{
        contracts: GatewayContract[];
      }>
    >(ScenarioApi.GetGatewayContracts, params);
  }

  syncCustomField(
    gatewayId: Gateway['gatewayId'],
    customFields: { customFieldKey: string; customFieldKeyDataValue?: string }[],
    profileId?: string,
  ) {
    return this.http.put(
      ScenarioCustomFieldApi.SyncCustomField,
      {
        customFields,
        gatewayId,
        profileId,
      },
      {
        context: new HttpContext().set(WithPrincipal, true),
      },
    );
  }

  previous(
    productId: string,
    params: {
      nodeId: string;
      gatewayId: string;
      scenarioId: string;
      principalId?: string;
      nodeResult: any;
    },
  ) {
    return this.http.delete<
      Restful<{
        deleteSuccess: boolean;
        deletedNodeId: string;
      }>
    >(`${ScenarioApi.previous}`.replace(':productId', productId), {
      body: params,
    });
  }

  queryProductCustodianStatus(params: { productId: string }) {
    const { productId } = params || {};
    return this.http.get<
      Restful<{
        assetMt: {
          productId: string;
          iraInvestmentEnabled: boolean;
          mtAssetStatus: 'Accepted' | 'ACCEPTED' | 'Unverified' | 'UNVERIFIED';
        };
      }>
    >(ScenarioApi.QueryProductCustodianStatus.replace(':productId', productId), {});
  }
}
