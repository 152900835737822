<svg
  width="110"
  height="132"
  viewBox="0 0 110 132"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14 8C14 3.6 17.6 0 22 0H84C88.4 0 92 3.6 92 8V80C92 84.4 88.4 88 84 88H22C17.6 88 14 84.4 14 80V8Z"
    fill="#adb5bd"
  />
  <path
    d="M75 23H31C29.9 23 29 22.1 29 21C29 19.9 29.9 19 31 19H75C76.1 19 77 19.9 77 21C77 22.1 76.1 23 75 23Z"
    fill="#F5F6FA"
  />
  <path
    d="M75 38H31C29.9 38 29 37.1 29 36C29 34.9 29.9 34 31 34H75C76.1 34 77 34.9 77 36C77 37.1 76.1 38 75 38Z"
    fill="#F5F6FA"
  />
  <path
    d="M48 54H31C29.9 54 29 53.1 29 52C29 50.9 29.9 50 31 50H48C49.1 50 50 50.9 50 52C50 53.1 49.1 54 48 54Z"
    fill="#F5F6FA"
  />
  <path
    d="M48 68H31C29.9 68 29 67.1 29 66C29 64.9 29.9 64 31 64H48C49.1 64 50 64.9 50 66C50 67.1 49.1 68 48 68Z"
    fill="#F5F6FA"
  />
  <g opacity="0.8" filter="url(#filter0_b_9071_56258)">
    <path
      d="M64.0639 47H105.936C107.014 47 108.048 47.4299 108.81 48.1952C109.572 48.9605 110 49.9985 110 51.0807V76.9111C110 77.9934 109.572 79.0313 108.81 79.7966C108.048 80.5619 107.014 80.9918 105.936 80.9918H103.096C102.1 80.9918 101.145 81.3891 100.44 82.0963C99.7361 82.8035 99.3405 83.7626 99.3405 84.7627V86.2903C99.3413 86.4155 99.3089 86.5387 99.2466 86.6472C99.1843 86.7556 99.0944 86.8455 98.986 86.9075C98.8777 86.9695 98.7549 87.0014 98.6302 87C98.5055 86.9985 98.3835 86.9638 98.2766 86.8993L89.5426 81.558C88.953 81.1977 88.2753 81.0091 87.5851 81.0132H64.0745C63.5382 81.0146 63.0068 80.9094 62.5112 80.7036C62.0155 80.4978 61.5653 80.1955 61.1865 79.8142C60.8078 79.4329 60.5079 78.98 60.3043 78.4817C60.1006 77.9835 59.9972 77.4497 60.0001 76.9111V51.038C60.0113 49.9631 60.4444 48.9361 61.2053 48.1801C61.9662 47.424 62.9934 46.9999 64.0639 47Z"
      fill="#343434"
    />
  </g>
  <path
    d="M91 67.5002C88.8 67.5002 86.8 66.8002 85.6 65.7002C83.7 63.8002 81.7 63.0002 79.8 63.1002C77 63.2002 75 65.5002 75 65.5002C74.6 66.0002 73.8 66.1002 73.2 65.7002C72.6 65.3002 72.6 64.5002 73 64.0002C73.1 63.9002 75.7 60.8002 79.6 60.6002C82.2 60.4002 84.8 61.6002 87.3 63.9002C88.1 64.6002 89.9 65.2002 92 65.0002C93.1 64.9002 95.2 64.3002 96.4 62.4002C96.8 61.8002 97.5 61.6002 98.1 62.0002C98.7 62.4002 98.9 63.1002 98.5 63.7002C97.2 65.8002 95 67.1002 92.3 67.4002C91.9 67.4002 91.5 67.5002 91 67.5002Z"
    fill="white"
  />
  <g filter="url(#filter1_f_9071_56258)">
    <ellipse cx="54" cy="112" rx="40" ry="6" fill="#adb5bd" />
  </g>
  <defs>
    <filter
      id="filter0_b_9071_56258"
      x="-44"
      y="-57"
      width="258"
      height="248"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImage" stdDeviation="52" />
      <feComposite
        in2="SourceAlpha"
        operator="in"
        result="effect1_backgroundBlur_9071_56258"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_backgroundBlur_9071_56258"
        result="shape"
      />
    </filter>
    <filter
      id="filter1_f_9071_56258"
      x="0"
      y="92"
      width="108"
      height="40"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="7" result="effect1_foregroundBlur_9071_56258" />
    </filter>
  </defs>
</svg>

<p class="max-w-3xl text-center text-gray-600 text-base">
  {{ (fundamental | async)?.getStartMessage?.en }}
</p>

<button
  mat-flat-button
  color="primary"
  class="text-base !px-16 font-semibold mat-button-lg"
  (click)="start()"
>
  {{ (fundamental | async)?.getStartButtonTitle?.en ?? 'Start' }}
</button>
