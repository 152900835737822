import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { take } from 'rxjs/operators';

import { SCENARIO_CONFIG, ScenarioConfig } from '../../scenario.type';
import { ScenarioPanelService } from '../../services/scenario-panel.service';
import { ScenarioBaseComponent, ScenarioComponent } from '../scenario/scenario.component';

@Component({
  selector: 'stream-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss'],
})
export class StatementComponent extends ScenarioBaseComponent {
  constructor(
    public panelService: ScenarioPanelService,
    private fb: UntypedFormBuilder,
    scenarioComponent: ScenarioComponent,
    vcr: ViewContainerRef,
    @Inject(SCENARIO_CONFIG) private config: ScenarioConfig,
  ) {
    super(scenarioComponent, vcr);
  }

  @ViewChild('checkbox')
  checkbox?: MatCheckbox;

  form = this.fb.group({
    checkbox: [null, [Validators.requiredTrue]],
  });

  isLastNode = this.panelService.isLastNode.pipe(take(1));

  submit() {
    if (this.form.get('checkbox')?.invalid) {
      this.checkbox?.ripple?.launch({});
      return;
    }

    this.panelService.submitNodeResult(this.config).subscribe();
  }
}
