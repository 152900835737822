import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureItem } from '@stream/libs/common/models/src';
import { map } from 'rxjs';
import { Rest } from '../interface/http';
import { SystemApi } from '../interface/http.api';

@Injectable({
  providedIn: 'root'
})
export class LPSystemService {
  constructor(private http: HttpClient) {}

  getSystemFeatureFlags(params?: { includeSystemDefault?: boolean }) {
    return this.http
      .get<
        Rest<{ features: FeatureItem[]; status: string }>
      >(SystemApi.getTenantFeatures, { params })
      .pipe(map(res => res.data));
  }
}
