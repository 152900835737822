import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';

import { QuillViewComponent } from './quill-view.component';

@NgModule({
  declarations: [QuillViewComponent],
  imports: [CommonModule, QuillModule],
  exports: [QuillViewComponent],
})
export class QuillViewModule {}
