import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ScenarioPanelService } from '@stream/libs/common';
import { MandatoryDocFile, MandatoryDocument } from '@stream/models';
import { isQuillHasContent } from '@stream/utils';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { FormlyService } from '../../formly.service';

@Component({
  selector: 'stream-mandatory-docs',
  templateUrl: './mandatory-docs.component.html',
  styleUrls: ['./mandatory-docs.component.scss']
})
export class MandatoryDocsComponent
  extends FieldType<{ formControl: UntypedFormArray }>
  implements OnInit
{
  constructor(
    public formlyService: FormlyService,
    private fb: UntypedFormBuilder,
    private panelService: ScenarioPanelService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  get refresh() {
    return !!this.panelService.subscriptionId;
  }

  getDocLabelName = (ctr: AbstractControl) => {
    const { owner, documentName } = ctr.value || ({} as MandatoryDocument);
    return owner ? `${documentName} - ${owner.name}` : documentName;
  };

  showTooltip = (description: string) => isQuillHasContent(description);

  getDocumentTagLabel = (v: MandatoryDocFile) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  // TODO: Cannot assign to 'formControl' because it is a read-only property
  prePopulate(field: FormlyFieldConfig) {
    (field as any).formControl = new UntypedFormArray([]);
  }
  ngOnInit(): void {
    this.formlyService.mandatoryDocs$
      ?.pipe(
        filter(v => !!v),
        take(1),
        switchMap(v => v as Observable<MandatoryDocument[]>)
      )
      .subscribe(list => {
        (list || []).forEach(item => {
          const form = this.fb.group(
            Object.keys(item || {}).reduce((pre, key) => {
              return {
                ...pre,
                [key]: [item[key as keyof MandatoryDocument]]
              };
            }, {})
          );
          (this.formControl as UntypedFormArray).push(form);
        });
        this.cdr.detectChanges();
      });
  }
}
