import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
  constructor(private snackBar: MatSnackBar, private breakpointObserver: BreakpointObserver) {}

  get isMedium() {
    return this.breakpointObserver.isMatched('(min-width: 768px)');
  }

  /**
   * 成功消息提示
   * @param msg 提示信息
   */
  success(msg: string) {
    this.snackBar.open(msg, '', {
      panelClass: 'notification-success',
      verticalPosition: this.isMedium ? 'top' : 'bottom'
    });
  }
  /**
   * alert提示
   * @param msg 提示信息
   */
  alert(msg: string) {
    this.snackBar.open(msg, '', {
      panelClass: 'notification-alert',
      verticalPosition: this.isMedium ? 'top' : 'bottom'
    });
  }

  /**
   * info消息提示
   * @param msg 提示信息
   */
  info(msg: string) {
    this.snackBar.open(msg, '', {
      panelClass: 'notification-info',
      verticalPosition: this.isMedium ? 'top' : 'bottom'
    });
  }

  /**
   * 错误消息提示
   * @param msg 提示信息
   */
  error(msg: string) {
    this.snackBar.open(msg, '', {
      panelClass: 'notification-error',
      verticalPosition: this.isMedium ? 'top' : 'bottom'
    });
  }
}
