import { I18n } from '../common';

export const ENTITY_TYPE: Record<string, (I18n & { key: string })[]> = {
  US: [
    { en: 'Association for Nonprofit', key: 'anassociation', zh: '非营利性协会' },
    { en: 'Branch', key: 'branch', zh: '分支' },
    { en: 'C Corporation', key: 'c_corporation', zh: 'C型公司' },
    { en: 'Collective Ownership', key: 'collective', zh: '集体所有制' },
    { en: 'Company', key: 'company', zh: '公司' },
    { en: 'Consortium', key: 'consortium', zh: '联营企业' },
    { en: 'Estates', key: 'estates', zh: '房地产' },
    { en: 'Foreign-Invested Enterprise', key: 'foreign', zh: '外商投资企业' },
    { en: 'Fund of Funds', key: 'fof', zh: '基金' },
    { en: 'General Partnership', key: 'gp', zh: '普通合伙' },
    {
      en: 'Joint-Stock Co-operative Enterprise',
      key: 'joint_stock_co',
      zh: '股份合作企业',
    },
    { en: 'Joint-Stock Enterprise', key: 'joint_stock', zh: '股份制企业' },
    { en: 'LLC (Limited Liability Company)', key: 'llc', zh: '有限责任公司' },
    { en: 'LLP (Limited Liability Partnership)', key: 'llp', zh: '有限责任合伙' },
    { en: 'Limited Partnership', key: 'lp', zh: '有限合伙' },

    { en: 'Partnership', key: 'partnership', zh: '合伙公司' },
    {
      en: 'Partnership or Unincorporated Entity',
      key: 'partnership_ue',
      zh: '合伙人或非公司实体',
    },
    { en: 'Private Limited Company', key: 'private', zh: '私人有限公司' },
    {
      en: 'Private Limited Liability Company',
      key: 'private_llc',
      zh: '私人有限责任公司',
    },
    { en: 'Private Enterprise', key: 'private_e', zh: '私营企业' },
    { en: 'Public Limited Company', key: 'public', zh: '公共有限公司' },
    {
      en: 'Public Limited Liability Company',
      key: 'public_llc',
      zh: '公共有限责任公司',
    },
    { en: 'Representative Office', key: 'representative', zh: '代表处' },
    { en: 'S Corporation', key: 's_corporation', zh: 'S型公司' },
    { en: 'SLP (Scottish Limited Partnership)', key: 'slp', zh: '苏格兰有限合伙' },
    { en: 'Sole Proprietorship', key: 'sole', zh: '个人独资' },
    { en: 'SOEs (State-Owned Enterprises)', key: 'soes', zh: '国有企业' },
    { en: 'Trust', key: 'trust', zh: '信托' },
    { en: 'Unlimited Company', key: 'unlimited', zh: '无限公司' },
    { en: 'Other', key: 'other', zh: '其他' },
  ],
  CN: [
    { en: 'Association for Nonprofit', key: 'anassociation', zh: '非营利性协会' },
    { en: 'Branch', key: 'branch', zh: '分支' },
    { en: 'C Corporation', key: 'c_corporation', zh: 'C型公司' },
    { en: 'Collective Ownership', key: 'collective', zh: '集体所有制' },
    { en: 'Company', key: 'company', zh: '公司' },
    { en: 'Consortium', key: 'consortium', zh: '联营企业' },
    { en: 'Estates', key: 'estates', zh: '房地产' },
    { en: 'Foreign-Invested Enterprise', key: 'foreign', zh: '外商投资企业' },
    { en: 'Fund of Funds', key: 'fof', zh: '基金' },
    { en: 'General Partnership', key: 'gp', zh: '普通合伙' },
    {
      en: 'Joint-Stock Co-operative Enterprise',
      key: 'joint_stock_co',
      zh: '股份合作企业',
    },
    { en: 'Joint-Stock Enterprise', key: 'joint_stock', zh: '股份制企业' },
    { en: 'LLC (Limited Liability Company)', key: 'llc', zh: '有限责任公司' },
    { en: 'LLP (Limited Liability Partnership)', key: 'llp', zh: '有限责任合伙' },
    { en: 'Limited Partnership', key: 'lp', zh: '有限合伙' },

    { en: 'Partnership', key: 'partnership', zh: '合伙公司' },
    {
      en: 'Partnership or Unincorporated Entity',
      key: 'partnership_ue',
      zh: '合伙人或非公司实体',
    },
    { en: 'Private Limited Company', key: 'private', zh: '私人有限公司' },
    {
      en: 'Private Limited Liability Company',
      key: 'private_llc',
      zh: '私人有限责任公司',
    },
    { en: 'Private Enterprise', key: 'private_e', zh: '私营企业' },
    { en: 'Public Limited Company', key: 'public', zh: '公共有限公司' },
    {
      en: 'Public Limited Liability Company',
      key: 'public_llc',
      zh: '公共有限责任公司',
    },
    { en: 'Representative Office', key: 'representative', zh: '代表处' },
    { en: 'S Corporation', key: 's_corporation', zh: 'S型公司' },
    { en: 'SLP (Scottish Limited Partnership)', key: 'slp', zh: '苏格兰有限合伙' },
    { en: 'Sole Proprietorship', key: 'sole', zh: '个人独资' },
    { en: 'SOEs (State-Owned Enterprises)', key: 'soes', zh: '国有企业' },
    { en: 'Trust', key: 'trust', zh: '信托' },
    { en: 'Unlimited Company', key: 'unlimited', zh: '无限公司' },
    { en: 'Other', key: 'other', zh: '其他' },
  ],
  HK: [
    { en: 'Association for Nonprofit', key: 'anassociation', zh: '非营利性协会' },
    { en: 'Branch', key: 'branch', zh: '分支' },
    { en: 'C Corporation', key: 'c_corporation', zh: 'C型公司' },
    { en: 'Collective Ownership', key: 'collective', zh: '集体所有制' },
    { en: 'Company', key: 'company', zh: '公司' },
    { en: 'Consortium', key: 'consortium', zh: '联营企业' },
    { en: 'Estates', key: 'estates', zh: '房地产' },
    { en: 'Foreign-Invested Enterprise', key: 'foreign', zh: '外商投资企业' },
    { en: 'Fund of Funds', key: 'fof', zh: '基金' },
    { en: 'General Partnership', key: 'gp', zh: '普通合伙' },
    {
      en: 'Joint-Stock Co-operative Enterprise',
      key: 'joint_stock_co',
      zh: '股份合作企业',
    },
    { en: 'Joint-Stock Enterprise', key: 'joint_stock', zh: '股份制企业' },
    { en: 'LLC (Limited Liability Company)', key: 'llc', zh: '有限责任公司' },
    { en: 'LLP (Limited Liability Partnership)', key: 'llp', zh: '有限责任合伙' },
    { en: 'Limited Partnership', key: 'lp', zh: '有限合伙' },

    { en: 'Partnership', key: 'partnership', zh: '合伙公司' },
    {
      en: 'Partnership or Unincorporated Entity',
      key: 'partnership_ue',
      zh: '合伙人或非公司实体',
    },
    { en: 'Private Limited Company', key: 'private', zh: '私人有限公司' },
    {
      en: 'Private Limited Liability Company',
      key: 'private_llc',
      zh: '私人有限责任公司',
    },
    { en: 'Private Enterprise', key: 'private_e', zh: '私营企业' },
    { en: 'Public Limited Company', key: 'public', zh: '公共有限公司' },
    {
      en: 'Public Limited Liability Company',
      key: 'public_llc',
      zh: '公共有限责任公司',
    },
    { en: 'Representative Office', key: 'representative', zh: '代表处' },
    { en: 'S Corporation', key: 's_corporation', zh: 'S型公司' },
    { en: 'SLP (Scottish Limited Partnership)', key: 'slp', zh: '苏格兰有限合伙' },
    { en: 'Sole Proprietorship', key: 'sole', zh: '个人独资' },
    { en: 'SOEs (State-Owned Enterprises)', key: 'soes', zh: '国有企业' },
    { en: 'Trust', key: 'trust', zh: '信托' },
    { en: 'Unlimited Company', key: 'unlimited', zh: '无限公司' },
    { en: 'Other', key: 'other', zh: '其他' },
  ],
  UK: [
    { en: 'Association for Nonprofit', key: 'anassociation', zh: '非营利性协会' },
    { en: 'Branch', key: 'branch', zh: '分支' },
    { en: 'C Corporation', key: 'c_corporation', zh: 'C型公司' },
    { en: 'Collective Ownership', key: 'collective', zh: '集体所有制' },
    { en: 'Company', key: 'company', zh: '公司' },
    { en: 'Consortium', key: 'consortium', zh: '联营企业' },
    { en: 'Estates', key: 'estates', zh: '房地产' },
    { en: 'Foreign-Invested Enterprise', key: 'foreign', zh: '外商投资企业' },
    { en: 'Fund of Funds', key: 'fof', zh: '基金' },
    { en: 'General Partnership', key: 'gp', zh: '普通合伙' },
    {
      en: 'Joint-Stock Co-operative Enterprise',
      key: 'joint_stock_co',
      zh: '股份合作企业',
    },
    { en: 'Joint-Stock Enterprise', key: 'joint_stock', zh: '股份制企业' },
    { en: 'LLC (Limited Liability Company)', key: 'llc', zh: '有限责任公司' },
    { en: 'LLP (Limited Liability Partnership)', key: 'llp', zh: '有限责任合伙' },
    { en: 'Limited Partnership', key: 'lp', zh: '有限合伙' },

    { en: 'Partnership', key: 'partnership', zh: '合伙公司' },
    {
      en: 'Partnership or Unincorporated Entity',
      key: 'partnership_ue',
      zh: '合伙人或非公司实体',
    },
    { en: 'Private Limited Company', key: 'private', zh: '私人有限公司' },
    {
      en: 'Private Limited Liability Company',
      key: 'private_llc',
      zh: '私人有限责任公司',
    },
    { en: 'Private Enterprise', key: 'private_e', zh: '私营企业' },
    { en: 'Public Limited Company', key: 'public', zh: '公共有限公司' },
    {
      en: 'Public Limited Liability Company',
      key: 'public_llc',
      zh: '公共有限责任公司',
    },
    { en: 'Representative Office', key: 'representative', zh: '代表处' },
    { en: 'S Corporation', key: 's_corporation', zh: 'S型公司' },
    { en: 'SLP (Scottish Limited Partnership)', key: 'slp', zh: '苏格兰有限合伙' },
    { en: 'Sole Proprietorship', key: 'sole', zh: '个人独资' },
    { en: 'SOEs (State-Owned Enterprises)', key: 'soes', zh: '国有企业' },
    { en: 'Trust', key: 'trust', zh: '信托' },
    { en: 'Unlimited Company', key: 'unlimited', zh: '无限公司' },
    { en: 'Other', key: 'other', zh: '其他' },
  ],
  GB: [
    { en: 'Association for Nonprofit', key: 'anassociation', zh: '非营利性协会' },
    { en: 'Branch', key: 'branch', zh: '分支' },
    { en: 'C Corporation', key: 'c_corporation', zh: 'C型公司' },
    { en: 'Collective Ownership', key: 'collective', zh: '集体所有制' },
    { en: 'Company', key: 'company', zh: '公司' },
    { en: 'Consortium', key: 'consortium', zh: '联营企业' },
    { en: 'Estates', key: 'estates', zh: '房地产' },
    { en: 'Foreign-Invested Enterprise', key: 'foreign', zh: '外商投资企业' },
    { en: 'Fund of Funds', key: 'fof', zh: '基金' },
    { en: 'General Partnership', key: 'gp', zh: '普通合伙' },
    {
      en: 'Joint-Stock Co-operative Enterprise',
      key: 'joint_stock_co',
      zh: '股份合作企业',
    },
    { en: 'Joint-Stock Enterprise', key: 'joint_stock', zh: '股份制企业' },
    { en: 'LLC (Limited Liability Company)', key: 'llc', zh: '有限责任公司' },
    { en: 'LLP (Limited Liability Partnership)', key: 'llp', zh: '有限责任合伙' },
    { en: 'Limited Partnership', key: 'lp', zh: '有限合伙' },

    { en: 'Partnership', key: 'partnership', zh: '合伙公司' },
    {
      en: 'Partnership or Unincorporated Entity',
      key: 'partnership_ue',
      zh: '合伙人或非公司实体',
    },
    { en: 'Private Limited Company', key: 'private', zh: '私人有限公司' },
    {
      en: 'Private Limited Liability Company',
      key: 'private_llc',
      zh: '私人有限责任公司',
    },
    { en: 'Private Enterprise', key: 'private_e', zh: '私营企业' },
    { en: 'Public Limited Company', key: 'public', zh: '公共有限公司' },
    {
      en: 'Public Limited Liability Company',
      key: 'public_llc',
      zh: '公共有限责任公司',
    },
    { en: 'Representative Office', key: 'representative', zh: '代表处' },
    { en: 'S Corporation', key: 's_corporation', zh: 'S型公司' },
    { en: 'SLP (Scottish Limited Partnership)', key: 'slp', zh: '苏格兰有限合伙' },
    { en: 'Sole Proprietorship', key: 'sole', zh: '个人独资' },
    { en: 'SOEs (State-Owned Enterprises)', key: 'soes', zh: '国有企业' },
    { en: 'Trust', key: 'trust', zh: '信托' },
    { en: 'Unlimited Company', key: 'unlimited', zh: '无限公司' },
    { en: 'Other', key: 'other', zh: '其他' },
  ],
  OTHER: [
    { en: 'Association for Nonprofit', key: 'anassociation', zh: '非营利性协会' },
    { en: 'Branch', key: 'branch', zh: '分支' },
    { en: 'C Corporation', key: 'c_corporation', zh: 'C型公司' },
    { en: 'Collective Ownership', key: 'collective', zh: '集体所有制' },
    { en: 'Company', key: 'company', zh: '公司' },
    { en: 'Consortium', key: 'consortium', zh: '联营企业' },
    { en: 'Estates', key: 'estates', zh: '房地产' },
    { en: 'Foreign-Invested Enterprise', key: 'foreign', zh: '外商投资企业' },
    { en: 'Fund of Funds', key: 'fof', zh: '基金' },
    { en: 'General Partnership', key: 'gp', zh: '普通合伙' },
    {
      en: 'Joint-Stock Co-operative Enterprise',
      key: 'joint_stock_co',
      zh: '股份合作企业',
    },
    { en: 'Joint-Stock Enterprise', key: 'joint_stock', zh: '股份制企业' },
    { en: 'LLC (Limited Liability Company)', key: 'llc', zh: '有限责任公司' },
    { en: 'LLP (Limited Liability Partnership)', key: 'llp', zh: '有限责任合伙' },
    { en: 'Limited Partnership', key: 'lp', zh: '有限合伙' },

    { en: 'Partnership', key: 'partnership', zh: '合伙公司' },
    {
      en: 'Partnership or Unincorporated Entity',
      key: 'partnership_ue',
      zh: '合伙人或非公司实体',
    },
    { en: 'Private Limited Company', key: 'private', zh: '私人有限公司' },
    {
      en: 'Private Limited Liability Company',
      key: 'private_llc',
      zh: '私人有限责任公司',
    },
    { en: 'Private Enterprise', key: 'private_e', zh: '私营企业' },
    { en: 'Public Limited Company', key: 'public', zh: '公共有限公司' },
    {
      en: 'Public Limited Liability Company',
      key: 'public_llc',
      zh: '公共有限责任公司',
    },
    { en: 'Representative Office', key: 'representative', zh: '代表处' },
    { en: 'S Corporation', key: 's_corporation', zh: 'S型公司' },
    { en: 'SLP (Scottish Limited Partnership)', key: 'slp', zh: '苏格兰有限合伙' },
    { en: 'Sole Proprietorship', key: 'sole', zh: '个人独资' },
    { en: 'SOEs (State-Owned Enterprises)', key: 'soes', zh: '国有企业' },
    { en: 'Trust', key: 'trust', zh: '信托' },
    { en: 'Unlimited Company', key: 'unlimited', zh: '无限公司' },
    { en: 'Other', key: 'other', zh: '其他' },
  ],
};
