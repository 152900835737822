import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConditionBuilderModule, TypeConfig } from './condition-builder';

export type ConsoleConfig = {
  condition: TypeConfig[];
};

export function conditionBuilderProviders(config: TypeConfig[]) {
  return ConditionBuilderModule.forRoot(config).providers;
}

@NgModule({
  imports: [],
  exports: [ConditionBuilderModule],
  providers: [],
  declarations: [],
})
export class ConsoleLibsModule {
  static forRoot(configs: ConsoleConfig): ModuleWithProviders<ConsoleLibsModule> {
    return {
      ngModule: ConsoleLibsModule,
      /* !!! Explicitly redefine the providers from imported/exported modules. */
      providers: [...(conditionBuilderProviders(configs.condition) ?? [])],
    };
  }
}
