<div
  [class]="{
    'message-input-wrap': true,
    'message-input-wrap-hover': !isValidatorsFailed,
    'message-input-wrap-focused': isContentFocus,
    'message-input-wrap-hidden-border': isHiddenBorder
  }"
  [style]="{
    '--message-input-color-default': '#d9d9d9',
    '--message-input-color-primary': colorPrimary,
    '--message-input-color-primary-light: ': colorPrimaryLight,
    '--message-input-color-border': colorBorder,
    '--message-input-color-border-focus': colorBorderFocus,
    'background-color':
      type === 'chat'
        ? sourceReal === 'GP'
          ? '#F5F6FA'
          : '#F8F9FA'
        : '#ffffff'
  }"
  [ngStyle]="customStyleWrap"
>
  <!-- up -->
  <textarea
    class="message-input-textarea"
    [style]="customStyleTextarea"
    [placeholder]="placeholder"
    [value]="valueContent"
    [maxLength]="limitInput === -1 ? 999999999 : limitInput"
    (focus)="handleContentFocus()"
    (blur)="handleContentBlur()"
    (input)="handleContentInput($event)"
  ></textarea>
  <!-- down -->
  <div class="message-input-down" [style]="customStyleFooter">
    <!-- down-left -->
    <div class="message-input-down-left">
      <ng-container *ngIf="valueFile && valueFile.length > 0; else noFile">
        <stream-message-file
          *ngFor="let itemFile of valueFile; let indexFile = index"
          class="message-input-down-left-stream-message-file"
          [info]="itemFile"
          [isShowBtnDelete]="true"
          (previewClick)="handleFilePreviewClick($event)"
          (deleteClick)="handleFileDeleteClick($event)"
        ></stream-message-file>
      </ng-container>
      <ng-template #noFile>
        <div class="message-input-down-left-item">
          <button
            type="button"
            mat-icon-button
            [disabled]="isFileUploading"
            (click)="uploadFile.click()"
          >
            <input
              class="message-input-down-left-input-file"
              type="file"
              hidden
              #uploadFile
              [accept]="acceptFile"
              [multiple]="false"
              (change)="handleFileInput($event)"
            />
            <div class="message-input-down-left-upload">
              <svg
                class="icon message-input-down-left-upload-svg"
                viewBox="0 0 1024 1024"
                [style]="{
                  opacity: isFileUploading ? 0.4 : 1
                }"
              >
                <path
                  d="M655.36 211.626667a160 160 0 0 1 226.261333 226.261333l-377.173333 377.088A224 224 0 1 1 187.733333 498.218667l316.8-316.8a32 32 0 1 1 45.226667 45.226666L232.96 543.488l-22.613333-22.613333 22.613333 22.613333a160 160 0 0 0 0 226.304l-22.485333 22.442667 22.485333-22.442667a160 160 0 0 0 226.261333 0l377.130667-377.173333A96 96 0 1 0 700.586667 256.853333L323.413333 633.984a32 32 0 0 0 45.226667 45.226667l316.8-316.757334a32 32 0 1 1 45.226667 45.226667L413.994667 724.48a96 96 0 1 1-135.765334-135.765333l377.173334-377.130667z"
                  fill="#666666"
                ></path>
              </svg>
            </div>
          </button>
          <mat-spinner
            *ngIf="isFileUploading"
            diameter="32"
            strokeWidth="2"
            class="message-input-down-left-upload-spinner"
          ></mat-spinner>
        </div>
      </ng-template>
    </div>
    <!-- down-right -->
    <div
      class="message-input-down-right box-border flex flex-[0_0_auto] flex-row items-center justify-center p-3"
    >
      <div
        *ngIf="isShowLimitInput && limitInput"
        class="message-input-down-right-limit down-item"
      >
        {{ valueContent.length }}/{{ limitInput }}
      </div>
      <button
        *ngIf="isShowBtnSend"
        mat-flat-button
        [disabled]="getBtnSendDisabled()"
        color="primary"
        class="message-input-down-right-send down-item"
        [style]="{
          'background-color': getBtnSendDisabled()
            ? ''
            : 'var(--message-input-color-primary)'
        }"
        (click)="handleBtnSendClick()"
      >
        Send
      </button>
      <button
        *ngIf="isShowBtnNormal"
        mat-flat-button
        [disabled]="isDisableBtnNormal"
        color="primary"
        class="message-input-down-right-normal down-item"
        [style]="{
          'background-color': isDisableBtnNormal
            ? ''
            : 'var(--message-input-color-primary)'
        }"
        (click)="handleBtnNormalClick()"
      >
        {{ textBtnNormal }}
      </button>
    </div>
  </div>
</div>
