export enum FeatureStatus {
  ON = 'ON',
  OFF = 'OFF'
}

export interface FeatureItem {
  dataCreateAccountId: string;
  dataCreateTime: string;
  dataIsDeleted: boolean;
  dataUpdateAccountId: string;
  dataUpdateTime: string;
  featureDescription: string;
  featureId: string;
  featureKey: string;
  featureName: string;
  status: FeatureStatus;
  tenantFeatureMapId: string;
  tenantId: string;
  display: boolean;
}
