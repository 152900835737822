import { ProfileSignatoryType } from '../models/src';

export const ErrorMessages = {
  general: 'There seems to be a little problem.',
  taskDone: 'This task has been handled..',
  identity: 'Please use Passport or National ID card as your identification document.',
  profileNotDone:
    'The investor profile is not complete yet, please complete it before proceeding to the next step.',
  profileNotSelect: 'Please select an investor profile from the list.',
  mandatoryNotUpload: 'Please upload all mandatory documents.',
};

export const SignatoryOptions = [
  {
    label: 'The investor signs the documents personally',
    value: ProfileSignatoryType.personally,
  },
  {
    label: 'I am authorized to sign the documents on behalf of the investor',
    value: ProfileSignatoryType.onBehalf,
  },
];
