import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CustomDomain, DataRoomApi, ScenarioApi, SubscriptionProfileApi } from '@stream/models';
import { uuid } from '@stream/utils';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, timeout } from 'rxjs';

import { environment } from '../../environments/environment';

const HTTP_TIMEOUT = 30000;

const HTTP_SUBSCRIPTION_CONFIRM_REGEX = /\/subscription\/(\d+)\/modification\/confirm/;

const ApiWhiteList = [
  ScenarioApi.SubmitNodeResult,
  SubscriptionProfileApi.PatchIRA,
  DataRoomApi.QueryFile
];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  @LocalStorage(CustomDomain.Domain, '')
  customDomain!: string;
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let httpTimeout = HTTP_TIMEOUT;

    if (HTTP_SUBSCRIPTION_CONFIRM_REGEX.test(req.url) || ApiWhiteList.includes(req.url as any)) {
      httpTimeout = 120000;
    }

    return next
      .handle(
        req.clone({
          url: `${this.customDomain ? 'https://' + this.customDomain : ''}${
            this.customDomain ? '/web' : environment.baseUrl
          }${req.url}`,
          setHeaders: {
            ...environment.headers,
            requestid: uuid(),
            ContentLanguage: this.localeId
          }
        })
      )
      .pipe(timeout(httpTimeout));
  }
}
