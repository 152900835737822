<mat-checkbox
  [formControl]="$any(formControl)"
  [id]="id"
  [formlyAttributes]="field"
  [tabIndex]="to.tabindex"
  [indeterminate]="to['indeterminate'] && formControl.value === null"
  [color]="to['color']"
  [labelPosition]="to['labelPosition'] || 'after'"
>
  <!-- todo delete to['align'] ||  -->
  <stream-quill-view [content]="to['label']"></stream-quill-view>
  <span
    *ngIf="to.required && to['hideRequiredMarker'] !== true"
    aria-hidden="true"
    class="mat-form-field-required-marker"
    >*</span
  >
</mat-checkbox>
