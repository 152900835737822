import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailDelayKeyEnum } from '@stream/models';
import { EmailDelay } from '@stream/ngx-utils';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { ScenarioPanelService, SignService } from '../../services';

@Component({
  selector: 'stream-sign-verify-dialog',
  templateUrl: './sign-verify-dialog.component.html',
  styleUrls: ['./sign-verify-dialog.component.scss'],
})
export class SignVerifyDialogComponent {
  constructor(
    private signService: SignService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      ticket: string;
      captchaId: string;
      email: string;
      panelService?: ScenarioPanelService;
    },
    private dialogRef: MatDialogRef<SignVerifyDialogComponent>,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.captchaId = data.captchaId;
  }

  code = new UntypedFormControl('');

  @EmailDelay(EmailDelayKeyEnum.SignCaptcha)
  delay!: Observable<number>;

  captchaId = '';

  loading = false;

  isMedium = this.breakpointObserver
    .observe('(min-width: 768px)')
    .pipe(map(({ matches }) => matches));

  resend() {
    this.signService
      .signCaptcha(this.data.ticket)
      .subscribe(({ data: { responseData } }) => {
        this.captchaId = responseData;
      });
  }

  verify() {
    if (!this.code.value || this.code.value.length === 0) {
      this.code.setErrors({ required: true });
      return;
    }
    if (this.code.value.length < 6) {
      this.code.setErrors({ wrong: true });
      return;
    }
    this.loading = true;
    this.signService
      .signValidate(this.code.value, this.captchaId, this.data.ticket)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        res => {
          switch (res.data.status) {
            case 'projectRevoked':
              if (this.data.panelService) {
                this.data.panelService.leave.next('projectRevoked');
              }
              this.code.setErrors({ wrong: true });
              break;
            case 'success':
              this.dialogRef.close({
                captchaId: this.captchaId,
                result: true,
              });
              break;
            default:
              this.code.setErrors({ wrong: true });
          }
        },
        err => {
          this.code.setErrors({ wrong: true });
          if (err.error.status === 'projectRevoked') {
            if (this.data.panelService) {
              this.data.panelService.leave.next('projectRevoked');
            }
          }
        },
      );
  }

  onCompleted(_e: string) {
    this.verify();
  }

  onEnter() {
    this.verify();
  }
}
