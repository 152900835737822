export const TRANSLATE_CONFIG = 'TRANSLATE_CONFIG';
export const IGNORE_NODE_NAME = [
  // '#document',
  // 'html',
  'meta',
  'script',
  'link',
  'base',
  'style',
  'head',
];
