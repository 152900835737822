import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DocumentOwner,
  DocumentTagApi,
  MandatoryDocFile,
  MandatoryDocsApi,
  ProfileMandatoryDocument,
  Restful
} from '@stream/models';

import { AbstractControl, FormGroup } from '@angular/forms';
import { MandatoryDocOptions, MandatoryDocTag } from './mandatory-doc.interface';
import { MandatoryDocComponent } from './mandatory-doc/mandatory-doc.component';

@Injectable({
  providedIn: 'root'
})
export class MandatoryDocService {
  constructor(
    private matDialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private http: HttpClient
  ) {}

  open(options: MandatoryDocOptions) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    return this.matDialog.open(MandatoryDocComponent, {
      data: {
        title: 'Add document',
        allowDelete: true,
        ...options
      },
      maxWidth: isMedium ? '680px' : '95vw',
      maxHeight: '100vh',
      width: '100%'
    });
  }

  /**
   * @description only for GP task detail additional doc
   * @param options
   * @returns
   */
  openAdditionalDoc(options: MandatoryDocOptions) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    const { mandatoryDocs, ...rest } = options;
    return this.matDialog.open(MandatoryDocComponent, {
      data: {
        title: 'Add document',
        allowDelete: true,
        ...rest,
        mandatoryDocs: (mandatoryDocs ?? []).map(doc => ({
          ...doc,
          required: true
        }))
      },
      maxWidth: isMedium ? '680px' : '95vw',
      maxHeight: '100vh',
      width: '100%'
    });
  }

  transLabelName = (owner: DocumentOwner | undefined, documentName: string) => {
    return owner ? `${documentName} - ${owner.name}` : documentName;
  };

  getTenantTags(params: { fundMandatoryDocId: string; groupName?: string }) {
    return this.http.get<Restful<MandatoryDocTag[]>>(DocumentTagApi.getDocumentTags, {
      params: {
        ...params
      }
    });
  }

  uploadMandatoryDoc(params: FormData) {
    return this.http.post<Restful<MandatoryDocFile>>(MandatoryDocsApi.uploadMandatoryDoc, params);
  }

  getDocByTagOrProfile(params: {
    profileId: string;
    tagName?: string;
    keyword?: string;
    investorId?: string;
    untagged?: true;
  }) {
    return this.http.get<Restful<ProfileMandatoryDocument[]>>(
      DocumentTagApi.getDocumentsByTagOrProfile,
      {
        params
      }
    );
  }

  tagRequireValidator = (form: FormGroup) => {
    return (control: AbstractControl) => {
      const fileCtr = form.get('file');
      if (!fileCtr) {
        return null;
      }
      if (fileCtr.value?.length > 0) {
        return control.value ? null : { required: true };
      }
      return null;
    };
  };

  fileRequiredValidator = () => {
    return (control: AbstractControl) => {
      if (!control.value) {
        return { required: true };
      }
      return control.value?.length > 0 ? null : { required: true };
    };
  };

  getTagsStatistics(queries: {
    profileId: string;
    tagName: string[]
  }) {
    return this.http.get<Restful<{
      name: string;
      count: number;
    }[]>>(DocumentTagApi.getTagsStatistics, {
      params: {
        ...queries
      }
    });
  }
}
