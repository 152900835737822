import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DataRoomDocument,
  EntityMember,
  Identity,
  Individual,
  ProfileDocumentConfigEnum,
  ProfileType,
  Store,
  TenantAttribute,
  policyType
} from '@stream/models';
import { transformDateToMoment, transformDateToStr } from '@stream/utils';
import { Subscription, of, zip } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';

import { MandatoryDocItemComponent, MandatoryDocService } from '../../../mandatory-doc';
import { ProfileSettingService } from '../../services';
import { ProfileService } from '../../services/profile.service';
import { profileFormOption } from '../../utils';
import { UploadRes } from '../upload-private-file/upload-private-file.component';

@Component({
  selector: 'stream-identity-dialog',
  templateUrl: './identity-dialog.component.html',
  styleUrls: ['./identity-dialog.component.scss']
})
export class IdentityDialogComponent implements OnInit, OnDestroy {
  @ViewChild('mandatoryDocItem')
  mandatoryDocItemRef?: MandatoryDocItemComponent;

  loading = false;

  todayDate = new Date();

  contentView = '';

  document?: UploadRes;

  store = new Store({ type: 'session', namespace: 'scenario' });

  form = this.fb.group({});

  // init identity form config
  initIdentityFrom() {
    const formGroup: { [key: string]: any } = {};
    const identityOptions = profileFormOption.identity;
    if (identityOptions) {
      for (const option of identityOptions) {
        formGroup[option.key] = [
          option.default ?? null,
          option.require ? Validators.required : null
        ];
      }
    }
    this.form = this.fb.group(formGroup);
  }

  mdConfig: any = {
    mandatoryDocs: [
      {
        documentName: 'Proof of identity',
        documentDescription: '',
        isMandatory: true,
        mandatoryDocumentKey: '',
        multiple: false,
        files: this.data.identity?.referenceProofCertificateFileEntity?.documents
          ? [
              {
                tag: this.data.identity?.tag,
                country: this.data.identity?.country,
                ...(this.data.identity?.referenceProofCertificateFileEntity?.documents ?? {})
              }
            ]
          : [],
        tags: [],
        fileName: this.fileName
      }
    ],
    profileId: this.data.profileId,
    loadInitValue: true,
    checkAllFiles: true,
    groupName: 'ID documents',
    errorMessage: '',
    allowReuse: true,
    allowDelete: true
  };
  mdForm = this.fb.group({
    tag: [null, []],
    country: [],
    file: []
  });

  proofOfIdentityConfig: ProfileDocumentConfigEnum = ProfileDocumentConfigEnum.hidden;
  tenantSub = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<IdentityDialogComponent>,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      investorProfileType: ProfileType;
      identity?: Identity & {
        referenceProofCertificateFileEntity?: { documents: DataRoomDocument };
      };
      tenantId: string;
      metaIndividualId?: Individual['id'];
      tenantAttributeList: TenantAttribute[];
      ownerName: string;
      reModify?: boolean;
      profileId: string;
      isMember?: boolean;
    },
    private mandatoryDocService: MandatoryDocService,
    private profileSettingService: ProfileSettingService
  ) {
    this.initIdentityFrom();
    this.form.patchValue({
      ...data.identity,
      expirationDate: transformDateToMoment(data.identity?.expirationDate)
    });

    if (data.identity?.expiredDate) {
      // 'expiredDate' is deprecated since version 101 for page, but still need to send value returned by server
      this.form.addControl('expiredDate', new FormControl(data.identity.expiredDate));
    }
  }

  get fileName(): string {
    if (this.data.ownerName) {
      return 'Proof of identity - ' + this.data.ownerName;
    }
    return 'Proof of identity';
  }

  get contentH(): string {
    return `calc(${window?.innerHeight - 161}px - 20vh)`;
  }

  get hideIdentity() {
    return this.proofOfIdentityConfig === ProfileDocumentConfigEnum.hidden;
  }

  get isMemberRole() {
    return !!this.data.isMember;
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.tenantSub.unsubscribe();
  }

  init() {
    zip(
      this._getTooltipInfo(),
      this.profileSettingService.tenantProfileSettings$.pipe(take(1))
    ).subscribe(([tooltip, configs]) => {
      this.mdConfig.mandatoryDocs.forEach((item: any) => {
        item.documentDescription = tooltip;
      });
      this.mdForm
        .get('tag')
        ?.addValidators(this.mandatoryDocService.tagRequireValidator(this.form));
      const { investorProfileType } = this.data;
      const settingKey =
        investorProfileType === ProfileType.Entity
          ? 'DOCUMENT_ENTITY_PROFILE'
          : this.isMemberRole
            ? 'DOCUMENT_ENTITY_MEMBER'
            : 'DOCUMENT_INDIVIDUAL_PROFILE';

      const configValue = configs?.[settingKey]?.settingValue ?? {};
      this.mdConfig.checkAllFiles =
        configValue.proofOfIdentity === ProfileDocumentConfigEnum.mandatory;

      if (this.data.reModify) {
        this.mdConfig.allowDelete = false;
      }
      this.proofOfIdentityConfig = configValue.proofOfIdentity as ProfileDocumentConfigEnum;
      if (this.proofOfIdentityConfig === ProfileDocumentConfigEnum.mandatory) {
        this.mdForm.get('file')?.addValidators(this.mandatoryDocService.fileRequiredValidator());
        this.mdConfig.errorMessage = 'This field is required.';
      }
    });
  }

  _getTooltipInfo() {
    return this.profileService
      .getToolTipInfo(policyType.PROOF_OF_IDENTITY)
      .pipe(map(({ data }) => data?.gpPolicies?.[0]?.policyContext));
  }

  submit() {
    if (this.hideIdentity) {
      this._saveIdentityWithoutMandatoryDoc();
    } else {
      this._saveIdentity();
    }
  }

  _saveIdentity() {
    const profile = this.store.get('profile');
    const { identity, ...data } = this.data;
    const isHas = (this.data.tenantAttributeList || []).some(
      item => item.attributeName === this.form.get('documentType')?.value
    );
    if (isHas === false) {
      this.form.get('documentType')?.setValue(null);
    }
    if (!this.hideIdentity) {
      Object.values(this.mdForm.controls).forEach(control => {
        control.updateValueAndValidity({ emitEvent: false });
        setTimeout(() => {
          control.markAsDirty();
          control.markAsTouched();
        });
      });
      if (!this.mdForm.valid || this.form.invalid) {
        return;
      }
    } else if (this.form.invalid) {
      return;
    }
    this.loading = true;
    const value = this.form.getRawValue();
    this.mandatoryDocItemRef?.tagList$
      .pipe(
        take(1),
        switchMap(tagList => {
          if (!this.mandatoryDocItemRef) {
            return of({ tagList, countryList: [] });
          }
          return this.mandatoryDocItemRef.countryList$.pipe(
            map(countryList => ({ tagList, countryList }))
          );
        }),
        switchMap(({ tagList, countryList }) => {
          const tag = tagList.find((t: any) => t.label === this.mdForm.get('tag')?.value);
          const country = countryList.find(
            (c: any) => c.value === this.mdForm.get('country')?.value
          );
          const documentToken = this.mdForm.get('file')?.value?.[0]?.documentToken;
          const tagAndCountry = {
            tag: tag
              ? {
                  id: tag.value,
                  name: tag.label
                }
              : null,
            country: country
              ? {
                  id: country.value,
                  name: country.label
                }
              : null
          };
          const params = {
            ...value,
            ...tagAndCountry,
            expirationDate: transformDateToStr(value.expirationDate),
            referenceProofCertificateFileToken: documentToken
          };

          if (profile && data.reModify) {
            const { investorProfileMetaInfoCert, investorProfileMetaInfoMemberList: memberList } =
              profile;

            const fileCtr = this.mdForm.get('file');
            if (fileCtr?.value && fileCtr?.value.length > 0) {
              this.document = {
                ...fileCtr?.value[0],
                url: fileCtr?.value[0]?.downloadUrl
              };
            }
            if (memberList) {
              profile.investorProfileMetaInfoMemberList = memberList.map((member: EntityMember) => {
                const { referenceInfoCertificateEntity: certificate } = member;
                const { documents = {} } = certificate.referenceProofCertificateFileEntity ?? {};

                member.referenceInfoCertificateEntity =
                  certificate?.id === identity?.id
                    ? {
                        ...certificate,
                        ...params,
                        ...{
                          referenceProofCertificateFileEntity: {
                            documents: this.document
                              ? { ...documents, ...this.document }
                              : documents
                          }
                        }
                      }
                    : certificate;

                return member;
              });
            } else {
              if (this.document) {
                const { referenceProofCertificateFileEntity } = investorProfileMetaInfoCert;
                referenceProofCertificateFileEntity.documents = {
                  ...referenceProofCertificateFileEntity.documents,
                  ...this.document
                };
              }

              profile.investorProfileMetaInfoCert = {
                ...investorProfileMetaInfoCert,
                ...params
              };
            }

            this.store.set('profile', profile);
            this.loading = false;
            return of({});
          }

          return this.profileService
            .patchIdentity(params, {
              id: identity?.id,
              ...data
            })
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            );
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  _saveIdentityWithoutMandatoryDoc() {
    const profile = this.store.get('profile');
    const { identity, ...data } = this.data;
    const isHas = (this.data.tenantAttributeList || []).some(
      item => item.attributeName === this.form.get('documentType')?.value
    );
    if (isHas === false) {
      this.form.get('documentType')?.setValue(null);
    }
    if (this.form.invalid) return;
    this.loading = true;
    const value = this.form.getRawValue();
    const tagAndCountry = {
      tag: this.data.identity?.tag,
      country: this.data.identity?.country
    };
    of(tagAndCountry)
      .pipe(
        switchMap(tagAndCountry => {
          const params = {
            ...value,
            ...tagAndCountry,
            expirationDate: transformDateToStr(value.expirationDate),
            referenceProofCertificateFileToken:
              this.data.identity?.referenceProofCertificateFileEntity?.documents?.documentToken
          };

          if (profile && data.reModify) {
            const { investorProfileMetaInfoCert, investorProfileMetaInfoMemberList: memberList } =
              profile;
            const fileCtr = this.mdForm.get('file');
            if (fileCtr?.value && fileCtr?.value.length > 0) {
              this.document = {
                ...fileCtr?.value[0],
                url: fileCtr?.value[0]?.downloadUrl
              };
            }
            if (memberList) {
              profile.investorProfileMetaInfoMemberList = memberList.map((member: EntityMember) => {
                const { referenceInfoCertificateEntity: certificate } = member;
                const { documents = {} } = certificate.referenceProofCertificateFileEntity ?? {};

                member.referenceInfoCertificateEntity =
                  certificate?.id === identity?.id
                    ? {
                        ...certificate,
                        ...params,
                        ...{
                          referenceProofCertificateFileEntity: {
                            documents: this.document
                              ? { ...documents, ...this.document }
                              : documents
                          }
                        }
                      }
                    : certificate;

                return member;
              });
            } else {
              if (this.document) {
                const { referenceProofCertificateFileEntity } = investorProfileMetaInfoCert;
                referenceProofCertificateFileEntity.documents = {
                  ...referenceProofCertificateFileEntity.documents,
                  ...this.document
                };
              }

              profile.investorProfileMetaInfoCert = {
                ...investorProfileMetaInfoCert,
                ...params
              };
            }

            this.store.set('profile', profile);
            this.loading = false;
            return of({});
          }

          return this.profileService
            .patchIdentity(params, {
              id: identity?.id,
              ...data
            })
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            );
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
