import moment, { Moment, MomentInput } from 'moment-timezone';

export function transformDateToMoment(date: unknown): Moment | null {
  if (!date) return null;
  if (!moment(date as MomentInput).isValid()) return null;
  if (moment.isMoment(date)) return date;
  return moment(date as MomentInput);
}

export function transformDateToStr(
  date: unknown,
  format: string = 'YYYY-MM-DD'
): string {
  if (!date) return '';
  if (moment.isMoment(date)) return date.format(format);
  if (moment(date as MomentInput).isValid())
    return moment(date as MomentInput).format(format);
  return '';
}
