import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CHAT_CONFIG, ChatConfig } from './chat.type';
import {
  MessageFileComponent,
  MessageInputComponent,
  MessageItemComponent,
} from './components';

@NgModule({
  declarations: [
    MessageInputComponent,
    MessageItemComponent,
    MessageFileComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NzMessageModule,
  ],
  exports: [MessageInputComponent, MessageItemComponent],
})
export class ChatModule {
  static injector: Injector;
  static forRoot(configs: ChatConfig): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [
        {
          provide: CHAT_CONFIG,
          useValue: configs,
        },
      ],
    };
  }

  constructor(injector: Injector) {
    ChatModule.injector = injector;
  }
}

export function ChatProviders(config: ChatConfig) {
  return ChatModule.forRoot(config).providers;
}
