import { MandatoryDocument, Tag, TagCountry } from './mandatory-docs.interface';

export enum TasksStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
  DONE = 'DATADONE_ROOM',
}

export enum TasksNode {
  EXTERNAL_AUDIT = 'EXTERNAL_AUDIT',
  INTERNAL_AUDIT = 'INTERNAL_AUDIT',
  ADDRESS_MATERIAL = 'ADDRESS_MATERIAL',
  IDENTITY_MATERIAL = 'IDENTITY_MATERIAL',
}

export enum TasksType {
  SUBSCRIPTION_APPROVAL_TASK = 'SUBSCRIPTION_APPROVAL_TASK',
  SUPPLEMENTARY_SUPPORTING_MATERIALS_TASK = 'SUPPLEMENTARY_SUPPORTING_MATERIALS_TASK',
}

export interface Tasks {
  approvalId: string;
  approvalName: string;
  completedTime: string;
  conditions: string;
  dataCreateTime: string;
  dataIsDeleted: boolean;
  dataStatus: string;
  dataUpdateTime: string;
  id: string;
  lpApprovalName: string;
  result: string;
  taskDescription: string;
  taskDetail: string;
  taskStatus: TasksStatus;
  tasksNode: TasksNode;
  tasksType: TasksType;
  tenantId: string;
  version: string;
  inboxId: string;
}

export interface TaskMandatoryDocument extends Omit<MandatoryDocument, 'files'> {
  systemFileDescription?: string;
  files?: MandatoryDocument['files'];
}

export interface TaskResultFile {
  fileId: string;
  fileName: string;
  fileType: string;
  fileUrl: string;
  documentName?: string;
  tag?: Tag;
  country?: TagCountry;
}
