<ng-container [formGroup]="$any(formArray$ | async)">
  <label class="label" nz-tooltip [nzTooltipTitle]="label$ | async">
    {{ label$ | async }}
  </label>
  <nz-form-control class="operator-selector-wrapper">
    <nz-select [formControlName]="1">
      <nz-option
        *ngFor="let operator of (typeConfig$ | async)?.operators ?? []"
        [nzValue]="operator.value"
        [nzLabel]="operator.label"
      >
      </nz-option>
    </nz-select>
  </nz-form-control>
  <div class="value-selector-wrapper">
    <ng-template streamValueSelector></ng-template>
  </div>
</ng-container>
