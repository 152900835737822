import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthStoreKeyEnum } from '@stream/models';
import { LocalStorage } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  @LocalStorage(AuthStoreKeyEnum.AccessToken, '')
  accessToken!: string;

  @LocalStorage(AuthStoreKeyEnum.PrincipalToken, '')
  principalToken!: string;

  constructor(private router: Router) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { url } = state;

    if (!this.accessToken || !this.principalToken) {
      const returnUrl = this.getReturnUrl(url);
      return this.router.parseUrl(`/auth?returnUrl=(${returnUrl})`);
    }
    return true;
  }

  getReturnUrl(url: string) {
    let _url = url;
    if (url.indexOf('scenario') > -1) {
      _url = '/product/list';
    }
    return encodeURIComponent(_url);
  }
}
