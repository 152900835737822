import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChatModule as LibsChatModule } from '@stream/libs/common/chat';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatErrorsModule } from 'ngx-mat-errors';

import { NoDataModule } from '../../../libs/ngx-utils/src/lib/components';
import { SharedModule } from '../shared/shared.module';
import { ChatComponent } from './chat.component';
import { ChatFundInfoComponent } from './components/chat-fund-info/chat-fund-info.component';
import { ChatMembersEditComponent } from './components/chat-members-edit/chat-members-edit.component';
import { ChatMembersManagementComponent } from './components/chat-members-management/chat-members-management.component';
import { ChatMessageManageComponent } from './components/chat-message-management/chat-message-management.component';
import { ChatOngoingConversationComponent } from './components/chat-ongoing-conversation/chat-ongoing-conversation.component';
import { ChatWidgetComponent } from './components/chat-widget/chat-widget.component';
import { ChatConversationAddComponent } from './components/conversation-add/conversation-add.component';
import { ChatConversationDetailComponent } from './components/conversation-detail/conversation-detail.component';
import { ChatConversationComponent } from './components/conversation/conversation.component';

@NgModule({
  declarations: [
    ChatMembersManagementComponent,
    ChatMembersEditComponent,
    ChatFundInfoComponent,
    ChatComponent,
    ChatConversationComponent,
    ChatConversationAddComponent,
    ChatConversationDetailComponent,
    ChatWidgetComponent,
    ChatOngoingConversationComponent,
    ChatMessageManageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatBadgeModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatErrorsModule,
    MatDialogModule,
    NzPopoverModule,
    InfiniteScrollModule,
    NoDataModule,
    LibsChatModule.forRoot({
      source: 'LP' // 'GP' | 'LP'
    })
  ],
  providers: [],
  exports: [ChatComponent, ChatWidgetComponent]
})
export class ChatModule {}
