export type ProfileFormKeyType = 'identity' | 'bank' | 'address';

export type ProfileFormOptionType = {
  [key in ProfileFormKeyType]?: {
    key: string;
    default?: null | string | number;
    require?: boolean;
    max?: number;
  }[];
};

/**
 * rosolve profile check list & progress sync issue
 * Synchronize subcomponent form validation with parent component,
 */
export const profileFormOption: ProfileFormOptionType = {
  identity: [
    {
      key: 'documentType',
      default: null,
      require: true
    },
    {
      key: 'identityNumber',
      default: null,
      require: true
    },
    {
      key: 'expirationDate',
      default: null
    },
    {
      key: 'referenceProofCertificateFileToken',
      default: null
    }
  ],
  bank: [
    {
      key: 'bankAccountName',
      default: null,
      require: true
    },
    {
      key: 'bankName',
      default: null,
      require: true
    },
    {
      key: 'bankAccountNumber',
      default: null,
      require: true
    },
    {
      key: 'bankSwiftCode',
      default: null,
      require: true
    },
    {
      key: 'bankAddress',
      default: null,
      require: true
    },
    {
      key: 'correspondentBankName',
      default: null
    },
    {
      key: 'correspondentBankBranchAccountNumber',
      default: null
    },
    {
      key: 'correspondentBankSwiftCode',
      default: null
    },
    {
      key: 'referenceProofBankFileToken',
      default: null
    }
  ],
  address: [
    {
      key: 'addressCountryCode',
      default: null,
      require: true
    },
    {
      key: 'addressLineOne',
      default: null,
      require: true
    },
    {
      key: 'addressLineTwo',
      default: null
    },
    {
      key: 'addressTown',
      default: null,
      require: true
    },
    {
      key: 'addressState',
      default: null,
      require: true
    },
    {
      key: 'zipCode',
      default: null,
      require: true,
      max: 20
    },
    {
      key: 'referenceProofAddressFileToken',
      default: null
    }
  ]
};

export const profileValidatorFn = (
  value: { [key: string]: any } | undefined,
  type: ProfileFormKeyType,
  document: {
    documentRequired: boolean;
    document: any;
  }
): boolean => {
  const options = profileFormOption[type];

  if (!options || !value) {
    return false;
  }

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    if (option.require && !value[option.key]) {
      return false;
    }

    if (option.max && (!value[option.key] || value[option.key].length > option.max)) {
      return false;
    }
  }

  const { documentRequired, document: doc } = document;

  if (!documentRequired) {
    return true;
  }

  return !!doc;
};
