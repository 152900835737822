<div class="top-area">
  <mat-progress-bar
    class="progress-bar"
    [mode]="loading ? 'indeterminate' : 'determinate'"
    [value]="progress"
  ></mat-progress-bar>
  <div class="text-primary-500 my-0 mb-2 text-3xl font-semibold md:my-8 md:mb-4">
    {{ progress }}%
  </div>
</div>
<form
  [formGroup]="form"
  class="flex flex-1 flex-col"
  (ngSubmit)="next()"
  #ngForm="ngForm"
>
  <h3 class="text-lg font-semibold text-black mb-2">
    <span *ngIf="(question | async)?.required" class="text-red-600">* </span
    >{{ (question | async)?.title?.en }}
  </h3>
  <formly-form
    [fields]="question | async | questionFormly"
    [model]="model"
    [form]="form"
    [options]="options"
    class="flex-1"
  ></formly-form>
  <ng-template #footer>
    <button
      mat-stroked-button
      color="primary"
      type="button"
      *ngIf="(question | async)?.previousQuestionId"
      class="mat-button-lg w-full md:!w-40"
      (click)="prev()"
      [disabled]="loading"
    >
      {{ 'Previous' | buttonName | async }}
    </button>
    <button
      mat-flat-button
      class="mat-button-lg w-full md:!w-40"
      (click)="ngForm.ngSubmit.emit()"
      color="primary"
      [disabled]="loading"
    >
      {{ 'Next' | buttonName | async }}
    </button>
  </ng-template>
  <button type="submit" hidden></button>
</form>
