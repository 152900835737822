export enum PortalComponentVisibilityState {
  SHOW = 'show',
  HIDE = 'hide',
  show = 'SHOW', //! big brain moment
  hide = 'HIDE',
}

export enum PortalComponentItemKey {
  SUMMARY_TILES = 'SUMMARY_TILES',
  ANALYSIS = 'ANALYSIS',
  SUBSCRIPTION = 'SUBSCRIPTION',
  NAV_REPORT = 'NAV_REPORT',
  PERFORMANCE_METRICS = 'PERFORMANCE_METRICS',
  CAPITAL_CALLS = 'CAPITAL_CALLS',
  TRANSACTION_RECORD = 'TRANSACTION_RECORD',
  COMPLIANCE_DOCUMENTS = 'COMPLIANCE_DOCUMENTS',
  REPORTS = 'REPORTS',
  OVERVIEW_TILES = 'OVERVIEW_TILES',
  METRIC_NAV = 'METRIC_NAV',
  METRIC_DISTRIBUTION = 'METRIC_DISTRIBUTION',
  METRIC_COMMITTED = 'METRIC_COMMITTED',
  METRIC_INVESTED = 'METRIC_INVESTED',
  METRIC_UNDRAWN = 'METRIC_UNDRAWN',
  METRIC_REDEEMED = 'METRIC_REDEEMED',
  NAV_GRAPHS = 'NAV_GRAPHS',
}

export type PortalComponentItemType = 'dashboard' | 'reporting';

export interface PortalComponent<T = PortalComponentItemType> {
  id: string;
  itemKey: PortalComponentItemKey;
  orderIndex: number;
  visibilityState: PortalComponentVisibilityState;
  itemName: string;
  itemDescription: string;
  parentId: number;
  type: T;
  metric: PortalComponent<T>[] | null;
  footnote?: string;
  footnoteVisibilityState?: 'show' | 'hide' | 'SHOW' | 'HIDE';
}

export type PortalComponentKeyMap = {
  [key in PortalComponentItemType]: Partial<
    Record<
      PortalComponentItemKey,
      Partial<Omit<PortalComponent, 'orderIndex' | 'metric'>> & {
        orderIndex: PortalComponent['orderIndex'];
        footnote?: string;
        footnoteVisibilityState?: 'show' | 'hide' | 'SHOW' | 'HIDE';
      }
    >
  >;
};

export interface PortalComponents {
  type: PortalComponentItemType;
  portalComponentList: PortalComponent[];
}

export interface WMChannelGP {
  linkUrl: string;
  sourceCompanyName: string;
  email: string;
  isChannelGp: boolean;
  targetCompanyName: string;
}
