<mat-drawer-container>
  <mat-drawer-content>
    <div
      (click)="!_disabled && !disabledSelect && drawer.toggle()"
      class="rounded border bg-white"
      [ngClass]="{
        'border-[rgb(229,231,235)]': !(_disabled || disabledSelect),
        'border-[#DEE2E6]': _disabled || disabledSelect,
        'cursor-pointer': !(_disabled || disabledSelect),
        'cursor-not-allowed bg-gray-200': _disabled,
        'cursor-default': disabledSelect
      }"
    >
      <div
        class="flex items-center justify-between px-6 py-8"
        *ngIf="!_transmitValue; else selectedOption"
      >
        <span
          class="flex items-center font-sans text-base"
          [ngClass]="{
            'text-[#ADB5BD]': disabledSelect
          }"
        >
          <svg class="icon svg-icon mr-4 inline text-2xl" aria-hidden="true">
            <use [attr.xlink:href]="icon"></use>
          </svg>
          {{ nameSelector || 'Choose ' + name }}
        </span>
        <svg *ngIf="!disabledSelect" class="icon svg-icon inline text-2xl" aria-hidden="true">
          <use xlink:href="#icon-a-Lineicons2"></use>
        </svg>
      </div>
      <ng-template #selectedOption>
        <div class="selected-wrapper relative p-[1.125em]">
          <ng-container
            *ngTemplateOutlet="
              optionTemplate;
              context: { $implicit: _transmitValue, selected: true }
            "
          ></ng-container>
          <svg
            *ngIf="!disabledSelect"
            class="icon svg-icon absolute bottom-0 right-6 top-0 my-auto inline h-6 text-2xl hover:text-primary-500"
          >
            <use xlink:href="#icon-qiehuan"></use>
          </svg>
        </div>
      </ng-template>
    </div>
  </mat-drawer-content>

  <mat-drawer #drawer position="end" class="!fixed w-full max-w-xl" (openedStart)="onDrawerOpen()">
    <div class="flex h-full flex-col pt-6 [&>*]:px-6">
      <h1 class="mb-5 flex-none font-sans text-xl font-semibold first-letter:uppercase">
        {{ nameDrawerTitle || name + ' list' }}
      </h1>
      <div class="flex-1 overflow-auto">
        <mat-radio-group
          class="flex flex-col items-stretch space-y-4"
          #optionGroup="matRadioGroup"
          [value]="_value?.[valueKey]"
        >
          <mat-radio-button
            *ngFor="let option of getOptions(options | async)"
            [value]="option?.[valueKey]"
            class="radio-button group relative"
            #optionButton="matRadioButton"
          >
            <ng-container
              *ngTemplateOutlet="
                optionTemplate;
                context: { $implicit: option, checked: optionButton.checked }
              "
            ></ng-container>

            <mat-icon
              class="edit-icon invisible absolute inset-y-0 right-6 my-auto text-lg text-gray-500 underline transition-all hover:text-primary-500 group-hover:visible"
              (click)="$event.preventDefault(); edit.emit(option)"
              *ngIf="showEdit"
              >edit</mat-icon
            >
          </mat-radio-button>
          <div
            tabindex="0"
            class="flex cursor-pointer items-center rounded border border-dashed border-gray-300 p-8 text-base"
            (click)="add.emit()"
            *ngIf="!disableCreate && totalLimitForAdd > getOptions(options | async).length"
          >
            <mat-icon class="mr-2 text-2xl leading-none"> add </mat-icon>
            {{ nameDrawerAdd || 'Create a new one' }}
          </div>
        </mat-radio-group>
      </div>
      <div class="flex flex-none justify-end gap-2 p-6 shadow-[0px_-4px_8px_rgba(0,0,0,0.12)]">
        <button
          mat-stroked-button
          color="primary"
          type="button"
          class="flex-1 md:flex-none md:!px-16"
          (click)="drawer.close()"
          *ngIf="!disableCreate"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="primary"
          type="button"
          class="flex-1 md:flex-none md:!px-16"
          (click)="submit(optionGroup.value)"
        >
          Submit
        </button>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
