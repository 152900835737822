import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@stream/models';
import { map } from 'rxjs/operators';

import { ScenarioPanelService } from '../../services/scenario-panel.service';

@Component({
  selector: 'stream-end',
  templateUrl: './gateway-result.component.html',
  styleUrls: ['./gateway-result.component.scss'],
})
export class GatewayResultComponent {
  constructor(public panelService: ScenarioPanelService, private router: Router) {}

  store = new Store({ type: 'session', namespace: 'scenario' });

  hitResultShow = this.panelService.resultGateway.pipe(
    map(result => {
      if (result) {
        const { hitResultShow } = result;
        if (hitResultShow?.salesSupportEmail) {
          hitResultShow.mailto = `mailto:${hitResultShow?.salesSupportEmail}`;
        }
        return result?.hitResultShow;
      }
      return null;
    }),
  );

  get isKYCProcess() {
    return this.panelService.resultGateway.pipe(
      map(result => result?.gatewayInnerType === 'KYC'),
    )
  }

  close() {
    this.store.clear('scenario');
    this.panelService.leave.next('');
  }

  redirect() {
    this.store.clear('scenario');
    this.panelService.leave.next("redirect");
    const { productId } = this.panelService.extraParams;
    this.router.navigate(
      productId ? [
        '/portfolio/investments/detail',
        productId,
      ] : [
        '/portfolio/investments'
      ]
    );
  }
}
