export const CONTROLLING_PERSON_TYPE = [
  {
    en: 'Legal person - control by ownership',
    key: 'ownership',
    zh: '法人控制人——以所有权形式控制',
  },
  {
    en: 'Legal person - control by other means',
    key: 'other_means',
    zh: '法人控制人——以其他形式控制',
  },
  {
    en: 'Legal person - senior managing official',
    key: 'senior',
    zh: '法人控制人——高层管理人员',
  },
  {
    en: 'Trust - settlor',
    key: 'settlor',
    zh: '信托控制人——财产授予人',
  },
  {
    en: 'Trust - trustee',
    key: 'trustee',
    zh: '信托控制人——受托人',
  },
  {
    en: 'Trust - protector',
    key: 'protector',
    zh: '信托控制人——保护人',
  },
  {
    en: 'Trust - beneficiary',
    key: 'beneficiary',
    zh: '信托控制人——受益人',
  },
  {
    en: 'Trust - other',
    key: 'other',
    zh: '信托控制人——其他',
  },
  {
    en: 'Legal arrangement (non-trust) - settlor-equivalent',
    key: 'settlor_eq',
    zh: '法律安排（非信托）的控制人——相当于财产授予人',
  },
  {
    en: 'Legal arrangement (non-trust) - trustee-equivalent',
    key: 'trustee_eq',
    zh: '法律安排（非信托）的控制人——相当于受托人',
  },
  {
    en: 'Legal arrangement (non-trust) - protector-equivalent',
    key: 'protector_eq',
    zh: '法律安排（非信托）的控制人——相当于保护人',
  },
  {
    en: 'Legal arrangement (non-trust) - beneficiary-equivalent',
    key: 'beneficiary_eq',
    zh: '法律安排（非信托）的控制人——相当于受益人',
  },
  {
    en: 'Legal arrangement (non-trust) - other-equivalent',
    key: 'other_eq',
    zh: '法律安排（非信托）的控制人——相当于其他',
  },
];
