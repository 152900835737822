import { DataRoomDocumentType } from '@stream/models';

export const getIconByType = (
  type: DataRoomDocumentType = DataRoomDocumentType.Pdf
) => {
  return {
    [DataRoomDocumentType.Pdf]: '#icon-PDF',
    [DataRoomDocumentType.Image]: '#icon-a-zhaopian11',
    [DataRoomDocumentType.Excel]: '#icon-a-Property1Variant19',
    [DataRoomDocumentType.Folder]: '#icon-wenjianguanbi',
  }[type];
};
