import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  Optional,
  Self,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MandatoryDocFile, MandatoryDocument } from '@stream/models';
import { FilePreviewerComponent } from '@stream/ngx-utils';
import { getIconByType } from '@stream/utils';
import { Observable, Subject } from 'rxjs';

import { MandatoryDocComponent, MandatoryDocService } from '@stream/mandatory-doc';

@Component({
  selector: 'stream-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements ControlValueAccessor, OnDestroy {
  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private mandatoryDocService: MandatoryDocService,
    private cdr: ChangeDetectorRef
  ) {
    if (this.ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  @Input()
  profileId?: string;

  private _required = false;
  private _disabled = false;
  focused = false;
  touched = false;
  stateChanges = new Subject<void>();
  loading = false;
  mandatoryDialog!: MatDialogRef<MandatoryDocComponent>;
  getIconByType = getIconByType;

  @Input()
  mandatory!: MandatoryDocument;

  @Input()
  fileListRef!: TemplateRef<any>;

  @Input()
  fileName?: string;

  @Input()
  placeholder = 'You can upload a new file or select one from your investor profile';

  @Input()
  multiple = false;

  @Input()
  title = 'Add document';

  @Input()
  accept?: string;

  @Input()
  maxSize = 30 * 1024 * 1024;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  uploadFn?: (file: File) => Observable<MandatoryDocFile>;

  get showUploadButton() {
    const { multiple } = this;
    if (multiple) {
      return true;
    }
    if ((this.ngControl.value || []).length > 0) {
      return false;
    }
    return true;
  }

  onChange?: (v: MandatoryDocFile[]) => void;

  onTouched?: () => void;

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.stateChanges.next();
  }

  deleteFile(index: number) {
    const newValue = ((this.ngControl.value as Array<MandatoryDocFile>) || []).filter(
      (_f, i) => index !== i
    );
    this.ngControl.control?.patchValue(newValue);
  }

  preview(doc: MandatoryDocFile) {
    if (!doc) {
      return;
    }
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.dialog.open(FilePreviewerComponent, {
      data: {
        type: doc.type,
        url: doc.url,
        downloadUrl: doc.downloadUrl,
        name: doc.name
      },
      maxWidth: isMedium ? '960px' : '90vw',
      maxHeight: '100vh',
      width: '100%'
    });
  }

  openMdSelector(refresh?: boolean, index?: number) {
    const { multiple } = this.mandatory;
    this.mandatoryDialog = this.mandatoryDocService.open({
      profileId: this.profileId,
      mandatoryDocs: [{ ...this.mandatory }],
      loadInitValue: false,
      allowReuse: true
    });
    this.mandatoryDialog.afterClosed().subscribe(result => {
      if (!(result && result[0])) {
        return;
      }

      const v = result[0];
      if (multiple) {
        if (!refresh) {
          this.ngControl.control?.patchValue([...this.ngControl.control.value, ...v.files]);
        }
        if (refresh && typeof index === 'number') {
          this.ngControl.control?.patchValue(
            ((this.ngControl.control?.value as Array<MandatoryDocFile>) || []).map((f, i) =>
              i !== index ? f : v.files[0]
            )
          );
        }
      } else {
        this.ngControl.control?.patchValue(v.files);
      }

      this.cdr.detectChanges();
    });
  }

  refreshFile(index: number) {
    this.openMdSelector(true, index);
  }
}
