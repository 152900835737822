<div class="relative flex flex-col gap-y-4">
  <div
    *ngTemplateOutlet="
      fileListRef || defaultFileList;
      context: { uploadRef: this }
    "
  ></div>
  <div
    class="loading-modal absolute top-0 left-0 flex h-full w-full items-center justify-center"
    *ngIf="loading && !showUploadButton"
  ></div>
  <div
    #UPLOAD_BUTTON
    *ngIf="showUploadButton"
    [class]="{
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
      'upload-disabled': disabled
    }"
    class="upload-button relative flex min-h-[82px] w-full flex-col items-center justify-center rounded border border-dashed border-[#000] border-opacity-10 bg-white py-3"
    (click)="loading || disabled ? null : input.click()"
    (drop)="onDrop($event)"
    (dragleave)="onDragLeave($event)"
    (dragover)="onDragOver($event)"
  >
    <ng-container *ngTemplateOutlet="trigger || defaultTrigger"></ng-container>
    <ng-template #defaultTrigger>
      <span class="upload-title text-base text-primary-500 underline">
        Upload
      </span>
    </ng-template>
    <span class="upload-placeholder text-[#62656C]">{{ placeholder }}</span>
    <input
      class="file-input"
      type="file"
      hidden
      #input
      (input)="onInput($event)"
      [accept]="accept"
    />
    <div
      class="loading-modal absolute top-0 left-0 flex h-full w-full items-center justify-center"
      *ngIf="loading"
    ></div>
  </div>
  <div *ngTemplateOutlet="errorMsgRef; context: { uploadRef: this }"></div>
</div>

<ng-template #defaultFileList let-uploadRef="uploadRef">
  <div
    *ngFor="let doc of ngControl.value || []; let i = index"
    class="rounded-md border border-dashed border-[#000] border-opacity-10 bg-white p-3"
  >
    <div
      class="flex w-full items-center gap-x-2 rounded bg-[#F8F9FA] py-2 px-4"
    >
      <svg class="icon text-lg">
        <use [attr.xlink:href]="uploadRef.getIconByType(doc?.type)"></use>
      </svg>
      <span
        class="flex-1 cursor-pointer text-sm text-gray-700 line-clamp-1"
        (click)="uploadRef.preview(doc)"
      >
        {{ doc.name }}
      </span>
      <div class="flex items-center gap-x-1">
        <button
          mat-icon-button
          type="button"
          *ngIf="!uploadRef.multiple"
          (click)="
            uploadRef.loading || uploadRef.disabled ? null : reUpload.click()
          "
        >
          <input
            type="file"
            hidden
            #reUpload
            (input)="uploadRef.onInput($event)"
            [accept]="uploadRef.accept"
            [multiple]="false"
          />
          <mat-icon class="text-gray-500">replay</mat-icon>
        </button>
        <button mat-icon-button type="button" (click)="uploadRef.deleteFile(i)">
          <mat-icon class="text-gray-500">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
