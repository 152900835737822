import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdditionalDocument,
  AddMandatoryDocumentResponse,
  Address,
  Bank,
  CrmApi,
  Custodian,
  DataRoomDocumentType,
  DocumentTagApi,
  Entity,
  EntityMember,
  GpPolicy,
  GroupType,
  HttpStatusEnum,
  Identity,
  Individual,
  IndividualType,
  InvestAccount,
  LpAccountApi,
  MandatoryDocument,
  MetaApi,
  MetaCountry,
  policy,
  PolicyApi,
  policyType,
  Principal,
  productSubscription,
  Profile,
  ProfileType,
  Restful,
  SubscriptionProfileApi,
  TenantAttribute
} from '@stream/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, pluck, repeatWhen, shareReplay, tap } from 'rxjs/operators';

import { Rest } from '../../../portal/interface/http';
import {
  accountStatus,
  currentSales,
  Investor,
  MemberStatus,
  SignLog
} from '../../../portal/model/investor.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  profileChange = new Subject<void>();

  memberChange = new Subject<void>();

  profileUpdate = new Subject<void>();

  // TODO: use account service's data and remove this when the libraries has moved to app.
  investAccount$ = new BehaviorSubject<InvestAccount>({} as InvestAccount);
  accountUpdated = new Subject();
  account = this.getAccount().pipe(
    pluck('data', 'investAccount'),
    repeatWhen(() => this.accountUpdated),
    shareReplay(1)
  );

  private getAccount() {
    return this.http
      .post<
        Restful<{
          investAccount: InvestAccount;
          investPrincipals: Principal[];
          status: HttpStatusEnum;
        }>
      >(LpAccountApi.GetAccount, {})
      .pipe(
        tap(({ data: { investAccount } }) => {
          this.investAccount$.next(investAccount);
        })
      );
  }

  investPrincipals$ = new BehaviorSubject<Principal[]>([]);
  principalInvestAccount$ = new BehaviorSubject<InvestAccount>({} as InvestAccount);
  investPrincipalsUpdated = new Subject();
  investPrincipals = this.getInvestPrincipals().pipe(
    pluck('data', 'investPrincipals'),
    repeatWhen(() => this.accountUpdated),
    shareReplay(1)
  );

  getInvestPrincipals() {
    return this.http
      .post<
        Restful<{
          investAccount: InvestAccount;
          investPrincipals: Principal[];
          status: HttpStatusEnum;
        }>
      >(LpAccountApi.GetAccount, {})
      .pipe(
        tap(({ data }) => {
          this.investPrincipals$.next(data.investPrincipals);
          this.principalInvestAccount$.next(data.investAccount);
        })
      );
  }

  countryList = this.http
    .get<Restful<{ metaCountries: MetaCountry[] }>>(MetaApi.QueryCountries)
    .pipe(pluck('data', 'metaCountries'), shareReplay(1));

  /**
   * 查询政策
   */
  GetPolicyByType(type: string) {
    return this.http.get<Restful<GpPolicy>>(PolicyApi.GetPolicy, {
      params: { policyEnum: type }
    });
  }

  getProfileList(profileType?: ProfileType) {
    return this.http.get<
      Restful<{
        investorProfileEntityList: Profile[];
        // ????
        investorProfileResponseEnum?: 'NO_DATA';
      }>
    >(SubscriptionProfileApi.GetProfileList, {
      params: profileType ? { profileTypeEnum: profileType } : {}
    });
  }

  deleteProfile(profileId: Profile['id']) {
    return this.http
      .delete(SubscriptionProfileApi.DeleteProfile, {
        params: { profileId }
      })
      .pipe(
        finalize(() => {
          this.profileChange.next();
        })
      );
  }

  getProfileInfo(profileId: Profile['id']) {
    return this.http.get<Restful<{ investorProfileEntity: Profile }>>(
      SubscriptionProfileApi.GetProfileInfo,
      { params: { profileId } }
    );
  }

  getIndividualMetaInfo() {
    return this.http.get<Restful<{ investorProfileMetaIndividual?: Individual }>>(
      SubscriptionProfileApi.GetIndividualMetaInfo
    );
  }

  patchIndividual(
    model: Record<string, any>,
    params?: {
      id?: Individual['id'];
      individualType?: IndividualType;
    }
  ) {
    return this.http
      .post<
        Restful<{
          investorProfileMetaIndividual: Individual;
          metaIndividualId: Individual['id'];
        }>
      >(SubscriptionProfileApi.PatchIndividual, {
        ...model,
        ...params
      })
      .pipe(
        tap(() => {
          this.memberChange.next();
        })
      );
  }

  getPersons() {
    return this.http.get<Restful<{ investorProfileMetaIndividualList: Individual[] }>>(
      SubscriptionProfileApi.GetPersons
    );
  }

  getEntityMembers(entityId: Entity['id']) {
    return this.http
      .get<Restful<{ investorProfileMetaInfoMembersList: EntityMember[] }>>(
        SubscriptionProfileApi.GetMembers,
        { params: { entityId } }
      )
      .pipe(repeatWhen(() => this.memberChange));
  }

  patchEntityMember(model: Record<string, any>) {
    return this.http.post(SubscriptionProfileApi.PatchMember, model);
  }

  deleteEntityMember(
    id: EntityMember['id'],
    referenceInvestorMetaIndividualId: EntityMember['referenceInvestorMetaIndividualId'],
    referenceInvestorProfileId: productSubscription['id']
  ) {
    return this.http.delete(SubscriptionProfileApi.DeleteMember, {
      body: {
        id,
        referenceInvestorMetaIndividualId,
        referenceInvestorProfileId
      }
    });
  }

  getAddressList(params?: { entityId?: Entity['id']; metaIndividualId?: Individual['id'] }) {
    return this.http.get<Restful<{ investorProfileMetaInfoAddressList: Address[] }>>(
      SubscriptionProfileApi.GetAddressList,
      {
        params: {
          investorProfileType: params?.entityId ? ProfileType.Entity : ProfileType.Individual,
          ...(params ?? {})
        }
      }
    );
  }

  getBankList(entityId?: Entity['id']) {
    return this.http.get<Restful<{ investorProfileMetaInfoBankList: Bank[] }>>(
      SubscriptionProfileApi.GetBank,
      {
        params: {
          investorProfileType: entityId ? ProfileType.Entity : ProfileType.Individual,
          ...(entityId ? { entityId } : {})
        }
      }
    );
  }

  patchBank(
    bank: Bank,
    params: {
      id?: Bank['id'];
      referenceInvestorProfileMetaEntityId?: Entity['id'];
      investorProfileType?: ProfileType;
    }
  ) {
    return this.http.post(SubscriptionProfileApi.PatchBank, {
      ...params,
      ...bank
    });
  }

  patchAddress(
    address: Address,
    params: {
      id?: Address['id'];
      referenceInvestorProfileMetaEntityId?: Entity['id'];
      investorProfileType?: ProfileType;
      metaIndividualId?: Individual['id'];
    }
  ) {
    return this.http.post(SubscriptionProfileApi.PatchAddress, {
      ...params,
      ...address
    });
  }

  getIRAList(params?: { entityId?: Entity['id']; metaIndividualId?: Individual['id'] }) {
    return this.http.get<Restful<{ investorProfileMetaInfoIRAList: Custodian[] }>>(
      SubscriptionProfileApi.GetIRA,
      {
        params: {
          investorProfileType: params?.entityId ? ProfileType.Entity : ProfileType.Individual,
          ...(params ?? {})
        }
      }
    );
  }

  patchIRA(params: {
    profileId: string;
    taxCode: string;
    taxType: string;
    mtAccountNumberLast4: string;
    accountDocumentId: string;
    contactDocumentId: string;
  }) {
    return this.http.post(SubscriptionProfileApi.PatchIRA, {
      ...(params ?? {})
    });
  }

  getMTDocumentList(params: { type?: string }) {
    return this.http.get<Restful<{ data: unknown }>>(SubscriptionProfileApi.GetMTDocumentList, {
      params: {
        ...params
      }
    });
  }

  downloadMTDocumentFile(params: { fileId: string }) {
    return this.http.get(
      SubscriptionProfileApi.DownloadMTDocumentFile.replace(':fileId', params.fileId),
      { headers: {}, responseType: 'blob' }
    );
  }

  getIdentityList(params?: { entityId?: Entity['id']; metaIndividualId?: Individual['id'] }) {
    return this.http.get<Restful<{ investorProfileMetaInfoCertificateList: Identity[] }>>(
      SubscriptionProfileApi.GetIdentity,
      {
        params: {
          investorProfileType: params?.entityId ? ProfileType.Entity : ProfileType.Individual,
          ...(params ?? {})
        }
      }
    );
  }

  patchIdentity(
    identity: Identity,
    params: {
      id?: Identity['id'];
      referenceInvestorProfileMetaEntityId?: Entity['id'];
      investorProfileType?: ProfileType;
    }
  ) {
    return this.http.post(SubscriptionProfileApi.PatchIdentity, {
      ...params,
      ...identity
    });
  }

  patchProfile(model: Record<string, any>) {
    return this.http.post<
      Restful<{
        productSubscriptionProfile: {
          id: Profile['id'];
          referenceProfileMetaId: string;
        };
        investorProfileEntity: Profile;
      }>
    >(SubscriptionProfileApi.PatchProfile, model);
  }

  getTenantAttributeList(postData: { tenantId: string; groupType: GroupType.IDENTITY }) {
    return this.http.get<Restful<{ tenantAttributes: TenantAttribute[]; status: string }>>(
      `${SubscriptionProfileApi.GetTenantAttribute}/${postData.tenantId}/attributes?groupType=${postData.groupType}`
    );
  }

  getBankCodePolicy(postData: { tenantId: string; query: string }) {
    return this.http.get<Restful<{ gpPolicies: policy[]; status: string }>>(
      `${PolicyApi.getBankCodePolicy.replace(':tenantId', postData.tenantId)}?q=${postData.query}`
    );
  }

  // query investor profile Additional document
  getAdditionalDocument(getData: { profileId: string; custom?: boolean; investorId?: string }) {
    return this.http.get<Restful<AdditionalDocument[]>>(
      `${SubscriptionProfileApi.GetAdditionalDocument.replace(
        ':profileId',
        getData.profileId
      )}?custom=${getData.custom}`
    );
  }

  // update investor profile Additional document
  postAdditionalDocument(postData: { profileId: string; documentId: string; file: File }) {
    let form = new FormData();
    form.append('file', postData.file);
    return this.http.post<
      Restful<{
        url: string;
        documentName: string;
        documentType: DataRoomDocumentType;
        id: string;
        status?: string;
      }>
    >(
      `${SubscriptionProfileApi.PostAdditionalDocument.replace(
        ':profileId',
        postData.profileId
      ).replace(':documentId', postData.documentId)}`,
      form
    );
  }

  addNewMandatoryDocument(
    profileId: string,
    params: {
      files: MandatoryDocument[];
    }
  ) {
    return this.http.post<Restful<{ status: AddMandatoryDocumentResponse }>>(
      SubscriptionProfileApi.AddNewMandatoryDocument.replace(':profileId', profileId),
      params
    );
  }

  getTenantTags(params?: { groupName: string }) {
    return this.http.get<
      Restful<
        {
          countryAlpha2Codes: string[];
          id: string;
          name: string;
        }[]
      >
    >(DocumentTagApi.getDocumentTags, {
      params
    });
  }

  getToolTipInfo(policyType: policyType) {
    return this.http.get<Restful<{ gpPolicies: policy[]; status: string }>>(
      `${PolicyApi.getBankCodePolicy}?q=${encodeURIComponent(`policyTypes="${policyType}"`)}`
    );
  }

  getPrincipalDetail(params: { principalId: string }) {
    return this.http.post<
      Rest<{
        hasInviteButton: boolean;
        principal: Investor;
        signLogsList: Array<SignLog>;
        status: string;
        principalGroupList: Array<{ name: string; id: number }>;
        accountStatus: MemberStatus;
        sale: currentSales;
        principalAccountStatus: accountStatus;
        sourceName?: string;
        sourceLogo?: string;
        wmCandidate?: boolean;
        ownerInvestAccountId?: string;
      }>
    >(CrmApi.principalDetail, { ...params });
  }

  /**
   * @description delete additional document in profile
   * @param params
   * @returns
   */
  deleteAdditionalDocument(params: { profileId: string; documentId: string }) {
    const { profileId, documentId } = params;
    const url = SubscriptionProfileApi.DeleteAdditionalDocument.replace(
      ':profileId',
      profileId
    ).replace(':documentId', documentId);
    return this.http.delete<Restful<{ status: string }>>(url);
  }
}
