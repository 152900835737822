import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'stream-rich-text',
  template: `
    <quill-view [content]="to.label" format="json" theme="snow"></quill-view>
  `,
  encapsulation: ViewEncapsulation.ShadowDom,
  styles: [
    `
      @import '~quill/dist/quill.core.css';
      @import '~quill/dist/quill.bubble.css';
      @import '~quill/dist/quill.snow.css';
      .ql-container {
        border: none !important;
      }
      .ql-clipboard {
        display: none !important;
      }
      .ql-editor {
        padding: 0;
      }
    `,
  ],
})
export class RichTextComponent extends FieldType {}
