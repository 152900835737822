<div class="flex h-full flex-col" *ngIf="conversationInfo">
  <div class="m-[30px] mb-0 border-b border-solid border-[#CED4DA]">
    <div class="relative flex pb-6">
      <span class="material-icons h-6 w-6 cursor-pointer" (click)="back()"
        >keyboard_arrow_left</span
      >
      <div
        class="line-clamp-2 flex-grow whitespace-normal break-all px-2 text-[22px] font-semibold leading-tight text-[#101213]"
        nz-popover
        [nzPopoverContent]="titleToolTip ? title : ''"
        nzPopoverOverlayClassName="chat-tooltip"
        [nzPopoverOverlayStyle]="{ maxWidth: titleWrapper.clientWidth + 'px' }"
        nzPopoverPlacement="bottom"
        [nzPopoverArrowPointAtCenter]="true"
      >
        <div #titleWrapper>
          {{ conversationInfo.category.title }}:{{ conversationInfo.subject }}
        </div>
      </div>
      <ng-template #title>
        <div class="color-[#2C2934] overflow-hidden break-all py-3 text-base">
          {{ conversationInfo.category.title }}:{{ conversationInfo.subject }}
        </div>
      </ng-template>
      <button mat-icon-button class="-top-3 !h-6 !w-6 !p-0" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="pb-3 text-sm md:flex md:flex-row-reverse md:justify-between md:gap-x-2">
      <div class="flex-shrink-0 flex-grow-0 basis-[153px] text-[14px] leading-6">
        {{ conversationInfo.dataCreateTime | accountDate : 'dd MMM yyyy, h:mm a' | async }}
      </div>
      <div class="mt-2 overflow-hidden md:mt-0">
        <stream-chat-members-management
          [conversationId]="conversationInfo.id"
        ></stream-chat-members-management>
      </div>
    </div>
    <div *ngIf="subscription?.fundName" class="pb-3">
      <stream-chat-fund-info [subscription]="subscription!"></stream-chat-fund-info>
    </div>
  </div>
  <stream-chat-message-list [lastReadTime]="lastReadTime"></stream-chat-message-list>
</div>
