<div class="!flex items-center" mat-dialog-title>
  <h1 class="font-sans font-bold text-xl flex-1">
    {{ isWitness ? 'Please identity yourself' : 'Witness information' }}
  </h1>
  <button mat-icon-button mat-dialog-close="">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="space-y-6 max-w-2xl !pt-6">
  <ng-container *ngIf="!isWitness; else witnessDesc">
    <div
      class="flex bg-orange-300 bg-opacity-20 rounded-lg ring-2 ring-orange-300 p-3 gap-2"
    >
      <mat-icon class="text-orange-300 flex-none">info</mat-icon>
      <p>
        Please ensure your witness is available to witness you signing the documents in
        the next step before providing their information below.
      </p>
    </div>
    <p>
      These documents require a witness to complete the signing process. Please enter the
      information for the witness below.
    </p>
  </ng-container>
  <ng-template #witnessDesc>
    <p>
      You have been identified as a signing witness for {{ getSignerName() }}. Please
      enter or correct the information below.
    </p>
  </ng-template>
  <form
    [formGroup]="form"
    class="grid grid-cols-2 gap-x-4 gap-y-1"
    style="
      --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.2);
      --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.2);
    "
  >
    <div class="sign-form-item col-span-1">
      <label class="sign-form-label" for="firstName" required> First name </label>
      <mat-form-field>
        <input id="firstName" matInput formControlName="firstName" autocomplete="off" />
        <mat-error> The field is required. </mat-error>
      </mat-form-field>
    </div>
    <div class="sign-form-item col-span-1">
      <label class="sign-form-label" for="lastName" required> Last name </label>
      <mat-form-field>
        <input id="lastName" matInput formControlName="lastName" autocomplete="off" />
        <mat-error> The field is required. </mat-error>
      </mat-form-field>
    </div>
    <div class="sign-form-item col-span-2">
      <label class="sign-form-label" for="email" required> Email </label>
      <mat-form-field>
        <input id="email" matInput formControlName="email" autocomplete="off" />
        <mat-error>{{ getEmailCtrError() }}</mat-error>
      </mat-form-field>
    </div>
    <div class="sign-form-item col-span-2">
      <label class="sign-form-label" for="phoneNumber" required> Phone number </label>
      <div class="flex w-full gap-x-4" formGroupName="phoneNumber">
        <mat-form-field class="w-40">
          <mat-select
            formControlName="areaCode"
            panelClass="min-w-[400px]"
            autocomplete="off"
          >
            <mat-option *ngFor="let country of areaCodes" [value]="country.value">
              {{ country.label }}
            </mat-option>
          </mat-select>
          <mat-error> The field is required. </mat-error>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <input id="phoneNumber" matInput formControlName="number" autocomplete="off" />
          <mat-error> The field is required. </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="sign-form-item col-span-2" *ngIf="isWitness">
      <label class="sign-form-label" for="occupation" required> Occupation </label>
      <mat-form-field>
        <input
          #occupation
          id="occupation"
          matInput
          formControlName="occupation"
          autocomplete="off"
        />
        <mat-error> The field is required. </mat-error>
      </mat-form-field>
    </div>

    <div class="sign-form-item col-span-2" *ngIf="isWitness">
      <label class="sign-form-label" for="address" required> Address </label>
      <mat-form-field>
        <input
          #address
          id="address"
          matInput
          formControlName="address"
          autocomplete="off"
        />
        <mat-error> The field is required. </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<footer mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" class="large" mat-dialog-close="">
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="submitting"
    class="large"
    (click)="submit()"
  >
    Confirm
  </button>
</footer>
