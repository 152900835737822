import { FormGroup } from '@angular/forms';

export function objectFilterNull<T>(obj: T): T {
  return fromEntries(
    Object.entries(obj!).filter(([, v]) => {
      if (v instanceof Object) {
        return objectFilterNull(v);
      }
      return v !== null && v !== undefined;
    })
  ) as T;
}

export function getFormValidValues(formGroup: FormGroup): Record<string, any> {
  return Object.keys(formGroup.controls).reduce((acc, key) => {
    const control = formGroup.controls[key];
    if (control instanceof FormGroup) {
      return {
        ...acc,
        [key]: getFormValidValues(control as FormGroup)
      };
    }
    return control.valid
      ? {
          ...acc,
          [key]: control.value
        }
      : acc;
  }, {});
}

export function fromEntries(iterable: Iterable<[string, any]>) {
  return [...iterable].reduce((obj: Record<string, any>, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
}
