<!-- <p class="font-semibold text-xl text-[#101213] mb-5">Additional document</p> -->
<div class="file-list">
  <div class="item" *ngFor="let document of additionalDoc; let i = index">
    <div class="download_bg mb-4">
      <div
        class="one_line flex cursor-pointer justify-start"
        (click)="previewFile(document)"
      >
        <svg class="icon text-lg">
          <use [attr.xlink:href]="getIconByType(document.fileType)"></use>
        </svg>
        <!-- <span class="ml-2">{{ document.fileName || document.documentName }}</span>
        <span
          *ngIf="document.tag"
          class="ml-2 rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
        >
          {{ getDocTagLabel(document) }}
        </span> -->
        <div class="max-w-fit flex items-center gap-x-1 text-sm text-gray-700">
          <span class="line-clamp-1">{{ document.fileName || document.documentName || '--' }}</span>
        </div>
        <span *ngIf="document.tag" class="rounded bg-[#fff9eb] py-0.5 px-2 text-black min-w-fit">
          {{ getDocTagLabel(document) }}
        </span>
      </div>
    </div>
  </div>
</div>
