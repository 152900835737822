import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import {
  Address,
  CONTROLLING_PERSON_TYPE,
  DOCUMENT_TYPE,
  EntityMember,
  EntityMemberRole,
  GroupType,
  Identity,
  Individual,
  ProfileType,
  Store,
  TenantAttribute,
  documentTypeEnum,
  policyType
} from '@stream/models';
import { DrawerSelectComponent } from '@stream/ngx-utils';
import { SharedService } from '@stream/shared';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { finalize, map, pluck, take } from 'rxjs/operators';

import { ProfileService } from '../../services/profile.service';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { EntityFormComponent } from '../entity-form/entity-form.component';
import { IdentityDialogComponent } from '../identity-dialog/identity-dialog.component';
import { OwnershipDirectorshipComponent } from '../ownership-directorship/ownership-directorship.component';
import { PersonDialogComponent } from '../person-dialog/person-dialog.component';

@Component({
  selector: 'stream-ownership-director-drawer',
  templateUrl: './ownership-director-drawer.component.html',
  styleUrls: ['./ownership-director-drawer.component.scss']
})
export class OwnershipDirectorDrawerComponent implements AfterViewInit, OnDestroy, OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private entityFormComponent: EntityFormComponent,
    private profileService: ProfileService,
    private dialog: MatDialog,
    public drawer: MatDrawer,
    private ownershipComponent: OwnershipDirectorshipComponent,
    private sharedService: SharedService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ControllingPersonType = CONTROLLING_PERSON_TYPE;

  MemberRole = EntityMemberRole;

  @ViewChild('personSelect')
  personSelect?: DrawerSelectComponent<Individual>;
  @Input() tenantId = '';
  @Input()
  member: EntityMember | null = null;

  @ViewChildren(DrawerSelectComponent)
  drawerSelects: DrawerSelectComponent[] = [];

  loading = false;
  @Output()
  drawerPositionChange = new EventEmitter<string>();

  directorChecked = new BehaviorSubject(false);

  EntityMemberRole = EntityMemberRole;

  memberAddressList: Address[] = [];

  memberIdentityList: Identity[] = [];

  countryList = this.sharedService.countryList;
  countryListSync: Array<{ label: string; value: string }> = [];
  tenantAttributeList: TenantAttribute[] = [];

  tooltipRoles = '';

  personError = false;

  personList = this.profileService.getPersons().pipe(
    pluck('data', 'investorProfileMetaIndividualList'),
    map(data => {
      if (this.member) {
        return data.map(item => (item.id === this.member?.id ? { ...item, ...this.member } : item));
      }
      return data;
    })
  );

  dialogSub = new Subscription();

  isMedium = this.breakpointObserver
    .observe('(min-width: 768px)')
    .pipe(map(({ matches }) => matches));

  store = new Store({ type: 'session', namespace: 'scenario' });

  reModify = !!this.store.get('snapshot');

  form = this.fb.group({
    memberRole: [null, Validators.required],
    isSigningAuthority: [false],
    person: [
      null,
      [
        Validators.required,
        (control: FormControl) => {
          const value = control.value as Individual;

          const personChosen = this.ownershipComponent.members
            .filter(({ id }) => (id !== this.member?.id ? id : undefined))
            .some(
              member => member.referenceInvestorMetaIndividualEntity?.id === value?.id
              // member.referenceInvestorMetaIndividualEntity?.email === value?.email
            );
          return personChosen ? { personChosen: true } : null;
        }
      ]
    ],
    address: [{ value: null, disabled: !this.reModify }, Validators.required],
    identity: [{ value: null, disabled: !this.reModify }, Validators.required]
  });

  get isControllingPerson() {
    return this.form.get('memberRole')?.value === EntityMemberRole.ControllingPerson;
  }

  get legalInfoList() {
    return (this.form.get('legalInfo') as FormArray)?.controls;
  }

  get memberRole() {
    const role = this.form.get('memberRole')?.value as EntityMemberRole;
    if (role === EntityMemberRole.ControllingPerson && this.directorChecked.getValue()) {
      return EntityMemberRole.Director;
    }
    return role;
  }

  private resetValues$ = this.form.get('person')?.valueChanges.subscribe((personValue: any) => {
    const { townOfBirth, jobPositionTitle, taxJurisdiction } = personValue || {};

    this.personError = !townOfBirth || !jobPositionTitle || !taxJurisdiction ? true : false;

    if (!this.reModify) {
      this.form.get('address')?.reset({ value: null, disabled: !personValue });
      this.form.get('identity')?.reset({ value: null, disabled: !personValue });
    }
  });

  private directorChange$ = this.directorChecked.subscribe(v => {
    this.form.get('isSigningAuthority')?.reset({ value: v, disabled: v });
  });

  private roleChange$ = this.form
    .get('memberRole')
    ?.valueChanges.subscribe((v: EntityMemberRole) => {
      this.directorChecked.next(false);

      const isAuthorisedSignatories = v === EntityMemberRole.AuthorisedSignatories;
      const isControllingPerson = v === EntityMemberRole.ControllingPerson;

      if (isControllingPerson || v === EntityMemberRole.Director) {
        this.form.addControl('isUsPerson', new FormControl(null, Validators.required));

        this.form.addControl(
          'percentageOwnership',
          new FormControl('', [Validators.required, this.percentageOwnershipValidator()])
        );
      } else {
        this.form.removeControl('isUsPerson');
        this.form.removeControl('percentageOwnership');
      }

      this.form.get('isSigningAuthority')?.reset({
        value: isAuthorisedSignatories,
        disabled: isAuthorisedSignatories
      });

      if (v === EntityMemberRole.AuthorisedSignatories) {
        this.form.removeControl('legalInfo');
      } else {
        this.form.addControl('legalInfo', this.getLegalInfoControl());
      }
    });

  percentageOwnershipValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.toString().trim();
      if (value === null || value === '') {
        return {
          required: 'Please enter the percentage ownership for the controlling person.'
        };
      }
      const numberValue = parseFloat(value);

      if (isNaN(numberValue) || !/^\d*\.?\d*$/.test(value)) {
        return { invalidFormat: 'Please enter a valid number up to 5 decimal places.' };
      }

      if (numberValue < 0 || numberValue > 100) {
        return {
          percentageOutOfRange: 'Please enter a percentage value between 0 and 100.'
        };
      }

      if (value.includes('.') && value.split('.')[1].length > 5) {
        const roundedValue = numberValue.toFixed(5);
        control.setValue(roundedValue, { emitEvent: false });
      }

      return null;
    };
  }

  private personListChange$?: Subscription;

  ngOnInit(): void {
    const profile = this.store.get('profile');
    if (profile) {
      const { investorProfileMetaInfoMemberList } = profile;
      if (investorProfileMetaInfoMemberList) {
        this.personList = of(
          investorProfileMetaInfoMemberList.map(
            (member: EntityMember) => member.referenceInvestorMetaIndividualEntity
          )
        );
      }
    }

    this.initMember();
    this.countryList.subscribe(countries => {
      this.countryListSync = (countries || []).map(item => ({
        ...item,
        label: item.countryName,
        value: item.countryAlpha2Code
      }));
    });
    this.getIdentityDocument();
    this.getTooltipRoleinTheEntity();
  }

  getTooltipRoleinTheEntity() {
    this.profileService.GetPolicyByType(policyType.ROLE_IN_THE_ENTITY).subscribe(({ data }) => {
      if (data?.gpPolicy) {
        this.tooltipRoles = data?.gpPolicy.policyContext;
      }
    });
  }

  initMember() {
    if (this.member) {
      const role =
        this.member.memberRole === EntityMemberRole.Director
          ? EntityMemberRole.ControllingPerson
          : this.member.memberRole;

      this.form.patchValue({
        memberRole: role,
        isSigningAuthority: this.member.isSigningAuthority,
        person: this.member.referenceInvestorMetaIndividualEntity,
        address: this.member.referenceInfoAddressEntity,
        identity: this.member.referenceInfoCertificateEntity,
        percentageOwnership: this.member.percentageOwnership,
        isUsPerson: this.member.isUsPerson
      });

      if (
        this.member.memberRole !== EntityMemberRole.AuthorisedSignatories &&
        this.member.legalInfo
      ) {
        this.form
          .get('legalInfo')
          ?.patchValue(JSON.parse(this.member.legalInfo as unknown as string));
      }

      if (this.member.memberRole === EntityMemberRole.Director) {
        this.directorChecked.next(true);
      }
    } else {
      this.form.patchValue({
        memberRole: EntityMemberRole.AuthorisedSignatories
      });
    }
  }

  ngAfterViewInit(): void {
    this.drawerSelects.forEach(({ drawer }) => {
      drawer?.openedStart.subscribe(() => {
        this.drawerPositionChange.emit(drawer?._getWidth() + 'px');
      });
      drawer?.closedStart.subscribe(() => {
        this.drawerPositionChange.emit('0');
      });
    });
    this.personListChange$ = this.personSelect?.options.subscribe(() => {
      this.form.get('person')?.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });
  }

  ngOnDestroy(): void {
    this.resetValues$?.unsubscribe();
    this.roleChange$?.unsubscribe();
    this.directorChange$.unsubscribe();
    this.personListChange$?.unsubscribe();
  }

  getMemberAddress() {
    const personId = this.form.get('person')?.value.id;
    const profile = this.store.get('profile');

    this.memberAddressList = [];

    if (profile) {
      const { investorProfileMetaInfoMemberList: memberList = [] } = profile;
      const member =
        memberList.find(
          ({ referenceInvestorMetaIndividualEntity: item }: EntityMember) => item.id === personId
        ) ?? {};
      const { referenceInfoAddressEntity: addressList } = member;

      this.memberAddressList = addressList ? [addressList] : [];

      return;
    }

    this.profileService
      .getAddressList({ metaIndividualId: this.form.get('person')?.value.id })
      .subscribe(({ data: { investorProfileMetaInfoAddressList } }) => {
        this.memberAddressList = investorProfileMetaInfoAddressList;
      });
  }

  getMemberIdentity() {
    const personId = this.form.get('person')?.value.id;
    const profile = this.store.get('profile');

    this.memberIdentityList = [];

    if (profile) {
      const { investorProfileMetaInfoMemberList: memberList = [] } = profile;
      const member =
        memberList.find(
          ({ referenceInvestorMetaIndividualEntity: item }: EntityMember) => item.id === personId
        ) ?? {};
      const { referenceInfoCertificateEntity: identityList } = member;

      this.memberIdentityList = identityList ? [identityList] : [];

      return;
    }

    this.profileService
      .getIdentityList({
        metaIndividualId: this.form.get('person')?.value.id
      })
      .subscribe(({ data: { investorProfileMetaInfoCertificateList } }) => {
        this.memberIdentityList = investorProfileMetaInfoCertificateList;
      });
  }

  openPerson(person?: Individual) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    const dialogRef = this.dialog.open(PersonDialogComponent, {
      data: {
        person,
        personSelectList: this.personSelect?.options.getValue(),
        reModify: this.reModify
      },
      panelClass: 'full-screen-dialog',

      width: !isMedium ? '100vw' : 'auto',
      maxWidth: !isMedium ? '100vw' : 'auto',
      height: !isMedium ? '100vh' : 'auto',
      maxHeight: !isMedium ? '100vh' : 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const profile = this.store.get('profile');
        if (profile) {
          const { investorProfileMetaInfoMemberList } = profile;
          this.personSelect?.refreshOptions(
            false,
            investorProfileMetaInfoMemberList.map(
              (member: EntityMember) => member.referenceInvestorMetaIndividualEntity
            )
          );
        } else {
          this.personSelect?.refreshOptions(true);
        }
      }
    });
  }

  get ownerName(): string {
    const { firstName, lastName } = this.form.get('person')?.value;
    return firstName + ' ' + lastName;
  }
  getIdentityDocument() {
    this.profileService
      .getTenantAttributeList({
        tenantId: this.tenantId,
        groupType: GroupType.IDENTITY
      })
      .subscribe(({ data }) => {
        this.tenantAttributeList = data?.tenantAttributes.filter(
          item => item.isCheckAttribute === 1
        );
      });
  }

  openIdentity(identity?: Identity) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    const person = this.form.get('person')?.value;
    const dialogRef = this.dialog.open(IdentityDialogComponent, {
      data: {
        investorProfileType: ProfileType.Individual,
        identity,
        metaIndividualId: person?.id,
        tenantId: this.tenantId,
        tenantAttributeList: this.tenantAttributeList,
        ownerName: this.ownerName,
        reModify: this.reModify,
        isMember: true,
        profileId: this.entityFormComponent.profileId.value
      },
      panelClass: 'full-screen-dialog',

      width: !isMedium ? '100vw' : 'auto',
      maxWidth: !isMedium ? '100vw' : 'auto',
      height: !isMedium ? '100vh' : 'auto',
      maxHeight: !isMedium ? '100vh' : 'auto'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getMemberIdentity();
      }
    });
  }

  getLegalInfoControl() {
    return this.fb.array([
      this.fb.group({
        legalName: [
          this.entityFormComponent.infoForm?.form.get('legalName')?.value,
          Validators.required
        ],
        controllingPersonType: [null, Validators.required]
      }),
      this.fb.group({
        legalName: [null],
        controllingPersonType: [null]
      }),
      this.fb.group({
        legalName: [null],
        controllingPersonType: [null]
      })
    ]);
  }

  translateCountryName(code: string) {
    if (!code) {
      return '--';
    }
    return this.countryListSync.find(item => item.value === code)?.label;
  }
  saveMember() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    if (this.personError) return;

    this.loading = true;
    const value = this.form.getRawValue();
    const profile = this.store.get('profile');

    this.entityFormComponent.entityId.pipe(take(1)).subscribe(id => {
      const params = {
        referenceInvestorMetaEntityId: id,
        id: this.member?.id,
        referenceInvestorProfileId: this.entityFormComponent.profileId.value,
        referenceInvestorMetaIndividualId: value.person.id,
        memberRole: this.memberRole,
        isSigningAuthority: value.isSigningAuthority,
        referenceInfoAddressId: value.address?.id,
        referenceInfoCertificateId: value.identity?.id,
        legalInfo: JSON.stringify(value.legalInfo),
        percentageOwnership: value.percentageOwnership,
        isUsPerson: value.isUsPerson
      };

      if (profile) {
        const { investorProfileMetaInfoMemberList: members = [] } = profile;

        this.store.set('profile', {
          ...profile,
          investorProfileMetaInfoMemberList: [
            ...members.filter(({ id }: any) => id !== this.member?.id),
            {
              ...members.find(({ id }: any) => id === this.member?.id),
              ...params
            }
          ]
        });

        this.loading = false;
        this.drawer?.close();
      } else {
        this.profileService
          .patchEntityMember(params)
          .pipe(
            finalize(() => {
              this.loading = false;
            })
          )
          .subscribe(() => {
            this.drawer?.close();
          });
      }
    });
  }

  parseValue(v: any) {
    return v && JSON.parse(v);
  }

  getIdentityInformation(key: documentTypeEnum): string {
    return DOCUMENT_TYPE[key] || key;
  }

  openAddress(address?: Address) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    const person = this.form.get('person')?.value;
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      data: {
        investorProfileType: ProfileType.Individual,
        address,
        individual: person,
        ownerName: this.ownerName,
        reModify: this.reModify,
        ownership: true,
        profileId: this.entityFormComponent.profileId.value,
        isMember: true
      },
      panelClass: 'full-screen-dialog',

      width: !isMedium ? '100vw' : 'auto',
      maxWidth: !isMedium ? '100vw' : 'auto',
      height: !isMedium ? '100vh' : 'auto',
      maxHeight: !isMedium ? '100vh' : 'auto'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getMemberAddress();
      }
    });
  }

  getDocTagLabel = (v: any) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  trackTaxJurisdiction(_i: number, item: any) {
    return item.toString();
  }
}
