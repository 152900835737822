import { Component } from '@angular/core';

@Component({
  template: `
    <div class="mr-0 text-center md:mr-24 md:text-left">
      <h1>404</h1>
      <h2 class="mb-12 text-3xl font-medium">The page you visited does not exist</h2>
    </div>
    <img src="assets/images/404.png" class="not-found-img" />
  `,
  styles: [
    `
      :host {
        @apply flex min-h-screen w-full flex-col items-center justify-center md:flex-row;
        h1 {
          font-size: 160px;
          line-height: 180px;
        }
        .not-found-img {
          width: 460px;
        }
      }
    `
  ]
})
export class NotFoundComponent {}
