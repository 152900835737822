export enum DataDocumentStatus {
  Valid = 'VALID',
  Invalid = 'INVALID',
  Shadow = 'SHADOW',
  Unmodified = 'UNMODIFIED',
}

export enum RootTag {
  ProductsRoot = 'PRODUCTS_ROOT',
  PrincipalsRoot = 'PRINCIPALS_ROOT',
  ProductRoot = 'PRODUCT_ROOT',
  PrincipalRoot = 'PRINCIPAL_ROOT',
}

export enum SystemTag {
  System = 'SYSTEM',
  Normal = 'NORMAL',
}

export enum DataRoomDocumentType {
  Pdf = 'PDF',
  Image = 'IMAGE',
  Excel = 'EXCEL',
  Folder = 'FOLDER',
}

export enum TypeTag {
  Product = 'PRODUCT',
  MarketingMaterial = 'MARKETING_MATERIAL',
  DataRoom = 'DATA_ROOM',
  NavReport = 'NAV_REPORT',
  ShareClass = 'SHARE_CLASS',
  Contract = 'CONTRACT',
  Principal = 'PRINCIPAL',
  PrincipalProduct = 'PRINCIPAL_PRODUCT',
  LpProfile = 'INVESTOR_PROFILE',
  Profile = 'PROFILE',
  Kyc = 'KYC',
  License = 'LICENSE',
  Investment = 'Investment',
  Subscription = 'SUBSCRIPTION',
  Compliance = 'Compliance',
}
export interface DataRoomDocument {
  cloudName: string;
  createBy: string;
  createTime: number;
  dataCreateTime: Date;
  dataIsDeleted: boolean;
  dataStatus: DataDocumentStatus;
  dataUpdateTime: Date;
  id: string;
  isInTrash: boolean;
  name: string;
  note: string;
  parentId: DataRoomDocument['id'];
  principalId: string;
  productId?: string;
  rootTag: RootTag;
  shareClassId: string;
  size: number;
  systemTag: SystemTag;
  tenantId: string;
  type: DataRoomDocumentType;
  typeTag: TypeTag;
  url: string;
  previewUrl: string;
  documentToken: string;
  downloadUrl?: string;
  tag?: {
    name: string;
    id: string;
  };
  country?: {
    name: string;
    id: string;
  };
  assetDocName?: string;
  assetDocCloudName?: string;
  assetDocType?: DataRoomDocumentType;
  assetDocUrl?: string;
  assetDocDownloadUrl?: string;
}
