import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Gateway,
  GatewayContract,
  GatewayNode,
  GatewayProgress,
  LateSignApi,
  MandatoryDocument,
  Marketing,
  Product,
  Profile,
  Restful,
} from '@stream/models';
import { objectFilterNull } from '@stream/utils';

@Injectable({
  providedIn: 'root',
})
export class ScenarioLateSignService {
  constructor(private http: HttpClient) {}

  getSubscriptionSnapshot(id: string) {
    return this.http.get<Restful<any>>(
      LateSignApi.snapshot.replace(':subscriptionId', id),
    );
  }

  submitSubscription(subscriptionId: string) {
    return this.http.put(
      LateSignApi.submitSubscription.replace(':subscriptionId', subscriptionId),
      {},
    );
  }

  getModifyScenarioProgress(subscriptionId: string, taskId: string) {
    return this.http.get<Restful<GatewayProgress>>(
      `${LateSignApi.modification.replace(
        ':subscriptionId',
        subscriptionId,
      )}?taskId=${taskId}`,
    );
  }

  patchModifyProgressNode(
    subscriptionId: string,
    params: {
      taskId: string;
      nodeId: string;
      nodeDataDraft: any;
      businessData?: any;
    },
  ) {
    return this.http.put<Restful<{ result: string }>>(
      LateSignApi.modification.replace(':subscriptionId', subscriptionId),
      params,
    );
  }

  getGatewayContractsv2(params: {
    gatewayIds: Gateway['gatewayId'][];
    profileId?: Profile['id'];
    productId?: Product['id'];
    marketingId?: Marketing['id'];
    subscriptionSnapshotDataJson?: any;
  }) {
    return this.http.post<
      Restful<{
        contracts: GatewayContract[];
      }>
    >(LateSignApi.GetGatewayContractsv2, params);
  }

  getFundMandatoryDocs(subscriptionId: string, taskId: string) {
    return this.http.get<Restful<Array<MandatoryDocument>>>(
      `${LateSignApi.mandatoryDocs.replace(
        ':subscriptionId',
        subscriptionId,
      )}?taskId=${taskId}`,
    );
  }

  getContractTags(subscriptionId: string, taskId: string) {
    return this.http.get<
      Restful<{ data: { customerJsonKeyValue: any; signDocumentsKeyValue: any } }>
    >(`${LateSignApi.contractTags}`, {
      params: {
        subscriptionId,
        taskId,
      },
    });
  }

  subscriptionConfirm(subscriptionId: string, taskId: string) {
    return this.http.put<Restful<boolean>>(
      LateSignApi.subscriptionConfirm.replace(':subscriptionId', subscriptionId),
      { taskId },
    );
  }

  modificationPrevious(
    subscriptionId: string,
    params: {
      taskId: string;
      nodeId: string;
      nodeDataDraft?: any;
    },
  ) {
    return this.http.put<Restful<boolean>>(
      LateSignApi.previous.replace(':subscriptionId', subscriptionId),
      params,
    );
  }

  modificationDiscard(
    subscriptionId: string,
    params: {
      taskId: string;
    },
  ) {
    return this.http.put<Restful<boolean>>(
      LateSignApi.discard.replace(':subscriptionId', subscriptionId),
      params,
    );
  }

  /**
   * Re-modify, get node data
   * @param
   */
  getNodeData(
    nodePath: {
      scenarioId: string;
      gatewayId: string;
      nodeId: string;
    },
    extraParams: Record<string, any> = {},
  ) {
    const { subscriptionId } = extraParams;

    return this.http.get<Restful<{ data: GatewayNode }>>(
      LateSignApi.getNodeData.replace(':subscriptionId', subscriptionId),
      {
        params: { ...nodePath, ...objectFilterNull(extraParams) },
      },
    );
  }
}
