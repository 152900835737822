import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IndividualType, Occupations, Profile, Store } from '@stream/models';
import { SharedService } from '@stream/shared';
import {
  getFormValidValues,
  phoneValidatorFn,
  transformDateToMoment,
  transformDateToStr
} from '@stream/utils';
import moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { debounceTime, filter, finalize, map, skip, take } from 'rxjs/operators';

import { CountriesService } from '@stream/libs/common/countries';
import { ProfilePanelService } from '../../services/profile-panel.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'stream-individual-info-form',
  templateUrl: './individual-info-form.component.html',
  styleUrls: ['./individual-info-form.component.scss']
})
export class IndividualInfoFormComponent implements OnInit, OnDestroy {
  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService,
    private fb: UntypedFormBuilder,
    private panelService: ProfilePanelService,
    private route: ActivatedRoute,
    private countriesService: CountriesService
  ) {
    window.moment = moment;
  }

  form = this.fb.group({
    firstName: [null, [Validators.maxLength(50), Validators.required]],
    lastName: [null, [Validators.maxLength(50), Validators.required]],
    previousName: [null],
    email: [null, [Validators.email, Validators.required]],
    phoneNationalityCode: [null, Validators.required],
    phoneNumber: [
      null,
      [
        Validators.required,
        (control: AbstractControl) =>
          phoneValidatorFn(control.parent?.get('phoneNationalityCode')?.value, control.value)
            ? null
            : { phone: true }
      ]
    ],
    nationalityCode: [null, Validators.required],
    anotherNationalityCode: [null],
    countryCodeOfBirth: [null, Validators.required],
    countryCodeOfResidence: [null, Validators.required],
    birthday: [null, Validators.required],
    occupation: [null, Validators.required]
  });

  individualId = new ReplaySubject<string | undefined>();
  areaCodeList = this.countriesService.getCountriesArray();
  store = new Store({ type: 'session', namespace: 'scenario' });

  countryList$ = this.sharedService.countryList.pipe(
    map(countryList =>
      (countryList || []).map(item => ({
        ...item,
        label: item.countryName,
        value: item.countryAlpha2Code
      }))
    )
  );
  countryListOptional$ = this.countryList$.pipe(map(list => [{ label: '', value: '' }, ...list]));

  occupationList = Occupations;

  todayDate = new Date();

  isOther = false;
  occupationValue: string | null = '';
  autoSave = this.form.valueChanges.pipe(debounceTime(2000), skip(1)).subscribe(() => {
    this.saveIndividual();
  });

  changeValue(e: any): void {
    if (e.value === 'other') {
      this.isOther = true;
      this.form.setControl('occupationComment', new FormControl('', Validators.required));
    } else {
      this.isOther = false;
      this.form.removeControl('occupationComment');
    }
  }

  ngOnInit(): void {
    const profile = this.store.get('profile');

    if (!this.panelService.isEdit.value) {
      this.getIndividualMetaInfo();
      return;
    }

    if (profile) {
      this.initProfile(profile);
      return;
    }

    this.panelService.profile
      .pipe(
        filter(v => !!v),
        take(1)
      )
      .subscribe(profile => {
        if (profile) {
          this.initProfile(profile);
        }
      });
  }

  initProfile(profile: Profile) {
    if (
      !profile?.investorProfileMetaIndividual?.occupation &&
      !profile?.investorProfileMetaIndividual?.occupationComment
    ) {
      this.occupationValue = null;
    } else if (profile?.investorProfileMetaIndividual?.occupation === 'other') {
      this.occupationValue = 'other';
      this.isOther = true;
      this.form.setControl('occupationComment', new FormControl('', Validators.required));
    }
    if (profile?.investorProfileMetaIndividual?.occupationComment) {
      this.occupationValue = 'other';
      this.isOther = true;
      this.form.setControl(
        'occupationComment',
        new FormControl(
          profile?.investorProfileMetaIndividual?.occupationComment,
          Validators.required
        )
      );
    }
    this.individualId.next(profile?.investorProfileMetaIndividual?.id);
    if (profile?.investorProfileMetaIndividual?.dateOfBirth) {
      // 'dateOfBirth' is deprecated since version 101 for page, but still need to send value returned by server
      this.form.addControl(
        'dateOfBirth',
        new FormControl(profile.investorProfileMetaIndividual.dateOfBirth)
      );
    }
    this.form.patchValue({
      ...(profile?.investorProfileMetaIndividual ?? {}),
      occupation: profile?.investorProfileMetaIndividual?.occupation || this.occupationValue,
      birthday: transformDateToMoment(profile?.investorProfileMetaIndividual?.birthday)
    });
  }

  ngOnDestroy(): void {
    this.autoSave.unsubscribe();
  }

  getCodeByLabel(str: string = '') {
    const arr = str?.split('+') ?? [];
    return arr[arr.length - 1] ? '+' + arr[arr.length - 1] : '';
  }

  getIndividualMetaInfo() {
    this.profileService
      .getIndividualMetaInfo()
      .subscribe(({ data: { investorProfileMetaIndividual } }) => {
        if (
          !investorProfileMetaIndividual?.occupation &&
          !investorProfileMetaIndividual?.occupationComment
        ) {
          this.occupationValue = null;
        } else if (investorProfileMetaIndividual?.occupation === 'other') {
          this.occupationValue = 'other';
          this.isOther = true;
          this.form.setControl('occupationComment', new FormControl('', Validators.required));
        } else if (investorProfileMetaIndividual?.occupationComment) {
          this.occupationValue = 'other';
          this.isOther = true;
          this.form.setControl(
            'occupationComment',
            new FormControl(investorProfileMetaIndividual?.occupationComment, Validators.required)
          );
        }
        this.individualId.next(investorProfileMetaIndividual?.id);
        this.form.patchValue(
          {
            ...investorProfileMetaIndividual,
            occupation: investorProfileMetaIndividual?.occupation || this.occupationValue,
            birthday: transformDateToMoment(investorProfileMetaIndividual?.birthday)
          },
          {
            emitEvent: false
          }
        );
        if (investorProfileMetaIndividual?.dateOfBirth) {
          // 'dateOfBirth' is deprecated since version 101 for page, but still need to send value returned by server
          this.form.addControl(
            'dateOfBirth',
            new FormControl(investorProfileMetaIndividual.dateOfBirth)
          );
        }
      });
  }

  saveIndividual() {
    const profile = this.store.get('profile');
    const snapshot = this.store.get('snapshot');
    const value = getFormValidValues(this.form);

    const params = {
      ...value,
      birthday: transformDateToStr(value['birthday'])
    } as any;

    if (snapshot && profile) {
      if (params.occupationComment) {
        params.occupation = 'other';
      }

      const investorProfileMetaIndividual = {
        ...profile.investorProfileMetaIndividual,
        ...params
      };
      const { occupation, occupationComment } = investorProfileMetaIndividual;

      if (
        (occupation === 'other' && !params.occupationComment) ||
        (occupation && occupation !== 'other' && occupationComment)
      ) {
        delete investorProfileMetaIndividual.occupationComment;
      }

      if (occupation === 'other' && occupationComment) {
        delete investorProfileMetaIndividual.occupation;
      }

      this.store.set('profile', {
        ...profile,
        investorProfileMetaIndividual
      });

      return investorProfileMetaIndividual;
    } else {
      if (
        Object.keys(value).indexOf('occupation') > -1 &&
        Object.keys(value).indexOf('occupationComment') > -1
      ) {
        Reflect.deleteProperty(value, 'occupation');
      }

      this.panelService.saving.next(true);
      this.individualId.pipe(take(1)).subscribe(id => {
        this.profileService
          .patchIndividual(params, {
            id,
            individualType: IndividualType.Main
          })
          .pipe(
            finalize(() => {
              this.panelService.saving.next(false);
            })
          )
          .subscribe(({ data: { metaIndividualId } }) => {
            this.individualId.next(metaIndividualId);
          });
      });
      return params;
    }
  }
}
