<input
  type="text"
  [placeholder]="placeholder"
  aria-label="ngx-select-country"
  matInput
  [formControl]="searchControl"
  [matAutocomplete]="auto"
  #INPUT
  (focus)="onFocusIn($event)"
  (blur)="onFocusOut($event)"
/>
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayWith"
  class="ngx-select-country-panel"
  (closed)="onAutocompleteClose()"
  (optionSelected)="onOptionSelected($event)"
>
  <mat-option *ngFor="let option of filterOptions" [value]="option.value">
    {{ option.label }}
  </mat-option>
</mat-autocomplete>
