import { LayoutModule as NgLayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ImageResizeModule, QuillViewModule } from '@stream/ngx-utils';
import { InviteMemberNotificationComponent } from 'src/app/components/invite-member-notification/invite-member-notification.component';
import { ChatModule } from 'src/app/modules/chat/chat.module';
import { TranslateModule } from 'src/libs/translate/translate.module';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { LayoutComponent } from './layout.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { SideNavComponent } from './side-nav/side-nav.component';


const matModules = [
  MatToolbarModule,
  MatListModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatChipsModule,
  MatDividerModule,
  MatTooltipModule,
  MatSidenavModule,
  MatDialogModule,
  MatBadgeModule,
];

@NgModule({
  imports: [
    QuillViewModule,
    CommonModule,
    RouterModule,
    NgLayoutModule,
    ...matModules,
    ImageResizeModule,
    OverlayModule,
    TranslateModule,
    ChatModule,
  ],
  declarations: [
    LayoutComponent,
    SideNavComponent,
    NavMenuComponent,
    BottomNavComponent,
    InviteMemberNotificationComponent
  ],
  exports: [LayoutComponent, BottomNavComponent],
})
export class LayoutModule {}
