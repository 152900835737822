import { DataRoomDocumentType } from './data-room.interface';
import { SubscriptionStepStatus } from './investment.interface';

export enum EMessageItemInfoStatus {
  success = 'success',
  sending = 'sending',
  failed = 'failed',
}

export enum ChatResponseEnum {
  Success = 'SUCCESS',
  IllegalConversation = 'ILLEGAL_CONVERSATION',
  InviteUpperLimit = 'INVITE_UPPER_LIMIT',
}

export enum ChatCategoryLocation {
  EveryWhere = 'EVERYWHERE',
  DMP = 'DMP',
  InvestmentJourney = 'INVESTMENT_JOURNEY',
  Portfolio = 'PORTFOLIO',
}

export enum ChatRecipientTypeEnum {
  LP = 'LP',
  GP = 'GP',
  WM = 'WM_CANDIDATE',
}

export interface ChatCategory {
  id: string;
  title: string;
  requiredSubscription: 0 | 1;
}

export interface ChatAssetWithCategory {
  assetId: string;
  assetName: string;
}

export interface ChatSubscription {
  id: string;
  fundName: string;
  amount: number;
  currency: string;
  status: SubscriptionStepStatus | 'REVOKE';
}

export interface ChatConversationRecipient {
  firstName: string;
  lastName: string;
  recipientType: ChatRecipientTypeEnum;
  recipientId: string;
  notifyEmail: string;
  userName: string;
}

export interface ChatMessageAttachment {
  downloadUrl: string;
  name: string;
  url: string;
  type: DataRoomDocumentType;
  size: number;
}

export interface ChatMessage {
  id: string;
  recipientId: string;
  recipientType: ChatRecipientTypeEnum;
  firstName: string;
  lastName: string;
  userName: string;
  roleTypes?: string[];
  content?: string;
  attachment?: ChatMessageAttachment[];
  messageTime: string;
  isDeleted: boolean;
  status: EMessageItemInfoStatus;
}

export interface ChatConversation {
  conversation: {
    id: string;
    category: ChatCategory;
    subject: string;
    recipients: ChatConversationRecipient[];
    dataStatus: 'VALID';
    dataCreateTime: string;
    subscription: ChatSubscription;
  };
  productSubscription: ChatSubscription;
}

export interface ChatConversationsItem {
  id: string;
  categoryTitle: string;
  subject: string;
  subscription: ChatSubscription;
  message: ChatMessage;
  unReadCount: number;
}

export interface ChatConversations {
  totalSize: number;
  pageNo: number;
  totalPage: number;
  pageSize: number;
  rows: ChatConversationsItem[];
}

export interface ChatConversationMessages {
  totalSize: number;
  pageNo: number;
  totalPage: number;
  pageSize: number;
  rows: ChatMessage[];
}

export interface ChatMessageValue {
  contentValue: string;
  fileValue: ChatMessageAttachment[];
}

export interface ChatFeature {
  status: 'OFF' | 'ON';
  featureKey: string;
}
