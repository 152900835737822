import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from '@stream/models';
import { BehaviorSubject, Subject } from 'rxjs';

import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class ProfilePanelService {
  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
  ) {
    if (this.isEdit.value && !this.isModify.value) {
      this.profileService
        .getProfileInfo(this.route.snapshot.params['profileId'])
        .subscribe(({ data: { investorProfileEntity } }) => {
          this.profile.next(investorProfileEntity);
        });
    }
  }

  isEdit = new BehaviorSubject(!!this.route.snapshot.params['profileId']);

  isModify = new BehaviorSubject(!!this.route.snapshot.params['subscriptionId']);

  profile = new BehaviorSubject<Profile | null>(null);

  saving = new BehaviorSubject(false);

  back = new Subject();

  profileId = new BehaviorSubject<string | null>(null);
}
