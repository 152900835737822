import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ngx-form-progress',
  template: `
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
      [value]="progress"
      class="ngx-progress-bar"
    ></mat-progress-bar>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      :host ::ng-deep .mat-progress-bar-buffer {
        background: #d9d9d9;
      }
      :host ::ng-deep .mat-progress-bar-fill::after {
        background: black;
      }
    `
  ]
})
export class NgxFormProgressComponent implements OnInit {
  @Input()
  formGroup!: FormGroup<any> | FormArray<any>;

  @Input()
  loading: boolean | null = false;

  progress = 0;

  updateProgress() {
    const controls = Object.values(this.formGroup.controls).filter(({ disabled }) => !disabled);
    const passedControls = controls.filter(({ valid }) => valid);

    this.progress = (passedControls.length / controls.length) * 100;
  }

  ngOnInit() {
    this.updateProgress();
    this.formGroup.statusChanges.pipe(debounceTime(100)).subscribe(() => {
      this.updateProgress();
    });
  }
}
