import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PermissionDirective } from '@stream/libs/common/directives';
import { AccountDatePipe } from './pipes/account-date.pipe';
import { CustomCurrencyPipe } from './pipes/currency.pipe';

@NgModule({
  declarations: [AccountDatePipe, CustomCurrencyPipe, PermissionDirective],
  imports: [CommonModule],
  exports: [AccountDatePipe, CustomCurrencyPipe, PermissionDirective]
})
export class SharedModule {}
