import { formatCurrency } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ShareClass, Store, SubscriptionFeeType } from '@stream/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { FormlyService } from '../../formly.service';

const MAX_AMOUNT = 10 ** 15;
@Component({
  selector: 'stream-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss']
})
export class AmountInputComponent extends FieldType implements OnInit {
  constructor(private formlyService: FormlyService) {
    super();
  }

  store = new Store({ type: 'session', namespace: 'scenario' });

  reModify = false;

  @ViewChild('amountInput')
  private amountInput?: ElementRef<HTMLInputElement>;

  get subscriptionFee() {
    return this.currentShareClass?.subscriptionFeeType === SubscriptionFeeType.Fixed
      ? this.currentShareClass.subscriptionFixedFee
      : this.formattedValue * (this.currentShareClass?.subscriptionFee ?? 0);
  }

  get total() {
    return this.subscriptionFee + this.formattedValue;
  }

  shareClassList: ShareClass[] = [];

  private currentShareClass$ = new BehaviorSubject<ShareClass | null>(null);

  get currentShareClass() {
    return this.currentShareClass$.getValue();
  }

  transformAmount(value: number) {
    return formatCurrency(value, 'en-US', '', this.formControl.get('currency')?.value);
  }

  // TODO: illegal expression
  prePopulate(field: FormlyFieldConfig) {
    (field as any).formControl = new UntypedFormGroup({
      amount: new UntypedFormControl(null, Validators.required),
      currency: new UntypedFormControl(null, Validators.required),
      shareClass: new UntypedFormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    const snapshot = this.store.get('snapshot');

    if (snapshot) {
      const { shareClass } = snapshot;
      this.initAmountForm([shareClass]);
      this.writeShareClass(shareClass.id);
      this.reModify = true;
    } else {
      this.formlyService.shareClass$
        ?.pipe(
          filter(v => !!v),
          take(1),
          switchMap(v => v as Observable<ShareClass[]>)
        )
        .subscribe(list => {
          this.initAmountForm(list);
        });
    }

    this.formControl.get('shareClass')?.valueChanges.subscribe((value: ShareClass['id']) => {
      this.writeShareClass(value);
    });
  }

  initAmountForm(list: ShareClass[]) {
    const { id, currency, minimumInvestment, minimumInvestmentAtSecTime, secTimeOpen } =
      list[0] ?? {};
    const opportunity$ = this.formlyService.opportunity$.getValue();

    this.shareClassList = list;
    this.formControl.get('shareClass')?.setValue(id);

    setTimeout(() => {
      this.formControl.get('currency')?.setValue(currency);
      this.formControl
        .get('amount')
        ?.setValue((secTimeOpen ? minimumInvestmentAtSecTime : minimumInvestment)?.toString());
    }, 0);

    if (opportunity$) {
      opportunity$.pipe(take(1)).subscribe(opportunity => {
        if (opportunity) {
          (this.formControl as FormGroup).addControl(
            'opportunityId',
            new FormControl(opportunity.id)
          );
        }
      });
    }
  }

  writeShareClass(value: string) {
    this.currentShareClass$.next(this.shareClassList.find(({ id }) => value === id) ?? null);
    setTimeout(() => {
      this.formControl.get('currency')?.setValue(this.currentShareClass?.currency);
      this.formControl.get('amount')?.setValue(this.formattedValue?.toString());
    }, 0);
  }

  get stepper() {
    return this.currentShareClass?.subsequentInvestment ?? 1;
  }

  // 修改场景初始值
  get snapshotInitValue() {
    const snapshot = this.store.get('snapshot');

    if (snapshot && snapshot.investing_amount) {
      const snapshotValue =
        Number(snapshot.investing_amount.component_investment_amount?.amount) ?? 0;

      return snapshotValue;
    }

    return this.minimumInvestment;
  }

  // 这里用于处理回显的 amount 数值
  get minimumInvestment() {
    const { secTimeOpen, minimumInvestment, minimumInvestmentAtSecTime } =
      this.currentShareClass ?? {};

    return (secTimeOpen ? minimumInvestmentAtSecTime : minimumInvestment) ?? 0;
  }

  get formattedValue() {
    const value = Number(this.amountInput?.nativeElement.value.replace(/[^0-9.]/g, ''));

    const num =
      Math.floor(
        (((!value ? 0 : value) > MAX_AMOUNT ? MAX_AMOUNT : value) - this.minimumInvestment) /
          this.stepper
      ) *
        this.stepper +
      this.minimumInvestment;

    return (num < this.minimumInvestment ? this.minimumInvestment : num) ?? 0;
  }

  stepUp() {
    if (!this.amountInput) return;

    const value = this.formattedValue + this.stepper;

    this.formControl.get('amount')?.setValue(value?.toString());
    this.amountInput.nativeElement.value = this.transformAmount(value) ?? '';
  }

  stepDown() {
    if (!this.amountInput) return;

    const value = this.formattedValue - this.stepper;
    const result = value < this.minimumInvestment ? this.minimumInvestment : value;
    this.setNewValue(result);
  }

  onAmountBlur() {
    if (!this.amountInput) return;

    this.formControl.get('amount')?.setValue(this.formattedValue?.toString());
    this.amountInput.nativeElement.value = this.transformAmount(this.formattedValue) ?? '';
  }

  setNewValue(result: number) {
    if (!this.amountInput) return;

    this.formControl.get('amount')?.setValue(result?.toString());
    this.amountInput.nativeElement.value = this.transformAmount(result) ?? '';
  }
}
