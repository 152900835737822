import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'stream-refresh-required',
  templateUrl: './refresh-required.component.html',
  styleUrls: ['./refresh-required.component.scss'],
})
export class RefreshRequiredComponent {
  constructor(private location: Location) {}

  refreshWindow() {
    this.location.historyGo(0);
  }
}
