import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'stream-message-file',
  templateUrl: './message-file.component.html',
  styleUrls: ['./message-file.component.less'],
})
export class MessageFileComponent {
  constructor() {}

  @Input()
  customStyle: any = '';

  @Input()
  info: any = null;

  @Input()
  isShowBtnDelete: boolean = false;

  @Output()
  previewClick = new EventEmitter();

  @Output()
  deleteClick = new EventEmitter();

  handleFilePreviewClick() {
    this.previewClick.emit(this.info);
  }

  handleFileDeleteClick() {
    this.deleteClick.emit(this.info);
  }
}
