import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@stream/libs/common/ngx-utils';
import { Observable, of } from 'rxjs';
import { catchError, pluck, switchMap } from 'rxjs/operators';

import { UplaodApi } from '../apis.enum';
import { Restful } from '../common';

export type ScanStatus = 'NO_THREATS_FOUND' | 'SCANNING' | 'MALICIOUS';

export type ScanResult = {
  scanResult: ScanStatus;
  name?: string;
  url?: string;
  downloadUrl?: string;
  size?: number;
  type?: string;
  documentToken?: string;
};

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient, private toast: ToastService) {}

  uploadFile(params: FormData, uuid?: string) {
    return this.http
      .post<
        Restful<{
          blobName: string;
          scanResult: ScanResult;
        }>
      >(UplaodApi.upload, params)
      .pipe(
        pluck('data'),
        catchError(error => {
          this.toast.common.warning({ uuid });
          throw error;
        }),
      );
  }

  scan(name: string) {
    return this.http
      .get<Restful<ScanResult>>(UplaodApi.scan.replace(':fileName', name))
      .pipe(pluck('data'));
  }

  startScan() {
    return this.toast.common.scanning();
  }

  removeScan(uuid: string) {
    this.toast.common.remove(uuid);
  }

  /**
   * through poll interface, check upload result
   * @param fileName
   * @param duaration
   * @returns Observable<ScanResult | null>
   */
  checkUploadResult(params: {
    blobName: string;
    uuid: string;
    duaration?: number;
  }): Observable<ScanResult | null> {
    const { blobName, uuid, duaration = 60 * 1000 } = params;
    const start = performance.now();
    const pollUploadResult = (): Observable<ScanResult | null> => {
      if (performance.now() - start > duaration) {
        this.toast.common.warning({ uuid });

        return of(null);
      }

      return this.scan(blobName).pipe(
        switchMap(data => {
          const { scanResult } = data;

          switch (scanResult) {
            case 'SCANNING':
              return pollUploadResult();

            case 'MALICIOUS':
              this.toast.common.malicious({ uuid });

              return of(null);

            case 'NO_THREATS_FOUND':
              this.toast.common.success({ uuid });

              return of(data);

            default:
              return of(null);
          }
        }),
        catchError(() => {
          this.toast.common.warning({ uuid });

          return of(null);
        }),
      );
    };

    return pollUploadResult();
  }
}
