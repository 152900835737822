import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxCodeInputComponent } from './ngx-code-input.component';

@NgModule({
  declarations: [NgxCodeInputComponent],
  imports: [CommonModule],
  exports: [NgxCodeInputComponent],
})
export class NgxCodeInputModule {}
