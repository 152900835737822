<header mat-dialog-title class="!mb-4 !flex items-center justify-between">
  <h1 class="text-neutral-900 md:text-[22px]">{{ title }}</h1>
  <button mat-icon-button mat-dialog-close type="button">
    <mat-icon>close</mat-icon>
  </button>
</header>
<p mat-dialog-content class="text-base text-neutral-500">
  {{ content }}
</p>
<div class="!mb-0 !mt-0 flex items-center justify-end gap-3 !p-0 !px-6 !pb-6" mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    class="!h-12 flex-1 !leading-[48px] md:w-40 md:flex-none"
    (click)="submit()"
  >
    {{ !showLimitReached ? 'Continue' : 'OK' }}
  </button>
</div>
