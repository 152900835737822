<div class="relative flex flex-col gap-y-4">
  <ng-container *ngTemplateOutlet="fileListRef; context: { uploadRef: this }"></ng-container>
  <div
    class="loading-modal absolute left-0 top-0 flex h-full w-full items-center justify-center"
    *ngIf="loading && !showUploadButton"
  >
    <div class="loader"></div>
  </div>
  <div
    #UPLOAD_BUTTON
    *ngIf="showUploadButton"
    [class]="{
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
      'upload-disabled': disabled
    }"
    class="upload-button relative flex min-h-[90px] w-full flex-col items-start justify-center rounded border border-solid border-primary-100 p-5"
    (click)="loading || disabled ? null : openMdSelector()"
  >
    <h3 class="upload-title mb-1 text-base text-primary-700">{{ title }}</h3>
    <p class="upload-placeholder text-[#62656C]">{{ placeholder }}</p>
    <span class="material-icons absolute right-5"> navigate_next </span>
    <div
      class="loading-modal absolute left-0 top-0 flex h-full w-full items-center justify-center"
      *ngIf="loading"
    >
      <div class="loader"></div>
    </div>
  </div>
</div>
