import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilePanelService, ProfileSettingService } from '@stream/libs/common';
import { PrincipalTypeEnum, ProfileType, Store } from '@stream/models';
import { map, pluck } from 'rxjs/operators';

@Component({
  selector: 'stream-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfilePanelService]
})
export class ProfileComponent {
  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private panelService: ProfilePanelService,
    private breakpointObserver: BreakpointObserver,
    public matDialog: MatDialog,
    private router: Router,
    private profileSettingService: ProfileSettingService
  ) {
    const store = new Store({ type: 'session', namespace: 'scenario' });
    const subscriptionId = this.route.snapshot.params['subscriptionId'];

    if (!store.get('snapshot') && subscriptionId) {
      this.router.navigate(['/inbox/TASK_MESSAGE']);
    }

    this.profileSettingService.updateTenantProfileSettings$.next();
  }

  autoSaving = this.panelService.saving.asObservable();

  profileId = this.route.params.pipe(pluck('profileId'));

  showProfileType = !this.route.snapshot.params['profileId'];
  tenantId = this.route.snapshot.params['tenantId'];
  PrincipalType = PrincipalTypeEnum;

  // 看到这可能奇怪为啥这么写 因为GP和LP对principal定义的模型不一样
  principalType: PrincipalTypeEnum = this.route.snapshot.data['principalType'];

  @ViewChild('MIN_PROGRESS')
  private minNavPanel!: TemplateRef<any>;

  isMedium = this.breakpointObserver
    .observe('(min-width: 768px)')
    .pipe(map(({ matches }) => matches));

  get isEdit() {
    return this.panelService.isEdit.getValue();
  }

  profileType =
    (this.route.snapshot.params['type'] as string)?.toUpperCase() ||
    (this.principalType === PrincipalTypeEnum.Institutional
      ? ProfileType.Entity
      : ProfileType.Individual);

  ProfileType = ProfileType;

  goBack() {
    this.location.back();
  }

  openProgressPanel() {
    this.matDialog.open(this.minNavPanel, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh'
    });
  }
}
