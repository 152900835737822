import { Component, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Config, Logic, Value } from './condition-builder.model';
import { ConditionBuilderService } from './condition-builder.service';

@Component({
  templateUrl: './condition-builder.component.html',
  selector: 'stream-condition-builder',
  styleUrls: ['./condition-builder.component.less'],
  providers: [ConditionBuilderService],
})
export class ConditionBuilderComponent {
  constructor(private service: ConditionBuilderService) {}

  @Input()
  set config(value: Config[]) {
    this.service.config$.next(value ?? []);
  }

  @Input()
  set formArray(value: FormArray) {
    if (value instanceof FormArray) {
      if (!(value.at(0) && value.at(0) instanceof FormControl))
        value.setControl(0, new FormControl(Logic.And));

      if (!(value.at(1) && value.at(1) instanceof FormArray))
        value.setControl(1, new FormArray([]));

      this.service.formArray = value;
    }
  }

  @Input()
  set value([logic, expressions]: Value) {
    this.service.logicControl.setValue(logic);
    this.service.expressionControl.clear();
    expressions.forEach(expression => {
      this.service.addExpression(...expression);
    });
  }

  get formArray() {
    return this.service.formArray;
  }

  get validExpressionControls() {
    return this.service.expressionControl.controls.filter(control => control.valid);
  }

  validate() {
    this.formArray.markAllAsTouched();
    this.service.validate$.next(null);
  }
}
