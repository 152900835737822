<ng-container *ngFor="let formArray of expressionControls; let i = index">
  <div *ngIf="i > 0" class="logic-wrapper">
    <div class="logic-tag">{{ logicSymbol$ | async }}</div>
  </div>
  <stream-expression-editor
    [formArray]="formArray"
    style="flex: 1"
  ></stream-expression-editor>
  <button nz-button nzShape="circle" nzType="text">
    <span nz-icon nzType="delete" class="delete-icon" (click)="deleteItem(i)"></span>
  </button>
</ng-container>
