<mat-progress-bar [mode]="getProgressBarMode()" class="mb-8"></mat-progress-bar>
<div class="mark">
  <stream-sign-pdf
    *ngIf="currentFile; else fileList"
    [file]="currentFile"
    [projectId]="projectId"
    class="flex-1"
    [profileId]="profile?.id"
    (back)="updateFileList()"
    (pdfLoading)="syncPdfLoading($event)"
  ></stream-sign-pdf>

  <ng-template #fileList>
    <h3
      class="mb-4 text-lg font-semibold text-primary-500"
      *ngIf="(project | async) && !loading"
    >
      Please review and
      {{
        !panelService.lateSign.getValue() && (isFirstSigner | async) ? 'sign' : 'amend'
      }}
      the documents below:
    </h3>
    <ng-container *ngIf="!loading">
      <ul class="flex-1 space-y-3">
        <ng-container *ngIf="isSign | async; else fillFileList">
          <li
            *ngFor="
              let file of (currentStatus | async)?.fileSignStatusList;
              let i = index;
              trackBy: trackByFn
            "
            class="file-item"
          >
            <ng-container
              *ngTemplateOutlet="signedTemplate; context: { file }"
            ></ng-container>
          </li>
        </ng-container>

        <ng-template #fillFileList>
          <ng-container [formGroup]="$any(formGroup)">
            <!-- contracts -->
            <div formArrayName="contracts">
              <li
                *ngFor="
                  let file of projectId ? (project | async)?.signFileList : contracts;
                  let i = index
                "
                class="file-item cursor-pointer"
                (click)="reviewPdf(file)"
              >
                <ng-container
                  *ngTemplateOutlet="pdfTemplate; context: { file }"
                ></ng-container>

                <div
                  *ngIf="profile?.profileType === 'ENTITY' && !projectId"
                  class="contracts-signature"
                >
                  <ng-container [formGroupName]="i">
                    <ng-container
                      class="divide-y rounded bg-slate-100"
                      formArrayName="signPlaceholders"
                    >
                      <mat-form-field
                        *ngFor="
                          let placeholder of file.signPlaceholderList;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <mat-label
                          >Signatory {{ i + 1 }}
                          {{ placeholder.required ? '' : '(Optional)' }}</mat-label
                        >
                        <mat-select formControlName="individualId">
                          <mat-option [value]="null" *ngIf="!placeholder.required"
                            >Please select</mat-option
                          >
                          <mat-option
                            *ngFor="let member of contractOptions"
                            [value]="member.referenceInvestorMetaIndividualEntity.id"
                            >{{ member.referenceInvestorMetaIndividualEntity.firstName }}
                            {{
                              member.referenceInvestorMetaIndividualEntity.lastName
                            }}</mat-option
                          >
                        </mat-select>
                        <mat-error ngx-mat-errors
                          >ngxMatErrorDefFor
                          <ng-container *ngxMatErrorDef="let error; for: 'required'">
                            This field is required
                          </ng-container>
                          <ng-container *ngxMatErrorDef="let error; for: 'duplicate'">
                            Signatories of the same document cannot be the same person
                          </ng-container>
                        </mat-error>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                </div>
              </li>
            </div>

            <div class="custodian" *ngIf="config.source === 'GP' && isCustodian">
              <h5 class="mb-4">
                Note: The investor needs to sign the documents personally when using IRA to invest.
              </h5>
            </div>

            <!-- sinatory -->
            <div
              class="signatory"
              *ngIf="
                config.source === 'GP' &&
                !panelService.isModifyInvest &&
                !projectId &&
                profile?.profileType !== 'ENTITY' &&
                !isCustodian
              "
            >
              <h5 class="mb-4">
                <span class="text-red-500">*</span>Please indicate the preferred signatory
                for the subscription documents
              </h5>
              <mat-radio-group
                class="radio-group"
                formControlName="signatory"
                (change)="onSignatoryChange($event)"
              >
                <mat-radio-button
                  class="signatory-radio"
                  *ngFor="let signOption of signatoryOptions"
                  [value]="signOption.value"
                  >{{ signOption.label }}</mat-radio-button
                >
              </mat-radio-group>

              <!-- authorization file -->
              <ng-container *ngIf="showFilesControl">
                <h5 class="mb-4">
                  <span class="text-red-500">*</span>Upload authorization file
                </h5>
                <stream-mandatory-doc-upload
                  class="file-field"
                  accept="application/pdf,image/jpeg,image/png"
                  formControlName="files"
                  (change)="$event.stopPropagation()"
                ></stream-mandatory-doc-upload>
                <p class="text-sx text-error" *ngIf="showFilesCtrError">
                  This field is required
                </p>
              </ng-container>
            </div>
          </ng-container>
        </ng-template>
      </ul>
    </ng-container>
  </ng-template>

  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="hidden">
    <symbol id="icon-pdf">
      <path
        d="M1.97049 0.846191H12.1953L17.3078 5.92311V19.8847C17.3078 20.5856 16.7356 21.1539 16.0297 21.1539H1.97049C1.26459 21.1539 0.692383 20.5856 0.692383 19.8847V2.11542C0.692383 1.41443 1.26459 0.846191 1.97049 0.846191Z"
        fill="#ADB5BD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3208 14.5227C11.6307 14.4718 10.9664 14.2173 10.4297 13.7592C9.3819 13.9882 8.38511 14.319 7.38844 14.7263C6.59627 16.1258 5.8551 16.8384 5.21617 16.8384C5.08836 16.8384 4.93511 16.813 4.83287 16.7366C4.55168 16.6094 4.39844 16.3294 4.39844 16.0496C4.39844 15.8205 4.44956 15.1843 6.87733 14.1409C7.43969 13.123 7.87399 12.0797 8.23186 10.9854C7.92512 10.3747 7.26076 8.87319 7.72062 8.10988C7.87399 7.82989 8.18074 7.6772 8.51305 7.70271C8.76854 7.70271 9.02403 7.82989 9.17741 8.03347C9.50971 8.49154 9.48415 9.45856 9.04959 10.8837C9.45859 11.646 9.99428 12.3343 10.6341 12.9195C11.1709 12.8176 11.7074 12.7413 12.2441 12.7413C13.4453 12.7667 13.6242 13.3265 13.5986 13.6574C13.5986 14.5227 12.7552 14.5227 12.3208 14.5227ZM5.16517 16.1001L5.16528 16.1001L5.16517 16.1003V16.1001ZM5.16528 16.1001C5.3952 15.7693 5.70178 15.5149 6.08515 15.3622C5.88065 15.6931 5.5996 15.9476 5.24186 16.0748L5.16528 16.1001ZM8.48736 8.46618H8.56405C8.69186 8.89873 8.69186 9.3568 8.53849 9.78935C8.35955 9.38218 8.28287 8.92412 8.38512 8.49156C8.41068 8.46618 8.4618 8.46618 8.48736 8.46618ZM8.7174 12.207L8.69184 12.1816C8.46178 12.767 8.20616 13.3522 7.92511 13.9121L7.97623 13.8867V13.9375C8.54454 13.7329 9.1249 13.5629 9.71407 13.4285L9.68851 13.4031H9.7652C9.38176 13.0213 9.02402 12.5888 8.74297 12.1562L8.7174 12.207ZM11.5541 13.5556C11.7841 13.5048 11.9885 13.5048 12.2185 13.5048C12.7297 13.5048 12.8319 13.632 12.8319 13.7084C12.6787 13.7593 12.4997 13.7847 12.3208 13.7593C12.0653 13.7338 11.8097 13.6829 11.5541 13.5556Z"
        fill="white"
      />
      <path
        d="M12.1953 0.846191L17.3077 5.92311H13.4734C12.7675 5.92311 12.1953 5.35488 12.1953 4.65388V0.846191Z"
        fill="#CED4DA"
      />
    </symbol>
  </svg>
</div>

<!-- sign template -->
<ng-template #signedTemplate let-file="file">
  <div
    class="file"
    [ngClass]="{
      'sign-progress': file.progress < 100
    }"
    [style]="{
      '--progress': file.progress + '%'
    }"
  >
    <div class="file-info z-10 flex items-center gap-x-3">
      <svg class="icon flex-shrink-0 text-2xl">
        <use xlink:href="#icon-pdf"></use>
      </svg>
      <h4 class="text-base text-primary-400">
        {{ file.fileName }}
      </h4>
    </div>

    <div class="z-10 flex items-center gap-x-2 justify-self-end" [ngSwitch]="file.status">
      <ng-container *ngSwitchCase="'success'">
        <mat-icon class="text-success-100">done</mat-icon>
        <span>Signed</span>
        <a
          mat-icon-button
          (click)="$event.stopPropagation(); downloadFile(file.id, file.fileName)"
        >
          <mat-icon class="text-primary-200">download</mat-icon>
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'failed'">
        <mat-icon class="text-error-100"> close </mat-icon>
        <span>Failed</span>
        <button
          mat-icon-button
          (click)="$event.stopPropagation(); sign()"
          [disabled]="signing | async"
        >
          <mat-icon class="text-primary-200">replay</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span class="font-base font-bold text-primary-500"> {{ file.progress }}% </span>
        <span class="font-base text-primary-400">Signing</span>
        <button mat-icon-button></button>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- pdf template -->
<ng-template #pdfTemplate let-file="file">
  <div class="file">
    <div class="file-info flex items-center gap-x-3">
      <svg class="icon flex-shrink-0 text-2xl">
        <use xlink:href="#icon-pdf"></use>
      </svg>
      <h4 class="text-base text-gray-700">
        {{ addSuffix(file.fileName) }}
      </h4>
    </div>
    <button
      mat-button
      class="flex items-center justify-self-end text-primary-300"
      *ngIf="project | async"
    >
      <span class="flex items-center">
        {{ (project | async)?.status !== 'draft' ? 'View' : 'Review and amend' }}
        <mat-icon>navigate_next</mat-icon>
      </span>
    </button>
  </div>
</ng-template>

<!-- button template -->
<ng-template #footer>
  <button
    mat-stroked-button
    color="primary"
    class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none"
    (click)="discard()"
    [disabled]="loading || lateSignLoading"
    *ngIf="
      (panelService.nodeData | async)?.nodeInnerType === 'INVESTING_SIGN' &&
      (currentStatus | async)?.taskStatus !== 'signed'
    "
  >
    {{ 'Discard' | buttonName | async }}
  </button>

  <ng-container>
    <button
      mat-flat-button
      *ngIf="!(project | async)"
      [disabled]="loading"
      color="primary"
      class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none font-semibold"
      (click)="continueSign()"
    >
      {{ 'Continue' | buttonName | async }}
    </button>
  </ng-container>
  <ng-container *ngIf="project | async">
    <!-- sign & lateSign -->
    <ng-container
      *ngIf="!(panelService.lateSign | async) || !(panelService.isLastNode | async)"
    >
      <button
        mat-flat-button
        color="primary"
        class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none font-semibold"
        [disabled]="loading || disabled"
        (click)="startUp()"
        *ngIf="
          (project | async)?.status === 'draft' && !panelService.isModifyInvest;
          else signAction
        "
      >
        {{ (!(isCustodian && config.source === 'GP') && (isFirstSigner | async) ? 'Sign' : 'Submit') | buttonName | async }}
      </button>
      <ng-template #signAction>
        <button
          mat-flat-button
          color="primary"
          class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none font-semibold"
          [disabled]="loading"
          (click)="leave(true)"
          *ngIf="(hasSignPermission() | async) === false; else sent"
        >
          {{ 'Submit' | buttonName | async }}
        </button>
        <ng-template #sent>
          <button
            mat-flat-button
            color="primary"
            class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none font-semibold"
            (click)="beforeSign()"
            [disabled]="loading || (signing | async) || (sendEmailDelay | async) !== 0"
            *ngIf="
              (currentStatus | async)?.taskStatus !== 'signed' &&
                (hasFailed | async) !== true &&
                !panelService.isModifyInvest;
              else close
            "
          >
            {{ ((signing | async) ? 'Signing' : 'Sign') | buttonName | async }}
            <span *ngIf="(sendEmailDelay | async) !== 0">
              ({{ sendEmailDelay | async }}s)</span
            >
          </button>
        </ng-template>
        <ng-template #close>
          <button
            mat-flat-button
            color="primary"
            class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none font-semibold"
            [disabled]="hasFailed | async"
            (click)="leave()"
            *ngIf="panelService.isLastNode | async; else next"
          >
            {{ 'Finish' | buttonName | async }}
          </button>
          <ng-template #next>
            <button
              mat-flat-button
              color="primary"
              class="mat-button-lg !w-auto flex-1 text-base md:!w-40 md:flex-none"
              (click)="nextStep()"
              [disabled]="(signing | async) || loading"
            >
              {{ 'Next' | buttonName | async }}
            </button>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-container>

    <!-- only open late sign config's button -->
    <ng-container *ngIf="panelService.lateSign | async">
      <button
        mat-flat-button
        color="primary"
        class="mat-button-lg !px-auto flex-1 !py-1 text-base font-semibold md:flex-none md:!px-16"
        (click)="lateSign()"
        *ngIf="panelService.isLastNode | async"
        [disabled]="
          lateSignLoading ||
          (hasFailed | async) ||
          (panelService.loading | async) ||
          loading
        "
      >
        {{
          ((isFirstSigner | async) ? 'Submit and sign later' : 'Submit')
            | buttonName
            | async
        }}
      </button>
    </ng-container>
  </ng-container>
</ng-template>
