<form [formGroup]="form" class="space-y-6">
  <div *ngIf="!isEdit">
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      Structure type
    </h2>
    <p class="mb-4 leading-snug text-gray-600">
      It is important that we understand the entity structure that you are using to
      invest. Please select the appropriate profile below to confirm whether you are a
      single investor investing through your own structure, using a
      nominee/administered/managed structure or a vehicle that is investing with an
      experienced CIO or investment committee backing e.g. single FO, Endowment, Pension
      etc.
    </p>
    <mat-radio-group class="grid-cols-3 gap-x-4 md:grid" formControlName="structureType">
      <mat-radio-button
        class="mb-2 w-full md:mb-0 md:w-auto"
        [value]="EntityStructureType.SingleInvestors"
        >Single Investors</mat-radio-button
      >
      <mat-radio-button
        class="mb-2 w-full md:mb-0 md:w-auto"
        [value]="EntityStructureType.PooledVehicle"
        >Pooled Vehicle</mat-radio-button
      >
      <mat-radio-button
        class="mb-2 w-full md:mb-0 md:w-auto"
        [value]="EntityStructureType.ProfessionallyManagedVehicle"
        >Professionally Managed Vehicle</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div class="-mb-5" streamAnchor="General information" [control]="infoForm.form">
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      General information
    </h2>
    <stream-entity-info-form
      class="grid gap-x-4"
      #infoForm
      style="grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr))"
    ></stream-entity-info-form>
  </div>
  <div streamAnchor="Registered address" [control]="form.get('address')">
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      Registered address
    </h2>
    <stream-drawer-select
      name="address"
      icon="#icon-address"
      #addressSelect
      (add)="openAddress()"
      (edit)="openAddress($event)"
      formControlName="address"
      [optionTemplate]="addressTemplate"
      [optionsObservable]="addressList"
      [disableCreate]="reModify"
    >
      <ng-template #addressTemplate let-address>
        <div class="pr-8 md:pr-0">
          <div class="text-base font-semibold text-gray-500">
            <p class="font-bold text-black">
              {{ address.addressLineOne }} {{ address.addressLineTwo }},
            </p>
            <p>
              {{ address.addressTown }} {{ address.addressState }}
              {{ translateCountryName(address.addressCountryCode) }},
              {{ address.zipCode }}
            </p>
          </div>
        </div>
      </ng-template>
    </stream-drawer-select>
  </div>
  <div streamAnchor="Bank information" [control]="form.get('bank')">
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      Bank information
    </h2>
    <stream-drawer-select
      name="bank"
      icon="#icon-bank"
      (add)="openBank()"
      (edit)="openBank($event)"
      #bankSelect
      formControlName="bank"
      [optionTemplate]="bankTemplate"
      [optionsObservable]="bankList"
      [disableCreate]="reModify"
    >
      <ng-template #bankTemplate let-bank>
        <div class="space-y-1 pr-8 md:pr-0">
          <div class="text-base font-semibold text-gray-500">
            {{ bank.bankName }}
            <strong class="text-black">{{
              bank.bankAccountNumber | bankNumber: true
            }}</strong>
          </div>
          <div class="!-mb-1 flex flex-wrap items-center gap-x-2">
            <stream-data-room-document
              [document]="bank.referenceProofBankFileEntity?.documents"
              [proofType]="'bank'"
              class="mb-1"
            ></stream-data-room-document>
            <div
              *ngIf="bank.tag"
              class="mb-1 whitespace-normal rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
            >
              {{ getDocTagLabel(bank) }}
            </div>
          </div>
        </div>
      </ng-template>
    </stream-drawer-select>
  </div>
  <div
    streamAnchor="Owners/Directors/Trustees/Beneficiaries"
    [control]="ownership.control"
  >
    <h2
      class="mb-2 flex items-center text-lg font-semibold"
      *ngIf="tooltipInfoOwnerDirectorTrustedBeneficiary"
      nz-popover
      nzPopoverOverlayClassName="max-w-3xl"
      [nzPopoverOrigin]="ownershipIcon._elementRef"
      [nzPopoverContent]="ownershipInfo"
    >
      <span class="mr-1 text-red-500">*</span>
      Owners/Directors/Trustees/Beneficiaries
      <mat-icon color="primary" class="ml-2 !h-auto !text-xl" #ownershipIcon
        >info_outlined</mat-icon
      >
    </h2>
    <h2
      class="mb-2 flex items-center text-lg font-semibold"
      *ngIf="!tooltipInfoOwnerDirectorTrustedBeneficiary"
    >
      <span class="mr-1 text-red-500">*</span>
      Owners/Directors/Trustees/Beneficiaries
    </h2>
    <stream-ownership-directorship
      #ownership
      [tenantId]="tenantId"
    ></stream-ownership-directorship>
    <ng-template #ownershipInfo>
      <stream-quill-view
        [content]="tooltipInfoOwnerDirectorTrustedBeneficiary"
        [pureViewer]="true"
      ></stream-quill-view>
    </ng-template>
  </div>
  <div streamAnchor="Tax status" [control]="form.get('actualSituationType')">
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      Tax Reporting
    </h2>
    <h6 class="mb-2 text-base font-normal">
      Please select according to the actual situation and fill in as the case may be
    </h6>

    <mat-radio-group class="grid grid-cols-1 gap-3" formControlName="actualSituationType">
      <mat-radio-button [value]="ActualSituationType.UscUStax">
        The entity is a Specified U.S. Person.
      </mat-radio-button>
      <mat-radio-button [value]="ActualSituationType.BornUSNotUSC">
        The entity is a U.S. Person that is not a Specified U.S. Person.
      </mat-radio-button>
      <mat-radio-button [value]="ActualSituationType.NotUSC">
        The entity is not a U.S. Person.
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- LP -->
  <div *ngIf="config.source === 'LP'">
    <stream-additional-document-form
      *ngIf="profileId?.value && !reModify"
      type="SUPPORTING_DOCUMENTS"
      [profileId]="profileId.value || ''"
    ></stream-additional-document-form>
  </div>

  <!-- GP -->
  <div *ngIf="additionalDocument?.length && config.source === 'GP' && !reModify">
    <h2 class="mb-2 text-lg font-semibold">Additional document</h2>
    <stream-additional-document
      [additionalDoc]="additionalDocument"
      [profileId]="profileId.value || ''"
    ></stream-additional-document>
  </div>

  <footer
    class="fixed bottom-0 left-0 grid w-full grid-cols-2 justify-around gap-x-2 bg-white p-6 md:flex md:justify-end md:space-x-2"
    style="z-index: 1"
  >
    <button
      mat-stroked-button
      color="primary"
      (click)="goback()"
      type="button"
      class="mat-button-lg !px-14"
    >
      <span class="font-semibold"> Back </span>
    </button>

    <button
      mat-flat-button
      type="button"
      color="primary"
      class="mat-button-lg !px-14"
      [disabled]="panelService.saving | async"
      (click)="submit()"
    >
      Save
    </button>
  </footer>
</form>
