<div>
  <div class="relative flex w-full flex-col gap-y-4">
    <div
      *ngFor="let doc of documents || []; let i = index"
      class="flex w-full items-center gap-x-2 rounded bg-[#f8f9fa] px-5 py-3 hover:bg-primary-50"
      (click)="preview(doc)"
    >
      <svg class="icon text-lg">
        <use [attr.xlink:href]="getIconByType(doc?.type)"></use>
      </svg>

      <div class="flex-1 cursor-pointer truncate text-sm text-gray-700">
        <span class="pr-1">
          {{ doc.name || '--' }}
        </span>
        <span class="inline-block rounded bg-[#fff9eb] px-2 py-0.5 text-black" *ngIf="doc.tag">
          {{ getDocumentTagLabel(doc) }}
        </span>
      </div>
      <button mat-icon-button type="button" (click)="deleteFile(i)">
        <mat-icon class="text-gray-500">delete</mat-icon>
      </button>
    </div>
    <div
      #UPLOAD_BUTTON
      class="upload-button relative flex w-full cursor-pointer flex-col items-start justify-center rounded border border-solid border-[rgb(229,231,235)] px-5 py-4"
      (click)="openDocSelector()"
    >
      <span class="upload-title text-base text-primary-700">Upload</span>
      <span class="upload-placeholder text-[#62656C]"
        >Allowed file formats pdf, jpeg, png / max 30mb</span
      >
      <span class="material-icons absolute right-5"> navigate_next </span>
    </div>
  </div>
</div>
