import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnchorManagementDirective } from './anchor-management.directive';
import { AnchorDirective } from './anchor.directive';

@NgModule({
  declarations: [AnchorDirective, AnchorManagementDirective],
  imports: [CommonModule],
  exports: [AnchorDirective, AnchorManagementDirective],
})
export class AnchorModule {}
