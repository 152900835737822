<mat-drawer-container class="relative !flex w-full flex-1 flex-col !bg-primary-50">
  <mat-drawer-content class="relative !flex max-h-screen flex-1">
    <div
      class="menu-drawer sticky left-0 top-0 z-20 w-[338px] flex-none bg-white"
      *ngIf="isMedium | async"
    >
      <stream-side-nav></stream-side-nav>
    </div>
    <div class="relative flex flex-grow flex-col overflow-y-auto" cdkScrollable>
      <mat-toolbar class="tool-bar" *ngIf="isMedium | async">
        <span class="text-lg font-bold text-primary-500">
          {{ activeRouteName }}
        </span>
        <div class="flex flex-shrink-0 items-center justify-end gap-x-2">
          <div>
            <stream-chat-widget></stream-chat-widget>
          </div>
          <div
            class="currecncy mr-4"
            *ngIf="activeRouteName === 'Dashboard' || activeRouteName === 'Investments'"
            (mouseover)="showOverlayMenu()"
            (mouseleave)="overlayTriggerLeave()"
          >
            <div class="flex items-center">
              <span class="currency_text">Currency: &nbsp;{{ currentCurrency }}&nbsp;&nbsp;</span>
              <mat-icon> info_outline</mat-icon>
            </div>
            <div class="currency_note" *ngIf="inTrigger">
              <p>
                Different currencies are currently converted to
                {{ currentCurrency }} for statistics. You can change the base currency in the
                <span class="account_page" (click)="goAccount()">account settings.</span>
              </p>
              <p>
                <span *ngIf="isRateShow"
                  >Exchange rate against {{ tenantCurrency }} dollar&nbsp;:</span
                >
                <span class="rate_account" *ngIf="isRateShow"
                  >&nbsp;{{ rateNumber }}&nbsp;&nbsp;</span
                >
                <span
                  class="account_page"
                  (click)="viewCurrency(); $event.stopPropagation()"
                  *ngIf="isPolicyShow"
                  >View currency exchange rate policy</span
                >
              </p>
            </div>
          </div>
          <a
            *ngIf="(wmCandidate | async) !== true"
            mat-icon-button
            routerLink="inbox"
            color="primary"
            [ngStyle]="{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0'
            }"
          >
            <mat-icon
              class="inbox-icon !h-auto !w-auto text-3xl leading-6 text-[#62656c]"
              [matBadge]="
                ((inboxMessageCount$ | async) ?? 0) > 9 ? '9+' : (inboxMessageCount$ | async)
              "
              matBadgeColor="warn"
              [matBadgeHidden]="(inboxMessageCount$ | async) === 0"
              >notifications</mat-icon
            >
          </a>
          <button
            *ngIf="(clientService.configuration | async)?.isMultiLingual === true"
            class="stream-translate-ignore"
            mat-button
            [matMenuTriggerFor]="menu"
          >
            <div class="flex items-center gap-1">
              <span>
                <img [src]="(activeLangInfo | async)?.flag" alt="" />
              </span>
              <span>{{ (activeLangInfo | async)?.title }}</span>
            </div>
          </button>
          <mat-menu class="stream-translate-ignore" #menu="matMenu">
            <button
              mat-menu-item
              *ngFor="let lang of languages | async"
              (click)="changeLanguage(lang.code)"
            >
              <div class="flex items-center gap-1">
                <span>
                  <img [src]="lang.flag" alt="" />
                </span>
                <span>{{ lang.title }}</span>
              </div>
            </button>
          </mat-menu>
        </div>
      </mat-toolbar>
      <main
        class="container mx-auto mb-5 mt-0 flex flex-1 flex-col px-5 md:mt-5 md:px-8 dt-hd:px-[52px]"
        [class.has-bottom-nav]="!hideBottomNav && (isMedium | async) === false"
      >
        <router-outlet></router-outlet>
      </main>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<!-- Mobile -->
<stream-bottom-nav *ngIf="!hideBottomNav && (isMedium | async) === false"></stream-bottom-nav>
<ng-template #discardDialog>
  <header class="!flex items-center" mat-dialog-title>
    <h2 class="flex-1">Currency exchange rate policy</h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div mat-dialog-content class="content_box">
    <stream-quill-view [content]="policyContent"></stream-quill-view>
  </div>
  <footer mat-dialog-actions align="end">
    <div mat-flat-button [mat-dialog-close]="false" class="closeButton">Got it</div>
  </footer>
</ng-template>
