<header class="!flex items-center" mat-dialog-title>
  <h2 class="!mb-0 flex-1 text-lg md:text-xl">{{
    data.title || 'Oops, something went wrong'
  }}</h2>
  <button mat-icon-button mat-dialog-close="" class="!hidden md:!inline-block">
    <mat-icon [mat-dialog-close]="false">close</mat-icon>
  </button>
</header>
<ng-container [ngSwitch]="data.type">
  <p mat-dialog-content class="text-primary-300" *ngSwitchCase="'fbo'">
    Unfortunately you are unable to invest using your Inspira Financial IRA due to account
    status. Please get in touch with Inspira Financial by sending an email to
    itpartnersupport&#64;mtrustcompany.com.
  </p>
  <p mat-dialog-content class="text-primary-300" *ngSwitchCase="'system'">
    System configuration updated. Redirecting to fund details page.
  </p>
  <p mat-dialog-content class="text-primary-300" *ngSwitchDefault>
    We're having a bit of trouble moving forward with your investment process right now.
    Please give it another try later. If you still run into issues, don't hesitate to
    reach out to our team by sending email to itpartnersupport&#64;mtrustcompany.com.
  </p>
</ng-container>
<footer class="flex items-center justify-between gap-x-2 md:justify-end">
  <button
    mat-flat-button
    class="mat-button-lg w-1/2 md:w-auto md:!px-6"
    color="primary"
    [mat-dialog-close]="true"
  >
    Okay
  </button>
</footer>
