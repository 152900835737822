import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'stream-phone-wrapper',
  template: `
    <label class="stream-formly-phone-label">{{ to.label }} </label>
    <ng-container #fieldComponent></ng-container>
  `,
  styleUrls: ['./phone-wrapper.component.scss'],
})
export class PhoneWrapperComponent extends FieldWrapper {}
