export const isQuillHasContent = (content: string) => {
  if (!content) {
    return false;
  }
  let _des: { ops: { insert: string | { image: string } }[] } = {
    ops: []
  };
  try {
    _des = JSON.parse(content);
  } catch (error) {}
  const { ops } = _des;
  if ((ops && ops.length === 0) || !ops) {
    return false;
  }
  const hasObjectTypeInsert = ops.some(op => typeof op.insert === 'object');
  if (hasObjectTypeInsert) {
    return true;
  }
  const hasStringTypeInsert = ops.some(
    op => typeof op.insert === 'string' && !!(op.insert ?? '').replaceAll('\n', '').trim()
  );
  if (hasStringTypeInsert) {
    return true;
  }
  return false;
};
