import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { EntityMember, EntityMemberRole, Store } from '@stream/models';
import { BehaviorSubject, of, tap } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { SharedService } from '@stream/shared';
import { ProfileSettingService } from '../../services';
import { ProfilePanelService } from '../../services/profile-panel.service';
import { ProfileService } from '../../services/profile.service';
import { EntityFormComponent } from '../entity-form/entity-form.component';

@Component({
  selector: 'stream-ownership-directorship',
  templateUrl: './ownership-directorship.component.html',
  styleUrls: ['./ownership-directorship.component.scss'],
})
export class OwnershipDirectorshipComponent implements OnInit, OnDestroy {
  constructor(
    private entityFormComponent: EntityFormComponent,
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    private panelService: ProfilePanelService,
    private sharedService: SharedService,
    private profileSettingService: ProfileSettingService,
  ) {}

  MemberRole = EntityMemberRole;

  members$ = new BehaviorSubject<EntityMember[]>([]);
  referenceInvestorProfileId$ = new BehaviorSubject<string>('');
  get members() {
    return this.members$.getValue();
  }

  control = this.fb.control(null, Validators.required);
  reModify = false;

  @Input() tenantId = '';

  @ViewChild('drawer')
  drawer?: MatDrawer;

  positionRight = '0';

  currentMember: EntityMember | null = null;

  store = new Store({ type: 'session', namespace: 'scenario' });

  private memberChange$ = this.members$.subscribe(list => {
    // changed for: https://bite.atlassian.net/browse/STREAM-9107
    this.loadMembersDocs(list);
  });

  loadMembersDocs(list: EntityMember[]) {
    // check profile has all mandatory documents
    this.profileSettingService.tenantProfileSettings$
      .pipe(
        filter(configs => !!configs),
        tap(configs => {
          let docConfigs = configs?.['DOCUMENT_ENTITY_MEMBER']?.settingValue ?? {};

          const validDocKeys = Object.keys(docConfigs).filter(
            key => (docConfigs as any)[key] === 'MANDATORY',
          );

          this.control.setValue(
            (this.validateMembers(validDocKeys) && list.length > 0) || null,
          );
        }),
      )
      .subscribe();
  }

  validateMembers(mandatoryKeys: string[]): boolean {
    for (const member of this.members) {
      if (!this.validateBasicInfo(member)) {
        return false;
      }

      if (!this.validateControllingPerson(member)) {
        return false;
      }

      if (!this.validateProofOfDocuments(member, mandatoryKeys)) {
        return false;
      }
    }

    return true;
  }

  private validateBasicInfo(member: any): boolean {
    const {
      referenceInvestorMetaIndividualEntity: {
        townOfBirth,
        jobPositionTitle,
        taxJurisdiction,
      },
    } = member;

    return !!(townOfBirth && jobPositionTitle && taxJurisdiction);
  }

  private validateControllingPerson(member: any): boolean {
    const { memberRole: role, percentageOwnership, isUsPerson } = member;
    if (
      role === EntityMemberRole.ControllingPerson ||
      role === EntityMemberRole.Director
    ) {
      return (
        percentageOwnership !== undefined &&
        percentageOwnership !== null &&
        isUsPerson !== undefined &&
        isUsPerson !== null
      );
    }

    return true;
  }

  private validateProofOfDocuments(member: any, mandatoryKeys: string[]): boolean {
    const {
      referenceInfoAddressEntity: address,
      referenceInfoCertificateEntity: certificate,
    } = member;

    if (mandatoryKeys.includes('proofOfAddress')) {
      if (!address || (address && !address.referenceProofAddressFileEntity?.documents)) {
        return false;
      }
    }

    if (mandatoryKeys.includes('proofOfIdentity')) {
      if (
        !certificate ||
        (certificate && !certificate.referenceProofCertificateFileEntity?.documents)
      ) {
        return false;
      }
    }

    return true;
  }

  getControllingEntitiesName(legalInfo: string) {
    return (
      ((legalInfo && JSON.parse(legalInfo)) as any[])
        ?.map(v => v.legalName)
        .filter(Boolean)
        .join(',') ?? ''
    );
  }

  ngOnInit(): void {
    const profile = this.store.get('profile');
    const profileObservable = profile ? of(profile) : this.panelService.profile;

    // late sign
    if (profile) {
      this.reModify = true;
    }

    // non-late sign
    profileObservable
      .pipe(
        filter(v => !!v),
        take(1),
      )
      .subscribe(profile => {
        this.members$.next(profile?.investorProfileMetaInfoMemberList ?? []);
        this.loadMembersDocs(profile?.investorProfileMetaInfoMemberList ?? []);
        this.referenceInvestorProfileId$.next(
          profile?.productSubscriptionProfile?.id ?? '',
        );
      });
  }

  ngOnDestroy(): void {
    this.memberChange$.unsubscribe();
  }

  getMembers() {
    const profile = this.store.get('profile');
    if (profile) {
      this.members$.next(profile.investorProfileMetaInfoMemberList ?? []);
    } else {
      this.entityFormComponent.optionalEntityId.pipe(filter(v => !!v)).subscribe(id => {
        this.profileService
          .getEntityMembers(id as string)
          .subscribe(({ data: { investorProfileMetaInfoMembersList } }) => {
            this.members$.next(investorProfileMetaInfoMembersList ?? []);
          });
      });
    }
  }

  editMember(member: EntityMember) {
    this.currentMember = member;
    this.drawer?.open();
  }

  drawerClosed() {
    this.currentMember = null;
    this.getMembers();
  }

  deleteMember(member: EntityMember) {
    this.profileService
      .deleteEntityMember(
        member.id,
        member.referenceInvestorMetaIndividualId,
        this.referenceInvestorProfileId$.getValue(),
      )
      .subscribe(() => {
        this.getMembers();
      });
  }
}
