import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  EMessageItemInfoStatus,
  IEMessageItemInfoType
} from '@stream/libs/common/chat/components/message-item/message-item.model';
import {
  ChatMessage,
  ChatMessageValue,
  ChatRecipientTypeEnum,
  ChatResponseEnum
} from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { uuid } from '@stream/utils';
import _ from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, Subject, Subscription, interval, of } from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  finalize,
  map,
  scan,
  shareReplay,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import { AccountDatePipe } from 'src/app/modules/shared/pipes/account-date.pipe';
import { AccountService } from 'src/app/services/account.service';

import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'stream-chat-message-list',
  templateUrl: './chat-message-management.component.html',
  styleUrls: ['./chat-message-management.component.scss']
})
export class ChatMessageManageComponent implements OnInit, OnDestroy, AfterViewInit {
  pageSize = 30;

  pageNum = 1;

  hasMore = false;

  scrollDistance = 0;

  pollingSize = this.pageSize;

  polling = false;

  sending = false;

  scrollBehavior: 'toBottom' | 'noChange' | '' = '';

  earliestUnReadMsgId?: string;

  unloadLocalMsgs: ChatMessage[] = [];

  conversationId = this.chatService.activeConversationId;

  disabled$ = this.chatService.conversationDisabled;

  fileUploadApi$ = this.chatService.fileUploadApi;

  PollingSub?: Subscription;

  @ViewChild('messagesWrapper')
  messagesWrapperRef?: ElementRef<HTMLDivElement>;

  @ViewChildren('messageItem')
  messageItemElements?: QueryList<any>;

  @Input()
  lastReadTime!: string;

  Update$ = new Subject();

  messageStore$ = this.Update$.pipe(
    scan((messages: ChatMessage[], operation: any) => {
      const newMessages = operation(messages);
      if (_.isEqual(newMessages, messages)) {
        return messages;
      }
      return newMessages;
    }, []),
    shareReplay(1)
  );

  Action$ = new BehaviorSubject<{
    type:
      | 'INIT'
      | 'HISTORY'
      | 'POLLING'
      | 'INSERT_MSG'
      | 'QUERY_SENDED'
      | 'REMOVE_MSG'
      | 'MARK_MSG_AS_FAILED'
      | 'MARK_MSG_AS_SENDING';
    data?: Partial<ChatMessage>;
  }>({ type: 'INIT' });

  constructor(
    private chatService: ChatService,
    private accountService: AccountService,
    private notification: NotificationService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.registerMessageChangeListener();
    this.registerActionListener();
  }

  ngOnInit(): void {
    this.chatService.checkPermission.next();
    this.chatService.CacheStore.pipe(take(1)).subscribe(localData => {
      if (localData?.[this.conversationId]) {
        this.unloadLocalMsgs = localData[this.conversationId].slice();
      }
    });
    this.startPollingMessages();
  }

  ngAfterViewInit(): void {
    this.messageItemElements?.changes
      .pipe(withLatestFrom(this.Action$))
      .subscribe(([_, { type }]) => {
        if (type === 'INIT') {
          this.scrollToBottom('auto');
          return;
        }
        if (type === 'INSERT_MSG' || type === 'QUERY_SENDED') {
          this.scrollToBottom('smooth');
          return;
        }
        if (type === 'HISTORY') {
          this.restoreScrollPosition();
          return;
        }
        if (type === 'POLLING' && this.isScrollNearBottom()) {
          this.scrollToBottom('smooth');
        }
      });
  }

  registerMessageChangeListener() {
    this.messageStore$.pipe(withLatestFrom(this.Action$)).subscribe(([messages, { type }]) => {
      this.pollingSize = messages.filter(
        msg => msg.status === EMessageItemInfoStatus.success
      ).length;
      if (type === 'INIT') {
        this.getEarliestUnReadMsgId(messages);
      }
      if (type === 'POLLING') {
        this.pageNum = Math.max(this.pageNum, Math.ceil(this.pollingSize / this.pageSize));
      }

      if (type !== 'INSERT_MSG' && type !== 'MARK_MSG_AS_SENDING') {
        setTimeout(() => {
          this.polling = true;
        });
      }
    });
  }

  registerActionListener() {
    this.Action$.subscribe(({ type, data }) => {
      if (type === 'POLLING') {
        this.pollingMessages();
        return;
      }
      this.polling = false;
      if (type === 'HISTORY' || type === 'INIT') {
        this.getHistoryMessages();
        return;
      }
      if (type === 'QUERY_SENDED' && data) {
        this.getLatestMessages(data);
        return;
      }
      if (type === 'INSERT_MSG' && data) {
        this.insertSendingMessage(data);
        return;
      }
      if (type === 'REMOVE_MSG' && data) {
        this.removeMessage(data);
        return;
      }
      if (data && (type === 'MARK_MSG_AS_FAILED' || type === 'MARK_MSG_AS_SENDING')) {
        this.updateMessage(data);
      }
    });
  }

  insertSendingMessage(data: Partial<ChatMessage>) {
    this.chatService.user$.subscribe(({ investAccount, investPrincipals }) => {
      const { firstName, lastName, id } = investAccount;
      const { wmCandidate } = investPrincipals[0];
      const msg = {
        ...data,
        recipientId: id,
        recipientType: wmCandidate ? ChatRecipientTypeEnum.WM : ChatRecipientTypeEnum.LP,
        firstName,
        lastName,
        userName: `${firstName} ${lastName}`,
        messageTime: new Date().toString(),
        isDeleted: false,
        status: EMessageItemInfoStatus.sending
      };
      this.Update$.next((prevMsgs: ChatMessage[]) => {
        return [...prevMsgs, msg];
      });
      this.chatService.CacheAction$.next({
        conversationId: this.conversationId,
        type: 'INSERT_SENDING_MSG',
        data: msg
      });
    });
  }

  updateMessage(data: Partial<ChatMessage>) {
    this.Update$.next((prevMsgs: ChatMessage[]) => {
      return prevMsgs.map(msg => {
        if (msg.id === data.id) {
          return { ...msg, ...data };
        }
        return msg;
      });
    });
  }

  removeMessage(data: Partial<ChatMessage>) {
    this.Update$.next((prevMsgs: ChatMessage[]) => {
      return prevMsgs.filter(msg => msg.id !== data.id);
    });
    this.chatService.CacheAction$.next({
      conversationId: this.conversationId,
      type: 'REMOVE_MSG',
      data
    });
  }

  getLatestMessages(sendingMsg: Partial<ChatMessage>) {
    this.chatService
      .getCurrentConversationMessages(this.conversationId, {
        current: 1,
        size: this.pageSize
      })
      .subscribe(
        data => {
          this.Update$.next(this.latestOperation(data.rows, sendingMsg));
          this.chatService.CacheAction$.next({
            conversationId: this.conversationId,
            type: 'REMOVE_MSG',
            data: sendingMsg
          });
        },
        () => {
          this.polling = true;
        }
      );
  }

  latestOperation(currentMsgs: ChatMessage[], sendingMsg: Partial<ChatMessage>) {
    return (prevMsgs: ChatMessage[]) => {
      const prevMsgIds = prevMsgs.map(msg => msg.id);
      return _.concat(
        prevMsgs.filter(msg => msg.id !== sendingMsg.id),
        _.chain(currentMsgs)
          .filter(msg => !prevMsgIds.includes(msg.id))
          .map(msg => ({
            ...msg,
            status: EMessageItemInfoStatus.success
          }))
          .sortBy(msg => {
            return moment(msg.messageTime).valueOf();
          })
          .value()
      );
    };
  }

  getHistoryMessages() {
    this.chatService
      .getCurrentConversationMessages(this.conversationId, {
        current: this.pageNum,
        size: this.pageSize
      })
      .subscribe(
        ({ totalPage, pageNo, rows }) => {
          if (totalPage > pageNo) {
            this.hasMore = true;
            this.pageNum++;
          } else {
            this.hasMore = false;
          }
          this.Update$.next(this.historyOperation(rows));
        },
        () => {
          this.polling = true;
        }
      );
  }

  loadLocalMsgs(messages: ChatMessage[]) {
    if (!this.unloadLocalMsgs.length) return [];
    if (!this.hasMore || messages.length < 3) {
      return this.unloadLocalMsgs.splice(0, this.unloadLocalMsgs.length);
    }
    const [{ messageTime: endTime }, { messageTime: startTime }] = [
      messages[0],
      messages[messages.length - 1]
    ];
    const loadMsgs = this.unloadLocalMsgs.filter(msg => {
      const m = moment(msg.messageTime);
      return (
        m.isAfter(endTime, 'millisecond') || m.isBetween(startTime, endTime, 'millisecond', '[]')
      );
    });
    const loadMsgIds = loadMsgs.map(msg => msg.id);
    this.unloadLocalMsgs = this.unloadLocalMsgs.filter(msg => !loadMsgIds.includes(msg.id));
    return loadMsgs;
  }

  historyOperation(currentMsgs: ChatMessage[]) {
    return (prevMsgs: ChatMessage[]) => {
      const prevMsgIds = prevMsgs.map(msg => msg.id);
      const localMsgs = this.loadLocalMsgs(currentMsgs);
      return _.concat(
        _.chain(currentMsgs)
          .filter(msg => !prevMsgIds.includes(msg.id))
          .map(msg => ({
            ...msg,
            status: msg.status || EMessageItemInfoStatus.success
          }))
          .concat(localMsgs)
          .sortBy(msg => {
            return moment(msg.messageTime).valueOf();
          })
          .value(),
        prevMsgs
      );
    };
  }

  pollingMessages() {
    this.chatService
      .getCurrentConversationMessages(this.conversationId, {
        current: 1,
        size: Math.max(this.pollingSize, this.pageSize)
      })
      .subscribe(({ rows }) => {
        if (!rows.length) return;
        this.Update$.next(this.pollingOperation(rows));
      });
  }

  pollingOperation(currentMsgs: ChatMessage[]) {
    return (prevMsgs: ChatMessage[]) => {
      if (!_.differenceBy(currentMsgs, prevMsgs, 'id').length) return prevMsgs;
      const prevMsgIds = prevMsgs.map(msg => msg.id);
      return _.concat(
        prevMsgs,
        _.chain(currentMsgs)
          .filter(msg => !prevMsgIds.includes(msg.id))
          .map(msg => ({
            ...msg,
            status: msg.status || EMessageItemInfoStatus.success
          }))
          .sortBy(msg => {
            return moment(msg.messageTime).valueOf();
          })
          .value()
      );
    };
  }

  getEarliestUnReadMsgId(messages: ChatMessage[]) {
    if (!messages.length || !this.lastReadTime) return;
    this.chatService.user$.subscribe(({ investAccount }) => {
      const receivedMsgs = messages.filter(msg => msg.recipientId !== investAccount.id);
      if (!receivedMsgs.length) return;
      const m = moment(this.lastReadTime);
      for (let i = receivedMsgs.length - 1; i >= 0; i--) {
        if (!m.isAfter(moment(receivedMsgs[i].messageTime), 'millisecond')) {
          this.earliestUnReadMsgId = receivedMsgs[i].id;
          break;
        }
      }
    });
  }

  submitMsg(msg: Pick<ChatMessage, 'id' | 'content' | 'attachment'>) {
    this.sending = true;
    return this.chatService
      .sendChatConversationMessages(this.conversationId, {
        content: msg.content,
        attachments: msg.attachment
      })
      .pipe(
        catchError(err => {
          if (err?.status !== 400) {
            return of(null);
          }
          return of({
            status: err?.status
          });
        }),
        finalize(() => {
          this.sending = false;
        })
      )
      .subscribe(data => {
        if (data && data.status === ChatResponseEnum.Success) {
          this.Action$.next({ type: 'QUERY_SENDED', data: msg });
        } else {
          if ((data as any)?.status !== 400) {
            this.notification.error('Send failed. You do not have permission to send messages.');
          }
          this.Action$.next({
            type: 'MARK_MSG_AS_FAILED',
            data: {
              id: msg.id,
              status: EMessageItemInfoStatus.failed
            }
          });
          this.chatService.CacheAction$.next({
            conversationId: this.conversationId,
            type: 'UPDATE_MSG',
            data: {
              ...msg,
              status: EMessageItemInfoStatus.failed
            }
          });
        }
      });
  }

  sendMsg(data: ChatMessageValue) {
    const msg = {
      content: data.contentValue || '',
      attachment: data.fileValue || []
    };
    const sendingMsg = {
      ...msg,
      id: 'SENDING_' + uuid()
    };
    this.Action$.next({ type: 'INSERT_MSG', data: sendingMsg });
    this.submitMsg(sendingMsg);
  }

  resendMsg(msg: ChatMessage) {
    this.Action$.next({
      type: 'MARK_MSG_AS_SENDING',
      data: {
        id: msg.id,
        status: EMessageItemInfoStatus.sending
      }
    });
    this.submitMsg(msg);
  }

  deleteMsg(data: ChatMessage) {
    this.Action$.next({ type: 'REMOVE_MSG', data });
  }

  startPollingMessages() {
    this.PollingSub = interval(5000)
      .pipe(
        delayWhen(() => this.disabled$.pipe(filter(disabled => !disabled))),
        filter(() => this.polling)
      )
      .subscribe(() => {
        this.Action$.next({
          type: 'POLLING'
        });
      });
  }

  handleScrollUp() {
    if (!this.hasMore) return;
    this.saveScrollDistance();
    this.Action$.next({
      type: 'HISTORY'
    });
  }

  scrollToBottom(behavior: 'auto' | 'smooth' = 'smooth') {
    if (!this.messagesWrapperRef) return;
    const wrapperEle = this.messagesWrapperRef.nativeElement;
    wrapperEle.scroll({
      top: wrapperEle.scrollHeight,
      behavior
    });
  }

  saveScrollDistance() {
    if (!this.messagesWrapperRef) return;
    const wrapper = this.messagesWrapperRef.nativeElement;
    const scrollTop = wrapper.scrollTop || 1;
    this.scrollDistance = wrapper.scrollHeight - scrollTop;
  }

  restoreScrollPosition() {
    if (!this.messagesWrapperRef) return;
    const wrapperEle = this.messagesWrapperRef.nativeElement;
    const offset = 70;
    wrapperEle.scroll({
      top: wrapperEle.scrollHeight - this.scrollDistance - offset,
      behavior: 'auto'
    });
  }

  isScrollNearBottom() {
    if (!this.messagesWrapperRef) return false;
    const threshold = 220;
    const { scrollTop, scrollHeight, clientHeight } = this.messagesWrapperRef.nativeElement;
    const position = scrollTop + clientHeight;
    return position > scrollHeight - threshold;
  }

  transformMessageTime(time: string) {
    return new AccountDatePipe(this.accountService, this.locale).transform(time, 'dd MMM, h:mm aa');
  }

  transformRolesType(recipientType: ChatRecipientTypeEnum, rolesType?: string[]) {
    if (recipientType === ChatRecipientTypeEnum.LP) {
      return ['LP'];
    }
    if (recipientType === ChatRecipientTypeEnum.WM) {
      return ['wmCandidate'];
    }
    return rolesType ?? [];
  }

  transform(message: ChatMessage) {
    return this.chatService.user$.pipe(
      switchMap(userInfo => {
        return this.transformMessageTime(message.messageTime).pipe(
          map(messageTime => [userInfo, messageTime])
        );
      }),
      map(([{ investAccount }, messageTime]) => {
        return {
          id: message.id,
          position: message.recipientId === investAccount.id ? 'right' : 'left',
          status: message.status,
          content: message.content,
          attachment: message.attachment,
          rolesType: this.transformRolesType(message.recipientType, message.roleTypes),
          textBlack: message.isDeleted ? `${message.userName}(Deleted)` : message.userName,
          textGray: `replied on ${messageTime}`
        } as IEMessageItemInfoType;
      })
    );
  }

  trackByFn: TrackByFunction<ChatMessage> = (_index, item) => item.id;

  ngOnDestroy(): void {
    this.PollingSub?.unsubscribe();
  }
}
