<stream-start *ngIf="panelService.start | async; else gatewayContent"></stream-start>

<ng-template #gatewayContent>
  <stream-end *ngIf="panelService.resultGateway | async; else nodeContent"></stream-end>

  <ng-template #nodeContent>
    <h1 class="text-primary-500 mb-4 text-[26px] font-semibold">
      {{ (panelService.nodeData | async)?.nodeName }}
    </h1>
    <p class="mb-4 text-base text-gray-600">
      {{ (panelService.nodeData | async)?.nodeDescription }}
    </p>
    <ng-container #nodeContentContainer></ng-container>

    <ng-template #nodeRender>
      <ng-container [ngSwitch]="(panelService.nodeData | async)?.type">
        <stream-scenario-form *ngSwitchCase="nodeType.Form"></stream-scenario-form>
        <stream-statement *ngSwitchCase="nodeType.Statement"></stream-statement>
        <stream-questionnaire *ngSwitchCase="nodeType.Questionnaire">
        </stream-questionnaire>
        <stream-custodian *ngSwitchCase="nodeType.Custodian"></stream-custodian>
        <stream-sign *ngSwitchCase="nodeType.Sign"></stream-sign>
      </ng-container>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #discardDialog>
  <header class="!flex items-center" mat-dialog-title>
    <h2 class="flex-1">{{
      panelService.subscriptionId ? 'Discard changes' : 'Discard subscription'
    }}</h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <p mat-dialog-content class="md:w-[30vw]">
    {{
      panelService.subscriptionId
        ? 'Are you sure you want to discard changes?'
        : 'Are you sure you want to discard this subscription?'
    }}
  </p>
  <footer mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" [mat-dialog-close]="false">
      Cancel
    </button>
    <button mat-flat-button [mat-dialog-close]="true" color="primary"> Confirm </button>
  </footer>
</ng-template>

<footer
  class="sticky bottom-0 z-30 mt-4 flex justify-between gap-x-4 border-t border-gray-300 bg-white py-4 empty:hidden md:justify-end"
>
  <ng-template [cdkPortalOutlet]="footerPortal"></ng-template>
</footer>
