import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStoreKeyEnum, LoginInfo, LpPlatformApi, Restful } from '@stream/models';
import { AccountService } from '@stream/service/account.service';
import { LpAuthApi, SendEmailApi } from '@stream/src/common';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

const WHITE_LIST: string[] = [
  LpPlatformApi.GetConfiguration,
  LpPlatformApi.Tenant,
  SendEmailApi.VerifyRegister,
  ...Object.values(LpAuthApi)
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  @LocalStorage(AuthStoreKeyEnum.AccessToken, '')
  accessToken!: string;

  @LocalStorage(AuthStoreKeyEnum.RefreshToken, '')
  refreshToken!: string;

  @LocalStorage(AuthStoreKeyEnum.PrincipalToken, '')
  principalToken!: string;

  isRefreshing = false;

  refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private router: Router
  ) {}

  handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addToken(request)))
      );
    } else {
      this.isRefreshing = true;
      return this.refresh().pipe(
        catchError(error => {
          this.accountService.logout().subscribe();

          return throwError(error);
        }),
        switchMap(({ data: { tokenResponse } }) => {
          this.accessToken = tokenResponse.accessToken;
          this.refreshToken = tokenResponse.refreshToken;
          this.principalToken = tokenResponse.principalToken;
          this.refreshTokenSubject.next(tokenResponse.accessToken);

          return next.handle(this.addToken(request));
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    }
  }

  private refresh() {
    return this.http.get<Restful<{ tokenResponse: LoginInfo['tokenData'] }>>(
      LpAuthApi.RefreshToken,
      {
        headers: {
          RefreshToken: this.refreshToken
        }
      }
    );
  }

  private addToken(request: HttpRequest<unknown>) {
    return request.clone({
      setHeaders: {
        AccessToken: this.accessToken
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (WHITE_LIST.includes(request.url)) return next.handle(request);
    return next.handle(this.addToken(request)).pipe(
      catchError(error => {
        if ((!this.accessToken && !this.refreshToken) || !this.principalToken) {
          this.accountService.logout().subscribe();
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (!this.refreshToken) {
            return throwError(error);
          }

          return this.handle401Error(request, next);
        }

        // Data overreach, redirect to product list page
        if (error?.error?.code === 'B-00032' && location.pathname !== '/product/list') {
          this.router.navigate(['/product/list']);
        }

        return throwError(error);
      })
    );
  }
}
