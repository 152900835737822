import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Restful } from '@stream/models';
import { of } from 'rxjs';

import { TRANSLATE_CONFIG } from './translate.constants';
import { TranslateConfig, TranslateIgnore } from './translate.interface';

@Injectable()
export class TranslateService {
  origin: string;
  ignore: Partial<TranslateIgnore>;

  constructor(
    @Inject(TRANSLATE_CONFIG) public config: TranslateConfig,
    private http: HttpClient
  ) {
    this.config = config;
    this.origin = this.config.origin;
    this.ignore = this.config.ignore ?? {};
  }
  translate(params: { text: string[]; to: string; from: string }) {
    if (params.to === params.from) {
      return of({
        data: {
          text: params.text,
        },
      });
    }
    return this.http.post<Restful<{ text: string[] }>>('/v2/translate', {
      ...params,
      from: params.from ?? this.config.origin,
    });
  }
}
