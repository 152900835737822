<div class="main-menu flex flex-1 flex-col" *ngIf="showMainMenu">
  <div class="mb-5 px-7 pt-7">
    <div class="logo-container stream-translate-ignore">
      <img
        class="h-auto w-auto"
        [src]="
          ((configuration | async)?.primaryLogoUrl | imageResize: { height: 95 }) ||
          'assets/images/default-logo.png'
        "
        alt="Logo"
      />
    </div>
  </div>
  <div class="nav-container px-7">
    <nav class="flex flex-col font-semibold text-primary-300" aria-label="">
      <stream-nav-menu [menuList]="mainMenuList"></stream-nav-menu>
    </nav>
  </div>
  <div class="mx-3 -translate-y-4" *ngIf="showNotification && (wmCandidate$ | async) !== true">
    <stream-invite-member-notification></stream-invite-member-notification>
  </div>
  <div
    class="user-entrance relative"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (mouseover)="showOverlayMenu()"
    (mouseleave)="overlayTriggerLeave()"
  >
    <img
      class="mr-3 h-10 w-10 rounded-full bg-dynamic-primary-500"
      [src]="(account | async)?.avatar || 'assets/images/default-avatar-simple.png'"
      alt="Avatar"
    />
    <span class="text-base text-primary-900">
      {{ (account | async)?.firstName }} {{ (account | async)?.lastName }}
    </span>
  </div>

  <ng-template
    #overlayMenu
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayMenuVisible"
  >
    <div
      class="overlay-menu divide-y divide-primary-100 p-7"
      [@slideInOut]
      (mouseenter)="handleMouseEnter()"
      (mouseleave)="overlayMenuLeave()"
    >
      <div class="user-info flex w-full flex-col gap-y-3 pb-5">
        <ng-container *ngTemplateOutlet="userInfo"></ng-container>
      </div>
      <div>
        <nav class="flex flex-col py-5 font-semibold text-primary-300" aria-label="">
          <ng-container *ngFor="let menu of secondaryMenuList">
            <a
              class="route-item"
              [routerLink]="getSecondaryMenuDeeplyPath(menu)"
              routerLinkActive="route-active"
              (click)="showSecondaryMenu = true"
            >
              <svg class="icon menu-icon" aria-hidden="true" *ngIf="menu.icon">
                <use [attr.xlink:href]="menu.icon"></use>
              </svg>
              {{ menu.label }}
            </a>
          </ng-container>
        </nav>
        <button mat-flat-button color="primary" class="!h-12 w-full" (click)="signOut()">
          Sign out
        </button>
      </div>
    </div>
  </ng-template>
</div>

<div class="secondary-menu flex flex-1 flex-col" *ngIf="showSecondaryMenu">
  <div class="mb-4 flex-shrink-0 p-7 shadow">
    <div class="user-container">
      <img
        class="h-10 w-10 rounded-full bg-dynamic-primary-500"
        [src]="(account | async)?.avatar || 'assets/images/default-avatar-simple.png'"
        alt="Avatar"
      />
      <div class="user-info flex flex-col gap-y-3 pl-5">
        <ng-container *ngTemplateOutlet="userInfo"></ng-container>
      </div>
    </div>
  </div>
  <div class="nav-container px-7">
    <nav class="flex flex-col font-semibold text-primary-300" aria-label="">
      <stream-nav-menu [menuList]="secondaryMenuList"></stream-nav-menu>
    </nav>
  </div>
  <div class="user-entrance" (click)="onBack()">
    <span class="material-icons"> keyboard_arrow_left </span>
    <span class="pl-2">Back</span>
  </div>
</div>

<ng-template #userInfo>
  <div class="truncate text-base font-bold text-primary-900">
    {{ (account | async)?.firstName }}
    {{ (account | async)?.lastName }}
  </div>
  <div
    class="stream-translate-ignore flex text-sm text-primary-300"
    *ngIf="(account | async)?.email"
    [matTooltip]="(account | async)?.email ?? ''"
  >
    <span class="flex-shrink truncate">{{ (splitEmail | async)?.[0] }}</span>
    <span>&#64;</span>
    <span>{{ (splitEmail | async)?.[1] }}</span>
  </div>
  <div class="truncate text-sm text-primary-900">
    <span class="rounded bg-success-10 px-2 py-1 leading-6" *ngIf="principalIdentity | async">
      {{ principalIdentity | async }}
    </span>
  </div>
</ng-template>
