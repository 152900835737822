import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { CountriesModule } from '../countries';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { ProfileIRAService } from './services/profile-ira.service';

const matModules = [
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatTooltipModule
];

@NgModule({
  declarations: [DocumentsListComponent, ProfileCardComponent],
  imports: [CommonModule, ...matModules, FormsModule, NgxExtendedPdfViewerModule, CountriesModule],
  providers: [ProfileIRAService],
  exports: [DocumentsListComponent, ProfileCardComponent]
})
export class ProfileSharedModule {}
