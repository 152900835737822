<ng-container [formGroup]="$any(formControl)">
  <mat-radio-group
    class="grid grid-cols-1 gap-4 xl:grid-cols-2"
    formControlName="id"
    #group="matRadioGroup"
    (change)="onProfileChange($event)"
  >
    <ng-container *ngFor="let profile of profileList | async; let i = index">
      <mat-radio-button
        class="radio"
        [disabled]="profile.valueDisabledRadio"
        [value]="profile.id"
        [style.order]="i * 2"
      >
        <stream-profile-card
          [profile]="profile"
          [reModify]="reModify"
          [isDisableIRA]="!!isDisableIRA"
          [isUnsupportCustodian]="!!isUnsupportCustodian"
          #profile
          class="profile-card h-full"
          (edit)="profileEventEmit(StreamFormlyEventTypeEnum.ProfileEdit, $event)"
          (delete)="profileEventEmit(StreamFormlyEventTypeEnum.ProfileDelete, $event)"
          (disabledRadioChange)="handleDisabledRadioChange($event)"
        ></stream-profile-card>
      </mat-radio-button>
    </ng-container>

    <div
      class="add-profile"
      *ngIf="!reModify"
      [style]="{ order: ((profileList | async)?.length ?? 1) * 2 }"
      (click)="profileEventEmit(StreamFormlyEventTypeEnum.ProfileAdd)"
    >
      <mat-icon class="add-icon">add</mat-icon>
      <div>Create a new one</div>
    </div>
  </mat-radio-group>
</ng-container>
