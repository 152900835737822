<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="$any(formControl)"
  [formlyAttributes]="field"
  [placeholder]="to.placeholder ?? ''"
  autocomplete="off"
  #trigger="matAutocompleteTrigger"
  (focus)="trigger.openPanel()"
  [errorStateMatcher]="errorStateMatcher"
/>
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (closed)="checkOption()"
>
  <mat-option
    *ngFor="let option of filteredOptions | async"
    [value]="option.value"
  >
    {{ option.label }}
  </mat-option>
  <mat-option disabled hidden></mat-option>
</mat-autocomplete>
