import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Principal } from '@stream/models';
import { map, take } from 'rxjs/operators';

import { AccountService } from '../../../services/account.service';

@Injectable({
  providedIn: 'root',
})
export class PrincipalResolver implements Resolve<Principal['principalType']> {
  constructor(private accountService: AccountService) {}
  resolve() {
    return this.accountService.principal.pipe(
      take(1),
      map(({ principalType, type }) => principalType || type)
    );
  }
}
