import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { Logic } from '../condition-builder.model';
import { ConditionBuilderService } from '../condition-builder.service';

@Component({
  selector: 'stream-expression-list',
  templateUrl: './expression-list.component.html',
  styleUrls: ['./expression-list.component.less'],
})
export class ExpressionListComponent {
  constructor(private service: ConditionBuilderService) {}

  expressionControls = this.service.expressionControl.controls as FormArray[];

  logicSymbol$ = this.service.logicControl.valueChanges.pipe(
    startWith(this.service.logicControl.value),
    map((value: Logic) =>
      // @ts-ignore
      Object.keys(Logic).find(logicKey => value === Logic[logicKey]),
    ),
    shareReplay(1),
  );

  deleteItem(index: number) {
    this.service.removeExpression(index);
  }
}
