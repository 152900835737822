import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { FormlyModule } from '@ngx-formly/core';
import { StreamFormlyType } from '@stream/models';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TableWrapperComponent } from './table-wrapper.component';
import { TableComponent } from './table/table.component';

const matModules = [MatRadioModule, MatFormFieldModule, MatInputModule];

@NgModule({
  declarations: [TableComponent, TableWrapperComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...matModules,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'table-wrapper',
          component: TableWrapperComponent
        }
      ],
      types: [
        {
          name: StreamFormlyType.TABLE,
          component: TableComponent,
          wrappers: ['table-wrapper']
        }
      ]
    })
  ]
})
export class TableModule {}
