import { Component, HostListener, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { AutocompleteComponent } from '../../autocomplete/autocomplete/autocomplete.component';

@Component({
  selector: 'stream-area-code-select',
  templateUrl: './area-code-select.component.html',
  styleUrls: ['./area-code-select.component.scss']
})
export class AreaCodeSelectComponent extends AutocompleteComponent {
  @ViewChild('trigger')
  private triggerRef!: MatAutocompleteTrigger;

  override displayFn = (v: string) => {
    const target = (this.to.options as any[])?.find(({ value }) => value === v);
    if (target?.label) {
      const areaCode = target.label.split('+');
      return areaCode[1] ? `+${areaCode[1]}` : target.label;
    }
    return target?.label;
  };

  @HostListener('click')
  clickEvent() {
    if (!this.triggerRef.panelOpen) {
      setTimeout(() => {
        this.triggerRef.openPanel();
      }, 200);
    }
  }
}
