import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, ResolveEnd, Router, RouterStateSnapshot } from '@angular/router';
import tinycolor from '@ctrl/tinycolor';
import { BusinessErrorResponse } from '@stream/core';
import {
  ChatApi,
  ChatFeature,
  ChatResponseEnum,
  CustomDomain,
  GPMetaRegistration,
  HttpStatusEnum,
  LpConfiguration,
  LpPlatformApi,
  Restful
} from '@stream/models';
import { LpAuthApi } from '@stream/src/common';
import { getMatColors, getNeutralColors } from '@stream/utils';
import { LocalStorageService } from 'ngx-webstorage';
import { of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(
    private http: HttpClient,
    private readonly title: Title,
    private router: Router,
    private localStorage: LocalStorageService
  ) {}

  configuration = this.getKVConfig().pipe(
    switchMap(({ data: { hostname, identity } }) => {
      if (hostname) {
        this.localStorage.store(CustomDomain.Domain, hostname);
        this.localStorage.store(CustomDomain.PrefixValue, identity);
      }

      return this.getPlatformConfiguration();
    }),

    switchMap(({ data: { gpCommonStatusEnum, tenantInfoResponse } }) => {
      if (gpCommonStatusEnum === HttpStatusEnum.Success) {
        return of(tenantInfoResponse);
      } else {
        return throwError(
          new BusinessErrorResponse({
            status: 404,
            statusText: 'No Tenant',
            error: gpCommonStatusEnum
          })
        );
      }
    }),
    tap(({ platformIconUrl, platformDescription, companyName, primaryColor }) => {
      platformIconUrl && this.setFavicon(platformIconUrl);
      this.watchTitle(platformDescription, companyName);
      this.updateTheme(primaryColor);
    }),

    catchError(error => {
      if (error?.error?.code === 'B-00035') {
        // tenant was disabled
        this.router.navigate(['/domain-not-operational']);
      } else {
        throw error;
      }
      return of();
    }),

    shareReplay(1)
  );

  tenantFeatures$ = this.getTenantFeatures().pipe(shareReplay(1));

  /**
   * 更新主题色
   * @param primaryColor 主题色
   */
  updateTheme(primaryColor: string = '#000000') {
    const style = document.documentElement.style;
    getMatColors(primaryColor).forEach(({ name, hex }) => {
      const { r, g, b } = tinycolor(hex).toRgb();
      style.setProperty(`--dynamic-primary-${name}`, [r, g, b].join(' '));
      style.setProperty(
        `--dynamic-primary-contrast-${name}`,
        tinycolor(hex).isLight() ? 'rgba(black,0.87)' : 'white'
      );
    });
    getNeutralColors().forEach(({ name, hex, contrast }) => {
      style.setProperty(`--theme-primary-${name}`, hex);
      style.setProperty(`--theme-primary-contrast-${name}`, contrast);
    });
  }

  /**
   * 设置当前favicon
   * @param url icon地址
   */
  setFavicon(url: string) {
    const linkElement = document.querySelector<HTMLAnchorElement>('link[rel=icon]');
    if (linkElement) {
      linkElement.href = url;
    }
  }

  /**
   * 设置页面title
   * @param snapshot 路由快照
   * @param platformDescription 平台描述
   * @param companyName 公司名称
   */
  setTitle(snapshot: RouterStateSnapshot, platformDescription: string, companyName: string) {
    const { data } = this.getDeepestChildSnapshot(snapshot.root);
    if (data?.title) {
      this.title.setTitle(`${data.title} - ${platformDescription || companyName}`);
    } else {
      this.title.setTitle(platformDescription || companyName);
    }
  }

  /**
   * 监听路由变化修改title
   * @param platformDescription 平台描述
   * @param companyName 公司名 用于未配置平台描述的情况
   */
  watchTitle(platformDescription: string, companyName: string) {
    this.setTitle(this.router.routerState.snapshot, platformDescription, companyName);
    this.router.events.subscribe(event => {
      if (event instanceof ResolveEnd) {
        this.setTitle(event.state, platformDescription, companyName);
      }
    });
  }

  /**
   * 获取当前最下层route信息
   * @param snapshot
   * @returns
   */
  private getDeepestChildSnapshot(snapshot: ActivatedRouteSnapshot) {
    let deepestChild = snapshot.firstChild;
    while (deepestChild?.firstChild) {
      deepestChild = deepestChild.firstChild;
    }
    return deepestChild || snapshot;
  }

  /**
   * 获取投资人端配置
   */
  getPlatformConfiguration() {
    return this.http.get<
      Restful<{
        gpCommonStatusEnum: HttpStatusEnum;
        tenantInfoResponse: LpConfiguration;
      }>
    >(LpPlatformApi.GetConfiguration, {
      params: {
        prefixValue: window.localStorage.getItem('lp|prefix_value')
          ? JSON.parse(window.localStorage.getItem('lp|prefix_value')!)
          : location.hostname.split('.')[0]
      }
    });
  }

  getKVConfig() {
    const location = window.location.origin;
    let isSelf =
      location.includes('localhost') ||
      location.includes('bite.dev') ||
      location.includes('bitestream.co');
    if (isSelf || !!window.localStorage.getItem('lp|custom_domain')) {
      // do not need return
      return of({
        data: {
          hostname: '',
          identity: ''
        },
        message: 'success',
        code: 0
      });
    }
    return this.http.get<
      Restful<{
        identity: string;
        hostname: string;
      }>
    >(LpPlatformApi.Tenant);
  }

  addActivity(action: string, activitiesTypeEnum: string, extra: Record<string, unknown>) {
    return this.http.post(LpPlatformApi.AddActivities, {
      action,
      activitiesTypeEnum,
      ...extra
    });
  }

  getRegistrationConfig() {
    return this.http
      .get<
        Restful<{ gpFlowMetaRegistration: GPMetaRegistration }>
      >(LpAuthApi.QueryRegistrationConfig)
      .pipe(map(res => res.data?.gpFlowMetaRegistration ?? {}));
  }

  getTenantFeatures() {
    return this.http
      .get<
        Restful<{ features: ChatFeature[]; status: ChatResponseEnum }>
      >(ChatApi.getTenantFeatures)
      .pipe(
        map(({ data }) => {
          return data?.status === ChatResponseEnum.Success && data?.features ? data?.features : [];
        })
      );
  }

  getTenantSetting(params: { settingKey: string }) {
    return this.http.get<Restful<{ settingValue: string }>>(LpPlatformApi.TenantSetting, {
      params
    });
  }

  getTenantSettings(params: { settingKeys: string[] }) {
    return this.http.get<Restful<{ settingValue: string }[]>>(LpPlatformApi.TenantSettings, {
      params
    });
  }
}
