import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FormlyForm, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FORM_ERROR_MESSAGE, FormErrorCodeEnum, StreamFormlyType } from '@stream/models';

import { STREAM_FORMLY_CONFIG } from '../lib/formly.config';
import { AmountModule } from './amount/amount.module';
import { AreaCodeSelectModule } from './area-code-select/area-code-select.module';
import { AutocompleteModule } from './autocomplete/autocomplete/autocomplete.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { FormlyFieldWrapperModule } from './formly-field-wrapper/formly-field-wrapper.module';
import { MandatoryDocsModule } from './mandatory-docs/mandatory-docs.module';
import { PanelComponent } from './panel/panel.component';
import { PhoneModule } from './phone/phone.module';
import { ProfileFormModule } from './profile/profile.module';
import { RadioModule } from './radio/radio.module';
import { StreamFormlyComponent } from './stream-formly.component';
import { TableModule } from './table/table.module';
import { TextModule } from './text/text.module';

const matModules = [MatButtonModule];
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...matModules,
    FormlyFieldWrapperModule,
    FormlyModule.forRoot({
      extras: {
        resetFieldOnHide: true,
      },
      types: [
        {
          name: StreamFormlyType.CHECKBOX,
          defaultOptions: {
            templateOptions: {
              indeterminate: false,
            },
          },
        },
        {
          name: StreamFormlyType.SELECT,
          defaultOptions: {
            templateOptions: {
              disableOptionCentering: true,
            },
          },
          wrappers: ['stream-formly-field-wrapper'],
        },
        {
          name: StreamFormlyType.INPUT,
          wrappers: ['stream-formly-field-wrapper'],
        },
      ],

      validationMessages: [
        {
          name: FormErrorCodeEnum.Required,
          message: FORM_ERROR_MESSAGE.get(FormErrorCodeEnum.Required) ?? '',
        },
      ],
    }),
    FormlyMaterialModule,
    RadioModule,
    TextModule,
    PhoneModule,
    CheckboxModule,
    TableModule,
    AmountModule,
    AutocompleteModule,
    AreaCodeSelectModule,
    MandatoryDocsModule,
    ProfileFormModule,
  ],
  declarations: [PanelComponent, StreamFormlyComponent],
  exports: [PanelComponent, StreamFormlyComponent, FormlyForm],
})
export class StreamFormlyModule {
  static forRoot(
    option: { editMode: boolean } = { editMode: false }
  ): ModuleWithProviders<StreamFormlyModule> {
    return {
      ngModule: StreamFormlyModule,
      providers: [{ provide: STREAM_FORMLY_CONFIG, useValue: option }],
    };
  }
}
