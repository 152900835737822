<div
  class="mx-auto flex max-w-4xl flex-1 flex-col items-center justify-center pb-12 xl:pb-24 2xl:pb-48"
>
  <!-- this text use for express intresting and so on -->
  <img [src]="(hitResultShow | async)?.icon" class="h-auto w-32" alt="icon" />
  <h2 class="my-6 text-2xl font-semibold">
    {{ (hitResultShow | async)?.title }}
  </h2>
  <p
    class="text-center text-base text-gray-600"
    *ngIf="
      (isKYCProcess | async) === true || !panelService.isCompleteInvestment;
      else notKYCResult
    "
  >
    {{ (hitResultShow | async)?.label }}
  </p>

  <!-- complete investment result text -->
  <ng-template #notKYCResult>
    <p class="text-center text-base text-gray-600">
      <!-- late sign, select the corresponding template based on the signer status.  -->
      <span *ngIf="panelService.lateSign | async; else nonLateSignTemplate">
        <ng-container
          *ngIf="
            panelService.isModifyInvest || !(panelService.isFirstSigner | async);
            else signerTemplate
          "
        >
          We will notify you as soon as we have reviewed your submission and then the
          relevant person(s) will be invited via email to sign the investment documents.
          You can view and download the existing investment documents in
        </ng-container>
        <a
          i18n
          class="ml-2.5 underline font-bold text-[#333] hover:font-bold hover:underline"
          (click)="redirect()"
        >
          "My Investments".
        </a>
        <ng-template #signerTemplate>
          We will notify you as soon as we have reviewed your submission and then you will
          be invited via email to sign the investment documents. You can view and download
          the existing investment documents in
        </ng-template>
      </span>

      <ng-template #nonLateSignTemplate>
        {{ (hitResultShow | async)?.label }}
      </ng-template>
    </p>
    <p
      class="mt-4 text-center text-base text-[#333]"
      *ngIf="(hitResultShow | async)?.salesSupportEmail"
    >
      <span>If you encounter any problems, please feel free to contact us at</span>
      <span class="ml-2.5">
        <a class="font-bold" [href]="(hitResultShow | async)?.mailto" target="_blank">{{
          (hitResultShow | async)?.salesSupportEmail
        }}</a>
      </span>
    </p>
  </ng-template>
</div>

<footer class="flex flex-none border-t border-gray-300 py-4 md:justify-end">
  <button
    mat-flat-button
    color="primary"
    (click)="close()"
    class="mat-button-lg w-full text-base font-semibold md:w-40"
  >
    {{ (hitResultShow | async)?.nodeButtonName?.['done'] ?? 'Done' }}
  </button>
</footer>
