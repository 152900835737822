<header class="!flex items-center" mat-dialog-title >
  <h2 class="!mb-0 text-lg md:text-xl flex-1">Refresh Required</h2>
  <button mat-icon-button mat-dialog-close="" class="!hidden md:!inline-block">
    <mat-icon [mat-dialog-close]="false">close</mat-icon>
  </button>
</header>
<p mat-dialog-content class="text-primary-300">
  The configuration of this page has been updated. Please refresh the page to
  access the latest features.
</p>
<footer class="flex items-center justify-between gap-x-2 md:justify-end">
  <button
    mat-stroked-button
    class="mat-button-lg w-1/2 md:w-auto md:!px-6"
    color="primary"
    [mat-dialog-close]="true"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    class="mat-button-lg w-1/2 md:w-auto md:!px-6"
    color="primary"
    (click)="refreshWindow()"
  >
    Refresh
  </button>
</footer>
