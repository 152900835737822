<form [formGroup]="form" (ngSubmit)="submit()" class="flex h-full flex-col">
  <div class="mb-4 !flex items-center md:mb-5" mat-dialog-title>
    <h1 class="flex-1 text-lg font-semibold text-primary-500 md:text-xl" >
      {{ data.address ? 'Edit' : 'Add' }} address
    </h1>
    <button mat-icon-button mat-dialog-close class="!hidden md:!inline-block">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    mat-dialog-content
    class="address-form-content flex-1"
    [style]="{ 'max-height': contentH }"
  >
    <mat-form-field>
      <mat-label>Address line 1</mat-label>
      <input autocomplete="address-line1" matInput formControlName="addressLineOne" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Address line 2 (Optional)</mat-label>
      <input autocomplete="address-line2" matInput formControlName="addressLineTwo" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>City/town</mat-label>
      <input autocomplete="address-level2" matInput formControlName="addressTown" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>State/province/county</mat-label>
      <input autocomplete="address-level1" matInput formControlName="addressState" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Country</mat-label>
      <ngx-select-country
        formControlName="addressCountryCode"
        [required]="true"
        [options]="countryList$"
      ></ngx-select-country>
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>ZIP code</mat-label>
      <input matInput formControlName="zipCode" autocomplete="postal-code" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <ng-container *ngIf="!isEntity">
      <stream-mandatory-doc-item
        *ngIf="!hideAddress"
        #mandatoryDocItem
        [form]="mdForm"
        [md]="mdConfig.mandatoryDocs[0]"
        [config]="mdConfig"
      >
        <p class="doc-description text-xs mb-2">
          Please upload a proof of address to be used for this investor profile.
        </p>
      </stream-mandatory-doc-item>
    </ng-container>
  </div>

  <div mat-dialog-actions class="grid grid-cols-2 justify-around md:flex md:justify-end">
    <button
      mat-stroked-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      cdkFocusInitial
      type="submit"
      [disabled]="loading"
    >
      Confirm
    </button>
  </div>
</form>
