export enum DistributionFeeTypeEnum {
  OneTime = 'ONE_TIME',
  Year = 'YEAR',
}

export interface MetaCountry {
  countryAlpha2Code: string;
  countryAlpha3Code: string;
  countryId: string;
  countryName: string;
  countryNumeric: string;
  countryRemark: string;
  dataCreateTime: Date;
  dataIsDeleted: boolean;
  dataUpdateTime: Date;
  id: number;
  isRegion: boolean;
}

export enum SubscriptionFeeType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export interface ShareClass {
  countryAlpha2Code: MetaCountry['countryAlpha2Code'];
  countryAlpha3Code: MetaCountry['countryAlpha3Code'];
  countryId: MetaCountry['countryId'];
  countryName: MetaCountry['countryName'];
  countryNumeric: MetaCountry['countryNumeric'];
  distributionFee: string;
  distributionFeeOpen: boolean;
  distributionFeeType: DistributionFeeTypeEnum;
  id: string;
  isDefault: boolean;
  currency: string;
  minimumInvestment: number;
  managementFee: number;
  managementFeeOpen: boolean;
  subsequentInvestment: number;
  name: string;
  orderIndex: number;
  performanceFee: number;
  performanceFeeOpen: boolean;
  performanceFeeRemark: string;
  productId: string;
  subscriptionFee: number;
  subscriptionFeeOpen: boolean;
  subscriptionFixedFee: number;
  subscriptionFeeType: SubscriptionFeeType;
  targetSize: number;
  tenantId: string;
  secTimeOpen: boolean;
  minimumInvestmentAtSecTime: number;
  managementFeeType: DistributionFeeTypeEnum;
}
