import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'stream-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent extends FieldType<any> implements OnInit {
  filteredOptions?: Observable<any[]>;

  displayFn = (v: string) => (this.to.options as any[])?.find(({ value }) => value === v)?.label;

  ngOnInit(): void {
    // todo 不继承 夫级的 ngOnInit
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(null),
      map((v: any) =>
        (this.to.options as any[])?.filter(({ label }) =>
          label?.toLowerCase().includes(v?.toLowerCase() ?? '')
        )
      )
    );
  }

  checkOption() {
    if (!~(this.to.options as any[]).findIndex(({ value }) => value === this.formControl.value)) {
      this.formControl.setValue(null);
      if (this.to.required) {
        this.formControl.setErrors({ required: true });
      }
    }
  }
}
