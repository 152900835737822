import { DebounceSettings, debounce } from 'lodash';

/**
 * 防抖装饰器： 每次操作后会等待一个固定时间再执行操作, 默认第一次立即执行, 最后一次也会执行
 * @returns
 */
export function Debounce(
  wait: number = 1000,
  options: DebounceSettings = {
    leading: true,
    trailing: true,
  }
) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const method = debounce(descriptor.value, wait, options);

    descriptor.value = function (...args: any[]) {
      method.apply(this, args);
    };
  };
}
