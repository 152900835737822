import { Component } from '@angular/core';

@Component({
  template: `
    <div class="mb-4 !flex items-center md:mb-5" mat-dialog-title>
      <h1 class="text-lg font-semibold text-primary-500 md:text-xl flex-1">Tip</h1>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <ng-container mat-dialog-content>
      <p class="mb-5 text-base text-primary-700">
        The configuration of this process has been changed, please refresh and try again.
      </p>
    </ng-container>

    <footer mat-dialog-actions class="flex items-center justify-between md:justify-end">
      <button
        mat-stroked-button
        mat-dialog-close
        color="primary"
        class="mat-button-lg w-[48%] !px-0 md:w-auto md:!px-14"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="mat-button-lg w-[48%] !px-0 md:w-auto md:!px-14"
        [mat-dialog-close]="true"
      >
        Confirm
      </button>
    </footer>
  `,
})
export class GatewayChangedDialogComponent {}
