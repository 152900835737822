import { NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  GatewayContract,
  MandatoryDocFile,
  MandatoryDocument,
  Opportunity,
  Profile,
  ShareClass,
  StreamFormlyPanelConfig,
  StreamPanelType
} from '@stream/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { STREAM_FORMLY_CONFIG } from './formly.config';
import { FormlyService } from './formly.service';

export enum StreamFormlyEventTypeEnum {
  ProfileAdd = 'profileAdd',
  ProfileEdit = 'profileEdit',
  ProfileDelete = 'profileDelete'
}

@Component({
  selector: 'stream-formly',
  template: `
    <form [formGroup]="form" (ngSubmit)="submit()">
      <stream-panel
        *ngFor="let panel of (panelConfigs | async) ?? panels"
        [fields]="panel.forms"
        [form]="form"
        [panelConfig]="panel"
        [model]="model"
      >
      </stream-panel>
      <button
        *ngIf="submitButton"
        mat-flat-button
        [ngStyle]="submitButton.styles ?? {}"
        color="primary"
        type="submit"
        class="submit-button"
        [disabled]="loading"
      >
        {{ submitButton.label }}
      </button>
    </form>
  `,
  styleUrls: ['./stream-formly.component.scss'],
  providers: [
    FormlyService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    }
  ]
})
export class StreamFormlyComponent {
  constructor(
    public formlyService: FormlyService,
    private cdr: ChangeDetectorRef,
    @Inject(STREAM_FORMLY_CONFIG) private config: { editMode: boolean }
  ) {}

  _form!: FormGroup;

  _panels?: StreamFormlyPanelConfig[];

  panelConfigs = new BehaviorSubject<StreamFormlyPanelConfig[] | undefined>(undefined);

  validFormKeys?: string[];

  @Input()
  set loading(value: boolean) {
    this.formlyService.loading$.next(value);
  }

  get loading() {
    return this.formlyService.loading$.value;
  }

  @Input()
  model: Record<string, unknown> = {};

  @Input()
  set form(value: FormGroup) {
    this._form = value;
    this.listenFormValueChange();
  }
  get form() {
    return this._form;
  }

  @Input()
  set panels(value: StreamFormlyPanelConfig[] | undefined) {
    this._panels = value;
    this.listenFormValueChange();
  }
  get panels() {
    return this._panels;
  }

  @Input()
  set profileId(v: string | undefined) {
    this.formlyService.profileId = v;
  }

  @Input()
  submitButton?: { label: string; styles: NgStyle['ngStyle'] };

  @Input()
  set shareClassObservable(v: Observable<ShareClass[]>) {
    this.formlyService.shareClass$.next(v);
  }

  @Input()
  set profileObservable(v: Observable<Profile[]>) {
    this.formlyService.profileList.next(v);
  }

  @Input()
  set opportunity$(v: Observable<Opportunity | null> | null) {
    this.formlyService.opportunity$.next(v);
  }

  @Input()
  set getContractsFn(v: (profileId: Profile['id']) => Observable<GatewayContract[]>) {
    this.formlyService.contractsFn$.next(v);
  }

  @Input()
  set mandatoryDocsObservable(v: Observable<MandatoryDocument[]>) {
    this.formlyService.mandatoryDocs$.next(v);
  }

  @Input()
  set uploadMandatoryDoc(v: (file: File) => Observable<MandatoryDocFile>) {
    this.formlyService.uploadMandatoryFn$.next(v);
  }

  @Output()
  formSubmit = new EventEmitter<Record<string, unknown>>();

  @Output()
  formEvent = new EventEmitter<{
    type: StreamFormlyEventTypeEnum;
    params?: any;
  }>();

  @Output()
  radioChange = new EventEmitter<any>();

  @Output()
  validKeyChange = new EventEmitter<string[] | undefined>();

  ngOnInit() {
    if (this.panels?.length) {
      this.panelConfigs.next(this.panels?.filter(panel => !this.hideDynamicPanel(panel)));
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  listenFormValueChange() {
    this.form?.valueChanges?.subscribe(() => {
      this.handlePanelsChange();
      this.cdr.detectChanges();
    });
  }

  submit() {
    Object.keys(this.form.controls).forEach(key => {
      if (!this.validFormKeys?.includes(key)) {
        this.form.removeControl(key);
      }
    });

    this.form.markAllAsTouched();

    if (this.form.invalid) return;

    this.formSubmit.emit(this.form.getRawValue());
  }

  /**
   * Actively monitor form change state, dynamically determine field hidden logic
   */
  handlePanelsChange(): any {
    const getRealPanels = (panels: StreamFormlyPanelConfig[] | undefined): any => {
      const _panels = panels?.filter(panel => !this.hideDynamicPanel(panel));

      const validFormKeys = this.getValidFormKeys(_panels);

      // Invalid form values ​​will be set to undefined to avoid failure to submit
      const validFormValues = Object.fromEntries(
        Object.entries(this.form.getRawValue()).map(([key, value]) => {
          if (!validFormKeys?.includes(key)) {
            value = undefined;
          }

          return [key, value];
        })
      );

      // According to the new value, the hidden logic is processed again
      const panelConfigs = _panels?.filter(panel => !this.hideDynamicPanel(panel, validFormValues));

      // Recursively handle nested form situations
      if (Number(_panels?.length) > Number(panelConfigs?.length)) {
        return getRealPanels(panelConfigs);
      }

      return panelConfigs;
    };

    if (this.panels?.length) {
      const panels = getRealPanels(this.panels);

      this.validFormKeys = this.getValidFormKeys(panels);
      this.validKeyChange.emit(this.validFormKeys);

      this.panelConfigs.next(panels);
    }
  }

  getValidFormKeys(panels?: StreamFormlyPanelConfig[]) {
    return [...new Set(panels?.map(panel => panel.forms.map(form => form.key)).flat())];
  }

  /**
   * It is used to be compatible with the logic of old versions.
   * TODO: The original hideExpression does not take effect for some reason. We need to continue to investigate in the future.
   * @param panelConfig
   * @returns
   */
  hideDynamicPanel(panelConfig: StreamFormlyPanelConfig, validFormValues?: Record<string, any>) {
    if (this.config.editMode) {
      return false;
    }

    if (panelConfig.panelType === StreamPanelType.Static) {
      return false;
    }

    const formValues = validFormValues ?? this.form.getRawValue();

    const getConditionResult = (
      displayCondition: StreamFormlyPanelConfig['panelDisplayCondition'] = {}
    ) => {
      if (displayCondition['and']) {
        return displayCondition.and.reduce(
          (acc, { formKey, formValue, type, value }) =>
            acc && (type === 'BACKEND_FILTER_RESULT' ? value : formValues[formKey] === formValue),
          true
        );
      } else if (displayCondition['or']) {
        return displayCondition.or.reduce(
          (acc, { formKey, formValue, type, value }) =>
            (type === 'BACKEND_FILTER_RESULT' ? value : formValues[formKey] === formValue) || acc,
          false
        );
      }
      return true;
    };

    const condition = panelConfig.panelDisplayCondition;
    const result = getConditionResult(condition);

    return panelConfig?.panelDisplayConditionIsShow ? !result : result;
  }
}
