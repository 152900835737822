import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { TableCellType } from '@stream/models';

@Component({
  selector: 'stream-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends FieldArrayType implements OnInit {
  TableCellType = TableCellType;

  ngOnInit(): void {
    this.initControls();
  }

  initControls() {
    this.to?.['tableOptions'].rows.forEach(() => {
      this.add(undefined, null);
    });
  }
}
