<div class="name" #memberNames>
  <span *ngFor="let item of members">
    {{ item.userName }}
  </span>
</div>
<div
  class="more"
  *ngIf="hasMore"
  (click)="handleMoreClick()"
  nz-popover
  [nzPopoverContent]="moreMembersList"
  nzPopoverOverlayClassName="chat-tooltip"
  [nzPopoverOverlayStyle]="{ maxWidth: memberNames.clientWidth + 32 + 'px' }"
  nzPopoverPlacement="bottomRight"
>
  <span></span>
  <span></span>
  <span></span>
</div>
<ng-template #moreMembersList>
  <div class="text-sm text-[#07133A]">
    <div class="truncate py-[7px]" *ngFor="let item of members">
      {{ item.userName }}({{ item.notifyEmail }})
    </div>
  </div>
</ng-template>
<div class="add" *ngIf="!loading && !(disabled | async)">
  <svg
    class="inline-block h-6 w-6 cursor-pointer fill-current text-[#ADB5BD]"
    (click)="edit()"
  >
    <use xlink:href="#icon-tianjia"></use>
  </svg>
</div>
