import { ErrorHandler, Injectable } from '@angular/core';
import { faro } from '@grafana/faro-web-sdk';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private sentryErrorHandler = Sentry.createErrorHandler({
    showDialog: false
  });

  handleError(error: any) {
    if (error instanceof Error) {
      this.sentryErrorHandler.handleError(error);
      faro.api.pushError(error);
    }

    console.error(error);
  }
}
