export enum LpAuthApi {
  Register = '/invest/account/register',
  ActiveAccount = '/invest/account/add-invest-account-by-crm',
  VerifyActiveAccountTempToken = '/invest/account/verify-crm-temp-token',
  Login = '/invest/account/login-by-password',
  RefreshToken = '/invest/account/get-new-tokens',
  VerifyForgetPasswordTempToken = '/invest/account/verify-forget-password-email',
  Session = '/invest/account/session',
  QueryRegistrationConfig = '/v2/tenants/:tenantId/registration'
}

export enum MFAApi {
  Factors = '/invest/security/factors',
  Factor = '/invest/security/factors/:type',
  SendCode = '/invest/security/factors/:type/code',
  SetDefaultFactor = '/invest/security/factors/:type/default',
  GetFactorQrCode = '/invest/security/factors/:type/qrcode',
  Ticket = '/invest/security/factors/ticket',
  Validate = '/invest/security/factors/:type/validate'
}

export enum PrincipalApi {
  queryPrincipalTokens = '/invest/principal/query-token',
  queryPrincipalProfile = '/user-center/query-principal-profile',
  queryPrincipalSignLogs = '/sign-logs/query',
  UpdatePrincipal = '/user-center/principal-update',
  queryPrincipalMembers = '/invest/account/get-invest-account-by-principal-id',
  BatchMembers = '/user-center/batch-invest-member-email',
  UpdateMember = '/user-center/update-members',
  DeleteMember = '/user-center/delete-members',
  EmailInvitation = '/invest/principal/invest-email-invitation'
}

export enum MarketApi {
  QueryMarketingList = '/marketing/query',
  GetMarketingDetail = '/marketing/get',
  GetTabs = '/marketing/tab'
}

export enum SendEmailApi {
  VerifyRegister = '/invest/account/send-email-verify',
  ForgetPassword = '/invest/account/send-forget-password-email'
}

export enum ProductSubscriptionApi {
  GetSubscriptionInvestment = '/product-subscription/investment',
  GetUMPSubscriptionInvestment = '/um/product-subscription/investment',
  GetSubscriptionProduct = '/product-subscription',
  GetUMPSubscriptionProduct = '/um/product-subscription',
  GetSubscriptionInvestmentDetail = '/product-subscription/investment-detail',
  GetSubscriptionTransactionPage = '/product-subscription/transaction-page',
  GetSubscriptionInvestmentAnalysis = '/product-subscription/investment-analysis',
  GetSubscriptionShareClassNAVReports = '/product-subscription/share-class-nav-report',
  getSubscriptionNav = '/product-subscription/nav',
  getCurrencyRate = '/currencies/rate',
  GetProductsReports = '/v2/products/:productId/reports',
  GetProductsReportFolders = '/v2/products/:productId/report-folders',
  GetReport = '/v2/products/:productId/reports/:reportId',
  GetRedemptions = '/subscription/:subscriptionId/redemptions',
  redemptionRequest = '/subscription/:subscriptionId/redemption-request',
  GetProductNavReportsByPOST = '/product-subscription/product-nav-report'
}

export enum WMApi {
  wmChannelGP = '/ump/channel/gp',
  replyEmail = '/ump/channel/reply-email'
}
