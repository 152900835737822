import { Directive, ElementRef, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { AnchorManagementDirective } from './anchor-management.directive';

@Directive({
  selector: '[streamAnchor]',
})
export class AnchorDirective implements OnInit, OnDestroy {
  constructor(
    public elementRef: ElementRef,
    @Optional()
    private management?: AnchorManagementDirective
  ) {}

  @Input()
  streamAnchor = '';

  get name() {
    return this.streamAnchor;
  }

  @Input()
  control: AbstractControl | null = null;

  get checked() {
    return this.control?.valid ?? true;
  }

  ngOnInit(): void {
    this.management?.anchorSet.add(this);
  }
  ngOnDestroy(): void {
    this.management?.anchorSet.delete(this);
  }
}
