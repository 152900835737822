import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormlyModule } from '@ngx-formly/core';
import { ProfileSharedModule } from '@stream/libs/common/profiles/profile-shared.module';
import { StreamFormlyType } from '@stream/models';
import { NgxMatErrorsModule } from 'ngx-mat-errors';

import { ProfileRadioComponent } from './profile-radio/profile-radio.component';

const matModules = [
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatDialogModule,
  MatTooltipModule
];

@NgModule({
  declarations: [ProfileRadioComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMatErrorsModule,
    ProfileSharedModule,
    ...matModules,
    FormlyModule.forChild({
      types: [{ name: StreamFormlyType.PROFILE, component: ProfileRadioComponent }]
    })
  ],
  exports: []
})
export class ProfileFormModule {}
