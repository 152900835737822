import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UploadService } from '@stream/libs/common/models/src/services/upload.service';
import { ToastService } from '@stream/libs/common/ngx-utils';
import { MandatoryDocFile } from '@stream/models';
import { FilePreviewerComponent } from '@stream/ngx-utils';
import { getIconByType } from '@stream/utils';
import { Subject, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

@Component({
  selector: 'stream-mandatory-doc-upload',
  templateUrl: './mandatory-doc-upload.component.html',
  styleUrls: ['./mandatory-doc-upload.component.scss']
})
export class MandatoryDocUploadComponent implements ControlValueAccessor, OnDestroy {
  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    private toast: ToastService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private uploadService: UploadService
  ) {
    if (this.ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  private _required = false;
  private _disabled = false;
  focused = false;
  touched = false;
  stateChanges = new Subject<void>();
  loading = false;
  getIconByType = getIconByType;
  uuid?: string;
  uploadSubscription?: Subscription;

  @ViewChild('input')
  uploadInputRef!: ElementRef;

  @Input()
  fileListRef!: TemplateRef<any>;

  @Input()
  errorMsgRef!: TemplateRef<any>;

  @Input()
  fileName?: string | null;

  @Input()
  placeholder = 'Allowed file formats pdf, jpeg, png / max 30mb';

  @Input()
  multiple = false;

  @Input()
  trigger?: TemplateRef<any> | null;

  @Input()
  accept?: string;

  @Input()
  maxSize = 30 * 1024 * 1024;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  public onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  public onDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  onDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer?.files;
    if (files) {
      this.beforeUpload(files[0]);
    }
  }

  get showUploadButton() {
    if ((this.ngControl.value || []).length > 0) {
      return false;
    }
    return true;
  }

  onChange?: (v: MandatoryDocFile[]) => void;

  onTouched?: () => void;

  ngOnDestroy(): void {
    this.uploadSubscription?.unsubscribe();
    this.uploadService.removeScan(this.uuid ?? '');
    this.stateChanges.complete();
  }

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.stateChanges.next();
  }

  onInput(e: Event) {
    const target = e.target as HTMLInputElement;
    const fileList = target.files;
    if (fileList) {
      Array.from(fileList).forEach(file => {
        this.beforeUpload(file);
      });
    }
    target.value = '';
  }

  isFileValid(file: File) {
    if (this.accept) {
      if (!this.accept.split(',').includes(file.type)) {
        this.toast.common.warning({
          msg: 'Upload Blocked: File format is not valid.'
        });
        return false;
      }
    }
    if (file.size > this.maxSize) {
      this.toast.common.warning({
        msg: `Upload Blocked: Max size for file is ${this.maxSize / 1024 / 1024} MB. `
      });
      return false;
    }
    return true;
  }

  beforeUpload(file: File) {
    const fileValid = this.isFileValid(file);
    if (!fileValid) {
      return;
    }
    if (this.fileName) {
      const blob = file.slice(0, file.size, file.type);
      file = new File([blob], this.fileName + '.' + file.name.split('.').pop(), {
        type: file.type
      });
    }
    this.upload(file);
  }

  upload(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    this.loading = true;
    this.disabled = true;
    this.uuid = this.uploadService.startScan();

    this.uploadSubscription = this.uploadService
      .uploadFile(formData, this.uuid)
      .pipe(
        switchMap(({ blobName }) => {
          return this.uploadService.checkUploadResult({
            blobName,
            uuid: this.uuid!
          });
        }),
        finalize(() => {
          this.loading = false;
          this.disabled = false;
        })
      )
      .subscribe({
        next: data => {
          if (data?.scanResult === 'NO_THREATS_FOUND') {
            const { scanResult, ...value } = data;
            if (this.multiple) {
              this.ngControl.control?.patchValue([...(this.ngControl.value || []), value]);
            } else {
              this.ngControl.control?.patchValue([value]);
            }
          }
        },
        error: () => {
          this.loading = false;
          this.disabled = false;
        }
      });
  }

  deleteFile(index: number) {
    const newValue = ((this.ngControl.value as Array<MandatoryDocFile>) || []).filter(
      (_f, i) => index !== i
    );
    this.ngControl.control?.patchValue(newValue);
  }

  preview(doc: MandatoryDocFile) {
    if (!doc) {
      return;
    }

    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.dialog.open(FilePreviewerComponent, {
      data: {
        type: doc.type,
        url: doc.url,
        downloadUrl: doc.downloadUrl,
        name: doc.name
      },
      maxWidth: isMedium ? '960px' : '90vw',
      maxHeight: '100vh',
      width: '100%'
    });
  }
}
