import { Component, Inject, OnDestroy, ViewContainerRef } from '@angular/core';
import {
  CustodianAccountType,
  LabelCustodianAccountType,
} from '@stream/libs/common/models/src/interfaces';
import { IFileType } from '@stream/libs/common/profiles/services';
import { catchError, finalize, map, of, shareReplay, switchMap, take } from 'rxjs';
import { SCENARIO_CONFIG, ScenarioConfig } from '../../scenario.type';
import { ScenarioPanelService } from '../../services';
import { ScenarioBaseComponent, ScenarioComponent } from '../scenario/scenario.component';

type Custodian = {
  documents: {
    title: string;
    files: { fileId: string; name: string; type: 'PDF' | 'TXT'; isMustView: boolean }[];
  }[];
  info: {
    accountNo: string;
    accountType: string;
    name: string;
  };
};

@Component({
  selector: 'stream-custodian',
  templateUrl: './custodian.component.html',
  styleUrls: ['./custodian.component.scss'],
})
export class CustodianComponent extends ScenarioBaseComponent implements OnDestroy {
  constructor(
    @Inject(SCENARIO_CONFIG) public config: ScenarioConfig,
    public panelService: ScenarioPanelService,
    private scenarioComponent: ScenarioComponent,
    vcr: ViewContainerRef,
  ) {
    super(scenarioComponent, vcr);

    this.loading = true;
  }

  loading = false;

  allChecked = false;

  files?: IFileType[];

  custodian = this.panelService.nodeData.pipe(
    take(1),
    shareReplay(1),
    finalize(() => {
      this.loading = false;
    }),
    switchMap((node: any) => {
      return (this.scenarioComponent.userPayload.isTeamMember$ ?? of(true)).pipe(
        map(isTeamMember => {
          const { source } = this.config;
          const files =
            node.mtDocuments.map((doc: any) => {
              return {
                ...doc,
                isMustView:
                  !this.panelService.lateSign.getValue() &&
                  !this.panelService.isModifyInvest &&
                  !(source === 'GP' || isTeamMember) &&
                  doc.mustView,
                type: doc.name.split('.').pop().toUpperCase(),
                isFolded: true,
              };
            }) ?? [];
          const result = {
            documents: [
              {
                files,
              },
            ],
            info: node.mtInfo,
          };

          this.files = files;
          this.allChecked = (result.documents[0].files ?? []).every(
            (file: any) => !file.isMustView,
          );

          return result;
        }),
      );
    }),
    catchError(() => {
      this.loading = false;

      return of(null);
    }),
  );

  formatAccountType(type: CustodianAccountType) {
    return LabelCustodianAccountType[type] || type;
  }

  handleCheckListChange(value: boolean) {
    this.allChecked = value;
  }

  submit() {
    if (!this.allChecked) {
      return;
    }

    const files = (this.files ?? []).map((file: any) => {
      const checked = file.isMustView;

      const _file = {
        ...file,
        checked,
      };

      delete _file.isMustView;

      return _file;
    });
    const params = {
      result: {
        component_investment_custodian: files,
      },
    };

    this.panelService.submitNodeResult(params).subscribe();
  }

  discard() {
    this.panelService.discard.next(null);
  }
}
