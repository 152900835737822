import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MobileNormalHeaderComponent } from './mobile-normal-header.component';

const matModules = [MatIconModule, MatButtonModule];

@NgModule({
  declarations: [MobileNormalHeaderComponent],
  imports: [CommonModule, ...matModules],
  exports: [MobileNormalHeaderComponent],
})
export class MobileNormalHeaderModule {}
