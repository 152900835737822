import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Address,
  DataRoomDocument,
  Entity,
  EntityMember,
  Individual,
  ProfileDocumentConfigEnum,
  ProfileType,
  Store,
  policyType
} from '@stream/models';
import { SharedService } from '@stream/shared';
import { objectFilterNull } from '@stream/utils';
import { of, zip } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { MandatoryDocItemComponent, MandatoryDocService } from '../../../mandatory-doc';

import { ProfileSettingService } from '../../services';
import { ProfileService } from '../../services/profile.service';
import { profileFormOption } from '../../utils';
import { UploadRes } from '../upload-private-file/upload-private-file.component';

@Component({
  selector: 'stream-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent implements OnInit {
  @ViewChild('mandatoryDocItem')
  mandatoryDocItemRef?: MandatoryDocItemComponent;

  countryList$ = this.sharedService.countryList.pipe(
    map(res =>
      (res || []).map(item => ({
        ...item,
        label: item.countryName,
        value: item.countryAlpha2Code
      }))
    )
  );

  loading = false;
  store = new Store({ type: 'session', namespace: 'scenario' });
  document?: UploadRes;

  form = this.fb.group({});

  mdConfig: any = {
    mandatoryDocs: [
      {
        documentName: 'Proof of Address',
        documentDescription: '',
        isMandatory: true,
        mandatoryDocumentKey: '',
        multiple: false,
        files: this.data.address?.referenceProofAddressFileEntity?.documents
          ? [
              {
                tag: this.data.address?.tag,
                country: this.data.address?.country,
                ...(this.data.address?.referenceProofAddressFileEntity?.documents ?? {})
              }
            ]
          : [],
        tags: [],
        fileName: this.fileName
      }
    ],
    profileId: this.data.profileId,
    loadInitValue: true,
    checkAllFiles: true,
    groupName: 'Address documents',
    errorMessage: '',
    allowReuse: true,
    allowDelete: true
  };
  mdForm = this.fb.group({
    tag: [null, []],
    country: [],
    file: []
  });

  proofOfAddressConfig: ProfileDocumentConfigEnum = ProfileDocumentConfigEnum.hidden;

  constructor(
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    private profileService: ProfileService,
    private dialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      investorProfileType?: ProfileType;
      referenceInvestorProfileMetaEntityId?: Entity['id'];
      address?: Address & {
        referenceProofAddressFileEntity?: { documents: DataRoomDocument };
      };
      individual?: Individual;
      ownerName?: string;
      reModify?: boolean;
      ownership?: boolean;
      profileId: string;
      isMember?: boolean;
    },
    private mandatoryDocService: MandatoryDocService,
    private profileSettingService: ProfileSettingService
  ) {
    this.initAddressFrom();
    this.form.patchValue(
      Object.assign(
        { addressCountryCode: data.individual?.countryCodeOfResidence },
        data.address ?? {}
      )
    );
    if (this.isEntity) {
      this.form.removeControl('referenceProofAddressFileToken');
    }
  }

  get isEntity() {
    return this.data.investorProfileType === ProfileType.Entity;
  }

  get hideAddress() {
    return this.proofOfAddressConfig === ProfileDocumentConfigEnum.hidden;
  }

  get isMemberRole() {
    return !!this.data.isMember;
  }

  ngOnInit() {
    this.init();
  }

  // init address form config
  initAddressFrom() {
    const formGroup: { [key: string]: any } = {};
    const addressOptions = profileFormOption.address;
    if (addressOptions) {
      for (const option of addressOptions) {
        formGroup[option.key] = [
          option.default ?? null,
          // TODO: optimize code
          option.max && option.require
            ? [Validators.required, Validators.maxLength(option.max)]
            : option.require
              ? Validators.required
              : option.max
                ? Validators.maxLength(option.max)
                : null
        ];
      }
    }
    this.form = this.fb.group(formGroup);
  }

  init() {
    zip(
      this._getTooltipInfo(),
      this.profileSettingService.tenantProfileSettings$.pipe(take(1))
    ).subscribe(([tooltip, configs]) => {
      this.mdConfig.mandatoryDocs.forEach((item: any) => {
        item.documentDescription = tooltip;
      });
      this.mdForm
        .get('tag')
        ?.addValidators(this.mandatoryDocService.tagRequireValidator(this.form));
      const { investorProfileType } = this.data;
      const settingKey =
        investorProfileType === ProfileType.Entity
          ? 'DOCUMENT_ENTITY_PROFILE'
          : this.isMemberRole
            ? 'DOCUMENT_ENTITY_MEMBER'
            : 'DOCUMENT_INDIVIDUAL_PROFILE';
      const configValue = configs?.[settingKey]?.settingValue ?? {};

      this.mdConfig.checkAllFiles =
        configValue.proofOfAddress === ProfileDocumentConfigEnum.mandatory;
      if (this.data.reModify) {
        this.mdConfig.allowDelete = false;
      }
      this.proofOfAddressConfig = configValue.proofOfAddress;
      if (this.proofOfAddressConfig === ProfileDocumentConfigEnum.mandatory) {
        this.mdForm.get('file')?.addValidators(this.mandatoryDocService.fileRequiredValidator());
        this.mdConfig.errorMessage = 'This field is required.';
      }
    });
  }

  _getTooltipInfo() {
    return this.profileService
      .getToolTipInfo(policyType.PROOF_OF_ADDRESS)
      .pipe(map(({ data }) => data?.gpPolicies?.[0]?.policyContext));
  }

  submit() {
    if (this.isEntity || this.hideAddress) {
      this._saveAddressWithoutMandatoryDoc();
    } else {
      this._saveAddress();
    }
  }

  _saveAddressWithoutMandatoryDoc() {
    if (this.form.invalid) return;
    const profile = this.store.get('profile');
    this.loading = true;
    const { address, ownership, ...data } = this.data;
    const tagAndCountry = {
      tag: this.data.address?.tag,
      country: this.data.address?.country
    };
    of(tagAndCountry)
      .pipe(
        switchMap(tagAndCountry => {
          const value = objectFilterNull({
            ...this.form.value,
            ...tagAndCountry,
            referenceProofAddressFileToken:
              this.data.address?.referenceProofAddressFileEntity?.documents?.documentToken
          }) as any;

          if (profile && data.reModify) {
            const {
              investorProfileMetaInfoAddress,
              investorProfileMetaInfoMemberList: memberList
            } = profile;
            const fileCtr = this.mdForm.get('file');
            if (fileCtr?.value && fileCtr?.value.length > 0) {
              this.document = {
                ...fileCtr?.value[0],
                url: fileCtr?.value[0]?.downloadUrl
              };
            }
            if (memberList && ownership) {
              profile.investorProfileMetaInfoMemberList = memberList.map((member: EntityMember) => {
                const { referenceInfoAddressEntity: entityAddress } = member;
                const { documents = {} } = entityAddress.referenceProofAddressFileEntity ?? {};

                if (entityAddress?.id === address?.id) {
                  if (!value.country) {
                    delete entityAddress.country;
                  }

                  if (!value.tag) {
                    delete entityAddress.tag;
                  }
                }

                member.referenceInfoAddressEntity =
                  entityAddress?.id === address?.id
                    ? {
                        ...entityAddress,
                        ...value,
                        ...{
                          referenceProofAddressFileEntity: {
                            documents: this.document
                              ? { ...documents, ...this.document }
                              : documents
                          }
                        }
                      }
                    : entityAddress;

                return member;
              });
            } else {
              if (this.document) {
                const { referenceProofAddressFileEntity } = investorProfileMetaInfoAddress;
                referenceProofAddressFileEntity.documents = {
                  ...referenceProofAddressFileEntity.documents,
                  ...this.document
                };
              }

              if (!value.country) {
                delete investorProfileMetaInfoAddress.country;
              }

              if (!value.tag) {
                delete investorProfileMetaInfoAddress.tag;
              }

              profile.investorProfileMetaInfoAddress = {
                ...investorProfileMetaInfoAddress,
                ...value
              };
            }
            this.store.set('profile', profile);
            this.loading = false;
            return of({});
          }

          return this.profileService
            .patchAddress(value, {
              id: address?.id,
              metaIndividualId: data.individual?.id,
              ...data
            })
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            );
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  _saveAddress() {
    if (!this.hideAddress && !this.isEntity) {
      Object.values(this.mdForm.controls).forEach(control => {
        control.updateValueAndValidity({ emitEvent: false });
        setTimeout(() => {
          control.markAsDirty();
          control.markAsTouched();
        });
      });
      if (!this.mdForm.valid || this.form.invalid) {
        return;
      }
    } else if (this.form.invalid) {
      return;
    }
    const profile = this.store.get('profile');
    this.loading = true;
    const { address, ownership, ...data } = this.data;
    this.mandatoryDocItemRef?.tagList$
      .pipe(
        take(1),
        switchMap(tagList => {
          if (!this.mandatoryDocItemRef) {
            return of({ tagList, countryList: [] });
          }
          return this.mandatoryDocItemRef.countryList$.pipe(
            map(countryList => ({ tagList, countryList }))
          );
        }),
        switchMap(({ tagList, countryList }) => {
          const tag = tagList.find((t: any) => t.label === this.mdForm.get('tag')?.value);
          const country = countryList.find(
            (c: any) => c.value === this.mdForm.get('country')?.value
          );
          const documentToken = this.mdForm.get('file')?.value?.[0]?.documentToken;
          const tagAndCountry = {
            tag: tag
              ? {
                  id: tag.value,
                  name: tag.label
                }
              : null,
            country: country
              ? {
                  id: country.value,
                  name: country.label
                }
              : null
          };
          const value = objectFilterNull({
            ...this.form.value,
            ...tagAndCountry,
            referenceProofAddressFileToken: documentToken
          }) as any;
          if (profile && data.reModify) {
            const {
              investorProfileMetaInfoAddress,
              investorProfileMetaInfoMemberList: memberList
            } = profile;
            const fileCtr = this.mdForm.get('file');
            if (fileCtr?.value && fileCtr?.value.length > 0) {
              this.document = {
                ...fileCtr?.value[0],
                url: fileCtr?.value[0]?.downloadUrl
              };
            }
            if (memberList && ownership) {
              profile.investorProfileMetaInfoMemberList = memberList.map((member: EntityMember) => {
                const { referenceInfoAddressEntity: entityAddress } = member;
                const { documents = {} } = entityAddress.referenceProofAddressFileEntity ?? {};

                if (entityAddress?.id === address?.id) {
                  if (!value.country) {
                    delete entityAddress.country;
                  }

                  if (!value.tag) {
                    delete entityAddress.tag;
                  }
                }

                member.referenceInfoAddressEntity =
                  entityAddress?.id === address?.id
                    ? {
                        ...entityAddress,
                        ...value,
                        ...{
                          referenceProofAddressFileEntity: {
                            documents: this.document
                              ? { ...documents, ...this.document }
                              : documents
                          }
                        }
                      }
                    : entityAddress;

                return member;
              });
            } else {
              if (this.document) {
                const { referenceProofAddressFileEntity } = investorProfileMetaInfoAddress;
                referenceProofAddressFileEntity.documents = {
                  ...referenceProofAddressFileEntity.documents,
                  ...this.document
                };
              }

              if (!value.country) {
                delete investorProfileMetaInfoAddress.country;
              }

              if (!value.tag) {
                delete investorProfileMetaInfoAddress.tag;
              }

              profile.investorProfileMetaInfoAddress = {
                ...investorProfileMetaInfoAddress,
                ...value
              };
            }
            this.store.set('profile', profile);
            this.loading = false;
            return of({});
          }

          return this.profileService
            .patchAddress(value, {
              id: address?.id,
              metaIndividualId: data.individual?.id,
              ...data
            })
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            );
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  get contentH(): string {
    return `calc(${window?.innerHeight - 161}px - 20vh)`;
  }

  get fileName(): string {
    if (this.data.ownerName) {
      return 'Proof of address - ' + this.data.ownerName;
    }
    return 'Proof of address';
  }
}
