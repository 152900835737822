import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '@stream/shared';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  constructor(private sharedService: SharedService) {}

  transform(countryCode?: string) {
    return this.sharedService.countryList.pipe(
      map(
        countries =>
          countries?.find(country => country.countryAlpha2Code === countryCode)
            ?.countryName,
      ),
    );
  }
}
