<form [formGroup]="form" (ngSubmit)="savePerson()">
  <div class="!flex items-center" mat-dialog-title>
    <h1 class="text-xl font-semibold flex-1">
      {{ data.person ? 'Edit' : 'Add' }} person information
    </h1>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    mat-dialog-content
    class="person-form-content"
    [style]="{ 'max-height': contentH }"
  >
    <div class="space-x-0 md:flex md:space-x-2">
      <mat-form-field class="w-full flex-1 md:w-auto">
        <mat-label>First name</mat-label>
        <input autocomplete="given-name" matInput formControlName="firstName" />
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <mat-form-field class="w-full flex-1 md:w-auto">
        <mat-label>Last name</mat-label>
        <input autocomplete="family-name" matInput formControlName="lastName" />
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input autocomplete="email" matInput formControlName="email" />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
    <div class="flex">
      <mat-form-field class="custom-small-form-width">
        <mat-label>Code</mat-label>
        <mat-select formControlName="phoneNationalityCode" #phoneCodeSelect>
          <mat-select-trigger>
            {{ getCodeByLabel($any(phoneCodeSelect.selected)?.viewValue) }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let areaCode of areaCodeList"
            [value]="areaCode.countryCode"
          >
            {{ areaCode.name + ' +' + areaCode.dialCode }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-1">
        <mat-label>Phone</mat-label>
        <input
          matInput
          autocomplete="tel-national"
          type="tel"
          formControlName="phoneNumber"
        />
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-x-2">
      <mat-form-field>
        <mat-label>Nationality</mat-label>
        <ngx-select-country
          formControlName="nationalityCode"
          [required]="true"
          [options]="countryList$"
        ></ngx-select-country>
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Country of residence</mat-label>
        <ngx-select-country
          formControlName="countryCodeOfResidence"
          [required]="true"
          [options]="countryList$"
        ></ngx-select-country>
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Date of birth (mm/dd/yyyy)</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="birthday"
          autocomplete="bday"
          [max]="todayDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Town of birth</mat-label>
        <input matInput formControlName="townOfBirth" />
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Country of birth</mat-label>
        <ngx-select-country
          formControlName="countryCodeOfBirth"
          [required]="true"
          [options]="countryList$"
        ></ngx-select-country>
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Position/Title</mat-label>
        <input matInput formControlName="jobPositionTitle" />
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
    </div>
    <div>
      <h3 class="mb-3 flex justify-between items-center text-base">
        <div
          class="flex items-center"
          *ngIf="tooltipTaxJurisdiction"
          nz-popover
          nzPopoverOverlayClassName="max-w-xl"
          nzPopoverPlacement="bottom"
          [nzPopoverOrigin]="icon._elementRef"
          [nzPopoverContent]="info"
        >
          <span>Tax jurisdiction</span> <span class="text-red-500">*</span>
          <mat-icon color="primary" class="ml-2 !h-auto !text-xl" #icon
            >info_outlined</mat-icon
          >
        </div>
        <div class="flex items-center" *ngIf="!tooltipTaxJurisdiction">
          <span>Tax jurisdiction</span> <span class="text-red-500">*</span>
        </div>
        <button
          mat-icon-button
          type="button"
          class="ml-auto"
          (click)="taxes.push(createTaxFormArray())"
        >
          <mat-icon>add</mat-icon>
        </button>
      </h3>

      <ng-template #info>
        <stream-quill-view
          [content]="tooltipTaxJurisdiction"
          [pureViewer]="true"
        ></stream-quill-view>
      </ng-template>
      <ul formArrayName="taxJurisdiction">
        <li
          *ngFor="let tax of taxes.controls; let i = index"
          [formGroupName]="i"
          class="tax-item"
        >
          <div class="form">
            <div class="form-row">
              <mat-form-field class="full-width">
                <mat-label>Jurisdiction of tax residency</mat-label>
                <input matInput formControlName="0" />
                <mat-error ngx-mat-errors></mat-error>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Tax reference number type(Optional)</mat-label>
                <input matInput formControlName="1" />
                <mat-error ngx-mat-errors></mat-error>
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field class="full-width !w-full">
                <mat-label>Tax reference number(e.g TIN)</mat-label>
                <input matInput formControlName="2" />
                <mat-error ngx-mat-errors></mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="operate flex justify-end">
            <button
              mat-icon-button
              [disabled]="taxes.controls.length === 1"
              type="button"
              (click)="taxes.removeAt(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div mat-dialog-actions class="grid grid-cols-2 justify-around md:flex md:justify-end">
    <button
      mat-stroked-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      cdkFocusInitial
      type="submit"
      [disabled]="loading"
    >
      Confirm
    </button>
  </div>
</form>
