/** 投资人端配置信息 */
export interface LpConfiguration {
  // 基础信息
  /** 租户🆔 */
  tenantId: string;
  /** 公司名 */
  companyName: string;
  /** 品牌图标地址 */
  marqueUrl: string;
  /** 平台favicon地址 */
  platformIconUrl: string;
  /** 主题色图标 */
  primaryLogoUrl: string;
  /** 主题色反转图标 */
  primaryLogoReverseUrl: string;
  /** 平台描述 */
  platformDescription: string;
  /** 主题色 */
  primaryColor: string;

  // 登录相关
  /** 是否开启自主注册 */
  isAutonomous: boolean;
  /** 开启谷歌账号登录 */
  isGoogleLogin: boolean;
  /** 开启微信登录 */
  isWechatLogin: boolean;
  /** 允许邮箱登录 */
  isMailLogin: boolean;
  /** 允许邮箱注册 */
  isMailRegistration: boolean;
  /** 允许手机号登录 */
  isMobileLogin: boolean;
  /** 允许手机号注册 */
  isMobileRegistration: boolean;

  // 密码设置相关
  /** 密码最少位数 */
  passwordLengthMinimum: number;
  /** 需要小写字母 */
  isLowercaseLetter: boolean;
  /** 需要大写字母 */
  isUppercaseLetter: boolean;
  /** 需要特殊字符 */
  isSpecialCharacter: boolean;
  /** 需要数字 */
  isNumber: boolean;

  // 其他
  /** 隐私政策 */
  privacyPolicy: string;
  /** 支持的语言 */
  supportedLanguages: string;
  /** 使用条款 */
  termsOfUse: string;

  fullDomainValue: string;
  prefixValue: string;
  /** 欢迎页图片 */
  splashPageUrl: string;
  /** banner */
  marketBannerUrl: string;
  baseCurrency: string;

  splashPagePictureBrightness: number;

  /** multi-language */
  isMultiLingual: boolean;

  salesSupportEmail: string;

  type: string;
}

export interface LpSupportLanguage {
  code: string;
  /** 使用条款 */
  createdAt: string;
  tenantId: string;
  prefixValue: string;
  id: string;
  title: string;
  updatedAt: string;
}

export interface LpSupportCurrency {
  countryAlpha2Country: string;
  cryptocurrency: boolean;
  currencyCode: string;
  currencyRemark: string;
  currencySymbol: string;
  dataCreateTime: string;
  dataIsDeleted: boolean;
  dataStatus: string;
  dataUpdateTime: string;
  enableSaas: boolean;
  id: string;
}

export interface GPMetaRegistration {
  isEnableMarketingSignup: boolean;
}
