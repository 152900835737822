<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="!flex !items-center !justify-between" mat-dialog-title>
    <div class="text-[22px] font-semibold leading-[26px]">New conversation</div>
    <button mat-icon-button mat-dialog-close class="!h-6 !w-6 !p-0">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="conversation-form-content">
    <label class="required">Subject</label>
    <mat-form-field>
      <input
        autocomplete="off"
        matInput
        formControlName="subject"
        placeholder="Please Input Subject"
      />
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>

    <label class="required"> Related asset</label>
    <mat-form-field>
      <mat-select
        formControlName="assetId"
        autocomplete="off"
        placeholder="Please select an asset"
        panelClass="chat-select-panel"
      >
        <mat-option *ngFor="let item of assetWithCategory" [value]="item.assetId">{{
          item.assetName
        }}</mat-option>
      </mat-select>
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>

    <label class="required">Category</label>
    <mat-form-field>
      <mat-select
        formControlName="categoryId"
        autocomplete="off"
        placeholder="Please select a conversation category"
        panelClass="chat-select-panel"
      >
        <mat-option *ngFor="let item of categoryList" [value]="item.id">{{
          item.title
        }}</mat-option>
      </mat-select>
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>

    <label
      *streamPermission="''; baseOn: 'FULL_GP, UNREGULATED_MARKETPLACE'"
      [class.required]="subscriptionRequired"
      >Subscription</label
    >
    <mat-form-field *streamPermission="''; baseOn: 'FULL_GP, UNREGULATED_MARKETPLACE'">
      <mat-select
        formControlName="subscriptionId"
        autocomplete="off"
        placeholder="Please select a subscription"
        panelClass="chat-select-panel"
      >
        <mat-select-trigger *ngIf="selectedSubscription">
          <stream-chat-fund-info [subscription]="selectedSubscription"></stream-chat-fund-info>
        </mat-select-trigger>
        <mat-option *ngFor="let subscription of subscriptionList" [value]="subscription.id">
          <stream-chat-fund-info [subscription]="subscription"></stream-chat-fund-info>
        </mat-option>
      </mat-select>
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>

    <ng-container>
      <label>LP members (Optional)</label>
      <mat-form-field>
        <mat-select
          multiple
          formControlName="recipientIds"
          panelClass="chat-select-panel multiple-members"
          autocomplete="off"
          placeholder="Please select LP members"
        >
          <mat-select-trigger>
            {{ getMembersTriggerText() }}
          </mat-select-trigger>
          <mat-option *ngFor="let item of recipientList" [value]="item.recipientId"
            >{{ item.userName }}({{ item.notifyEmail }})</mat-option
          >
        </mat-select>
        <svg
          matPrefix
          class="mx-auto inline-block h-6 w-6 cursor-pointer fill-current text-[#ADB5BD]"
        >
          <use xlink:href="#icon-youjian"></use>
        </svg>
        <mat-error ngx-mat-errors></mat-error>
      </mat-form-field>
    </ng-container>
    <label class="required">Message</label>
    <mat-form-field class="message">
      <stream-message-input
        formControlName="message"
        type="normal"
        placeholder="Please input"
        [apiUpload]="(fileUploadApi | async)!"
        [isShowBtnSend]="false"
        [isHiddenBorder]="true"
        [customStyleWrap]="{
          padding: '0 2px'
        }"
      >
      </stream-message-input>
      <mat-error ngx-mat-errors></mat-error>
    </mat-form-field>
  </div>
  <div
    mat-dialog-actions
    class="!m-0 flex !min-h-[48px] justify-between gap-x-2.5 !p-4 md:justify-end md:gap-x-5"
  >
    <button
      mat-stroked-button
      color="primary"
      class="!h-12 flex-1 font-semibold tracking-normal md:w-[168px] md:flex-none"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="!h-12 flex-1 font-semibold tracking-normal md:w-[168px] md:flex-none"
      type="submit"
    >
      Confirm
    </button>
  </div>
</form>
