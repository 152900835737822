export const DeactivateInfoContent =
  'The member will no longer be able to sign in to the workspace.';

export const IfaDeactivateContent = 'Are you sure you want to deactivate this IFA?';

export const IfaDeactivateConfirmContent =
  'Confirming the deactivate of this IFA will result in his/her investors becoming independent. Please assign a sales representative to maintain their related business.';

export const DeactivateContent = 'Are you sure you want to deactivate this account?';

export const DeactivateConfirmContent =
  'Confirming the deactivate of this account will result in his/her investors becoming independent. Please assign a sales representative to maintain their related business.';

export const IfaAdditionalContent =
  'The number of IFAs you have added has been reached to the limit. Please contact your account manager or sales representative to upgrade your plan. Or you can contact us at';

export const SupportEmail = 'support@biteinvestments.com';
