import { Portal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { ContactFile, Store } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { fromEntries } from '@stream/utils';
import _ from 'lodash';
import diff from 'microdiff';
import { FormDataType, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { finalize, take } from 'rxjs/operators';

import { ErrorMessages } from '../../constant';
import { ScenarioPanelService, ScenarioService, SignService } from '../../services';
import { ScenarioBaseComponent, ScenarioComponent } from '../scenario/scenario.component';

export type CustomFormDataType = {
  [fieldName: string]: string | number | boolean | string[] | null;
};

@Component({
  selector: 'stream-sign-pdf',
  templateUrl: './sign-pdf.component.html',
  styleUrls: ['./sign-pdf.component.scss'],
})
export class SignPdfComponent
  extends ScenarioBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    private signService: SignService,
    private panelService: ScenarioPanelService,
    private scenarioComponent: ScenarioComponent,
    private scenarioService: ScenarioService,
    private notification: NotificationService,
    vcr: ViewContainerRef,
  ) {
    super(scenarioComponent, vcr);

    pdfDefaultOptions.cMapUrl = () => `/assets/cmaps/`;
  }

  @Input()
  file: ContactFile | null = null;

  @Input()
  projectId?: string;

  @Input()
  profileId?: string;

  httpHeaders = this.scenarioComponent.httpHeaders;

  signFooterPortal?: Portal<any>;

  @Output()
  back = new EventEmitter();

  @Output()
  pdfLoading = new EventEmitter();

  private initialFormData: CustomFormDataType = {};

  formData: FormDataType = {};

  fileUrl?: string;

  loading = false;

  zoom = 100;

  currentPage = 1;

  store = new Store({ type: 'session', namespace: 'scenario' });

  // text input with blur event array
  inputBlurEvents: string[] = [];

  // text input value object
  inputDynamicValue: { [key: string]: string } = {};

  ngOnInit() {
    this.getFileBolbUrl();

    if (this.file) {
      const { formData, fileName } = this.file;

      (this.panelService.isModifyInvest
        ? this.panelService.getModifyContractTags
        : this.panelService.nodeData
      )
        .pipe(take(1))
        .subscribe(({ signDocumentsKeyValue, customerJsonKeyValue = {} }) => {
          if (!this.panelService.isModifyInvest && formData) {
            this.initialFormData = formData;
          } else {
            this.initialFormData = {
              ...(formData ?? {}),
              ...signDocumentsKeyValue,
              ...customerJsonKeyValue[
                Object.keys(customerJsonKeyValue).find(key => fileName.startsWith(key)) ??
                  ''
              ],
              ...customerJsonKeyValue['contractArray']?.find(
                (contractInfo: any) => contractInfo.contractName === fileName,
              )?.signPlaceholders,
            };
          }

          _.forEach(
            _.keys(this.initialFormData),
            key =>
              (this.initialFormData[key] =
                this.initialFormData[key] === '' ? null : this.initialFormData[key]),
          );

          this.formData = Object.assign({}, this.initialFormData) as FormDataType;
          // replace all formData's key contains /, so replace to empty
          Object.entries(this.formData).forEach(([key, value]) => {
            if (key.includes('/')) {
              delete this.formData[key];
              // this.formData[key.replace(/\//g, '')] = value;
            }
          });
        });
    }
  }

  override ngAfterViewInit(): void {
    this.signFooterPortal = this.scenarioComponent.footerPortal;

    document
      .querySelector('.pdfViewer')
      ?.addEventListener('click', this.handleInput.bind(this));

    super.ngAfterViewInit();
  }

  override ngOnDestroy(): void {
    this.scenarioComponent.footerPortal = this.signFooterPortal;

    document
      .querySelector('.pdfViewer')
      ?.removeEventListener('click', this.handleInput.bind(this));
  }

  handleInput(event: Event) {
    if (event.target) {
      const target = event.target as HTMLElement & { type: string; value: any };
      const checked = target.getAttribute('checked');
      const name = target.getAttribute('name');

      if(!name) {
        return;
      }

      // input
      if (target.type === 'text') {
        if(!this.inputBlurEvents.includes(name)) {
          target.addEventListener('blur', this.handleTextInput.bind(this));
          this.inputBlurEvents.push(name);
        }
       
        return;
      }

      // checkbox
      if (!checked) {
        target.setAttribute('checked', 'checked');
        target.setAttribute('value', 'Yes');
        return;
      }

      target.setAttribute('value', 'false');
      target.removeAttribute('checked');
    }
  }

  handleTextInput(event: Event) {
    const target = event.target as HTMLElement & { type: string; value: any };
    const name = target.getAttribute('name');

    if(!name) {
      return;
    }

    target.setAttribute('value', target.value);
    this.inputDynamicValue[name] = target.value;
  } 

  getFileBolbUrl() {
    this.loading = true;
    this.pdfLoading.emit(true);
    this.signService
      .downloadFile(this.file?.id ?? '')
      .pipe(
        finalize(() => {
          this.loading = false;
          this.pdfLoading.emit(false);
        }),
      )
      .subscribe(
        blob => (this.fileUrl = URL.createObjectURL(blob)),
        () => {
          this.fileUrl = '';
          this.notification.error(ErrorMessages.general);
        },
      );
  }

  onPagesLoaded() {
    setTimeout(() => {
      const checkboxs = window.document.querySelectorAll(
        '.pdfViewer input[type=checkbox][exportvalue]',
      );
      checkboxs.forEach(checkbox => {
        const name = checkbox.getAttribute('name');

        if (name && Object.keys(this.initialFormData).includes(name)) {
          const initialData = this.initialFormData[name];
          const exportValue = checkbox.getAttribute('exportvalue');

          // legacy form checkbox value
          if (
            initialData === true ||
            initialData === exportValue ||
            (initialData && initialData !== 'Off' && initialData !== 'false')
          ) {
            checkbox.setAttribute('checked', 'checked');
            return;
          }

          checkbox.removeAttribute('checked');
        }
      });
    }, 600);
  }

  syncCustomFields(formData: FormDataType) {
    const fields = (<any[]>diff(this.initialFormData, formData))
      .filter(({ path: [key], value }) => {
        return typeof value === 'string' || (value === null && this.initialFormData[key]);
      })
      .map(({ path: [key], value }) =>
        value === null
          ? {
              customFieldKey: key,
            }
          : { customFieldKey: key, customFieldKeyDataValue: value },
      );

    if (fields.length) {
      if (this.panelService.isModifyInvest) {
        this.panelService
          .submitNodeResult({
            businessData: {
              customFields: fields,
              contractDataToBeUpdated: [
                { fileId: (this.file as ContactFile).id, formData },
              ],
            },
            next: false,
          })
          .subscribe(() => this.panelService.loading.next(false));
      } else {
        this.panelService.currentNodePath.pipe(take(1)).subscribe(({ gatewayId }) => {
          this.scenarioService
            .syncCustomField(gatewayId, fields, this.profileId)
            .subscribe({
              error: err => {
                if (err.error.errorCustomFieldKeys) {
                  this.notification.error(
                    `Failed to update ${err.error.errorCustomFieldKeys.join(',')}.`,
                  );
                }
              },
            });
        });
      }
    }
  }

  formatCheckbox() {
    const checkboxs = window.document.querySelectorAll(
      '.pdfViewer input[type=checkbox][exportvalue]',
    );

    checkboxs.forEach(checkbox => {
      const name = checkbox.getAttribute('name');

      if (name) {
        const initialData = this.formData[name];
        const value = checkbox.getAttribute('value');

        // Processed data have a value attribute
        if (value) {
          this.formData[name] = value === 'Yes' ? 'Yes' : null;
          return;
        }

        // Process default data without any operations
        this.formData[name] =
          checkbox.getAttribute('checked') ||
          (initialData && initialData !== 'Off' && initialData !== 'false')
            ? 'Yes'
            : null;
      }
    });
  }

  submit() {
    this.formatCheckbox();

    const formData = fromEntries(
      Object.entries({...this.formData, ...this.inputDynamicValue}).map(([key, value]) => {
        if (!value) {
          return [key, null];
        }

        return [key, value];
      }),
    );

    Object.entries(formData).forEach(([key, _]) => {
      if (key.includes('/')) {
        delete formData[key];
      }
    });

    this.syncCustomFields(formData);

    // Do not update the contract document in modified status.
    if (!this.panelService.isModifyInvest) {
      this.signService.updateFile((this.file as ContactFile).id, formData).subscribe(
        () => {
          Object.assign(this.file ?? {}, { formData });
          this.back.emit();
        },
        err => {
          if (err.error.status === 'projectRevoked') {
            this.panelService.leave.next('projectRevoked');
          }
        },
      );
    } else {
      this.back.emit();
    }
  }
}
