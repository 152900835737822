import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataRoomDocument, DataRoomDocumentType, Store } from '@stream/models';
import { FilePreviewerComponent } from '@stream/ngx-utils';
import { SubscriptionService } from '@stream/service/subscription.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'stream-data-room-document',
  templateUrl: './data-room-document.component.html',
  styleUrls: ['./data-room-document.component.scss'],
})
export class DataRoomDocumentComponent {
  @Input()
  document?: {
    url: DataRoomDocument['url'];
    name: DataRoomDocument['name'];
    type: DataRoomDocumentType;
    id?: string;
  } | null;
  @Input()
  proofType?: string | null;
  isMedium = this.breakpointObserver
    .observe('(min-width: 768px)')
    .pipe(map(({ matches }) => matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private subscription: SubscriptionService,
    private dialog: MatDialog,
  ) {}

  store = new Store({ type: 'session', namespace: 'scenario' });

  getIconByType(type: DataRoomDocumentType = DataRoomDocumentType.Pdf) {
    return {
      [DataRoomDocumentType.Pdf]: '#icon-PDF',
      [DataRoomDocumentType.Image]: '#icon-a-zhaopian11',
      [DataRoomDocumentType.Excel]: '#icon-a-Property1Variant19',
      [DataRoomDocumentType.Folder]: '#icon-wenjianguanbi',
    }[type];
  }

  openFile($event: any, url?: string) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    const snapshot = this.store.get('snapshot');

    if(url && snapshot && snapshot.urlMap) {
      const _url = snapshot.urlMap[url];
      if(_url) {
        this.preview(_url);
        return;
      }

      this.preview(url);
      return;
    }

    if (this.document?.id) {
      $event.stopPropagation();
      this.subscription.queryFile(this.document.id).subscribe(res => {
        if (res.data.documents?.url) {
          this.preview(res.data.documents?.url);
        }
      });
      return;
    }

    if (url && isMedium) {
      this.preview(url);
    }
  }

  get fileName() {
    let result = this.document?.name || '';
    const lastIndex = result.lastIndexOf(".");
    if (lastIndex >= 0) {
      result = result.substring(0, lastIndex);
    }
    return result;
  }

  get fileExt() {
    let result = this.document?.name || '';
    const lastIndex = result.lastIndexOf(".");
    if (lastIndex >= 0) {
      result = result.substring(lastIndex + 1);
    } else {
      result = '';
    }
    return result;
  }

  preview(url: string) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.dialog.open(FilePreviewerComponent, {
      data: {
        type: this.document?.type,
        url: url,
        downloadUrl: url,
        name: this.document?.name,
      },
      maxWidth: isMedium ? '960px' : '90vw',
      maxHeight: '100vh',
      width: '100%',
    });
  }
}
