import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ProfileService } from '@stream/libs/common';
import {
  ACCOUNT_ERROR_MESSAGE,
  AuthStoreKeyEnum,
  BatchInvestEnum,
  ChangePasswordStatusEnum,
  EditEmailEnum,
  HttpStatusEnum,
  InvestAccount,
  InvestAccountRoleEnum,
  INVITATION_ERROR_MESSAGE,
  KycDetail,
  KycStatusEnum,
  LpAccountApi,
  LpSupportCurrency,
  Page,
  PageRequest,
  Principal,
  PrincipalIdentityEnum,
  PrincipalMember,
  PrincipalTypeEnum,
  Restful,
  TaskApi,
  Tasks
} from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { PrincipalApi } from '@stream/src/common';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, interval, Subject, throwError } from 'rxjs';
import { catchError, map, pluck, repeatWhen, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private dateAdapter: DateAdapter<Date>,
    private ns: NotificationService,
    private profileService: ProfileService,
    private localStorage: LocalStorageService
  ) {}

  //TODO: legal submodules libs, should be optimized
  accountInfo$ = new BehaviorSubject<any>({});
  accountUpdated = new Subject<void | null>();
  principalTimer = new BehaviorSubject<[{ id: string; count: number }] | null>(null);

  DEFAULT_DELAY_SECOND = 60;
  delayMap = new Map<string, number>();

  subjectMap = new Map<string, Subject<number>>();

  // ! big pit
  principal$ = new BehaviorSubject<Principal[]>([]);

  principal = this.principal$.asObservable().pipe(map(([principal]) => principal));

  userInfo = this.getAccount().pipe(
    pluck('data'),
    repeatWhen(() => this.accountUpdated),
    shareReplay(1)
  );

  account = this.userInfo.pipe(pluck('investAccount'));

  wmCandidate = this.userInfo.pipe(map(({ investPrincipals }) => investPrincipals[0].wmCandidate));

  isTeamMemberKycNotPass = this.getAccountRequest().pipe(
    pluck('data'),
    map(({ investAccount, investPrincipals: [principal] }) => {
      const principalType = principal.principalType || principal.type;
      if (principalType !== PrincipalTypeEnum.Individual) return false;
      if (investAccount.role === InvestAccountRoleEnum.OWNER) return false;
      if (principal.kycStatus === KycStatusEnum.Success) return false;
      return true;
    })
  );

  private getAccount() {
    return this.http
      .post<
        Restful<{
          investAccount: InvestAccount;
          investPrincipals: Principal[];
          status: HttpStatusEnum;
        }>
      >(LpAccountApi.GetAccount, {})
      .pipe(
        tap(
          ({
            data: {
              investPrincipals,
              investAccount: { timeZoneText = 'en-US' }
            }
          }) => {
            this.principal$.next(investPrincipals);
            this.dateAdapter.setLocale(timeZoneText);
            this.localStorage.store(
              AuthStoreKeyEnum.PrincipalToken,
              investPrincipals[0]['principalToken']
            );
          }
        )
      );
  }

  getAccountRequest() {
    return this.http.post<
      Restful<{
        investAccount: InvestAccount;
        investPrincipals: Principal[];
        status: HttpStatusEnum;
      }>
    >(LpAccountApi.GetAccount, {});
  }

  //获取principal profile
  getPrincipal() {
    // remove accountId param, fix for https://bite.atlassian.net/browse/STREAM-12534
    return this.http
      .post<Restful<{ userCenterProfileVOS: [Principal]; status: HttpStatusEnum }>>(
        PrincipalApi.queryPrincipalProfile,
        {}
      )
      .pipe(
        tap(({ data }) => {
          const { userCenterProfileVOS } = data;

          if (userCenterProfileVOS.length) {
            userCenterProfileVOS.map((item: Principal) => {
              item.principalIdentityEnumForTag = item.principalIdentity;
              if (
                item.principalType === PrincipalTypeEnum.Individual &&
                ['ADMIN', 'MEMBER'].includes(item.accountRole)
              ) {
                item.principalIdentityEnumForTag = PrincipalIdentityEnum.TEAM_MEMBER;
              }
              return item;
            });
            this.principal$.next(userCenterProfileVOS);
            this.localStorage.store(
              AuthStoreKeyEnum.PrincipalToken,
              userCenterProfileVOS[0]['principalToken']
            );
          }
        })
      );
  }

  updatePrincipal(queryParams: {
    firstName?: string;
    lastName?: string;
    principalId: string;
    telephone?: string;
    telephoneCurrencyCode?: string;
    email?: string;
  }) {
    return this.http
      .post<Restful<{ signLogs: [KycDetail]; status: HttpStatusEnum }>>(
        PrincipalApi.UpdatePrincipal,
        { ...queryParams }
      )
      .pipe(
        catchError(err => {
          if (err.error?.status !== HttpStatusEnum.Success) {
            this.ns.error(err.error?.message ?? 'Failed');
          }
          return throwError(err);
        })
      );
  }

  //获取kyc详情
  getPrincipalKycInfo(queryParams: { principalId: string; signType: string }) {
    return this.http.post<Restful<{ signLogs: [KycDetail]; status: HttpStatusEnum }>>(
      PrincipalApi.queryPrincipalSignLogs,
      { ...queryParams }
    );
  }

  //获取支持货币
  getCurrenciesInfo() {
    return this.http.get<Restful<{ metaCurrencies: [LpSupportCurrency]; status: HttpStatusEnum }>>(
      LpAccountApi.QueryCurrency,
      {}
    );
  }

  updateAccount(queryParams: {
    id?: any;
    currentPassword?: string;
    password?: string;
    timeZoneText?: string;
    timeZone?: number;
    language?: string;
    currencyCode?: string;
  }) {
    return this.http
      .put<Restful<{ status: ChangePasswordStatusEnum }>>(LpAccountApi.UpdateAccount, {
        ...queryParams
      })
      .pipe(
        catchError(err => {
          const errStr = ACCOUNT_ERROR_MESSAGE.get(err.error.status);
          if (errStr && errStr !== '' && err.error?.status !== ChangePasswordStatusEnum.Success) {
            this.ns.error(errStr);
          }
          return throwError(err);
        }),
        tap(() => {
          this.accountUpdated.next(null);
          this.profileService.accountUpdated.next(null);
        })
      );
  }

  getMembers(queryParams: { page: PageRequest; principalId: string }) {
    return this.http.post<Restful<{ page: Page<PrincipalMember>; status: HttpStatusEnum }>>(
      PrincipalApi.queryPrincipalMembers,
      { ...queryParams }
    );
  }
  batchMembers(queryParams: {
    emailInstanceTypeEnum: string;
    investorPrincipalId: string;
    linkUrl: string;
    batchInvestMemberEmailVOs: any[];
  }) {
    return this.http.post<Restful<{ hadInvestedEmail: string[]; status: BatchInvestEnum }>>(
      PrincipalApi.BatchMembers,
      { ...queryParams }
    );
  }
  updateMember(queryParams: {
    firstName?: string;
    lastName?: string;
    principalId: string;
    telephone?: string;
    telephoneCode?: string;
    email?: string;
    role?: string;
    position?: string;
    id: string;
    dataType: string;
  }) {
    return this.http.put<Restful<{ status: EditEmailEnum }>>(PrincipalApi.UpdateMember, {
      ...queryParams
    });
  }

  deleteMember(queryParams: { id: string; principalId: string; dataType: string }) {
    return this.http.delete<Restful<{ status: HttpStatusEnum }>>(PrincipalApi.DeleteMember, {
      params: queryParams
    });
  }

  InvestEmailInvitation(queryParams: {
    email: string;
    emailInstanceTypeEnum: string;
    firstName: string;
    investEmailInvitationEnum: string;
    investorPrincipalId: string;
    lastName: string;
    linkUrl: string;
    principalId: string;
    principalType: string;
  }) {
    return this.http
      .post<Restful<{ status: EditEmailEnum }>>(PrincipalApi.EmailInvitation, {
        ...queryParams
      })
      .pipe(
        catchError(err => {
          if (err.error?.status !== EditEmailEnum.Success) {
            this.ns.error(INVITATION_ERROR_MESSAGE.get(err.error.status) || '');
          }
          return throwError(err);
        })
      );
  }

  SendEmail(storeKey: string, delay = this.DEFAULT_DELAY_SECOND) {
    this.delayMap.set(storeKey, delay);

    const iii = interval(1000).subscribe(data => {
      this.delayMap.set(storeKey, 59 - data);
      if (data >= 59) {
        this.delayMap.set(storeKey, 0);
        iii.unsubscribe();
      }
    });
  }

  EmailDelay(storeKey: string) {
    return this.delayMap.get(storeKey) ?? 0;
  }

  logout(noReturnUrl?: boolean) {
    return this.http.post<null>(LpAccountApi.Logout, {}).pipe(
      tap(() => {
        this.localStorage.clear(AuthStoreKeyEnum.AccessToken);
        this.localStorage.clear(AuthStoreKeyEnum.RefreshToken);
        this.localStorage.clear(AuthStoreKeyEnum.PrincipalToken);
        if (!noReturnUrl) {
          window.location.reload();
        }
      })
    );
  }

  GetCurrencyPolicy() {
    return this.http.get<Restful<{ data: any; status: string }>>(LpAccountApi.CurrencyPolicy, {});
  }

  getTasksCount(queryParams: { subscriptionId: string; taskType: string; taskStatus: string }) {
    return this.http.post<Restful<{ tasks: Tasks[]; status: string }>>(TaskApi.queryTasks, {
      ...queryParams
    });
  }

  sendRemindTasks(params: { subscriptionId: string; subscriptionStepStatus: string }) {
    return this.http.post<Restful<{ status: string }>>(TaskApi.remindTasks, {
      ...params
    });
  }

  changeEmailPreferences(unsubscribe: string[]) {
    return this.http
      .put<
        Restful<{
          status: string;
        }>
      >(LpAccountApi.EmailPreferences, {
        unsubscribe
      })
      .pipe(
        tap(() => {
          this.accountUpdated.next();
        })
      );
  }
}
