import { FormGroup } from '@angular/forms';
import { MandatoryDocument } from '@stream/models';

export interface MandatoryDocOptions {
  mandatoryDocs: Array<MandatoryDocument>;
  allowReuse?: boolean;
  profileId?: string;
  loadInitValue?: boolean; // 回显
  groupName?: string;
  title?: string;
  checkAllFiles?: boolean; // 校验全部File
  errorMessage?: string;
  allowDelete?: boolean;
}

export interface MandatoryDocTag {
  countryAlpha2Codes: string[];
  id: string;
  name: string;
}

export interface MandatoryDocTagOption extends MandatoryDocTag {
  label: string;
  value: string;
}

export interface MandatoryDocItem extends MandatoryDocument {
  form: FormGroup;
  tags: Array<MandatoryDocTagOption>;
  index: number;
  errorMessage?: string;
  fileName?: string;
}
