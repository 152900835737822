<div class="mb-4 !flex items-center md:mb-5" mat-dialog-title>
  <h1 class="flex-1 text-lg font-semibold text-primary-500 md:text-xl">My signatures</h1>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-radio-group
  mat-dialog-content
  class="stream-seal-radio"
  [(ngModel)]="selectedSealId"
>
  <mat-radio-button
    *ngFor="let seal of sealList | async"
    [value]="seal.id"
    class="w-full"
  >
    <div class="flex flex-col items-center space-x-4 sm:flex-row">
      <img
        [src]="seal.thumbUrl"
        [ngStyle]="{
          width: seal.width + 'px',
          height: seal.height + 'px'
        }"
        class="mb-2 sm:mb-0"
        alt="thumb"
      />
      <span class="text-base font-semibold text-gray-700">{{ data.fullName }}</span>
    </div>
  </mat-radio-button>
</mat-radio-group>

<footer mat-dialog-actions class="stream-seal-footer">
  <button
    mat-stroked-button
    mat-dialog-close
    class="mat-button-lg stream-seal-cancel_button"
    color="primary"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    class="mat-button-lg stream-seal-confirm_button"
    (click)="adopt()"
    color="primary"
  >
    Adopt
  </button>
</footer>
