<div
  *ngIf="visible | async"
  class="flex cursor-default items-center text-base font-semibold text-[#1A1821]"
  (click)="open()"
>
  <div class="chat-icon w-6 md:w-8">
    <svg viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.6667 4H3V24H17L23.6667 27.3333V24H29.6667V4Z"
        fill="#62656C"
        stroke="#62656C"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 14.333C9 14.333 11.6667 16.9997 16.3333 16.9997C21 16.9997 23.6667 14.333 23.6667 14.333"
        fill="#62656C"
      />
      <path
        d="M9 14.333C9 14.333 11.6667 16.9997 16.3333 16.9997C21 16.9997 23.6667 14.333 23.6667 14.333"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g filter="url(#filter0_f_34893_29797)">
        <ellipse cx="19.5" cy="31" rx="10.5" ry="1" fill="#D9D9D9" />
      </g>
      <defs>
        <filter
          id="filter0_f_34893_29797"
          x="7"
          y="28"
          width="25"
          height="6"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1"
            result="effect1_foregroundBlur_34893_29797"
          />
        </filter>
      </defs>
    </svg>
    <span class="badge" *ngIf="unReadCount | async">
      {{ unReadCount | async }}
    </span>
  </div>
  <div class="chat-ask hidden cursor-pointer pl-3 md:block">Messages</div>
</div>
