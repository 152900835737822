import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { StreamFormlyType } from '@stream/models';

import { AutocompleteModule } from '../autocomplete/autocomplete/autocomplete.module';
import { AreaCodeSelectComponent } from './area-code-select/area-code-select.component';

const matModules = [MatAutocompleteModule, MatInputModule];

@NgModule({
  declarations: [AreaCodeSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...matModules,
    AutocompleteModule,
    FormlyModule.forChild({
      types: [
        {
          name: StreamFormlyType.AREA_CODE,
          component: AreaCodeSelectComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
})
export class AreaCodeSelectModule {}
