import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import crc32 from 'crc-32';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IGNORE_NODE_NAME, TRANSLATE_CONFIG } from './translate.constants';
import { TranslateConfig } from './translate.interface';
import { TranslateService } from './translate.service';

@Component({
  selector: 'stream-translate',
  template: `<ng-content></ng-content>`
})
export class TranslateComponent implements AfterViewInit, OnDestroy {
  mutationObserver!: MutationObserver;
  mutationAddedNodes: any[] = [];
  mutationTimer!: unknown;
  recordMap = new Map<string, Map<string, string>>();
  originMap = new Map<string, string>();
  originHashMap = new Map<string, string>();
  isEnable = false;
  lang!: string;

  constructor(
    @Inject(TRANSLATE_CONFIG)
    private readonly config: TranslateConfig,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.registerMutationObserver();
  }

  ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
  }

  public setLanguage(lang: string) {
    this.lang = lang;
  }

  public disable() {
    this.isEnable = false;
    if (this.mutationObserver) {
      this.mutationObserver?.disconnect();
    }
  }

  public enable() {
    this.isEnable = true;
    if (!this.mutationObserver) {
      this.registerMutationObserver();
    }
  }

  public translate(params: { lang: string }) {
    if (!this.isEnable) {
      return of();
    }
    const { lang } = params;
    this.setLanguage(lang);
    const nodes = this.getNodes(document);
    this.translateByCache(nodes, lang);
    const noTranslatedNodes = nodes.filter(
      (n: any) => !(Object.values(n.attribute)[0] as any)?.translation[lang]
    );
    const contents = [
      ...new Set<string>(
        noTranslatedNodes.map((n: any) => (Object.values(n.attribute)[0] as any).origin)
      )
    ];
    if (!contents.length) {
      return of();
    }
    this.requestTranslate(contents, lang, this.config.origin).subscribe(data => {
      noTranslatedNodes.forEach((n: any) => {
        Object.keys(n.attribute).forEach(key => {
          n.attribute[key].translation[lang] = data[n.hash];
        });
      });
      this.render(nodes, lang);
    });
    return of();
  }

  mutationTranslate() {
    const lang = this.lang;
    if (!lang) {
      return;
    }
    const nodes = this.mutationAddedNodes.reduce((acc, cur) => {
      return [...acc, ...this.getNodes(cur)];
    }, [] as any);
    this.translateByCache(nodes, lang);
    const noTranslatedNodes = nodes.filter(
      (n: any) => !(Object.values(n.attribute)[0] as any)?.translation[lang]
    );
    const contents = [
      ...new Set<string>(
        noTranslatedNodes.map((n: any) => (Object.values(n.attribute)[0] as any).origin)
      )
    ];
    if (!contents.length) {
      return;
    }
    this.requestTranslate(contents, lang, this.config.origin).subscribe(data => {
      noTranslatedNodes.forEach((n: any) => {
        Object.keys(n.attribute).forEach(key => {
          n.attribute[key].translation[lang] = data[n.hash];
        });
      });
      this.mutationAddedNodes = [];
      this.render(noTranslatedNodes, lang);
    });
  }

  handleMutationAddedNodes() {
    clearTimeout(this.mutationTimer as any);
    this.mutationTimer = setTimeout(() => {
      this.mutationTranslate();
    }, 500);
  }

  translateByCache(nodes: any, lang: string) {
    let list: any = [];
    for (let node of nodes) {
      const { attribute, hash } = node;
      Object.keys(attribute).forEach(key => {
        const cache = this.recordMap.get(lang)?.get(hash);
        if (cache) {
          attribute[key].translation[lang] = cache;
          list.push(node);
          return;
        }
      });
    }
    this.render(list, lang);
  }

  registerMutationObserver() {
    this.mutationObserver = new MutationObserver(mutations => {
      this.mutationCallback(mutations);
    });
    this.mutationObserver.observe(document, {
      childList: true,
      subtree: true
    });
  }

  mutationCallback(mutations: MutationRecord[]) {
    mutations.forEach(mutation => {
      const { addedNodes, type } = mutation;
      if (type !== 'childList') {
        return;
      }
      if (addedNodes.length) {
        this.mutationAddedNodes.push(...Array.from(addedNodes));
        this.handleMutationAddedNodes();
      }
    });
  }

  /**
   * @description check if node is ignored node
   * @param node
   * @returns boolean
   */
  isIgnoredNode(node: Element, config: TranslateConfig) {
    const { ignore } = config;
    const ignoreTags = ignore?.tags ?? [];
    const ignoreClasses = ignore?.classes ?? [];
    const ignoreIds = ignore?.ids ?? [];
    const hasIgnoreClassName = Array.from(ignoreClasses).some(name =>
      node.classList?.contains(name)
    );
    const hasIgnoreTag = ignoreTags.includes(node.tagName?.toUpperCase());
    const hasIgnoreId = ignoreIds.includes(node.id);
    return hasIgnoreClassName || hasIgnoreTag || hasIgnoreId;
  }

  getOriginText(value?: string | null) {
    const recordMapValueList = Array.from(this.recordMap.values()).reduce(
      (acc, cur) => {
        return [...acc, ...Array.from(cur.entries())];
      },
      [] as Array<[string, string]>
    );
    const cachedKey = recordMapValueList.find((item: any) => item[1] === value)?.[0];
    if (!cachedKey) {
      return value;
    }
    return this.originHashMap.get(cachedKey) ?? value;
  }

  getNodes(rootNode: Element | Document) {
    let textNodes: any[] = [];
    const findTextNodes = (node: Element) => {
      const isIgnoredNode = this.isIgnoredNode(node, this.config);
      if (isIgnoredNode) {
        return;
      }
      if (IGNORE_NODE_NAME.includes(node.nodeName.toLowerCase())) {
        return;
      }
      if (node.nodeType === Node.TEXT_NODE) {
        const v = this.getOriginText(node.nodeValue);
        if (v) {
          textNodes.push({
            node,
            hash: crc32.str(v).toString(),
            attribute: {
              nodeValue: {
                origin: v,
                translation: {}
              }
            }
          });
        }
      }
      if (['INPUT', 'TEXTAREA'].includes(node.tagName)) {
        const placeholder = this.getOriginText((node as HTMLInputElement).placeholder);
        if (placeholder) {
          textNodes.push({
            node,
            hash: crc32.str(placeholder).toString(),
            attribute: {
              placeholder: {
                origin: placeholder,
                translation: {}
              }
            }
          });
        }
      }
      if (node.hasChildNodes()) {
        for (let i = 0; i < node.childNodes.length; i++) {
          findTextNodes(node.childNodes[i] as Element);
        }
      }
      if (node.shadowRoot) {
        findTextNodes(node.shadowRoot as unknown as Element);
      }
    };
    findTextNodes(rootNode as Element);
    return textNodes.filter((node: any) => {
      const hasIgnoredParent = this.hasIgnoredParent(node as Element);
      const isEmptyContent = Object.values(node.attribute).every((attr: any) => !attr.origin);
      if (isEmptyContent || hasIgnoredParent || !node.node.isConnected) {
        return false;
      }
      return true;
    });
  }

  /**
   * @description check if node has ignored parent node
   * @param node
   * @returns boolean
   */
  hasIgnoredParent(node: Element) {
    let currentNode: Element | null = node;
    while (currentNode) {
      if (this.isIgnoredNode(currentNode, this.config)) {
        return true;
      }
      currentNode = currentNode.parentElement;
    }
    return false;
  }

  render(nodes: any[], lang: string) {
    for (let n of nodes) {
      const { attribute, node } = n;
      Object.keys(attribute).forEach(key => {
        if (key === 'placeholder') {
          node.setAttribute(key, attribute[key].translation[lang]);
        } else {
          node[key] = attribute[key].translation[lang];
        }
        this.storeTranslateData(
          lang,
          n.hash,
          attribute[key].translation[lang],
          attribute[key].origin
        );
      });
    }
  }

  storeTranslateData(lang: string, hash: string, value: string, origin: string) {
    if (this.recordMap.has(lang)) {
      this.recordMap.get(lang)?.set(hash, value);
    } else {
      this.recordMap.set(lang, new Map([[hash, value]]));
    }
    if (!this.originMap.has(origin)) {
      this.originMap.set(origin, hash);
    }
    if (!this.originHashMap.has(hash)) {
      this.originHashMap.set(hash, origin);
    }
  }

  /**
   * @description request translate data
   * @param text need to translate text
   * @param to which language you want to translate
   * @param from which language those node are
   * @returns Observable<{ text: string[] }>
   */
  requestTranslate(texts: string[], to: string, from: string): Observable<Record<string, string>> {
    if (!texts.length) {
      return of({});
    }
    return this.translateService.translate({ text: texts, to, from }).pipe(
      map(res => (res.data.text ? res.data : { text: texts.map(str => `${to}=${str}`) })),
      map(({ text }) =>
        texts.reduce(
          (acc, t, index) => ({
            ...acc,
            [crc32.str(t).toString()]: text[index]
          }),
          {}
        )
      )
    );
  }
}
