<form class="flex w-full flex-col gap-y-4" [formGroup]="$any(formControl)">
  <div class="flex w-full flex-col gap-y-2" *ngFor="let ctr of formControl.controls || []">
    <div class="flex items-center gap-x-1 text-base font-semibold">
      <sup class="text-red-500" *ngIf="ctr.value.isMandatory">*</sup>
      <label
        class="line-clamp-1"
        [matTooltip]="getDocLabelName(ctr)"
        [matTooltipShowDelay]="1000"
        >{{ getDocLabelName(ctr) }}</label
      >
      <mat-icon
        class="flex-none !text-lg text-[#62656C]"
        #icon
        *ngIf="showTooltip(ctr.value.documentDescription)"
        nz-popover
        nzPopoverOverlayClassName="max-w-xl"
        nzPopoverPlacement="bottom"
        [nzPopoverOrigin]="icon._elementRef"
        [nzPopoverContent]="info"
        >info_outlined</mat-icon
      >
      <ng-template #info>
        <stream-quill-view
          [content]="ctr.value.documentDescription"
          [pureViewer]="true"
        ></stream-quill-view>
      </ng-template>
    </div>
    <form [formGroup]="$any(ctr)">
      <stream-doc-upload
        [multiple]="ctr.value.multiple"
        [fileName]="ctr.value.multiple ? null : getDocLabelName(ctr)"
        [fileListRef]="FILE_LIST_REF"
        [mandatory]="ctr.value"
        [profileId]="formlyService.profileId"
        formControlName="files"
        accept="application/pdf,image/jpeg,image/png"
      ></stream-doc-upload>
      <ng-template #FILE_LIST_REF let-uploadRef="uploadRef">
        <div
          *ngFor="let doc of ctr.value.files || []; let i = index"
          class="rounded border border-dashed border-[#ADB5BD] p-3"
          (click)="uploadRef.preview(doc)"
        >
          <div
            class="flex w-full items-center gap-x-2 rounded bg-[#f8f9fa] p-3 hover:bg-primary-50"
          >
            <svg class="icon flex-none text-lg">
              <use [attr.xlink:href]="uploadRef.getIconByType(doc?.type)"></use>
            </svg>

            <div class="flex max-w-fit cursor-pointer items-center gap-x-1 text-sm text-gray-700">
              <span class="line-clamp-1">
                {{ doc.name || '--' }}
              </span>
              <span class="min-w-fit rounded bg-[#fff9eb] px-2 py-0.5 text-black" *ngIf="doc.tag">
                {{ getDocumentTagLabel(doc) }}
              </span>
            </div>
            <span class="flex-1"></span>
            <button
              mat-icon-button
              class="flex-none"
              type="button"
              *ngIf="!refresh"
              (click)="$event.stopPropagation(); uploadRef.deleteFile(i)"
            >
              <mat-icon class="text-gray-500">delete</mat-icon>
            </button>

            <button
              mat-icon-button
              class="flex-none"
              type="button"
              *ngIf="refresh"
              (click)="$event.stopPropagation(); uploadRef.refreshFile(i)"
            >
              <mat-icon class="text-gray-500">replay</mat-icon>
            </button>
          </div>
        </div>
      </ng-template>
    </form>
  </div>
</form>
