export enum RedemptionType {
  PARTIAL = 'PARTIAL',
  FULL = 'FULL'
}

export enum RedemptionMethod {
  SHARES = 'SHARES',
  AMOUNT = 'AMOUNT'
}
export interface CreateRedemptionPayload {
  redemptionType: RedemptionType;
  redemptionMethod?: RedemptionMethod;
  sharesNumber?: number;
  amount?: number;
}
