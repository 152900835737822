import { Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'stream-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
})
export class ChatWidgetComponent {
  visible = this.chatService.conversationDisabled.pipe(
    map((disabled) => !disabled)
  );

  unReadCount = this.chatService.unReadCount.pipe(
    map((num) => (num > 99 ? '99+' : num))
  );

  constructor(private chatService: ChatService) {}

  open(): void {
    this.chatService.opened = true;
  }
}
