import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Option, ValueSelectorComponent } from '../../condition-builder.model';

@Component({
  selector: 'stream-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.less'],
})
export class AutocompleteComponent
  extends ValueSelectorComponent
  implements AfterViewInit
{
  constructor(private el: ElementRef) {
    super();
  }

  @Input()
  set options(value: Option[] | Observable<Option[]>) {
    if (!value) {
      this.options$ = of([]);
      return;
    }
    if (value instanceof Observable) {
      this.options$ = value;
      return;
    }
    this.options = of(value);
  }

  options$: Observable<Option[]> = of([]);

  ngAfterViewInit() {
    const inputEl = (this.el.nativeElement as HTMLElement)
      .querySelector('nz-select-search')
      ?.querySelector('.ant-select-selection-search-input') as HTMLInputElement;

    inputEl.setAttribute('maxlength', '100');
  }
}
