import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  MAIN_MENU,
  NavMenu,
  PRINCIPAL_IDENTITY_LABEL,
  PrincipalTypeEnum,
  TEAM_MEMBERS_MENU,
  USER_CENTRE_MENU
} from '@stream/models';
import { AuthService } from '@stream/service/auth.service';
import { Subscription } from 'rxjs';
import { filter, map, pluck, shareReplay, switchMap } from 'rxjs/operators';

import { PermissionService } from '@stream/libs/common/utils/service';
import { environment } from '../../../../environments/environment';
import { AccountService } from '../../../services/account.service';
import { ClientService } from '../../../services/client.service';

@Component({
  selector: 'stream-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('200ms ease-in')])
    ])
  ]
})
export class SideNavComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(LOCALE_ID)
    private localeId: string,
    private clientService: ClientService,
    private router: Router,
    private accountService: AccountService,
    private authService: AuthService,
    private permissionService: PermissionService
  ) {}

  accountId: string = '';

  showSecondaryMenu = false;
  mainMenuList: Array<NavMenu> = [];
  secondaryMenuList: Array<NavMenu> = [];
  configuration = this.clientService.configuration;
  account = this.accountService.account;

  principal = this.account.pipe(
    switchMap(accountInfo => {
      return this.accountService.getPrincipal();
    }),
    pluck('data', 'userCenterProfileVOS'),
    shareReplay(1)
  );

  wmCandidate$ = this.accountService.wmCandidate;
  wmCandidate = false;
  principalIdentity = this.principal.pipe(
    map(([principal]) => PRINCIPAL_IDENTITY_LABEL.get(principal?.principalIdentityEnumForTag) ?? '')
  );
  splitEmail = this.account.pipe(map(({ email }) => email.split('@') as [string, string]));
  overlayMenuVisible = false;
  inTrigger = false;
  inOverlay = false;
  routerChangeSub = new Subscription();

  /**
   * INVESTMENT feature flag
   */
  INVESTMENT?: {
    display: boolean;
    status: string;
  };
  tenantType?: string;

  get showMainMenu() {
    return !this.showSecondaryMenu;
  }

  get showNotification() {
    return this.authService.retrieveNotificationRecord(this.accountId);
  }

  ngOnInit(): void {
    this.initNavInfo();
  }

  ngOnDestroy(): void {
    this.routerChangeSub.unsubscribe();
  }

  initNavInfo() {
    this.clientService.configuration.subscribe(config => {
      this.tenantType = config.type;
    });

    this.permissionService.getFeatures({ dataSource: 'REQUEST' }).subscribe((features: any) => {
      this.INVESTMENT = features['INVESTMENT'];
      this.initMainMenuList();

      this.accountService.wmCandidate.subscribe(wmCandidate => {
        this.wmCandidate = wmCandidate;
        this.initSecondaryMenuList(wmCandidate);
      });
    });

    this.getWhichMenuActive();
    this.routerChangeSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.getWhichMenuActive();
      });

    this.accountService.userInfo.subscribe(res => {
      this.accountId = res.investAccount.id;
    });
  }

  getWhichMenuActive() {
    const mainMenuActive = this.mainMenuList.some(item => this.getRouteActive(item));
    const secondaryMenuActive =
      this.secondaryMenuList.some(item => this.getRouteActive(item)) ||
      this.router.url.indexOf('account') > -1;
    this.showSecondaryMenu = secondaryMenuActive && !mainMenuActive;
  }

  getRouteActive(menu: NavMenu) {
    return (menu?.children ?? []).some(item =>
      this.router.isActive(item.path as string, {
        matrixParams: 'ignored',
        queryParams: 'ignored',
        fragment: 'ignored',
        paths: 'subset'
      })
    );
  }

  setMenuIndex(list: Array<NavMenu>, parentIndex?: string): Array<NavMenu> {
    return list.map((item, index) => {
      const itemIndex = parentIndex ? `${parentIndex}-${index}` : `${index}`;
      const result: NavMenu = {
        ...item,
        index: itemIndex,
        parent: parentIndex
      };
      if (item.children) {
        result.children = this.setMenuIndex(item.children, itemIndex);
      }
      return result;
    });
  }

  getSecondaryMenuDeeplyPath(menu: NavMenu): string {
    if (menu.path) {
      return menu.path;
    }
    if (menu.children && menu.children[0]) {
      return this.getSecondaryMenuDeeplyPath(menu.children[0]);
    }
    return '';
  }

  initMainMenuList() {
    this.mainMenuList = this.setMenuIndex(
      MAIN_MENU.filter(
        item =>
          this.tenantType !== 'LITE_GP' ||
          (this.tenantType === 'LITE_GP' && item.label === 'Opportunities')
      )
        .filter(item => !this.wmCandidate || item.label === 'Opportunities')
        .map(item => ({
          ...item,
          isOpen: item.label === 'Opportunities' ? item.isOpen : this.getRouteActive(item)
        }))
    );

    if (this.tenantType === 'UNREGULATED_MARKETPLACE') {
      this.mainMenuList.map(item => {
        if (item.children && item.children.length > 0) {
          item.children = item.children.filter(children => {
            return children.label !== 'Dashboard';
          });
        }

        return item;
      });
    }
  }

  initSecondaryMenuList(wmCandidate: boolean) {
    const accountMenu = USER_CENTRE_MENU[0];
    const teamMembers = TEAM_MEMBERS_MENU[0];

    if (wmCandidate) {
      this.secondaryMenuList = this.setMenuIndex([
        {
          ...accountMenu,
          isOpen: this.getRouteActive(accountMenu)
        }
      ]);
      return;
    }

    this.principal.subscribe(([principalData]) => {
      const principalType = principalData.principalType || principalData.type;

      const profileMenu: NavMenu = {
        label: 'Profile',
        icon: '#icon-profile',
        children: [
          {
            label:
              principalType === PrincipalTypeEnum.Individual
                ? principalData.firstName + ' ' + principalData.lastName
                : principalData.companyName,
            path: 'account/principal-profile/' + principalData.id
          },
          {
            label: 'Investor profile',
            path: 'account/investor-profile'
          }
        ]
      };

      const secondaryMenu: any[] = [
        {
          ...accountMenu,
          isOpen: this.getRouteActive(accountMenu)
        }
      ];

      if (this.tenantType !== 'LITE_GP') {
        if (!this.INVESTMENT?.display) {
          profileMenu.children = profileMenu.children!.filter(
            item => item.label !== 'Investor profile'
          );
        }

        secondaryMenu.push({
          ...profileMenu,
          isOpen: this.getRouteActive(profileMenu)
        });
      }

      if (
        this.tenantType !== 'LITE_GP' ||
        (this.tenantType === 'LITE_GP' && principalType !== PrincipalTypeEnum.Individual)
      ) {
        secondaryMenu.push({
          ...teamMembers,
          isOpen: this.getRouteActive(teamMembers)
        });
      }

      this.secondaryMenuList = this.setMenuIndex(secondaryMenu);
    });
  }

  getRedirectUrl() {
    const href = '/auth/sign-in';
    if (!environment.production) {
      return href;
    }
    if (this.localeId) {
      return `/${this.localeId}${href}`;
    }
    return href;
  }

  signOut() {
    this.accountService.logout(true).subscribe(() => {
      this.locationReplace('/auth/sign-in');
    });
  }

  locationReplace(path: string) {
    window.location.replace(path);
  }

  showOverlayMenu() {
    if (!this.inTrigger) {
      this.initNavInfo();
    }

    this.inTrigger = true;
    this.overlayMenuVisible = true;
  }

  overlayTriggerLeave() {
    this.inTrigger = false;
    setTimeout(() => {
      this.closeOverlayMenu();
    }, 100);
  }

  overlayMenuLeave() {
    this.inOverlay = false;
    setTimeout(() => {
      this.closeOverlayMenu();
    }, 100);
  }

  closeOverlayMenu() {
    if (!this.inOverlay && !this.inTrigger) {
      this.overlayMenuVisible = false;
    }
  }

  onBack() {
    this.showSecondaryMenu = false;
    this.router.navigate(['/product/list']);
  }

  handleMouseEnter() {
    this.inOverlay = true;
  }
}
