import { Observable } from 'rxjs';
import { I18n } from '../common';
import { Gateway, GatewayNode } from './gateway.interface';

export enum ScenarioType {
  OnBoarding = 'ONBOARDING',
  Identity = 'IDENTITY',
  FundData = 'FUND_DATA',
  Investing = 'INVESTING',
  Screening = 'SCREENING',
  Reverse = 'RESERVE',
  Countersign = 'COUNTERSIGN',
  ExpressInterest = 'EXPRESS_INTEREST',
}

export interface Fundamental {
  getStartMessage: I18n;
  getStartButtonTitle: I18n;
  getStartTitle: I18n;
  getStartIcon: string;
  completedMessage: I18n;
  completedTitle: I18n;
  completedButtonTitle: I18n;
  completedIcon: string;
}

export interface Scenario {
  scenarioType: ScenarioType;
  fundData: unknown[];
  fundamental: Fundamental;
  fundId: string;
  tenantId: string;
  id: string;
  scenarioDescription: string;
  scenarioName: string;
}

export interface ContactFile {
  docHeight: number;
  docWidth: number;
  fileName: string;
  id: string;
  groupId: string;
  imageList: { pageNo: number; url: string };
  isSigned: boolean;
  signDateFormat: string;
  signPlaceholderList: SignPlaceholder[];
  url: string;
  formData: Record<string, string>;
  zoom: number;
  fieldList: {
    type: string;
    name: string;
    value: string;
  }[];
}

export interface SignPlaceholder {
  id: string;
  name: string;
  required: boolean;
  signerId?: string;
}

export interface Signer {
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  orderIndex: number;
  email: string;
  signUrl?: string;
  signedAt: number;
  status: 'signing' | 'pending' | 'signed' | 'expired' | 'resign';
  type?: string;
  witnessList?: Array<Signer>;
  witnessFor?: string;
  expiredAt?: number;
}

export interface SignerDTO {
  id: string;
  firstName: string;
  lastName: string;
  type: SignerType;
  fullName: string;
  email: string;
  phoneNumber: {
    areaCode: string;
    number: string;
  };
  occupation?: string;
  address?: string;
}

export interface Group {
  id: string;
  name: 'Investment documents' | 'Custodian documents';
  orderIndex: number;
}

export interface Project {
  id: string;
  signMode: string;
  fileList: ContactFile[];
  fileCount: number;
  finishedAt: number;
  signPlaceholderList: SignPlaceholder[];
  signerList: Signer[];
  subject: string;
  updatedAt: number;
  status: string;
  currentUserNeedSign: boolean;
  ticket: string;
  currentSigner: Signer;
  groupList: Group[];
  signFileList?: ContactFile[];
}

export interface Seal {
  id: string;
  height: number;
  isDefault: boolean;
  name: string;
  thumbUrl: string;
  type: string;
  width: number;
}

export interface GatewayContract {
  gatewayId: Gateway['gatewayId'];
  nodeId: GatewayNode['id'];
  contractId: string;
  contractOpenId: string;
  contractName: string;
  contractOriginName: string;
  contractDescription: string;
}

export enum SignerType {
  lp = 'LP',
  gp = 'GP',
  witness = 'WITNESS',
}

export interface SignDetail {
  createdAt: Date;
  currentSigner: Signer;
  defaultSeal?: Signature;
  expiredAt: Date;
  fileCount: number;
  fileList: Array<SignFile>;
  id: string;
  sender: Sender;
  signMode?: string;
  signerList: Array<Signer>;
  status: ProjectStatus;
  subject: string;
  updatedAt: Date;
}

export interface SignFile {
  fileName: string;
  id: string;
  url: string;
  signed: boolean;
}

export enum ProjectStatus {
  deleted = 'deleted',
  draft = 'draft',
  expired = 'expired',
  free = 'free',
  send = 'sent',
  signed = 'signed',
  signing = 'signing',
  revoked = 'revoked',
}

export interface Sender {
  email: string;
  name: string;
}

export interface Signature {
  createdAt: Date;
  height: number;
  id: string;
  isDefault: boolean;
  name: string;
  thumbUrl: string;
  type: string;
  width: number;
}

export enum SignTaskStatus {
  expired = 'expired',
  pending = 'pending',
  rejected = 'rejected',
  signed = 'signed',
  verified = 'verified',
}

export interface IScenarioUserPayload {
  email$: Observable<string>;
  isTeamMember$?: Observable<boolean>;
}
