import { Directive, HostBinding, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Directive({
  selector:
    '[mat-button][loading],[mat-raised-button][loading],[mat-icon-button][loading],[mat-fab][loading],[mat-mini-fab][loading],[mat-flat-button][loading]',
})
export class MatButtonLoadingDirective {
  constructor(private matButton: MatButton) {}

  @Input()
  set loading(value: boolean) {
    this.matButton.disabled = value;
  }

  @HostBinding('class')
  get loadingClass() {
    return this.matButton.disabled
      ? `after:rounded-full after:animate-spin after:inline-block after:w-[1.25em] after:h-[1.25em] after:border-2 after:border-transparent after:border-t-primary-500 after:absolute after:text-center after:m-auto after:inset-0`
      : '';
  }
}
