<section
  class="relative flex h-full items-center justify-center bg-white px-16 py-20 max-md:max-w-full max-md:px-5"
>
  <div class="mb-56 mt-72 w-[1022px] max-w-full max-md:my-10">
    <div class="flex gap-5 max-md:flex-col max-md:gap-0">
      <article class="flex w-[75%] flex-col max-md:ml-0 max-md:w-full">
        <header
          class="my-auto flex flex-col self-stretch text-center font-semibold text-neutral-900 max-md:mt-10 max-md:max-w-full"
        >
          <h1 class="self-center text-9xl max-md:max-w-full max-md:text-4xl">Oops!</h1>
          <p class="mt-11 text-3xl leading-9 max-md:mt-10 max-md:max-w-full">
            This site is no longer operational. <br />
            Please refer to your provider for more information.
          </p>
        </header>
      </article>
      <aside class="ml-5 flex w-[25%] flex-col max-md:ml-0 max-md:w-full">
        <img
          loading="lazy"
          src="./assets/images/icon-domain-not-operational.svg"
          alt="Domain not operational"
          class="aspect-square w-full max-md:mt-10"
        />
      </aside>
    </div>
  </div>
</section>
