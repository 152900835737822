import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  header = `cache`; // 管理字段前缀
  tailer = 'deadtime'; // 管理字段后缀

  constructor(private localStorageService: LocalStorageService) {}

  /**
   * 通过传入的 Map 缓存频繁复杂计算操作
   * @param {Map<typeof key, any>} map 用于缓存计算后的值存于该Map中
   * @param {string} key 缓存键
   * @param {() => any} callback 复杂计算操作
   */
  setMap(map: Map<typeof key, any>, key: string, callback: () => any) {
    if (!map.has(key)) {
      const value = callback();
      map.set(key, value);
    }
    return map.get(key);
  }

  /**
   * 存LocalStorage
   * @param {string} key 缓存键
   * @param {any} value 缓存值
   * @param {number} survival 缓存时间 单位为秒 不传值则永久缓存
   */
  setLocalStorage(key: string, value: any, survival?: number) {
    const strKey = [this.header, key].join('_');
    const strTime = [this.header, key, this.tailer].join('_');

    this.localStorageService.store(strKey, value);
    if (survival && survival > 0) {
      let timestamp = new Date().getTime();
      timestamp = timestamp + survival * 1000;
      this.localStorageService.store(strTime, timestamp);
    } else {
      this.localStorageService.clear(strTime);
    }
  }

  /**
   * 取LocalStorage
   * @param {string} key 缓存键
   * @returns 缓存值如不存在或超时则返回undefined
   */
  getLocalStorage(key: string) {
    const strKey = [this.header, key].join('_');
    const strTime = [this.header, key, this.tailer].join('_');
    const deadtime = this.localStorageService.retrieve(strTime);

    if (deadtime && deadtime < new Date().getTime()) {
      this.localStorageService.clear(strKey);
      this.localStorageService.clear(strTime);
      return undefined;
    }

    const res = this.localStorageService.retrieve(strKey) || undefined;
    return res;
  }

  /**
   * 移除指定缓存字段
   * @param {*} key 缓存键
   */
  removeLocalStorage(key: string) {
    const strKey = [this.header, key].join('_');
    const strTime = [this.header, key, this.tailer].join('_');

    this.localStorageService.clear(strKey);
    this.localStorageService.clear(strTime);
  }

  /**
   * 聚合存LocalStorage
   * @param {string} key 缓存键
   * @param {string} subKey 缓存子键
   * @param {any} value 缓存值
   * @param {number} survival 缓存时间 单位为秒 不传值则永久缓存
   */
  setLocalStorageAgg(
    key: string,
    subKey: string,
    value: any,
    survival?: number
  ) {
    const strValue = this.getLocalStorage(key) || '{}';

    try {
      const cache = JSON.parse(strValue);
      cache[subKey] = {
        value: value,
      };
      if (survival && survival > 0) {
        let timestamp = new Date().getTime();
        timestamp = timestamp + survival * 1000;
        cache[subKey].deadtime = timestamp;
      }
      this.setLocalStorage(key, JSON.stringify(cache));
    } catch (error) {
      console.error('setLocalStorageAgg', error);
    }
  }

  /**
   * 聚合取LocalStorage, 缓存值如不存在或超时, 则返回undefined
   * @param {string} key 缓存键
   * @param {string} subKey 缓存子键
   */
  getLocalStorageAgg(key: string, subKey: string) {
    let result;
    const strValue = this.getLocalStorage(key) || '{}';

    try {
      const cache = JSON.parse(strValue);
      const { value, deadtime } = cache[subKey] || {};
      if (deadtime && deadtime < new Date().getTime()) {
        // 过期时间
        this.removeLocalStorageAgg(key, subKey);
        result = undefined;
      } else {
        // 非过期时间
        result = value;
      }
    } catch (error) {
      console.error('getLocalStorageAgg', error);
    }

    return result;
  }

  /**
   * 移除指定聚合缓存字段
   * @param {*} key 缓存键
   */
  removeLocalStorageAgg(key: string, subKey: string) {
    const strValue = this.getLocalStorage(key) || '{}';

    try {
      const cache = JSON.parse(strValue);
      delete cache[subKey];
      this.setLocalStorage(key, JSON.stringify(cache));
    } catch (error) {
      console.error('removeLocalStorageAgg', error);
    }
  }

  /**
   * 清除所有缓存
   */
  // clearStorage() {
  //   this.localStorageService.clear();
  // }
}
