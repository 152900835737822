import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CustomDomainGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate() {
    const currentUrl = window.location.href;
    return this.checkUrl(currentUrl);
  }
  checkUrl(currentUrl: string):boolean {
    // If user invite old url, replace it.
    if (currentUrl.includes('/en-us')) {
      return this.removeTranslate(currentUrl)
    }
    return true;
  }
  removeTranslate(currentUrl: string):boolean {
    if (!currentUrl) {
      return true
    } else {
      let url = this.router.url.replace('/en-us', '');
      this.router.navigateByUrl(url);
      return false;
    }
  }
}
