import { FeatureItem } from '@stream/libs/common/models/src';

export enum Effect {
  ALLOW = 'ALLOW',
  DENY = 'DENY'
}

export interface Action {
  id: string;
  actionKey: string;
  effect: Effect;
  isHidden: number;
  actionMenu: string;
}

export enum GpRoleTypeEnum {
  INVESTMENT_DIRECTOR = 'INVESTMENT_DIRECTOR',
  FUND_ADMINISTRATOR = 'FUND_ADMINISTRATOR',
  OWNER = 'owner',
  ADMIN = 'admin',
  OPERATIONAL_SUPPORT = 'OPERATIONAL_SUPPORT',
  RELATIONSHIP_MANAGER = 'RELATIONSHIP_MANAGER',
  HEAD_OF_INVESTOR_RELATIONS = 'HEAD_OF_INVESTOR_RELATIONS',
  BIZ_OPS = 'BIZ_OPS',
  WEALTH_MANAGER_IFA_RIA = 'WEALTH_MANAGER_IFA_RIA',
  LEGAL_COUNSEL = 'LEGAL_COUNSEL'
}

export const ROLE_TYPE_CODE_MAP = {
  'R2.1': [GpRoleTypeEnum.HEAD_OF_INVESTOR_RELATIONS],
  'R2.2': [GpRoleTypeEnum.RELATIONSHIP_MANAGER],
  'R2.5': [GpRoleTypeEnum.ADMIN, GpRoleTypeEnum.OWNER],
  'R2.5-admin': [GpRoleTypeEnum.ADMIN],
  'R2.5-owner': [GpRoleTypeEnum.OWNER],
  'R2.6': [GpRoleTypeEnum.INVESTMENT_DIRECTOR],
  'R2.7': [GpRoleTypeEnum.OPERATIONAL_SUPPORT],
  'R3.3': [GpRoleTypeEnum.FUND_ADMINISTRATOR],
  'R3.4': [GpRoleTypeEnum.LEGAL_COUNSEL],
  'R5.1': [GpRoleTypeEnum.WEALTH_MANAGER_IFA_RIA],
  'R9.1': [GpRoleTypeEnum.BIZ_OPS]
};

export type RoleCodeEnum = keyof typeof ROLE_TYPE_CODE_MAP;

export interface SecurityPermission {
  actionList: Action[];
  roleList: GpRoleTypeEnum[];
  // rawRoleList is added for STREAM-17067, to fix the issue of roleList has FUND_ADMINISTRATOR when current user is a member.
  rawRoleList: GpRoleTypeEnum[];
  status: string;
  features?: FeatureItem[];
}
