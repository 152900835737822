<div
  class="flex cursor-pointer items-center space-x-2 md:max-w-min"
  *ngIf="document?.name && document?.name !== ''; else notUpload"
>
  <ng-container *ngIf="isMedium; else IS_MOBILE">
    <svg class="icon text-lg">
      <use [attr.xlink:href]="getIconByType(document?.type)"></use>
    </svg>
    <span class="flex text-sm text-gray-700" (click)="openFile($event, document?.url)">
      <span class="inline-block truncate" style="max-width: 200px; white-space: no-wrap;">{{ fileName }}</span>
      <span style="white-space: no-wrap;">.{{ fileExt }}</span>
    </span>
  </ng-container>
  <ng-template #IS_MOBILE>
    <svg class="icon inline text-lg">
      <use [attr.xlink:href]="getIconByType(document?.type)"></use>
    </svg>
    <span class="whitespace-normal" (click)="openFile(document); $event.stopPropagation()"
      >{{ fileName }}.{{ fileExt }}</span
    >
  </ng-template>
</div>

<ng-template #notUpload>
  <div class="flex cursor-pointer items-center text-sm md:max-w-min">
    <svg class="icon mr-2 inline text-base">
      <use xlink:href="#icon-icon-group"></use>
    </svg>
    <span
      class="inline truncate whitespace-pre-wrap text-gray-500 md:inline-block md:whitespace-nowrap"
    >
      Proof of {{ proofType }} has not been uploaded
    </span>
  </div>
</ng-template>
