import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormlyModule } from '@ngx-formly/core';
import { QuillViewModule } from '@stream/ngx-utils';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

import { TooltipsModule } from '../tooltips/tooltips.module';
import { FormlyFieldWrapperComponent } from './formly-field-wrapper.component';

@NgModule({
  declarations: [FormlyFieldWrapperComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    TooltipsModule,
    MatIconModule,
    NzPopoverModule,
    QuillViewModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'stream-formly-field-wrapper',
          component: FormlyFieldWrapperComponent,
        },
      ],
    }),
  ],
  exports: [FormlyFieldWrapperComponent],
})
export class FormlyFieldWrapperModule {}
