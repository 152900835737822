import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataRoomApi,
  DataRoomDocument,
  DataRoomDocumentType,
  MetaApi,
  MetaCountry,
  Restful
} from '@stream/models';
import moment from 'moment';
import { pluck, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private http: HttpClient) {}

  countryList = this.getCountryList().pipe(pluck('data', 'metaCountries'), shareReplay(1));

  /**
   * 获取国家列表
   */
  private getCountryList() {
    return this.http.get<Restful<{ metaCountries: MetaCountry[] }>>(MetaApi.QueryCountries);
  }

  /**
   * 获取有水印文件
   * @param fileId 文件ID
   * @returns 文件Blob
   */
  queryFile(fileId: string, marketingDataRoomId?: string, download?: boolean, timezone?: string) {
    const tz = timezone || moment.tz.guess();
    return this.http.post<Blob>(
      DataRoomApi.QueryFile,
      {
        fileId,
        marketingDataRoomId,
        download,
        readDate: moment.tz(new Date(), tz).format('MMM DD, YYYY, hh:mmA').toString()
      },

      {
        //@ts-ignore
        responseType: 'blob'
      }
    );
  }

  /**
   * 获取文件夹结构
   * @param folderId 文件ID
   */
  queryFolder(folderId: string) {
    return this.http.post<
      Restful<{
        currentDataRoomDocument: DataRoomDocument;
        dataRoomDocumentsList: DataRoomDocument[];
      }>
    >(DataRoomApi.QueryFolder, {
      folderId
    });
  }

  /**
   * 上传私库文件
   * @param file 文件
   */
  uploadPrivateFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<
      Restful<{
        documentToken: string;
        documentType: DataRoomDocumentType;
        name: string;
        url: string;
        status: string;
      }>
    >(DataRoomApi.UploadPrivateFile, formData);
  }
}
