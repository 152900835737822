import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BaseCurrency } from '@stream/models';
import { LocalStorageService } from 'ngx-webstorage';
import { filter, map } from 'rxjs/operators';

import { AccountService } from '../../services/account.service';
import { ClientService } from '../../services/client.service';
import { InboxService } from '../../services/inbox.service';
import { LanguageService } from '../../services/language.service';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'stream-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  constructor(
    private subscriptionSv: SubscriptionService,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private location: Location,
    private matDialog: MatDialog,
    public clientService: ClientService,
    private inboxService: InboxService,
    private languageService: LanguageService
  ) {
    this.inboxService.getInboxUnreadCount().subscribe();
  }

  inboxMessageCount$ = this.inboxService.unreadCount$;
  languages = this.languageService.languageList$;
  activeLang = new FormControl('en-gb');

  @ViewChild('discardDialog')
  discardDialogRef!: TemplateRef<any>;
  policyContent: any;
  rate = this.subscriptionSv.rate;
  account = this.accountService.account;
  wmCandidate = this.accountService.wmCandidate;
  currentCurrency = 'USD';
  isMedium = this.breakpointObserver
    .observe('(min-width: 768px)')
    .pipe(map(({ matches }) => matches));

  activeRouteName = '';

  hideBottomNav = false;

  inTrigger = false;
  rateNumber = 0;
  isPolicyShow = false;
  isRateShow = false;
  tenantCurrency = '';

  get activeLangInfo() {
    return this.languages.pipe(
      map(list => {
        const lang = list.find(item => item.code === this.activeLang.value);
        return {
          title: lang?.title,
          flag: lang?.flag
        };
      })
    );
  }

  ngOnInit(): void {
    this.activeRouteName = this.getActiveRouteTitle(this.route.snapshot);
    this.hideBottomNav = this.getActiveRouteNavHidden(this.route.snapshot);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.activeRouteName = this.getActiveRouteTitle(this.route.snapshot);
      this.hideBottomNav = this.getActiveRouteNavHidden(this.route.snapshot);
    });
    this.account.subscribe(({ currencyCode: currencyCode, language }) => {
      if (currencyCode) {
        this.currentCurrency = currencyCode;
        this.localStorage.store(BaseCurrency.currency, currencyCode);
        this.rate.subscribe(res => {
          this.rateNumber = res[currencyCode];
        });
      } else {
        this.currentCurrency = 'USD';
        this.localStorage.store(BaseCurrency.currency, 'USD');
      }

      if (language !== this.activeLang.value) {
        this.activeLang.setValue(language ?? 'en-gb');
        this.changeLanguage(language);
      }
      this.clientService.configuration.subscribe(({ baseCurrency: baseCurrency }) => {
        this.tenantCurrency = baseCurrency;
        if (baseCurrency === currencyCode) {
          this.isRateShow = false;
        } else {
          this.isRateShow = true;
        }
      });
    });
    this.getCurrency();
  }
  getCurrency() {
    this.accountService.GetCurrencyPolicy().subscribe(result => {
      this.policyContent = result.data?.data;
      if (result.data?.data) {
        this.isPolicyShow = true;
      } else {
        this.isPolicyShow = false;
      }
    });
  }
  getActiveRouteTitle = (route: ActivatedRouteSnapshot): string => {
    const { title } = route.data;
    if (title) {
      return title;
    }
    if (route.firstChild) {
      return this.getActiveRouteTitle(route.firstChild);
    }
    return '';
  };

  getActiveRouteNavHidden = (route: ActivatedRouteSnapshot): boolean => {
    const { hideBottomNav } = route.data;
    if (hideBottomNav) {
      return hideBottomNav as boolean;
    }
    if (route.firstChild) {
      return this.getActiveRouteNavHidden(route.firstChild);
    }
    return false;
  };

  showOverlayMenu() {
    this.inTrigger = true;
  }
  overlayTriggerLeave() {
    this.inTrigger = false;
  }
  goAccount() {
    this.router.navigate(['/account/basic']);
    this.inTrigger = false;
  }
  viewCurrency() {
    this.matDialog
      .open(this.discardDialogRef, {
        autoFocus: '[mat-flat-button]'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.location.back();
        }
      });
  }

  changeLanguage(lang: string) {
    setTimeout(() => {
      this.activeLang.patchValue(lang);
      this.languageService.translateSubject$.next(lang);
    }, 200);
  }
}
