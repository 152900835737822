import { Component, Input } from '@angular/core';
import { AdditionalDocument, DataRoomDocumentType } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { finalize } from 'rxjs/operators';

import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'stream-additional-doc-upload',
  templateUrl: './additional-doc-upload.component.html',
  styleUrls: ['./additional-doc-upload.component.scss']
})
export class AdditionalDocUploadComponent {
  @Input() document: AdditionalDocument = {
    id: '',
    fileType: DataRoomDocumentType.Pdf
  };
  loading = false;
  @Input()
  maxSize = 30 * 1024 * 1024;
  @Input()
  accept?: string = 'application/pdf,image/jpg,image/png,image/jpeg';
  @Input() profileId: string = '';
  @Input() other: boolean = false;

  constructor(
    private notification: NotificationService,
    private profileService: ProfileService
  ) {}

  onInput(e: Event) {
    const target = e.target as HTMLInputElement;
    const fileList = target.files;
    if (fileList) {
      Array.from(fileList).forEach(file => {
        this.beforeUpload(file);
      });
    }
    target.value = '';
  }

  beforeUpload(file: File) {
    if (this.accept) {
      if (!this.accept.split(',').includes(file.type)) {
        this.notification.error('Allowed file formats pdf, jpeg, png / max 30mb');
        return false;
      }
    }
    if (file.size > this.maxSize) {
      this.notification.error('Allowed file formats pdf, jpeg, png / max 30mb');
      return false;
    }
    this.upload(file);
    return true;
  }

  documentList(document: AdditionalDocument): any {
    const { fileUrl, fileName, fileType, id } = document;
    return {
      name: fileName,
      type: fileType,
      url: fileUrl
    };
  }

  upload(file: File) {
    this.loading = true;
    const index = file.name?.lastIndexOf('.');
    const ext = file.name?.substring(index + 1);
    let fileName = file.name;
    if (!this.other) {
      fileName = `${this.document.documentName}.${ext}`;
    }
    this.profileService
      .postAdditionalDocument({
        file,
        profileId: this.profileId,
        documentId: this.document.id
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(({ data: { url, documentType } }) => {
        this.document = {
          ...this.document,
          fileUrl: url,
          fileName,
          fileType: documentType
        };
      });
  }
}
