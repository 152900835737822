import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Option, ValueSelectorComponent } from '../../condition-builder.model';

@Component({
  selector: 'stream-condition-value-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less'],
})
export class SelectComponent extends ValueSelectorComponent {
  @Input()
  set options(value: Option[] | Observable<Option[]>) {
    if (!value) {
      this.options$ = of([]);
      return;
    }
    if (value instanceof Observable) {
      this.options$ = value;
      return;
    }
    this.options = of(value);
  }

  options$: Observable<Option[]> = of([]);
}
