import { Injectable } from "@angular/core";
import { AREA_CODE_SOURCE } from './countries.data';
@Injectable()
export class CountriesService {
  constructor() {}

  getCountriesArray() {
    return AREA_CODE_SOURCE;
  }

  /**
   * @description Get country data
   * @param countryCode 
   */
  getCountryData(countryCode: string) {
    const country = this.getCountriesArray().find((country) => country.countryCode === countryCode)
    if (country?.name) {
      return {
        ...country,
        countryCode,
        dialCode: `${country.dialCode}`,
        phone: [country.dialCode],
      }
    }
    return null;
  }

  getCountryDialCodeArrayInSelection() {
    return this.getCountriesArray().map(({ name, countryCode, dialCode }) => ({
      label: `${name} +${dialCode}`,
      value: countryCode,
    }));
  }

  getCountriesInSelection() {
    return this.getCountriesArray().map(({ name, countryCode }) => ({
      label: name,
      value: countryCode,
    }));
  }
}