import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import {
  VerifyUploadComponent,
} from './components';
import { UTILS_CONFIG, UtilsConfig } from './utils.type';

@NgModule({
  declarations: [
    VerifyUploadComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NzButtonModule,
    NzIconModule,
    NzMessageModule,
    NzUploadModule,
  ],
  exports: [VerifyUploadComponent],
})

export class UtilsModule {
  static injector: Injector;
  static forRoot(configs: UtilsConfig): ModuleWithProviders<UtilsModule> {
    return {
      ngModule: UtilsModule,
      providers: [
        {
          provide: UTILS_CONFIG,
          useValue: configs,
        },
      ],
    };
  }

  constructor(injector: Injector) {
    UtilsModule.injector = injector;
  }
}