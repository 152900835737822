import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ContactFile,
  EmailDelayKeyEnum,
  GatewayContract,
  Project,
  Restful,
  Seal,
  SignApi,
  SignDetail,
  Signer,
  SignerAPI,
  SignerDTO,
} from '@stream/models';
import { SendEmail } from '@stream/ngx-utils';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SignService {
  constructor(
    private http: HttpClient,
  ) {}

  getProjectDetail(id: Project['id']) {
    return this.http.get<
      Restful<{
        description: string;
        responseData: Project & {
          currentUserNeedSign: boolean;
          ticket?: string;
          currentSigner?: Signer;
        };
        status: string;
      }>
    >(SignApi.GetProjectDetail, {
      params: {
        id,
      },
    });
  }

  updateFile(fileId: ContactFile['id'], formData: Record<string, string>) {
    return this.http.post(SignApi.UpdateFile, {
      fileId,
      formData,
    });
  }

  startUpProject(id: string, sendEmail?: boolean) {
    return this.http.get<
      Restful<{
        responseData: {
          currentUserNeedSign: boolean;
          ticket?: string;
        };
      }>
    >(SignApi.StartUpProject, { params: sendEmail ? { id, sendEmail } : { id } });
  }

  @SendEmail(EmailDelayKeyEnum.SignCaptcha)
  signCaptcha(ticket: string) {
    return this.http.get<
      Restful<{
        responseData: string;
        status: string;
        description: string;
      }>
    >(SignApi.SignCaptcha, {
      params: {
        ticket,
      },
    });
  }

  signValidate(captcha: string, captchaId: string, ticket: string) {
    return this.http.post<
      Restful<{
        description: string;
        status: string;
      }>
    >(SignApi.SignValidate, {
      captcha,
      captchaId,
      ticket,
    });
  }

  getSealList(ticket: string) {
    return this.http.get<
      Restful<{
        responseData: Seal[];
        status: string;
      }>
    >(SignApi.GetSealList, { params: { ticket } });
  }

  signProject(ticket: string, sealId: Seal['id'], reviewerName?:string, reviewerTitle?:string) {
    return this.http.post<
      Restful<{
        responseData: string;
        status: string;
        description: string;
      }>
    >(SignApi.SignProject, {
      sealId,
      ticket,
      reviewerName,
      reviewerTitle
    });
  }

  getProjectStatus(ticket: string) {
    return this.http.get<
      Restful<{
        responseData: {
          fileSignStatusList: {
            fileName: string;
            id: string;
            progress: number;
            signedUrl: string;
            status: string;
          }[];
          projectStatus: string;
          taskStatus: string;
        };
      }>
    >(SignApi.GetProjectStatus, { params: { ticket } });
  }

  resendEmail(projectId: Project['id'], signerId: Signer['id']) {
    return this.http.post<
      Restful<{
        responseData: string;
        description: string;
      }>
    >(SignApi.ResendEmail, {
      projectId,
      signerId,
    });
  }

  updateSigner(id: string, params: SignerDTO) {
    return this.http.put<
      Restful<{
        status: string;
      }>
    >(SignerAPI.signer.replace(':signerId', id), params);
  }

  getSignDetail(ticketId: string) {
    return this.http
      .get<Restful<{
        responseData: SignDetail;
        status: string;
        description: string;
      }>>(SignApi.SignDetail, {
        params: {
          ticket: ticketId,
        },
      });
  }

  downloadFile(fileId: string) {
    return this.http.get(`${SignApi.DownloadFile}?id=${fileId}`,
      { responseType: 'blob' }
    );
  }

  getPdfDetail(id: GatewayContract['contractId']) {
    return this.http.get<
      Restful<{
        responseData: ContactFile;
      }>
    >(SignApi.GetPdfFileDetail, { params: { id } });
  }

  getPdfFiles(ids: string[]) {
    return this.http.post<Restful<ContactFile[]>>(SignApi.GetPdfFiles, { ids }).pipe(pluck('data'));
  }

  updateProjectFiles(files: {
    fileId: string,
    formData: Record<string, string>,
  }[]) {
    return this.http.post<Restful<any>>(SignApi.UpdateFiles, {updateFileDataList: files}).pipe(pluck('data'));
  }
}
