import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Signer, SignerType } from '@stream/models';
import { finalize } from 'rxjs/operators';

import { CountriesService } from '@stream/libs/common/countries';
import { SignService } from '../../services';

@Component({
  selector: 'sign-signatory-witness-form',
  templateUrl: './witness-form.component.html',
  styleUrls: ['./witness-form.component.scss'],
})
export class WitnessFormComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      witness: Signer;
      signerType: SignerType;
      signerList: Signer[];
    },
    private signerService: SignService,
    private dialogRef: MatDialogRef<WitnessFormComponent>,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private countriesService: CountriesService,
  ) {}
  readonly areaCodes = this.countriesService.getCountriesArray().map(c => ({
    value: c.dialCode,
    label: `${c.name} +${c.dialCode}`,
  })).filter(c => c.value);
  form!: FormGroup;
  submitting = false;

  readonly signerEmailValidators = (control: FormControl) => {
    const valid = this.data.signerList.every(signer => signer.email !== control.value);
    return valid ? null : { duplicate: true };
  };

  get isWitness() {
    return this.data.signerType === SignerType.witness;
  }

  get emailCtr() {
    return this.form.get('email') as FormControl;
  }

  get isMedium() {
    return this.breakpointObserver.isMatched('(min-width: 768px)');
  }

  ngOnInit(): void {
    if (this.isWitness) {
      this.initWitnessForm();
    } else {
      this.initSignerForm();
    }
  }

  initSignerForm() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, this.signerEmailValidators]],
      phoneNumber: this.fb.group({
        areaCode: ['', [Validators.required]],
        number: ['', [Validators.required]],
      }),
    });
    this.form.patchValue({
      ...this.data.witness,
    });
  }

  initWitnessForm() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: this.fb.group({
        areaCode: ['', [Validators.required]],
        number: ['', [Validators.required]],
      }),
      occupation: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
    this.form.patchValue({
      ...this.data.witness,
    });
    this.form.get('email')?.disable();
    this.form.get('firstName')?.disable();
    this.form.get('lastName')?.disable();
    this.form.get('phoneNumber')?.disable();
  }

  getEmailCtrError() {
    if (!this.emailCtr) {
      return '';
    }
    if (this.emailCtr.hasError('required')) {
      return 'The field is required.';
    }
    if (this.emailCtr.hasError('email')) {
      return 'Please enter valid email.';
    }
    if (this.emailCtr.hasError('duplicate')) {
      return 'Email cannot be the same as signer.';
    }
    return '';
  }

  submit() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    Object.values({
      ...this.form.controls,
      ...(this.form.controls['phoneNumber'] as FormGroup).controls,
    }).forEach(c => {
      c.markAsTouched();
      c.markAsDirty();
      c.updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.submitting = false;
      return;
    }
    this.signerService
      .updateSigner(this.data.witness.id, {
        ...this.form.value,
        id: this.data.witness.id,
        type: SignerType.witness,
      })
      .pipe(
        finalize(() => {
          this.submitting = false;
        }),
      )
      .subscribe(res => {
        if (res.data.status === 'success') {
          this.dialogRef.close(true);
        } else {
          this.snackBar.open(res.message || 'Update witness failed.', '', {
            panelClass: 'notification-error',
            verticalPosition: this.isMedium ? 'top' : 'bottom',
          });
        }
      });
  }

  getSignerName() {
    if (!this.isWitness) {
      return '';
    }
    const signerId = this.data.witness.witnessFor;
    const signer = this.data.signerList.find(s => s.id === signerId);
    return signer?.fullName ?? '';
  }
}
