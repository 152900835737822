import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BrowserFingerService } from '../services/browser-finger.service';

@Injectable()
export class DeviceInfoInterceptor implements HttpInterceptor {
  constructor(private fingerService: BrowserFingerService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.fingerService.deviceHeaderInfo.pipe(
      switchMap((deviceInfo) =>
        next.handle(
          request.clone({
            setHeaders: deviceInfo,
          })
        )
      )
    );
  }
}
