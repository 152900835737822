import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

import { AccountService } from '../../../services/account.service';

@Injectable({
  providedIn: 'root',
})
export class WMCandidateGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { url } = state;
    if (
      url.indexOf('scenario') > -1 &&
      !/\/scenario\/(INVESTING|RESERVE)/.test(url)
    ) {
      return true;
    }
    return this.accountService.wmCandidate.pipe(
      map((wmCandidate) =>
        wmCandidate ? this.router.createUrlTree(['/']) : true
      )
    );
  }
}
