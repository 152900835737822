<header class="!flex items-center" mat-dialog-title>
  <h2 class="!mb-0 text-lg md:text-xl flex-1">Re-invest Required</h2>
  <button mat-icon-button mat-dialog-close="CLOSE" class="!hidden md:!inline-block">
    <mat-icon>close</mat-icon>
  </button>
</header>
<p mat-dialog-content class="text-primary-300">
  Investment process configuration has changed. Please re-invest.
</p>
<footer class="flex items-center justify-between gap-x-2 md:justify-end">
  <button
    mat-stroked-button
    class="mat-button-lg w-1/2 md:w-auto md:!px-6"
    color="primary"
    mat-dialog-close="CLOSE"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    class="mat-button-lg w-1/2 md:w-auto md:!px-6"
    color="primary"
    (click)="reInvest()"
  >
    Re-invest
  </button>
</footer>
