<div class="truncate leading-6">
  <span class="name">{{ subscription.fundName }}</span>
  <span class="amount"
    >- {{ subscription.currency }}{{ subscription.amount }}</span
  >
</div>
<div
  class="tag"
  *ngIf="subscription.status !== 'REVOKE'"
  [class]="subscription.status"
>
  {{ getFundStatusText(subscription.status) }}
</div>
