import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ScanningStatusEnum } from '@stream/libs/common/models/src';
import { ToastService } from '@stream/libs/common/ngx-utils/services/toast';
import { FilePreviewerComponent } from '@stream/ngx-utils';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { Debounce } from '../../../utils/decorator';
import { CacheService, FetchService } from '../../../utils/service';
import { CHAT_CONFIG, ChatConfig } from '../../chat.type';
import { IMessageFileValueType } from '../message-file/message-file.model';

@Component({
  selector: 'stream-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.less'],
  providers: [
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => MessageInputComponent),
    //   multi: true,
    // },
    {
      provide: MatFormFieldControl,
      useExisting: MessageInputComponent
    }
  ]
})
export class MessageInputComponent
  implements OnInit, OnDestroy, DoCheck, MatFormFieldControl<any>, ControlValueAccessor
{
  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    public dialogFilePreviewer: MatDialog,
    public cacheService: CacheService,
    public fetchService: FetchService,
    private toast: ToastService,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Inject(CHAT_CONFIG)
    public config: ChatConfig
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  touched = false;
  static nextId = 0;
  private _required = false;
  private _disabled = false;
  private _isFileUploading: boolean = false;
  public cacheGetMap = new Map();
  public isContentFocus: boolean = false;
  public errorState: boolean = false;
  scopeToast = uuidV4();

  @HostBinding()
  id = `stream-message-input-${MessageInputComponent.nextId++}`;

  stateChanges = new Subject<void>();

  focused: boolean = false;

  controlType?: string | undefined;

  autofilled?: boolean | undefined;

  userAriaDescribedBy?: string | undefined;

  setDescribedByIds(ids: string[]): void {
    // implement this
  }

  onContainerClick(event: MouseEvent): void {
    // implement this
  }

  public _onChange: any = () => null;
  public _onTouch: any = () => null;

  get value() {
    return {
      contentValue: this.valueContent,
      fileValue: this.valueFile
    };
  }

  get empty() {
    return this.valueContent === '' && this.valueFile.length === 0;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  source: 'GP' | 'LP' | '' = '';

  @Input()
  type: 'normal' | 'chat' = 'normal';

  @Input()
  uuid: string = '';

  @Input()
  customStyleWrap: any = '';

  @Input()
  customStyleTextarea: any = '';

  @Input()
  customStyleFooter: any = '';

  @Input()
  isHiddenBorder: boolean = false;

  @Input()
  isValidatorsFailed: boolean = false;

  @Input()
  isShowBtnSend: boolean = false;

  @Input()
  isDisableBtnSend: boolean = false;

  @Input()
  isShowBtnNormal: boolean = false;

  @Input()
  isDisableBtnNormal: boolean = false;

  @Input()
  textBtnNormal: string = 'Normal';

  @Input()
  isShowLimitInput: boolean = false;

  @Input()
  limitInput: number = -1;

  @Input()
  placeholder: string = 'Please input';

  @Input()
  apiUpload: string = '/data-room-documents/upload-private-file';

  @Input()
  acceptFile: string = 'application/pdf,image/jpg,image/png,image/jpeg';

  @Input()
  limitFileSize: number = 30 * 1024 * 1024;

  @Input()
  errorMessageFile: string =
    `We only support file formats JPG, PNG, and PDF, with a maximum file size of 30MB size`;

  @Input()
  valueContent: string = '';

  @Input()
  valueFile: IMessageFileValueType[] = [];

  set isFileUploading(value: boolean) {
    this._isFileUploading = value;
    this.fileUploadChange.emit(value);
  }

  get isFileUploading() {
    return this._isFileUploading;
  }

  get sourceReal() {
    return this.source || this.config.source;
  }

  get colorPrimary() {
    return this.sourceReal === 'GP' ? '#2E4B9C' : '#101213';
  }

  get colorPrimaryLight() {
    return this.sourceReal === 'GP' ? '#2E4B9C82' : '#10121382';
  }

  get colorBorder() {
    return this.isValidatorsFailed
      ? '#f5222d'
      : this.isContentFocus
        ? 'var(--message-input-color-primary)'
        : 'var(--message-input-color-default)';
  }

  get colorBorderFocus() {
    return this.isValidatorsFailed ? '#f5222d20' : `${this.colorPrimary}20`;
  }

  @Output()
  contentFocus = new EventEmitter();

  @Output()
  contentBlur = new EventEmitter();

  @Output()
  valueChange = new EventEmitter();

  @Output()
  fileUploadChange = new EventEmitter();

  @Output()
  sendClick = new EventEmitter();

  @Output()
  normalClick = new EventEmitter();

  public updateErrorState() {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState =
      (this.ngControl?.invalid || this.ngControl.invalid) && (this.touched || parent.submitted);

    if (oldState !== newState) {
      this.errorState = newState as boolean;
      this.stateChanges.next();
    }
  }

  onFocusIn(_event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(_event: FocusEvent) {
    this.touched = true;
    this.focused = false;
    this._onTouch();
    this.stateChanges.next();
  }

  ngOnInit(): void {
    // 如果涉及缓存, 则从缓存中获取输入框内容
    if (this.uuid) {
      const cacheValue = this.cacheService.getLocalStorageAgg('message_input', this.uuid);
      if (cacheValue) {
        this.valueContent = cacheValue.contentValue;
        this.valueFile = cacheValue.fileValue;
      }
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.ngControl.control?.updateValueAndValidity();
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.toast.common.clearCb({
      scopes: [this.scopeToast]
    });
  }

  writeValue(obj: any): void {
    // console.log('writeValue', obj);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // implement this
  }

  getBtnSendDisabled(): boolean {
    return (
      this.isDisableBtnSend ||
      this.isFileUploading ||
      (!this.valueContent && !this.valueFile.length)
    );
  }

  updateValueChanges(): void {
    const result = {
      contentValue: this.valueContent,
      fileValue: this.valueFile
    };
    this._onChange(result);
    this.valueChange.emit(result);
    if (this.uuid) {
      this.cacheService.setLocalStorageAgg('message_input', this.uuid, result);
    }
  }

  handleContentFocus() {
    this.isContentFocus = true;
    this.contentFocus.emit();
  }

  handleContentBlur() {
    this.isContentFocus = false;
    this.contentBlur.emit();
    this.touched = true;
    this.focused = false;
    this._onTouch();
    this.stateChanges.next();
  }

  @Debounce(100)
  handleContentInput(event: any): void {
    this.valueContent = event.target.value;
    this.updateValueChanges();
    this._onTouch();
  }

  handleFileInput(event: any): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (file) {
      if (this.acceptFile && !this.acceptFile.split(',').includes(file.type)) {
        this.toast.common.warning({
          scope: this.scopeToast,
          msg: 'Upload Blocked: File format is not valid.'
        });
        return;
      }
      if (file.size > this.limitFileSize) {
        this.toast.common.warning({
          scope: this.scopeToast,
          msg: `Upload Blocked: Max size for file is ${Math.floor(
            this.limitFileSize / (1024 * 1024)
          )} MB.`
        });
        target.value = '';
        return;
      }
      this.isFileUploading = true;
      const uuid = this.toast.common.scanning({ scope: this.scopeToast });
      const params = new FormData();
      params.append('file', file as unknown as Blob);
      this.fetchService.post(this.apiUpload, params).subscribe(
        (res: any) => {
          this.isFileUploading = false;
          if (res?.data?.url) {
            this.toast.common.success({ scope: this.scopeToast, uuid });
            this.valueFile.push(res?.data);
            this.updateValueChanges();
          } else if (
            res?.data?.scanResult === ScanningStatusEnum.RESULT_MALICIOUS ||
            res?.data?.status === ScanningStatusEnum.MALICIOUS
          ) {
            this.toast.common.malicious({ scope: this.scopeToast, uuid });
            console.error('handleFileInput failed', res);
            target.value = '';
          } else {
            this.toast.common.warning({ scope: this.scopeToast, uuid });
            console.error('handleFileInput failed1', res);
            target.value = '';
          }
        },
        (err: any) => {
          this.isFileUploading = false;
          this.toast.common.warning({ scope: this.scopeToast, uuid });
          console.error('handleFileInput failed2', err);
          target.value = '';
        }
      );
    } else {
      this.updateValueChanges();
    }
  }

  handleFilePreviewClick(file: IMessageFileValueType | null) {
    if (!file) {
      return;
    }
    const type = file.documentType || file.type;
    if (file && ['IMAGE', 'PDF'].includes(String(type))) {
      this.dialogFilePreviewer.open(FilePreviewerComponent, {
        data: {
          type: type,
          name: file.name,
          url: file.url || file.downloadUrl,
          downloadUrl: file.downloadUrl || file.url,
          btnCloseText: 'Cancel',
          btnCloseStyle: `color: ${this.colorPrimary}`,
          btnDownloadText: 'Download',
          btnDownloadStyle: `background-color: ${this.colorPrimary}`
        },
        maxWidth: '60vw',
        maxHeight: '90vh',
        width: '100%'
      });
    }
  }

  handleFileDeleteClick(index: number): void {
    this.valueFile.splice(index, 1);
    this.updateValueChanges();
  }

  handleBtnSendClick(): void {
    const result = cloneDeep({
      contentValue: this.valueContent,
      fileValue: this.valueFile
    });
    this.sendClick.emit(result);
    // 初始化组件数据, 清除缓存
    this.valueContent = '';
    this.valueFile = [];
    this.updateValueChanges();
    if (this.uuid) {
      this.cacheService.removeLocalStorageAgg('message_input', this.uuid);
    }
  }

  handleBtnNormalClick(): void {
    const result = cloneDeep({
      contentValue: this.valueContent,
      fileValue: this.valueFile
    });
    this.normalClick.emit(result);
  }
}
