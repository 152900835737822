import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MandatoryDocFile, Store } from '@stream/models';
import { FilePreviewerComponent } from '@stream/ngx-utils';
import { getIconByType } from '@stream/utils';
import { MandatoryDocService } from '../../../mandatory-doc';

@Component({
  selector: 'stream-certified-supporting-docs',
  templateUrl: './certified-supporting-docs.component.html',
  styleUrls: ['./certified-supporting-docs.component.scss'],
})
export class CertifiedSupportingDocsComponent {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private mdDocService: MandatoryDocService,
  ) {}

  @Input() control: AbstractControl | null = null;

  get documents(): MandatoryDocFile[] {
    return this.control?.value || [];
  }

  getIconByType = getIconByType;

  store = new Store({ type: 'session', namespace: 'scenario' });

  getDocumentTagLabel = (v: MandatoryDocFile) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  deleteFile(index: number) {
    const profile = this.store.get('profile');

    if (profile) {
      const { investorProfileMetaInfoCertificateList = [] } = profile;
      profile.investorProfileMetaInfoCertificateList =
        investorProfileMetaInfoCertificateList.filter((_: any, i: number) => i !== index);
      this.store.set('profile', profile);
    }

    this.control?.setValue(this.documents.filter((_, i) => i !== index));
  }

  preview(doc: MandatoryDocFile) {
    const isMedium = this.breakpointObserver.isMatched('(min-width: 768px)');
    this.dialog.open(FilePreviewerComponent, {
      data: {
        type: doc.type,
        url: doc.url,
        downloadUrl: doc.downloadUrl,
        name: doc.name,
      },
      maxWidth: isMedium ? '960px' : '90vw',
      maxHeight: '100vh',
      width: '100%',
    });
  }

  openDocSelector() {
    const dialog = this.mdDocService.open({
      mandatoryDocs: [
        {
          documentName: 'Supporting documents',
          files: [],
          isMandatory: true,
          multiple: true,
        },
      ] as any,
    });
    dialog.afterClosed().subscribe(result => {
      if (!(result && result[0])) {
        return;
      }
      const v = result[0];
      const profile = this.store.get('profile');

      if (v?.files?.[0]) {
        if (profile) {
          const { investorProfileMetaInfoCertificateList = [] } = profile;
          const file = v.files?.[0];
          file.fileName = file.name;
          file.fileType = file.type;
          file.fileUrl = file.url;

          const entityFile = {
            referenceProofCertificateFileEntity: {
              documents: file,
            },
          };
          profile.investorProfileMetaInfoCertificateList = [
            ...investorProfileMetaInfoCertificateList,
            entityFile,
          ];
          this.store.set('profile', profile);
        }

        this.control?.setValue([...this.documents, v.files[0]]);
      }
    });
  }
}
