import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ReplaySubject, zip } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { ConditionBuilderService } from '../../condition-builder.service';
import { ValueSelectorDirective } from './value-selector.directive';

@Component({
  selector: 'stream-expression-editor',
  templateUrl: './expression-editor.component.html',
  styleUrls: ['./expression-editor.component.less'],
})
export class ExpressionEditorComponent implements OnInit, OnDestroy {
  constructor(
    private service: ConditionBuilderService,
    private injector: Injector,
  ) {}

  @Input()
  set formArray(value: FormArray) {
    if (value) {
      this.formArray$.next(value);
    }
  }

  @ViewChild(ValueSelectorDirective, { static: true })
  valueSelectorHost!: ValueSelectorDirective;

  formArray$ = new ReplaySubject<FormArray>();

  config$ = this.formArray$.pipe(
    switchMap(formArray => this.service.getConfig(formArray.at(0).value)),
    shareReplay(1),
  );

  typeConfig$ = this.config$.pipe(
    map(({ type }) => this.service.getTypeConfig(type)),
    shareReplay(1),
  );

  label$ = this.config$.pipe(
    map(config => `${config.parent?.name.concat(' - ') ?? ''}${config?.name}`),
  );

  private formValidateHandler = this.service.validate$.subscribe(() => {
    this.formArray$.pipe(take(1)).subscribe(formArray => {
      formArray.controls.forEach(control => {
        control.updateValueAndValidity();
      });
    });
  });

  ngOnInit() {
    this.loadValueSelectorComponent();
  }

  loadValueSelectorComponent() {
    const viewContainerRef = this.valueSelectorHost.viewContainerRef;
    viewContainerRef.clear();
    zip(this.config$, this.typeConfig$, this.formArray$)
      .pipe(take(1))
      .subscribe(([{ params }, { component, defaultParams }, formArray]) => {
        const componentRef = viewContainerRef.createComponent(component, {
          injector: Injector.create({
            providers: [],
            parent: this.injector,
          }),
        });

        Object.assign(componentRef.instance, defaultParams, params, {
          formControl: formArray.at(2),
        });
      });
  }

  ngOnDestroy(): void {
    this.formValidateHandler.unsubscribe();
  }
}
