<form [formGroup]="form" (ngSubmit)="handleSaveIRASubmit()" class="flex h-full flex-col">
  <div class="mb-4 !flex items-center md:mb-5" mat-dialog-title>
    <h1 class="flex-1 text-lg font-semibold text-primary-500 md:text-xl">
      IRA information
    </h1>
    <button mat-icon-button mat-dialog-close class="!hidden md:!inline-block">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    mat-dialog-content
    class="ira-form-content flex-1"
    [style]="{ 'max-height': contentH }"
  >
    <mat-form-field>
      <mat-label>Tax ID type</mat-label>
      <mat-select formControlName="taxType" panelClass="max-w-none">
        <mat-option *ngFor="let item of taxIDTypeList" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', 'taxType')">
        This field is required
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tax ID</mat-label>
      <input matInput formControlName="taxCode" />
      <mat-error *ngIf="form.hasError('required', 'taxCode')">
        This field is required
      </mat-error>
      <mat-error *ngIf="form.hasError('maxlength', 'taxCode')">
        Please input a valid Tax ID
      </mat-error>
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label>IRA type</mat-label>
      <mat-select formControlName="type" panelClass="max-w-none">
        <mat-option *ngFor="let item of iraTypeList" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', 'type')">
        This field is required
      </mat-error>
    </mat-form-field> -->
    <mat-form-field>
      <mat-label>Account number (last 4 digits)</mat-label>
      <input matInput formControlName="mtAccountNumberLast4" />
      <mat-error *ngIf="form.hasError('required', 'mtAccountNumberLast4')">
        This field is required
      </mat-error>
      <mat-error
        *ngIf="
          form.hasError('minlength', 'mtAccountNumberLast4') ||
          form.hasError('maxlength', 'mtAccountNumberLast4')
        "
      >
        Please input the last 4 digits of your account
      </mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="grid grid-cols-2 justify-around md:flex md:justify-end">
    <button
      mat-stroked-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flex-1 !px-14 md:flex-none"
      cdkFocusInitial
      type="submit"
    >
      <div class="flex flex-row justify-center items-center">
        <mat-spinner
          *ngIf="loading"
          diameter="22"
          strokeWidth="2"
          class="mr-2"
        ></mat-spinner>
        <div>Retrieve account</div>
      </div>
    </button>
  </div>
</form>
