import { Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { ScenarioPanelService } from '../../services/scenario-panel.service';

@Component({
  selector: 'stream-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent {
  constructor(private panelService: ScenarioPanelService) {}

  fundamental = this.panelService.scenario.pipe(map(scenario => scenario.fundamental));

  start() {
    this.panelService.start.next(false);
  }
}
