<ul class="table">
  <li class="thead">
    <div class="name-head"></div>
    <ul class="content-head">
      <li *ngFor="let column of to?.['tableOptions'].columns">
        {{ column.en }}
      </li>
    </ul>
  </li>
  <li *ngFor="let row of to?.['tableOptions'].rows; let rowIndex = index" class="row">
    <div class="name">
      {{ row.en }}
    </div>
    <ng-container [ngSwitch]="to['tableOptions'].cellType">
      <mat-radio-group *ngSwitchCase="TableCellType.Radio" class="radio">
        <mat-radio-button
          class="radio-button"
          *ngFor="let column of to?.['tableOptions'].columns"
          [value]="column.value"
          (change)="formControl.at(rowIndex).setValue(column.value)"
        ></mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </li>
</ul>
