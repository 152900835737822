import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatusEnum, LpSupportLanguage, Restful } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { LpPlatformApi } from '@stream/models';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, tap } from 'rxjs/operators';

import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private http: HttpClient,
    private ns: NotificationService,
    private clientService: ClientService
  ) {}

  supportLanguage$ = new BehaviorSubject<LpSupportLanguage[]>([]);
  supportLanguage = this.supportLanguage$.asObservable();
  translateSubject$ = new BehaviorSubject<string | null>(null);
  languageList$ = this.clientService.configuration.pipe(
    mergeMap(config => {
      if (!config.isMultiLingual) {
        return of([
          {
            code: 'en-gb',
            title: 'English (UK)',
            flag: './assets/images/flag/en-gb.svg'
          }
        ]);
      }
      return this.getI18nLanguageList().pipe(
        map(res => {
          return (res.data?.data ?? []).map(item => ({
            ...item,
            flag: `./assets/images/flag/${item.code}.svg`
          }));
        }),
        catchError(err => {
          this.ns.error(err.error.message);
          return of([
            {
              code: 'en-gb',
              title: 'English (UK)',
              flag: './assets/images/flag/en-gb.svg'
            }
          ]);
        })
      );
    }),
    shareReplay(1)
  );

  getLanguageList() {
    return this.http
      .get<Restful<{ data: [LpSupportLanguage]; responseStatus: HttpStatusEnum }>>(
        LpPlatformApi.GetSupportLanguage,
        {
          params: {
            prefixValue: window.localStorage.getItem('lp|prefix_value')
              ? JSON.parse(window.localStorage.getItem('lp|prefix_value')!)
              : location.hostname.split('.')[0]
          }
        }
      )
      .pipe(
        tap(result => {
          this.supportLanguage$.next(result.data.data);
        }),
        catchError(err => {
          if (err.error?.status !== HttpStatusEnum.Success) {
            this.ns.error('Failed');
          }
          return throwError(err);
        })
      );
  }

  getI18nLanguageList() {
    return this.http.get<Restful<{ data: [LpSupportLanguage]; responseStatus: HttpStatusEnum }>>(
      LpPlatformApi.GetLanguages
    );
  }
}
