<form [formGroup]="form" class="grid gap-y-6">
  <div class="-mb-5" streamAnchor="General information" [control]="infoForm.form">
    <h2 class="mb-2 text-lg font-semibold">General information</h2>

    <stream-individual-info-form
      #infoForm
      class="grid gap-x-4"
      style="grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr))"
    ></stream-individual-info-form>
  </div>

  <div streamAnchor="Address information" [control]="form.get('address')">
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      Address information
    </h2>
    <stream-drawer-select
      name="address"
      [icon]="config.source === 'LP' ? '#icon-address' : '#icon-dizhi'"
      (add)="openAddress()"
      (edit)="openAddress($event)"
      #addressSelect
      formControlName="address"
      [optionTemplate]="addressTemplate"
      [optionsObservable]="addressList"
      [disableCreate]="reModify"
    >
      <ng-template #addressTemplate let-address>
        <div class="space-y-1 pr-8 md:pr-0">
          <div class="text-base font-semibold text-gray-500">
            <p class="font-bold text-black">
              {{ address.addressLineOne }} {{ address.addressLineTwo }},
            </p>
            <p>
              {{ address.addressTown }} {{ address.addressState
              }}{{ translateCountryName(address.addressCountryCode) }},{{
                address.zipCode
              }}
            </p>
          </div>
          <div class="!-mb-1 flex flex-wrap items-center gap-x-2">
            <stream-data-room-document
              [document]="address.referenceProofAddressFileEntity?.documents"
              [proofType]="'address'"
              class="mb-1"
            ></stream-data-room-document>
            <div
              *ngIf="address.tag"
              class="mb-1 whitespace-normal rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
            >
              {{ getDocTagLabel(address) }}
            </div>
          </div>
        </div>
      </ng-template>
    </stream-drawer-select>
  </div>

  <div streamAnchor="Identity information" [control]="form.get('identity')">
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      Identity information
    </h2>
    <stream-drawer-select
      name="identity"
      [icon]="config.source === 'LP' ? '#icon-identity1' : '#icon-identity'"
      #identitySelect
      (add)="openIdentity()"
      (edit)="openIdentity($event)"
      formControlName="identity"
      [optionTemplate]="identityTemplate"
      [optionsObservable]="identityList"
      [disableCreate]="reModify"
    >
      <ng-template #identityTemplate let-identity>
        <div class="space-y-1 pr-8 md:pr-0">
          <div class="space-x-1 text-base font-semibold capitalize text-gray-500">
            {{ getIdentityInformation(identity.documentType) }}:
            <strong class="text-black">{{ identity.identityNumber }}</strong>
          </div>
          <div class="!-mb-1 flex flex-wrap items-center gap-x-2">
            <stream-data-room-document
              [document]="identity.referenceProofCertificateFileEntity?.documents"
              [proofType]="'identity'"
              class="mb-1"
            ></stream-data-room-document>
            <div
              *ngIf="identity.tag"
              class="mb-1 whitespace-normal rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
            >
              {{ getDocTagLabel(identity) }}
            </div>
          </div>
        </div>
      </ng-template>
    </stream-drawer-select>
  </div>
  <!-- Payments method -->
  <div
    *ngIf="
      (reModify && form.get('payMethod')?.value === 'IRA') ||
        (featureFlagMT && isIRACanEnabled) ||
        (featureFlagMT &&
          config.source === 'LP' &&
          form.get('payMethod')?.value === 'IRA' &&
          isLPMember);
      else bankInformation
    "
  >
    <div
      streamAnchor="Payments"
      [control]="
        form.get('payMethod')?.value === 'BANK'
          ? form.get('bank')
          : form.get('payMethod')?.value === 'IRA'
            ? form.get('ira')
            : null
      "
    >
      <h2 class="mb-2 text-lg font-semibold">
        <span class="text-red-500">*</span>
        Payment method
      </h2>
      <!-- options -->
      <mat-radio-group
        formControlName="payMethod"
        class="actual-situation-radio-group"
        *ngIf="(isIRACanEnabled && !isLinkCustodian) || isLPMember"
        (change)="handlePaymentMethodChange()"
      >
        <mat-radio-button
          class="w-1/2 pr-1 mb-2 banktype-responsive-width"
          [value]="IPayMethodType.BANK"
          [disabled]="
            reModify ||
            (config.source === 'LP' &&
              (isLinkCustodian ||
                (form.get('payMethod')?.value === 'IRA' && isLPMember))) ||
            (config.source === 'GP' && form.get('payMethod')?.value === 'IRA')
          "
        >
          Bank account
        </mat-radio-button>
        <mat-radio-button
          class="w-1/2 pl-1 mb-2 banktype-responsive-width"
          [value]="IPayMethodType.IRA"
          [disabled]="
            reModify ||
            isLinkCustodian ||
            config.source === 'GP' ||
            (config.source === 'LP' &&
              form.get('payMethod')?.value === 'IRA' &&
              isLPMember)
          "
        >
          Inspira Financial IRA (formerly known as Millennium Trust)
        </mat-radio-button>
      </mat-radio-group>
      <stream-drawer-select
        [ngStyle]="{
          display: form.get('payMethod')?.value === 'BANK' ? 'block' : 'none'
        }"
        name="bank"
        icon="#icon-bank"
        #bankSelect
        (add)="openBank()"
        (edit)="openBank($event)"
        formControlName="bank"
        [optionTemplate]="bankTemplate"
        [optionsObservable]="bankList"
        [disableCreate]="reModify"
      >
        <ng-template #bankTemplate let-bank>
          <div class="space-y-1 pr-8 md:pr-0">
            <div class="text-base font-semibold text-gray-500">
              {{ bank.bankName }}
              <strong class="text-black">{{
                bank.bankAccountNumber | bankNumber: true
              }}</strong>
            </div>
            <div class="!-mb-1 flex flex-wrap items-center gap-x-2">
              <stream-data-room-document
                [document]="bank.referenceProofBankFileEntity?.documents"
                [proofType]="'bank'"
                class="mb-1"
              ></stream-data-room-document>
              <div
                *ngIf="bank.tag"
                class="mb-1 whitespace-normal rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
              >
                {{ getDocTagLabel(bank) }}
              </div>
            </div>
          </div>
        </ng-template>
      </stream-drawer-select>
      <stream-drawer-select
        [ngStyle]="{
          display: form.get('payMethod')?.value === 'IRA' ? 'block' : 'none'
        }"
        nameSelector="Link IRA"
        nameDrawerTitle="IRA"
        nameDrawerAdd="Link a new IRA"
        [totalLimitForAdd]="1"
        icon="#icon-identity1"
        [showEdit]="false"
        #iraSelect
        (add)="openIRA()"
        formControlName="ira"
        [optionTemplate]="iraTemplate"
        [optionsObservable]="iraList"
        [disableCreate]="reModify"
        [disabledSelect]="
          reModify ||
          isLinkCustodian ||
          config.source === 'GP' ||
          !profileId.value ||
          (config.source === 'LP' && form.get('payMethod')?.value === 'IRA' && isLPMember)
        "
      >
        <ng-template #iraTemplate let-ira>
          <div class="space-y-1 pr-8 md:pr-0">
            <div
              class="flex flex-wrap flex-row justify-start items-center text-base font-semibold text-[#101213]"
            >
              <div class="mr-1">{{ ira.firstName }} {{ ira.lastName }}</div>
              <div class="whitespace-normal rounded bg-[#F6FFED] px-2 py-0.5">
                {{ labelAccountType }}
              </div>
            </div>
            <div
              class="flex flex-wrap flex-row justify-start items-center text-base font-semibold text-[#62656C]"
            >
              <div class="mr-2">*******{{ ira.mtAccountNumberLast4 }}</div>
              <div> Inspira Financial </div>
            </div>
          </div>
        </ng-template>
      </stream-drawer-select>
    </div>
  </div>
  <ng-template #bankInformation>
    <div streamAnchor="Bank information" [control]="form.get('bank')">
      <h2 class="mb-2 text-lg font-semibold">
        <span class="text-red-500">*</span>
        Bank information
      </h2>
      <stream-drawer-select
        name="bank"
        icon="#icon-bank"
        #bankSelect
        (add)="openBank()"
        (edit)="openBank($event)"
        formControlName="bank"
        [optionTemplate]="bankTemplate"
        [optionsObservable]="bankList"
        [disableCreate]="reModify"
      >
        <ng-template #bankTemplate let-bank>
          <div class="space-y-1 pr-8 md:pr-0">
            <div class="text-base font-semibold text-gray-500">
              {{ bank.bankName }}
              <strong class="text-black">{{
                bank.bankAccountNumber | bankNumber: true
              }}</strong>
            </div>
            <div class="!-mb-1 flex flex-wrap items-center gap-x-2">
              <stream-data-room-document
                [document]="bank.referenceProofBankFileEntity?.documents"
                [proofType]="'bank'"
                class="mb-1"
              ></stream-data-room-document>
              <div
                *ngIf="bank.tag"
                class="mb-1 whitespace-normal rounded bg-[#fff9eb] px-2 py-0.5 text-[#101213]"
              >
                {{ getDocTagLabel(bank) }}
              </div>
            </div>
          </div>
        </ng-template>
      </stream-drawer-select>
    </div>
  </ng-template>
  <!-- Payments method END-->

  <div
    *ngIf="_isShowTaxStatus"
    streamAnchor="Tax status"
    [control]="form.get('actualSituationType')"
  >
    <h2 class="mb-2 text-lg font-semibold">
      <span class="text-red-500">*</span>
      Tax Reporting
    </h2>
    <h6 class="mb-2 text-base font-normal">
      Please select according to the actual situation and fill in as the case may be
    </h6>
    <mat-radio-group
      formControlName="actualSituationType"
      class="actual-situation-radio-group space-y-3"
    >
      <mat-radio-button [value]="ActualSituationType.UscUStax" class="w-full">
        I confirm that I am a U.S. citizen and/or resident in the U.S. for tax purposes
        (green card holder or resident under the substantial presence test)
      </mat-radio-button>
      <mat-radio-button [value]="ActualSituationType.BornUSNotUSC" class="w-full">
        I confirm that I was born in the U.S. (or a U.S. territory) but am no longer a
        U.S. citizen as I have voluntarily surrendered my citizenship.
      </mat-radio-button>
      <mat-radio-button [value]="ActualSituationType.NotUSC" class="w-full">
        I confirm that I am not a U.S. citizen or resident in the U.S. for tax purposes.
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!--- LP -->
  <stream-additional-document-form
    *ngIf="profileId?.value && config.source === 'LP' && !reModify"
    [profileId]="profileId.value || ''"
  ></stream-additional-document-form>

  <!-- GP -->
  <div *ngIf="additionalDocument?.length && config.source === 'GP' && !reModify">
    <h2 class="mb-2 text-lg font-semibold">Additional document</h2>
    <stream-additional-document
      [additionalDoc]="additionalDocument"
      [profileId]="profileId.value || ''"
    ></stream-additional-document>
  </div>

  <footer
    class="fixed bottom-0 left-0 grid w-full grid-cols-2 justify-around gap-x-2 bg-white p-6 md:flex md:justify-end md:space-x-2"
    style="z-index: 1"
  >
    <button
      mat-stroked-button
      color="primary"
      (click)="goback()"
      type="button"
      class="mat-button-lg w-full !px-14 md:w-auto"
    >
      <span class="font-semibold"> Back </span>
    </button>
    <button
      mat-flat-button
      type="button"
      color="primary"
      class="mat-button-lg !px-14"
      [disabled]="panelService.saving | async"
      (click)="submit()"
    >
      Save
    </button>
  </footer>
</form>
