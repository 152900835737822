import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Custodian, Entity, ProfileType } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { finalize } from 'rxjs/operators';

import { ProfileService } from '../../services/profile.service';
import { CertifyDocumentsToLinkIRADialogComponent } from '../certify-documents-to-link-ira-dialog/certify-documents-to-link-ira-dialog.component';

@Component({
  selector: 'stream-ira-information-dialog',
  templateUrl: './ira-information-dialog.component.html',
  styleUrls: ['./ira-information-dialog.component.scss'],
})
export class IRAInformationDialogComponent {
  constructor(
    private notification: NotificationService,
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<IRAInformationDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      profileId: string;
      investorProfileType?: ProfileType;
      referenceInvestorProfileMetaEntityId?: Entity['id'];
      tenantId: string;
      ira?: Custodian;
      reModify?: boolean;
      completeCertifyDocumentsToLinkIRA?: (res: unknown) => void;
    },
  ) {
    this.form.patchValue(data.ira ?? {});
  }

  get contentH(): string {
    return `calc(${window?.innerHeight - 161}px - 20vh)`;
  }

  loading = false;

  form = this.fb.group({
    taxType: ['', [Validators.required]],
    taxCode: ['', [Validators.required, Validators.maxLength(20)]],
    // type: ['', [Validators.required]], // IRA type
    mtAccountNumberLast4: [
      '',
      [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
    ],
  });

  taxIDTypeList = [
    {
      label: 'SSN',
      value: 'SSN',
    },
    {
      label: 'ITIN',
      value: 'ITIN',
    },
  ];

  iraTypeList = [
    {
      label: 'Traditional',
      value: 'TRADITIONAL_IRA',
    },
    {
      label: 'Roth',
      value: 'ROTH_IRA',
    },
  ];

  handleSaveIRASubmit() {
    if (this.loading) return;
    if (this.form.invalid) return;

    // const { type } = this.form.value;

    this.loading = true;

    this.profileService
      .getMTDocumentList({
        // type,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        res => {
          this.dialogRef.close(true);

          const dialogCertifyDocumentsToLinkIRARef = this.dialog.open(
            CertifyDocumentsToLinkIRADialogComponent,
            {
              data: {
                profileId: this.data.profileId,
                resDocumentList: res.data,
                resIRAInformation: this.form.value,
              },
              panelClass: 'full-screen-dialog',
              width: '100vw',
              maxWidth: '100vw',
              height: '100vh',
              maxHeight: '100vh',
            },
          );

          dialogCertifyDocumentsToLinkIRARef.afterClosed().subscribe(res => {
            this.data.completeCertifyDocumentsToLinkIRA?.(res);
          });
        },
        () => {
          this.notification.error('There seems to be a little problem.');
        },
      );
  }
}
