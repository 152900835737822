import { Component } from '@angular/core';

@Component({
  template: `
    <div class="!flex items-center" mat-dialog-title>
      <h1 class="text-xl font-semibold flex-1">Save and Exit</h1>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div mat-dialog-content class="!mb-4">
      <p>Are you sure you would like to save and exit?</p>
      <p>You can pick up where you leave off by clicking invest next time.</p>
    </div>

    <div
      mat-dialog-actions
      class="grid grid-cols-2 justify-around md:flex md:justify-end"
    >
      <button
        mat-stroked-button
        color="primary"
        class="flex-1 !px-14 md:flex-none"
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="flex-1 !px-14 md:flex-none"
        cdkFocusInitial
        [mat-dialog-close]="true"
      >
        Confirm
      </button>
    </div>
  `,
})
export class SaveAndExitConfirmDialogComponent {}
