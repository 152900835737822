import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessErrorResponse } from '@stream/core';
import { ScenarioService } from '@stream/libs/common';
import {
  ActiveAccountStatusEnum,
  AuthStoreKeyEnum,
  AuthType,
  EmailDelayKeyEnum,
  FactorType,
  Gateway,
  GatewayTempTokenStatusEnum,
  InvestAccount,
  LOGIN_ERROR_MESSAGE,
  LoginInfo,
  LoginStatusEnum,
  LpAccountApi,
  MFAStatusEnum,
  Principal,
  ReCaptchaPayload,
  REGISTER_ERROR_MESSAGE,
  RegisterInfo,
  RegisterStatusEnum,
  Restful,
  ScenarioApi,
  ScenarioType,
  SendRegisterEmailStatusEnum,
  SetPasswordStatusEnum,
  VerifyActiveAccountStatusEnum,
  VerifyForgetPasswordStatusEnum
} from '@stream/models';
import { NotificationService, SendEmail } from '@stream/ngx-utils';
import { LpAuthApi, SendEmailApi } from '@stream/src/common';
import { LocalStorageService, SessionStorage } from 'ngx-webstorage';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { PermissionService } from '@stream/libs/common/utils/service';
import { AccountService } from './account.service';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private clientService: ClientService,
    private scenarioService: ScenarioService,
    private ns: NotificationService,
    private localStorageService: LocalStorageService,
    private accountService: AccountService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  @SessionStorage(AuthStoreKeyEnum.TempToken, null)
  tempToken!: string;
  welcomeVisible?: boolean = true;

  gatewayInstanceList$ = new BehaviorSubject<Gateway['gatewayInstanceDataList'] | null>(null);

  gatewayInstanceList = this.gatewayInstanceList$.asObservable();

  @SessionStorage(AuthStoreKeyEnum.RegisterInfo, null)
  registerInfo!: RegisterInfo;

  @SessionStorage(AuthStoreKeyEnum.ForgetPasswordEmail, null)
  forgetPasswordEmail!: string;

  loginHandler({ accessToken, refreshToken }: LoginInfo['tokenData']) {
    this.localStorageService.store(AuthStoreKeyEnum.AccessToken, accessToken);
    this.localStorageService.store(AuthStoreKeyEnum.RefreshToken, refreshToken);
  }

  savePrincipal(principalList: Principal[]) {
    if (principalList.length) {
      this.localStorageService.store(
        AuthStoreKeyEnum.PrincipalToken,
        principalList[0]['principalToken']
      );
    }

    this.accountService.principal$.next(
      principalList.map(({ type, ...principal }) => ({
        ...principal,
        principalType: type || principal.principalType
      }))
    );
  }

  saveInviteNotification(principalList: Principal[], memberCount: number) {
    const _principal = principalList[0];

    this.permissionService.getFeatures().subscribe((features: any) => {
      if (features['TEAM_MEMBERS']?.display) {
        if (
          _principal.type === 'individual' &&
          _principal.kycStatus === 'SUCCESS' &&
          memberCount <= 1
        ) {
          if (_principal.screeningStatus === 'SUCCESS') {
            this.accountService.userInfo.subscribe(res => {
              if (this.retrieveNotificationRecord(res.investAccount.id) === false) {
                return;
              }
              this.storeNotificationRecord(true);
            });

            return;
          }

          this.scenarioService.getScenarioProgress(ScenarioType.Screening).subscribe(res => {
            if (res.data.scenarioProgressResult === 'BREAK') {
              this.accountService.userInfo.subscribe(res => {
                if (this.retrieveNotificationRecord(res.investAccount.id) === false) {
                  return;
                }
                this.storeNotificationRecord(true);
              });
            }
          });

          return;
        }

        this.localStorageService.clear(AuthStoreKeyEnum.InviteNotification);
      }
    });
  }

  // without record value, it will clear the record
  storeNotificationRecord(record?: boolean) {
    let currentRecord =
      this.localStorageService.retrieve(AuthStoreKeyEnum.InviteNotification) || {};
    this.accountService.userInfo.subscribe(res => {
      if (record || record === false) {
        currentRecord[res.investAccount.id] = record;
      } else {
        delete currentRecord[res.investAccount.id];
      }
      this.localStorageService.store(AuthStoreKeyEnum.InviteNotification, currentRecord);
    });
  }

  retrieveNotificationRecord(accountId: string) {
    const currentRecord = this.localStorageService.retrieve(AuthStoreKeyEnum.InviteNotification);
    return accountId && currentRecord ? currentRecord[accountId] : null;
  }

  /** 发送注册邮件 */
  @SendEmail(EmailDelayKeyEnum.SignUp)
  sendRegisterEmail(
    registerInfo: RegisterInfo,
    { reCaptchaResponse, reCaptchaVersion }: ReCaptchaPayload
  ) {
    return this.clientService.configuration.pipe(
      switchMap(({ tenantId }) =>
        this.http.post<Restful<{ status: SendRegisterEmailStatusEnum }>>(
          SendEmailApi.VerifyRegister,
          { ...registerInfo, tenantId, reCaptchaResponse, reCaptchaVersion }
        )
      ),
      tap(() => (this.registerInfo = registerInfo)),
      catchError(err => {
        const { error } = err;
        if (error.status === RegisterStatusEnum.ReCaptchaVerifyFailed) {
          return throwError(err);
        }
        if (err instanceof BusinessErrorResponse) {
          this.ns.error(REGISTER_ERROR_MESSAGE.get(err.error.status) || '');
        }
        return throwError(err);
      })
    );
  }

  /**
   * 发送忘记密码邮件
   * @param email 📮
   */
  @SendEmail(EmailDelayKeyEnum.ForgetPassword)
  sendForgetPasswordEmail(email: InvestAccount['email']) {
    return this.clientService.configuration.pipe(
      switchMap(({ tenantId }) =>
        this.http.post<Restful<{ status: SendRegisterEmailStatusEnum }>>(
          SendEmailApi.ForgetPassword,
          { email, tenantId }
        )
      ),
      tap(() => {
        this.forgetPasswordEmail = email;
      }),
      catchError(err => {
        if (err instanceof BusinessErrorResponse) {
          this.ns.error(LOGIN_ERROR_MESSAGE.get(err.error.status) || '');
        }
        return throwError(err);
      })
    );
  }

  /**
   * 根据tempToken获取完善信息场景结构
   */
  getScenarioByTempToken() {
    return this.http
      .post<
        Restful<{
          scenarioData: Gateway;
          scenarioResult: boolean;
          scenarioResultDescription: string;
          status: GatewayTempTokenStatusEnum;
        }>
      >(
        ScenarioApi.GetScenarioByTempToken,
        {},
        {
          headers: {
            TempToken: this.tempToken
          }
        }
      )
      .pipe(
        tap(
          ({
            data: {
              scenarioData: { gatewayInstanceDataList }
            }
          }) => {
            this.gatewayInstanceList$.next(gatewayInstanceDataList);
          }
        )
      );
  }

  /**
   * 提交完善信息场景数据
   * @param model
   */
  submitCompleteProfile(model: Record<string, unknown>) {
    return this.gatewayInstanceList.pipe(
      take(1),
      map(v => v as Gateway['gatewayInstanceDataList']),
      switchMap(
        ([
          {
            biteFlowNodeActionFormGroupList: [{ id: actionFormGroupId }]
          }
        ]) =>
          this.scenarioService.submitActionForm<LoginInfo>(actionFormGroupId, model, {
            headers: { TempToken: this.tempToken }
          })
      )
    );
  }

  /**
   * 注册
   * @param tempToken
   */
  register(tempToken: string) {
    return this.http
      .post<Restful<LoginInfo>>(LpAuthApi.Register, {
        tempToken
      })
      .pipe(
        tap(({ data: { loginStatus, tokenData, scenarioData, investPrincipalVOS } }) => {
          if (loginStatus === LoginStatusEnum.GatewayNotPass) {
            this.handleGatewayNotPass(scenarioData, tempToken);
          }
          if (loginStatus === LoginStatusEnum.Success) {
            this.loginHandler(tokenData);
          }
          this.savePrincipal(investPrincipalVOS);
        })
      );
  }

  private handleGatewayNotPass(scenarioData: Gateway | undefined, tempToken: string) {
    this.gatewayInstanceList$.next(scenarioData?.gatewayInstanceDataList ?? null);
    this.tempToken = tempToken || '';
  }

  /**
   * 校验激活账号tempToken
   * @param verifyCrmTempTokenRequest
   * @returns
   */
  verifyActiveAccountTempToken(verifyCrmTempTokenRequest: {
    tempToken: string;
    code: string;
    principalId?: string;
  }) {
    return this.http.post<Restful<{ status: VerifyActiveAccountStatusEnum }>>(
      LpAuthApi.VerifyActiveAccountTempToken,
      verifyCrmTempTokenRequest
    );
  }

  /**
   * 激活账号
   * @param activeData
   * @returns
   */
  activeAccount(
    activeData: {
      tempToken: string;
      code: string;
      principalId?: string;
      investEmailInvitation: string;
    },
    investAccount: RegisterInfo
  ) {
    return this.clientService.configuration.pipe(
      take(1),
      switchMap(({ tenantId }) =>
        this.http.post<Restful<{ status: ActiveAccountStatusEnum }>>(LpAuthApi.ActiveAccount, {
          ...activeData,
          investAccount,
          tenantId
        })
      )
    );
  }

  /**
   * 密码登录
   * @param email 邮箱
   * @param password 密码
   */
  login(
    { email, password }: Pick<InvestAccount, 'email' | 'password'>,
    { reCaptchaResponse, reCaptchaVersion }: ReCaptchaPayload
  ) {
    return this.clientService.configuration.pipe(
      switchMap(({ tenantId }) =>
        this.http
          .post<Restful<LoginInfo>>(LpAuthApi.Login, {
            email,
            password,
            tenantId,
            reCaptchaResponse,
            reCaptchaVersion
          })
          .pipe(
            catchError(err => {
              if (err?.error?.code === 'B-00035') {
                this.router.navigate(['/domain-not-operational']);
              }
              if (err.error.investPrincipalVOS) {
                this.savePrincipal(err.error.investPrincipalVOS);
              }
              if (err instanceof BusinessErrorResponse) {
                const { status = '', scenarioData = null, tempToken = '' } = err.error;
                if (status === LoginStatusEnum.GatewayNotPass) {
                  this.handleGatewayNotPass(scenarioData, tempToken);
                } else if (
                  status !== LoginStatusEnum.ReCaptchaVerifyFailed &&
                  status !== LoginStatusEnum.RequireMFA
                ) {
                  this.ns.error(LOGIN_ERROR_MESSAGE.get(status) || 'Unknown error');
                }
              }
              return throwError(err);
            }),
            tap(({ data: { tokenData, investPrincipalVOS, memberCount } }) => {
              if (tokenData) {
                this.loginHandler(tokenData);
              }
              this.savePrincipal(investPrincipalVOS);
              this.saveInviteNotification(investPrincipalVOS, memberCount || 0);
            })
          )
      )
    );
  }

  /**
   * 检验忘记密码tempToken
   * @param tempToken
   */
  verifyForgetPasswordTempToken(tempToken: string) {
    return this.http.post<Restful<{ status: VerifyForgetPasswordStatusEnum }>>(
      LpAuthApi.VerifyForgetPasswordTempToken,
      {
        tempToken
      }
    );
  }

  /**
   * 重设密码
   * @param tempToken 临时token
   * @param password 新密码
   * @returns
   */
  resetPassword(tempToken: string, password: InvestAccount['password']) {
    return this.http
      .post<Restful<{ status: SetPasswordStatusEnum }>>(LpAccountApi.SetPassword, {
        tempToken,
        password
      })
      .pipe(
        tap(({ data: { status } }) => {
          if (status === SetPasswordStatusEnum.Success) {
            this.ns.success('Password successfully updated.');
          }
        })
      );
  }

  session(type: AuthType, request: { mfaCode?: string; mfaTicket?: string; mfaType?: FactorType }) {
    return this.http
      .post<Restful<LoginInfo>>(LpAuthApi.Session, {
        authType: type,
        ...request
      })
      .pipe(
        catchError(err => {
          if (err.error.investPrincipalVOS) {
            this.savePrincipal(err.error.investPrincipalVOS);
          }
          if (err instanceof BusinessErrorResponse) {
            const { status = '', scenarioData = null, tempToken = '' } = err.error;
            if (status === LoginStatusEnum.GatewayNotPass) {
              this.handleGatewayNotPass(scenarioData, tempToken);
            } else if (
              status !== LoginStatusEnum.ReCaptchaVerifyFailed &&
              status !== LoginStatusEnum.RequireMFA &&
              status !== MFAStatusEnum.MFAAuthFailed
            ) {
              this.ns.error(LOGIN_ERROR_MESSAGE.get(status) || 'Unknown error');
            }
          }
          return throwError(err);
        }),
        tap(({ data: { tokenData, investPrincipalVOS } }) => {
          if (tokenData) {
            this.loginHandler(tokenData);
          }
          this.savePrincipal(investPrincipalVOS);
        })
      );
  }
}
