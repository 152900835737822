export const AREA_CODE_SOURCE = [
  {
    countryCode: 'AF',
    dialCode: '93',
    currencySymbol: 'AF',
    name: 'Afghanistan',
    currency: 'AFG',
  },
  {
    countryCode: 'AL',
    dialCode: '355',
    currencySymbol: 'AL',
    name: 'Albania',
    currency: 'ALB',
  },
  {
    countryCode: 'DZ',
    dialCode: '213',
    currencySymbol: 'DZ',
    name: 'Algeria',
    currency: 'DZA',
  },
  {
    countryCode: 'AS',
    dialCode: '1684',
    currencySymbol: 'AS',
    name: 'American Samoa',
    currency: 'ASM',
  },
  {
    countryCode: 'AD',
    dialCode: '376',
    currencySymbol: 'AD',
    name: 'Andorra',
    currency: 'AND',
  },
  {
    countryCode: 'AO',
    dialCode: '244',
    currencySymbol: 'AO',
    name: 'Angola',
    currency: 'AGO',
  },
  {
    countryCode: 'AI',
    dialCode: '1264',
    currencySymbol: 'AI',
    name: 'Anguilla',
    currency: 'AIA',
  },
  {
    countryCode: 'AQ',
    dialCode: '672',
    currencySymbol: 'AQ',
    name: 'Antarctica',
    currency: 'ATA',
  },
  {
    countryCode: 'AG',
    dialCode: '1268',
    currencySymbol: 'AG',
    name: 'Antigua and Barbuda',
    currency: 'ATG',
  },
  {
    countryCode: 'AR',
    dialCode: '54',
    currencySymbol: 'AR',
    name: 'Argentina',
    currency: 'ARG',
  },
  {
    countryCode: 'AM',
    dialCode: '374',
    currencySymbol: 'AM',
    name: 'Armenia',
    currency: 'ARM',
  },
  {
    countryCode: 'AW',
    dialCode: '297',
    currencySymbol: 'AW',
    name: 'Aruba',
    currency: 'ABW',
  },
  {
    countryCode: 'AU',
    dialCode: '61',
    currencySymbol: 'AU',
    name: 'Australia',
    currency: 'AUS',
  },
  {
    countryCode: 'AT',
    dialCode: '43',
    currencySymbol: 'AT',
    name: 'Austria',
    currency: 'AUT',
  },
  {
    countryCode: 'AZ',
    dialCode: '994',
    currencySymbol: 'AZ',
    name: 'Azerbaijan',
    currency: 'AZE',
  },
  {
    countryCode: 'BS',
    dialCode: '1242',
    currencySymbol: 'BS',
    name: 'Bahamas',
    currency: 'BHS',
  },
  {
    countryCode: 'BH',
    dialCode: '973',
    currencySymbol: 'BH',
    name: 'Bahrain',
    currency: 'BHR',
  },
  {
    countryCode: 'BD',
    dialCode: '880',
    currencySymbol: 'BD',
    name: 'Bangladesh',
    currency: 'BGD',
  },
  {
    countryCode: 'BB',
    dialCode: '1246',
    currencySymbol: 'BB',
    name: 'Barbados',
    currency: 'BRB',
  },
  {
    countryCode: 'BY',
    dialCode: '375',
    currencySymbol: 'BY',
    name: 'Belarus',
    currency: 'BLR',
  },
  {
    countryCode: 'BE',
    dialCode: '32',
    currencySymbol: 'BE',
    name: 'Belgium',
    currency: 'BEL',
  },
  {
    countryCode: 'BZ',
    dialCode: '501',
    currencySymbol: 'BZ',
    name: 'Belize',
    currency: 'BLZ',
  },
  {
    countryCode: 'BJ',
    dialCode: '229',
    currencySymbol: 'BJ',
    name: 'Benin',
    currency: 'BEN',
  },
  {
    countryCode: 'BM',
    dialCode: '1441',
    currencySymbol: 'BM',
    name: 'Bermuda',
    currency: 'BMU',
  },
  {
    countryCode: 'BT',
    dialCode: '975',
    currencySymbol: 'BT',
    name: 'Bhutan',
    currency: 'BTN',
  },
  {
    countryCode: 'BO',
    dialCode: '591',
    currencySymbol: 'BO',
    name: 'Bolivia',
    currency: 'BOL',
  },
  {
    countryCode: 'BQ',
    dialCode: '599',
    currencySymbol: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
    currency: 'BES',
  },
  {
    countryCode: 'BA',
    dialCode: '387',
    currencySymbol: 'BA',
    name: 'Bosnia and Herzegovina',
    currency: 'BIH',
  },
  {
    countryCode: 'BW',
    dialCode: '267',
    currencySymbol: 'BW',
    name: 'Botswana',
    currency: 'BWA',
  },
  {
    countryCode: 'BV',
    dialCode: '47',
    currencySymbol: 'BV',
    name: 'Bouvet Island',
    currency: 'BVT',
  },
  {
    countryCode: 'BR',
    dialCode: '55',
    currencySymbol: 'BR',
    name: 'Brazil',
    currency: 'BRA',
  },
  {
    countryCode: 'IO',
    dialCode: '246',
    currencySymbol: 'IO',
    name: 'British Indian Ocean Territory',
    currency: 'IOT',
  },
  {
    countryCode: 'BN',
    dialCode: '673',
    currencySymbol: 'BN',
    name: 'Brunei Darussalam',
    currency: 'BRN',
  },
  {
    countryCode: 'BG',
    dialCode: '359',
    currencySymbol: 'BG',
    name: 'Bulgaria',
    currency: 'BGR',
  },
  {
    countryCode: 'BF',
    dialCode: '226',
    currencySymbol: 'BF',
    name: 'Burkina Faso',
    currency: 'BFA',
  },
  {
    countryCode: 'BI',
    dialCode: '257',
    currencySymbol: 'BI',
    name: 'Burundi',
    currency: 'BDI',
  },
  {
    countryCode: 'CV',
    dialCode: '238',
    currencySymbol: 'CV',
    name: 'Cabo Verde',
    currency: 'CPV',
  },
  {
    countryCode: 'KH',
    dialCode: '855',
    currencySymbol: 'KH',
    name: 'Cambodia',
    currency: 'KHM',
  },
  {
    countryCode: 'CM',
    dialCode: '237',
    currencySymbol: 'CM',
    name: 'Cameroon',
    currency: 'CMR',
  },
  {
    countryCode: 'CA',
    dialCode: '1',
    currencySymbol: 'CA',
    name: 'Canada',
    currency: 'CAN',
  },
  {
    countryCode: 'KY',
    dialCode: '1345',
    currencySymbol: 'KY',
    name: 'Cayman Islands',
    currency: 'CYM',
  },
  {
    countryCode: 'CF',
    dialCode: '236',
    currencySymbol: 'CF',
    name: 'Central African Republic',
    currency: 'CAF',
  },
  {
    countryCode: 'TD',
    dialCode: '235',
    currencySymbol: 'TD',
    name: 'Chad',
    currency: 'TCD',
  },
  {
    countryCode: 'CL',
    dialCode: '56',
    currencySymbol: 'CL',
    name: 'Chile',
    currency: 'CHL',
  },
  {
    countryCode: 'CN',
    dialCode: '86',
    currencySymbol: 'CN',
    name: 'China',
    currency: 'CHN',
  },
  {
    countryCode: 'CX',
    dialCode: '61',
    currencySymbol: 'CX',
    name: 'Christmas Island',
    currency: 'CXR',
  },
  {
    countryCode: 'CC',
    dialCode: '61',
    currencySymbol: 'CC',
    name: 'Cocos (Keeling) Islands',
    currency: 'CCK',
  },
  {
    countryCode: 'CO',
    dialCode: '57',
    currencySymbol: 'CO',
    name: 'Colombia',
    currency: 'COL',
  },
  {
    countryCode: 'KM',
    dialCode: '269',
    currencySymbol: 'KM',
    name: 'Comoros',
    currency: 'COM',
  },
  {
    countryCode: 'CD',
    dialCode: '243',
    currencySymbol: 'CD',
    name: 'Congo (Democratic Republic of)',
    currency: 'COD',
  },
  {
    countryCode: 'CG',
    dialCode: '242',
    currencySymbol: 'CG',
    name: 'Congo (the)',
    currency: 'COG',
  },
  {
    countryCode: 'CK',
    dialCode: '682',
    currencySymbol: 'CK',
    name: 'Cook Islands',
    currency: 'COK',
  },
  {
    countryCode: 'CR',
    dialCode: '506',
    currencySymbol: 'CR',
    name: 'Costa Rica',
    currency: 'CRI',
  },
  {
    countryCode: 'HR',
    dialCode: '385',
    currencySymbol: 'HR',
    name: 'Croatia',
    currency: 'HRV',
  },
  {
    countryCode: 'CU',
    dialCode: '53',
    currencySymbol: 'CU',
    name: 'Cuba',
    currency: 'CUB',
  },
  {
    countryCode: 'CW',
    dialCode: '5999',
    currencySymbol: 'CW',
    name: 'Curaçao',
    currency: 'CUW',
  },
  {
    countryCode: 'CY',
    dialCode: '357',
    currencySymbol: 'CY',
    name: 'Cyprus',
    currency: 'CYP',
  },
  {
    countryCode: 'CZ',
    dialCode: '420',
    currencySymbol: 'CZ',
    name: 'Czechia',
    currency: 'CZE',
  },
  {
    countryCode: 'CI',
    dialCode: '225',
    currencySymbol: 'CI',
    name: "Côte d'Ivoire",
    currency: 'CIV',
  },
  {
    countryCode: 'DK',
    dialCode: '45',
    currencySymbol: 'DK',
    name: 'Denmark',
    currency: 'DNK',
  },
  {
    countryCode: 'DJ',
    dialCode: '253',
    currencySymbol: 'DJ',
    name: 'Djibouti',
    currency: 'DJI',
  },
  {
    countryCode: 'DM',
    dialCode: '1767',
    currencySymbol: 'DM',
    name: 'Dominica',
    currency: 'DMA',
  },
  {
    countryCode: 'DO',
    dialCode: '1809',
    currencySymbol: 'DO',
    name: 'Dominican Republic',
    currency: 'DOM',
  },
  {
    countryCode: 'EC',
    dialCode: '593',
    currencySymbol: 'EC',
    name: 'Ecuador',
    currency: 'ECU',
  },
  {
    countryCode: 'EG',
    dialCode: '20',
    currencySymbol: 'EG',
    name: 'Egypt',
    currency: 'EGY',
  },
  {
    countryCode: 'SV',
    dialCode: '503',
    currencySymbol: 'SV',
    name: 'El Salvador',
    currency: 'SLV',
  },
  {
    countryCode: 'GQ',
    dialCode: '240',
    currencySymbol: 'GQ',
    name: 'Equatorial Guinea',
    currency: 'GNQ',
  },
  {
    countryCode: 'ER',
    dialCode: '291',
    currencySymbol: 'ER',
    name: 'Eritrea',
    currency: 'ERI',
  },
  {
    countryCode: 'EE',
    dialCode: '372',
    currencySymbol: 'EE',
    name: 'Estonia',
    currency: 'EST',
  },
  {
    countryCode: 'SZ',
    dialCode: '268',
    currencySymbol: 'SZ',
    name: 'Eswatini',
    currency: 'SWZ',
  },
  {
    countryCode: 'ET',
    dialCode: '251',
    currencySymbol: 'ET',
    name: 'Ethiopia',
    currency: 'ETH',
  },
  {
    countryCode: 'FK',
    dialCode: '500',
    currencySymbol: 'FK',
    name: 'Falkland Islands',
    currency: 'FLK',
  },
  {
    countryCode: 'FO',
    dialCode: '298',
    currencySymbol: 'FO',
    name: 'Faroe Islands',
    currency: 'FRO',
  },
  {
    countryCode: 'FJ',
    dialCode: '679',
    currencySymbol: 'FJ',
    name: 'Fiji',
    currency: 'FJI',
  },
  {
    countryCode: 'FI',
    dialCode: '358',
    currencySymbol: 'FI',
    name: 'Finland',
    currency: 'FIN',
  },
  {
    countryCode: 'FR',
    dialCode: '33',
    currencySymbol: 'FR',
    name: 'France',
    currency: 'FRA',
  },
  {
    countryCode: 'GF',
    dialCode: '594',
    currencySymbol: 'GF',
    name: 'French Guiana',
    currency: 'GUF',
  },
  {
    countryCode: 'PF',
    dialCode: '689',
    currencySymbol: 'PF',
    name: 'French Polynesia',
    currency: 'PYF',
  },
  {
    countryCode: 'TF',
    dialCode: '262',
    currencySymbol: 'TF',
    name: 'French Southern Territories',
    currency: 'ATF',
  },
  {
    countryCode: 'GA',
    dialCode: '241',
    currencySymbol: 'GA',
    name: 'Gabon',
    currency: 'GAB',
  },
  {
    countryCode: 'GM',
    dialCode: '220',
    currencySymbol: 'GM',
    name: 'Gambia',
    currency: 'GMB',
  },
  {
    countryCode: 'GE',
    dialCode: '995',
    currencySymbol: 'GE',
    name: 'Georgia',
    currency: 'GEO',
  },
  {
    countryCode: 'DE',
    dialCode: '49',
    currencySymbol: 'DE',
    name: 'Germany',
    currency: 'DEU',
  },
  {
    countryCode: 'GH',
    dialCode: '233',
    currencySymbol: 'GH',
    name: 'Ghana',
    currency: 'GHA',
  },
  {
    countryCode: 'GI',
    dialCode: '350',
    currencySymbol: 'GI',
    name: 'Gibraltar',
    currency: 'GIB',
  },
  {
    countryCode: 'GR',
    dialCode: '30',
    currencySymbol: 'GR',
    name: 'Greece',
    currency: 'GRC',
  },
  {
    countryCode: 'GL',
    dialCode: '299',
    currencySymbol: 'GL',
    name: 'Greenland',
    currency: 'GRL',
  },
  {
    countryCode: 'GD',
    dialCode: '1473',
    currencySymbol: 'GD',
    name: 'Grenada',
    currency: 'GRD',
  },
  {
    countryCode: 'GP',
    dialCode: '590',
    currencySymbol: 'GP',
    name: 'Guadeloupe',
    currency: 'GLP',
  },
  {
    countryCode: 'GU',
    dialCode: '1671',
    currencySymbol: 'GU',
    name: 'Guam',
    currency: 'GUM',
  },
  {
    countryCode: 'GT',
    dialCode: '502',
    currencySymbol: 'GT',
    name: 'Guatemala',
    currency: 'GTM',
  },
  {
    countryCode: 'GG',
    dialCode: '44',
    currencySymbol: 'GG',
    name: 'Guernsey',
    currency: 'GGY',
  },
  {
    countryCode: 'GN',
    dialCode: '224',
    currencySymbol: 'GN',
    name: 'Guinea',
    currency: 'GIN',
  },
  {
    countryCode: 'GW',
    dialCode: '245',
    currencySymbol: 'GW',
    name: 'Guinea-Bissau',
    currency: 'GNB',
  },
  {
    countryCode: 'GY',
    dialCode: '592',
    currencySymbol: 'GY',
    name: 'Guyana',
    currency: 'GUY',
  },
  {
    countryCode: 'HT',
    dialCode: '509',
    currencySymbol: 'HT',
    name: 'Haiti',
    currency: 'HTI',
  },
  {
    countryCode: 'HM',
    dialCode: '672',
    currencySymbol: 'HM',
    name: 'Heard and McDonald Islands',
    currency: 'HMD',
  },
  {
    countryCode: 'VA',
    dialCode: '379',
    currencySymbol: 'VA',
    name: 'Holy See',
    currency: 'VAT',
  },
  {
    countryCode: 'HN',
    dialCode: '504',
    currencySymbol: 'HN',
    name: 'Honduras',
    currency: 'HND',
  },
  {
    countryCode: 'HK',
    dialCode: '852',
    currencySymbol: 'HK',
    name: 'Hong Kong',
    currency: 'HKG',
  },
  {
    countryCode: 'HU',
    dialCode: '36',
    currencySymbol: 'HU',
    name: 'Hungary',
    currency: 'HUN',
  },
  {
    countryCode: 'IS',
    dialCode: '354',
    currencySymbol: 'IS',
    name: 'Iceland',
    currency: 'ISL',
  },
  {
    countryCode: 'IN',
    dialCode: '91',
    currencySymbol: 'IN',
    name: 'India',
    currency: 'IND',
  },
  {
    countryCode: 'ID',
    dialCode: '62',
    currencySymbol: 'ID',
    name: 'Indonesia',
    currency: 'IDN',
  },
  {
    countryCode: 'IR',
    dialCode: '98',
    currencySymbol: 'IR',
    name: 'Iran',
    currency: 'IRN',
  },
  {
    countryCode: 'IQ',
    dialCode: '964',
    currencySymbol: 'IQ',
    name: 'Iraq',
    currency: 'IRQ',
  },
  {
    countryCode: 'IE',
    dialCode: '353',
    currencySymbol: 'IE',
    name: 'Ireland',
    currency: 'IRL',
  },
  {
    countryCode: 'IM',
    dialCode: '44',
    currencySymbol: 'IM',
    name: 'Isle of Man',
    currency: 'IMN',
  },
  {
    countryCode: 'IL',
    dialCode: '972',
    currencySymbol: 'IL',
    name: 'Israel',
    currency: 'ISR',
  },
  {
    countryCode: 'IT',
    dialCode: '39',
    currencySymbol: 'IT',
    name: 'Italy',
    currency: 'ITA',
  },
  {
    countryCode: 'JM',
    dialCode: '1876',
    currencySymbol: 'JM',
    name: 'Jamaica',
    currency: 'JAM',
  },
  {
    countryCode: 'JP',
    dialCode: '81',
    currencySymbol: 'JP',
    name: 'Japan',
    currency: 'JPN',
  },
  {
    countryCode: 'JE',
    dialCode: '44',
    currencySymbol: 'JE',
    name: 'Jersey',
    currency: 'JEY',
  },
  {
    countryCode: 'JO',
    dialCode: '962',
    currencySymbol: 'JO',
    name: 'Jordan',
    currency: 'JOR',
  },
  {
    countryCode: 'KZ',
    dialCode: '7',
    currencySymbol: 'KZ',
    name: 'Kazakhstan',
    currency: 'KAZ',
  },
  {
    countryCode: 'KE',
    dialCode: '254',
    currencySymbol: 'KE',
    name: 'Kenya',
    currency: 'KEN',
  },
  {
    countryCode: 'KI',
    dialCode: '686',
    currencySymbol: 'KI',
    name: 'Kiribati',
    currency: 'KIR',
  },
  {
    countryCode: 'KP',
    dialCode: '850',
    currencySymbol: 'KP',
    name: 'North Korea (DPRK)',
    currency: 'PRK',
  },
  {
    countryCode: 'KR',
    dialCode: '82',
    currencySymbol: 'KR',
    name: 'South Korea (ROK)',
    currency: 'KOR',
  },
  {
    countryCode: 'KW',
    dialCode: '965',
    currencySymbol: 'KW',
    name: 'Kuwait',
    currency: 'KWT',
  },
  {
    countryCode: 'KG',
    dialCode: '996',
    currencySymbol: 'KG',
    name: 'Kyrgyzstan',
    currency: 'KGZ',
  },
  {
    countryCode: 'LA',
    dialCode: '856',
    currencySymbol: 'LA',
    name: 'Laos',
    currency: 'LAO',
  },
  {
    countryCode: 'LV',
    dialCode: '371',
    currencySymbol: 'LV',
    name: 'Latvia',
    currency: 'LVA',
  },
  {
    countryCode: 'LB',
    dialCode: '961',
    currencySymbol: 'LB',
    name: 'Lebanon',
    currency: 'LBN',
  },
  {
    countryCode: 'LS',
    dialCode: '266',
    currencySymbol: 'LS',
    name: 'Lesotho',
    currency: 'LSO',
  },
  {
    countryCode: 'LR',
    dialCode: '231',
    currencySymbol: 'LR',
    name: 'Liberia',
    currency: 'LBR',
  },
  {
    countryCode: 'LY',
    dialCode: '218',
    currencySymbol: 'LY',
    name: 'Libya',
    currency: 'LBY',
  },
  {
    countryCode: 'LI',
    dialCode: '423',
    currencySymbol: 'LI',
    name: 'Liechtenstein',
    currency: 'LIE',
  },
  {
    countryCode: 'LT',
    dialCode: '370',
    currencySymbol: 'LT',
    name: 'Lithuania',
    currency: 'LTU',
  },
  {
    countryCode: 'LU',
    dialCode: '352',
    currencySymbol: 'LU',
    name: 'Luxembourg',
    currency: 'LUX',
  },
  {
    countryCode: 'MO',
    dialCode: '853',
    currencySymbol: 'MO',
    name: 'Macao',
    currency: 'MAC',
  },
  {
    countryCode: 'MG',
    dialCode: '261',
    currencySymbol: 'MG',
    name: 'Madagascar',
    currency: 'MDG',
  },
  {
    countryCode: 'MW',
    dialCode: '265',
    currencySymbol: 'MW',
    name: 'Malawi',
    currency: 'MWI',
  },
  {
    countryCode: 'MY',
    dialCode: '60',
    currencySymbol: 'MY',
    name: 'Malaysia',
    currency: 'MYS',
  },
  {
    countryCode: 'MV',
    dialCode: '960',
    currencySymbol: 'MV',
    name: 'Maldives',
    currency: 'MDV',
  },
  {
    countryCode: 'ML',
    dialCode: '223',
    currencySymbol: 'ML',
    name: 'Mali',
    currency: 'MLI',
  },
  {
    countryCode: 'MT',
    dialCode: '356',
    currencySymbol: 'MT',
    name: 'Malta',
    currency: 'MLT',
  },
  {
    countryCode: 'MH',
    dialCode: '692',
    currencySymbol: 'MH',
    name: 'Marshall Islands',
    currency: 'MHL',
  },
  {
    countryCode: 'MQ',
    dialCode: '596',
    currencySymbol: 'MQ',
    name: 'Martinique',
    currency: 'MTQ',
  },
  {
    countryCode: 'MR',
    dialCode: '222',
    currencySymbol: 'MR',
    name: 'Mauritania',
    currency: 'MRT',
  },
  {
    countryCode: 'MU',
    dialCode: '230',
    currencySymbol: 'MU',
    name: 'Mauritius',
    currency: 'MUS',
  },
  {
    countryCode: 'YT',
    dialCode: '262',
    currencySymbol: 'YT',
    name: 'Mayotte',
    currency: 'MYT',
  },
  {
    countryCode: 'MX',
    dialCode: '52',
    currencySymbol: 'MX',
    name: 'Mexico',
    currency: 'MEX',
  },
  {
    countryCode: 'FM',
    dialCode: '691',
    currencySymbol: 'FM',
    name: 'Micronesia',
    currency: 'FSM',
  },
  {
    countryCode: 'MD',
    dialCode: '373',
    currencySymbol: 'MD',
    name: 'Moldova',
    currency: 'MDA',
  },
  {
    countryCode: 'MC',
    dialCode: '377',
    currencySymbol: 'MC',
    name: 'Monaco',
    currency: 'MCO',
  },
  {
    countryCode: 'MN',
    dialCode: '976',
    currencySymbol: 'MN',
    name: 'Mongolia',
    currency: 'MNG',
  },
  {
    countryCode: 'ME',
    dialCode: '382',
    currencySymbol: 'ME',
    name: 'Montenegro',
    currency: 'MNE',
  },
  {
    countryCode: 'MS',
    dialCode: '1664',
    currencySymbol: 'MS',
    name: 'Montserrat',
    currency: 'MSR',
  },
  {
    countryCode: 'MA',
    dialCode: '212',
    currencySymbol: 'MA',
    name: 'Morocco',
    currency: 'MAR',
  },
  {
    countryCode: 'MZ',
    dialCode: '258',
    currencySymbol: 'MZ',
    name: 'Mozambique',
    currency: 'MOZ',
  },
  {
    countryCode: 'MM',
    dialCode: '95',
    currencySymbol: 'MM',
    name: 'Myanmar',
    currency: 'MMR',
  },
  {
    countryCode: 'NA',
    dialCode: '264',
    currencySymbol: 'NA',
    name: 'Namibia',
    currency: 'NAM',
  },
  {
    countryCode: 'NR',
    dialCode: '674',
    currencySymbol: 'NR',
    name: 'Nauru',
    currency: 'NRU',
  },
  {
    countryCode: 'NP',
    dialCode: '977',
    currencySymbol: 'NP',
    name: 'Nepal',
    currency: 'NPL',
  },
  {
    countryCode: 'NL',
    dialCode: '31',
    currencySymbol: 'NL',
    name: 'Netherlands',
    currency: 'NLD',
  },
  {
    countryCode: 'NC',
    dialCode: '687',
    currencySymbol: 'NC',
    name: 'New Caledonia',
    currency: 'NCL',
  },
  {
    countryCode: 'NZ',
    dialCode: '64',
    currencySymbol: 'NZ',
    name: 'New Zealand',
    currency: 'NZL',
  },
  {
    countryCode: 'NI',
    dialCode: '505',
    currencySymbol: 'NI',
    name: 'Nicaragua',
    currency: 'NIC',
  },
  {
    countryCode: 'NE',
    dialCode: '227',
    currencySymbol: 'NE',
    name: 'Niger',
    currency: 'NER',
  },
  {
    countryCode: 'NG',
    dialCode: '234',
    currencySymbol: 'NG',
    name: 'Nigeria',
    currency: 'NGA',
  },
  {
    countryCode: 'NU',
    dialCode: '683',
    currencySymbol: 'NU',
    name: 'Niue',
    currency: 'NIU',
  },
  {
    countryCode: 'NF',
    dialCode: '672',
    currencySymbol: 'NF',
    name: 'Norfolk Island',
    currency: 'NFK',
  },
  {
    countryCode: 'MP',
    dialCode: '1670',
    currencySymbol: 'MP',
    name: 'Northern Mariana Islands',
    currency: 'MNP',
  },
  {
    countryCode: 'NO',
    dialCode: '47',
    currencySymbol: 'NO',
    name: 'Norway',
    currency: 'NOR',
  },
  {
    countryCode: 'OM',
    dialCode: '968',
    currencySymbol: 'OM',
    name: 'Oman',
    currency: 'OMN',
  },
  {
    countryCode: 'PK',
    dialCode: '92',
    currencySymbol: 'PK',
    name: 'Pakistan',
    currency: 'PAK',
  },
  {
    countryCode: 'PW',
    dialCode: '680',
    currencySymbol: 'PW',
    name: 'Palau',
    currency: 'PLW',
  },
  {
    countryCode: 'PS',
    dialCode: '970',
    currencySymbol: 'PS',
    name: 'Palestine',
    currency: 'PSE',
  },
  {
    countryCode: 'PA',
    dialCode: '507',
    currencySymbol: 'PA',
    name: 'Panama',
    currency: 'PAN',
  },
  {
    countryCode: 'PG',
    dialCode: '675',
    currencySymbol: 'PG',
    name: 'Papua New Guinea',
    currency: 'PNG',
  },
  {
    countryCode: 'PY',
    dialCode: '595',
    currencySymbol: 'PY',
    name: 'Paraguay',
    currency: 'PRY',
  },
  {
    countryCode: 'PE',
    dialCode: '51',
    currencySymbol: 'PE',
    name: 'Peru',
    currency: 'PER',
  },
  {
    countryCode: 'PH',
    dialCode: '63',
    currencySymbol: 'PH',
    name: 'Philippines',
    currency: 'PHL',
  },
  {
    countryCode: 'PN',
    dialCode: '64',
    currencySymbol: 'PN',
    name: 'Pitcairn',
    currency: 'PCN',
  },
  {
    countryCode: 'PL',
    dialCode: '48',
    currencySymbol: 'PL',
    name: 'Poland',
    currency: 'POL',
  },
  {
    countryCode: 'PT',
    dialCode: '351',
    currencySymbol: 'PT',
    name: 'Portugal',
    currency: 'PRT',
  },
  {
    countryCode: 'PR',
    dialCode: '1787',
    currencySymbol: 'PR',
    name: 'Puerto Rico',
    currency: 'PRI',
  },
  {
    countryCode: 'QA',
    dialCode: '974',
    currencySymbol: 'QA',
    name: 'Qatar',
    currency: 'QAT',
  },
  {
    countryCode: 'MK',
    dialCode: '389',
    currencySymbol: 'MK',
    name: 'North Macedonia',
    currency: 'MKD',
  },
  {
    countryCode: 'RO',
    dialCode: '40',
    currencySymbol: 'RO',
    name: 'Romania',
    currency: 'ROU',
  },
  {
    countryCode: 'RU',
    dialCode: '7',
    currencySymbol: 'RU',
    name: 'Russian Federation',
    currency: 'RUS',
  },
  {
    countryCode: 'RW',
    dialCode: '250',
    currencySymbol: 'RW',
    name: 'Rwanda',
    currency: 'RWA',
  },
  {
    countryCode: 'RE',
    dialCode: '262',
    currencySymbol: 'RE',
    name: 'Réunion',
    currency: 'REU',
  },
  {
    countryCode: 'BL',
    dialCode: '590',
    currencySymbol: 'BL',
    name: 'Saint Barthélemy',
    currency: 'BLM',
  },
  {
    countryCode: 'SH',
    dialCode: '290',
    currencySymbol: 'SH',
    name: 'Saint Helena and Islands',
    currency: 'SHN',
  },
  {
    countryCode: 'KN',
    dialCode: '1869',
    currencySymbol: 'KN',
    name: 'Saint Kitts and Nevis',
    currency: 'KNA',
  },
  {
    countryCode: 'LC',
    dialCode: '1758',
    currencySymbol: 'LC',
    name: 'Saint Lucia',
    currency: 'LCA',
  },
  {
    countryCode: 'MF',
    dialCode: '590',
    currencySymbol: 'MF',
    name: 'Saint Martin (French)',
    currency: 'MAF',
  },
  {
    countryCode: 'PM',
    dialCode: '508',
    currencySymbol: 'PM',
    name: 'Saint Pierre and Miquelon',
    currency: 'SPM',
  },
  {
    countryCode: 'VC',
    dialCode: '1784',
    currencySymbol: 'VC',
    name: 'Saint Vincent and the Grenadines',
    currency: 'VCT',
  },
  {
    countryCode: 'WS',
    dialCode: '685',
    currencySymbol: 'WS',
    name: 'Samoa',
    currency: 'WSM',
  },
  {
    countryCode: 'SM',
    dialCode: '378',
    currencySymbol: 'SM',
    name: 'San Marino',
    currency: 'SMR',
  },
  {
    countryCode: 'ST',
    dialCode: '239',
    currencySymbol: 'ST',
    name: 'Sao Tome and Principe',
    currency: 'STP',
  },
  {
    countryCode: 'SA',
    dialCode: '966',
    currencySymbol: 'SA',
    name: 'Saudi Arabia',
    currency: 'SAU',
  },
  {
    countryCode: 'SN',
    dialCode: '221',
    currencySymbol: 'SN',
    name: 'Senegal',
    currency: 'SEN',
  },
  {
    countryCode: 'RS',
    dialCode: '381',
    currencySymbol: 'RS',
    name: 'Serbia',
    currency: 'SRB',
  },
  {
    countryCode: 'SC',
    dialCode: '248',
    currencySymbol: 'SC',
    name: 'Seychelles',
    currency: 'SYC',
  },
  {
    countryCode: 'SL',
    dialCode: '232',
    currencySymbol: 'SL',
    name: 'Sierra Leone',
    currency: 'SLE',
  },
  {
    countryCode: 'SG',
    dialCode: '65',
    currencySymbol: 'SG',
    name: 'Singapore',
    currency: 'SGP',
  },
  {
    countryCode: 'SX',
    dialCode: '1721',
    currencySymbol: 'SX',
    name: 'Sint Maarten (Dutch)',
    currency: 'SXM',
  },
  {
    countryCode: 'SK',
    dialCode: '421',
    currencySymbol: 'SK',
    name: 'Slovakia',
    currency: 'SVK',
  },
  {
    countryCode: 'SI',
    dialCode: '386',
    currencySymbol: 'SI',
    name: 'Slovenia',
    currency: 'SVN',
  },
  {
    countryCode: 'SB',
    dialCode: '677',
    currencySymbol: 'SB',
    name: 'Solomon Islands',
    currency: 'SLB',
  },
  {
    countryCode: 'SO',
    dialCode: '252',
    currencySymbol: 'SO',
    name: 'Somalia',
    currency: 'SOM',
  },
  {
    countryCode: 'ZA',
    dialCode: '27',
    currencySymbol: 'ZA',
    name: 'South Africa',
    currency: 'ZAF',
  },
  {
    countryCode: 'GS',
    dialCode: '500',
    currencySymbol: 'GS',
    name: 'South Georgia and Islands',
    currency: 'SGS',
  },
  {
    countryCode: 'SS',
    dialCode: '211',
    currencySymbol: 'SS',
    name: 'South Sudan',
    currency: 'SSD',
  },
  {
    countryCode: 'ES',
    dialCode: '34',
    currencySymbol: 'ES',
    name: 'Spain',
    currency: 'ESP',
  },
  {
    countryCode: 'LK',
    dialCode: '94',
    currencySymbol: 'LK',
    name: 'Sri Lanka',
    currency: 'LKA',
  },
  {
    countryCode: 'SD',
    dialCode: '249',
    currencySymbol: 'SD',
    name: 'Sudan',
    currency: 'SDN',
  },
  {
    countryCode: 'SR',
    dialCode: '597',
    currencySymbol: 'SR',
    name: 'Suriname',
    currency: 'SUR',
  },
  {
    countryCode: 'SJ',
    dialCode: '4779',
    currencySymbol: 'SJ',
    name: 'Svalbard and Jan Mayen',
    currency: 'SJM',
  },
  {
    countryCode: 'SE',
    dialCode: '46',
    currencySymbol: 'SE',
    name: 'Sweden',
    currency: 'SWE',
  },
  {
    countryCode: 'CH',
    dialCode: '41',
    currencySymbol: 'CH',
    name: 'Switzerland',
    currency: 'CHE',
  },
  {
    countryCode: 'SY',
    dialCode: '963',
    currencySymbol: 'SY',
    name: 'Syria',
    currency: 'SYR',
  },
  {
    countryCode: 'TW',
    dialCode: '886',
    currencySymbol: 'TW',
    name: 'Taiwan',
    currency: 'TWN',
  },
  {
    countryCode: 'TJ',
    dialCode: '992',
    currencySymbol: 'TJ',
    name: 'Tajikistan',
    currency: 'TJK',
  },
  {
    countryCode: 'TZ',
    dialCode: '255',
    currencySymbol: 'TZ',
    name: 'Tanzania',
    currency: 'TZA',
  },
  {
    countryCode: 'TH',
    dialCode: '66',
    currencySymbol: 'TH',
    name: 'Thailand',
    currency: 'THA',
  },
  {
    countryCode: 'TL',
    dialCode: '670',
    currencySymbol: 'TL',
    name: 'Timor-Leste',
    currency: 'TLS',
  },
  {
    countryCode: 'TG',
    dialCode: '228',
    currencySymbol: 'TG',
    name: 'Togo',
    currency: 'TGO',
  },
  {
    countryCode: 'TK',
    dialCode: '690',
    currencySymbol: 'TK',
    name: 'Tokelau',
    currency: 'TKL',
  },
  {
    countryCode: 'TO',
    dialCode: '676',
    currencySymbol: 'TO',
    name: 'Tonga',
    currency: 'TON',
  },
  {
    countryCode: 'TT',
    dialCode: '1868',
    currencySymbol: 'TT',
    name: 'Trinidad and Tobago',
    currency: 'TTO',
  },
  {
    countryCode: 'TN',
    dialCode: '216',
    currencySymbol: 'TN',
    name: 'Tunisia',
    currency: 'TUN',
  },
  {
    countryCode: 'TR',
    dialCode: '90',
    currencySymbol: 'TR',
    name: 'Turkey',
    currency: 'TUR',
  },
  {
    countryCode: 'TM',
    dialCode: '993',
    currencySymbol: 'TM',
    name: 'Turkmenistan',
    currency: 'TKM',
  },
  {
    countryCode: 'TC',
    dialCode: '1649',
    currencySymbol: 'TC',
    name: 'Turks and Caicos Islands',
    currency: 'TCA',
  },
  {
    countryCode: 'TV',
    dialCode: '688',
    currencySymbol: 'TV',
    name: 'Tuvalu',
    currency: 'TUV',
  },
  {
    countryCode: 'UG',
    dialCode: '256',
    currencySymbol: 'UG',
    name: 'Uganda',
    currency: 'UGA',
  },
  {
    countryCode: 'UA',
    dialCode: '380',
    currencySymbol: 'UA',
    name: 'Ukraine',
    currency: 'UKR',
  },
  {
    countryCode: 'AE',
    dialCode: '971',
    currencySymbol: 'AE',
    name: 'United Arab Emirates',
    currency: 'ARE',
  },
  {
    countryCode: 'GB',
    dialCode: '44',
    currencySymbol: 'GB',
    name: 'United Kingdom',
    currency: 'GBR',
  },
  {
    countryCode: 'UM',
    dialCode: '1',
    currencySymbol: 'UM',
    name: 'United States Minor Outlying Islands',
    currency: 'UMI',
  },
  {
    countryCode: 'US',
    dialCode: '1',
    currencySymbol: 'US',
    name: 'United States of America',
    currency: 'USA',
  },
  {
    countryCode: 'UY',
    dialCode: '598',
    currencySymbol: 'UY',
    name: 'Uruguay',
    currency: 'URY',
  },
  {
    countryCode: 'UZ',
    dialCode: '998',
    currencySymbol: 'UZ',
    name: 'Uzbekistan',
    currency: 'UZB',
  },
  {
    countryCode: 'VU',
    dialCode: '678',
    currencySymbol: 'VU',
    name: 'Vanuatu',
    currency: 'VUT',
  },
  {
    countryCode: 'VE',
    dialCode: '58',
    currencySymbol: 'VE',
    name: 'Venezuela',
    currency: 'VEN',
  },
  {
    countryCode: 'VN',
    dialCode: '84',
    currencySymbol: 'VN',
    name: 'Vietnam',
    currency: 'VNM',
  },
  {
    countryCode: 'VG',
    dialCode: '1284',
    currencySymbol: 'VG',
    name: 'British Virgin Islands',
    currency: 'VGB',
  },
  {
    countryCode: 'VI',
    dialCode: '1340',
    currencySymbol: 'VI',
    name: 'United States Virgin Islands',
    currency: 'VIR',
  },
  {
    countryCode: 'WF',
    dialCode: '681',
    currencySymbol: 'WF',
    name: 'Wallis and Futuna',
    currency: 'WLF',
  },
  {
    countryCode: 'EH',
    dialCode: '212',
    currencySymbol: 'EH',
    name: 'Western Sahara',
    currency: 'ESH',
  },
  {
    countryCode: 'YE',
    dialCode: '967',
    currencySymbol: 'YE',
    name: 'Yemen',
    currency: 'YEM',
  },
  {
    countryCode: 'ZM',
    dialCode: '260',
    currencySymbol: 'ZM',
    name: 'Zambia',
    currency: 'ZMB',
  },
  {
    countryCode: 'ZW',
    dialCode: '263',
    currencySymbol: 'ZW',
    name: 'Zimbabwe',
    currency: 'ZWE',
  },
  {
    countryCode: 'AX',
    dialCode: '358',
    currencySymbol: 'AX',
    name: 'Åland Islands',
    currency: 'ALA',
  },
];
