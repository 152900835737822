import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ContactFile,
  GatewayContract,
  MandatoryDocFile,
  MandatoryDocument,
  Opportunity,
  Profile,
  Restful,
  ShareClass,
  SignApi,
} from '@stream/models';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormlyService {
  constructor(private http: HttpClient) {}

  loading$ = new BehaviorSubject(false);

  loading = this.loading$.asObservable();

  shareClass$ = new BehaviorSubject<Observable<ShareClass[]> | null>(null);

  mandatoryDocs$ = new BehaviorSubject<Observable<MandatoryDocument[]> | null>(
    null
  );

  profileList = new BehaviorSubject<Observable<Profile[]> | null>(null);

  contractsFn$ = new ReplaySubject<
    (profileId: Profile['id']) => Observable<GatewayContract[]>
  >();

  opportunity$ = new BehaviorSubject<Observable<Opportunity | null> | null>(
    null
  );

  uploadMandatoryFn$ = new ReplaySubject<
    (file: File) => Observable<MandatoryDocFile>
  >();

  profileId?: string;

  getPdfDetail(id: GatewayContract['contractId']) {
    return this.http.get<
      Restful<{
        responseData: ContactFile;
      }>
    >(SignApi.GetPdfFileDetail, { params: { id } });
  }
}
