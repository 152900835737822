import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActualSituationType,
  Address,
  Bank,
  Custodian,
  CustodianAccountType,
  DOCUMENT_TYPE,
  GroupType,
  IPayMethodType,
  Identity,
  LabelCustodianAccountType,
  Profile,
  ProfileType,
  Store,
  TenantAttribute,
  documentTypeEnum
} from '@stream/models';
import { DrawerSelectComponent } from '@stream/ngx-utils';
import { SharedService } from '@stream/shared';
import { objectFilterNull } from '@stream/utils';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { debounceTime, filter, finalize, pluck, take, tap } from 'rxjs/operators';

import { PermissionService } from '@stream/libs/common/utils/service';
import { PROFILE_CONFIG, ProfileConfig } from '../../profile.type';
import { ProfileSettingService } from '../../services';
import { ProfilePanelService } from '../../services/profile-panel.service';
import { ProfileService } from '../../services/profile.service';
import { profileValidatorFn } from '../../utils';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { BankDialogComponent } from '../bank-dialog/bank-dialog.component';
import { IdentityDialogComponent } from '../identity-dialog/identity-dialog.component';
import { IndividualInfoFormComponent } from '../individual-info-form/individual-info-form.component';
import { IRAInformationDialogComponent } from '../ira-information-dialog/ira-information-dialog.component';

@Component({
  selector: 'stream-individual-form',
  templateUrl: './individual-form.component.html',
  styleUrls: ['./individual-form.component.scss']
})
export class IndividualFormComponent implements OnInit, OnDestroy {
  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    public panelService: ProfilePanelService,
    private profileService: ProfileService,
    public location: Location,
    private router: Router,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    @Inject(PROFILE_CONFIG) public config: ProfileConfig,
    private permissionService: PermissionService,
    private profileSettingService: ProfileSettingService,
    private cdr: ChangeDetectorRef
  ) {}

  ActualSituationType = ActualSituationType;

  IPayMethodType = IPayMethodType;

  @Input() tenantId = '';

  @ViewChild('infoForm')
  infoForm?: IndividualInfoFormComponent;

  @ViewChild('iraSelect')
  iraSelect?: DrawerSelectComponent<Custodian>;

  @ViewChild('addressSelect')
  addressSelect?: DrawerSelectComponent<Address>;

  @ViewChild('identitySelect')
  identitySelect?: DrawerSelectComponent<Identity>;

  @ViewChild('bankSelect')
  bankSelect?: DrawerSelectComponent<Bank>;

  isIRACanEnabled: boolean = false;

  featureFlagMT = false;

  iraList = new Observable<Custodian[]>();

  addressList = this.profileService
    .getAddressList()
    .pipe(pluck('data', 'investorProfileMetaInfoAddressList'));

  identityList = this.profileService
    .getIdentityList()
    .pipe(pluck('data', 'investorProfileMetaInfoCertificateList'));

  bankList = this.profileService
    .getBankList()
    .pipe(pluck('data', 'investorProfileMetaInfoBankList'));

  form = this.fb.group({
    address: [null, Validators.required],
    identity: [null, Validators.required],
    bank: [null, Validators.required],
    actualSituationType: [null, Validators.required],
    payMethod: [IPayMethodType.BANK, Validators.required],
    ira: [null, Validators.required]
  });

  // re-modify && route has subscriptionId, don't save profile to BE
  autoSave = this.form.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
    if (this.reModify) {
      return;
    }

    this.saveProfile().subscribe();
  });

  profileId = new BehaviorSubject<string | null>(null);

  countryList = this.sharedService.countryList;
  countryListSync: Array<{ label: string; value: string }> = [];

  dialogSub = new Subscription();
  tenantAttributeList: TenantAttribute[] = [];
  store = new Store({ type: 'session', namespace: 'scenario' });
  reModify = false;
  additionalDocument: any[] = [];

  isLinkCustodian: boolean = false;

  referencePrincipalId: string = '';

  _isShowTaxStatus: boolean = true;

  isLPMember = false;

  set isShowTaxStatus(value: boolean) {
    // ensure the rendering order of Tax status
    this._isShowTaxStatus = false;
    setTimeout(() => {
      this._isShowTaxStatus = value;
    }, 10);
  }

  get isMedium() {
    return this.breakpointObserver.isMatched('(min-width: 768px)');
  }

  get labelAccountType() {
    return LabelCustodianAccountType[this.form.value.ira.accountType as CustodianAccountType];
  }

  ngOnInit(): void {
    this.permissionService
      .getFeatureEnabled({
        featureKey: 'INSPIRA_IRA'
      })
      .subscribe(flag => {
        this.featureFlagMT = flag;
      });
    // late sign re-modify
    const snapshot = this.store.get('snapshot');

    if (snapshot) {
      this.store.set('profile', snapshot.profile);
    }

    if (snapshot) {
      this.initProfileItem();
      this.initIRACanEnabledStatus();
    } else {
      this.panelService.profile
        .pipe(
          filter(v => !!v),
          take(1)
        )
        .subscribe(profile => {
          if (profile) {
            this.initProfile(profile);
          }
        });
    }

    this.countryList.subscribe(countries => {
      this.countryListSync = (countries || []).map(item => ({
        ...item,
        label: item.countryName,
        value: item.countryAlpha2Code
      }));
    });
    this.getIdentityDocument();
    this.initIRACanEnabledStatus();
    this.form.valueChanges.subscribe(() => {
      this.checkFileRequiredFormValid();
    });
  }

  initProfile(profile: Profile) {
    const {
      payMethod,
      investorProfileMetaInfoBank,
      investorProfileMetaInfoCert,
      actualSituationType,
      investorProfileMetaInfoAddress,
      investorProfileMtCustodianData
    } = profile as Profile;

    this.form.patchValue(
      {
        payMethod,
        actualSituationType,
        bank: investorProfileMetaInfoBank,
        identity: investorProfileMetaInfoCert,
        address: investorProfileMetaInfoAddress,
        ira: investorProfileMtCustodianData
      },
      { emitEvent: false }
    );
    this.isLinkCustodian = !!this.form.value.ira;
    this.profileId.next(profile?.id ?? null);
    this.referencePrincipalId = profile.investorProfileMetaIndividual?.referencePrincipalId!;
    this.initIRACanEnabledStatus();
    this.checkFileRequiredFormValid();
  }

  checkFileRequiredFormValid() {
    let investorProfileMetaInfoAddress = this.form.get('address')?.value;
    let investorProfileMetaInfoCert = this.form.get('identity')?.value;
    let investorProfileMetaInfoBank = this.form.get('bank')?.value;

    // check profile has all mandatory documents
    this.profileSettingService.tenantProfileSettings$
      .pipe(
        filter(configs => !!configs),
        tap(configs => {
          let docConfigs = configs?.['DOCUMENT_INDIVIDUAL_PROFILE']?.settingValue ?? {};
          const validDocKeys = Object.keys(docConfigs).filter(
            key => (docConfigs as any)[key] === 'MANDATORY'
          );

          // address validation
          if (
            !profileValidatorFn(investorProfileMetaInfoAddress, 'address', {
              documentRequired: validDocKeys.includes('proofOfAddress'),
              document: investorProfileMetaInfoAddress?.referenceProofAddressFileEntity?.documents
            })
          ) {
            setTimeout(() => {
              this.form.get('address')?.setErrors({ required: true });
            });
          }

          // identity validation
          if (
            !profileValidatorFn(investorProfileMetaInfoCert, 'identity', {
              documentRequired: validDocKeys.includes('proofOfIdentity'),
              document: investorProfileMetaInfoCert?.referenceProofCertificateFileEntity?.documents
            })
          ) {
            setTimeout(() => {
              this.form.get('identity')?.setErrors({ required: true });
            });
          }

          // bank validation
          if (
            this.form.get('payMethod')?.value === IPayMethodType.BANK &&
            !profileValidatorFn(investorProfileMetaInfoBank, 'bank', {
              documentRequired: validDocKeys.includes('proofOfBankAccount'),
              document: investorProfileMetaInfoBank?.referenceProofBankFileEntity?.documents
            })
          ) {
            setTimeout(() => {
              this.form.get('bank')?.setErrors({ required: true });
            }, 50);
          }
        })
      )
      .subscribe();
  }

  initIRACanEnabledStatus() {
    if (this.config.source === 'LP') {
      this.handleLPSource();
    } else if (this.config.source === 'GP') {
      this.handleGPSource();
    }
  }

  handleLPSource() {
    this.profileService.getInvestPrincipals().subscribe({
      next: ({ data }) => {
        const { investPrincipals, investAccount } = data;
        // https://bite.atlassian.net/browse/STREAM-14917
        this.isLPMember = investAccount?.role === 'MEMBER' || investAccount?.role === 'ADMIN';
        if (investPrincipals && investPrincipals.length > 0) {
          this.isIRACanEnabled =
            investPrincipals[0]?.countryAlpha2Code?.toUpperCase() === 'US' && !this.isLPMember;
          if (this.isIRACanEnabled) {
            this.isShowTaxStatus = true;
          }

          this.cdr.detectChanges();
        } else {
          throw new Error('No principal found');
        }
      },
      error: error => {
        throw error;
      }
    });
  }

  handleGPSource() {
    // change logic for https://bite.atlassian.net/browse/STREAM-14811
    let principalId = '';
    if (this.referencePrincipalId) {
      // for exist profile, get user info from profile investorProfileMetaIndividual.referencePrincipalId
      principalId = this.referencePrincipalId;
      this.setIRACanEnabledStatus(principalId);
    } else {
      // for create new one, get PrincipalId from api/subscription/profile/get-individual-meta-info, fields: investorProfileMetaIndividual.referencePrincipalId
      this.profileService.getIndividualMetaInfo().subscribe(({ data }) => {
        principalId = data.investorProfileMetaIndividual?.referencePrincipalId ?? '';
        if (principalId) {
          this.setIRACanEnabledStatus(principalId);
        } else {
          throw new Error('No principal found');
        }
      });
    }
  }

  setIRACanEnabledStatus(principalId: string) {
    this.profileService.getPrincipalDetail({ principalId: principalId }).subscribe({
      next: ({ data }) => {
        const country = data.principal.countryAlpha2Code;
        // Enable IRA if the payment method is IRA and the country is US
        this.isIRACanEnabled = this.form.get('payMethod')?.value === 'IRA' && country === 'US';
        if (this.isIRACanEnabled) {
          this.isShowTaxStatus = true;
        }
      },
      error: error => {
        throw error;
      }
    });
  }

  ngOnDestroy(): void {
    this.autoSave.unsubscribe();
  }

  translateCountryName(code: string) {
    if (!code) {
      return '--';
    }
    return this.countryListSync.find(item => item.value === code)?.label;
  }

  get ownerName(): string {
    const { firstName, lastName } = this.infoForm?.form.value || {};
    if (firstName && lastName) {
      return firstName + ' ' + lastName;
    }
    if (firstName || lastName) {
      return firstName || lastName;
    }
    if (!(firstName && lastName)) {
      return (
        this.profileService.investAccount$.getValue()?.firstName +
        ' ' +
        this.profileService.investAccount$.getValue()?.lastName
      );
    }
    return '';
  }

  initProfileItem() {
    const profile = this.store.get('profile');
    if (profile) {
      this.initProfile(profile);
      this.reModify = true;
      this.iraList = of([profile.investorProfileMetaInfoIRA]);
      this.addressList = of([profile.investorProfileMetaInfoAddress]);
      this.identityList = of([profile.investorProfileMetaInfoCert]);
      this.bankList = of([profile.investorProfileMetaInfoBank]);
    }
    this.checkFileRequiredFormValid();
  }

  handlePaymentMethodChange() {
    this.saveProfile().subscribe();
  }

  openIRA(ira?: Address) {
    this.dialog.open(IRAInformationDialogComponent, {
      data: {
        profileId: this.profileId.value || '',
        investorProfileType: ProfileType.Individual,
        ira,
        individual: this.infoForm?.form.value,
        ownerName: this.ownerName,
        reModify: this.reModify,
        completeCertifyDocumentsToLinkIRA: (res: { data: unknown }) => {
          if (res) {
            // link ira success
            this.iraSelect?.drawer?.close();
            this.form.patchValue(
              {
                ira: res?.data || null
              },
              { emitEvent: false }
            );
            this.isLinkCustodian = !!this.form.value.ira;
            this.saveProfile().subscribe();
          }
        }
      },
      panelClass: 'full-screen-dialog',

      width: !this.isMedium ? '100vw' : 'auto',
      maxWidth: !this.isMedium ? '100vw' : 'auto',
      height: !this.isMedium ? '100vh' : 'auto',
      maxHeight: !this.isMedium ? '100vh' : 'auto'
    });
  }

  openAddress(address?: Address) {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      data: {
        investorProfileType: ProfileType.Individual,
        address,
        individual: this.infoForm?.form.value,
        ownerName: this.ownerName,
        reModify: this.reModify,
        profileId: this.profileId.value || ''
      },
      panelClass: 'full-screen-dialog',

      width: !this.isMedium ? '100vw' : 'auto',
      maxWidth: !this.isMedium ? '100vw' : 'auto',
      height: !this.isMedium ? '100vh' : 'auto',
      maxHeight: !this.isMedium ? '100vh' : 'auto'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const profile = this.store.get('profile');

        if (profile) {
          const { investorProfileMetaInfoAddress: options } = profile;
          this.addressSelect?.refreshOptions(false, [options]);
          this.initProfileItem();
        } else {
          this.addressSelect?.refreshOptions();
          this.checkFileRequiredFormValid();
        }
      }
    });
  }

  openIdentity(identity?: Identity) {
    const dialogRef = this.dialog.open(IdentityDialogComponent, {
      data: {
        investorProfileType: ProfileType.Individual,
        identity,
        tenantId: this.tenantId,
        tenantAttributeList: this.tenantAttributeList,
        ownerName: this.ownerName,
        reModify: this.reModify,
        profileId: this.profileId.value || ''
      },
      panelClass: 'full-screen-dialog',
      width: !this.isMedium ? '100vw' : 'auto',
      maxWidth: !this.isMedium ? '100vw' : 'auto',
      height: !this.isMedium ? '100vh' : 'auto',
      maxHeight: !this.isMedium ? '100vh' : 'auto'
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const profile = this.store.get('profile');

        if (profile) {
          const { investorProfileMetaInfoCert: options } = profile;
          this.identitySelect?.refreshOptions(false, [options]);
          this.initProfileItem();
        } else {
          this.identitySelect?.refreshOptions();
          this.checkFileRequiredFormValid();
        }
      }
    });
  }

  submit() {
    const snapshot = this.store.get('snapshot');
    const profile = this.store.get('profile');

    const individual = this.infoForm?.saveIndividual();
    if (this.reModify && snapshot && profile) {
      const { value } = this.form;
      profile.investorProfileMetaIndividual = individual;
      profile.actualSituationType = value.actualSituationType;
      snapshot.profile = profile;
      this.profileService.profileUpdate.next();
      this.store.set('snapshot', snapshot);
      this.store.remove('profile');
      this.goback();
    } else {
      this.saveProfile().subscribe(() => {
        this.goback();
      });
    }
  }

  goback() {
    if (this.config.source === 'GP') {
      this.panelService.back.next(null);
      this.panelService.profileId.next(null);
      this.panelService.profile.next(null);
    } else {
      this.location.back();
    }
  }

  saveProfile() {
    this.panelService.saving.next(true);
    const { value } = this.form;

    return this.profileService
      .patchProfile({
        ...objectFilterNull({
          referenceProfileInfoAddressId: value.address?.id ?? null,
          referenceProfileInfoBankId: value.bank?.id ?? null,
          referenceProfileInfoCertId: value.identity?.id ?? null,
          referenceProfileMtCustodianId: value.ira?.id ?? null,
          actualSituationType: value.actualSituationType,
          profileId: this.profileId.value,
          payMethod: value.payMethod ?? null
        }),
        profileType: ProfileType.Individual
      })
      .pipe(
        finalize(() => {
          this.panelService.saving.next(false);
        }),
        tap(
          ({
            data: {
              productSubscriptionProfile: { id }
            }
          }) => {
            this.location.replaceState(
              this.router
                .createUrlTree(['edit', ProfileType.Individual.toLowerCase(), id, this.tenantId], {
                  relativeTo: this.route.parent
                })
                .toString()
            );
            this.panelService.isEdit.next(true);
            this.profileId.next(id);
          }
        )
      );
  }
  getIdentityInformation(key: documentTypeEnum): string {
    return DOCUMENT_TYPE[key] || key;
  }

  openBank(bank?: Bank) {
    const bankAccountName =
      this.infoForm?.form.get('firstName')?.value +
      ' ' +
      this.infoForm?.form.get('lastName')?.value;
    const dialogRef = this.dialog.open(BankDialogComponent, {
      data: {
        investorProfileType: ProfileType.Individual,
        bank: { bankAccountName, ...bank },
        tenantId: this.tenantId,
        ownerName: this.ownerName,
        reModify: this.reModify,
        isMember: this.isLPMember,
        profileId: this.profileId.value || ''
      },
      panelClass: 'full-screen-dialog',
      ...(!this.isMedium
        ? {
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            maxHeight: '100vh'
          }
        : {
            width: 'auto',
            maxWidth: 'auto',
            height: 'auto',
            maxHeight: 'auto'
          })
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const profile = this.store.get('profile');

        if (profile) {
          const { investorProfileMetaInfoBank: options } = profile;
          this.bankSelect?.refreshOptions(false, [options]);
          this.initProfileItem();
        } else {
          this.bankSelect?.refreshOptions();
          this.checkFileRequiredFormValid();
        }
      }
    });
  }

  getIdentityDocument() {
    this.profileService
      .getTenantAttributeList({
        tenantId: this.tenantId,
        groupType: GroupType.IDENTITY
      })
      .subscribe(({ data }) => {
        this.tenantAttributeList = data?.tenantAttributes.filter(
          item => item.isCheckAttribute === 1
        );
      });
  }

  getDocTagLabel = (v: any) => {
    const { tag, country } = v;
    return tag ? `${tag.name}${country ? ' - ' + country.name : ''}` : '';
  };

  getAdditionalDoc() {
    this.profileService
      .getAdditionalDocument({
        profileId: this.profileId.value || '',
        custom: true
      })
      .subscribe(({ data }) => {
        const { profileInvestorDocumentMappingVOList, otherProfileInvestorDocumentMappingVOList } =
          data as any;
        this.additionalDocument = [
          ...profileInvestorDocumentMappingVOList,
          ...otherProfileInvestorDocumentMappingVOList
        ];
      });
  }
}
