import { Component, Inject, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { AnswerResult, Question, QuestionType } from '@stream/models';
import { ReplaySubject } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';

import { SCENARIO_CONFIG, ScenarioConfig } from '../../scenario.type';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { ScenarioPanelService } from '../../services/scenario-panel.service';
import { ScenarioBaseComponent, ScenarioComponent } from '../scenario/scenario.component';

@Component({
  selector: 'stream-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  providers: [QuestionnaireService],
})
export class QuestionnaireComponent extends ScenarioBaseComponent {
  constructor(
    private questionnaireService: QuestionnaireService,
    private panelService: ScenarioPanelService,
    scenarioComponent: ScenarioComponent,
    vcr: ViewContainerRef,
    @Inject(SCENARIO_CONFIG) private config: ScenarioConfig,
  ) {
    super(scenarioComponent, vcr);
    this.checkNodeFinished();
  }

  /** 问卷🆔 */
  recordId = this.panelService.nodeData.pipe(
    map(
      ({
        data: {
          surveyQuestionData: { recordId },
        },
      }) => recordId,
    ),
  );

  /** 当前问题 */
  question = new ReplaySubject<Question>(1);

  form = new UntypedFormGroup({});

  model: Record<string, unknown> = {};

  options: FormlyFormOptions = {
    formState: { disabled: false },
  };

  loading = false;

  progress = 0;

  answerResult = new ReplaySubject<AnswerResult>(1);

  /**
   * 判断当前问卷是否已经结束 处理边缘情况
   */
  checkNodeFinished() {
    this.panelService.nodeData.pipe(take(1)).subscribe(
      ({
        data: {
          surveyQuestionData: { currentQuestionId, progress, status },
        },
      }) => {
        if (status === 'finished') {
          this.panelService.submitNodeResult(this.config).subscribe();
        } else {
          this.getQuestion(currentQuestionId);
          this.progress = progress ?? 0;
        }
      },
    );
  }

  /**
   * 获取问题详情
   * @param questionId
   */
  getQuestion(questionId: Question['id']) {
    this.options.formState.disabled = true;

    this.recordId.pipe(take(1)).subscribe(recordId => {
      this.questionnaireService
        .getQuestion({
          recordId,
          questionId,
        })
        .pipe(
          finalize(() => {
            this.options.formState.disabled = false;
          }),
        )
        .subscribe(({ data: { responseData } }) => {
          this.form = new UntypedFormGroup({});
          this.options.resetModel?.();
          this.loading = false;

          if (responseData.value) {
            const values = responseData.extras?.options?.map(({ value }) => value) ?? [];
            if (values.includes('Other') && !values.includes(responseData.value)) {
              Object.assign(this.model, {
                [responseData.name]: 'Other',
                [responseData.name + '_other']: responseData.value,
              });
            } else {
              Object.assign(this.model, {
                [responseData.name]: responseData.value,
              });
            }
          }

          this.progress = responseData.progress;
          this.question.next(responseData);
        });
    });
  }

  /**
   * 上一步
   */
  prev() {
    this.loading = true;

    this.recordId
      .pipe(
        switchMap(recordId =>
          this.question.pipe(
            switchMap(({ id }) =>
              this.questionnaireService.clearAnswer({
                recordId,
                questionId: id,
              }),
            ),
          ),
        ),
        take(1),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        ({
          data: {
            responseData: { previousQuestionId },
          },
        }) => {
          this.getQuestion(previousQuestionId);
        },
      );
  }

  /**
   * 下一步
   */
  next() {
    this.form.markAllAsTouched();

    if (this.form.invalid) return;

    this.loading = true;
    this.recordId
      .pipe(
        switchMap(recordId =>
          this.question.pipe(
            switchMap(({ id, name, type }) =>
              this.questionnaireService.submitAnswer(
                { recordId, questionId: id },
                this.model[name + '_other'] ||
                  (type === QuestionType.CheckboxGroup
                    ? Object.entries(<Record<string, boolean>>this.model[name])
                        .filter(([, value]) => value)
                        .map(([key]) => key)
                    : type === QuestionType.Table
                      ? this.form.value[name]
                      : this.model[name]),
              ),
            ),
          ),
        ),
        take(1),
      )
      .subscribe(
        ({
          data: {
            responseData: { finished, nextId },
          },
        }) => {
          if (finished) {
            this.panelService.submitNodeResult(this.config).subscribe({
              error: err => {
                this.loading = false;
                throw err;
              },
            });
          } else {
            this.getQuestion(nextId);
          }
        },
        err => {
          throw err;
        },
      );
  }
}
