<mat-drawer-container>
  <mat-drawer-content>
    <ul class="space-y-3 bg-white">
      <li
        *ngFor="let member of members"
        class="flex items-center justify-between space-y-2 rounded border border-[rgb(229,231,235)] p-4"
      >
        <div>
          <h4
            class="flex flex-wrap-reverse items-center whitespace-nowrap text-base font-semibold sm:flex-nowrap"
          >
            <span class="pr-2">
              {{ member.referenceInvestorMetaIndividualEntity.firstName }}
              {{ member.referenceInvestorMetaIndividualEntity.lastName }}
            </span>

            <div class="flex items-center space-x-2 whitespace-nowrap">
              <span
                class="tag bg-blue-300 text-blue-300"
                *ngIf="member.memberRole === MemberRole.Director"
              >
                Director
              </span>
              <span
                class="tag bg-amber-600 text-amber-600"
                *ngIf="
                  member.memberRole === MemberRole.ControllingPerson ||
                  member.memberRole === MemberRole.Director
                "
              >
                Controlling person
              </span>
              <span
                class="tag bg-pink-300 text-pink-300"
                *ngIf="member.memberRole === MemberRole.AuthorisedSignatories"
              >
                Authorised signatories
              </span>
            </div>
          </h4>

          <div *ngIf="getControllingEntitiesName($any(member.legalInfo))">
            Relevant entity:
            {{ getControllingEntitiesName($any(member.legalInfo)) }}
          </div>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="text-gray-500">more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editMember(member)">Edit</button>
          <button mat-menu-item (click)="deleteMember(member)" *ngIf="!reModify">Delete</button>
        </mat-menu>
      </li>
      <li
        class="flex cursor-pointer flex-col items-center justify-center rounded border border-[rgb(229,231,235)] bg-white py-5"
        (click)="drawer.open()"
        *ngIf="!reModify"
      >
        <svg class="icon svg-icon text-2xl text-gray-500" aria-hidden="true">
          <use xlink:href="#icon-tianjia"></use>
        </svg>
        <span class="block text-base text-black"> Add a new one </span>
      </li>
    </ul>
  </mat-drawer-content>
  <mat-drawer
    class="drawer-transition !fixed w-full max-w-4xl p-6"
    [style.right]="positionRight"
    #drawer
    position="end"
    (closed)="drawerClosed()"
  >
    <stream-ownership-director-drawer
      *ngIf="drawer.opened"
      [member]="currentMember"
      (drawerPositionChange)="positionRight = $event"
      [tenantId]="tenantId"
    ></stream-ownership-director-drawer>
  </mat-drawer>
</mat-drawer-container>
