<mat-radio-group
  [formControl]="$any(formControl)"
  [formlyAttributes]="field"
  [required]="$any(to.required)"
  [tabindex]="to.tabindex"
>
  <ng-container *ngFor="let option of $any(to.options); let i = index">
    <ng-container *ngIf="option['value'] === 'Other'; else radio">
      <mat-radio-button
        [id]="id + '_' + i"
        [color]="to['color']"
        [labelPosition]="to['labelPosition'] ?? 'after'"
        [disabled]="option['disabled']"
        [value]="otherControl.value"
        #otherRadio
        class="w-full"
      >
        <div class="label">
          {{ option['label'] }}
        </div>
        <p class="desc" *ngIf="option.description">{{ option.description }}</p>
      </mat-radio-button>
      <mat-form-field
        class="mat-radio-other-input [&>.mat-form-field-wrapper]:pb-0"
        appearance="outline"
        *ngIf="otherRadio.checked"
      >
        <input
          matInput
          [formControl]="otherControl"
          (change)="formControl.setValue(otherControl.value)"
        />
      </mat-form-field>
    </ng-container>

    <ng-template #radio>
      <mat-radio-button
        [id]="id + '_' + i"
        [color]="to['color']"
        [labelPosition]="to['labelPosition'] ?? 'after'"
        [value]="option['value']"
        [disabled]="option['disabled']"
        class="w-full"
      >
        <div class="label">
          {{ option['label'] }}
        </div>
        <p class="desc" *ngIf="option.description">{{ option.description }}</p>
      </mat-radio-button>
    </ng-template>
  </ng-container>
</mat-radio-group>
