import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewerComponent } from '@stream/ngx-utils';
import { CacheService } from '../../../utils/service';
import { CHAT_CONFIG, ChatConfig } from '../../chat.type';
import { IMessageFileValueType } from '../message-file/message-file.model';
import {
  EMessageItemInfoRoles,
  IEMessageItemInfoType,
  rolesLabel,
  rolesOrderList,
} from './message-item.model';

@Component({
  selector: 'stream-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.less'],
})
export class MessageItemComponent {
  constructor(
    public dialogFilePreviewer: MatDialog,
    public cacheService: CacheService,
    @Inject(CHAT_CONFIG) public config: ChatConfig
  ) {}

  @Input()
  source: 'GP' | 'LP' | '' = '';

  @Input()
  customWrapClass: string = '';

  @Input()
  info: IEMessageItemInfoType | null = null;

  @Output()
  resendClick = new EventEmitter();

  @Output()
  deleteClick = new EventEmitter();

  @Output()
  contentClick = new EventEmitter();

  cacheGetMap = new Map();

  tagDownBG: string = '';

  get sourceReal() {
    return this.source || this.config.source;
  }

  get colorPrimary() {
    return this.sourceReal === 'GP' ? '#2E4B9C' : '#101213';
  }

  get colorPrimaryLight() {
    return this.sourceReal === 'GP' ? '#2E4B9C82' : '#10121382';
  }

  get colorPrimaryLightBG() {
    return this.sourceReal === 'GP' ? '#5873C699' : '#2C2934';
  }

  get tagDown() {
    return this.cacheService.setMap(this.cacheGetMap, 'tagDown', () => {
      let result = '';
      let rolesType =
        typeof this.info?.rolesType === 'string'
          ? [this.info?.rolesType]
          : this.info?.rolesType ?? [];
      const stRoles = new Set(rolesType);
      const sizeRoles = stRoles.size;

      if (
        sizeRoles === 1 &&
        (stRoles.has(EMessageItemInfoRoles.BIZ_OPS) ||
          stRoles.has(EMessageItemInfoRoles.owner))
      ) {
        // result = '';
      } else if (
        sizeRoles === 2 &&
        stRoles.has(EMessageItemInfoRoles.BIZ_OPS) &&
        stRoles.has(EMessageItemInfoRoles.owner)
      ) {
        // result = '';
      } else {
        let rolesName = '';
        for (
          let indexOrder = 0;
          indexOrder < rolesOrderList.length;
          indexOrder++
        ) {
          const itemOrder: EMessageItemInfoRoles = rolesOrderList[indexOrder];

          if (stRoles.has(itemOrder)) {
            rolesName = itemOrder;
            break;
          }
        }
        result =
          rolesLabel[rolesName as keyof typeof rolesLabel] ||
          rolesLabel[EMessageItemInfoRoles.GP_MEMBER];

        this.tagDownBG = ['LP', 'WM Candidate'].includes(result || '')
          ? '#E8F8FF'
          : '#FFF9EB';
      }

      return result;
    });
  }

  get messageType() {
    return this.cacheService.setMap(this.cacheGetMap, 'messageType', () => {
      let result = '';
      const hasContent = !!this.info?.content;
      const hasAttachment =
        !!this.info?.attachment && this.info.attachment.length > 0;

      if (hasContent && hasAttachment) {
        result = 'TEXT_FILE';
      } else if (hasContent) {
        result = 'TEXT';
      } else if (hasAttachment) {
        result = 'FILE';
      }

      return result;
    });
  }

  handleResendClick() {
    this.resendClick.emit(this.info);
  }

  handleDeleteClick() {
    this.deleteClick.emit(this.info);
  }

  handleContentClick() {
    this.contentClick.emit(this.info);
  }

  handleFilePreviewClick(file: IMessageFileValueType | null) {
    if (!file) {
      return;
    }
    const type = file.documentType ?? file.type;
    if (file && ['IMAGE', 'PDF'].includes(String(type))) {
      this.dialogFilePreviewer.open(FilePreviewerComponent, {
        data: {
          type: type,
          name: file.name,
          url: file.url ?? file.downloadUrl,
          downloadUrl: file.downloadUrl ?? file.url,
          btnCloseText: 'Cancel',
          btnCloseStyle: `color: ${this.colorPrimary}`,
          btnDownloadText: 'Download',
          btnDownloadStyle: `background-color: ${this.colorPrimary}`,
        },
        maxWidth: '60vw',
        maxHeight: '90vh',
        width: '100%',
      });
    }
  }
}
