import { Directive } from '@angular/core';

import { AnchorDirective } from './anchor.directive';

@Directive({
  selector: '[streamAnchorManagement]',
  exportAs: 'anchorManagement',
})
export class AnchorManagementDirective {
  anchorSet = new Set<AnchorDirective>();
}
