import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  AnchorModule,
  DrawerSelectModule,
  NgxSelectCountryModule,
  NotificationModule,
  QuillViewModule,
} from '@stream/ngx-utils';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMatErrorsModule } from 'ngx-mat-errors';
import { MandatoryDocModule } from '../mandatory-doc';

import { InvestorService } from '../../portal/services/investor.service';
import { CountriesModule } from '../countries';
import { AdditionalDocUploadComponent } from './components/additional-doc-upload/additional-doc-upload.component';
import { AdditionalDocumentFormComponent } from './components/additional-document-form/additional-document-form.component';
import { AdditionalDocumentComponent } from './components/additional-document/additional-document.component';
import { AddressDialogComponent } from './components/address-dialog/address-dialog.component';
import { BankDialogComponent } from './components/bank-dialog/bank-dialog.component';
import { CertifiedSupportingDocsComponent } from './components/certified-supporting-docs/certified-supporting-docs.component';
import { CertifyDocumentsToLinkIRADialogComponent } from './components/certify-documents-to-link-ira-dialog/certify-documents-to-link-ira-dialog.component';
import { DataRoomDocumentComponent } from './components/data-room-document/data-room-document.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { EntityInfoFormComponent } from './components/entity-info-form/entity-info-form.component';
import { IdentityDialogComponent } from './components/identity-dialog/identity-dialog.component';
import { IndividualFormComponent } from './components/individual-form/individual-form.component';
import { IndividualInfoFormComponent } from './components/individual-info-form/individual-info-form.component';
import { IRAInformationDialogComponent } from './components/ira-information-dialog/ira-information-dialog.component';
import { OwnershipDirectorDrawerComponent } from './components/ownership-director-drawer/ownership-director-drawer.component';
import { OwnershipDirectorshipComponent } from './components/ownership-directorship/ownership-directorship.component';
import { PersonDialogComponent } from './components/person-dialog/person-dialog.component';
import { UploadPrivateFileComponent } from './components/upload-private-file/upload-private-file.component';
import { BankNumberPipe } from './pipes/bank-number.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { ProfileSharedModule } from './profile-shared.module';
import { PROFILE_CONFIG, ProfileConfig } from './profile.type';

const matModules = [
  MatButtonModule,
  MatFormFieldModule,
  MatRadioModule,
  MatIconModule,
  MatSelectModule,
  MatInputModule,
  MatMomentDateModule,
  MatDatepickerModule,
  MatDialogModule,
  MatTooltipModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
];

const nzModules = [NzPopoverModule];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationModule,
    // MdDocModule,
    MandatoryDocModule,
    ...matModules,
    ...nzModules,
    DrawerSelectModule,
    NgxMatErrorsModule,
    AnchorModule,
    NgxSelectCountryModule,
    ScrollingModule,
    QuillViewModule,
    NgxExtendedPdfViewerModule,
    ProfileSharedModule,
    CountriesModule,
  ],
  declarations: [
    IndividualInfoFormComponent,
    IndividualFormComponent,
    AddressDialogComponent,
    IdentityDialogComponent,
    BankDialogComponent,
    IRAInformationDialogComponent,
    UploadPrivateFileComponent,
    EntityFormComponent,
    EntityInfoFormComponent,
    OwnershipDirectorshipComponent,
    DataRoomDocumentComponent,
    PersonDialogComponent,
    BankNumberPipe,
    CountryNamePipe,
    OwnershipDirectorDrawerComponent,
    AdditionalDocumentFormComponent,
    AdditionalDocUploadComponent,
    CertifiedSupportingDocsComponent,
    CertifyDocumentsToLinkIRADialogComponent,
    AdditionalDocumentComponent,
  ],
  exports: [
    matModules,
    AnchorModule,
    IndividualInfoFormComponent,
    IndividualFormComponent,
    AddressDialogComponent,
    IdentityDialogComponent,
    BankDialogComponent,
    UploadPrivateFileComponent,
    EntityFormComponent,
    EntityInfoFormComponent,
    OwnershipDirectorshipComponent,
    DataRoomDocumentComponent,
    PersonDialogComponent,
    OwnershipDirectorDrawerComponent,
    AdditionalDocumentFormComponent,
    AdditionalDocUploadComponent,
    CertifiedSupportingDocsComponent,
    AdditionalDocumentComponent,
  ],
  providers: [InvestorService],
})
export class ProfileBaseModule {
  static injector: Injector;
  static forRoot(configs: ProfileConfig): ModuleWithProviders<ProfileBaseModule> {
    return {
      ngModule: ProfileBaseModule,
      providers: [
        {
          provide: PROFILE_CONFIG,
          useValue: configs,
        },
      ],
    };
  }

  constructor(injector: Injector) {
    ProfileBaseModule.injector = injector;
  }
}
