import { Pipe, PipeTransform } from "@angular/core";
import { CountriesService } from "./countries.service";

@Pipe({
  name: "countries"
})
export class CountriesPipe implements PipeTransform {

  constructor(private countriesService: CountriesService) {}

  transform(countryCode: string | undefined, key?: 'dialCode' | 'countryCode' | 'name') {
    let _key = 'name' as 'dialCode' | 'countryCode' | 'name';
    if (!countryCode) {
      return null;
    }
    if (key) {
      _key = key;
    }
    const country = this.countriesService.getCountryData(countryCode);
    if (country?.name) {
      return country[_key];
    }
    return countryCode;
  }
}