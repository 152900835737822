import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LpAccountApi, SubscriptionProfileApi } from '@stream/models';
import { NotificationService } from '@stream/ngx-utils';
import { WMApi } from '@stream/src/common';
import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export class BusinessErrorResponse extends HttpErrorResponse {}

const WHITE_LIST = [LpAccountApi.Logout, WMApi.replyEmail, SubscriptionProfileApi.PatchIRA];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private notify: NotificationService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (
      request.responseType === 'blob' ||
      ~WHITE_LIST.findIndex(url => request.url.includes(url))
    ) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      switchMap(event => {
        if (event instanceof HttpResponse) {
          if (event.body?.code !== 1) {
            // throw backend errors
            return throwError(
              new HttpErrorResponse({
                url: event.url ?? undefined,
                headers: event.headers,
                status: event.body.code,
                error: event.body.msg,
                statusText: event.body.msg
              })
            );
          } else {
            const {
              body: {
                data: { status = 'success' }
              }
            } = event;
            // throw business errors
            if (status !== 'success' && status !== 'SUCCESS')
              return throwError(
                new BusinessErrorResponse({
                  url: event.url ?? undefined,
                  headers: event.headers,
                  error: event.body.data
                })
              );
          }
        }
        return of(event);
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          this.notify.error(error.error.message);
        }
        if (error?.error?.code === 'B-00035') {
          this.router.navigate(['/domain-not-operational']);
        }
        return throwError(error);
      })
    );
  }
}
