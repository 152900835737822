import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GatewayProgress, NodeType, Profile, ScenarioType, ShareClass } from '@stream/models';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  pairwise,
  repeat,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { Store } from '../../models/src/store';
import { GatewayChangedDialogComponent, ScenarioDialogComponent } from '../components';
import { ReInvestRequiredComponent } from '../components/re-invest-required/re-invest-required.component';
import { ErrorMessages } from '../constant';
import { ScenarioLateSignService, ScenarioSubmitResult } from '../public-api';
import { ScenarioService } from './scenario.service';

export type Progress = {
  gateways: GatewayProgress[];
  scenarioName: string;
  scenarioProgress: number;
  isScenarioDone: boolean;
  scenarioId: string;
};

export type ScenarioSnapshot = {
  profile: Profile;
  shareClass: ShareClass;
};

export class ScenarioPanelService {
  constructor(
    private scenarioService: ScenarioService,
    private scenarioLateSignService: ScenarioLateSignService,
    private router: Router,
    public type: ScenarioType,
    private dialog: MatDialog,
    public extraParams: Record<string, any> = {}
  ) {
    this.subscriptionId = extraParams?.['subscriptionId'];
    this.productId = extraParams?.['productId'];
  }

  subscriptionId?: string;

  productId: string = '';

  leave = new Subject<string | void>();

  discard = new Subject<null | { skipDeleteSubscription: true } | void>();

  sessionStore = new Store({ type: 'session', namespace: 'scenario' });

  /**
   * Complete scene information.
   */
  scenario = this.scenarioService.getScenario(this.type, this.extraParams).pipe(
    map(x => x?.data?.scenarioData),
    shareReplay(1)
  );

  exist = new BehaviorSubject(true);

  gatewayChanged = false;

  private progressUpdate = new Subject<void>();

  private modifyUpdate = new Subject<void>();

  /**
   * Whether to start displaying
   */
  start = new BehaviorSubject(false);

  /**
   * Current scene results
   */
  resultGateway = new BehaviorSubject<GatewayProgress | null>(null);

  /**
   * The entire scenario service global loading state.
   */
  loading = new BehaviorSubject<boolean>(false);

  /**
   * Mark whether the current investment is a late sign. Note: late sign does not mean that this is a modification process.
   */
  lateSign = new BehaviorSubject<boolean>(false);

  /**
   * Record modification process operation node.
   */
  step = new BehaviorSubject<number>(0);

  /**
   * Record whether the modification status is requested or not.
   */
  requestMandatoryDocs = new BehaviorSubject<boolean>(false);

  projectId = new BehaviorSubject<string | null>(null);

  isFirstSigner: Observable<boolean> = of(false);

  /**
   * Is it a complete investment process?
   */
  isCompleteInvestment: boolean = false;

  getStep() {
    return this.step.getValue();
  }

  /**
   * Get scene progress
   */
  getProgress() {
    this.progressUpdate.next();
  }

  /**
   * Determine whether the current investment status is modified
   */
  get isModifyInvest() {
    const { subscriptionId, taskId } = this.extraParams;

    return !!(subscriptionId && taskId);
  }

  /**
   * the step will be updated only when the scene is modified
   * @param step
   */
  updateModifyStep(step: -1 | 0 | 1) {
    const _step = this.getStep() + step;
    this.step.next(_step);
    this.modifyUpdate.next();
  }

  /**
   * (Normal investment progress) Not re-modify, get investment process progress information.
   */
  normalScenarioProgress = this.scenarioService
    .getScenarioProgress(this.type, this.extraParams)
    .pipe(
      repeat({ delay: () => this.progressUpdate }),
      tap(({ data: { scenarioProgressResult, isScenarioDone } }) => {
        if (scenarioProgressResult === 'GATEWAY_NOT_EXIST') {
          this.exist.next(false);
        }

        if (this.type === ScenarioType.Reverse && isScenarioDone) {
          this.deleteNodeResult().subscribe();
        }
      }),
      map(({ data: { biteFlowGatewayList, lateSigning, ...scenarioInfo } }) => {
        this.lateSign.next(lateSigning);
        this.sessionStore.clear('scenario');

        const res = {
          ...scenarioInfo,
          gateways: biteFlowGatewayList
            ?.map(gateway => ({
              ...gateway,
              nodes: gateway.nodes.filter(({ type }) => type !== NodeType.Start)
            }))
            .sort(({ orderIndex: preOrder }, { orderIndex: curOrder }) => preOrder - curOrder)
        };

        const lastGateway = res.gateways[res.gateways.length - 1];

        if (
          lastGateway.hitResultShow &&
          lastGateway.gatewayInnerType === 'EXPRESS_INTEREST_FLOWS' &&
          !lastGateway.nodes.length
        ) {
          this.resultGateway.next(lastGateway);
        }

        return res;
      }),
      shareReplay(1)
    );

  /**
   * Re-modify, get modification process progress information.
   */
  modifyScenarioProgress = of(this.extraParams).pipe(
    repeat({ delay: () => this.modifyUpdate }),
    mergeMap(_ => {
      const snapshot = this.sessionStore.get('snapshot');
      const progress = this.sessionStore.get('progress');
      const { subscriptionId, taskId, source } = this.extraParams;

      if (snapshot && progress) {
        return of(progress as Progress);
      }
      return this.scenarioLateSignService.getModifyScenarioProgress(subscriptionId, taskId).pipe(
        mergeMap(({ data }) => {
          this.lateSign.next(true);

          return this.scenarioLateSignService.getSubscriptionSnapshot(this.subscriptionId!).pipe(
            map(x => {
              const snapshot = x.data;
              const progress = {
                gateways: [data],
                isScenarioDone: false,
                scenarioName: data.gatewayDescription,
                scenarioId: data.scenarioId ?? ''
              };

              // write data to snapshot
              data.nodes.forEach(node => {
                const { busData, nodeInnerType } = node;
                if (busData && nodeInnerType) {
                  snapshot[nodeInnerType.toLowerCase()] = JSON.parse(busData);
                }
              });

              this.sessionStore.set('snapshot', snapshot);
              this.sessionStore.set('progress', progress);
              this.step.next(0);
              return progress;
            })
          );
        }),
        // TODO: Optimize prompt operations
        catchError(err => {
          const { error } = err;
          this.sessionStore.clear('scenario');

          if (error && error.message === 'task not in progress') {
            this.scenarioService.notification.error(ErrorMessages.taskDone);

            if (source === 'GP') {
              this.discard.next(null);
            }

            if (source === 'LP') {
              this.router.navigate([`inbox/TASK_MESSAGE/`]);
            }
          } else {
            this.scenarioService.notification.error(ErrorMessages.general);
          }

          throw err;
        })
      );
    }),
    shareReplay(1)
  );

  /**
   * Get progress observable
   */
  scenarioProgress = this.isModifyInvest
    ? this.modifyScenarioProgress
    : this.normalScenarioProgress;

  /**
   * Get latest gateway
   */
  resultHit$ = this.scenarioProgress
    .pipe(
      pairwise(),
      filter(([pre, cur]) => cur.isScenarioDone && !pre.isScenarioDone)
    )
    .subscribe(([pre, cur]) => {
      const lastGateway = cur.gateways[cur.gateways.length - 1];
      if (lastGateway.hitResultShow && !this.gatewayChanged) {
        this.resultGateway.next(lastGateway);
      } else {
        this.leave.next('');
      }
    });

  /**
   * Get current node data
   */
  currentNodePath = this.scenarioProgress.pipe(
    repeat({ delay: () => this.modifyUpdate }),
    map(progress => {
      const { gateways: biteFlowGatewayList, scenarioId } = progress;
      const step = this.getStep();

      if (this.isModifyInvest) {
        const currentGateway = biteFlowGatewayList[0];
        const currentNode = currentGateway?.nodes[step];

        return {
          scenarioId,
          gatewayId: currentGateway?.id || '',
          nodeId: currentNode?.id || ''
        };
      }

      const currentGateway = biteFlowGatewayList.find(({ isGatewayDone }: any) => !isGatewayDone);
      const currentNode = currentGateway?.nodes.find(({ isNodeDone }: any) => !isNodeDone);

      return {
        scenarioId,
        gatewayId: currentGateway?.id ?? '',
        nodeId: currentNode?.id ?? '',
        ...currentNode
      };
    }),
    shareReplay(1)
  );

  // Get node data
  nodeData = this.currentNodePath.pipe(
    filter(({ gatewayId, nodeId }) => !!gatewayId && !!nodeId),
    switchMap(nodePath => {
      return this.getNodeData(nodePath);
    }),
    shareReplay(1)
  );

  // Is it possible to discard
  canDiscard = this.scenarioProgress.pipe(
    switchMap(({ gateways }) =>
      this.currentNodePath.pipe(
        take(1),
        map(({ gatewayId, nodeId }) => {
          const gateway = gateways.find((gateway: any) => gateway.id === gatewayId);
          return (
            gateway?.gatewayInnerType === 'INVESTING_FLOWS' &&
            gateway.nodes.findIndex((gateway: any) => gateway.id === nodeId)
          );
        })
      )
    )
  );

  // Is it previous
  canPrevious = this.currentNodePath.pipe(map(({ canPrevious }) => canPrevious));

  // Is it last node
  isLastNode = this.currentNodePath.pipe(
    switchMap(({ gatewayId, nodeId }) =>
      this.scenarioProgress.pipe(
        map(({ gateways }) => {
          return (
            gatewayId === gateways[gateways.length - 1].id &&
            nodeId ===
              gateways[gateways.length - 1].nodes[gateways[gateways.length - 1].nodes.length - 1].id
          );
        })
      )
    )
  );

  modifyMandatoryDocs = of(this.extraParams).pipe(
    mergeMap(params => {
      const subscriptionId = params?.['subscriptionId'];
      const taskId = params?.['taskId'];
      return this.scenarioLateSignService
        .getFundMandatoryDocs(subscriptionId, taskId)
        .pipe(map(x => x?.data));
    })
  );

  // Re-modify, contract data
  getModifyContractTags = this.scenarioLateSignService
    .getContractTags(this.extraParams?.['subscriptionId'], this.extraParams?.['taskId'])
    .pipe(map(x => x?.data?.data));

  // Re-modify, confirmation submission
  subscriptionConfirm = this.scenarioLateSignService
    .subscriptionConfirm(this.extraParams?.['subscriptionId'], this.extraParams?.['taskId'])
    .pipe(map(x => x?.data));

  /**
   * Get invest node data
   * @param nodePath
   * @returns
   */
  getNodeData = (nodePath: any) => {
    const { scenarioId, gatewayId, nodeId } = nodePath;
    const service = this.isModifyInvest ? this.scenarioLateSignService : this.scenarioService;
    const getNodeData = this.isModifyInvest
      ? service.getNodeData
      : this.scenarioService.getNodeData;

    return getNodeData.call(service, { scenarioId, gatewayId, nodeId }, this.extraParams).pipe(
      map(x => x?.data?.data),
      finalize(() => {
        this.loading.next(false);
      }),
      shareReplay(1)
    );
  };

  /**
   * Temporary node information
   * @param result
   * @returns
   */
  saveNodeResult(result?: any) {
    return this.currentNodePath.pipe(
      take(1),
      switchMap(nodePath =>
        this.scenarioService.saveNodeResult(nodePath, result, this.extraParams ?? {})
      )
    );
  }

  discardInvest(deleteSubscription = false) {
    const productId = this.extraParams?.['productId'];
    return productId
      ? this.scenarioService.discardInvest(productId, deleteSubscription).pipe(
          tap(() => {
            this.getProgress();
          })
        )
      : null;
  }

  deleteNodeResult() {
    return this.scenarioService.deleteNodeResult(this.extraParams?.['productId'], this.type);
  }

  /**
   * Every investing scenario node submit result
   * @param params
   * @returns
   */
  submitNodeResult(params: ScenarioSubmitResult = {}) {
    return this.isModifyInvest
      ? this.modifySubmitNodeResult(params)
      : this.normalSubmitNodeResult(params);
  }

  /**
   * (Normal investment progress) Not re-modify, submit node result
   * @param params
   * @returns
   */
  normalSubmitNodeResult(params: ScenarioSubmitResult) {
    const { result, source, next = true } = params;
    this.loading.next(true);

    return this.currentNodePath.pipe(
      take(1),
      switchMap(nodePath =>
        this.scenarioService.submitNodeResult(nodePath, result, this.extraParams ?? {}).pipe(
          switchMap(({ data: { result, ...rest } }) => {
            if (result === 'NO_MATCH_SELF_INVEST_SWITCH') {
              return this.dialog
                .open(ReInvestRequiredComponent, {
                  maxWidth: '680px',
                  width: '100%'
                })
                .afterClosed()
                .pipe(
                  tap(result => {
                    switch (result) {
                      case 'CLOSE':
                        this.loading.next(false);
                        break;
                      case 'RE_INVEST':
                        this.getProgress();
                        break;
                      case 'DISCARD':
                        this.discard.next({
                          skipDeleteSubscription: true
                        });
                        break;
                    }
                  })
                );
            }

            if (result === 'GATEWAY_CHANGED') {
              // !!! LP: to fix wrong result show after gateway changed
              if (source !== 'GP') {
                this.gatewayChanged = true;
              }

              return this.dialog
                .open(GatewayChangedDialogComponent, {})
                .afterClosed()
                .pipe(
                  tap(result => {
                    if (result && next) {
                      this.getProgress();
                    } else {
                      this.loading.next(false);
                    }
                  })
                );
            } else {
              this.gatewayChanged = false;

              if (next) {
                this.getProgress();
              }

              return of({ result, ...rest });
            }
          })
        )
      ),
      catchError(err => {
        const openScenarioDialog = (data?: Record<string, unknown>) => {
          this.dialog.open(ScenarioDialogComponent, {
            maxWidth: '680px',
            width: '100%',
            ...(data ?? {})
          });
        };

        this.loading.next(false);

        switch (err.error?.code) {
          case '4003':
            openScenarioDialog();
            break;
          case '4006':
            openScenarioDialog({
              data: {
                type: 'fbo'
              }
            });
            break;
          case 'B-00031':
            openScenarioDialog({
              data: {
                title: 'System Configuration Updated',
                type: 'system'
              }
            });
            break;
          case 'STREAM-CE-00007':
            this.scenarioService.notification.error(ErrorMessages.identity);
            break;

          default:
            this.scenarioService.notification.error(ErrorMessages.general);
        }

        throw err;
      })
    );
  }

  /**
   * Re-modify, submit node result
   */
  modifySubmitNodeResult(params: ScenarioSubmitResult) {
    const { result: nodeDataDraft = {}, source, businessData: extraData, next = true } = params;
    const snapshot = this.sessionStore.get('snapshot');

    // The profile node needs to submit all modified information
    const businessData =
      extraData ||
      (nodeDataDraft?.component_investment_profile ? { profile: snapshot.profile } : null);
    const { taskId } = this.extraParams;
    const step = nodeDataDraft?.component_investment_sign ? 0 : 1;
    this.loading.next(true);

    if (next) {
      this.updateSnapshot(params);
      this.requestMandatoryDocs.next(true);
    }

    return this.currentNodePath.pipe(
      take(1),
      switchMap(nodePath =>
        this.scenarioLateSignService
          .patchModifyProgressNode(this.subscriptionId!, {
            nodeId: nodePath.nodeId,
            taskId: taskId,
            nodeDataDraft,
            businessData
          })
          .pipe(
            switchMap(({ data }) => {
              if (data && next) {
                this.gatewayChanged = false;
                this.updateModifyStep(step);
              }

              return of(data);
            }),
            catchError(err => {
              const { error } = err;

              this.sessionStore.clear('scenario');

              if (error && error.message === 'task not in progress') {
                this.scenarioService.notification.error(ErrorMessages.taskDone);

                if (source === 'GP') {
                  this.discard.next(null);
                }

                if (source === 'LP') {
                  this.router.navigate([`inbox/TASK_MESSAGE/`]);
                }
              } else {
                this.scenarioService.notification.error(ErrorMessages.general);
              }

              throw err;
            })
          )
      ),
      catchError(err => {
        this.scenarioService.notification.error(ErrorMessages.general);
        this.loading.next(false);

        throw err;
      })
    );
  }

  /**
   * Re-modify, update snapshot data
   * @param params
   */
  updateSnapshot(params: ScenarioSubmitResult) {
    const { result: nodeDataDraft } = params;
    const snapshot = this.sessionStore.get('snapshot');
    const component_investment_amount = nodeDataDraft?.component_investment_amount;

    if (snapshot) {
      const { investing_amount } = snapshot;

      if (component_investment_amount && investing_amount) {
        snapshot.investing_amount.component_investment_amount = component_investment_amount;
      }
    }

    snapshot.model = { ...(snapshot.model ?? {}), ...nodeDataDraft };
    this.sessionStore.set('snapshot', snapshot);
  }

  /**
   * (Normal investment progress) Not re-modify, previous logic
   * @param nodeData
   * @returns
   */
  previous(nodeData: any) {
    return this.currentNodePath.pipe(
      take(1),
      switchMap(nodePath => {
        const { nodeId, gatewayId, scenarioId } = nodePath;
        const { source, productId, principalId } = this.extraParams;

        return this.scenarioService
          .previous(productId, {
            nodeId,
            gatewayId,
            scenarioId,
            principalId,
            nodeResult: nodeData
          })
          .pipe(
            map(({ data }) => {
              if (data.deleteSuccess) {
                if (source !== 'GP') {
                  this.gatewayChanged = true;
                }

                this.getProgress();

                return;
              }

              this.loading.next(false);
            }),
            catchError(err => {
              this.scenarioService.notification.error(ErrorMessages.general);
              this.loading.next(false);

              throw err;
            })
          );
      })
    );
  }

  /**
   * Re-modify, previous logic
   * @param nodeData
   * @returns
   */
  modificationPrevious(nodeData?: any) {
    this.loading.next(true);

    return this.currentNodePath.pipe(
      take(1),
      switchMap(nodePath => {
        const { nodeId } = nodePath;
        const { taskId } = this.extraParams;

        return this.scenarioLateSignService
          .modificationPrevious(this.subscriptionId!, {
            nodeId,
            taskId,
            nodeDataDraft: nodeData
          })
          .pipe(
            map(({ data }) => {
              return data;
            }),
            catchError(err => {
              this.scenarioService.notification.error(ErrorMessages.general);
              this.loading.next(false);

              throw err;
            })
          );
      })
    );
  }

  modificationDiscard() {
    const { taskId } = this.extraParams;

    return this.scenarioLateSignService
      .modificationDiscard(this.subscriptionId!, {
        taskId
      })
      .pipe(
        map(({ data }) => {
          return data;
        }),
        catchError(err => {
          this.scenarioService.notification.error(ErrorMessages.general);

          throw err;
        })
      );
  }
}
