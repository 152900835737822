import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '@stream/ngx-utils';
import { Observable } from 'rxjs';

import { IDocumentType, IFileType } from '../../services/profile-ira.service';
import { ProfileService } from '../../services/profile.service';

const DELAY_NEXT_TICK: number = 50;

@Component({
  selector: 'stream-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent {
  _documentsList: IDocumentType[] = [];

  @Input()
  set documentsList(list: IDocumentType[]) {
    this._documentsList = list || [];
    this.checkDocumentsList();
  }

  @Input()
  previewAction: (params: { [key: string]: unknown }) => Observable<Blob> = this.profileService
    .downloadMTDocumentFile as (params: { [key: string]: unknown }) => Observable<Blob>;

  @Input()
  previewParamsKey: string[] = ['fileId'];

  @Output()
  checkListChange = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient, // HttpClient service must exist
    private profileService: ProfileService,
    private notification: NotificationService
  ) {
    this._documentsList = [];
  }

  checkDocumentsList() {
    const disabledValue = this._documentsList.some(doc =>
      doc.files.some(file => file.isMustView && !file.checkMustView)
    );
    this.checkListChange.emit(!disabledValue);
  }

  genPreviewParams = (file: IFileType) => {
    const result: {
      [key: string]: unknown;
    } = {};
    this.previewParamsKey.forEach((key: string) => {
      result[key] = file[key as keyof IFileType];
    });
    return result;
  };

  getFileBlob(params: {
    file: IFileType;
    indexDoc: number;
    indexFile: number;
    cb?: (res: boolean) => void;
  }) {
    const { file, indexDoc, indexFile, cb } = params || {};
    const previewParams = this.genPreviewParams(file);
    const funPreview = this.previewAction(previewParams) as unknown as Observable<Blob>;

    funPreview.subscribe(
      (res: Blob) => {
        if ('PDF' === file.type) {
          const blobUrl = URL.createObjectURL(res);
          this._documentsList[indexDoc].files[indexFile].blobUrl = blobUrl;
          cb && cb(true);
        } else if ('TXT' === file.type) {
          const reader = new FileReader();
          reader.onloadend = () => {
            this._documentsList[indexDoc].files[indexFile].blobUrl = reader.result as string;
            cb && cb(true);
          };
          reader.onerror = () => {
            // file read error
            cb && cb(false);
          };
          reader.readAsText(res);
        } else {
          // no match type
          cb && cb(false);
        }
      },
      () => {
        // api error
        cb && cb(false);
      }
    );
  }

  handleFileClick(file: IFileType, indexDoc: number, indexFile: number) {
    const fileSelect = this._documentsList[indexDoc].files[indexFile];

    for (let i = 0; i < this._documentsList.length; i++) {
      for (let j = 0; j < this._documentsList[i].files.length; j++) {
        // this._documentsList[i].files[j].blobUrl = '';
        if (i === indexDoc && j === indexFile) {
          continue;
        }
        this._documentsList[i].files[j].isFolded = true;
      }
    }

    setTimeout(() => {
      fileSelect.isFolded = !fileSelect.isFolded;

      if (this._documentsList[indexDoc].files[indexFile].blobUrl) {
        return;
      }
      // setTimeout(() => {
      if (!fileSelect.isFolded) {
        fileSelect.isFileLoading = true;
        this.getFileBlob({
          file: file,
          indexDoc,
          indexFile,
          cb: (res: boolean) => {
            fileSelect.isFileLoading = false;
            if (!res) {
              this.notification.error(
                'There seems to be a little problem, please refresh and try again.'
              );
            }
          }
        });
      }
      // }, DELAY_NEXT_TICK);
    }, DELAY_NEXT_TICK);
  }

  handleCheckMustViewChange() {
    setTimeout(() => {
      this.checkDocumentsList();
    }, DELAY_NEXT_TICK);
  }
}
