import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { QuillViewModule } from '@stream/ngx-utils';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

import { TooltipsComponent } from './tooltips.component';

@NgModule({
  declarations: [TooltipsComponent],
  imports: [MatIconModule, NzPopoverModule, QuillViewModule],
  exports: [TooltipsComponent],
})
export class TooltipsModule {}
