<header mat-dialog-title class="!mb-4 !flex !items-center !justify-between">
  <h1 class="text-neutral-900 md:text-[22px]">
    {{ showLimitReached ? 'Recipients limit reached' : 'Edit members' }}
  </h1>
  <button mat-icon-button (click)="close()" type="button">
    <mat-icon>close</mat-icon>
  </button>
</header>

<div class="px-5">
  <p mat-dialog-content class="text-base text-neutral-500" *ngIf="showLimitReached">
    The conversation has reached the maximum number of recipients (50). If you wish to invite
    additional recipients, please remove existing recipients.
  </p>
  <div *ngIf="!showLimitReached">
    <div class="text-base font-semibold text-[#2C2934]">LP members</div>
    <mat-form-field>
      <mat-select
        [formControl]="membersCtr"
        multiple
        panelClass="chat-select-panel multiple-members"
        autocomplete="off"
        placeholder="Please select LP members"
      >
        <mat-select-trigger>
          {{ getMembersTriggerText() }}
        </mat-select-trigger>
        <mat-option *ngFor="let item of memberList" [value]="item.recipientId"
          >{{ item.userName }}({{ item.notifyEmail }})</mat-option
        >
      </mat-select>
      <svg
        matPrefix
        class="mx-auto inline-block h-6 w-6 cursor-pointer fill-current text-[#ADB5BD]"
      >
        <use xlink:href="#icon-youjian"></use>
      </svg>
    </mat-form-field>
  </div>
  <div class="!mb-5 !mt-5 flex items-center justify-end gap-3 !p-0" mat-dialog-actions>
    <button
      *ngIf="!showLimitReached"
      mat-stroked-button
      color="primary"
      class="!h-12 flex-1 !leading-[48px] md:w-40 md:flex-none"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="!h-12 flex-1 !leading-[48px] md:w-40 md:flex-none"
      (click)="submit()"
    >
      {{ showLimitReached ? 'OK' : 'Confirm' }}
    </button>
  </div>
</div>
