import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

interface Option {
  label: string;
  value: string;
}

// 1.无值，点击区域，展开选择面板
// 2.有值，点击区域，展开选择面板，显示所有数据
// 3.值合法，失焦时，面板关闭
// 4.值不合法，失焦时，清空当前内容，面板关闭

@Component({
  selector: 'ngx-select-country',
  templateUrl: './ngx-select-country.component.html',
  styleUrls: ['./ngx-select-country.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: NgxSelectCountryComponent,
    },
  ],
})
export class NgxSelectCountryComponent
  implements
    MatFormFieldControl<string>,
    OnInit,
    OnChanges,
    ControlValueAccessor,
    OnDestroy,
    DoCheck
{
  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    @Optional()
    public parentFormField: MatFormField,
    private scrollingDispatcher: ScrollDispatcher,
    @Optional() private parentForm: NgForm,
    @Optional() private parentFormGroup: FormGroupDirective
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @ViewChild('INPUT', { read: MatAutocompleteTrigger })
  private _elementRef!: MatAutocompleteTrigger;

  @ViewChild('INPUT')
  private _inputRef!: ElementRef;

  static nextId = 0;

  searchControl = new FormControl();
  focused = false;
  private _required = false;
  private _disabled = false;
  private _value: string | null = null;
  controlType = 'ngx-select-country';
  autofilled?: boolean;
  touched = false;
  filterOptions: Option[] = [];
  stateChanges = new Subject<void>();
  private _placeholder?: string;
  searchSub = new Subscription();
  scrollSub = new Subscription();
  private _options: Option[] = [];
  private _onChange: any = (v: any) => null;
  private _onTouch: any = () => null;

  errorState: boolean = false;

  displayWith = (v: string): string => {
    const target = this._options.find((item) => item.value === v);
    return target ? target.label : v;
  };

  get isAsyncOptions() {
    return this.options instanceof Observable;
  }

  @Input()
  set options(arr: Observable<Option[]> | Option[]) {
    if (arr instanceof Observable) {
      arr.pipe(take(1)).subscribe((l) => {
        this._options = l || [];
        if (this.empty) {
          this.filterOptions = this._options;
        } else {
          // 如果 options 返回延时，input 会直接显示 value, 需要在此处更新一下
          this.searchControl.patchValue(this.value, { emitEvent: false });
        }
      });
    } else {
      this._options = arr || [];
      if (this.empty) {
        this.filterOptions = this._options;
      }
    }
  }
  get options() {
    return this._options;
  }

  @Input('aria-describedby')
  userAriaDescribedBy?: string;

  set value(v: string | null) {
    this._value = v;
    this.stateChanges.next();
  }

  get value() {
    return this._value;
  }

  get empty() {
    return !this.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.searchControl.disable() : this.searchControl.enable();
    this.stateChanges.next();
  }

  @HostBinding()
  id = `ngx-select-country-${NgxSelectCountryComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder || '';
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Output()
  selectChange = new EventEmitter<unknown>();

  ngOnInit(): void {
    this.searchSub = this.searchControl.valueChanges.subscribe((v) => {
      this.filterOptions = this._filter(v);
      const opt = this.getOptionByLabel(v);
      if (!v) {
        this.value = null;
        this._onChange(this.value);
      }
      if (opt && this.filterOptions.length === 1) {
        this.value = opt.value;
        this._onChange(this.value);
      }
    });
    this.scrollSub = this.scrollingDispatcher.scrolled().subscribe(() => {
      this._elementRef.closePanel();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'].currentValue) {
      this.filterOptions = this._options || [];
      this.searchControl.patchValue(this.value, { emitEvent: false });
    }
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  private updateErrorState() {
    const parent = this.parentFormGroup || this.parentForm;

    const oldState = this.errorState;
    const newState =
      !!this.ngControl.invalid && (this.touched || parent.submitted);

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  private _filter(value: string): Option[] {
    const filterValue = value?.toLowerCase();
    return this._options.filter((option) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  onFocusIn(_event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(_event: FocusEvent) {
    this.touched = true;
    this.focused = false;
    this._onTouch();
    this.stateChanges.next();
  }

  writeValue(obj: any): void {
    this.searchControl.patchValue(obj);
    this.value = obj;
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.searchControl.disable() : this.searchControl.enable();
    this.stateChanges.next();
  }

  setDescribedByIds(_ids: string[]): void {
    // todo.
  }
  onContainerClick(event: MouseEvent): void {
    if (
      (event.target as Element).tagName.toLowerCase() !== 'input' &&
      !this.focused
    ) {
      this._inputRef?.nativeElement.focus();
    }
    this._openPanel();
  }

  onAutocompleteClose() {
    if (this.value) {
      const isValid = this.isValueValid(this.value);
      if (!isValid) {
        return;
      }
      const opt = this._options.find((item) => item.value === this.value);
      if (opt && opt?.label !== this.searchControl.value) {
        // 修正错误的 label
        this.searchControl.patchValue(opt.label, { emitEvent: false });
      }
    }
  }

  // 后于 searchControl.subscribe 触发
  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option?.value;
    this._onChange(this.value);
    this.selectChange.emit(this.value);
  }

  private _openPanel() {
    this.filterOptions = this._options;
    if (this.filterOptions.length > 0) {
      setTimeout(() => {
        this._elementRef.openPanel();
      }, 300);
    }
  }

  private getOptionByLabel(label: string) {
    if (label) {
      return this._options.find(
        (item) => item.label.toLocaleLowerCase() === label.toLowerCase()
      );
    }
    return '';
  }

  private isValueValid(value: string) {
    return this._options.some((item) => item.value === value);
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.searchSub.unsubscribe();
    this.scrollSub.unsubscribe();
  }
}
