import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[historyBack]',
})
export class HistoryBackDirective {
  constructor(private location: Location) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    this.location.back();
  }
}
