import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { NgxFormProgressComponent } from './ngx-form-progress.component';

@NgModule({
  declarations: [NgxFormProgressComponent],
  imports: [CommonModule, MatProgressBarModule],
  exports: [NgxFormProgressComponent],
})
export class NgxFormProgressModule {}
