import { Pipe, PipeTransform } from '@angular/core';
import { isProdEnvironment } from '@stream/libs/common/utils';

interface ImageResizeParams {
  /** 宽 */
  width?: number;
  /** 高 */
  height?: number;
  dpr?: number;
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
  /** 图片质量 有效值50-90 默认85 */
  quality?: number;
  /** 尝试使用webp格式 */
  format?: 'auto';
}

// const IMAGE_RESIZE_CDN = `https://storage.bitestream.co/cdn-cgi/image`;
const IMAGE_RESIZE_CDN = isProdEnvironment()
  ? `https://storage.bitestream.co/cdn-cgi/image`
  : `https://storage.stream.bite.dev/cdn-cgi/image`;

@Pipe({
  name: 'imageResize'
})
export class ImageResizePipe implements PipeTransform {
  transform(url?: string | null, params?: ImageResizeParams): string {
    if (!url) return '';
    return `${IMAGE_RESIZE_CDN}/${Object.entries(params ?? {})
      .map(([key, value]) => key + '=' + value)
      .join(',')}/${url}`;
  }
}
