import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

import { FilePreviewerComponent } from "./file-previewer.component";

@NgModule({
  declarations: [FilePreviewerComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [FilePreviewerComponent],
})
export class FilePreviewerModule {}
