import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChatAsset,
  ChatAssetCategory,
  EmailDelayKeyEnum,
  HttpStatusEnum,
  MandatoryDocFile,
  MandatoryDocsApi,
  MandatoryDocument,
  Marketing,
  MarketingStatusEnum,
  NextAction,
  Page,
  PageRequest,
  PortalComponents,
  PortalComponentsApi,
  Product,
  ProductApi,
  Restful,
  ShareClass,
  ShareClassApi,
  WMChannelGP
} from '@stream/models';
import { SendEmail } from '@stream/ngx-utils';
import { MarketApi, WMApi } from '@stream/src/common';

export type MarketingTab = {
  id: Marketing['id'];
  orderIndex: number;
  productId: Product['id'];
  title: string;
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient) {}

  queryProductList(
    page: PageRequest,
    name: Product['fundamentalName'],
    queryParams: Record<string, string[]>
  ) {
    return this.http.get<
      Restful<{
        data: Page<Product>;
        nextAction: NextAction[];
      }>
    >(ProductApi.QueryProductList, {
      params: {
        q: encodeURIComponent(
          [
            ...Object.keys(queryParams).map(
              key => `${(queryParams[key] ?? []).map(v => `${key}="${v}"`).join(' OR ')}`
            ),
            name && `name ~ "${name}"`
          ]
            .filter(Boolean)
            .map(v => `(${v})`)
            .join(' AND ')
        ),
        ...page
      }
    });
  }

  getProduct(productId: Product['id'], withoutLogActivity?: boolean) {
    withoutLogActivity = withoutLogActivity == undefined ? false : withoutLogActivity;
    return this.http.post<
      Restful<{ product: Product; tenantAgreementType: string; status: string }>
    >(ProductApi.GetProduct, {
      productId,
      withoutLogActivity
    });
  }

  getMarketingTabs(productId: Product['id']) {
    return this.http.post<
      Restful<{
        marketingList: MarketingTab[];
      }>
    >(MarketApi.GetTabs, { productId });
  }

  getMarketingDetail(productId: Product['id'], marketingId: Marketing['id']) {
    return this.http.post<
      Restful<{
        marketingVO: Marketing;
        status: MarketingStatusEnum;
      }>
    >(MarketApi.GetMarketingDetail, {
      productId,
      marketingId
    });
  }

  getShareClasses(productId: string) {
    return this.http.post<Restful<{ shareClassList: ShareClass[] }>>(
      ShareClassApi.getShareClasses,
      { productId }
    );
  }

  getFundMandatoryDocs(
    tenantId: string,
    params: {
      productId: string;
      nodeId: string;
      gatewayId: string;
      scenarioId: string;
    }
  ) {
    return this.http.get<Restful<Array<MandatoryDocument>>>(
      MandatoryDocsApi.GetFundMandatoryDocs.replace(':tenantId', tenantId),
      {
        params
      }
    );
  }

  uploadMandatoryDoc(tenantId: string, params: FormData) {
    return this.http.post<Restful<MandatoryDocFile>>(
      MandatoryDocsApi.uploadMandatoryDoc.replace(':tenantId', tenantId),
      params
    );
  }

  getPortalComponents() {
    return this.http.get<Restful<Array<PortalComponents>>>(
      PortalComponentsApi.getPortalComponents,
      {
        params: {
          visibilityState: 'show'
        }
      }
    );
  }

  getProductPortalComponents(productId: string) {
    return this.http.get<Restful<Array<PortalComponents>>>(
      PortalComponentsApi.getProductPortalComponents.replace(':productId', productId)
    );
  }

  wmChannelGP(productId: string) {
    return this.http.post<
      Restful<{
        status: HttpStatusEnum;
        result?: WMChannelGP;
        code?: string;
        message?: string;
      }>
    >(`${WMApi.wmChannelGP}?productId=${productId}`, {});
  }

  @SendEmail(EmailDelayKeyEnum.SignUp)
  replyEmail(params: { productId: string }) {
    return this.http.get<Restful<{ status: string; linkUrl?: string }>>(WMApi.replyEmail, {
      params
    });
  }

  // for chat component
  getProductsHasCategory(location: string) {
    return this.http.get<Restful<Array<ChatAsset>>>(
      ProductApi.GetChatRelatedAssets.replace(':location', location)
    );
  }

  getProductCategories(location: string, productId: string) {
    return this.http.get<Restful<Array<ChatAssetCategory>>>(
      ProductApi.GetChatAssetCategories.replace(':location', location).replace(
        ':preferredAsset',
        productId
      )
    );
  }
}
