import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
  MatDialogModule
} from '@angular/material/dialog';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TraceService } from '@sentry/angular-ivy';
import { CoreModule, DeviceInfoInterceptor, ErrorInterceptor } from '@stream/core';
import { AppInactivityReminderComponent } from '@stream/libs/common';
import { STREAM_CONFIG, StreamLPConfig } from '@stream/libs/common/common.type';
import { ToastModule } from '@stream/libs/common/ngx-utils';
import { EmailModule, NotificationModule } from '@stream/ngx-utils';
import { ProfileComponent } from '@stream/profile';
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaSettings,
  RecaptchaV3Module
} from 'ng-recaptcha';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { map } from 'rxjs';
import { ChatModule } from 'src/app/modules/chat/chat.module';
import { deployingEnvironment } from 'src/libs/utils/src/lib/system';

import { faroInitializer } from '@stream/utils';
import { environment } from '../environments/environment';
import { ProfileModule } from '../libs/profile/profile.module';
import { TranslateModule } from '../libs/translate/translate.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './handler/global-error.handler';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { PrincipalInterceptor } from './interceptors/principal.interceptor';
import { TenantInterceptor } from './interceptors/tenant.interceptor';
import { PermissionGuard } from './modules/account/guards/permission.guard';
import { PrincipalResolver } from './modules/account/guards/principal.resolver';
import { WMCandidateGuard } from './modules/account/guards/wm-candidate.guard';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { CustomDomainGuard } from './modules/auth/guards/custom-domain-redirect.guard';
import { DomainNotOperationalComponent } from './modules/landing/pages/domain-not-operational/domain-not-operational.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { LayoutModule } from './modules/layout/layout.module';
import { NotFoundComponent } from './not-found.component';
import { AccountService } from './services/account.service';
import { ClientService } from './services/client.service';

const matModules = [MatButtonModule, MatSnackBarModule, MatMomentDateModule, MatDialogModule];

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TenantInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DeviceInfoInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PrincipalInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
];

const reCaptchaProviders = [
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.reCaptchaKeyV3
  },
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.reCaptchaKeyV2,
      size: 'invisible'
    } as RecaptchaSettings
  },
  {
    provide: RECAPTCHA_LANGUAGE,
    useFactory: (locale: string) => locale,
    deps: [LOCALE_ID]
  },
  {
    provide: RECAPTCHA_BASE_URL,
    useValue: 'https://recaptcha.net/recaptcha/api.js' // use recaptcha.net script source for some of our users
  }
];

@NgModule({
  declarations: [AppComponent, NotFoundComponent, AppInactivityReminderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    CoreModule,
    ...matModules,
    RecaptchaV3Module,
    LayoutModule,
    NgxWebstorageModule.forRoot({
      prefix: 'lp'
    }),
    ProfileModule,
    NotificationModule,
    EmailModule,
    ToastModule,
    RouterModule.forRoot([
      {
        path: 'domain-not-operational',
        component: DomainNotOperationalComponent
      },
      {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule)
      },
      {
        path: 'landing',
        loadChildren: () =>
          import('./modules/landing/landing.module').then(module => module.LandingModule)
      },

      {
        path: '',
        canActivate: [AuthGuard],
        data: {
          translate: true
        },
        children: [
          {
            path: '',
            component: LayoutComponent,
            children: [
              {
                path: 'account',
                canActivate: [PermissionGuard],
                loadChildren: () =>
                  import('./modules/account/account.module').then(m => m.AccountModule)
              },
              {
                path: 'product',
                loadChildren: () =>
                  import('./modules/product/product.module').then(m => m.LpProductModule)
              },
              {
                path: 'portfolio',
                canActivate: [WMCandidateGuard, PermissionGuard],
                loadChildren: () =>
                  import('./modules/portfolio/portfolio.module').then(m => m.PortfolioModule)
              },
              {
                path: '',
                redirectTo: 'product',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'scenario',
            canActivate: [WMCandidateGuard],
            loadChildren: () =>
              import('./modules/scenario/scenario.module').then(m => m.ScenarioPanelModule)
          },
          {
            path: 'profile',
            canActivate: [WMCandidateGuard],
            resolve: {
              principalType: PrincipalResolver
            },
            children: [
              {
                path: 'create/:tenantId',
                component: ProfileComponent,
                loadChildren: () =>
                  import('../libs/profile/profile.module').then(m => m.ProfileModule)
              },
              {
                path: 'edit/:type/:profileId/:tenantId',
                component: ProfileComponent,
                loadChildren: () =>
                  import('../libs/profile/profile.module').then(m => m.ProfileModule)
              },
              {
                path: ':subscriptionId/:type/:profileId/:tenantId',
                component: ProfileComponent,
                loadChildren: () =>
                  import('../libs/profile/profile.module').then(m => m.ProfileModule)
              }
            ]
          },
          {
            path: 'inbox',
            canActivate: [WMCandidateGuard],
            loadChildren: () => import('./modules/inbox/inbox.module').then(m => m.InboxModule)
          }
        ]
      },
      {
        path: '**',
        canActivate: [CustomDomainGuard],
        component: NotFoundComponent
      }
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    TranslateModule.forRoot({
      origin: 'en-gb',
      ignore: {
        tags: ['svg', 'mat-icon', 'stream-translate', 'noscript', 'ngx-extended-pdf-viewer'],
        classes: ['stream-translate-ignore', 'ignore-dom', 'material-icons'],
        ids: ['stream-translate-ignore']
      }
    }),
    ChatModule
  ],
  providers: [
    {
      provide: STREAM_CONFIG,
      useFactory: (
        clientService: ClientService,
        accountService: AccountService
      ): StreamLPConfig => {
        return {
          source: 'LP',
          environment: {
            features: environment.featureFlags || [],
            currentEnv: deployingEnvironment()
          },
          tenantInfo$: clientService.configuration.pipe(
            map(tenantInfo => ({
              tenantId: tenantInfo.tenantId,
              tenantType: tenantInfo.type,
              salesSupportEmail: tenantInfo.salesSupportEmail
            }))
          ),
          account$: accountService.account,
          principal$: accountService.principal
        };
      },
      deps: [ClientService, AccountService]
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (clientService: ClientService) => () => clientService.configuration,
      deps: [ClientService, TraceService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: faroInitializer,
      deps: [],
      multi: true
    },
    interceptors,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    ...reCaptchaProviders,
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        panelWidth: 'auto'
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        maxWidth: '95vw',
        disableClose: true,
        autoFocus: 'dialog',
        closeOnNavigation: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
